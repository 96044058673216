import React, { useState, useReducer } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { getSearchContratsAgent, getProduitFormules } from 'redux/actions/apiActions'
import Core from 'pages/components/core'
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDate from 'components/CustomDate/CustomDate'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Box } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },


});
const useStyles = makeStyles(theme => ({
    dcard: {
        marginBottom: 10,
        paddingBottom: 10,
        flexWrap: 'wrap'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    coreCustum: {
        borderRadius: 0,
        borderLeftWidth:'10px',
        borderLeftStyle:'solid',
        borderLeftColor:theme.palette.primary.main
    },
    itemTitle: {
        fontStyle: "italic"
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        marginBottom: 20,
        backgroundColor:green[50],
        padding: 10
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
}))
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


function ModalIdentification(props) {
    const { open, handleClose, data, produits, handleAlertClose, handleSaveOperation, handleAlertOpen } = props
    const [loading, setLoading] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const [currentSelected, setCurrentSelected] = useState(null)
    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            idcontrat: '',
            numeroproposition: '',
            numeropolice: '',
            numerobulletin: '',
            dateeffet: '',
            dateecheance: '',
            capital: '',
            prime: '',
            adherent: '',
            datenaissance: '',
            codeconseiller: '',
            nomconseiller: '',
            etatcontrat: '',
            codeproduit: '',
            codeguichet: '',
            numerocompte: ''
        });

    const [formuleProduit, setFormuleproduit] = useState(null)
    const classes = useStyles();
    const handleInputChange = (event) => {
        const { name, value } = event.target
        setInputField({
            [name]: value
        })
    }
    const handleReset = () => {
        setInputField({
            ['idcontrat']: '',
            ['numeroproposition']: '',
            ['numeropolice']: '',
            ['numerobulletin']: '',
            ['dateeffet']: '',
            ['dateecheance']: '',
            ['capital']: '',
            ['prime']: '',
            ['adherent']: '',
            ['datenaissance']: '',
            ['codeconseiller']: '',
            ['nomconseiller']: '',
            ['etatcontrat']: '',
            ['codeproduit']: '',
            ['codeguichet']: '',
            ['numerocompte']: ''
        })
    }

    const [details, setDetails] = useState(null)
    const handleSearch = () => {
        setLoading(true)
        setCurrentSelected(null)
        if (inputField.idcontrat === '' && inputField.numeroproposition === '' && inputField.numeropolice === '' && inputField.dateeffet === '' && inputField.dateEcheance === '' && inputField.capital === '' && inputField.prime === '' && inputField.adherent === '' && inputField.codeconseiller === '' && inputField.nomconseiller === '' && inputField.numerocompte === '') {
            return
        }
        getSearchContratsAgent(inputField).then(resData => {
            if (resData) {
                setDetails(resData.details)
                setShowResult(true)
                setLoading(false)
            } else {
                setLoading(false)
                handleAlertOpen("Aucune données trouvées,Veuillez verifier les critères", false)
            }
        }).catch(err => {
            setLoading(false)
            handleAlertOpen("Erreur lors de la récupération des donneé", false)
        })

    }
    const [moresInfo, setMoreInfo] = useState(false)
    const handleValide = async () => {
        if (currentSelected) {
            const dataSend = {
                id: data.id,
                idproposition: currentSelected.id,
                codeproposition: currentSelected.CodeProposition,
                codepolice: currentSelected.CodePolice,
                dateeffet: currentSelected.DateEffet,
                dateecheance: currentSelected.DateEcheance,
                adherent: currentSelected.Adherent,
                prime: currentSelected.Prime,
                capital: currentSelected.Capital,
                nombreemission: currentSelected.NombreEmission,
                montantemission: currentSelected.MontantEmission,
                nombreencaissement: currentSelected.NombreEncaissement,
                montantencaissement: currentSelected.MontantEncaissement,
                nombreimpayes: currentSelected.NombreImpayes,
                montantimpayes: currentSelected.MontantImpayes,
                codeproduit: currentSelected.CodeProduit,
                codeproduitformule: currentSelected.CodeProduitFormule,
            }
            if (produits && produits.lenght !== 0) {
                const cproduit = produits.filter(item => item.CodeProduit == currentSelected.CodeProduit)[0]
                if (cproduit) { dataSend['libelleproduit'] = cproduit['MonLibelle'] || "" }
            }
            let formule
            if (formuleProduit && formuleProduit.lenght !== 0) {
                formule = formuleProduit.filter(item => item.CodeProduitFormule === currentSelected.CodeProduitFormule)[0]
                if (formule) { dataSend['libelleproduitformule'] = formule['MonLibelle'] || "" }
            } else {
                const fmData = await getProduitFormules(currentSelected.CodeProduit)
                if (fmData) {
                    formule = fmData.filter(item => item.CodeProduitFormule === currentSelected.CodeProduitFormule)[0]
                    dataSend['libelleproduitformule'] = formule['MonLibelle'] || ""
                }
            }
            handleSaveOperation(dataSend)
        } else {
            handleAlertOpen("Aucun contrat sélectionnés", false, handleAlertClose)
        }
    }

    return (
        <Dialog
            onClose={handleClose}
            fullWidth
            disableBackdropClick
            maxWidth={'md'}
            aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                {`Edition opération #Ref ${data && data['codeoperation']} - ${data['id']} - Selection un contrat`}
            </DialogTitle>
            <DialogContent dividers>
                {!showResult && <div>
                    <Paper className={classes.paper}>
                        <Core.Base>
                            <Core.Inner direction='column' salign='flex-start'>
                                <Box className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        #ID:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${data['idproposition'] || ""}`}
                                    </Typography>
                                </Box>
                                <Box className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Propostion :
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${data['codeproposition'] || ""}`}
                                    </Typography>
                                </Box>
                                <Box className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        N° Police :
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${data['codepolice'] || ""}`}
                                    </Typography>
                                </Box>
                            </Core.Inner>
                            <Core.Inner direction='column' salign='flex-start'>
                                <Box className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        #Adherent:
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${data['adherent'] || ""}`}
                                    </Typography>
                                </Box>
                                <Box className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Produit :
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${data['libelleproduit'] || ""}`}
                                    </Typography>
                                </Box>
                                <Box className={classes.assureInfoRow}>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Formule :
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {`\xa0 ${data['libelleproduitformule'] || ""}`}
                                    </Typography>
                                </Box>
                            </Core.Inner>
                        </Core.Base>
                    </Paper>

                    <LabelledOutline label="TROUVER UN CONTRAT">
                        <Paper elevation={3} className={classes.dcard}>
                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4} lg={4}>
                                    <CustomInput
                                        label="Code guichet"
                                        id="codeguichet"
                                        name="codeguichet"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={inputField['codeguichet'] || ""}
                                        type='texte'
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={6}>
                                    <CustomInput
                                        label="N° compte/client"
                                        id="numerocompte"
                                        name="numerocompte"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={inputField['numerocompte'] || ""}
                                        type='texte'
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                </GridItem>
                            </GridContainer>
                        </Paper>
                        {moresInfo && <>
                            <Paper elevation={3} className={classes.dcard}>
                                <GridContainer>
                                    <GridItem xs={12} sm={5} md={5} lg={5}>
                                        <CustomInput
                                            label="Adherent (Nom & Prenoms)"
                                            id="adherent"
                                            name="adherent"
                                            value={inputField.adherent}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            type='texte'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomDate
                                            label="Date de naissance"
                                            id="datenaissance"
                                            name="datenaissance"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                type: 'date',
                                            }}
                                            value={inputField.datenaissance}
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Paper>
                            <Paper elevation={3} className={classes.dcard}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomInput
                                            label="ID"
                                            id="idcontrat"
                                            name="idcontrat"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={inputField.idcontrat}
                                            type='texte'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomInput
                                            label="N° Proposition"
                                            id="numeroproposition"
                                            name="numeroproposition"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={inputField.numeroproposition}
                                            type='texte'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomInput
                                            label="N° Police"
                                            id="numeropolice"
                                            name="numeropolice"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={inputField.numeropolice}
                                            type='texte'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomInput
                                            label="N° Bulletin"
                                            id="numerobulletin"
                                            name="numerobulletin"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={inputField.numerobulletin}
                                            type='texte'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Paper>

                            <Paper elevation={3} className={classes.dcard}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomDate
                                            label="Date d'effet"
                                            id="dateeffet"
                                            name="dateeffet"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                type: 'date',
                                            }}
                                            value={inputField.dateeffet}
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomDate
                                            label="Date fin cotisation"
                                            id="dateecheance"
                                            name="dateecheance"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            InputProps={{
                                                type: 'date',
                                            }}
                                            value={inputField.dateecheance}
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomInput
                                            label="Capital"
                                            id="capital"
                                            name="capital"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={inputField.capital}
                                            type='number'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomInput
                                            label="Prime"
                                            id="prime"
                                            name="prime"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={inputField.prime}
                                            type='number'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    {(produits && produits.length !== 0) && (
                                        <GridItem xs={12} sm={4} md={4} lg={4}>
                                            <TextField
                                                variant="outlined"
                                                id="codeproduit"
                                                name='codeproduit'
                                                select
                                                label="Produit"
                                                value={inputField.codeproduit}
                                                onChange={handleInputChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{ marginTop: 20, marginBottom: 10 }}
                                            >
                                                <MenuItem key={0} value={0} />
                                                {(produits.length !== 0) && produits.map((option) => (
                                                    <MenuItem key={option.CodeProduit} value={option.CodeProduit}>
                                                        {option.MonLibelle.toUpperCase()}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                    )}
                                </GridContainer>
                            </Paper>

                            <Paper elevation={3} className={classes.dcard}>
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4} lg={4}>
                                        <CustomInput
                                            label="Code connseiller"
                                            id="codeconseiller"
                                            name="codeconseiller"
                                            value={inputField.codeconseiller}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            type='texte'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6} lg={6}>
                                        <CustomInput
                                            label="Nom conseiller"
                                            id="nomconseiller"
                                            name="nomconseiller"
                                            value={inputField.nomConseiller}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            type='texte'
                                            variant="outlined"
                                            onChange={handleInputChange}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Paper>
                        </>}

                        <div>
                            <Core.DisButton onClick={() => setMoreInfo(!moresInfo)}>{moresInfo ? `Moins de critères ` : `Plus de critères `} {moresInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Core.DisButton>
                        </div>
                        <Paper elevation={3} className={classes.dcard}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6} lg={6}>
                                    <Button variant='outlined' color='secondary' className={classes.buttonReset} onClick={handleReset} >
                                        REINITIALISER
                                    </Button>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6} lg={6}>
                                    <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleSearch} >
                                        RECHERCHER
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </Paper>
                    </LabelledOutline>
                </div>}
                {showResult &&
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Button variant='outlined' color='primary' className={classes.buttonNewSearch} onClick={() => setShowResult(false)} >
                                    Edition des critères de recherche
                                </Button>
                            </GridItem>
                        </GridContainer>
                        {(details && details.length !== 0 && showResult) && (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <MaterialTable
                                        columns={[
                                            { title: 'Adhérent', field: 'Adherent' },
                                            { title: 'Date de naissance', field: 'DateNaissance' },
                                            { title: 'Produit', field: 'Produit' },
                                            { title: 'Date d\'effet', field: 'DateEffet' },
                                            { title: 'Date d\'échéance', field: 'DateEcheance' },
                                            { title: 'Prime', field: 'Prime', render: rowData => parseInt(rowData.Prime) ? formatNumber(parseInt(parseInt(rowData.Prime))) : "" },
                                            { title: 'Capital', field: 'Capital', render: rowData => parseInt(rowData.Capital) ? formatNumber(parseInt(parseInt(rowData.Capital))) : "" },
                                            { title: 'Etat', field: 'Etat' },
                                            { title: 'Nb d\'émission', field: 'NombreEmission', render: rowData => parseInt(rowData.NombreEmission) ? formatNumber(parseInt(rowData.NombreEmission)) : "" },
                                            { title: 'Mt d\'émission', field: 'MontantEmission', render: rowData => parseInt(rowData.MontantEmission) ? formatNumber(parseInt(rowData.MontantEmission)) : "" },
                                            { title: 'Nb d\'encaissement', field: 'NombreEncaissement', render: rowData => parseInt(rowData.NombreEncaissement) ? formatNumber(parseInt(rowData.NombreEncaissement)) : "" },
                                            { title: 'Mt d\'encaissement', field: 'MontantEncaissement', render: rowData => parseInt(rowData.MontantEncaissement) ? formatNumber(parseInt(parseInt(rowData.MontantEncaissement))) : "" },
                                            { title: 'Nb d\'impayes', field: 'NombreImpayes', render: rowData => parseInt(rowData.NombreImpayes) ? formatNumber(parseInt(parseInt(rowData.NombreImpayes))) : "" },
                                            { title: 'Mt d\'impayes', field: 'MontantImpayes', render: rowData => parseInt(rowData.MontantImpayes) ? formatNumber(parseInt(parseInt(rowData.MontantImpayes))) : "" },
                                        ]}
                                        data={details}
                                        title=""
                                        localization={{
                                            toolbar: {
                                                searchPlaceholder: 'Rechercher',
                                                searchTooltip: 'Rechercher'
                                            },
                                            body: {
                                                emptyDataSourceMessage: 'Aucune donnée trouvée !'
                                            },
                                            pagination: {
                                                labelRowsSelect: 'Ligne(s)',
                                                labelDisplayedRows: '{count} sur {from}-{to}',
                                                firstTooltip: 'Prémière page',
                                                previousTooltip: 'Précédent',
                                                nextTooltip: 'Suivant',
                                                lastTooltip: 'Dernière page'
                                            }
                                        }}
                                        options={{
                                            selection: true,
                                            showSelectAllCheckbox: false,
                                            exportButton: true,
                                            searchFieldStyle: {
                                                fontSize: 18,
                                                width: '100%',
                                                height: 50,
                                            },
                                            actionsColumnIndex: -1
                                        }}
                                        onSelectionChange={(rows, currentRow) => {
                                            const dataCopy = [...details];
                                            rows.map(row => {
                                                row.tableData.checked = false
                                                dataCopy[row.tableData.id] = row;
                                            })
                                            setCurrentSelected(currentRow)
                                            if (currentSelected && currentSelected.tableData.id == currentRow.tableData.id) {
                                                dataCopy[currentSelected.tableData.id].tableData.checked = false
                                                setCurrentSelected(null)
                                            } else {
                                                dataCopy[currentRow.tableData.id].tableData.checked = !currentRow.tableData.checked
                                                setCurrentSelected(currentRow)
                                            }
                                            setDetails(dataCopy)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        )}
                    </div>}

                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="secondary" />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button className={classes.buttonSearch} autoFocus onClick={handleValide} color="primary" disabled={currentSelected ? false : true}>
                    Valider
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default ModalIdentification