import React,{useState,useEffect} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/llv//components/customRadioStyle.js";

import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  
} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function CustomCheckBox(props) {
    const classes = useStyles();
    const { formControlProps,id,labelProps,helperText,row,inputProps,error,success,options,label,value,onChange,name,input,required, ...rest } = props
    const [checked, setChecked] = useState((input&&input.value)?input?.value:(value?value:[]));
    const handleChange = event => {
       
        const cvalue = event.target.value

        let newChecked = checked
        if(newChecked.includes(cvalue)){
            let n=[]
            n =newChecked.filter(c => c !== cvalue)
            setChecked(n);
            if(input){
                input.onChange(n) 
            }else{
                onChange(n) 
            }
        }else{
            setChecked([...checked,cvalue]) 
            newChecked.push(cvalue)
            if(input){
                input.onChange(newChecked) 
            }else{
                onChange(newChecked) 
            } 
        }  
        
    }
    useEffect(() => {
        if(value && value.length!==0){
            setChecked(value)
        }
        if(input&& input.value && input.value.length!==0){
            setChecked(input.value)
        }
    }, [])
    return(
        <FormControl fullWidth component="fieldset" margin="normal">
            <FormLabel component="legend" focused={false}>
                {label}
            </FormLabel>
            <FormGroup aria-label="Temas" row={row?true:false}>
                {options.map((option, i) => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Checkbox  onChange={e => handleChange(e)} checked= {checked.includes(option.value)}/>}
                    label={option.label}
                    {...rest}
                />
                ))}
            </FormGroup>
        </FormControl>        
    )
}

CustomCheckBox.propTypes = {
    label: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
  };