import React, { useEffect, useRef } from 'react'
import { makeStyles,withStyles } from '@material-ui/styles'
import { Paper,Typography,Divider, Button,Tooltip,IconButton } from '@material-ui/core'
import { red,blue } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MaterialTable from 'material-table';
import {getProductionAgentParProduit,getTotProductionAgent} from 'redux/actions/apiActions'
import { useDispatch,useSelector } from 'react-redux';
import { connect } from 'react-redux';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import AirlineSeatIndividualSuiteIcon from '@material-ui/icons/AirlineSeatIndividualSuite';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Badge from '@material-ui/core/Badge';
import Chart from 'util/chart'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
    getEquipesZones,
    getZonesReseau,
    getRolesReseau,
    getReseauxNoDispatch,
    getBranche,
    getPartenaire
  } from 'redux/actions/settingsAction'
  import MenuItem from '@material-ui/core/MenuItem';
  import TextField from '@material-ui/core/TextField';
 
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useState } from 'react';
import { getModePaiement } from 'redux/actions/apiActions';
import Checkbox from '@material-ui/core/Checkbox'
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { getProduitFormules } from 'redux/actions/apiActions';
import ListItemText  from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
const useStyle = makeStyles(theme=>({
    root:{
        padding:20,
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto',
    },
    ppaper:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:20,
        marginBottom:25
    },
    ppaperItem:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        alignItems:'center',
        margin:10,
    },
    textegreen:{
        color:theme.palette.primary.main,
    },
    texteblue:{
        color:"#00FF00",
    },
    textered:{
        color:red[400],
    },
    texteyel:{
        color:'#f9b233',
    },
    actionContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:20,
        flexWrap:'wrap'
    },
    btnadd:{
        display:'flex',
        flex:1,
        paddingLeft:20,
        paddingRight:20,
        paddingTop:10,
        paddingBottom:10,
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'#fff',
        }

    },
    incorpContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:25,
        marginBottom:20,
        marginLeft:20,
        marginRight:20,
        padding:20
    },
    incorpTable:{
        display:'flex',
        flex:1,
        marginTop:20,
        justifyContent:'center',
       
    },
    incorppaper:{
        padding:20,
        borderRadius:10,
        marginRight:20 
    },
    tabTitle:{
        marginBottom:10
    },
    hPaper:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8,
      display:'flex',
      justifyContent:'space-between'
    },
    hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      padding:20
    },
}))
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        padding:10,
    },
  },
};

const AddFacture = () => {
    const classes = useStyle()
    const produits = useSelector(state=>state.api.produits)
    const [inputField,setInputField]=useState(
    {
      codepartenaire:'',
      codebranche:'',
      codezone:'',
      codeequipe:'',
      codereseau:'',
      produit:"",
      formules:[],
      details:[],
      nombre:0,
      total:0
    });

    const [zones,setZones] = useState(null)
    const [equipes,setEquipes] = useState(null)
    const [reseaux,setReseaux] = useState(null)
    const [rolesReseau,setRolesReseau] = useState(null)
    const [branches,setBranches] = React.useState(null)
    const [partenaires,setPartenaires] = React.useState(null)
    const [modepaiement,setModePaiement] = React.useState(null)
    const [formules,setFormules] = React.useState(null)
    
    useEffect(()=>{
        if(inputField.codereseau!==''){
          getZonesReseau(inputField.codereseau).then(dataZone=>{
              if(dataZone){setZones(dataZone)}
          })
        }
        if(inputField.codereseau!==''){
          getRolesReseau(inputField.codereseau).then(dataRole=>{
              if(dataRole){setRolesReseau(dataRole)}
          })
        }
    },[inputField.codereseau])

    useEffect(() => {
      if(inputField.codezone!==''){
        getEquipesZones(inputField.codezone).then(dataEquipe=>{
          if(dataEquipe){setEquipes(dataEquipe)}
        })
      }
    },[inputField.codezone])
  
    useEffect(() => {
      getReseauxNoDispatch().then(drezo=>{
        if(drezo){setReseaux(drezo)}
      })
      getBranche().then(data=>{
        if(data){setBranches(data);}
      })
      getPartenaire().then(data=>{
        if(data){setPartenaires(data);}
      })
      getModePaiement().then(res=>{
        if(res){
            setModePaiement(res)
        }
      })
    },[])
  
    const handleChange = (event)=>{   
        const {name,value} = event.target
        setInputField({
            ...inputField,
            [name]:value
        })
    }
    const handleChangeFormule = (event) => {
        const {
          target: { value },
        } = event;
        setInputField(
          {...inputField, ['formules']:typeof value === 'string' ? value.split(',') : value}
        );
    };
    const handleFormuleProduit = () => {
        if (inputField['produit'] !== "") {
            getProduitFormules(inputField.produit).then(data => { if (data) { setFormules(data); } })
        }
    }
      useEffect(() => {
          handleFormuleProduit()
      }, [inputField.produit])

  return (
    <div className='flex my-4 mx-4 flex-col gap-8'>
        <Paper className={classes.hPaper} variant="outlined">
            <div className='flex flex-row items-center gap-2 py-2'>
                <IconButton className='hover:bg-primary-main flex-1 items-center justify-center'>
                    <ArrowBackIosIcon className='text-[40px]' />
                </IconButton>
                <Typography variant='h5'>Génération de factures</Typography>
            </div>
        </Paper>
        <div className='container mx-auto flex gap-4 flex-wrap items-center h-fit'>
            <Paper variant='outlined' className='flex flex-col justify-between items-center flex-1 p-2'>
                <div className='flex flex-row justify-end gap-2 w-full'>
                    <IconButton><AutorenewIcon /></IconButton>
                </div>
                <Divider className='my-2' />
                <div className='mx-4 w-full'>
                    <form noValidate className='gap-2'>
                        <div className='flex flex-row flex-wrap gap-3 w-full md:max-w-screen-sm mx-auto'>
                            <TextField
                                variant="outlined"
                                id="codebranche"
                                name ='codebranche'
                                select
                                label="Branche"
                                value={inputField.codebranche}
                                onChange={handleChange}
                                className='flex-1 my-2'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                
                                >
                                <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(branches && branches.length !==0)&& branches.map((option) => (
                                    <MenuItem key={option.codebranche} value={option.codebranche}>
                                    {option.libelle}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                variant="outlined"
                                id="codepartenaire"
                                name ='codepartenaire'
                                select
                                label="Partenaires"
                                value={inputField.codepartenaire&&inputField.codepartenaire}
                                onChange={handleChange}
                                className='flex-1 my-2'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                
                                >
                                <MenuItem key={0} value={""}>Aucun</MenuItem>
                                    
                                {(partenaires && partenaires.length !==0)&& partenaires.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                    {option.designation}
                                    </MenuItem>
                                ))}
                            </TextField>
                             
                          </div>
                          <div className='flex flex-row flex-wrap gap-3 w-full md:max-w-screen-sm mx-auto'>
                            <TextField
                                variant="outlined"
                                id="codereseau"
                                name ='codereseau'
                                select
                                label="Réseau de commercialisation"
                                value={inputField.codereseau&&inputField.codereseau}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className='flex-1 my-2'
                                >
                                 <MenuItem key={0} value={""}>Aucun</MenuItem>
                                    
                                {(reseaux && reseaux.length !==0)&& reseaux.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.libelle}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                variant="outlined"
                                id="codezone"
                                name ='codezone'
                                select
                                label="Zone/Departement"
                                value={inputField.codezone}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className='flex-1 my-2'
                                >
                                 <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(zones && zones.length !==0)&& zones.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.libellezone}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div className='flex flex-row flex-wrap gap-3 w-full md:max-w-screen-sm mx-auto'>
                            <TextField
                                variant="outlined"
                                id="codeequipe"
                                name ='codeequipe'
                                select
                                label="Equipe/Agence"
                                value={inputField.codeequipe&&inputField.codeequipe}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className='flex-1 my-2'
                                >
                                   <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(equipes && equipes.length !==0)&& equipes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.libelleequipe}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <Divider className='my-2 mx-4' />
                        <div className='flex flex-row flex-wrap gap-3 w-full md:max-w-screen-sm mx-auto'>
                            {modepaiement&&(
                                <TextField
                                variant="outlined"
                                id="modepaiement"
                                name ='modepaiement'
                                select
                                label="Mode de paiement"
                                value={inputField.modepaiement&&inputField.modepaiement}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className='flex-1 my-2'
                                >
                                   <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(modepaiement && modepaiement.length !==0)&& modepaiement.map((option) => (
                                    <MenuItem key={option.CodePaiement} value={option.CodePaiement}>
                                    {option.MonLibelle}
                                    </MenuItem>
                                ))}
                            </TextField>
                            )}
                            
                        </div>
                        <Divider className='my-2 mx-4' />  
                       
                        <div className='flex flex-row flex-wrap gap-3 w-full md:max-w-screen-sm mx-auto'>
                            {produits&&(
                                <TextField
                                variant="outlined"
                                id="produit"
                                name ='produit'
                                select
                                label="Produit"
                                value={inputField.produit&&inputField.produit}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className='flex-1 my-2'
                                >
                                   <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(produits && produits.length !==0)&& produits.map((option) => (
                                    <MenuItem key={produits.CodeProduit} value={option.CodeProduit}>
                                    {option.MonLibelle}
                                    </MenuItem>
                                ))}
                            </TextField>
                            )}
                            {(formules && formules.length !== 0)&&<FormControl style={{ marginTop: 20, marginBottom: 10,width:'100%' }} >
                                    <InputLabel variant='outlined' id="label-codeformule">Formule</InputLabel>
                                    <Select
                                         labelId="label-codeformule"
                                         id="codeproduitformule"
                                         multiple
                                         value={inputField.formules}
                                         onChange={handleChangeFormule}
                                         label='Formules'
                                         renderValue={(selected) => selected.join(', ')}
                                         variant="outlined"
                                         name='codeproduitformule'
                                         MenuProps={MenuProps}
                                    >
                                        <MenuItem value='' />
                                        {(formules.length !== 0) && formules.map((option) => (
                                            <MenuItem key={option.CodeProduitFormule} value={option.CodeProduitFormule}>
                                                <Checkbox checked={inputField.formules.indexOf(option.CodeProduitFormule) > -1} />
                                                <ListItemText primary={option.MonLibelle} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>}
                        </div>
                        <Divider className='my-2 mx-4' /> 
                        <div className='flex flex-row flex-wrap gap-3 w-full md:max-w-screen-sm mx-auto'>
                        <TextField
                        id="periodedu"
                        name="periodedu"
                        label="Période du"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className='flex-1 my-2'
                        value ={inputField['periodedu']||""}
                        onChange={handleChange}
                        variant='outlined'
                        style={{marginRight:20}}
                    />
                    <TextField
                        id="periodeau"
                        name="periodeau"
                        label="Au"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className='flex-1 my-2'
                        value ={inputField['periodeau']||""}
                        onChange={handleChange}
                        variant='outlined'
                        style={{marginRight:20}}
                    />
                        </div>
                        <Divider className='my-2 mx-4' /> 
                        <div className='flex flex-row flex-wrap gap-3 w-full md:max-w-screen-sm mx-auto'>
                            <Button className='flex-1 py-3 bg-secondary-main hover:bg-primary-main '>Evaluer</Button>
                        </div>
                        {(inputField.details && inputField.details.length!==0)&&<>
                        <div className='my-4'></div>
                        <h4 className='mx-5'>Détails de la factures</h4>
                        <Divider className=' mx-4' /> 
                        <div className='flex flex-row flex-wrap w-full md:max-w-screen-sm mx-auto'>

                            <div className='flex flex-row p-2 justify-between w-full'>
                                <div className='flex flex-1 flex-col justify-center items-center'>
                                    <span className='font-bold text-slate-600'>Nombre de lignes</span>
                                    <h1>12</h1>
                                </div>
                                <Divider orientation='vertical' className='mx-2' />
                                <div className='flex flex-1 flex-col justify-center items-center'>
                                    <span className='font-bold text-slate-600'>Montant</span>
                                    <h1>1 000 000 FCFA</h1>
                                </div>
                                
                            </div>
                            
                        </div>
                        <div className='flex flex-row flex-wrap w-full md:max-w-screen-sm mx-auto'>
                        <div className='flex flex-row w-full justify-evenly items-start gap-2 shadow-md py-4 rounded-sm my-4'>
                                <Button className='py-2 bg-secondary-main gap-3 hover:bg-black'><DescriptionIcon /> Aperçu</Button>
                                <Button className='py-2 bg-primary-main gap-3 hover:bg-black'><SaveIcon />Enregistrer</Button>
                                <Button className='py-2 bg-pink-600 gap-3 hover:bg-black'><SendIcon />Enregistrer et transmettre</Button>
                               
                            </div>
                        </div>
                        </>}
                        
                    </form>
                </div>
            </Paper>
        </div>
    </div>
  )
}

export default AddFacture