import React, {useState,useEffect} from 'react';
import { Link, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = (theme) => ({
    ...theme.spreadIt,
    
    root:{
      flexGrow: 1,
      backgroundColor: theme.palette.secondary.main,
      padding: 20,
      elevation:2,
      shadowColor: "#000000",
      shadowOpacity: 0.8,
      shadowRadius: 2,
      shadowOffset: {
        height: 1,
        width: 1
      }
    },
    footerText:{
        color:theme.palette.secondary.contrastText
    }
  });

  
function Footer(props) {
    const {classes} = props
    return (
      <Grid container justify='center' className={classes.root}>
          <Typography varriant='body' className={classes.footerText} >
                Copyright - 2019 LA LOYALE VIE Tous droits réservés - Site web :
            </Typography> 
          <Link to="/">www.laloyalevie.com</Link> 
        </Grid>
    );
}

export default withStyles(styles)(Footer)


