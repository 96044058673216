import React,{useEffect,useRef,useState} from 'react';
import { connect } from 'react-redux';
import {Field,getFormValues,reduxForm,formValueSelector,change} from 'redux-form';
import { Divider } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CustomDate from 'components/CustomDate/CustomDate'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber'
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Ask  from './ask'
const styles =theme=>({
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
      },
      rootExpan: {
        width: '100%',
      },
      heading: {
        fontSize:26,
        fontWeight: 'bold',
      },
      paper:{
          padding:'20px',
      },
      list: {
        width: '100%',
       padding:5
      },
     
      rootfab: {
        
      },
      extendedIcon: {
     
      },
    separateurv:{
        marginLeft:20,
        marginRight:20,
        marginTop:10,
        marginBottom:10
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      hTrait:{
        marginTop:20,
        marginBottom:20
    },
});
const useStyles = makeStyles(styles);
function ProfilEtatSante(props){
    const classes = useStyles();
    const { handleSubmit,handleBack,handleNext,handleAttente,steps,activeStep,dataForm } = props    
    const [detailsTab,setDetailsTab]= useState(null)

    useEffect(()=>{
        let  currentElt = []
        if(dataForm && dataForm.hasOwnProperty("fumezVous")) {
            if(dataForm["fumezVous"]==='Oui'){
                currentElt.push("fumezVous")
            }
        } 
        if(dataForm && dataForm.hasOwnProperty("estInfirme")) {
            if(dataForm["estSportif"]==='Oui'){
                currentElt.push("estInfirme")
            } 
        } 
        if(dataForm && dataForm.hasOwnProperty("estEnArretTravail")) {
            if(dataForm["estEnArretTravail"]==='Oui'){
                currentElt.push("estEnArretTravail")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-1")) {
            if(dataForm["nature-1"]==='Oui'){
                currentElt.push("nature-1")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-2")) {
            if(dataForm["nature-2"]==='Oui'){
                currentElt.push("nature-2")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-3")) {
            if(dataForm["nature-3"]==='Oui'){
                currentElt.push("nature-3")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-4")) {
            if(dataForm["nature-4"]==='Oui'){
                currentElt.push("nature-4")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-5")) {
            if(dataForm["nature-5"]==='Oui'){
                currentElt.push("nature-5")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-7")) {
            if(dataForm["nature-7"]==='Oui'){
                currentElt.push("nature-7")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-8")) {
            if(dataForm["nature-8"]==='Oui'){
                currentElt.push("nature-8")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-9")) {
            if(dataForm["nature-9"]==='Oui'){
                currentElt.push("nature-9")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-10")) {
            if(dataForm["nature-10"]==='Oui'){
                currentElt.push("nature-10")
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-11")) {
            if(dataForm["nature-11"]==='Oui'){
                currentElt.push("nature-11")
            }
        }
        setDetailsTab(currentElt)
    },[dataForm])
    
    return (
        <form onSubmit={handleSubmit} style={{marginLeft:'auto', marginRight:'auto',backgroundColor:'#f6f6f6', padding:10}}>
                {['YKS_2008','YKE_2008','YKS_2018'].includes(dataForm.produit.CodeProduit)&&(<><Ask>
                    <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>L'assure a t'il été hospitalisé  au cours des ces 3 (trois) derniers mois ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="nature-1" name='nature-1' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                </Ask.Inner>
                </Ask>
                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Etes-vous sous traitement médical pour, ou souffrez-vous d'une ces maladies ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Ask>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Diabète</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-3" name='nature-3' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                
                                </Ask.Card>
                        {(detailsTab && detailsTab.includes('nature-3'))&&<Ask.Details visible={true}>
                                <Ask.Card>
                                    <Ask.Group>
                                    <Ask.Title variant='body'>Suivez-vous un traitement ?</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                    <Field component={CustomRadio} id="nature-3-1" name="nature-3-1" formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]} row/>
                                    </Ask.Group>
                                </Ask.Card>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='body'>Quel est le type de diabète ?</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Field component={CustomRadio} id="nature-3-2" name="nature-3-2" formControlProps={{ fullWidth: false }} options={[{"label":"Type 1","value":"Type 1"},{"label":"Type 2","value":"Type 2"}]} row />
                                    </Ask.Group>
                                </Ask.Card>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='body'>Le diagnostic de cette maladie date de quand ? </Ask.Title>
                                        <Ask.SmallText>(Renseigner uniquement l’année)</Ask.SmallText>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Field component={CustomInput} id="nature-3-3" name="nature-3-3" formControlProps={{ fullWidth: false }} type='number' variant="outlined"/>
                                    </Ask.Group>
                                </Ask.Card>
                                <Ask.Card> <Ask.Group><Ask.Title variant='body'>Votre dernière consultation chez le médecin date de quand ? </Ask.Title> </Ask.Group> <Ask.Group><Field component={CustomDate} id="nature-7-4" name="nature-7-4" formControlProps={{ fullWidth: false }} InputProps={{type:'date'}} variant="outlined"/> </Ask.Group></Ask.Card>
                                <Ask.Card> <Ask.Group><Ask.Title variant='body'>A combien était la dernière prise </Ask.Title> </Ask.Group> <Ask.Group><Field component={CustomInput} id="nature-7-5" name="nature-7-5" formControlProps={{ fullWidth: false }} type='number' variant="outlined"/> </Ask.Group></Ask.Card>
                            </Ask.Details>}
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Hypertension artérielle</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-4" name='nature-4' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                            
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Drépanocytose</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-5" name='nature-5' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                            
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Cirrhose de foie</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-6" name='nature-6' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Affections pulmonaires</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-7" name='nature-7' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                            
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Cancer</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-8" name='nature-8' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                            
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Anémie</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-9" name='nature-9' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                            
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Insuffisance rénale</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-10" name='nature-10' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                        
                        </Ask.Inner>
                        <Ask.Inner direction='column'>
                            <Ask.Card>
                                <Ask.Group>
                                    <Ask.Title variant='h5'>Avez-vous déjà été victime d’un AVC ?</Ask.Title>
                                </Ask.Group>
                                <Ask.Group>
                                    <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                    <Field component={CustomRadio} id="nature-11" name='nature-11' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                                
                            </Ask.Card>
                            
                        </Ask.Inner>
                    </Ask>
                </AccordionDetails>
            </Accordion>
            </>
            )}
            {['YKR_2021'].includes(dataForm.produit.CodeProduit)&&(<>
                <Ask>
                    <Ask.Inner direction='column'>
                        <Ask.Card>
                            <Ask.Group>
                                <Ask.Title variant='h5'>Avez-vous déjà été victime d'un accident ?</Ask.Title>
                            </Ask.Group>
                            <Ask.Group>
                                <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                <Field component={CustomRadio} id="nature-1" name='nature-1' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                            </Ask.Group>
                        </Ask.Card>
                    </Ask.Inner>
                    <Ask.Inner direction='column'>
                        <Ask.Card>
                            <Ask.Group>
                                <Ask.Title variant='h5'>Suivez-vous ou avez-vous suivi un traitement médical ces 6(six) derniers mois ?</Ask.Title>
                            </Ask.Group>
                            <Ask.Group>
                                <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                <Field component={CustomRadio} id="nature-2" name='nature-2' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                            </Ask.Group>
                            
                        </Ask.Card>
                    
                    </Ask.Inner>
                    <Ask.Inner direction='column'>
                        <Ask.Card>
                            <Ask.Group>
                                <Ask.Title variant='h5'>Avez-vous déjà subi une transfusion de sang ?</Ask.Title>
                            </Ask.Group>
                            <Ask.Group>
                                <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                <Field component={CustomRadio} id="nature-3" name='nature-3' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                            </Ask.Group>
                            
                        </Ask.Card>
                        
                    </Ask.Inner>
                    <Ask.Inner direction='column'>
                        <Ask.Card>
                            <Ask.Group>
                                <Ask.Title variant='h5'>Avez-vous fait recemment l'objet d'un test de depistage de l'hepatite B ?</Ask.Title>
                            </Ask.Group>
                            <Ask.Group>
                                <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                <Field component={CustomRadio} id="nature-4" name='nature-4' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                            </Ask.Group>
                            
                        </Ask.Card>
                        
                    </Ask.Inner>
                    <Ask.Inner direction='column'>
                        <Ask.Card>
                            <Ask.Group>
                                <Ask.Title variant='h5'>Avez-vous déjà subi des interventions chirurgicales ?</Ask.Title>
                            </Ask.Group>
                            <Ask.Group>
                                <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                <Field component={CustomRadio} id="nature-5" name='nature-5' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                            </Ask.Group>
                            
                        </Ask.Card>
                        
                    </Ask.Inner>
                    <Ask.Inner direction='column'>
                        <Ask.Card>
                            <Ask.Group>
                                <Ask.Title variant='h5'>Devez-vous subir une intervention chirurgicale ?</Ask.Title>
                            </Ask.Group>
                            <Ask.Group>
                                <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                <Field component={CustomRadio} id="nature-6" name='nature-6' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                            </Ask.Group>
                        </Ask.Card>
                    </Ask.Inner>
                </Ask>
                <Accordion expanded={true}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>Etes-vous sous traitement médical pour, ou souffrez-vous d'une ces maladies ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Ask>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Diabète</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-8" name='nature-8' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                               {(detailsTab && detailsTab.includes('nature-8'))&&<Ask.Details visible={true}>
                                    <Ask.Card>
                                        <Ask.Group>
                                        <Ask.Title variant='body'>Suivez-vous un traitement ?</Ask.Title>
                                        </Ask.Group>
                                        <Ask.Group>
                                        <Field component={CustomRadio} id="nature-8-1" name="nature-8-1" formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]} row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='body'>Quel est le type de diabète ?</Ask.Title>
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Field component={CustomRadio} id="nature-8-2" name="nature-8-2" formControlProps={{ fullWidth: false }} options={[{"label":"Type 1","value":"Type 1"},{"label":"Type 2","value":"Type 2"}]} row />
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='body'>Le diagnostic de cette maladie date de quand ? </Ask.Title>
                                            <Ask.SmallText>(Renseigner uniquement l’année)</Ask.SmallText>
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Field component={CustomInput} id="nature-8-3" name="nature-8-3" formControlProps={{ fullWidth: false }} type='number' variant="outlined"/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Ask.Card> <Ask.Group><Ask.Title variant='body'>Votre dernière consultation chez le médecin date de quand ? </Ask.Title> </Ask.Group> <Ask.Group><Field component={CustomDate} id="nature-7-4" name="nature-7-4" formControlProps={{ fullWidth: false }} InputProps={{type:'date'}} variant="outlined"/> </Ask.Group></Ask.Card>
                                    <Ask.Card> <Ask.Group><Ask.Title variant='body'>A combien était la dernière prise </Ask.Title> </Ask.Group> <Ask.Group><Field component={CustomInput} id="nature-7-5" name="nature-7-5" formControlProps={{ fullWidth: false }} type='number' variant="outlined"/> </Ask.Group></Ask.Card>
                                </Ask.Details>}
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Hypertension artérielle</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-9" name='nature-9' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Drépanocytose</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-10" name='nature-10' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Cirrhose de foie</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-11" name='nature-11' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Affections pulmonaires</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-12" name='nature-12' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Cancer</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-13" name='nature-13' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Anémie</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-14" name='nature-14' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Insuffisance rénale</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-15" name='nature-15' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                               
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Avez-vous déjà été victime d’un AVC ?</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-16" name='nature-16' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                        </Ask>
                    </AccordionDetails>
                </Accordion>
            </>)}
            {['CADENCE','PFA_IND','PFA_COL','CAD_EDUCPLUS'].includes(dataForm.produit.CodeProduit)&&(<>
                <Ask>
                    <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Quelle est votre taille ? </Ask.Title>
                        </Ask.Group>  
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv}/>
                            <Field component={CustomInputNumber} id="taille" name='taille' formControlProps={{fullWidth: false}}variant="outlined"/>
                            <Ask.SmallText variant='h5'>(CM)</Ask.SmallText>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Quel est votre poids ? </Ask.Title> 
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomInputNumber} id="poids" name='poids' formControlProps={{ fullWidth: false }} variant="outlined"/>
                            <Ask.SmallText variant='h5'>(KG)</Ask.SmallText>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Fumez-vous ? </Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="fumezVous" name='fumezVous' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    {(detailsTab && detailsTab.includes('fumezVous'))&&<Ask.Details>
                        <Ask.Card>
                            <Ask.Group>
                                <Ask.Title variant='h6'>Combien de cigarette par Jour  ? </Ask.Title>
                            </Ask.Group>
                            <Ask.Group>
                                <Field component={CustomInput} id="nbCigaretteJour" name="nbCigaretteJour" formControlProps={{ fullWidth: false }} type='number' variant="outlined"/>
                            </Ask.Group>
                        </Ask.Card>
                    </Ask.Details>}
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Buvez vous de l'alcool ? </Ask.Title>
                        </Ask.Group>
                        
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="buvezVous" name='buvezVous' formControlProps={{ fullWidth: false}} options={[{"label":"Pas du tout","value":"Non"},{"label":"A l'ocassion","value":"Partiel"},{"label":"Régulièrement (au moins une fois par semaine)","value":"Oui"}]} row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                </Ask.Inner>
               
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Etes-vous atteint d'une infirmité ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="estInfirme" name='estInfirme' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    {(detailsTab && detailsTab.includes('estInfirme'))&&<Ask.Details>
                        
                        <Ask.Card>
                            <Ask.Group><Ask.Title variant='body'>Nature de l'infirmité </Ask.Title></Ask.Group>
                            <Ask.Group><Field component={CustomInput} id="natureInfirmite, " name="natureInfirmite, " formControlProps={{ fullWidth: false }}  variant="outlined"/></Ask.Group>
                        </Ask.Card>
                    </Ask.Details>}
                </Ask.Inner>
                
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Êtes-vous en arrêt de travail ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="estEnArretTravail" name='estEnArretTravail' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    {(detailsTab && detailsTab.includes('estEnArretTravail'))&&<Ask.Details>
                        
                        <Ask.Card>
                            <Ask.Group><Ask.Title variant='body'>Pour quel motif êtes-vous en arret travail  ? </Ask.Title></Ask.Group>
                            <Ask.Group><Field component={CustomInput} id="motifArretTravail" name="motifArretTravail" formControlProps={{ fullWidth: false }}  variant="outlined"/></Ask.Group>
                        </Ask.Card>
                    </Ask.Details>}
                </Ask.Inner>


                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Avez-vous déjà été victime d'un accident ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="nature-1" name='nature-1' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>

                
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Quelles sont vos distractions<Ask.SmallText> (separer par une virgule)</Ask.SmallText> ? </Ask.Title>
                        </Ask.Group>
                        
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomInput}id="distraction" name="distraction" formControlProps={{ fullWidth: true}} variant="outlined"/>
                        </Ask.Group>
                        
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Suivez-vous ou avez-vous suivi un traitement médical ces 6(six) derniers mois ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="nature-2" name='nature-2' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                   
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Avez-vous déjà subi une transfusion de sang ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="nature-3" name='nature-3' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Avez-vous déjà subi des interventions chirurgicales ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="nature-4" name='nature-4' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Devez-vous subir une intervention chirurgicale ?</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="nature-5" name='nature-5' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                    </Ask.Inner>
                </Ask>
                <Accordion expanded={true}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography className={classes.heading}>Etes-vous sous traitement médical pour, ou souffrez-vous d'une ces maladies ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Ask>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Diabète</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-7" name='nature-7' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                               {(detailsTab && detailsTab.includes('nature-7'))&&<Ask.Details visible={true}>
                                    <Ask.Card>
                                        <Ask.Group>
                                        <Ask.Title variant='body'>Suivez-vous un traitement ?</Ask.Title>
                                        </Ask.Group>
                                        <Ask.Group>
                                        <Field component={CustomRadio} id="nature-7-1" name="nature-7-1" formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]} row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='body'>Quel est le type de diabète ?</Ask.Title>
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Field component={CustomRadio} id="nature-7-2" name="nature-7-2" formControlProps={{ fullWidth: false }} options={[{"label":"Type 1","value":"Type 1"},{"label":"Type 2","value":"Type 2"}]} row />
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='body'>Le diagnostic de cette maladie date de quand ? </Ask.Title>
                                            <Ask.SmallText>(Renseigner uniquement l’année)</Ask.SmallText>
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Field component={CustomInput} id="nature-7-3" name="nature-7-3" formControlProps={{ fullWidth: false }} type='number' variant="outlined"/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Ask.Card> <Ask.Group><Ask.Title variant='body'>Votre dernière consultation chez le médecin date de quand ? </Ask.Title> </Ask.Group> <Ask.Group><Field component={CustomDate} id="nature-7-4" name="nature-7-4" formControlProps={{ fullWidth: false }} InputProps={{type:'date'}} variant="outlined"/> </Ask.Group></Ask.Card>
                                    <Ask.Card> <Ask.Group><Ask.Title variant='body'>A combien était la dernière prise </Ask.Title> </Ask.Group> <Ask.Group><Field component={CustomInput} id="nature-7-5" name="nature-7-5" formControlProps={{ fullWidth: false }} type='number' variant="outlined"/> </Ask.Group></Ask.Card>
                                </Ask.Details>}
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Hypertension artérielle</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-8" name='nature-8' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Drépanocytose</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-9" name='nature-9' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Cirrhose de foie</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-10" name='nature-10' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Affections pulmonaires</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-11" name='nature-11' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Cancer</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-12" name='nature-12' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Anémie</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-13" name='nature-13' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Insuffisance rénale</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-14" name='nature-14' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>        
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Avez-vous déjà été victime d’un AVC ?</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio} id="nature-15" name='nature-15' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                        </Ask>
                    </AccordionDetails>
                </Accordion>
            </>)}
                

            <Divider />
                <div style ={{display:"flex",justifyContent:"space-between",padding:20}}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.btnback}
                        
                    >
                        Retour
                    </Button>
                    <Button
                        variant="contained"
                        type= 'submit' 
                        className={classes.btnnext}
                        large
                    >
                       CONTINUER ...
                    </Button>
                </div>

            </form>

    )
}
const selector = formValueSelector('adForm')
const mapStateToProps = (state) => {
    
    return {
        api: state.api,
        ui: state.UI,
        user:state.user,
        dataForm:getFormValues('adForm')(state),
    }
  };
const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm',field,data))
const mapActionsToProps = {
  updateField,
}
export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form:'adForm',destroyOnUnmount:false,})(ProfilEtatSante))