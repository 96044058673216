import React,{useEffect} from 'react';

import PivotTableUI from 'components/Pivot/SimplePivotTableUI';
import 'components/Pivot/pivottable.css';
import TableRenderers from 'components/Pivot/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'components/Pivot/PlotlyRenderers';

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {sortAs} from 'components/Pivot/Utilities';
import HeaderFonction from 'pages/Banking/Etats/components/HeaderFonction'
import {getdataSet} from 'redux/actions/apiActions'
import Paper from '@material-ui/core/Paper';
const PlotlyRenderers = createPlotlyRenderers(Plot);
class PivotTableUISmartWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {pivotState: props};
    }

    componentWillReceiveProps(nextProps) {
        this.setState({pivotState: nextProps});
    }

    render() {
       
        return (
            <GridContainer>
               
                <PivotTableUI
                    renderers={Object.assign(
                        {},
                        TableRenderers,
                        createPlotlyRenderers(Plot)
                    )}
                    {...this.state.pivotState}
                    onChange={s => this.setState({pivotState: s})}
                    unusedOrientationCutoff={Infinity}
                />
            </GridContainer>
        );
    }
}



 function ETTCaParConseiller(props) {
    const {handleCliqueFonction}=props
    const [state,setState] = React.useState(null)
    const [stategr,setStategr] = React.useState(null)
    useEffect(() => {
        getdataSet({}).then(resData=>{
            const dataSet = resData.dataSet
            let colonnes =[]
            const data=[]
            for(var k in dataSet){
                if(dataSet[k] instanceof Object){
                   
                    data.push(Object.values(dataSet[k]))  
                }
            }
            for (const [key, value] of Object.entries(dataSet[0])) {
                colonnes.push(key)
            }
            data.unshift(colonnes)
                setState({
                    mode: 'demo',
                    filename: 'Sample Dataset: Tips',
                    pivotState: {
                        data: data,
                        rows: ['Conseiller','Etape'],
                        cols: ['Produit'],
                        aggregatorName: 'Somme entier',
                        vals: ['Prime'],
                        rendererName: 'Tableau',
                        sorters: {
                            Meal: sortAs(['Lunch', 'Dinner']),
                            'Day of Week': sortAs([
                                'Thursday',
                                'Friday',
                                'Saturday',
                                'Sunday',
                            ]),
                        },
                        plotlyOptions: {width: 900, height: 500},
                        plotlyConfig: {},
                        tableOptions: {
                            clickCallback: function(e, value, filters, pivotData) {
                                var names = [];
                                pivotData.forEachMatchingRecord(filters, function(
                                    record
                                ) {
                                    names.push(record.Meal);
                                });
                                alert(names.join('\n'));
                            },
                        },
                    },
                });
                setStategr({
                    mode: 'demo',
                    filename: 'Etat de la production par produit',
                    pivotState: {
                        data: data,
                        rows: ['Produit'],
                        cols: ['Conseiller','Etape'],
                        aggregatorName: 'Somme entier',
                        vals: ['Prime'],
                        rendererName: 'Graphique en aires',
                        sorters: {
                            Meal: sortAs(['Lunch', 'Dinner']),
                            'Day of Week': sortAs([
                                'Thursday',
                                'Friday',
                                'Saturday',
                                'Sunday',
                            ]),
                        },
                        plotlyOptions: {width: 900, height: 500},
                        plotlyConfig: {},
                        tableOptions: {
                            clickCallback: function(e, value, filters, pivotData) {
                                var names = [];
                                pivotData.forEachMatchingRecord(filters, function(
                                    record
                                ) {
                                    names.push(record.Meal);
                                });
                                alert(names.join('\n'));
                            },
                        },
                    },
                })
        })
    }, [])
    

 
    return (
        <GridContainer>   
            <GridItem xs={12} sm={12} md={12} lg={12}>
            <HeaderFonction history={props.history} handleFonctionMini={handleCliqueFonction} />
        
            </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Paper> 
                      {state&&<PivotTableUISmartWrapper {...state.pivotState} />}
                      {stategr&&<PivotTableUISmartWrapper {...stategr.pivotState} />}
                  </Paper>
                   
            </GridItem>           
        </GridContainer>
        
    )
}

export default ETTCaParConseiller