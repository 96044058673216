import { Button, Checkbox, CircularProgress, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core'
import React,{useEffect, useState} from 'react'
import { getProduitFormules, getProduitAll } from 'redux/actions/apiActions'
import {getproduitBranche,addProduitBranche} from 'redux/actions/settingsAction'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustumModal from 'components/CustumModal';
import swal from 'sweetalert';
import MaterialTable,{MTableToolbar} from 'material-table';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckIcon from '@material-ui/icons/Check';
import { deleteProduitBranche } from 'redux/actions/settingsAction';
import { changeStateProduitBranche } from 'redux/actions/settingsAction';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import dayjs from 'dayjs';
import theme from 'util/theme';
import { green } from '@material-ui/core/colors';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { getUtilisateurNoDispatch } from 'redux/actions/settingsAction';
const UserBranche = ({branche,handleAssociate,...restProps}) => {
  const [searchSize,setSearchSize] = useState()
  const [usproduits,setUsproduits] = useState([])
  const [loading,setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [open,setOpen] = useState(false)
    const handleOpen =()=>{
        setOpen(true)
    }
    const handleClose =(e,reason)=>{
        if (reason && reason == "backdropClick") {
            return;
          }
        setOpen(false)
    }
    const [users,setUsers]=React.useState([])


    const resizeSearch =()=>{
      if (isWidthUp("md", restProps.width,true) || isWidthUp("lg", restProps.width,true)) {
        setSearchSize(850);
      } else {
        if(isWidthUp("sm", restProps.width,true)){
          setSearchSize(650);
        }else{
          setSearchSize(250);
        }
      }
    }
    useEffect(()=>{
      resizeSearch()
    },[])
    window.onresize = function(event) {
      resizeSearch()
    };

    const handleGetusers =()=>{
      setLoading(true)
      getUtilisateurNoDispatch({typ_membre:7}).then(datauser=>{
        if(datauser){
          setUsers(datauser)
          setLoading(false)
        }else{
          setLoading(false)
        }
      }).catch(err=>{
        console.log(err);
        setLoading(false)
      })
    }
    useEffect(()=>{
      handleGetusers()
    },[])
  const handleSelection =(rows)=>{
    setSelectedRow([...rows])
    if(rows && rows.length !==0){
      setSearchSize(450);
    }
  }

  return (
    <div>
        <Paper variant='outlined' className='bg-slate-100 flex flex-col py-4 justify-center max-w items-center max-w-screen-sm mx-auto my-4 border-dashed'>
            {!(usproduits && usproduits.length!==0)&& <span>Aucun utilisateur associé</span>}
            <div className='mt-2 flex flex-row gap-2'>
              <Button onClick={handleOpen} className='hover:bg-primary-main hover:text-white rounded-full shadow-md mx-3 space-x-2'><SupervisedUserCircleIcon />Associer un utilisateur</Button>
              <Button onClick={handleOpen} className='hover:bg-secondary-main hover:text-white rounded-full shadow-md mx-3 space-x-2'><PersonAddIcon />Créer un utilisateur</Button>
            </div>
            {/*associé un utilisateur*/}
            <CustumModal open={open} handleOpen={handleOpen} handleClose={handleClose} title="Associer un utilisateur" width='md' actions={<div className='py-2'>
                
              <Button onClick={()=>handleAssociate(selectedRow)} disabled={!(selectedRow || selectedRow?.length!==0)} className='bg-primary-main px-4 cursor-pointer text-white hover:bg-secondary-main border-0 shadow-md rounded-full'>Associer</Button>
              </div>}>
                <div>
                {loading?(
                 <div className=' flex justify-center items-center'>
                   <CircularProgress color="primary" />
               </div>
              ):(
                <MaterialTable
                columns={[
                  { title: '#', field: 'idmembre' },
                  { title: 'Nom', field: 'nom'},
                  { title: 'Prénom', field: 'prenom'},
                  { title: 'Date naissence', field: 'datenaissance',render:rowDtat=>(rowDtat.datenaissance?dayjs(rowDtat.datenaissance).format('DD/MM/YYYY'):"")},
                  { title: 'Email', field: 'email'},
                  { title: 'Telephone', field: 'telephone',render:rowData=>((rowData.telephone && rowData.telephone2)?(rowData.telephone+" / "+rowData.telephone2):rowData.telephone)},
                  { title: 'Profile', field: 'role'},
                ]}
                data={users}
                title=""
                onSelectionChange={(rows) =>handleSelection(rows)}
                localization={{
                  toolbar:{
                      searchPlaceholder:'Rechercher',
                      searchTooltip:'Rechercher',
                      nRowsSelected: '{0} Ligne(s)'
                  },
                  body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                  },
                  pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                  }
                }}

                components={{
                  Toolbar: props => (
                      <div style={{ backgroundColor: '#e8eaf5' }}>
                          <MTableToolbar {...props} />
                      </div>
                  )
              }}
                options={{
                  searchFieldAlignment:'left',
                  emptyRowsWhenPaging: false,
                  selection: true,
                  searchFieldVariant: "outlined",
                  searchFieldStyle: {
                    width: searchSize || 820,
                    backgroundColor:green[100],
                    height:50,
                    borderRadius:100,
                    fontWeight: 450,
                    border:'none',
                  },
                  actionsColumnIndex: -1,
                  rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow?.find(item=>item?.id === rowData.tableData.id)) ? '#FFF1EB' : '#FFF'
                    })
                }}
              />
              )}
                </div>

            </CustumModal>
            {/* créer un utilisateur un utilisateur*/}
        </Paper>
    </div>
  )
}

export default withWidth()(UserBranche)
