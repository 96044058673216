import React, { useReducer, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { reduxForm} from 'redux-form';
import { connect } from 'react-redux'
import { totProductionAgent } from 'redux/actions/apiActions'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
const useStyles = makeStyles(theme=>({
    cardState:{
        display:'flex',
        width:'100%',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    itcard:{
        display:'flex',
        flex:1,
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)"
        },
        cursor:'pointer'
    },
    numberState:{
        fontSize:35,
        color:theme.palette.secondary.main,
        textAlign:'center'
    },
    labelState:{
        color:'#666',
        fontWeight:'bold',
        textAlign:'center'
    },
}))

function PretStat(props) {
    const classes = useStyles();
    const { user: { privileges }, user: { credentials } } = props

    const [pretData, setPretData] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            pretEnAttente: 0,
            pretEnCotation: 0,
            pretValide: 0,
            pretMisEnPlace: 0,
            pretEchu: 0,
            pretRejeter: 0,
            pretRacheter: "",
            pretAnnuler: '',
            total: 0
        });
        useEffect(() => {
            totProductionAgent(credentials.idmembre).then(res => {
                if (res) {
                    const { general } = res
                    setPretData({
                        ['pretEnAttente']: general.enAttente.nbCotation,
                        ['pretEnCotation']: general.cotations.nbCotation,
                        ['pretValide']: general.valides.nbValide,
                        ['pretMisEnPlace']: general.enplaces.nbMisEnPlace,
                        ['pretEchu']: general.echus.nbEchu,
                        ['pretRacheter']: general.rachetes.nbreracheter,
                        ['pretRejeter']: general.rejetes.nbrejeter,
                        ['pretAnnuler']: general.annules.nbreannuler,
                        ['total']: general.all.total,
                    })
                }
            })
        }, [])

  return (
    <div style={{display:'flex'}}>
        <Card className={classes.itcard} style={{marginRight:15}}>
            <CardContent>
                <div className={classes.cardState}>
                    <span className={classes.numberState}>{pretData['total'] ||""}</span>
                    <span className={classes.labelState}>
                        Toutes les demandes
                    </span>
                </div>
            </CardContent>
        </Card>
        <Card className={classes.itcard} style={{marginRight:15}}>
            <CardContent>
                <div className={classes.cardState}>
                    <span className={classes.numberState}>{pretData['pretEnAttente'] ||""}</span>
                    <span className={classes.labelState}>
                        Cotations en attente
                    </span>
                </div>
            </CardContent>
        </Card>
        <Card className={classes.itcard}style={{marginRight:15}}>
            <CardContent>
                <div className={classes.cardState}>
                    <span className={classes.numberState}>{pretData['pretEnCotation'] ||""}</span>
                    <span className={classes.labelState}>
                        Cotations en traitement
                    </span>
                </div>
            </CardContent>
        </Card>
        <Card className={classes.itcard} style={{marginRight:15}}>
            <CardContent>
                <div className={classes.cardState}>
                    <span className={classes.numberState}>{pretData['pretValide'] ||""}</span>
                    <span className={classes.labelState}>
                        Cotations validées
                    </span>
                </div>
            </CardContent>
        </Card>
        <Card className={classes.itcard} style={{marginRight:15}}>
            <CardContent>
                <div className={classes.cardState}>
                    <span className={classes.numberState}>{pretData['pretMisEnPlace'] ||""}</span>
                    <span className={classes.labelState}>
                        Cotation avec prêt mis en place
                    </span>
                </div>
            </CardContent>
        </Card>
        <Card className={classes.itcard}>
            <CardContent>
                <div className={classes.cardState}>
                    <span className={classes.numberState}>{pretData['pretAnnuler'] ||""}</span>
                    <span className={classes.labelState}>
                        Cotations annulées
                    </span>
                </div>
            </CardContent>
        </Card>
                   
    </div>
  )
}

const mapPropsActions = {}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    ui: state.UI,
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(PretStat))
