import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomAlert from 'components/CustomAlert/CustomAlert'
import {updatePret} from 'redux/actions/apiActions'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {formatNumber} from 'application'
import Divider from '@material-ui/core/Divider';
import dayjs from 'dayjs';
import {Field,FieldArray,getFormValues,reduxForm,formValueSelector,change,registerField,reset} from 'redux-form';
import { connect,useDispatch } from 'react-redux';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    assureInfoRow:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-between'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    
  }));
  
 function ModalRapportMedecin ({data,ropen,...props}){
    const classes = useStyles();
    const [myAlerte, setMyAlerte]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    aMessage:null,
    aopen:false,
    amode:null,
    });
    const handleAlerte = (aMessage,aopen,amode)=>{
    setMyAlerte({
        ['message']:aMessage,
        ['open']:aopen,
        ['mode']:amode
        })
    }
   
    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      txsurprimemedical:null,
      txprimeunique:data.txprimeunique,
      txsurprimeold:data.txsurprime,
      primeold:data.primeht,
      prime:data.prime,
      tauxdef:null,
      fraismedicaux:null,
      fraisaccessoires:data.fraisaccessoires,
      txsurprime:null,
      montantsurprime:null,
      montantpret:data.montantpret
    });
  
    const handleClickOpen = () => {
      props.handleRopen(true);
    };
    
    const handleClose = (event,reason) => {
      if (reason === 'backdropClick') {
        return
      }
      props.handleRopen(false);
    };
    const [libType,setLibType] = useState(null)
    useEffect(()=>{
      if(data.typepret){
        switch (data.typepret) {
          case 'immobilier':
            setLibType('Prêt Immobilier')
            break;
          case 'consommation':
            setLibType('Prêt à la consommation')
            break;
          case 'decouvert':
            setLibType('Decouvert/Avance de trésorie')
            break;
          default:
            setLibType("Prêt à la consommation")
            break;
        }
      }
    },[data])
   
    const handleChange =(event)=>{
      const  {name,value}=event.currentTarget
      setInputField({
        [name]:value
      })
    }
    
    const handleRelaodPrime =()=>{
      const txsurprimedef = parseFloat(inputField.txsurprimeold)+parseFloat(inputField.txsurprimemedical)
     
      const tauxDef =parseFloat(inputField.txprimeunique)+(txsurprimedef*parseFloat(inputField.txprimeunique))/100
      const montantSuprimeMedi = inputField.primeold*(parseFloat(inputField.txsurprimemedical)/100)
      const newprime =Math.round(montantSuprimeMedi+parseInt(inputField.fraismedicaux)+parseInt(inputField.prime))
    
      setInputField({
        ["tauxdef"]:parseFloat(tauxDef).toFixed(4),
        ["montantsurprime"]:(inputField.montantsurprime+Math.round(montantSuprimeMedi)),
        ["prime"]:newprime,
        ["txsurprime"]:((parseFloat(inputField.txprimeunique)*parseFloat(txsurprimedef))/100).toFixed(4)
      })
    }
    const handleSave = ()=>{
        const mydata ={
          id:data.id,
          typeop:"SAI_RM",
          txsurprimemedical:inputField.txsurprimemedical,
          txsurprime:inputField.txsurprime,
          prime:inputField.prime,
          tauxdef:inputField.tauxdef,
          fraismedicaux:inputField.fraismedicaux,
          etat:2,
          modifiele:dayjs().format('YYYY-MM-DD'),
          encotation:false
        }
        props.updatePret(mydata).then(reponse=>{
          if(reponse){
            swal("Bien!","Opération effectée avec succès","success")
          
            handleClose()
          }else{
            swal("Oops!","Erreur lors de l'opération","error")
            
          }
        })
    }
    return(

      <Dialog
          open={ropen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title">
            <div className='flex flex-row justify-between items-center'>
              <Typography variant='h5'>Saisie resultat rapport medical</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              </div>
              
            </DialogTitle>
          <DialogContent>
          <Divider />
          <CustomAlert open={myAlerte.open} message={myAlerte.message} mode={myAlerte.mode} />
            <div className='flex flex-row justify-between bg-green-50'>
              <div className='flex flex-col'>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Ref :
                    </Typography>
                    <Typography variant="subtitle1">
                    {`\xa0 ${data.id}`}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Partenaire :
                    </Typography>
                    <Typography variant="subtitle1">
                    {`\xa0 ${data.utilisateur?.partenaire}`}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Emprunteur :
                    </Typography>
                    <Typography variant="subtitle1">
                        {`\xa0 ${data.adherent?.nom} \xa0 ${data.adherent?.prenom}`}
                    </Typography>
                  </Box>
              </div>
              <div className='flex flex-col'>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Type prêt:
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${libType ||""}`} </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Montant du pret (F CFA) :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${formatNumber(data.montantpret)}`} </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Durée (mois) :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${data.duree}`} </Typography>
                </Box>
                
              </div>
              <div className='flex flex-col'>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Prime emprunteur (XOF) :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${data.primeht}`} </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Prime YAKO (XOF)  :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${data.primeobseque}`} </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Surprime :
                    </Typography>
                    {data.surprime&&(
                      <Typography variant="subtitle1">{`\xa0 ${data.surprime}`} </Typography>
                    )}
                    
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title}>
                      Prime actuelle  (XOF) :
                  </Typography>
                  {data.primeemprunteur&&(<Typography variant="subtitle1">{`\xa0 ${formatNumber(data.primeemprunteur)}`} </Typography>)}
                </Box>
               
              </div>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}><Divider /></GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6} sm={4} md={4} lg={4}>
                <GridContainer>
                  <GridItem  xs={12} sm={12} md={12} lg={12}>
                      <CustomInput
                          label="Taux surpime RM (%)"
                          id="txsurprimemedical"
                          name="txsurprimemedical"
                          formControlProps={{
                              fullWidth: true
                          }}
                          variant="outlined"
                          InputProps={{
                              type:'Number'
                          }}
                        onChange ={handleChange}
                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomInput
                          label="Frais médicaux (XOF)"
                          id="fraismedicaux"
                          name="fraismedicaux"
                          formControlProps={{
                              fullWidth: true
                          }}
                          variant="outlined"
                          InputProps={{
                              type:'Number'
                          }}
                        onChange ={handleChange}
                      />
                  </GridItem>
                </GridContainer>
                
              </GridItem>
              <GridItem disabled={inputField.txsurprimemedical===null} xs={2} sm={3} md={3} lg={3} style={{marginTop:30}} onClick={handleRelaodPrime}>
                  <Button variant="outlined" className='py-4 bg-primary-main text-white hover:bg-secondary-main'>
                      Reévaluer la prime <AutorenewIcon />
                  </Button>
              </GridItem>
              <GridItem xs={6} sm={4} md={4} lg={4}>
                {inputField.prime&&(
                  <Box m={1}>
                    <Typography className={classes.title} style={{fontSize:16,color:'red'}}>
                    Prime définitive (XOF) :
                  </Typography>
                    <Typography style={{fontSize:26,color:'red'}} variant="subtitle1">{`\xa0 ${formatNumber(inputField.prime)}`} </Typography>
                  </Box> 
                
                )}
                {inputField.montantsurprime&&(
                    <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Montant surprime def.  :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${formatNumber(inputField.montantsurprime)}`} </Typography>
                </Box>
                )}
                {inputField.tauxdef&&(
                    <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Taux définitif (%)  :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${(inputField.tauxdef)}`} </Typography>
                </Box>
                )}
                
              </GridItem>
            </GridContainer>
          </DialogContent>
                  <Divider />
            <div className='flex flex-row justify-between items-center my-4 mx-3'>
            <Button onClick={handleClose} variant='outlined' className='text-white bg-secondary-main border-0'>
              Annuler
            </Button>
            <Button onClick={handleSave} className='text-white bg-primary-main hover:bg-secondary-main' size="large" disabled={inputField.prime===null} color="primary" variant='outlined' autoFocus>
              Valider
            </Button>
            </div>

         
        </Dialog>
    )
  }
  const mapStateToProps = (state) => ({
    api: state.api,
  });
const mapActionsToProps = {
  updatePret
}
  
export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form:'addPret',destroyOnUnmount:false,})(ModalRapportMedecin))