import styled from 'styled-components/macro'
import Paper from '@material-ui/core/Paper'
export const Container = styled(Paper)`
    display:flex;
    flex-direction:column;
    padding:10px;
    margin:20px;
    @media(max-width: 600px){
        padding:10px;
    }
`
export const Base = styled.div`
    display:flex;
    flex-direction:${({ direction }) => direction || 'row'};
    flex:${({ flex }) => flex};
    justify-content:${({ palign }) => palign || 'space-between'};
    align-items:${({ salign }) => salign || 'center'};
    margin-top:10px;
    @media(max-width: 600px){
        display:flex;
        flex:${({ flex }) => flex};
        flex-direction:${({ direction }) => direction || 'column'};
        justify-content:${({ palign }) => (palign || 'center')};
        align-items:${({ salign }) => (salign || 'space-between')};
    }
`
export const Inner = styled.div`
    display:flex;
    flex-direction:${({ direction }) => direction || 'column'};
    flex:${({ flex }) => flex};
    justify-content:${({ palign }) => palign || 'flex-start'};
    align-items:${({ salign }) => salign || 'center'};
    padding:5px;
`
export const Title = styled.h1`
    color:#666;
    font-size:16px;
    @media(max-width: 600px){
        font-size:12px;
    }
`
export const Group = styled.div`
    display: flex;
    flex:${({ flex }) => flex || null};
    flex-direction:${({ direction }) => direction || 'row'};
    justify-content:${({ palign }) => palign || 'flex-start'};
    align-items:${({ salign }) => salign || 'flex-start'};
`
export const Text = styled.span`
    color:#333;
    font-size:26px;
    font-weight:500;
    @media(max-width: 600px){
        font-size:16px;
    }
`
export const Label = styled.p`
    color:#666;
    font-size:15px;
    @media(max-width: 600px){
        font-size:10px;
    }
`

export const CButton = styled.button`
    display:flex;
    align-items:center;
    background-color:#fff;
    border:1px solid #f2f2f2;
    border-radius:30px;
    height:14px;
    &:hover {
        background-color:#E35F5B;
        border:none;
    };
`

export const DisButton = styled.button`
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#fff;
    border-top:1px solid #f2f2f2;
    height:12px;
    border-radius:0px;
    text-align: center;
    text-decoration: none;
    width:100%;
    &:hover {
        background-color:#E35F5B;
        border:none;
    };
`