import React, { useReducer,useEffect,useState } from 'react';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import MaterialTable,{MTableToolbar } from 'material-table';
import {connect,useDispatch} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {addZone,getBranche,getReseaux,getZonesReseau,getModules,getPrivileges} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import HeaderFonction from '../components/HeaderFonction'
import {verifRule} from 'application'
function ZoneList(props){
    const {settings:{reseaux},user:{credentials}}=props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [addopen, setAddopen] = React.useState(false);
    const  dispatch = useDispatch()
    const [zones,setZones] = React.useState([]);
    useEffect(() => {
       if(credentials){
        getZonesReseau(credentials.codereseau).then(resData=>{
          if(resData){
            setZones(resData)
          }
        })
       }
    }, [])
    
    const handleClickAddOpen = () => {
    setAddopen(true);
  };

  const handleCloseAdd = () => {
    setAddopen(false);
  };
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    codereseau:credentials.codereseau,
    codezone:'',
    libellezone:'',
    coderesponsable:'',
    nomresponsable:''
    });
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveZone =()=>{
    if(inputField.codereseau==='')return
    if(inputField.libellezone.trim()==='')return
    if(inputField.codezone.trim()==='')return
    props.addZone(inputField).then(data=>{
        if(data){
            handleCloseAdd()
        }else{
            return false
        }
    }).catch(err=>{
        console.log(err);
        return
    })
  }
  const handleFonctionMini =(newvaleur)=>{
    props.handleCliqueFonction(newvaleur)
  }
    return (
      <div style={{marginTop:75}}>
        <GridContainer>
           
          <GridItem xs={12} sm={12} md={12} lg={12}>
              <HeaderFonction handleFonctionMini={handleFonctionMini}/>
          </GridItem>
      
            <Dialog maxWidth='md' fullWidth open={addopen} onClose={handleCloseAdd} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Ajouter une zone /inspection/Departement</DialogTitle>
                <DialogContent>
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                    <CustomInput
                        required
                        autoFocus
                        variant="outlined"
                        id="codezone"
                        name='codezone'
                        label="code"
                        type="text"
                        value = {inputField.codezone}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8} lg={8}>
                    <CustomInput
                        autoFocus
                        required
                        variant="outlined"
                        id="libellezone"
                        name ='libellezone'
                        onChange={handleChange}
                        label="Nom de la zone / inspeection /département"
                        type="text"
                        value = {inputField.libellezone}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                    <CustomInput
                        required
                        autoFocus
                        variant="outlined"
                        id="coderesponsable"
                        name='coderesponsable'
                        label="code responsable"
                        type="text"
                        value = {inputField.coderesponsable}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8} lg={8}>
                    <CustomInput
                        autoFocus
                        required
                        variant="outlined"
                        id="nomresponsable"
                        name ='nomresponsable'
                        onChange={handleChange}
                        label="Nom responsable"
                        type="text"
                        value = {inputField.nomresponsable}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                    </GridItem>
                </GridContainer>     
                    <Divider />
            </DialogContent>
            <DialogActions>
          <Button onClick={handleCloseAdd} variant='outlined' color="secondary">Annuler</Button>
          <Button onClick={handleSaveZone} variant='outlined' color="primary">Ajouter</Button>
        </DialogActions>
      </Dialog>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
        columns={[
          { title: '#', field: 'id' },
          { title: 'Code', field: 'codezone'},
          { title: 'Libelle', field: 'libellezone'},
          { title: 'Code responsable', field: 'coderesponsable'},
          { title: 'Nom responsable.', field: 'nomresponsable'},
          { title: 'Reseau', field: 'codereseau'},
        ]}
        data={zones}
        title="Gestion des zones"
        localization={{
          toolbar:{
              searchPlaceholder:'Rechercher',
              searchTooltip:'Rechercher'
          },
          body: {
            emptyDataSourceMessage: 'Aucune donnée trouvée !'
          },
          pagination: {
            labelRowsSelect: 'Ligne(s)',
            labelDisplayedRows: '{count} sur {from}-{to}',
            firstTooltip: 'Prémière page',
            previousTooltip: 'Précédent',
            nextTooltip: 'Suivant',
            lastTooltip: 'Dernière page'
          }
        }}
        
        options={{
          exportButton: true,
          searchFieldStyle:{
            fontSize:18,
            width:'100%',
            height:50,
          },
          actionsColumnIndex: -1
        }}
        
        components={{
         Action: props=> {  
            return(  
              <CustomMenu 
                data={props.data}       
                />
              )
         },
         Toolbar: props => (
            <div>
             <MTableToolbar {...props} />
             {verifRule('zone','add')&&
              <div style={{padding: '0px 10px',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                <Button onClick={handleClickAddOpen} variant='outlined' color='primary' >
                    <Add style={{color:'#fff',fontSize: 30}} />
                </Button> 
              </div>
            }
            </div>
         )
        }}
        actions={[
          {
            icon: 'more_vert',
            tooltip: 'Actions',
            onClick: (event, rowData) =>alert("You want to add a new row"),
          },
        ]}
        
      />
            </GridItem>
    </GridContainer>
    </div>
    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user
  });
  const mapActionsToProps = {addZone}
export default connect(mapStateToProps,mapActionsToProps)(ZoneList)