import {
    SET_PRODUCTEUR_DATA,
    SET_PROD_CURRENT_DETAIL
  } from '../types';

  let initialState = {
    producteurAllData:null,
    currentDetails:null
  };

  export default function(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCTEUR_DATA:
            return {
              ...state,
              producteurAllData:action.payload,
        }
        case SET_PROD_CURRENT_DETAIL:
            return {
              ...state,
              currentDetails:action.payload,
        }
      default:
        return state;
    }
  }