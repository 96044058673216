import React, { Fragment,useState,useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { connect,useDispatch } from 'react-redux';
import { Grid,Box } from '@material-ui/core'
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'jspdf-autotable'
import dayjs from 'dayjs'
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";
import {
  saveValidationProposition,
  selectGarantiesContrat,
  selectAssuresContrat,
  selectBeneficairesContrat,
  validationProposition,
  validationPropositionAssure,
  validationPropositionBeneficaire,
  validationPropositionDocument
} from 'redux/actions/apiActions'
import {generateBulletinSouscription} from 'pages/Banking/Propositions/EditionBulletin' 
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import AlertSucces from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import {formatNumber} from 'application'
import ModalLoading from 'components/ModalLoading'
import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import RejetForm from 'pages/Producteur/Propositions/components/RejetForm'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DocumentSide from 'pages/Producteur/Propositions/components/DocumentSide'
import DoneIcon from '@material-ui/icons/Done';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import ValidationList from 'pages/Producteur/Propositions/components/ValidationList'
import  {validationPropositionSimple} from 'redux/actions/apiActions'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const styles =(theme)=>({
  root:{
    marginBottom:10,
    padding:10
  },
  btnNext:{
    backgroundColor:theme.palette.primary.main,
    height:40,
    width:150,
    borderRadius:20,
    color:theme.palette.primary.contrastText
  },
  assureInfoRow:{
    display:'flex',
    flexDirection:'row',
    color:"#FF0000"
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    marginBottom: 10,
  },
  chip: {
    margin: theme.spacing(1),
  },
  btnNext:{
    padding:20
  }
})


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
//button succès
const SuccesButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);
//bouton error
const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
   
  },
}))(Button);

function InfoSouscription (props){
  const dispatch = useDispatch()
  const {classes,
      user,api:{bordereauxValidation},
      UI:{loading},
       worker: {producteurAllData}
    } = props
   
    const [localData,setLocalData] =useState(null)
    const handleLocalData =(dt)=>{
      setLocalData(dt)
    }
    useEffect(()=>{
      handleLocalData(props.data)
    },[props.data])
    const [produit,setProduit] = React.useState([])
    const [assures,setAssures] = React.useState([])
    const [beneficiaires,setBeneficiaires]=React.useState([])
    const [garanties,setGaranties]=React.useState([])

  useEffect(() => {
    const produitAll = props.api.produits
    
    if(produitAll && produitAll.length!==0 && localData){
      setProduit(produitAll.filter(item=>item.CodeProduit ===localData.codeproduit)[0])
    }
    selectAssuresContrat(props.data.id).then(resAss=>{
      if(resAss){
        setAssures(resAss)
      }
    })
    selectBeneficairesContrat(props.data.id).then(resBen=>{
      if(resBen){
        setBeneficiaires(resBen)
      }
    })
    selectGarantiesContrat(props.data.id).then(resGar=>{
      if(resGar){
        setGaranties(resGar)
      }
    })
  }, [localData])

    const [succes, setSucces] = useState(false)
    const [newContrat,setNewContrat] = useState(null)
    const [myErrors,setMyErrors]= useState(null)
    const [openAlert, setOpenAlert] = React.useState(false);
    let history =useHistory() 
   
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenAlert(false);
      setMyErrors(null)
    };  
    const [open, setOpen] = React.useState(false);
    
    const handleClose = () => {
      setOpen(false);
      if(succes){
        const propData = newContrat.length!==0?newContrat[0]:{}
        generateBulletinSouscription(propData,user.credentials)
        props.handleNext()
      }
    };

    //alerte après savegarde
    const AlerteForSave =()=>{
      return(
        <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{
            succes?(<AlertSucces icon={<CheckIcon fontSize="inherit" />} severity="success">
              <Typography variant='h4' color={green[400]}>Enregistrement effectué avec succès.</Typography> </AlertSucces>):(
              <AlertSucces severity="error"><Typography variant='h4' color={red[400]}>Le système a rencontré une ereur lors de l'enrégistrement.</Typography></AlertSucces>
            )
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {succes?(
            <SuccesButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
            OK
          </SuccesButton>
          ):(
            <ErrorButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
            OK
          </ErrorButton>
          )}
          
        </DialogActions>
      </Dialog>
    </div>
  

      )
    }
  
    

    //ouverture et fermeture du formulaire de rejeter
    const [openRejet,setOpenRejet]=React.useState(false)
    const handleOpenRejet =()=>{
      setOpenRejet(true)
    }
    const handleCloseRejet =()=>{
      setOpenRejet(false)
    }

    //geston la liste du bordereau
    const [showBord,setShowBord]=useState(false)
    const handleShowBord =()=>{
      setShowBord(true)
    }
    const handleCloseBord =()=>{
      setShowBord(false)
    }
    const viderListe =()=>{
      dispatch({type:'SET_BORDEREAUX_VALIDATION',payload:[]})
    }
    const retirer=(id)=>{
      const newBordereau= bordereauxValidation.filter(item=>item.id!==id)
      dispatch({type:'SET_BORDEREAUX_VALIDATION',payload:newBordereau})
    } 
    const [activeStep, setActiveStep] = React.useState(1);
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    //contrat suivant
    const handleNextContrat =(indx,type)=>{
      const alldata = producteurAllData.propoAccepterNMDetails
      let currentData 
      let cIndex = indx
      
      if(alldata && alldata.length!==0){
        if(type=='c'){
          cIndex = alldata.findIndex(item=>item.id === localData.id)+1
        }
        if(alldata.length>cIndex){
          currentData = alldata[cIndex]
        }else{
          currentData = alldata[0]
        }
      }
      if(currentData){
        setLocalData(currentData)
      }
    }
    //validation sigle
    const validationPropositionSingle =()=>{
        const dataSend ={refs:[localData.id]}
       
        validationPropositionSimple(dataSend).then(res=>{
          if(res){
           
            let newDetails =[]
            const currentdetails = (producteurAllData && producteurAllData.hasOwnProperty('propoAccepterNMDetails'))?producteurAllData.propoAccepterNMDetails:null
            if(currentdetails){
              const newProducteurData = producteurAllData
              const currentIndex = currentdetails.findIndex(item=>item.id===localData.id)
              newDetails=currentdetails.filter(item=>item.id!==localData.id)
             
              newProducteurData.propoAccepterNMDetails = newDetails
              
              dispatch({type:"SET_PRODUCTEUR_DATA",payload:newProducteurData})
              dispatch({type:"SET_PROD_CURRENT_DETAIL",payload:newDetails})
              if(currentIndex){
                handleNextContrat(currentIndex,'')
              }else{
                props.unShow()
              }
            }
          }
        })
    }
    const [rapport,setRapport] =useState([])
    //téléchargement du rapport 
    const downloadRapport =()=>{
      if(rapport && rapport.length!==0){
          var content = "";
          content += "Date\t Description\tResultats\tRéférence\n";
          for (var i = 0; i < rapport.length; i += 1) {
              content += `${dayjs(rapport[i].date).format('DD-MM-YYYY  HH:MM:SS')}\t${rapport[i].libelle}\t${rapport[i].resultat}\t${rapport[i].ref}\n`;
              content += "\n";
          }
          let uri = "data:application/octet-stream," + encodeURIComponent(content);
          var link = document.createElement('a');
          link.setAttribute('href', uri);
          link.setAttribute('download', `rapport_validation_${dayjs().format('DDMMYYYYHHMM')}.txt`);
          link.click(); 
         // window.location.href = uri;
      }
    }
    const [migraEnd,setMigrateEnd] =useState(true)
    useEffect(() => {
      if(migraEnd){
        dispatch({type:'STOP_LOADING_UI'})
      }else{
        dispatch({type:'LOADING_UI'})
      }
    },[migraEnd])
    //validation proposition
    const validationPropositionGroup =(bordereau,propositions)=>{
      if(bordereau && propositions){
        const dataSend ={}
        const refs =[]
        dataSend.bordereau = bordereau.CodeLot
        propositions.forEach(element => {
          refs.push(element.id)
        });
        const currentRapport =[]
        dataSend.refs = refs
        setMigrateEnd(false)
        //récupération des donnéee
        handleNext()
        currentRapport.push({date:new Date(),libelle:"Début traitement des proposition ...",resultat:"",ref:""})
        validationProposition(dataSend).then(res=>{
          if(res){
            currentRapport.push({date:new Date(),libelle:"Fin traitement des propositions",resultat:"OK",ref:res})
            currentRapport.push({date:new Date(),libelle:"Début traitement assuré(e)s et garanties ...",resultat:"",ref:""})
            validationPropositionAssure(dataSend).then(assRes=>{
              if(assRes){
                currentRapport.push({date:new Date(),libelle:"Fin traitement assuré(e)s et garanties",resultat:"OK",ref:""})
                setMigrateEnd(true) 
              }else{
                currentRapport.push({date:new Date(),libelle:"Fin traitement assuré(e)s et garanties",resultat:"Erreur",ref:""})
                setMigrateEnd(true) 
              }
              setMigrateEnd(false)
              currentRapport.push({date:new Date(),libelle:"Début traitement bénéficiaires ...",resultat:"",ref:""})
              validationPropositionBeneficaire(dataSend).then(benRes=>{
                if(benRes){
                  currentRapport.push({date:new Date(),libelle:"Fin traitement bénéficiaires",resultat:"OK",ref:""})
                  setMigrateEnd(true)
                }else{
                  currentRapport.push({date:new Date(),libelle:"Fin traitement bénéficiaires",resultat:"Erreur",ref:""})
                  setMigrateEnd(true) 
                }
                setMigrateEnd(false)
                currentRapport.push({date:new Date(),libelle:"Début traitement documents ...",resultat:"",ref:""})
                validationPropositionDocument(dataSend).then(docRes=>{
                  if(docRes){
                    currentRapport.push({date:new Date(),libelle:"Fin traitement documents",resultat:"OK",ref:""})
                    setMigrateEnd(true) 
                  }else{
                    currentRapport.push({date:new Date(),libelle:"Fin traitement documents",resultat:`Erreur`,ref:""})
                    setMigrateEnd(true) 
                  }
                }).catch(err=>{
                  currentRapport.push({date:new Date(),libelle:"Fin traitement documents",resultat:`Erreur:${err}`,ref:""})
                  setMigrateEnd(true) 
                })
              }).catch(err=>{
                currentRapport.push({date:new Date(),libelle:"Fin traitement bénéficiaires",resultat:`Erreur:${err}`,ref:""})
                setMigrateEnd(true) 
              })
            }).catch(err=>{
              currentRapport.push({date:new Date(),libelle:"Fin traitement assuré(e)s et garanties",resultat:`Erreur:${err}`,ref:""})
              setMigrateEnd(true) 
            })
          }else{
            currentRapport.push({date:new Date(),libelle:"Fin traitement des propositions",resultat:`Erreur`,ref:""})
            
            setMigrateEnd(true) 
          }

        }).catch(err=>{
          currentRapport.push({date:new Date(),libelle:"Fin traitement des propositions",resultat:`Erreur:${err}`,ref:""})
          console.log(err);
          setMigrateEnd(true) 
        })

        if(currentRapport && currentRapport.length!==0){
          setRapport(currentRapport)
        }
      }else{

      }
    }
    //rendu de la fenêtre
    const handleFin =()=>{
      dispatch({type:'SET_BORDEREAUX_VALIDATION',payload:[]})
      setRapport([])
      history.push('/producteur/suivi-proposition')
    }

    return (
      <Fragment>
        <ValidationList 
          open={showBord} 
          data={bordereauxValidation} 
          handleClose={handleCloseBord} 
          handleOpen={handleShowBord} 
          viderListe={viderListe}
          retirer={retirer}
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
          handleReset={handleReset}
          loading={loading}
          rapport={rapport}
          handleValidate={validationPropositionGroup}
          downloadRapport={downloadRapport}
          handleFin={handleFin}
        />
           {AlerteForSave()}
        {myErrors&&(
          <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
          
        >
          <Alert onClose={handleCloseAlert} severity={myErrors.type}>
            {myErrors.message}
        </Alert>
        </Snackbar>
        )}
        {!loading? (
          <>
            {localData&&<RejetForm  ficheData={localData} open={openRejet} handleClose={handleCloseRejet} handleOpen={handleOpenRejet} />}
            <Card>
            <CardHeader
        
              title={`FICHE DE PROPOSITION N° : ${(localData && localData.hasOwnProperty('id'))&&localData.id} - ${(localData && localData.hasOwnProperty('codeproduit'))&&localData.codeproduit}`}
              subheader={`DU ${(localData && localData.hasOwnProperty("saisiele"))&&localData.saisiele?dayjs(localData.saisiele).format('DD/MM/YYYY'):""}`}
            />
            </Card>
            
            <Grid container direction="row" justify="space-between" alignItems="center">        
             
                <Button type="button"  onClick={props.unShow}>
                  Révenir à la liste
                </Button>
               
            </Grid>
         <Divider component="hr" className={classes.hTrait} style={{marginBottom:20}}/>
            <GridContainer>
              <GridItem xs={12} sm={(props.data.etape==2)?9:12} md={(props.data.etape==2)?9:12} lg={(props.data.etape==2)?9:12} >
                <Card className={classes.root} variant="outlined">

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    ADHERENT
                  </Typography>
                  <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Titre :
                        </Typography>
                        <Typography variant="subtitle1">
                            {localData&&localData.civilite}
                        </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Nom :
                        </Typography>
                        <Typography variant="subtitle1">
                            {localData&&localData.nom}
                        </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Prénoms :
                          </Typography>
                          <Typography variant="subtitle1"> {localData && localData.hasOwnProperty('prenom')?localData.prenom:""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Sexe :
                      </Typography>
                          <Typography variant="subtitle1">
                              {localData&&localData.sexe?localData.sexe:""}
                          </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Date de naissance :
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.datenaissance)?dayjs(localData.datenaissance).format('DD/MM/YYYY'):""}</Typography>
                      </Box>
                
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Lieu de naissance:
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('lieunaissance'))?localData.lieunaissance:""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Lieu de résidence:
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('lieuresidence'))?localData.lieuresidence:""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            N° {(localData && localData.hasOwnProperty('naturepiece'))?localData.naturepiece:" CNI"} :
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('numeropiece'))?localData.numeropiece:""}</Typography>
                      </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Profession :
                      </Typography>
                      <Typography variant="subtitle1">
                          {(localData && localData.hasOwnProperty('profession'))?localData.profession:""}
                      </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Secteur d'activité:
                        </Typography>
                        <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('employeur'))?localData.employeur:""}</Typography>
                    </Box>
                  
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Email :
                      </Typography>
                          <Typography variant="subtitle1">
                              {(localData && localData.hasOwnProperty('email'))?localData.email:""}
                      </Typography>
                    </Box>
                  
                
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Téléphone:
                        </Typography>
                        <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('telephone'))?(localData.telephone!=='null'?localData.telephone:"A préciser"):'A préciser'}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Mobile :
                        </Typography>
                        <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('mobile'))?(localData.mobile!=='null'?localData.mobile:"A préciser"):""} {(localData && localData.hasOwnProperty('mobile2'))?(localData.mobile2!=='null'?`/ ${localData.mobile2}`:""):""}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Adresse postale :
                        </Typography>
                        <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('bp'))?(localData.bp!=='null'?localData.bp:""):""} </Typography>
                    </Box>
                  </Grid> 
                  </Grid> 
                </CardContent>

                </Card> 
                <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    CONDITIONS DE PAIEMENT DE LA PRIME & PERIODICITE
                  </Typography>
                  <Grid container>
                      <Grid item sm={6} xs={12}>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Produit :
                        </Typography>
                            <Typography variant="subtitle1">
                                {(localData && localData.hasOwnProperty('produit'))&& localData.produit.MonLibelle}
                            </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Périodicité:
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('periodicite'))?(localData.periodicite!=='null'?localData.periodicite:""):""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Date Effet:
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty("dateeffet"))&&dayjs(localData.dateeffet).format('DD/MM/YYYY')}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Durée(en année):
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('duree'))?(localData.duree!=='null'?localData.duree:""):""}</Typography>
                      </Box>
                      
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Echéance paiement :
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('dateeffet') && localData.hasOwnProperty('duree'))&&dayjs(localData.dateeffet).add(localData.duree,'year').format('DD/MM/YYYY')}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Prime principale:
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('prime'))?formatNumber(localData.prime):""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Capital désiré :
                        </Typography>
                            <Typography variant="subtitle1">
                                {(localData && localData.hasOwnProperty('capital'))?formatNumber(parseInt(localData.capital)) :""}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      
                      <Box m={1} className={classes.assureInfoRow}>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Mode paiement:
                          </Typography>
                          <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('modepaiement'))?localData.modepaiement:""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Organisme payeur :
                        </Typography>
                        <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('organisme'))?localData.organisme:""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Agence :
                        </Typography>
                        <Typography variant="subtitle1"> {(localData && localData.hasOwnProperty('agence'))?localData.agence:""}</Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          N° Compte :
                        </Typography>
                        <Typography variant="subtitle1">
                            {(localData && localData.hasOwnProperty('numerocompte'))?localData.numerocompte:""}
                        </Typography>
                      </Box>
                      <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Conseiller :
                        </Typography>
                        <Typography variant="subtitle1">
                            {`${(localData && localData.hasOwnProperty('codeConseiller'))?(localData.codeConseiller!=='null'?localData.codeConseiller:""):""} - ${(localData && localData.hasOwnProperty('nomagent'))?(localData.nomagent!=='null'?localData.nomagent:""):""}`}
                        </Typography>
                      </Box>
                      </Grid>
                  </Grid>
                  {(localData && localData.hasOwnProperty('codeproduit'))&&localData.codeproduit==='PVRBNI'&&(<Grid container>
                        <Grid item sm={12} xs={12}><Typography variant='h4' color="textSecondary" >Rente</Typography></Grid>
                          <Grid item sm={4} xs={12}>
                            <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Montant de la rente :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {(localData && localData.hasOwnProperty('montantrente'))?formatNumber(localData.montantrente):''}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Périodicité de la rente :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {localData.periodiciterente==='M'?'Mensuelle':localData.periodiciterente==='T'?'Trimestrielle':localData.periodiciterente==='S'?'Semestrielle':localData.periodiciterente==='A'?'Annuelle':'Inconnu'}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item sm={4} xs={12}>
                            <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Durée de la rente en mois :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {(localData && localData.hasOwnProperty('dureerente'))&&localData.dureerente}
                              </Typography>
                            </Box>
                          </Grid>
                    
                  </Grid>)}
                  
                </CardContent>

                </Card> 
                <Card className={classes.root} variant="outlined">

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    ASSURE(E)S
                  </Typography>
                  <Grid container>
                      <Grid item sm={12} xs={12}>
                        {assures?(<TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>

                                  <TableCell>Nom</TableCell>
                                  <TableCell>Prénoms</TableCell>
                                  <TableCell align="center">Né(e) le</TableCell>
                                  <TableCell align="center">Lieu de naissance</TableCell>
                                  <TableCell align="center">Lieu de residence</TableCell>
                                  <TableCell align="right">Filiation</TableCell>
                                  <TableCell align="right">Garanties</TableCell>
                                  <TableCell align="center">Téléphone</TableCell>
                                  <TableCell align="right">Email</TableCell>
                                  <TableCell>N° CNI</TableCell>
                                  <TableCell align="right">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {assures.map(assure => (
                                  <TableRow key={assure.nomAssure}>

                                    <TableCell component="th" scope="row">
                                      {assure.nom}
                                    </TableCell>
                                    <TableCell align="right">{assure.prenom}</TableCell>
                                    <TableCell align="right">{dayjs(assure.datenaissance).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="right">{assure.lieunaissance}</TableCell>
                                    <TableCell align="right">{assure.lieuresidence}</TableCell>
                                    <TableCell align="right">{assure.filiation}</TableCell>
                                    <TableCell align="right"><>
                                      {

                                        garanties.length!==0 && garanties.map((garantie)=>((garantie.codeassure==assure.id)&&<Typography key={garantie.codeproduitgarantie}> - {garantie.monlibelle +'\n'}</Typography> ))
                                      }

                                      </>
                                      </TableCell>
                                    <TableCell align="right">{assure.telephone}</TableCell>
                                    <TableCell align="right">{assure.email}</TableCell>
                                    <TableCell component="th" scope="row">
                                      {assure.numeropiece}
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                        </TableContainer>):<Typography>Aucun aucun Assuré </Typography>}
                  
                      </Grid>
                  </Grid>
                  
                </CardContent>

                </Card>   
                <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    BENEFICIAIRE(S)
                  </Typography>
                  <Grid container>
                    <Grid container direction="row">
                          {(localData && localData.hasOwnProperty('beneficiaireauterme'))&&<Grid item sm={12} xs={12} md={12} lg={12}>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormLabel component="h5">Au terme du contrat</FormLabel>
                                <Paper component="ul" className={classes.chipRoot}>
                                  {(Array.isArray(localData.beneficiaireauterme))?(localData.beneficiaireauterme.map((option) => {
                                      let libelle =""
                                        switch (option) {
                                          case 'adherent':
                                            libelle="L'adherent"
                                            break;
                                          case 'conjoint':
                                            libelle="Le conjoint non divorcé, ni séparé de corps"
                                            break;
                                          case 'enfants':
                                            libelle="Les enfants nés et à naître"
                                            break;
                                          default:
                                            libelle="Autres"
                                            break;
                                        }
                                      return <li key={option}>
                                        <Chip
                                          icon={null}
                                          label={libelle}
                                          className={classes.chip}
                                        />
                                      </li>
                                  })):(<li>
                                    <Chip
                                      icon={null}
                                      label={localData.beneficiaireauterme}
                                      className={classes.chip}
                                    />
                                  </li>)}
                                </Paper>
                              </Grid>
                            </Grid>           
                          }
                          {(localData && localData.hasOwnProperty('beneficiaireaudeces'))&&<Grid item sm={12} xs={12} md={12} lg={12}>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <FormLabel component="h6">En cas de décès avant le terme</FormLabel>
                                <Paper component="ul" className={classes.chipRoot}>
                                  {(Array.isArray(localData.beneficiaireaudeces))?(localData.beneficiaireaudeces.map((option) => {
                                      let libelle =""
                                      switch (option) {
                                        case 'conjoint':
                                          libelle="Le conjoint non divorcé, ni séparé de corps"
                                          break;
                                        case 'enfants':
                                          libelle="Les enfants nés et à naître"
                                          break;
                                        default:
                                          libelle="Autres"
                                          break;
                                      }
                                      return <li key={option}>
                                        <Chip
                                          icon={null}
                                          label={libelle}
                                          className={classes.chip}
                                        />
                                      </li>
                                    })):(<li>
                                      <Chip
                                        icon={null}
                                        label={localData.beneficiaireaudeces}
                                        className={classes.chip}
                                      />
                                    </li>)}
                                </Paper>
                              </Grid>
                            </Grid>           
                          }
                      </Grid>              
                      <Grid item sm={12} xs={12} md={12} lg={12}>
                        {beneficiaires?(<TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Nom</TableCell>
                                  <TableCell>Prénoms</TableCell>
                                  <TableCell align="center">Né(e) le</TableCell>
                                  <TableCell align="center">Lieu de naissance</TableCell>
                                  <TableCell align="center">Lieu de residence</TableCell>
                                  <TableCell align="center">Filiation</TableCell>
                                  <TableCell align="center">Téléphone</TableCell>
                                  <TableCell align="left">Email</TableCell>
                                  <TableCell align="center">Taux (%)</TableCell>
                                  <TableCell align="center">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {beneficiaires.map(beneficiaire => (
                                  <TableRow key={beneficiaire.nomBeneficiaire}>
                                    <TableCell component="th" scope="row">
                                      {beneficiaire.nom}
                                    </TableCell>
                                    <TableCell align="center">{beneficiaire.prenom}</TableCell>
                                    <TableCell align="center">{dayjs(beneficiaire.datenaissance).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="center">{beneficiaire.lieunaissance}</TableCell>
                                    <TableCell align="center">{beneficiaire.lieuresidence}</TableCell>
                                    <TableCell align="center">{beneficiaire.filiation}</TableCell>
                                    <TableCell align="center">{beneficiaire.telephone}</TableCell>
                                    <TableCell align="center">{beneficiaire.email}</TableCell>
                                    <TableCell align="center">{beneficiaire.taux}</TableCell>
                                    <TableCell align="center"></TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                        </TableContainer>):<Typography>Aucun aucun bénéficiaire </Typography>}
                  
                      </Grid>
                  </Grid>
                  
                </CardContent>

                </Card>
                <Card className={classes.root} variant="outlined">

                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    GARANTIES(S) & PRIMES
                  </Typography>
                  <Grid container>
                      <Grid item sm={12} xs={12}>
                        {garanties?(<TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Nom</TableCell>
                                
                                  <TableCell align="center">Garantie</TableCell>
                                  {(produit && produit.length!==0 && produit.CodeProduit!=='PVRBNI')&&  <TableCell align="center">Capital</TableCell>}
                                  
                                  <TableCell align="center">Prime</TableCell>
                                  
                                
                                  <TableCell align="center">Périodicite</TableCell>
                                  <TableCell align="right">Total prime</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                              {assures.map((assure)=>{
                                let gars = garanties.filter(item=>item.codeassure = assure.id)
                                let rows =null 
                                  if(gars){
                                  return  gars.map((garantie)=>(
                                      <TableRow key={garantie.codeproduitgarantie}>
                                      <TableCell component="th" scope="row" align='left'>{`${assure.nom}  ${assure.prenom}`}</TableCell>
                                        
                                        <TableCell align="center">{garantie.monlibelle}</TableCell>
                                        {(produit && produit.length!==0 && produit.CodeProduit!=='PVRBNI')&&  <TableCell align="center">{garantie.capitalgarantie? formatNumber(parseInt(garantie.capitalgarantie)):''}</TableCell>}
                                        
                                        <TableCell align="center">{garantie.prime?formatNumber(parseInt(garantie.prime)):''}</TableCell>
                                        
                                        <TableCell align="center">{props.data.periodicite?(props.data.periodicite==='M'?'Mensuelle':(props.data.periodicite==='T'?'Trimestrielle':(props.data.periodicite==='S'?'Smestrielle':'Annuelle'))):''}</TableCell>
                                        <TableCell align="right">{garantie.primetotal? formatNumber(garantie.primetotal):''}</TableCell>
                                      </TableRow>
                      
                                    ))
                                  }
                                
                                })
                              }
                              <TableRow>
                                <TableCell colSpan={(produit && produit.length!==0 && produit.CodeProduit==='PVRBNI')?3:4} align='center'><Typography variant='h5'>TOTAL</Typography></TableCell>
                                <TableCell colSpan={(produit && produit.length!==0 && produit.CodeProduit==='PVRBNI')?1:2} align='right'><Typography variant='h5'>{props.data.primepricipale? formatNumber(props.data.primepricipale):''}</Typography></TableCell></TableRow>
                              </TableBody>
                            </Table>
                        </TableContainer>):<Typography>Aucun aucun garantie </Typography>}
                  
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </GridItem>
              
              {(props.data.etape ==2)&&(<GridItem xs={12} sm={3} md={3} lg={3} >
                <div style={{position:'fixed'}}>
                  <Paper >
                    <Grid container direction="column" style={{padding:10}}> 
                        {(!bordereauxValidation || bordereauxValidation.length===0)&&( <Button color='success' type="submit" onClick={validationPropositionSingle}>
                            Valider
                          <DoneIcon style={{marginLeft:10}} /> 
                        </Button>)}
                                  
                          <Button color='danger' type="submit" disabled={props.data.length==0} onClick={handleOpenRejet}>
                            Rejeter
                            <CancelScheduleSendIcon style={{marginLeft:10}} /> 
                          </Button>
                      </Grid>
                  </Paper>
                  <Button style={{margin:20,padding:20}} color='primary' onClick={()=>handleNextContrat(0,'c')}>
                      suivant
                      <NavigateNextIcon fontSize="large" style={{marginLeft:10}} /> 
                  </Button> 
                  <DocumentSide id={(localData && localData.hasOwnProperty('id'))&&localData.id} />
                </div>
                 
              </GridItem>)}
              
            </GridContainer>
            

            <Divider component="hr" className={classes.hTrait}/>
            
         
          </>
        ):(
          <ModalLoading myopen={loading} />
        )}
       
            
      </Fragment>
      
    )

}


const mapStateToProps = (state) => ({
  worker:state.worker,
  user:state.user,
  api: state.api,
  UI:state.UI,
 
})  
  
  const mapActionsToProps = {
    saveValidationProposition
  }
  InfoSouscription = withStyles(styles)(InfoSouscription)


  export default connect(mapStateToProps,mapActionsToProps)(InfoSouscription)
