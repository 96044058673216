import React, {  useState, useEffect, useCallback } from 'react'
import GridItem from 'components/Grid/GridItem'
import { reduxForm, change, registerField, getFormValues, reset } from 'redux-form';
import { connect} from 'react-redux';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { formatNumber } from 'application';
import { verifRule } from 'application'
import CheckClientSimulateur from 'pages/Banking/Propositions/components/CheckClientSimulateur'
import "./simulateur.css"
import PretSimmulation from 'pages/Banking/Prets/components/PretSimulation'
import MiniHeader from 'components/MiniHeader';
import MinToolsBar from 'components/ESMinToolsBar';
import { Box, IconButton, Tab, Tabs} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import CustomDate from 'components/CustomDate/CustomDate';
import dayjs from 'dayjs';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getGarantiesAssure } from 'redux/actions/apiActions';
import { ageAssure } from 'redux/actions/userActions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getPrimeGarantie } from 'redux/actions/apiActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { primePvrPremium } from 'application';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
const useStyles = makeStyles(theme => ({
    root: {
        display:'flex',
        flexDirection:'column',
        width:'75%',
        marginLeft:24,
        marginRight:24,
        [theme.breakpoints.down('sm')]: {
            width:'95%',
            marginLeft:10,
            marginRight:10,
          },
          [theme.breakpoints.up('md')]: {
            width:'85%',
            marginLeft:15,
            marginRight:15,
          },
          [theme.breakpoints.up('lg')]: {
            width:'75%',
          },
          marginLeft:'auto',
          marginRight:'auto',
        flexGrow: 1,
        marginTop:40
    },

    simulPaper:{
        display:"flex",
        flexDirection:'column',
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)",
        marginTop:20,
        marginBottom:20,
        marginLeft:'auto',
        marginRight:'auto',
        flex:1
    },

    paperheader:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between'
    },
    headerCorps:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        paddingLeft:20,
        paddingTop:20
    },
    prime:{
        fontSize:30,
        color:theme.palette.primary.main
    },
    paperCorps:{
        display: 'flex',
        justifyContent:'space-between',
        padding:20,
        marginTop:30
    },
    primeBox:{
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom:30, 
        flexDirection: 'column', 
        alignItems: 'center', 
        marginTop: 10
    },
    primeBoxpvr:{
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom:20, 
        flexDirection: 'column', 
        alignItems: 'center', 
        marginTop: 10
    },
    btnRun: {
       backgroundColor: theme.palette.primary.main,
        color:'white',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
    },
    itemCorps:{
        display:'flex',
        flexDirection: 'column', 
        alignItems: 'center', 
        marginLeft:20,
        marginRight:20
    },
    actionBox:{
        display:'flex'
    },
    buttonAdd: {
        marginBottom: 20,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.light,
        }
    },
    textItem: {
        marginRight: 10
    }

}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  
const RowSimulation = ({prod,rows,handleAction,credentials})=>{
    const classes =useStyles()
    return (<Paper className={classes.simulPaper}>
                <div className={classes.paperheader}>
                    <div className={classes.headerCorps}>
                        <div>
                            <Typography variant='h5'>{prod}</Typography>
                            <small> (Cliquez sur une option pour selectionner ou cliquez sur "Demarrer une souscription")</small>   
                        </div>
                        <IconButton>
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                    <Divider />
                </div>
                <div className={classes.paperCorps}>
                    {rows.map((rowData,indx,dat)=>(
                        <div style={{display:'flex'}}>
                            <div className={classes.itemCorps}>
                                <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                    <Typography component="h5" variant="h5" color="textPrimary">{rowData.option}</Typography>
                                    <div className={classes.primeBox}>
                                        <h6>{(credentials.codepartenaire === 'AFC') ? "Prime annuelle" : "Prime mensuelle"} </h6>
                                        <h2 className={classes.prime}>{rowData.prime ? formatNumber(rowData.prime) : 0} F CFA </h2>
                                    </div>
                                    <p className={classes.description}>{rowData.description ? ` ${rowData.description}` : ""}</p>
                                </div>
                                <Button className={classes.btnRun} onClick={(e) => handleAction(e, rowData, true)}>
                                    Demarrer une sourcription
                                </Button>
                            </div>
                        {(indx<(dat.length-1))&&<Divider style={{marginLeft:20,marginRight:20,marginBottom:20}} orientation='vertical'/>}
                        </div>
                    ))}
                </div>
            </Paper>)
}
function Simulateur(props) {
    const classes = useStyles();
    const [inputField, setInputField] = useState(
        {
            montantrente: '',
            periodiciterente: 'M',
            dureerente: '',
            duree: '',
            periodicite: '',
            CodeProduit: '',
            prime: 0,
            age:0
        }
    ) 
    const handleChange = (event) => {
        const { value, name } = event.target
        setInputField({...inputField,
            [name]: value
        })
    }
    const handleChangeSelect = (event, name) => {
        const { value } = event.target
        setInputField({...inputField,
            [name]: value
        })
    }

    //mise à jour du titre
    useEffect(() => {
        document.title = "ENOV - Simulateur"
    })

    const [myErrors, setMyErrors] = useState(null)
    const [openAlert, setOpenAlert] = React.useState(false);

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
        setMyErrors(null)
    };
    const [loadingui, setLoading] = React.useState(false)

    //evaluation de la prime PVR
    const EvaluerLaprime = (CodeProduit) => {
        const { dureerente, montantrente, periodiciterente, duree, periodicite } = inputField
        if (!dureerente || dureerente === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: 'Veuillez renseigner la durée de la rente' })
            handleOpenAlert()
            return false
        }
        if (!duree || duree < 5) {
            setLoading(false)
            setMyErrors({ type: 'warning', message: 'La durée du contrat doit être superieure ou égale à 5' })
            handleOpenAlert()
            return false
        }
        if (!montantrente || montantrente === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: 'Veuillez renseigner le montant de la rente' })
            handleOpenAlert()
            return false
        }
        if (!periodiciterente || periodiciterente === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: 'Veuillez renseigner la périodicite de la rente' })
            handleOpenAlert()
            return false
        }
        if (!duree || duree === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: 'Veuillez renseigner la duréé du contrat' })
            handleOpenAlert()
            return false
        }
        if (!periodicite || periodicite === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: 'Veuillez renseigner la périodicité du contrat' })
            handleOpenAlert()
            return false
        }
        //determination de la prime net 1
        const dureeCotisation = duree
        const i_c = 0.035; const fg_c = 0.05; const i_net_c = i_c * (1 - fg_c)
        let per_c = 12
        switch (periodicite) {
            case 'M':
                per_c = 12
                break;
            case 'T':
                per_c = 4
                break;
            case 'S':
                per_c = 2
                break;
            default:
                per_c = 1
                break;
        };
        const i_per = (Math.pow((1 + i_net_c), (1 / per_c))) - 1
        let v_c = 1 / (1 + i_per)
        let d_c = i_per / (1 + i_per)
        let valeur_a_c = (1 - Math.pow(v_c, (dureeCotisation * per_c))) / d_c

        const tx_fg = 0.04
        const tx_fa = 0.05
        const tx_investi = 1 - tx_fg - tx_fa
        const prime_net1 = (valeur_a_c * tx_investi)

        // determination prime rente
        const i_r = 0.035
        const fg_r = 0.05
        const i_net_r = i_r * (1 - fg_r)
        let per_r = 12
        switch (periodiciterente) {
            case 'M':
                per_r = 12
                break;
            case 'T':
                per_r = 4
                break;
            case 'S':
                per_r = 2
                break;
            default:
                per_r = 1
                break;
        };
        const i_per_r = (Math.pow((1 + i_net_r), (1 / per_r))) - 1
        const v_r = 1 / (1 + i_per_r)
        const d_r = i_per_r / (1 + i_per_r)
        const dureeRentea = parseInt(dureerente) / 12
        const dureeTotal = dureeRentea + parseInt(dureeCotisation)
        const valeur_a_r = (1 - Math.pow(v_r, ((dureeTotal - dureeCotisation) * per_r))) / d_r
        const vn = Math.pow(v_r, dureeCotisation * per_r)

        const tx_fg_r = (1 + 0.03)
        let primeRelle = (parseInt(montantrente) * tx_fg_r * valeur_a_r * vn) / prime_net1
        primeRelle = Math.round(primeRelle)
        setInputField({ ['prime']: primeRelle })

    }

    //nouvelle sourciption YK
    const souscrireYK = () => {
        if ((credentials.codepartenaire !== 'AFC')) {
            handleOpenSearchCli()
        } else {
            props.history.push('/bankass/nouvelle-proposition', { step: 1 });
        }

        // 
    }
    //PVR
    const nouvelleSouscription = () => {
        
        props.dispatch(reset('adForm'))

        if (!props.data || !props.data.hasOwnProperty('primePrincipale')) {
            props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
        }
        props.updateField('primePrincipale', inputField.prime)


        if (!props.data || !props.data.hasOwnProperty('totalPrime')) {
            props.dispatch(registerField("adForm", 'totalPrime', 'Field'));
        }
        props.updateField('totalPrime', (inputField.prime))

        if(tabsvalue === 0){
            if (!props.data || !props.data.hasOwnProperty('montantrente')) {
                props.dispatch(registerField("adForm", 'montantrente', 'Field'));
            }
            props.updateField('montantrente', inputField.montantrente)
    
            if (!props.data || !props.data.hasOwnProperty('periodiciterente')) {
                props.dispatch(registerField("adForm", 'periodiciterente', 'Field'));
            }
            props.updateField('periodiciterente', inputField.periodiciterente)
    
            if (!props.data || !props.data.hasOwnProperty('dureerente')) {
                props.dispatch(registerField("adForm", 'dureerente', 'Field'));
            }
            props.updateField('dureerente', inputField.dureerente)    
        }
        
        if (!props.data || !props.data.hasOwnProperty('duree')) {
            props.dispatch(registerField("adForm", 'duree', 'Field'));
        }
        props.updateField('duree', inputField.duree)

        if (!props.data || !props.data.hasOwnProperty('periodicite')) {
            props.dispatch(registerField("adForm", 'periodicite', 'Field'));
        }
        props.updateField('periodicite', inputField.periodicite);
        
        if(tabsvalue === 1 ||  tabsvalue === 2){
            if (!props.data || !props.data.hasOwnProperty('dateNaissanceSous')) {
                props.dispatch(registerField("adForm", 'dateNaissanceSous', 'Field'));
            }
            props.updateField('dateNaissanceSous', inputField.datenaissanceassure)

            if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
            }
            props.updateField('capitalSouscrit', inputField["capital-deces"])
        }

        if(tabsvalue === 1 && inputField['addsenior']){
            if (!props.data || !props.data.hasOwnProperty('garanties-SENIOR')) {
                props.dispatch(registerField("adForm", 'garanties-SENIOR', 'Field'));
            }
            props.updateField('garanties-SENIOR', inputField.primesenior)
        }
        
        if (!props.data || !props.data.hasOwnProperty('fraisadhesion')) {
            props.dispatch(registerField("adForm", 'fraisadhesion', 'Field'));
        }
        props.updateField('fraisadhesion', inputField['fraisadhesion'])

        const { produits } = props.api;

        if (produits && produits.lenght !== 0) {
            if(tabsvalue === 0){
                const currentProduit = produits.filter(item => item.CodeProduit === 'PVRBNI')[0]
                if (!props.data || !props.data.hasOwnProperty('produit')) {
                    props.dispatch(registerField("adForm", 'produit', 'Field'));
                }
                props.updateField('produit', currentProduit);
            }
            if(tabsvalue === 1){
             
                const currentProduit = produits.filter(item => (item.CodeProduit === 'PVRPRE' && item.CodeProduitFormule === 'PVRPRE_V1'))[0]
                if (!props.data || !props.data.hasOwnProperty('produit')) {
                    props.dispatch(registerField("adForm", 'produit', 'Field'));
                }
             
                props.updateField('produit', currentProduit);

                if (!props.data || !props.data.hasOwnProperty('details')) {
                    props.dispatch(registerField("adForm", 'details', 'Field'));
                }
                props.updateField('details', inputField['details'])
    
                if (!props.data || !props.data.hasOwnProperty('prime-epargne')) {
                    props.dispatch(registerField("adForm", 'prime-epargne', 'Field'));
                }
                props.updateField('prime-epargne', inputField['prime-epargne'])
        
                if (!props.data || !props.data.hasOwnProperty('prime-protectys')) {
                    props.dispatch(registerField("adForm", 'prime-protectys', 'Field'));
                }
                props.updateField('prime-epargne', inputField['prime-protectys'])
        
                if (!props.data || !props.data.hasOwnProperty('prime-peristance')) {
                    props.dispatch(registerField("adForm", 'prime-peristance', 'Field'));
                }
                props.updateField('prime-epargne', inputField['prime-peristance'])
        
                if (!props.data || !props.data.hasOwnProperty('cumul-eplus')) {
                    props.dispatch(registerField("adForm", 'cumul-eplus', 'Field'));
                }
                props.updateField('cumul-eplus', inputField['cumul-eplus'])
    
                if (!props.data || !props.data.hasOwnProperty('cumul-cotisation')) {
                    props.dispatch(registerField("adForm", 'cumul-cotisation', 'Field'));
                }
                props.updateField('cumul-cotisation', inputField['cumul-cotisation'])
    
                if (!props.data || !props.data.hasOwnProperty('capital-terme')) {
                    props.dispatch(registerField("adForm", 'capital-terme', 'Field'));
                }
                props.updateField('capital-terme', inputField['capital-terme'])
    
                if (!props.data || !props.data.hasOwnProperty('capital-terme-10')) {
                    props.dispatch(registerField("adForm", 'capital-terme-10', 'Field'));
                }
                props.updateField('capital-terme-10', inputField['capital-terme-10'])
    
                if (!props.data || !props.data.hasOwnProperty('bonus-persistance')) {
                    props.dispatch(registerField("adForm", 'bonus-persistance', 'Field'));
                }
                props.updateField('bonus-persistance', inputField['bonus-persistance'])
    
                if (!props.data || !props.data.hasOwnProperty('capital-deces')) {
                    props.dispatch(registerField("adForm", 'capital-deces', 'Field'));
                }
                props.updateField('capital-deces', inputField['capital-deces'])
    
                if (!props.data || !props.data.hasOwnProperty('epargne10')) {
                    props.dispatch(registerField("adForm", 'epargne10', 'Field'));
                }
                props.updateField('epargne10', inputField['epargne10'])
            }
            if(tabsvalue === 2){
                const currentProduit = produits.filter(item => (item.CodeProduit === 'YKE_2018' && item.CodeProduitFormule=== 'YKE_2018_V3'))[0]
                if (!props.data || !props.data.hasOwnProperty('produit')) {
                    props.dispatch(registerField("adForm", 'produit', 'Field'));
                }
      
                props.updateField('produit', currentProduit);
            }
            

            handleOpenSearchCli()
            //props.history.push('/bankass/esouscription/nouvelle-proposition',{step:1});
            
        }
    }

    const handleClientSelectYKL = (event, myrow, dr = false) => {
        const { produits } = props.api;
        if (produits) {
            const currentProduit = produits.filter(item => item.CodeProduit === 'YKL_2004')[0]
            if (!props.data || !props.data.hasOwnProperty('produit')) {
                props.dispatch(registerField("adForm", 'produit', 'Field'));
            }
            props.updateField('produit', currentProduit);

            if (myrow.prime == (credentials.codepartenaire === 'AFC') ? 17040 : 1490) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }

                props.updateField('capitalSouscrit', 430000);
            }
            if (!props.data || !props.data.hasOwnProperty('primePrincipale')) {
                props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
            }
            props.updateField('primePrincipale', myrow.prime);

            if (myrow.prime == (credentials.codepartenaire === 'AFC') ? 27360 : 2400) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 750000);
            }
            if (myrow.prime == (credentials.codepartenaire === 'AFC') ? 44460 : 3900) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 1400000);
            }
            if (dr) {
                souscrireYK()
            }
        }
    }

    const handleClientSelect = (event, myrow, dr = false) => {
        //renitialiser la selection
        const { produits } = props.api;
        if (produits) {
            const currentProduit = produits.filter(item => item.CodeProduit === 'YKV_2004')[0]
            if (!props.data || !props.data.hasOwnProperty('produit')) {
                props.dispatch(registerField("adForm", 'produit', 'Field'));
            }
            props.updateField('produit', currentProduit);

            if (!props.data || !props.data.hasOwnProperty('primePrincipale')) {
                props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
            }
            props.updateField('primePrincipale', myrow.prime);

            if (myrow.prime == (credentials.codepartenaire === 'AFC') ? 11340 : 990) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 430000);
            }
            if (myrow.prime == (credentials.codepartenaire === 'AFC') ? 17040 : 1490) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 750000);
            }
            if (myrow.prime == (credentials.codepartenaire === 'AFC') ? 28440 : 2490) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 1400000);
            }
        }
        if (dr) {
            souscrireYK()
        }
    }
    const { privileges, credentials } = props.user


    const optionData = {
        columns: [
            {
                title: 'id',
                field: 'libelle',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData => <div className="slist">
                    <div className="slist__corps">
                        <div>
                            <Typography component="h5" variant="h5" color="textPrimary">{rowData.option}</Typography>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                                display='block'
                            >
                                {rowData.description ? ` ${rowData.description}` : ""}
                            </Typography>
                        </div>
                        <div className="item__prime">
                            <div style={{ display: 'flex', width: 200, height: '50%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10, borderWidth: 1, borderStyle: 'dashed', }}>
                                <Typography varaint='h5' component='h5'>{(credentials.codepartenaire === 'AFC') ? "PRIME ANNUELLE" : "PRIME MENSUELLE"} : </Typography>
                                <Typography style={{ fontSize: 20 }} color='primary' varaint='h6' component='h6'>{rowData.prime ? formatNumber(rowData.prime) : 0} F CFA </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="slist__footer">
                        <Button variant='outlined' className={classes.buttonDetails} onClick={(e) => handleClientSelect(e, rowData, true)}>Demarrer une sourcription</Button>
                    </div>

                </div>,
            },
        ],
        rows: [{ "option": 'HONNEUR', "description": "Service de base :\nEnlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    100 000 FCFA \nForfait Cash:\n    30 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 11340 : 990 }, { "option": 'ELITE', "description": "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    300 000 FCFA \nForfait Cash:\n    100 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 17040 : 1490 }, { "option": 'PRESTIGE', "description": "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    500 000 FCFA \nForfait Cash:\n    500 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 28440 : 2490 }]
    }
    //YAKO ALLIANCE
    const optionDataYKL = {
        columns: [
            {
                title: 'id',
                field: 'libelle',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData => <div className="slist">
                    <div className="slist__corps">
                        <div>
                            <Typography component="h5" variant="h5" color="textPrimary">{rowData.option}</Typography>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                                display='block'
                            >
                                {rowData.description ? ` ${rowData.description}` : ""}
                            </Typography>
                        </div>
                        <div className="item__prime">
                            <div style={{ display: 'flex', width: 200, height: '50%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10, borderWidth: 1, borderStyle: 'dashed', }}>

                                <Typography varaint='h5' component='h5'>{(credentials.codepartenaire === 'AFC') ? "PRIME ANNUELLE" : "PRIME MENSUELLE"} : </Typography>
                                <Typography style={{ fontSize: 20 }} color='primary' varaint='h6' component='h6'>{rowData.prime ? formatNumber(rowData.prime) : 0} F CFA </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="slist__footer">
                        <Button variant='outlined' className={classes.buttonDetails} onClick={(e) => handleClientSelectYKL(e, rowData, true)}>Demarrer une sourcription</Button>
                    </div>

                </div>,
            },
        ],
        rows: [{ "option": 'HONNEUR', "description": "Service de base :\nEnlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    100 000 FCFA \nForfait Cash:\n    30 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 17040 : 1490 }, { "option": 'ELITE', "description": "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    300 000 FCFA \nForfait Cash:\n    100 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 27360 : 2400 }, { "option": 'PRESTIGE', "description": "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    500 000 FCFA \nForfait Cash:\n    500 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 44460 : 3900 }]
    }
    //YAKO FAMILLE
    const optionDataYKF = {
        columns: [
            {
                title: 'id',
                field: 'libelle',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData => <div className="slist">
                    <div className="slist__corps">
                        <div>
                            <Typography component="h5" variant="h5" color="textPrimary">{rowData.option}</Typography>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                                display='block'
                            >
                                {rowData.description ? ` ${rowData.description}` : ""}
                            </Typography>
                        </div>
                        <div className="item__prime">
                            <div style={{ display: 'flex', width: 200, height: '50%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginTop: 10, borderWidth: 1, borderStyle: 'dashed', }}>

                                <Typography varaint='h5' component='h5'>{(credentials.codepartenaire === 'AFC') ? "PRIME ANNUELLE" : "PRIME MENSUELLE"} : </Typography>
                                <Typography style={{ fontSize: 20 }} color='primary' varaint='h6' component='h6'>{rowData.prime ? formatNumber(rowData.prime) : 0} F CFA </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="slist__footer"><Button variant='outlined' className={classes.buttonDetails} onClick={(e) => handleClientSelectYKF(e, rowData, true)}>Demarrer une sourcription</Button></div>

                </div>,
            },
        ],
        rows: [{ "option": 'HONNEUR', "description": "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \n    Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 100 000 FCFA / Enfant : 75 000 FCFA\nForfait Cash:\n    Adulte : 30 000 FCFA / Enfant : 25 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 27360 : 2400 }, { "option": 'ELITE', "description": "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \n   Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 300 000 FCFA / Enfant: 150 000 FCFA \nForfait Cash:\n    Adulte : 100 000 FCFA / Enfant : 50 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 34200 : 3000 }, { "option": 'PRESTIGE', "description": "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \n    Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 500 000 FCFA / Enfant: 250 000 FCFA \nForfait Cash:\n    Adulte : 500 000 FCFA / Enfant : 250 000 FCFA", "prime": (credentials.codepartenaire === 'AFC') ? 53580 : 4700 }]
    }

    const handleClientSelectYKF = (event, myrow, dr = false) => {
        const { produits } = props.api;
        if (produits) {
            const currentProduit = produits.filter(item => item.CodeProduit === 'YKF_2004')[0]
            if (!props.data || !props.data.hasOwnProperty('produit')) {
                props.dispatch(registerField("adForm", 'produit', 'Field'));
            }
            props.updateField('produit', currentProduit);

            if (!props.data || !props.data.hasOwnProperty('primePrincipale')) {
                props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
            }
            props.updateField('primePrincipale', myrow.prime);
            if (myrow.prime == 2400) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 430000);
            }
            if (myrow.prime == 3000) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 750000);
            }
            if (myrow.prime == 4700) {
                if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                }
                props.updateField('capitalSouscrit', 1400000);
            }
            if (dr) {
                souscrireYK()
            }
        }
    }
    const [openSearchCli, setOpenSearchCli] = useState(false)
    const handleCloseSearchCli = () => {
        setOpenSearchCli(false)
    }
    const handleOpenSearchCli = () => {
        setOpenSearchCli(true)
    }
    const handleSetProduit =()=>{
        switch (tabsvalue) {
            case 0:
                setInputField({...inputField,'CodeProduit': 'PVRBNI'})
                break;
            case 1:
                setInputField({...inputField,'CodeProduit': 'PVRPRE'})
                break;
            case 2:
                setInputField({...inputField,'CodeProduit': 'YKE_2018'})
                break;
            default:
                break;
        }
    }
    const [tabsvalue, setTabsValue] = React.useState(0);

    const handleTabsChange = (event, newValue) => {
        setTabsValue(newValue);
        setInputField({...inputField,'isEvaluate':false})
        switch (newValue) {
            case 0:
                setInputField({...inputField,'CodeProduit': 'PVRBNI'})
                break;
            case 1:
                setInputField({...inputField,'CodeProduit': 'PVRPRE'})
                break;
            case 2:
                setInputField({...inputField,'CodeProduit': 'YKE_2018'})
                break;
            default:
                break;
        }
    };
    const [errors,setErrors] = useState([])

    const simulYakoEternite = async ()=>{
        //récupération du produit
        setLoading(true)
        setInputField({...inputField,isEvaluate:false})
        const produits = props.api.produits
        if(!inputField.datenaissanceassure || !dayjs(inputField.datenaissanceassure).isValid()){
            setErrors([...errors,{datenaissanceassure:"Date de naissance incorrect"}])
        }
        if(!inputField.capital || inputField.capital==0){
            setErrors([...errors,{capital:"Capital souscrit incorrect"}])
        }
        if(!inputField.periodicite || inputField.periodicite.trim()===''){
            setErrors([...errors,{periodicite:"Périodicité incorrect"}])
        }
        if(inputField.addsenior && (!inputField.primesenior || inputField.primesenior==0)){
            setErrors([...errors,{primesenior:"Périodicité incorrect"}])
        }
        
        if(produits && produits.length!==0){
            const myDate = new Date()
            const produitCourrant = produits.filter(item=>(item.CodeProduit ==='YKE_2018' && item.CodeProduitFormule==='YKE_2018_V3'))[0]
            if(produitCourrant){
                
                props.getGarantiesAssure(produitCourrant['CodeProduit'], ageAssure(dayjs(inputField.datenaissanceassure).format('YYYY-MM-DD'))).then(async (garanties)=>{
                    
                    if(garanties){
                        const allGaranties= await Promise.all(garanties.map(async (garantie)=>{
                        
                            const params={
                                CodeProduit:produitCourrant.CodeProduit,
                                CodeProduitFormule:produitCourrant.CodeProduitFormule,
                                codePeriodicite:inputField.periodicite,
                                duree:5,
                                capitalSouscrit:parseInt(inputField.capital),
                                age:ageAssure(dayjs(inputField.datenaissanceassure).format('YYYY-MM-DD')),
                                codeGarantie:garantie.codeproduitgarantie,
                                dateEffet:dayjs().add(1,'month').set('date', 1).format('YYYY-MM-DD')
                            }
                            
                            const infoxprime = await getPrimeGarantie(params).then(garPrime=>{
                                if(garPrime){
                                    return {
                                        prime: garPrime[0].Prime,
                                        capitalGarantie: garPrime[0].Capital,
                                        primeTotal:parseInt(garPrime[0].Prime) 
                                    }
                                }
                            })

                            return infoxprime&&{...garantie,...infoxprime}
                        }))
                        setLoading(false)
                        
                        if(allGaranties){
                            let maPrime =0
                            allGaranties.forEach(el=>{
                                if(el){
                                    maPrime +=el['primeTotal']
                                }
                            
                            })
                            inputField.addsenior && (maPrime = maPrime+inputField.primesenior)
                            setInputField({...inputField,'prime':maPrime,'fraisadhesion':3000,'isEvaluate':true})
                        }
                    }
                })
            }else{
                setErrors([...errors,{produit:"Erreur lors de la récupération du produit"}])
            }
        }else{
            setErrors([...errors,{produit:"Liste des produits incorrect pour ce partenaire"}])
        }
        if(errors && errors.length!==0){
            setLoading(false)
            console.log(errors);
            return
        }
        //récupération des garantie
        //récupération des prime par garantie
        
        //calcul de la prime

    }
    const [activeshow,setActiveshow] =useState('simulation')
    const handleChangeCheckBox =(e)=>{
        const {name,checked} = e.target
        setInputField({...inputField,[name]:checked})
    }
    const [pminpreium,setPminpreium] = useState(25000)
    const handleChangePminpremium = useCallback(()=>{
        if(inputField.periodicite && tabsvalue==1){
            switch (inputField.periodicite) {
                case 'T':
                    setPminpreium(75000)
                    break;
                case 'S':
                    setPminpreium(75000)
                    break;
                case 'A':
                    setPminpreium(300000)
                    break;
                default:
                    setPminpreium(25000)
                    break;
                
            }
        }
    },[inputField.periodicite])

    useEffect(()=>{
        if(inputField.CodeProduit === 'PVRPRE')
            handleChangePminpremium()
    },[inputField.periodicite])
    useEffect(()=>{
        handleSetProduit()
    },[])
    
    const handleEvaluerPrime = async ()=>{
        setLoading(true)
        setInputField({...inputField,'isEvaluate':false})
        const { CodeProduit, datenaissanceassure, prime, periodicite,ageretraite } = inputField
        
        if (!CodeProduit || CodeProduit === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: 'Veuillez selection une option de simulation' })
            handleOpenAlert()
            return false
        }
        if (!datenaissanceassure || datenaissanceassure === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: "Veuillez renseigner la date de naissance de l'assurée" })
            handleOpenAlert()
            return false
        }
        if (!prime || prime === '' || prime===0) {
            setLoading(false)
            setMyErrors({ type: 'warning', message: "Veuillez saisir la prime payer par le client" })
            handleOpenAlert()
            return false
        }
        if (!periodicite || periodicite === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: "Veuillez selectionner une périodicité" })
            handleOpenAlert()
            return false
        }
        if (!ageretraite || ageretraite === '') {
            setLoading(false)
            setMyErrors({ type: 'warning', message: "Veuillez selectionner l'age prévu de la retraite" })
            handleOpenAlert()
            return false
        }
        const detailprime = await primePvrPremium(inputField,credentials)
        const {primeseplus,primespersistance,cumuleplus,cumulcotisation,capitalterme,capitalterme10,bonuspersistance,duree,age,primeepargne10,primeepargne,primeperistance,capitaldeces,primeprotectys} = detailprime
            setInputField({...inputField,
                 'prime-epargne': primeepargne,
                 'prime-protectys':primeprotectys,
                 'prime-peristance':primeperistance,
                 'duree': duree,
                 'cumul-eplus':cumuleplus,
                 'cumul-cotisation':cumulcotisation,
                 'capital-terme':capitalterme,
                 'capital-terme-10':capitalterme10,
                 'bonus-persistance':bonuspersistance,
                 'capital-deces': capitaldeces,
                 'epargne10':primeepargne10,
                 'age':age,
                 'isEvaluate':true,
                 'tabeplus':primeseplus,
                 'tabpersistance':primespersistance,
                 'details':detailprime,
                 'fraisadhesion':5000
                })
             setLoading(false)
        }
        let condTabOrientation='vertical';
        if (isWidthUp("md", props.width,true) || isWidthUp("lg", props.width,true)) {
            condTabOrientation = "vertical";
          } else {
            condTabOrientation = "horizontal";
        }
        window.onresize = function(event) {
            if (isWidthUp("md", props.width,true) || isWidthUp("lg", props.width,true)) {
                condTabOrientation = "vertical";
              } else {
                condTabOrientation = "horizontal";
            }
        };
       
    return (
        <div className='flex-1'>
            {myErrors && (<Snackbar
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity={myErrors.type}>{myErrors.message}</Alert>
            </Snackbar>)
            }
            <CheckClientSimulateur history={props.history} open={openSearchCli} handleClose={handleCloseSearchCli} />
            <MiniHeader titre="Tarification" showbtn={false} plateforme='souscription'/>
            <MinToolsBar />

            <div className={classes.root}>
                <Paper variant='outlined' className='p-0 shadow-none rounded-md w-[100%]'>
                    <div className='p-0 m-0'>
                        <Button 
                            variant='outlined'
                            className={`py-4 py-0 m-0 ${(activeshow==='simulation') ?"bg-transparent text-primary-main":"bg-primary-main text-white" } shadow-none bordder-none  rounded-none focus:text-primary-main`}
                            onClick={()=>setActiveshow('simulation')}
                        >
                                Simulation
                        </Button>
                        <Button 
                            variant='outlined' 
                            className={`py-4 py-0 m-0 ${(activeshow==='tarifs') ?"bg-transparent text-primary-main":"bg-primary-main text-white"} bordder-none shadow-none rounded-none focus:text-primary-main`}
                            onClick={()=>setActiveshow('tarifs')}
                        >
                                Autres tarifs YAKO
                        </Button>
                    </div>

                    {(activeshow==='simulation')&&(<div className='p-4'>
                        {(['092'].includes(credentials.codepartenaire)) && 
                            <Paper elevation={3} className={classes.simulPaper}>
                                <div className='bg-white p-2'>
                                    <Typography variant='h5'>CHOISISSEZ UN PRODUIT</Typography>
                                    <ArrowDropDownIcon className='text-[60px] text-secondary-main' />
                                </div>
                               
                                <div className='flex mt-0 flex-col sm:flex-row'>
                                    <Tabs
                                        orientation={condTabOrientation}
                                        variant="scrollable"
                                        value={tabsvalue}
                                        onChange={handleTabsChange}
                                        aria-label="vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                        className='p-0 bg-primary-main'
                                        indicatorColor="none"
                                    >
                                        <Tab className={`p-2 py-4 ${tabsvalue===0?"bg-white text-primary-main":"text-white"}`} icon={<ChevronRightIcon style={{ fontSize: 60 }} />} iconPosition="end" label="PLAN VERT RETRAITE (PVR)" {...a11yProps(0)} />
                                        <Tab className={`p-2 py-4 ${tabsvalue===1?"bg-white text-primary-main":"text-white"}`} icon={<ChevronRightIcon style={{ fontSize: 60 }} />} iconPosition="end" label="PLAN VERT RETRAITE PREMIUM(PVR PRE)" {...a11yProps(1)} />
                                        <Tab className={`p-2 py-4 ${tabsvalue===2?"bg-white text-primary-main":"text-white"}`} icon={<ChevronRightIcon style={{ fontSize: 60 }} />} iconPosition="end" label="YAKO ETERNITE BNI" {...a11yProps(2)} />
                                        {/*<Tab className={`p-2 py-4 ${tabsvalue===3?"bg-white text-primary-main":"text-white"}`} icon={<ChevronRightIcon style={{ fontSize: 60 }} />} iconPosition="end" label="PRET PERSONNEl ZO'" {...a11yProps(3)} />*/}
                                    </Tabs>
                                    <TabPanel value={tabsvalue} index={0}>
                                        <div><Typography variant='h5'>SIMULATION PLAN VERS RETRAITE (PVR)</Typography></div>
                                        <Paper  variant='outlined' className='border-0'>
                                            <div className={classes.itemCorps}>
                                                <div className={classes.fieldItem}>
                                                    <CustomSimpleSelect
                                                        required
                                                        id="montantrente"
                                                        name="montantrente"
                                                        label="Montant de la rente"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        value={inputField.montantrente}
                                                        variant="outlined"
                                                        options={[50000, 75000, 100000, 150000, 200000, 250000, 500000, 1000000]}
                                                        onChange={handleChange}
                                                    />
            
                                                    <CustomSimpleSelect
                                                        required
                                                        id="dureerente"
                                                        name="dureerente"
                                                        label="Durée du service de la rente (mois)"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        value={inputField.dureerente}
                                                        options={[12, 24, 36]}
                                                        onChange={handleChange}
                                                    />
                                                    <Divider />
                                                    <CustomInput
                                                        required
                                                        id="duree"
                                                        name="duree"
                                                        label="Durée du contrat en années (minimum 5 ans)"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        value={inputField.duree}
                                                        type='numeric'
                                                        InputProps={{
                                                            inputProps: { min: 5 },
                                                        }}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />
            
                                                    <CustomRadio
                                                        name="periodicite"
                                                        id='periodicite'
                                                        onChange={handleChange}
                                                        label="Périodicité de la cotisation à payer"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        row
                                                        value={inputField.periodicite}
                                                        options={[
                                                            { "label": "Mois", "value": "M" },
                                                            { "label": "Trimestre", "value": "T" },
                                                            { "label": "Semestre", "value": "S" },
                                                            { "label": "Année", "value": "A" }
                                                        ]}
                                                    />
                                                </div>
                                                <div className="item__prime">
                                                    <div className={classes.primeBoxpvr}>
                                                        <h6>MONTANT PRIME: </h6>
                                                        <h1 className={classes.prime}>{inputField.prime ? formatNumber(parseInt(inputField.prime)) : 0} F CFA </h1>
                                                    </div>
                                                </div>
                                                <div className={classes.actionBox}>
                                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                                        <Button className={classes.buttonAdd} onClick={EvaluerLaprime}>
                                                            Evaluer la prime
                                                        </Button>
                                                    </GridItem>
                                                    {verifRule('prop', 'add', privileges) && (<GridItem xs={12} sm={12} md={6} lg={6}>
                                                        <Button className={classes.btnRun} disabled={inputField.prime === 0} onClick={nouvelleSouscription}>
                                                            Nouvelle souscription
                                                        </Button>
                                                    </GridItem>)}
                                                </div>
                                            </div>
                                        </Paper>
                                    </TabPanel>
            
                                    <TabPanel value={tabsvalue} index={1}>
                                            <div><Typography variant='h5'>SIMULATION PLAN VERS RETRAITE PREMIUM (PVRP)</Typography></div>
                                            <Paper variant='outlined' className='border-0'>
                                                <div className={classes.itemCorps}>
                                                    <div className={classes.fieldItem}>
                                                        <CustomDate
                                                            required
                                                            label="Date de naissance de l'assuré"
                                                            id="datenaissanceassure"
                                                            name="datenaissanceassure"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            InputProps={{
                                                                type: 'date',
                                                                inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                                                            }}
                                                            value={inputField.datenaissanceassure}
                                                            onChange={handleChange}
                                                            variant="outlined"
                                                        />
                                                        <CustomRadio
                                                            name="periodicite"
                                                            id='periodicite'
                                                            onChange={handleChange}
                                                            label="Périodicité de paiement de la prime"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            row
                                                            value={inputField.periodicite}
                                                            options={[
                                                                { "label": "Mois", "value": "M" },
                                                                { "label": "Trimestre", "value": "T" },
                                                                { "label": "Semestre", "value": "S" },
                                                                { "label": "Année", "value": "A" }
                                                            ]}
                                                        />
                                                        
                                                        <CustomInput
                                                            required
                                                            id="prime"
                                                            name="prime"
                                                            label="Montant de la prime"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            value={inputField.prime}
                                                            type='numeric'
                                                            InputProps={{
                                                                inputProps: { min: pminpreium },
                                                            }}
                                                            onChange={handleChange}
                                                            variant="outlined"
                                                        />
                                                        <CustomSimpleSelect
                                                            required
                                                            id="ageretraite"
                                                            name="ageretraite"
                                                            label="Age prévu pour la rétraite (année)"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            value={inputField.ageretraite}
                                                            options={[60, 65]}
                                                            onChange={handleChange}
                                                        />
                                                </div>
                                                {(inputField.hasOwnProperty('isEvaluate') &&inputField.isEvaluate)&& <Paper className='p-2'>          
                                                    <div className=' flex-1 mb-3'>
                                                        <div className='flex flex-row items-center gap-3'>
                                                            <div className='flex flex-col'>
                                                                <h6>PRIME MENSUELLE: </h6>
                                                                <h1 className={classes.prime}>{inputField.prime ? formatNumber(parseInt(inputField.prime)) : 0} F CFA </h1>
                                                            </div>
                                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                                            <div className='flex flex-col'>
                                                                <h6>FRAIS ADHESION: </h6>
                                                                <h1 className={classes.prime}>{formatNumber('5000')} F CFA </h1>
                                                            </div>
                                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                                            <div className='flex flex-col'>
                                                                <h6>DUREE DU CONTRAT: </h6>
                                                                <h1 className={classes.prime}>{inputField.duree ? formatNumber(parseInt(inputField.duree)) : 0} </h1>
                                                            </div>
                                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                                            <div className='flex flex-col '>
                                                                <h6>CAPITAL PROTECTYS: </h6>
                                                                <h1 className={classes.prime}>{inputField['capital-deces'] ? formatNumber(parseInt(inputField['capital-deces'])) : 0} F CFA</h1>
                                                            </div>
                                                        </div>
                                                        <Divider />
                                                    </div>

                                                    <div className='flex flex-row mb-4 space-x-3'>
                                                        <div>
                                                                <Paper variant='outlined' className='p-1 rounded-md'>
                                                                    <div className='p-2 bg-primary-main rounded-t-md'>
                                                                        <h6 className='text-white'>DECOMPOSITION DE LA COTISATION PERIODIQUE</h6>
                                                                        <span className='text-white flex flex-row items-center justify-center'>Avant 10 ans</span>
                                                                    </div> 
                                                                    <div>
                                                                    <TableContainer component={Paper}>
                                                                        <Table aria-label="simple table" size="small">
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell align="left">Prime Protectys</TableCell>
                                                                                    <TableCell align="center">{(inputField['prime-protectys'] && inputField['prime-peristance']) ? formatNumber(parseInt(inputField['prime-protectys'])+parseInt(inputField['prime-peristance'])) : 0}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell align="left">Prime Epargne Plus</TableCell>
                                                                                    <TableCell align="center">{inputField['epargne10'] ? formatNumber(parseInt(inputField['epargne10'])) : 0}</TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    </div>
                                                                </Paper>
                                                                <Paper variant='outlined' className='p-1 rounded-md'>
                                                                    <div className='p-2 bg-primary-main rounded-t-md'>
                                                                        <h6 className='text-white'>DECOMPOSITION DE LA COTISATION PERIODIQUE</h6>
                                                                        <span className='text-white flex flex-row items-center justify-center'>Après 10 ans</span>
                                                                    </div> 
                                                                    <div>
                                                                    <TableContainer component={Paper}>
                                                                        <Table aria-label="simple table" size="small">
                                                                            <TableBody>
                                                                                <TableRow>
                                                                                    <TableCell align="left">Prime Protectys</TableCell>
                                                                                    <TableCell align="center">{inputField['prime-protectys'] ? formatNumber(parseInt(inputField['prime-protectys'])) : 0}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow>
                                                                                    <TableCell align="left">Prime Epargne Plus</TableCell>
                                                                                    <TableCell align="center">{inputField['prime-epargne'] ? formatNumber(parseInt(inputField['prime-epargne'])) : 0}</TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    </div>
                                                                </Paper>
                                                        </div>
                                                        
                                                        <Paper variant='outlined' className='p-1 rounded-md'>
                                                            <div className='p-2 bg-primary-main rounded-t-md flex flex-row items-center'>
                                                                <h6 className='text-white'>EVALUATION AU TERME DU CONTRAT</h6>
                                                            </div> 
                                                            <div>
                                                            <TableContainer component={Paper}>
                                                                <Table aria-label="simple table">
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell align="left">Cumul des cotisations Epargne Plus</TableCell>
                                                                            <TableCell align="center">{inputField['cumul-eplus'] ? formatNumber(parseInt(inputField['cumul-eplus'])) : 0}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell align="left">Cumul des cotisations</TableCell>
                                                                            <TableCell align="center">{inputField['cumul-cotisation'] ? formatNumber(parseInt(inputField['cumul-cotisation'])) : 0}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className='bg-primary-main text-white'>
                                                                            <TableCell className='text-white' align="left">Capital constitué à terme</TableCell>
                                                                            <TableCell className='text-white' align="center">{inputField['capital-terme'] ? formatNumber(parseInt(inputField['capital-terme'])) : 0}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className='bg-primary-main text-white'>
                                                                            <TableCell className='text-white' align="left">Capital constitué à 10 ans</TableCell>
                                                                            <TableCell className='text-white' align="center">{inputField['capital-terme-10'] ? formatNumber(parseInt(inputField['capital-terme-10'])) : 0}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow className='bg-primary-main text-white'>
                                                                            <TableCell className='text-white' align="left">Bonus persistance à 10 ans</TableCell>
                                                                            <TableCell className='text-white' align="center">{inputField['bonus-persistance'] ? formatNumber(parseInt(inputField['bonus-persistance'])) : 0}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            </div>
                                                        </Paper>
                                                    </div>
                                                </Paper>   }
                                               
                                                <div className={classes.actionBox}>
                                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                                        <Button className={classes.buttonAdd} onClick={handleEvaluerPrime}>
                                                            Evaluer la prime
                                                        </Button>
                                                    </GridItem>
                                                    {verifRule('prop', 'add', privileges) && (<GridItem xs={12} sm={12} md={6} lg={6}>
                                                        <Button className={classes.btnRun} disabled={inputField.prime === 0} onClick={nouvelleSouscription}>
                                                            Nouvelle souscription
                                                        </Button>
                                                    </GridItem>)}
                                                </div>
                                            </div>
                                        </Paper>
                                    </TabPanel>

                                    <TabPanel value={tabsvalue} index={2}>
                                        <div><Typography variant='h5'>SIMULATION YAKO ETERNITE</Typography></div>
                                        <Paper variant='outlined' className='border-0'>
                                            <div className={classes.itemCorps}>
                                                <div className="flex flex-col justify-start">
                                                    <CustomDate
                                                        required
                                                        label="Date de naissance de l'assuré"
                                                        id="datenaissanceassure"
                                                        name="datenaissanceassure"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        InputProps={{
                                                            type: 'date',
                                                            inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                                                        }}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />
                                                    <CustomSimpleSelect
                                                        required
                                                        id="capital"
                                                        name="capital"
                                                        label="Calpital au terme"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        value={inputField.capital}
                                                        variant="outlined"
                                                        options={[300000,500000,750000,1000000,1250000,1500000,2000000,3000000,4000000,5000000]}
                                                        onChange={handleChange}
                                                    />
            
                                                    <Divider />
                                                    <CustomRadio
                                                        name="periodicite"
                                                        id='periodicite'
                                                        onChange={handleChange}
                                                        label="Périodicité de paiement de la prime"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        row
                                                        value={inputField.periodicite}
                                                        options={[
                                                            { "label": "Mensuelle", "value": "M" },
                                                            { "label": "Trimestrielle", "value": "T" },
                                                            { "label": "Semestrielle", "value": "S" },
                                                            { "label": "Annuelle", "value": "A" }
                                                        ]}
                                                    />
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={inputField.addsenior} onChange={handleChangeCheckBox} name="addsenior" />}
                                                            label="Appliquer la garantie sénior"
                                                        />
                                                    </FormGroup>
                                                    {inputField.addsenior&&<CustomInput
                                                        required
                                                        id="primesenior"
                                                        name="primesenior"
                                                        label="Montant de la prime sénior"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        value={inputField.primesenior}
                                                        type='numeric'
                                                        InputProps={{
                                                            inputProps: { min: 225 },
                                                        }}
                                                        onChange={handleChange}
                                                        variant="outlined"
                                                    />}
                                                    
                                                </div>
                                                {(inputField.hasOwnProperty('isEvaluate') &&inputField.isEvaluate)&&<div className=' flex-1 mb-3'>
                                                        <div className='flex flex-row items-center gap-3'>
                                                            <div className='flex flex-col'>
                                                                <h6>PRIME MENSUELLE: </h6>
                                                                <h1 className={classes.prime}>{inputField.prime ? formatNumber(parseInt(inputField.prime)) : 0} F CFA </h1>
                                                            </div>
                                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                                            <div className='flex flex-col'>
                                                                <h6>FRAIS ADHESION: </h6>
                                                                <h1 className={classes.prime}>{inputField.fraisadhesion ? formatNumber(parseInt(inputField.fraisadhesion)) : 0} F CFA </h1>
                                                            </div>
                                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                                            <div className='flex flex-col'>
                                                                <h6>DUREE DU CONTRAT: </h6>
                                                                <h1 className={classes.prime}>{inputField.duree ? formatNumber(parseInt(inputField.duree)) : 0} </h1>
                                                            </div>
                                                            <Divider orientation='vertical' style={{marginRight:10,marginLeft:10}} />
                                                            <div className='flex flex-col '>
                                                                <h6>CAPITAL : </h6>
                                                                <h1 className={classes.prime}>{inputField['capital'] ? formatNumber(parseInt(inputField['capital'])) : 0} F CFA</h1>
                                                            </div>
                                                        </div>
                                                        <Divider />
                                                </div>}
                                                
                                                <div className={classes.actionBox}>
                                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                                        <Button className={classes.buttonAdd} onClick={simulYakoEternite}>
                                                            Evaluer la prime
                                                        </Button>
                                                    </GridItem>
                                                    {verifRule('prop', 'add', privileges) && (<GridItem xs={12} sm={12} md={6} lg={6}>
                                                        <Button className={classes.btnRun} disabled={inputField.prime === 0} onClick={nouvelleSouscription}>
                                                            Nouvelle souscription
                                                        </Button>
                                                    </GridItem>)}
                                                </div>
                                            </div>
                                        </Paper>
                                    </TabPanel>
                                    <TabPanel value={tabsvalue} index={3}>
                                        <Typography>... Fonctionnalité non disposnible</Typography>
                                    </TabPanel>
                                </div> 
                            </Paper>   
                        }
                    </div>)}
                    {(activeshow==='tarifs')&&(
                        <div className='mx-auto p-4'>
                            <RowSimulation prod="YAKO AVANTAGE" rows={optionData.rows} handleAction={handleClientSelect} credentials={credentials}/>
                            <RowSimulation prod="YAKO ALLIANCE" rows={optionDataYKL.rows} handleAction={handleClientSelect} credentials={credentials}/>
                            <RowSimulation prod="YAKO FAMILLE" rows={optionDataYKF.rows} handleAction={handleClientSelect} credentials={credentials}/>
                        </div>
                    )}
            
                    {(['WF'].includes(credentials.codepartenaire)) &&
                        <Paper className={classes.simulPaper}>
                            <div className={classes.paperheader}>
                                <div className={classes.headerCorps}>
                                    <Typography   Typography variant='h5'>COTATION DE PRET</Typography>
                                    <IconButton>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </div>
                                <Divider />
                            </div>
                            <div className="item_contenu">
                                <PretSimmulation />
                            </div>
                        </Paper>
                    }
                </Paper>
            </div>
        </div>

    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField,
    getGarantiesAssure
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state)
});

export default withWidth()(connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(Simulateur)))
