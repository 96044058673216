import React, { Fragment,useState,useEffect } from 'react'
import {withStyles, createMuiTheme} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { Button } from '@material-ui/core';
import { Grid,Box } from '@material-ui/core'
import { Field, reduxForm,registerField,change } from 'redux-form'
import { connect } from 'react-redux';
const styles= (theme)=> ({
    MuiEngagementCard:{
        margin:10,
        transition:"0.3s",
        maxWidth: 300,
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
    },
    MuiMediaContent:{
        paddingTop: "56.25%",
        textAlign: "left",
        padding: muiBaseTheme.spacing(3),
        margin: `${muiBaseTheme.spacing(3)}px 0`
    },
    TextHeader:{
        fontWeight: "bold"
    },
    TextContent:{
        lineHeight: 1.8
    },
    btnSouscribe:{
        backgroundColor:theme.palette.primary.main,
        marginTop:10,
    }
})
  
const muiBaseTheme = createMuiTheme();
function ProduitCard (props) {

    const souscrire = async () =>{
        const {produit}= props
        //props.input.onChange(produit)
        if(!props.hasOwnProperty('produit')){
            props.dispatch(registerField("adForm",'produit','Field'));
           }
          props.updateField('produit',produit)
        props.handleSubmit()
    }

    const {produit,classes} = props
  
    return(
        
            <Grid item sm={4} xs={6} >
                <Card className={classes.MuiEngagementCard}>
                    <CardMedia
                        className={"MuiCardMedia-root"}
                        image={
                        "https://image.freepik.com/free-photo/river-foggy-mountains-landscape_1204-511.jpg"
                        }
                    />
                    <CardContent className={classes.MuiMediaContent}>
                        <Typography
                        className={classes.TextHeader}
                        variant={"h6"}
                        gutterBottom
                        >
                        {produit.MonLibelle}
                        </Typography>
                            <Typography
                            className={classes.TextContent}
                            variant={"caption"}
                            >
                        {produit.Description}
                        </Typography>
                        <Divider className={"MuiDivider-root"} light />
                        <Button className={classes.btnSouscribe} onClick={souscrire}>Souscrire</Button>
                    </CardContent>
                </Card>

                { /*<ModeAssure handleClose={handleClose} open={open} handleMode ={handleMode}/>*/}
            </Grid>

    )
}


const mapStateToProps = (state) => ({});
  
  const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
  const mapActionsToProps = {
    updateField
  }

ProduitCard= withStyles(styles)(ProduitCard)

export default connect( mapStateToProps,
    mapActionsToProps)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
  })(ProduitCard))

