import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import Button from "components/CustomButtons/Button.js";
import { Grid, Box, IconButton,Tooltip } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { formatNumber,ficheOperation,etatEncaissement } from 'application'
import dayjs from 'dayjs'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { 
  getSearchContratsAgent, 
  EtatCotisation,
  updateOperation
} from 'redux/actions/apiActions'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import DoneIcon from '@material-ui/icons/Done';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import DocumentSide from 'pages/Producteur/Propositions/components/DocumentSide'
import ListAltIcon from '@material-ui/icons/ListAlt';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
const useStyles = makeStyles(theme=>({
    root: {
    marginBottom: 10,
    padding: 10
    },

    loading: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonAddChild: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
buttonReset: {
    width: '100%',
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    '&:hover': {
        borderColor: theme.palette.secondary.main,
    }
},
buttonNewSearch: {
    width: '100%',
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    '&:hover': {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    }
},
media: {
    height: 140,
},
avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
},
pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
},
green: {
    color: '#fff',
    backgroundColor: green[500],
},
large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
},
dcard: {
    marginBottom: 10,
    paddingBottom: 10
},
paper: {
    marginBottom: 20,
    padding: 20
},
heading: {
    fontSize: 26,
    fontWeight: 'bold',
},
assureInfoRow: {
    display: 'flex',
    flexDirection: 'row'
},
btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  header:{
    display:'flex',
    justifyContent:'space-between'
  },
  hleft:{
    display:'flex',
    flex:0.5
  },
  hright:{
      display:'flex',
      flex:2,
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center'
  }
}))


function FicheResumeOperation(props) {
  const classes = useStyles();
  const {dataForm, handleUpdateOperation } = props
  const [contratInfos,setContratInfo] = useState(null)
  useEffect(()=>{
    if(dataForm && dataForm.hasOwnProperty('idproposition')){
      getSearchContratsAgent({idcontrat:dataForm['idproposition']}).then(res=>{
        setContratInfo(res['details'][0])
      })
    }
  },[dataForm])
  const  editEtatCotisation = ()=>{
    if(dataForm && dataForm.hasOwnProperty('idproposition')){
      EtatCotisation({ idContrat: dataForm['idproposition'] }).then(res => {
        if (res) {
          etatEncaissement(res)
        }
      })
    }
  }

  return (
    <div>
      
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.hleft}>
                <Tooltip title="Retour" placement="bottom-start">
                    <IconButton onClick={()=>props.handleCurrentView('LISTOP')} color="primary" aria-label="Retour">
                        <ArrowBackIcon fontSize='large' />
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.hright}>
              <Typography className={classes.heading}>{(dataForm['groupeoperation'] === 'SIN') ? 'FICHE DE DECLARATION DE SINIISTRE' : (dataForm['groupeoperation'] === 'PRES' ? 'FICHE DE DEMANDE DE PRESTATION' : 'FICHE DE DEMANDE DE MODIFICATION')}</Typography>
              <Typography variant='h6'>{dataForm['codeoperation']} / {dataForm['id']}</Typography>
            </div>
          </div>
            <Divider />
        </Paper>
        <GridContainer>
          <GridItem xs={12} sm={8} md={8} lg={8}>
            {contratInfos&&(
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant='h5'>CONTRATS</Typography>
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                #ID :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${dataForm['idproposition'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Code proposition :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['Codeproposition'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                N° police :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['CodePolice'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Date d'effet :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['DateEffet'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Date échéance :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['DateEcheance'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Capital :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['Capital'] ? formatNumber(parseInt(contratInfos['Capital']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Produit :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['Produit'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Adherent :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['Adherent'] || ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Prime :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['Prime'] ? formatNumber(parseInt(contratInfos['Prime']).toString()) : ""}`}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Nombre d'émission :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['NombreEmission'] ? formatNumber(parseInt(contratInfos['NombreEmission']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Montant d'émission :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['MontantEmission'] ? formatNumber(parseInt(contratInfos['MontantEmission']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Nombre d'encaissement :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['NombreEncaissement'] ? formatNumber(parseInt(contratInfos['NombreEncaissement']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Montant d'encaissement :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['MontantEncaissement'] ? formatNumber(parseInt(contratInfos['MontantEncaissement']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Nombre d'impayés :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['NombreImpayes'] ? formatNumber(parseInt(contratInfos['NombreImpayes']).toString()) : ""}`}
                            </Typography>
                        </Box>
                        <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Montant d'impayés :
                            </Typography>
                            <Typography variant="subtitle1">
                                {`\xa0 ${contratInfos['MontantImpayes'] ? formatNumber(parseInt(contratInfos['MontantImpayes']).toString()) : ""}`}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
          )}
          
          <Card className={classes.root} variant="outlined">
              <CardContent>
                  <Typography variant='h5'>OPERATION</Typography>
                  <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Type :
                              </Typography>
                              <Typography variant="h6">
                                  {`\xa0 ${dataForm['libelletypeoperation'] || ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Date opération :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dayjs(dataForm['dateoperation']).format('DD/MM/YYYY')}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Délai traitement :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['delaitraitement'] || ""} jour(s)`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Date fin traitement prévu :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['datefinprevu'] && dayjs(dataForm['datefinprevu']).format('DD/MM/YYYY')}`}
                              </Typography>
                          </Box>
                      </Grid>
                      {dataForm['groupeoperation'] === 'SIN' && <Grid item sm={6} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Nature du sinsitre :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['naturesinistre'] || ""} ${dataForm['decesaccidentel'] ? "Accidentel" : ""}`}

                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Cause du sinsitre:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['causesinistre'] || ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Date du sinsitre :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['datesinistre'] ? dayjs(dataForm['datesinistre']).format('DD/MM/YYYY') : ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Lieu du sinsitre :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['lieusinistre'] || ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Lieu de concervation :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['lieuconservcorps'] || ""}`}
                              </Typography>
                          </Box>
                      </Grid>}
                      
                  </Grid>
                  
                  <Divider />
                  {dataForm['groupeoperation'] === 'SIN' && <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Hunimation effectué ? :
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['hunimationok'] ? 'Oui' : 'Non'}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Date hunimation:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['dateinhumation'] ? dayjs(dataForm['dateinhumation']).format('DD/MM/YYYY') : ""}`}
                              </Typography>
                          </Box>

                      </Grid>
                      <Grid item sm={6} xs={12}>
                          <Box m={3} className={classes.assureInfoRow}></Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Lieu hunimation:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['lieuinhumation'] || ""}`}
                              </Typography>
                          </Box>
                      </Grid>
                  </Grid>}
                  <Divider />
                  {dataForm['groupeoperation'] === 'SIN' && <Grid container spacing={2}>
                      <Grid item sm={6} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Nom du defunt:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['nomdefunt'] || ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Prenom du defunt:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['prenomdefunt'] || ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Date de naissance:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['datenaissancedefunt'] ? dayjs(dataForm['datenaissancedefunt']).format('DD/MM/YYYY') : ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Lieu de naissance:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['lieunaissancedefunt'] || ""}`}
                              </Typography>
                          </Box>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Lieu de résidence:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['lieuresidencedefunt'] || ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Profession:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['professiondefunt'] || ""}`}
                              </Typography>
                          </Box>
                          <Box m={1} className={classes.assureInfoRow}>
                              <Typography className={classes.title} color="textSecondary" gutterBottom>
                                  Secteur d'activité:
                              </Typography>
                              <Typography variant="subtitle1">
                                  {`\xa0 ${dataForm['secteuractivite'] || ""}`}
                              </Typography>
                          </Box>
                      </Grid>
                  </Grid>}
              </CardContent>
          </Card>
          </GridItem>
          {!(['1,3,4'].includes(dataForm['etat']))&&<GridItem xs={12} sm={3} md={3} lg={3}>
                <div style={{position:'fixed'}}>
                <Paper style={{display:'flex',marginBottom:20}}>
                  <Tooltip title="Voir l'état de cotisation" placement="bottom-start">
                    <IconButton style={{display:'flex',justifyContent:'center',alignItems:'center'}} onClick={editEtatCotisation}>
                      <ListAltIcon style={{marginLeft:10,fontSize:30}} /> 
                    </IconButton> 
                  </Tooltip>  
                  <Divider style={{marginLeft:10,marginRight:10}} />
                  <Tooltip title="Accusé de reception" placement="bottom-start">
                    <IconButton style={{display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>ficheOperation(dataForm)}>
                      <ConfirmationNumberIcon style={{marginLeft:10,fontSize:30}} /> 
                    </IconButton> 
                  </Tooltip>  
                </Paper>
                  <Paper >
                    <Grid container direction="column" style={{padding:10}}> 
                        <Button color='success' type="submit" onClick={()=>handleUpdateOperation(dataForm,'AC')}>
                            Valider
                          <DoneIcon style={{marginLeft:10}} /> 
                        </Button>
                        
                          <Button color='danger' type="submit" disabled={props.dataForm.length==0} onClick={()=>handleUpdateOperation(dataForm,'RJ')}>
                            Rejeter
                            <CancelScheduleSendIcon style={{marginLeft:10}} /> 
                          </Button>
                      </Grid>
                  </Paper>
                  <DocumentSide id={(dataForm && dataForm.hasOwnProperty('id'))&&dataForm.id} mod='OP' />
                </div>
          </GridItem>}
        </GridContainer>
    </div>
  )
}

export default FicheResumeOperation