import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import {getEquipesZones,getZonesReseau,getRolesReseau,getReseauxNoDispatch,changerEquipeUser} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import dayjs from 'dayjs'
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertPage from 'components/AlertPage';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent:'center',
        alignItems:'center'
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

function ChangerEquipeUser(props){
    const classes = useStyles();
    //intialisation des élement du state
    const {userData,mopen,handleCloseMod}=props //recuperation des valieur du props

    const [zones,setZones] = useState(null)
    const [equipes,setEquipes] = useState(null)
    const [reseaux,setReseaux] = useState(null)
    const [rolesReseau,setRolesReseau] = useState(null)
    const [userinfos,setUserinfos] = useState(null)

    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        codeuser:userData.idmembre?userData.idmembre:'',
        codezone:userData.codezone?userData.codezone:'',
        codeequipe:userData.codeequipe?userData.codeequipe:'',
        codereseau:userData.codereseau?userData.codereseau:'',
        coderole:userData.coderole?userData.coderole:'',
        profession:userData.profession?userData.profession:''
    });
  
    const [loading,setLoading] = useState(false)
    const handleChange = (event)=>{   
        const {name,value} = event.target
        setInputField({
            [name]:value
        })
    }
    //agence actuelle
    useEffect(()=>{
        if(reseaux && zones && equipes  && userData){
            let currentInfos =""
            const currentRezo = reseaux.filter(item=>item.id==userData.codereseau)
            const currentZone = zones.filter(item=>item.id==userData.codezone)
            const currentEquipe = equipes.filter(item=>item.id==userData.codezone)
            if(currentEquipe!==undefined && currentEquipe && currentEquipe.length!==0){
                currentInfos= `${currentEquipe[0].libelleequipe}`
            }
            if(currentInfos && currentZone!==undefined && currentZone && currentZone.length!==0){
                currentInfos+= ` / ${currentZone[0].libellezone}`
            }
   
            if(currentInfos && currentRezo!==undefined && currentRezo && currentRezo.length!==0){
                currentInfos+= ` / ${currentRezo[0].libelle}`
            }
         
            setUserinfos(currentInfos)
        }
    },[reseaux,zones,equipes,userData])
 
    useEffect(()=>{
        if(inputField.codereseau!==''){
            getZonesReseau(inputField.codereseau).then(dataZone=>{
                if(dataZone){
                    setZones(dataZone)
                }
            })
        }
        if(inputField.codereseau!==''){
            getRolesReseau(inputField.codereseau).then(dataRole=>{
                if(dataRole){
                    setRolesReseau(dataRole)
                }
            })
        }
    },[inputField.codereseau])
    useEffect(() => {
      if(inputField.codezone!==''){
        getEquipesZones(inputField.codezone).then(dataEquipe=>{
            if(dataEquipe){
                setEquipes(dataEquipe)
            }
        })
      }
   },[inputField.codezone])

   useEffect(() => {
    getReseauxNoDispatch().then(drezo=>{
      if(drezo){
          setReseaux(drezo)
      }
    })
 },[])
    const handleSaveUtilisateur =(event)=>{
        if(inputField.idmembre===""){
            handleAlertOpen("veuillez selectionner un utilisateur",false,null) 
            return
        }
        console.log(inputField);
      setLoading(true)
    changerEquipeUser(inputField).then(dataUser=>{
        if(dataUser){
            setLoading(false)
            handleAlertOpen("Modification effectuée avec succès",true,handleCloseMod)
        }else{
            setLoading(false)
            handleAlertOpen("Erreur lors de l'enregistrement",false,null)
            return false
        }
        return
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        handleAlertOpen(err,false,null)
        return
    })
    event.stopPropagation()
    return 
  }
  const [alertInfo,setAlertInfo]=useState({})
  const handleAlertClose =(action=null)=>{
      setAlertInfo({open:false,message:"",succes:false})
      action&&action()
  }
  const handleAlertOpen =(message,succes=false,action)=>{
      setAlertInfo({open:true,message:message,succes:succes,action:action})
  } 
  //processus principale
return (
        <Dialog
            maxWidth='sm'
            open={mopen}
            disableBackdropClick 
            disableBackdropClick
            onClose={handleCloseMod} 
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseMod} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Mise à jour equipe utilisateur - #{userData.idmembre} 
                    </Typography>
                    
                </Toolbar>
        </AppBar>
            <DialogContent>
                <AlertPage open={alertInfo['open']||false}  action={alertInfo['action']||null} message={alertInfo['message']||""} succes={alertInfo['succes']||false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
                <form>
                    {!loading?(
                    <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <LabelledOutline label="Informations personnelles" >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <Typography variant='subtitle1'>Nom :</Typography><Typography variant='h6'>{userData.nom?userData.nom:""}</Typography>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <Typography variant='subtitle1'>Prenom :</Typography><Typography variant='h6'>{userData.prenom?userData.prenom:""}</Typography>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <Typography variant='subtitle1'>Né(e) le :</Typography><Typography variant='h6'>{userData.datenaissance?dayjs(userData.datenaissance).format('DD/MM/YYYY'):""}</Typography>
                                        
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} lg={6}>
                                            <Typography variant='subtitle1'>Genre:</Typography><Typography variant='h6'>{userData.sexe?userData.sexe:""}</Typography>
                                        </GridItem>
                                    </GridContainer>
                                </LabelledOutline>       
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <LabelledOutline label="Agence actuelle" >
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant='h6'>{userinfos&&userinfos}</Typography>
                                        </GridItem>
                                    </GridContainer>
                                </LabelledOutline>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <LabelledOutline label="Nouvelle agence" >
                                    <GridContainer>
                                        {zones&&<GridItem xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                variant="outlined"
                                                id="codezone"
                                                name ='codezone'
                                                select
                                                label="Zone/Departement"
                                                value={inputField.codezone}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{marginTop:20,marginBottom:10}}
                                                >
                                                    <MenuItem key={0} value={0} />
                                                {(zones && zones.length !==0)&& zones.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                    {option.libellezone}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>}
                                        {equipes&&<GridItem xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                variant="outlined"
                                                id="codeequipe"
                                                name ='codeequipe'
                                                select
                                                label="Equipe/Agence"
                                                value={inputField.codeequipe}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{marginTop:20,marginBottom:10}}
                                                >
                                                    <MenuItem key={0} value={0} />
                                                {(equipes && equipes.length !==0)&& equipes.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                    {option.libelleequipe}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>}
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <CustomInput
                                                variant="outlined"
                                                id="profession"
                                                name='profession'
                                                label="Profession"
                                                type="text"
                                                value = {inputField.profession}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={handleChange}
                                            />
                                        </GridItem>
                                    </GridContainer>  
                                </LabelledOutline>
                                {rolesReseau&&<LabelledOutline label="Profil" >
                                    <GridContainer>  
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                variant="outlined"
                                                id="coderole"
                                                name ='coderole'
                                                select
                                                label="Profile"
                                                value={inputField.coderole}
                                                onChange={handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{marginTop:20,marginBottom:10}}
                                                >
                                                    <MenuItem key={0} value={0} />
                                                {(rolesReseau && rolesReseau.length !==0)&& rolesReseau.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                    {option.role}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                    </GridContainer>
                                </LabelledOutline>}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <Button  color="primary" onClick={handleSaveUtilisateur}  style={{width:'100%',marginTop:20,marginBottom:20}}>Enregistrer</Button>
                            </GridItem>
                        </GridContainer>):(<div className={classes.root}><CircularProgress color="primary" /></div>)}
                    
                </form>  
                
            </DialogContent>
      </Dialog>
    )
}

export default ChangerEquipeUser