import React, { useState, useReducer } from 'react'
import { Divider, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from "@material-ui/core/Typography";
import Alert from '@material-ui/lab/Alert';
import { connect, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form'
import CustomAlert from 'components/CustomAlert/CustomAlert';
import { updateProposition } from 'redux/actions/apiActions'
const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    minWidth: 650,
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  btnpay:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.dark,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
}));
function PropoTransmission(props) {
  const { open, handleClose, data,user } = props
  const handleAnnuletrans = () => {
    props.handleRedirectSouscription(data)
  }
  const classes = useStyles()
  const [aopen, setAopen] = useState(false)
  const [aInfo, setAinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      amessage: null,
      amode: null,
    });

  const transmissionProposition = async (donnees) => {
    if (!donnees) { return }
    if (donnees.length !== 0) {
      await Promise.all(donnees.map((souscription) => {
        return props.updateProposition({ id: souscription.id, typeop: 'TR' }).then((ctr) => {
          if (ctr) {
            return ctr
          } else {
            return null
          }
        })
      })).then(resultat => {
        if (resultat) {
          setAinfo({
            ['amessage']: "Tranmission effectué avec succès",
            ['amode']: "succes"
          })
          setAopen(true)
        } else {
          setAinfo({
            ['amessage']: "Erreur incconnu lors de la transmission",
            ['amode']: "error"
          })
          setAopen(true)
        }
      }).catch((err) => {
        setAinfo({
          ['amessage']: "Erreur incconnu lors de la transmission",
          ['amode']: "error"
        })
        setAopen(true)
      })

      props.handleRedirectSouscription(data)

    }
  }
  const handlePayerPrime =(e)=>{
    e.preventDefault()
    /*let element = document.createElement('a');
    element.href=`https://yakoafricassur.com/paiement-first-esous.php?id=${props.data.id}`;
    element.target = '_blank';
    element.click();*/
    //transmissionProposition([data])

    props.handleRedirectSouscription(data,'PAI')
  }
  return (
    <Dialog
      maxWidth='md'
      disableBackdropClick
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <CustomAlert aopen={aopen} message={aInfo.amessage} mode={aInfo.amode} />
        
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',margin:20}}>
            <Typography component='h4' variant='h4'>Voullez-vous transmettre la proposition Réf : {data.id} pour validation ? </Typography>
            <Alert severity="warning">NB: En transmettant la proposition vous ne pourrez plus faire de modification sauf après réjet du service production de YAKO AFRICA </Alert>
          </div>

          <Divider />
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',flexWrap:'wrap',marginTop:20}}>
            <Button classeName={classes.btnback} onClick={handleAnnuletrans} variant="outlined">
              Transmettre plustard
            </Button>
            {(user && !['092'].includes(user.credentials.codepartenaire))&&<Button classeName={classes.btnpay}  onClick={handlePayerPrime} variant="outlined">
                Payer la première prime
            </Button>}
              
            <Button classeName={classes.btnnext} onClick={(e) => transmissionProposition([data])} variant="outlined">
              Transmettre
            </Button>
        </div>
      </DialogContent>
      
    </Dialog>
  )
}
const mapActionsToProps = { updateProposition }
const mapStateProps = (state) => ({
  user: state.user,
})


export default connect(mapStateProps, mapActionsToProps)(reduxForm({ form: 'adForm', destroyOnUnmount: false })(PropoTransmission))


