import React from 'react';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import MaterialTable,{MTableToolbar } from 'material-table';
import {connect} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import AddEquipe from './AddEquipe'


function EquipeList(props){
    
    const [addopen, setAddopen] = React.useState(false);
    const {settings:{equipes},}=props
    const handleClickAddOpen = () => {
      setAddopen(true);
    };

  const handleCloseAdd = () => {
    setAddopen(false);
  };
  

    return (
        <GridContainer>
            <AddEquipe mopen={addopen} handleCloseMod={handleCloseAdd} handleMopen={handleClickAddOpen} />
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
                  columns={[
                    { title: '#', field: 'id' },
                    { title: 'Code', field: 'codeequipe'},
                    { title: 'Libelle', field: 'libelleequipe'},
                    { title: 'Code responsable', field: 'coderesponsable'},
                    { title: 'Nom responsable.', field: 'nomresponsable'},
                    { title: 'Zone', field: 'codezone'},
                  ]}
                  data={equipes}
                  title="Equipes/Agences"
                  localization={{
                    toolbar:{
                        searchPlaceholder:'Rechercher',
                        searchTooltip:'Rechercher'
                    },
                    body: {
                      emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                      labelRowsSelect: 'Ligne(s)',
                      labelDisplayedRows: '{count} sur {from}-{to}',
                      firstTooltip: 'Prémière page',
                      previousTooltip: 'Précédent',
                      nextTooltip: 'Suivant',
                      lastTooltip: 'Dernière page'
                    }
                  }}
                  
                  options={{
                    exportButton: true,
                    searchFieldStyle:{
                      fontSize:18,
                      width:'100%',
                      height:50,
                    },
                    actionsColumnIndex: -1
                  }}
                  
                  components={{
                  Action: props=> {  
                      return(  
                        <CustomMenu 
                          data={props.data}       
                          />
                        )
                  },
                  Toolbar: props => (
                      <div>
                      <MTableToolbar {...props} />
                        <div style={{padding: '0px 10px',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                          <Button onClick={handleClickAddOpen} variant='outlined' color='primary' >
                              <Add style={{color:'#fff',fontSize: 30}} />
                          </Button> 
                        </div>
                        
                      </div>
                  )
                  }}
                  actions={[
                    {
                      icon: 'more_vert',
                      tooltip: 'Actions',
                      onClick: (event, rowData) =>alert("You want to add a new row"),
                    },
                  ]}
                  
                />
            </GridItem>
        </GridContainer>
    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user
  });
  const mapActionsToProps = {}
export default connect(mapStateToProps,mapActionsToProps)(EquipeList)