import React,{useState,useEffect} from "react";
import {  reduxForm,change,getFormValues } from 'redux-form';
import {connect} from 'react-redux'
import ProdFunctions from 'pages/Commercial/Propositions/ProdFonctions'
import PropositionList from 'pages/Commercial/Propositions/PropositionList'
import ContratList from 'pages/Commons/Contrats/ContratList'
import  RechercheContrat from 'pages/Commons/Contrats/RechercheContrat'
import {verifRule} from 'application'
import ErrorPage from 'components/ErrorPage'
import Paiement from "pages/Commons/Paiements/Paiement";

function ProductionHome(props){
    const [fonctionnalite,setFonctionalite]=useState('HOME')
    const [data,setData] = useState(null)
    const handleCliqueFonction = (element,cdata)=>{
        cdata&&setData(cdata)
        setFonctionalite(element)
    }
    useEffect(() => {
        document.title ="YNOV - Gestion de la production"
      })
      const {privileges} = props.user
    return(
        <div>
            {fonctionnalite==='HOME'&&  <ProdFunctions history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {(fonctionnalite==='PROD'/* && verifRule('prop','read',privileges)*/)?  <PropositionList history={props.history} handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='PROD' && !verifRule('prop','read',privileges))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
            {(fonctionnalite==='PAI'/* && verifRule('prop','read',privileges)*/)&&  <Paiement data={data} history={props.history} handleFonction={handleCliqueFonction}/>}
            {(fonctionnalite==='CONTRAT'/* && verifRule('ctr','read',privileges)*/)? <ContratList handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='CONTRAT' && !verifRule('ctr','read',privileges))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
            {(fonctionnalite==='SEARCH'/* && verifRule('ctr','search',privileges)*/)? <RechercheContrat handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='SEARCH' && !verifRule('ctr','search',privileges))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(ProductionHome))
  
