import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MinimalLayout from 'Layouts/Minimal/MinimalLayout';
import AuthRoute from 'util/AuthRoute'
import Admin from "Layouts/Admin";
import Bankass from "Layouts/Bankass";
import Settings from "Layouts/Settings";
import Producteur from "Layouts/Producteur";
import Commercial from "Layouts/Commercial";
import Courtage from "Layouts/Courtage";
import Entreprise from "Layouts/Entreprise";
import Client from "Layouts/Client";
import RouteWithLayout from 'components/RouteWithLayout/RouteWithLayout'
import { connect, useDispatch } from 'react-redux'
import Home from "pages/Home";
import Souscription from "pages/Souscription"
import Signup from 'pages/Signup/Signup';
import DrawerLayout from 'Layouts/DrawerLayout';
import SimpleLayout from 'Layouts/SimpleLayout';
import MasterPartenaire from 'Layouts/MasterPartenaire';

function CustomRoute(props) {
  const { ui: { layoutRoot },user } = props
  let layoutComponent = DrawerLayout

  if (layoutRoot === 'bankass') {
    layoutComponent =Bankass
  }
  if (layoutRoot === 'producteur') {
    layoutComponent = Producteur
  }
  if (layoutRoot === 'courtage') {
    layoutComponent = MasterPartenaire//Courtage
  }
  if (layoutRoot === 'entreprise') {
    layoutComponent = Entreprise
  }
  if (layoutRoot === 'client') {
    layoutComponent = Client
  }
  if (layoutRoot === 'com') {
    layoutComponent = MasterPartenaire//Commercial
  }
  if (layoutRoot === 'settings') {
    layoutComponent = Settings
  }
  if (layoutRoot === 'medecin') {
    layoutComponent = SimpleLayout
  }

  return (
    <Router>
      <Switch>
        <RouteWithLayout exact path="/" component={Home} layout={MinimalLayout} />
        <Route hRoute exact path="/inscription" component={Signup} layout={MinimalLayout} />
        <Route exact path="/souscription" component={Souscription} layout={MinimalLayout} />

        {(layoutRoot==='bankass')?((user && user.codepartenaire!=='092' )? (<AuthRoute path={`/${layoutRoot}`} component={layoutComponent} layout={MasterPartenaire} />):(<AuthRoute path={`/${layoutRoot}`} component={layoutComponent} layout={DrawerLayout} />)
        ):(<AuthRoute path={`/${layoutRoot}`} component={layoutComponent} layout={MasterPartenaire}/>) }
        {(layoutRoot==='courtage')&&(<AuthRoute path={`/${layoutRoot}`} component={layoutComponent} layout={MasterPartenaire} />)}
        {(layoutRoot==='com')&&(<AuthRoute path={`/${layoutRoot}`} component={layoutComponent} layout={layoutComponent} />)}
        
        <AuthRoute path="/settings" component={Settings} />
      </Switch>
    </Router>
  )
}
const mapStateToProps = (state) => ({
  ui: state.UI,
  user:state.user.credentials
});

const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(CustomRoute)