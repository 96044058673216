import React ,{useState,useEffect,useReducer} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector,connect} from 'react-redux';
import {getFiliations} from 'redux/actions/apiActions'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomDate from 'components/CustomDate/CustomDate'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import withWidth from '@material-ui/core/withWidth';
import {Grid} from '@material-ui/core';
import {formValueSelector} from 'redux-form'

const useStyles = makeStyles(theme => ({
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  rootTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
}));

function ProfilModalFormBenef(props) {
  const classes =    useStyles()
  const {open,handleClose,handleAddAssure} = props
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    nomBeneficiaire: '', 
       prenomBeneficiaire: '',
       dateNaissanceBeneficiaire:'',
       lieuNaissanceBeneficiaire:'',
       lieuResidenceBeneficiaire:'',
       filiationBeneficiaire:'',
       telephoneBeneficiaire:'',
       emailBeneficiaire:'',
       taux:'',
       monIndex:0
  });
  
      //get value off villes
    let  mesVilles = useSelector(state=>state.api.villes)
    if(mesVilles.length===0){
      mesVilles=JSON.parse(localStorage.APIVilles);
    }

    //get filaiation value
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
      getFiliations().then((fil)=>{
        setFiliations(fil);
      })
     
    }, [])
    
    const submit=(e)=>{
      e.preventDefault()
      let dataAssure =inputField;
      handleAddAssure(dataAssure)
    }
    const handleChange =(event)=>{
      const {name,value}=event.target
      setInputField({
        [name]:value
      })
    }
    const enabled =inputField.nomBeneficiaire.length > 0 &&inputField.prenomBeneficiaire.length > 0;
    return (
      
        <Dialog fullWidth maxWidth={props.width} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Nouveau bénéficiaire</DialogTitle>
          <Divider />
          <form onSubmit={submit}>
          <DialogContent> 
            <GridContainer>
              <GridItem item xs={12} sm={12} md ={12} lg={12}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md ={4} lg={4}>
                    <CustomInput
                        required
                        label="Nom"
                        id="nomBeneficiaire"
                        name="nomBeneficiaire"
                        variant="outlined"
                        formControlProps={{
                            fullWidth: true
                        }}
                        value={(inputField.nomBeneficiaire && inputField.nomBeneficiaire!=="")&&inputField.nomBeneficiaire}
                        onChange={handleChange}
                        
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md ={8} lg={8}>
                    <CustomInput
                        required
                        label="Prénom"
                        id="prenomBeneficiaire"
                        name="prenomBeneficiaire"
                        variant="outlined"
                        formControlProps={{
                            fullWidth: true
                        }}
                        value={(inputField.prenomBeneficiaire && inputField.prenomBeneficiaire!=="")&&inputField.prenomBeneficiaire}
                        onChange={handleChange}
                        autoFocus
                        
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md ={4} lg={4}>
                    <CustomDate
                      label="Date de naissance"
                      id="dateNaissanceBeneficiaire"
                      name="dateNaissanceBeneficiaire"
                      formControlProps={{
                          fullWidth: true,
                      }}
                      InputProps={{
                          type:'date',
                          value:(inputField.dateNaissanceBeneficiaire && inputField.dateNaissanceBeneficiaire!=="")&&inputField.dateNaissanceBeneficiaire
                      }}
                      onChange={handleChange}
                      
                      />
                  </GridItem>
                  <GridItem xs={12} sm={8} md ={8} lg={8}>
                    <CustomSelect
                      label="Lieu de naissance"
                      id="lieuNaissanceBeneficiaire"
                      name="lieuNaissanceBeneficiaire"
                      formControlProps={{
                          fullWidth: true
                      }}
                      value={(inputField.lieuNaissanceBeneficiaire && inputField.lieuNaissanceBeneficiaire!=="")&&((inputField.lieuNaissanceBeneficiaire.hasOwnProperty('MonLibelle'))?inputField.lieuNaissanceBeneficiaire.MonLibelle:inputField.lieuNaissanceBeneficiaire)}
                      options={mesVilles}
                      onChange={handleChange}
                      
                      />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem item xs={12} sm={6} md ={6} lg={6}>
                    <CustomSelect
                      label="Lieu de résidence"
                      id="lieuResidenceBeneficiaire"
                      name="lieuResidenceBeneficiaire"
                      formControlProps={{
                          fullWidth: true
                      }}
                      value={(inputField.lieuResidenceBeneficiaire && inputField.lieuResidenceBeneficiaire!=="")&&((inputField.lieuResidenceBeneficiaire.hasOwnProperty('MonLibelle'))?inputField.lieuResidenceBeneficiaire.MonLibelle:inputField.lieuResidenceBeneficiaire)}
                      options={mesVilles}
                      onChange={handleChange}
                      
                      />
                  </GridItem>
                  <GridItem  xs={12} sm={6} md ={6} lg={6}>
                          <CustomSelect
                              label="Lien de parenté"
                              id="filiationBeneficiaire"
                              name="filiationBeneficiaire"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={(inputField.filiationBeneficiaire && inputField.filiationBeneficiaire!=="")&&((inputField.filiationBeneficiaire.hasOwnProperty('MonLibelle'))?inputField.filiationBeneficiaire.MonLibelle:inputField.filiationBeneficiaire)}
                              options={filiations}
                              onChange={handleChange}
                              
                            />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                        <GridItem  xs={12} sm={4} md ={4} lg={4}>
                          <CustomInput
                              label="Téléphone"
                              id="telephoneBeneficiaire"
                              name="telephoneBeneficiaire"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={(inputField.telephoneBeneficiaire && inputField.telephoneBeneficiaire!=="")&&inputField.telephoneBeneficiaire}
                              onChange={handleChange}
                              autoFocus
                              
                            />
                        </GridItem>
                        <GridItem  xs={12} sm={8} md ={8} lg={8}>
                          <CustomInput
                              label="Addresse email"
                              id="emailBeneficiaire"
                              name="emailBeneficiaire"
                              type="email"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={(inputField.emailBeneficiaire && inputField.emailBeneficiaire!=="")&&inputField.emailBeneficiaire}
                              onChange={handleChange}
                            />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem item xs={12} sm={4} md ={4} lg={4}>
                          <CustomInput
                            label="Part"
                            id="taux"
                            name="taux"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={(inputField.taux && inputField.taux!=="")&&inputField.taux}
                            onChange={handleChange}
                      />
                        </GridItem>
                      </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
          <Grid container direction="row" justify="space-between" alignItems="center"> 
            <Button onClick={handleClose} className={classes.btnback} variant="contained" >
              Annuler
            </Button>
            <Button type='submit' className={classes.btnnext} variant="contained" disabled={!enabled}>
              Ajouter
            </Button>
            </Grid>
          </DialogActions>
          </form>
        </Dialog>
      
    );
  }
  const selector = formValueSelector('adForm')
  const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user
  });
  
  const mapActionsToProps = {
   
  };
  ProfilModalFormBenef = withWidth()(ProfilModalFormBenef)
  export default connect(mapStateToProps,mapActionsToProps)(ProfilModalFormBenef)
