import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux';
import {Field,getFormValues,reduxForm,formValueSelector,change,registerField} from 'redux-form';
import { Paper, Typography,Divider,IconButton, Badge, Button } from '@material-ui/core'
import clsx from 'clsx'
import Card from '@material-ui/core/Card';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardContent from '@material-ui/core/CardContent';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import EvolutionProduction from 'pages/stats/EvolutionProduction'
import ProductionParproduit from 'pages/stats/ProductionParproduit'
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import { getTotProductionAgent } from 'redux/actions/apiActions'
import { useHistory } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import OperationStat from 'pages/Operations/components/OperationStat';
import PretStat from 'pages/Banking/Prets/components/PretStat';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CreateIcon from '@material-ui/icons/Create';
import PeopleIcon from '@material-ui/icons/People';
import SendIcon from '@material-ui/icons/Send';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { green, red } from '@material-ui/core/colors';
import HomeIcon from '@material-ui/icons/Home';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Ask from 'pages/Banking/Prets/addprets/components/ask';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import CustomInput from "components/CustomInput/CustomInput";
import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber'
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import dayjs from 'dayjs';

const useStyle = makeStyles(theme=>({
    root:{
       display:'flex',
       flexDirection:'column'
    },
    homhead:{
        width:'100%',
       marginBottom:20,
       marginTop:0,
       backgroundColor:theme.palette.primary.main,
    },
    hpaper:{
        width:'85%',
        marginRight:'auto',
        marginLeft:'auto',
        [theme.breakpoints.down('sm')]: {
          width:'100%',
        },
        [theme.breakpoints.up('md')]: {
          width:'75%',
        },
        [theme.breakpoints.up('lg')]: {
          width:'60%',
        },
        marginTop:20,
        padding:20,
        border:'none',
        background:'transparent !important'
      
    },
    servicesBox:{
        display:'flex',
        flexDirection:'column',
        marginRight:'auto',
        marginLeft:'auto',
        [theme.breakpoints.down('sm')]: {
          width:'100%',
        },
        [theme.breakpoints.up('md')]: {
          width:'75%',
        },
        [theme.breakpoints.up('lg')]: {
          width:'50%',
        },
        marginTop:30,
        padding:20,
        
    },
    marge:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    texte:{
      color:'#fff'
    },
    card:{
      display:'flex',
      flex:1,
      flexDirection:'column',
      justifyContent:'space-around',
      padding:20,
      margin:20,
      alignItems:'center'
    },
    activityCard:{
      padding:20,
      margin:20,
    },
    activityContent:{
      display:'flex',
      justifyContent:'sapce-between'
    },
    activityItem1:{
      flex:2,
      margin:10
    },
    activityItem2:{
      flex:1,
      margin:10
    },
    mainitem:{
        display:'flex',
        flexDirection:'column',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        paddingBottom:20
    },
    iheader:{
        display:'flex',
        marginBottom:10,
        alignItems:'center',
        padding:10
    },
    icorps:{
        marginTop:10,
        padding:20,
        display:'flex'
    },
    icard:{
        display:'flex',
        justifyContent:'center',
        flex:1,
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
        },
        cursor:'pointer',
        margin:20
    },
    iStat:{
        display:'flex',
        marginTop:20,
        marginBottom:20
    },
    igraph:{
        display:'flex',
        justifyContent:'space-around'
    },
    cardLogo:{
        position:'absolute',
        top:-50,
        width:100,
        height:100
    },
    img:{
        objectFit: 'fill',
        width:100
    },
    cardState:{
        display:'flex',
        width:'100%',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    cardpar:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3)",
        marginBottom:20,
        position:'relative',
        padding:20,
        marginTop:70
    },
    itcard:{
        display:'flex',
        flex:1,
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)"
        },
        cursor:'pointer'
    },
    numberState:{
        fontSize:35,
        color:theme.palette.primary.main,
        textAlign:'center'
    },
    numberAj:{
        fontSize:35,
        color:red[500],
        textAlign:'center'
    },
    labelState:{
        color:'#666',
        fontWeight:'bold',
        textAlign:'center'
    },
    cardadd:{
        marginLeft:20,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:theme.palette.primary.main,
        borderRadius:0,
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);",
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
        }
    },
    stateBox:{
        display:'flex',
        padding:20,
        marginBottom:20,
        border:'0px 10px 0px 10px',
        borderRadius:'0px 20px 0px 20px',
        borderColor:green[500],
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
    },
    cardrapide:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:0,
        flex:1,
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:'white'
        }
    },
    contentar:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    separateurv:{
        marginLeft:20,
        marginRight:20,
        marginTop:10,
        marginBottom:10
    },
    btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:green[400],
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
  }))


  const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor:'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);
const AddRapportMedical = (props) => {
    const classes = useStyle()
    const history = useHistory()
    const {state:{patient}} = useLocation()
    const { handleSubmit,handleBack,handleNext,handleAttente,steps,activeStep,dataForm } = props    
    const [detailsTab,setDetailsTab]= useState(null)

    useEffect(()=>{
        let  currentElt = []
        if(dataForm && dataForm.hasOwnProperty("patientconnu")) {
            currentElt.push("patientconnu")
        } 
        if(dataForm && dataForm.hasOwnProperty("patientconnu_autreraison")) {
            if(dataForm["patientconnu_autreraison"]==='Oui'){
                currentElt.push("patientconnu_autreraison")
            }
        } 
        if(dataForm && dataForm.hasOwnProperty("estsoutraitement")) {
            if(dataForm["estsoutraitement"]==='Oui'){
                currentElt.push("estsoutraitement")
            }
        } 
        if(dataForm && dataForm.hasOwnProperty("aspectsain")) {
            if(dataForm["aspectsain"]==='Oui'){
                currentElt.push("aspectsain")
            }
        } 
        if(dataForm && dataForm.hasOwnProperty("hepatomegalieok")) {
            if(dataForm["hepatomegalieok"]==='Oui'){
                currentElt.push("hepatomegalieok")
            }
        } 
        if(dataForm && dataForm.hasOwnProperty("hepatomegalieok")) {
            if(dataForm["hepatomegalieok"]==='Oui'){
                currentElt.push("hepatomegalieok")
            }
        } 
        if(dataForm && dataForm.hasOwnProperty("hernieok")) {
            if(dataForm["hernieok"]==='Oui'){
                currentElt.push("hernieok")
            }
        } 
        if(dataForm && dataForm.hasOwnProperty("souflecardiaquecoeurok")) {
            if(dataForm["souflecardiaquecoeurok"]==='Oui'){
                currentElt.push("souflecardiaquecoeurok")
            }
        } 
        setDetailsTab(currentElt)
    },[dataForm])
    const handleChange = (event) => {
        const {
            target: { value,name },
        } = event;
        props.dispatch(registerField("addRapport",name,'Field'));
        props.updateField(name,(typeof value === 'string' ? value.split(',') : value))
    };
    const [expanded, setExpanded] = React.useState('panel1');

  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [remarque, setRemarque] = useState('');
  const handleChangeremarque = (v)=>{
    setRemarque(v)
    props.dispatch(registerField("addRapport",'remarques','Field'));
    props.updateField('remarques',v)
  }
  const questions =[
        { id: 1, groupe:"", question:'Avez-vous déjà été victime d\'un accident', reponse: '', commentaire: '', details: [{id:1,question:"",reponse:"",commentaire:""}] },
    ]
  
  return (
    <div className={classes.root}>
        <div className={classes.homhead}>
            <Paper className={classes.hpaper} variant="outlined">
                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                    <IconButton onClick={()=>history.push('/medecin/accueil')}>
                        <ArrowBackIcon style={{fontSize:40,color:'white'}} />
                    </IconButton>
                    <Typography className={classes.texte} variant='h4'> Saisie d'un nouveau rapport</Typography>
                </div>
            </Paper> 
        </div>
        <div className={classes.servicesBox}>
           <Typography variant='h5'>Patients</Typography>
           <Divider />
           <div className='flex flex-row justify-between  bg-green-50' style={{borderWidth:1,borderRadius:10,width:'100%',padding:20,borderColor:'black'}}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic',marginRight:20}}>Nom & prénoms : </span>
                    {patient&&<span>
                        {`${patient.adherent?.nom.toUpperCase()} ${patient.adherent?.prenom.toUpperCase()}`}
                        </span>}</div>
                    
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic'}}>Date naissance : </span>{patient&&<span>
                        {`${dayjs(patient.adherent?.datenaissance).format('DD/MM/YYYY')} `}
                        </span>}</div>
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic'}}>Lieu de naissance : </span>{patient&&<span>
                        {`${patient.adherent?.lieunaissance}`}
                        </span>}</div>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic'}}>Profession : </span>{patient&&<span>
                        {`${patient.adherent?.profession}`}
                        </span>}</div>
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic'}}>Secteur d'activité' : </span>{patient&&<span>
                        {`${patient.adherent?.employeur}`}
                        </span>}</div>
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic'}}>Lieu de résidence : </span>{patient&&<span>
                        {`${patient.adherent?.lieuresidence}`}
                        </span>}</div>
                </div>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic'}}>Téléphone : </span>{patient&&<span>
                        {`${patient.adherent?.mobile}`}
                        </span>}</div>
                 
                    <div style={{display:'flex'}}><span style={{fontWeight:'bold',fontStyle:'italic'}}>Email : </span>{patient&&<span>
                        {`${patient.adherent['email'] || ""}`}
                        </span>}</div>
                </div>
           </div>
           <Divider />
            <Ask>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Avez-vous déjà soigné la personne à assurer ? </Ask.Title> 
                        </Ask.Group> 
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio} id="patientconnu" name='patientconnu' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                    {(detailsTab && detailsTab.includes('patientconnu') && dataForm.patientconnu ==='Oui')&&<Ask.Details>
                        <Ask.Card>
                            <Ask.Group><Ask.Title variant='body'>Si oui, quand et pour quel(s) motif(s) ? </Ask.Title></Ask.Group>
                            <Ask.Group>
                                <Field component={CustomInput} id="patientconnu_motifs" name="patientconnu_motifs" formControlProps={{ fullWidth: true }}  variant="outlined"/>
                            </Ask.Group>
                        </Ask.Card>
                    </Ask.Details>}
                    {(detailsTab && detailsTab.includes('patientconnu') && dataForm.patientconnu ==='Non')&&<Ask.Details>
                            <Ask.Card>
                                <Ask.Group><Ask.Title variant='body'>Si non, la connaissez-vous pour d’autres raisons ? </Ask.Title></Ask.Group>
                                <Ask.Group>
                                    <Field component={CustomRadio} id="patientconnu_autreraison" name='patientconnu_autreraison' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                </Ask.Group>
                            </Ask.Card>
                            {(detailsTab && detailsTab.includes('patientconnu_autreraison') && dataForm['patientconnu_autreraison'] ==='Oui')&&<Ask.Details>
                                <Ask.Card>
                                    <Ask.Group><Ask.Title variant='body'>Lesquelles ? </Ask.Title></Ask.Group>
                                    <Ask.Group>
                                        <Field component={CustomInput} id="patientconnu_autreraisonconu" name="patientconnu_autreraisonconu" formControlProps={{ fullWidth: true }}  variant="outlined"/>
                                    </Ask.Group>
                                </Ask.Card>
                            </Ask.Details>}
                    </Ask.Details>}
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>La personne à assurer est-elle actuellement sous contrôle ou en traitement ? </Ask.Title> 
                        </Ask.Group> 
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio}  id="estsoutraitement" name='estsoutraitement' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                    {(detailsTab && detailsTab.includes('estsoutraitement') && dataForm['estsoutraitement'] ==='Oui')&&<Ask.Details>
                        <Ask.Card>
                            <div>
                            <Ask.Group><Ask.Title variant='body'>Lequel ? </Ask.Title></Ask.Group>
                            <Ask.Group>
                                <Field component={CustomInput} id="estsoutraitement_lequel" name="estsoutraitement_lequel" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                            </Ask.Group>
                            </div>
                            <div>
                            <Ask.Group><Ask.Title variant='body'>Depuis quand ? </Ask.Title></Ask.Group>
                            <Ask.Group>
                                <Field type='date' component={CustomInput} id="estsoutraitement_depuisquant" name="estsoutraitement_depuisquant" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                            </Ask.Group>
                            </div>
                            <div>
                            <Ask.Group><Ask.Title variant='body'>Pour quel motif ? </Ask.Title></Ask.Group>
                            <Ask.Group>
                                
                                <Field component={CustomInput} id="estsoutraitement_motif" name="estsoutraiestsoutraitement_motiftement_lequel" formControlProps={{ fullWidth: false }}  variant="outlined"/>
                            </Ask.Group>
                            </div>
                        </Ask.Card>
                    </Ask.Details>}
                </Ask.Inner>
                <div style={{marginTop:20,marginBottom:20,paddingBottom:20}}>
                    <Accordion square expanded={expanded === 'panel1'} onChange={handlePanelChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography variant='h5'>ASPECT GENERAL</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                            <Ask.Inner>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Aspect sain correspondant à l’âge indiqué ? </Ask.Title> 
                                    </Ask.Group> 
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio}  id="aspectsain" name='aspectsain' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                </Ask.Card>
                                {(detailsTab && detailsTab.includes('aspectsain') && dataForm['aspectsain'] ==='Oui')&&<Ask.Details>
                                    <Ask.Card>
                                        <Ask.Group><Ask.Title variant='body'>Type constitutionnel ? </Ask.Title></Ask.Group>
                                        <Ask.Group>
                                            <Field type='date' component={CustomInput} id="aspectsain_constitution" name="aspectsain_constitution" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Details>}
                            </Ask.Inner>

                            <Ask.Inner>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Y a-t-il des malformations ou des mutilations ?</Ask.Title> 
                                    </Ask.Group> 
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomRadio}  id="aspectmalformation" name='aspectmalformation' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                </Ask.Card>
                            </Ask.Inner>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel2'} onChange={handlePanelChange('panel2')}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography variant='h5'>TAILLE – POIDS</Typography><br /> <small>(Résultats des mensurations et de la pesée)</small>
                        </AccordionSummary>
                        <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                            <Ask.Inner>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Quelle est votre taille ? </Ask.Title>
                                    </Ask.Group>  
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomInputNumber} id="taille" name='taille' formControlProps={{fullWidth: false}}variant="outlined"/>
                                        <Ask.SmallText variant='h5'>(CM)</Ask.SmallText>
                                    </Ask.Group>
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Quel est votre poids ? </Ask.Title> 
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field component={CustomInputNumber} id="poids" name='poids' formControlProps={{ fullWidth: false }} variant="outlined"/>
                                        <Ask.SmallText variant='h5'>(KG)</Ask.SmallText>
                                    </Ask.Group>
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Périmètre thoracique </Ask.Title> 
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <div style={{display:'flex',flexDirection:'column'}}>
                                            <><Ask.SmallText variant='h5'>En inspiration : </Ask.SmallText><Field component={CustomInputNumber} id="pthoraciqueinspiration" name='pthoraciqueinspiration' formControlProps={{ fullWidth: false }} variant="outlined"/></>
                                            <><Ask.SmallText variant='h5'>En expiration : </Ask.SmallText><Field component={CustomInputNumber} id="pthoraciqueexpiration" name='pthoraciqueexpiration' formControlProps={{ fullWidth: false }} variant="outlined"/></>
                                        </div>
                                    </Ask.Group>
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Périmètre abdominal </Ask.Title> 
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomInputNumber} id="pabdominal" name='pabdominal' formControlProps={{ fullWidth: false }} variant="outlined"/>
                                    </Ask.Group>
                                </Ask.Card>
                            </Ask.Inner>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel3'} onChange={handlePanelChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant='h5'>PEAU</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}> 
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y 'a t-il: </Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group></Ask.Group>
                                    </Ask.Card>
                                    <Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Ictère ou cyanose ? </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomRadio}  id="peauictereok" name='peauictereok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                            </Ask.Group>
                                        </Ask.Card>
                                    </Ask.Details>
                                    <Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Eruption, ulcération, kyste, tumeur,varices ou oedèmes ? </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomRadio}  id="peaueruptionok" name='peaueruptionok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                            </Ask.Group>
                                        </Ask.Card>
                                    </Ask.Details>
                                    <Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Ganglions lymphatiques augmentés de volume ? </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomRadio}  id="peauganglionsok" name='peauganglionsok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                            </Ask.Group>
                                        </Ask.Card>
                                    </Ask.Details>
                                    <Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Cicatrices, tatouages ?</Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomRadio}  id="peaucicatricesok" name='peaucicatricesok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                            </Ask.Group>
                                        </Ask.Card>
                                    </Ask.Details>
                                    <Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Tophus, ranthome ?</Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomRadio}  id="peautophusok" name='peautophusok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                            </Ask.Group>
                                        </Ask.Card>
                                    </Ask.Details>
                                </Ask.Inner>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel4'} onChange={handlePanelChange('panel4')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant='h5'>COU</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il un goitre ? </Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="cougoitreok" name='cougoitreok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel5'} onChange={handlePanelChange('panel5')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant='h5'>YEUX ET APPAREIL AUDITIF</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une affection des yeux ? </Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="yeuxaffectionok" name='yeuxaffectionok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Acuité visuelle </Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group style={{justifyContent:'center'}}>
                                            <Typography variant='h6'>OD</Typography>
                                        </Ask.Group>
                                        <Ask.Group style={{justifyContent:'center'}}>
                                            <Typography variant='h6'>OG</Typography>
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Avant correction :</Ask.Title></Ask.Group>
                                            <Ask.Group style={{marginRight:20}}>
                                                <Field type='number' component={CustomInput} id="yeuxacuiteavcorrectionod" name="yeuxacuiteavcorrectionod" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                                <Ask.SmallText variant='h5'>/10 </Ask.SmallText>
                                            </Ask.Group>
                                            <Ask.Group>
                                                <Field type='number' component={CustomInput} id="yeuxacuiteavcorrectionog" name="yeuxacuiteavcorrectionog" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                                <Ask.SmallText variant='h5'>/10 </Ask.SmallText>
                                            </Ask.Group>
                                        </Ask.Card>
                                    </Ask.Details>
                                    <Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Après correction :</Ask.Title></Ask.Group>
                                            <Ask.Group style={{marginRight:20}}>
                                                <Field type='number' component={CustomInput} id="yeuxacuiteapcorrectionod" name="yeuxacuiteapcorrectionod" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                                <Ask.SmallText variant='h5'>/10 </Ask.SmallText>
                                            </Ask.Group>
                                            <Ask.Group>
                                                <Field type='number' component={CustomInput} id="yeuxacuiteapcorrectionog" name="yeuxacuiteapcorrectionog" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                                <Ask.SmallText variant='h5'>/10 </Ask.SmallText>
                                            </Ask.Group>
                                        </Ask.Card>
                                    </Ask.Details>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une affection de l’appareil auditif ? </Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="auditifaffectionok" name='auditifaffectionok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                            
                                        </Ask.Group>
                                        
                                    </Ask.Card>
                                </Ask.Inner>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel6'} onChange={handlePanelChange('panel6')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography variant='h5'>APPAREIL DIGESTIF</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>La langue, le pharynx et les amygdales ont-ils un aspect anormal ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="digestifaspectlangueok" name='digestifaspectlangueok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>La palpation de l’abdomen décèle t-elle un état pathologique ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="digestifaspectpalpationok" name='digestifaspectpalpationok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une hépatomégalie ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="hepatomegalieok" name='hepatomegalieok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    {(detailsTab && detailsTab.includes('hepatomegalieok') && dataForm['hepatomegalieok'] ==='Oui')&&<Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>De combien de travers de Doigts ? </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomInput} id="hepatomegaliedoigt" name="hepatomegaliedoigt" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                            </Ask.Group>
                                        </Ask.Card>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Consistance : </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomInput} id="hepatomegalieconsistance" name="hepatomegalieconsistance" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                            </Ask.Group>
                                        </Ask.Card>
                                        
                                    </Ask.Details>}
                                </Ask.Inner>

                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une splénomégalie ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="splenomegalieok" name='splenomegalieok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    {(detailsTab && detailsTab.includes('splenomegalieok') && dataForm['splenomegalieok'] ==='Oui')&&<Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Dégré ? </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomInput} id="splenomegalieokdegre" name="splenomegalieokdegre" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                            </Ask.Group>
                                        </Ask.Card>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Consistance : </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomInput} id="hepatomegalieokconsistance" name="hepatomegalieokconsistance" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                            </Ask.Group>
                                        </Ask.Card>
                                        
                                    </Ask.Details>}
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une hernie ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="hernieok" name='hernieok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    {(detailsTab && detailsTab.includes('hernieok') && dataForm['hernieok'] ==='Oui')&&<Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>De quelle nature ? </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomInput} id="hernieoknature" name="hernieoknature" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                            </Ask.Group>
                                        </Ask.Card>
                                        
                                        
                                    </Ask.Details>}
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il des hémorroïdes, une notion de melaona, de rectorragies ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="hemoroideok" name='hemoroideok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel7'} onChange={handlePanelChange('panel7')}>
                        <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                        <Typography variant='h5'>APPAREIL RESPIRATOIRE</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Antécédents ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="respirationantecedentok" name='respirationantecedentok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Séquelles ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="respirationsequellesok" name='respirationsequellesok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Le mouvement respiratoire est-il limité, asymétrique ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="respirationlimite" name='respirationlimite' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>La percussion montre-t-elle des matités anormales ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="percussionok" name='percussionok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>L’auscultation donne-t-elle des résultats anormaux ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="auscultationok" name='auscultationok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>La voix est-elle voilée ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="voixvoile" name='voixvoile' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel8'} onChange={handlePanelChange('panel8')}>
                        <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                        <Typography variant='h5'>APPAREIL CIRCULATOIRE</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                            <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Antécédents ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="circulationantecedentok" name='circulationantecedentok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Séquelles ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="circulationsequellesok" name='circulationsequellesok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Le cœur est-il agrandi ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="coeuragrandi" name='coeuragrandi' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Les bruits du cœur sont-ils modifiés ? (Intensité, dédoublement, etc…)</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="bruitcoeurok" name='bruitcoeurok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Entendez-vous un souffle cardiaque ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="souflecardiaquecoeurok" name='souflecardiaquecoeurok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                    {(detailsTab && detailsTab.includes('souflecardiaquecoeurok') && dataForm['souflecardiaquecoeurok'] ==='Oui')&&<Ask.Details>
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Comment se propage-t-il ? </Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomInput} id="propagationsoufle" name="propagationsoufle" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                            </Ask.Group>
                                        </Ask.Card> 
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Où est situé son maximum ?</Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Ask.SmallText variant='h5'>Intensité</Ask.SmallText><Field component={CustomInput} id="soufleintensite" name="soufleintensite" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/>
                                            </Ask.Group>
                                        </Ask.Card> 
                                        <Ask.Card>
                                            <Ask.Group><Ask.Title variant='body'>Le souffle est-il organique ?</Ask.Title></Ask.Group>
                                            <Ask.Group>
                                                <Field component={CustomRadio}  id="soufleorganiqueok" name='soufleorganiqueok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                            </Ask.Group>
                                            <Ask.group>
                                                    <Field component={CustomSimpleSelect} label ='Systolique' id="souflesystolique" name='souflesystolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[130,135,140,145,150,155,160,165,170,175,180,186]}/>
                                                    <Field component={CustomSimpleSelect} label ='Diastolique' id="souflediastolique" name='souflediastolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[75,80,85,90,95,100,105,106]}/>
                                            </Ask.group>
                                        </Ask.Card> 
                                    </Ask.Details>}
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Le pouls radial et pédieux est-il impalpable ou diminué ? </Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="poulsradialdiminue" name='poulsradialdiminue' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel9'} onChange={handlePanelChange('panel9')}>
                        <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                        <Typography variant='h5'>TENSION ARTERIELLE</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Field component={CustomSimpleSelect} label ='Systolique' style={{marginRight:20}} id="systolique" name='systolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[130,135,140,145,150,155,160,165,170,175,180,186]}/>
                                            <Field component={CustomSimpleSelect} label ='Diastolique' id="diastolique" name='diastolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[75,80,85,90,95,100,105,106]}/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Field component={CustomRadio}  id="esttraite" name='esttraite' formControlProps={{ fullWidth: false }} options={[{"label":"Traitée","value":"Traitée"},{"label":"Non traitée","value":"Non traitée"}]}  row/>
                                            
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Ask.SmallText variant='h5' style={{marginRight:20}}>Pouls</Ask.SmallText><Field component={CustomInput} id="pouls" name="pouls" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/><Ask.SmallText variant='h5'>/mn</Ask.SmallText>
                                            <Ask.SmallText variant='h5' style={{marginLeft:20,marginRight:20}}>Extrasystoles</Ask.SmallText><Field component={CustomInput} id="extrasystoles" name="extrasystoles" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/><Ask.SmallText variant='h5'>/mn</Ask.SmallText>
                                        </Ask.Group>
                                    </Ask.Card>
                                    <Divider />
                                    
                                </Ask.Inner>
                                <Ask.Inner>
                                    <p>Si les chiffres de la pression artérielle sont supérieurs à 150/90, veuillez effectuer un contrôle en fin d’examen après dix minutes de repos allongé. </p>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.SmallText variant='h5' style={{marginRight:20}}>Contrôle éventuel :</Ask.SmallText>
                                            <Field component={CustomSimpleSelect} label ='Systolique' style={{marginRight:20}} id="controlSystolique" name='controlSystolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[130,135,140,145,150,155,160,165,170,175,180,186]}/>
                                            <Field component={CustomSimpleSelect} label ='Diastolique' id="controldiastolique" name='controldiastolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[75,80,85,90,95,100,105,106]}/>
                                            <Ask.SmallText variant='h5' style={{marginRight:20}}>Pouls</Ask.SmallText><Field component={CustomInput} id="controlpouls" name="controlpouls" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/><Ask.SmallText variant='h5'>/mn</Ask.SmallText>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel10'} onChange={handlePanelChange('panel10')}>
                        <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                        <Typography variant='h5'>ORGANES GENITO-URINAIRES</Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il eu dans les antécédents une affection des organes génito-urinaires ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="affectiongenitalok" name='affectiongenitalok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <div style={{marginTop:20}} />
                                <Typography variant='h6'>Pour les personnes de sexe masculin :</Typography>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il des indices d’une affection des organes génitaux ? (testicules, épididymes,prostate)</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="indiceaffectionmasculinok" name='indiceaffectionmasculinok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une gynécomastie ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="gynecomastieok" name='gynecomastieok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <div style={{marginTop:20}} />
                                <Typography variant='h6'>Pour les personnes de sexe féminin :</Typography>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il des indices d’une affection des organes génitaux ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="indiceaffectionfemininok" name='indiceaffectionfemininok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une modification anormale des seins ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Field component={CustomRadio}  id="modificationanormale" name='modificationanormale' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <div style={{marginTop:20}} />
                                <Typography variant='h6'>EXAMEN DE L ’URINE (émise en présence du Médecin Examinateur)</Typography>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ASPECT</th>
                                            <th>ALBUMINE</th>
                                            <th>GLUCOSE</th>
                                            <th>PUS</th>
                                            <th>AUTRES ELEMENTS ANORMAUX</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td><Field component={CustomInput} id="urineaspect" name="urineaspect" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/></td>
                                            <td><Field component={CustomInput} id="urinealbumine" name="urinealbumine" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/></td>
                                            <td><Field component={CustomInput} id="urineglucose" name="urineglucose" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/></td>
                                            <td><Field component={CustomInput} id="urinepus" name="urinepus" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/></td>
                                            <td><Field component={CustomInput} id="urineautres" name="urineautres" style={{marginRight:10}} formControlProps={{ fullWidth: false }}  variant="outlined"/></td>
                                        </tr>
                                    </tbody>
                                    
                                </table>
                            </AccordionDetails>
                    </Accordion>
                    <Accordion square expanded={expanded === 'panel11'} onChange={handlePanelChange('panel11')}>
                        <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">
                        <Typography variant='h5'>SQUELETTE / SYSTEME NERVEUX </Typography>
                        </AccordionSummary>
                            <AccordionDetails style={{display:'flex',flexDirection:'column'}}>
                                <div style={{marginTop:20}} />
                                <Typography variant='h6'>SQUELETTE :</Typography>
                                <Divider />
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il une affection des os, des articulations, des disques intervertébraux</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="affectionos" name='affectionos' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>

                                <div style={{marginTop:20}} />
                                <Typography variant='h6'>SYSTEME NERVEUX :</Typography>
                                <Divider />
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Antécédents ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="systemeantecedentok" name='systemeantecedentok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Séquelles ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="systemesequellesok" name='systemesequellesok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Y a-t-il des réflexes pupillaires, abdominaux ou rotuliens anormaux ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="reflexpupillaireok" name='reflexpupillaireok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Existe-t-il des signes de dystonie neurovégétative ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="dystonieneurovegetativeok" name='dystonieneurovegetativeok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                                <Ask.Inner>
                                    <Ask.Card>
                                        <Ask.Group>
                                            <Ask.Title variant='h5'>Présence de troubles psychiques ou neurologiques ?</Ask.Title> 
                                        </Ask.Group>
                                        <Ask.Group>
                                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                            <Field component={CustomRadio}  id="troublespsychiqueok" name='troublespsychiqueok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                        </Ask.Group>
                                    </Ask.Card>
                                </Ask.Inner>
                            </AccordionDetails>
                    </Accordion>
                </div>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Y a-t-il des indices d’alcoolisme, de tabagisme, d’abus de médicaments ou d’usage de stupéfiants ?</Ask.Title> 
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio}  id="indicealcolismeok" name='indicealcolismeok' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Existe-t-il une répercussion des occupations professionnelles ou autres sur l’état de santé ?</Ask.Title> 
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio}  id="repercussionprook" name='repercussionprook' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <div style={{marginTop:20}} />
                <Typography variant='h4'> CONCLUSION</Typography>
                <Divider />
                <div style={{marginTop:20}} />
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Faites-vous des réserves sur la longévité de la personne à assurer ?</Ask.Title> 
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio}  id="reservelongevite" name='reservelongevite' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Estimez-vous qu’il existe des risques d’invalidité ou d’incapacité partielle ou totale ?</Ask.Title> 
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio}  id="riskinvalidite" name='riskinvalidite' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>L’état de santé de la personne à assurer peut-il être considéré comme :</Ask.Title> 
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field component={CustomRadio}  id="riskinvalidite" name='riskinvalidite' formControlProps={{ fullWidth: false }} options={[{"label":"BON","value":"BON"},{"label":"MEDIOCRE","value":"MEDIOCRE"},{"label":"DEFAVORABLE","value":"DEFAVORABLE"}]}  row/>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Typography variant='h6'>REMARQUES SPECIALES ET SUGGESTIONS DU MEDECIN</Typography>
                <ReactQuill theme="snow" value={remarque} onChange={handleChangeremarque} />                   
            </Ask>
            <div  className={clsx([classes.paper,classes.cardbox])} style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.btnback}>Annuler</Button>
                <Button variant="contained" color="primary" type='submit' className={classes.btnnext} large>Enregistrer</Button>
            </div>

        </div>
        
    </div>
  )
}


const selector = formValueSelector('addRapport')
const mapStateToProps = (state) => {
    return {
        api: state.api,
        ui: state.UI,
        user:state.user,
        dataForm:getFormValues('addRapport')(state),
        initialValues: state.form.addRapport?.values
    }
  };
const updateField =(field, data)=>(dispatch)=>dispatch(change('addRapport',field,data))
const mapActionsToProps = {
  updateField,
}
export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form:'addRapport',destroyOnUnmount:false,})(AddRapportMedical))

