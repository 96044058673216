import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, Typography,Divider,IconButton, Badge } from '@material-ui/core'
import clsx from 'clsx'
import Card from '@material-ui/core/Card';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardContent from '@material-ui/core/CardContent';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import EvolutionProduction from 'pages/stats/EvolutionProduction'
import ProductionParproduit from 'pages/stats/ProductionParproduit'
import {connect, useSelector} from 'react-redux'
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import { getTotProductionAgent } from 'redux/actions/apiActions'
import { useHistory,useRouteMatch } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import OperationStat from 'pages/Operations/components/OperationStat';
import PretStat from 'pages/Banking/Prets/components/PretStat';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CreateIcon from '@material-ui/icons/Create';
import PeopleIcon from '@material-ui/icons/People';
import SendIcon from '@material-ui/icons/Send';
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare';
import { green, red } from '@material-ui/core/colors';
const useStyle = makeStyles(theme=>({
    root:{
       display:'flex',
       flexDirection:'column'
    },
    homhead:{
        width:'100%',
       marginBottom:20,
       marginTop:0,
       backgroundColor:theme.palette.primary.main,
    },
    hpaper:{
        width:'85%',
        marginRight:'auto',
        marginLeft:'auto',
        [theme.breakpoints.down('sm')]: {
          width:'100%',
        },
        [theme.breakpoints.up('md')]: {
          width:'75%',
        },
        [theme.breakpoints.up('lg')]: {
          width:'60%',
        },
        marginTop:20,
        padding:20,
        border:'none',
        background:'transparent !important'
    },
    servicesBox:{
        display:'flex',
        flexDirection:'column',
        marginRight:'auto',
        marginLeft:'auto',
        [theme.breakpoints.down('sm')]: {
          width:'100%',
        },
        [theme.breakpoints.up('md')]: {
          width:'75%',
        },
        [theme.breakpoints.up('lg')]: {
          width:'50%',
        },
        marginTop:30,
        padding:20,
        
    },
    marge:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    texte:{
      color:'#fff'
    },
    card:{
      display:'flex',
      flex:1,
      flexDirection:'column',
      justifyContent:'space-around',
      padding:20,
      margin:20,
      alignItems:'center'
    },
    activityCard:{
      padding:20,
      margin:20,
    },
    activityContent:{
      display:'flex',
      justifyContent:'sapce-between'
    },
    activityItem1:{
      flex:2,
      margin:10
    },
    activityItem2:{
      flex:1,
      margin:10
    },
    mainitem:{
        display:'flex',
        flexDirection:'column',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        paddingBottom:20
    },
    iheader:{
        display:'flex',
        marginBottom:10,
        alignItems:'center',
        padding:10
    },
    icorps:{
        marginTop:10,
        padding:20,
        display:'flex'
    },
    icard:{
        display:'flex',
        justifyContent:'center',
        flex:1,
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);",
            backgroundColor:green[100]
        },
        cursor:'pointer',
        margin:20
    },
    iStat:{
        display:'flex',
        marginTop:20,
        marginBottom:20
    },
    igraph:{
        display:'flex',
        justifyContent:'space-around'
    },
    cardLogo:{
        position:'absolute',
        top:-50,
        width:100,
        height:100
    },
    img:{
        objectFit: 'fill',
        width:100
    },
    cardState:{
        display:'flex',
        width:'100%',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    cardpar:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3)",
        marginBottom:20,
        position:'relative',
        padding:20,
        marginTop:70
    },
    itcard:{
        display:'flex',
        flex:1,
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)"
        },
        cursor:'pointer'
    },
    numberState:{
        fontSize:35,
        color:theme.palette.primary.main,
        textAlign:'center'
    },
    numberAj:{
        fontSize:35,
        color:red[500],
        textAlign:'center'
    },
    labelState:{
        color:'#666',
        fontWeight:'bold',
        textAlign:'center'
    },
    cardadd:{
        marginLeft:20,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:theme.palette.primary.main,
        borderRadius:0,
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);",
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
        }
    },
    stateBox:{
        display:'flex',
        padding:20,
        marginBottom:20,
        border:'0px 10px 0px 10px',
        borderRadius:'0px 20px 0px 20px',
        borderColor:green[500],
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
    },
    cardrapide:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:0,
        flex:1,
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:'white'
        }
    },
    contentar:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
   
  }))
const MedecinHome = () => {
    const classes =useStyle()
    let history = useHistory();
    const {path,url} = useRouteMatch()
    const handleNavigate = (route,lst)=>{
        history.push(`/medecin/${route}`)
    }

  return (
    <div className={classes.root}>
        <div className={classes.homhead}>
            <Paper className={classes.hpaper} variant="outlined">
                <div>
                    <Typography className={classes.texte} variant='h4'> Bienvenue  dans l'espace medécin de Ynov</Typography>
                    <Typography className={clsx([classes.texte])} variant='caption'> Patient,Saisie de rapport, Analyse ....</Typography>
                </div>
            </Paper> 
        </div>
        
            <div className={classes.servicesBox}>
                     <Paper className={classes.stateBox} variant='outlined'>
                     <div className={classes.cardState}>
                            <span className={classes.numberState}>00</span>
                            <span className={classes.labelState}>
                                 Dossier réçu
                            </span>
                        </div>
                        <div className={classes.cardState}>
                            <span className={classes.numberState}>00</span>
                            <span className={classes.labelState}>
                                 Patient traité
                            </span>
                        </div>
                        <div className={classes.cardState}>
                            <span className={classes.numberState}>00</span>
                            <span className={classes.labelState}>
                                Rapport en attente
                            </span>
                        </div>
                        <div className={classes.cardState}>
                            <span className={classes.numberState}>00</span>
                            <span className={classes.labelState}>
                                Rapport émis
                            </span>
                        </div>
                        <div className={classes.cardState}>
                            <span className={classes.numberAj}>00</span>
                            <span className={classes.labelState}>
                                Dossier ajourné
                            </span>
                        </div>
                     </Paper>
                    <div>
                        <Typography variant='h5'>Actions</Typography>
                        <Card className={classes.icard} onClick={()=>handleNavigate('liste-patient','p-new')}>
                            <CardContent>
                                <Badge badgeContent='01' color="error">
                                    <PeopleOutlineIcon style={{ color:'#f9b233', fontSize:60 }} />
                                </Badge>
                                <Typography variant="h5" component="h3">
                                   Nouveau patient
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.icard} onClick={()=>handleNavigate('add-rapport-medical','rp-new')} >
                            <CardContent>
                                <CreateIcon style={{ color:'#f9b233', fontSize:60 }} />
                                <Typography variant="h5" component="h3">
                                    Rédiger un rapport
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                    <Typography variant='h5'>Détails des traitements</Typography>
                    <div  style={{diaplay:'flex'}}>
                        <Card className={classes.icard} onClick={()=>handleNavigate('liste-patient','p-traite')}>
                            <CardContent>
                                <PeopleIcon style={{ color:'#f9b233', fontSize:60 }} />
                                <Typography variant="h5" component="h3">
                                    Liste des patients traités
                                </Typography>
                                
                            </CardContent>
                        </Card>
                        <Card className={classes.icard} onClick={()=>handleNavigate('liste-rapport-medical','rp-non-emis')}>
                            <CardContent>
                                <Badge badgeContent={4} color="success">
                                    <StopScreenShareIcon style={{ color:'#f9b233', fontSize:60 }} />
                                </Badge>
                                <Typography variant="h5" component="h3">
                                    Liste des rapports non émis
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.icard} onClick={()=>handleNavigate('liste-rapport-medical','rp-emis')}>
                            <CardContent>
                                <SendIcon style={{ color:'#f9b233', fontSize:60 }} />
                                <Typography variant="h5" component="h3">
                                    Liste des rapports emis
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                        
            </div>
           
    </div>
  )
}

export default MedecinHome
