import React,{useRef,useState,useEffect,useReducer} from 'react'
import { Paper,Tooltip,IconButton,Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import HomeIcon from '@material-ui/icons/Home';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { connect, useDispatch } from 'react-redux'
import { reduxForm, change, registerField, getFormValues, reset } from 'redux-form'
import dayjs from 'dayjs'
import { Add } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomMenu from './CustomMenu'
import 'jspdf-autotable'
import CustomAlert from 'components/CustomAlert/CustomAlert'
import {
  selectPropositionByUser,
  getCapitauxGaranies,
  getGarantiesAssure,
  getPrimesCapital,
  updateProposition,
  selectGarantiesContrat,
  selectAssuresContrat,
  selectBeneficairesContrat,
  selectPropositionByUserNoDispatch,
  getGarantiesProduit
} from 'redux/actions/apiActions'
import MaterialTable from 'material-table'
import { ageAssure } from 'redux/actions/userActions'
import XLSX from 'xlsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  getEquipesReseau,
  getEquipesZones,
  getZonesReseau,
  getZonesNoDispatch,
  getEquipesNoDispatch
} from 'redux/actions/settingsAction'

import {useHistory} from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'styles/styles.css'


const useStyle = makeStyles(theme=>({
    root:{
      width:'85%',
      marginLeft:'auto',
      marginRight:'auto'
    },
    hPaper:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8,
      display:'flex',
      justifyContent:'space-between'
    },
    hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
    },
    bCorps:{
      display:'flex',
      justifyContent:'space-between',
      padding:20
    },
    formContainer:{
      width:'75%',
      padding:20
    },
    filterPaper:{
        padding:10,
        backgroundColor:'transparent',
        borderRadius:10,
        marginBottom:20,
    },
    contentbox:{
        padding:20
    },
    filterForm: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      filterItem:{
        display:'flex',
        flex:1,
        justifyContent:'flex-start',
        alignItems:'center',
        marginRight:20
      },
      textField:{
        margin:5
      },
      nav:{
        backgroundColor:'#66bb6a',
        borderRadius:10,
        '&:hover':{
            backgroundColor:'#66bb6a',
        }
      },
      navLik:{
        fontSize:'14px',
        padding:10,
        
      },
      linked:{
        fontSize:'16px',
        color:'#ffffff',
        
        '&:active':{
            color:theme.palette.primary.main
        },
        '&:focus':{
            color:theme.palette.primary.main
        }
      },
      buttonSearch: {
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
    
        color: '#FFF',
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        }
      }
  }))
  
  const exportToExcel = (columns, data) => {
    // determining header labels
    const columnInfo = columns.reduce(
      (acc, column) => {
        const headerLabel = column.title || column.field;
        acc.header.push(headerLabel);
        acc.map[column.field] = headerLabel;
        return acc;
      },
      { map: {}, header: [] }
    );
  
    const mappedData = data.map(row =>
      Object.entries(row).reduce((acc, [key, value]) => {
        if (columnInfo.map[key]) {
          acc[columnInfo.map[key]] = value;
        }
        return acc;
      }, {})
    );
  
    var ws = XLSX.utils.json_to_sheet(mappedData, {
      header: columnInfo.header, // garanties the column order same as in material-table
    });
  
    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    /* generate an XLSX file and triggers the browser to generate the download*/
    XLSX.writeFile(wb, `LLV-liste-Proposition.xlsx`);
  };
  
  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }

function PropositionList(props) {
    let containerref =useRef()
    const classes =useStyle()
    const {handleFonctionShow, user: {credentials }, ui: { layoutRoot } } = props
    const dispatch = useDispatch()
    const history = useHistory();

    const [propoData, setPropoData] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      propoEnAttente: null,
      propoTransmis: null,
      propoAccepter: null,
      propoRejeter: null,
      propoAnnuler: null,
    });

 const [dopen, setDopen] = useState(false)
  const handleDopen = (etape) => {
    setDopen(etape)
  }
  const [dinfo, setDinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      ddata: [],
      dmessage: null,
      ddescription: null,
      dTexteButton: null,
      dmode: null,
      daction: null
    });

  const [aopen, setAopen] = useState(false)
  const [aInfo, setAinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      amessage: null,
      amode: null,
    });
  const affectationDuree = (dureeCotisationMin, dureeCotisationMax, dureeContratMin, dureeContratMax, optionnelle) => {
        const { updateField } = props
        //durée cotisation mini
        if (!props.hasOwnProperty('data')) {
          props.dispatch(registerField("adForm", 'dureecontratmin', 'Field'));
        } else {
          if (optionnelle && (parseInt(dureeContratMin) > parseInt(props.data.dureecontratmin))) { dureeContratMin = props.data.dureecontratmin }
        }
        updateField('dureecontratmin', dureeContratMin)
    
        //durée cotisation maximum
        if (!props.hasOwnProperty('data')) {
          props.dispatch(registerField("adForm", 'dureecontratmax', 'Field'));
        } else {
          if (optionnelle && (parseInt(dureeContratMax) < parseInt(props.data.dureecontratmax))) { dureeContratMax = props.data.dureecontratmax }
        }
        updateField('dureecontratmax', dureeContratMax)
        //duré cotisation mini
        if (!props.hasOwnProperty('data')) {
          props.dispatch(registerField("adForm", 'dureecotisationmin', 'Field'));
        } else {
          if (optionnelle && (parseInt(dureeCotisationMin) > parseInt(props.data.dureecotisationmin))) { dureeCotisationMin = props.data.dureecotisationmin }
        }
        updateField('dureecotisationmin', dureeCotisationMin)
        //durée cotisation max
        if (!props.hasOwnProperty('data')) {
          props.dispatch(registerField("adForm", 'dureecotisationmax', 'Field'));
        } else {
          if (optionnelle && (parseInt(dureeCotisationMax) < parseInt(props.data.dureecotisationmax))) { dureeCotisationMax = props.data.dureecotisationmax }
        }
        updateField('dureecotisationmax', dureeCotisationMax)
    
  }
  const editProposition = async (donnees) => {
        props.dispatch(reset('adForm'))
        if (donnees) {
          if (!props.data || !props.data.hasOwnProperty('civiliteSous')) {
            props.dispatch(registerField("adForm", 'civiliteSous', 'Field'));
          }
          props.dispatch(registerField("adForm", 'civiliteSous', 'Field'));
          if (donnees && donnees.civilite && donnees.civilite !== null) props.updateField('civiliteSous', donnees.civilite)
    
          if (!props.data || !props.data.hasOwnProperty('nomSous')) {
            props.dispatch(registerField("adForm", 'nomSous', 'Field'));
          }
          if (donnees && donnees.nom && donnees.nom !== null) props.updateField('nomSous', donnees.nom)
    
          if (!props.data || !props.data.hasOwnProperty('prenomSous')) {
            props.dispatch(registerField("adForm", 'prenomSous', 'Field'));
          }
          if (donnees && donnees.prenom && donnees.prenom !== null) props.updateField('prenomSous', donnees.prenom)
    
          if (!props.data || !props.data.hasOwnProperty('dateNaissanceSous')) {
            props.dispatch(registerField("adForm", 'dateNaissanceSous', 'Field'));
          }
          if (donnees && donnees.datenaissance && donnees.datenaissance !== null) props.updateField('dateNaissanceSous', dayjs(donnees.datenaissance).format('YYYY-MM-DD'))
    
          if (!props.data || !props.data.hasOwnProperty('lieuNaissanceSous')) {
            props.dispatch(registerField("adForm", 'lieuNaissanceSous', 'Field'));
          }
          if (donnees && donnees.lieunaissance && donnees.lieunaissance !== null) props.updateField('lieuNaissanceSous', donnees.lieunaissance)
    
          if (!props.data || !props.data.hasOwnProperty('sexeSous')) {
            props.dispatch(registerField("adForm", 'sexeSous', 'Field'));
          }
          if (donnees && donnees.sexe && donnees.sexe !== null) props.updateField('sexeSous', donnees.genre)
    
          if (!props.data || !props.data.hasOwnProperty('pieceIdentiteSous')) {
            props.dispatch(registerField("adForm", 'pieceIdentiteSous', 'Field'));
          }
          if (donnees && donnees.numeropiece && donnees.numeropiece != null) props.updateField('pieceIdentiteSous', donnees.numeropiece)
    
          if (!props.data || !props.data.hasOwnProperty('naturePiece')) {
            props.dispatch(registerField("adForm", 'naturePiece', 'Field'));
          }
          if (donnees && donnees.naturepiece && donnees.naturepiece !== null) props.updateField('naturePiece', donnees.naturepiece)
    
          if (!props.data || !props.data.hasOwnProperty('lieuResidenceSous')) {
            props.dispatch(registerField("adForm", 'lieuResidenceSous', 'Field'));
          }
          if (donnees && donnees.lieuresidence && donnees.lieuresidence !== null) props.updateField('lieuResidenceSous', donnees.lieuresidence)
    
          if (!props.data || !props.data.hasOwnProperty('professionSous')) {
            props.dispatch(registerField("adForm", 'professionSous', 'Field'));
          }
          if (donnees && donnees.profession && donnees.profession !== null) props.updateField('professionSous', donnees.profession)
    
          if (!props.data || !props.data.hasOwnProperty('employeurSous')) {
            props.dispatch(registerField("adForm", 'employeurSous', 'Field'));
          }
          if (donnees && donnees.employeur && donnees.employeur !== null) props.updateField('employeurSous', donnees.employeur)
    
          //contact
          if (!props.data || !props.data.hasOwnProperty('adressepostale')) {
            props.dispatch(registerField("adForm", 'adressepostale', 'Field'));
          }
          if (donnees && donnees.adressepostale && donnees.bp !== null) props.updateField('adressepostale', donnees.bp)
    
          if (!props.data || !props.data.hasOwnProperty('telephoneSous')) {
            props.dispatch(registerField("adForm", 'telephoneSous', 'Field'));
          }
          if (donnees && donnees.telephone && donnees.telephone !== null) props.updateField('telephoneSous', donnees.telephone)
    
          if (!props.data || !props.data.hasOwnProperty('emailSous')) {
            props.dispatch(registerField("adForm", 'emailSous', 'Field'));
          }
          if (donnees && donnees.email && donnees.email !== 'null' && donnees.email !== null) props.updateField('emailSous', donnees.email)
    
          if (!props.data || !props.data.hasOwnProperty('mobileSous')) {
            props.dispatch(registerField("adForm", 'mobileSous', 'Field'));
          }
          if (donnees && donnees.mobile && donnees.mobile !== null) props.updateField('mobileSous', donnees.mobile)
    
          if (!props.data || !props.data.hasOwnProperty('mobile2Sous')) {
            props.dispatch(registerField("adForm", 'mobile2Sous', 'Field'));
          }
          if (donnees && donnees.mobile1 && donnees.mobile1 !== null) props.updateField('mobile2Sous', donnees.mobile1)
    
          if (!props.data || !props.data.hasOwnProperty('produit')) {
            props.dispatch(registerField("adForm", 'produit', 'Field'));
          }
          if (donnees && donnees.codeproduit && donnees.codeproduit !== null) {
            const { produits } = props.api
            if (produits && produits.length !== 0) {
    
              const currentproduit = produits.filter(item => item.CodeProduit === donnees.codeproduit)[0]
    
              props.updateField('produit', currentproduit)
            }
          }
    
    
          //personneressource
          if (!props.data || !props.data.hasOwnProperty('personneressource')) {
            props.dispatch(registerField("adForm", 'personneressource', 'Field'));
          }
          if (donnees && donnees.personneressource && donnees.personneressource !== null) props.updateField('personneressource', `${donnees.personneressource}`)
    
          //contactpersonneressource
          if (!props.data || !props.data.hasOwnProperty('contactpersonneressource')) {
            props.dispatch(registerField("adForm", 'contactpersonneressource', 'Field'));
          }
          if (donnees && donnees.contactpersonneressource && donnees.contactpersonneressource !== null) props.updateField('contactpersonneressource', `${donnees.contactpersonneressource}`)
    
          //personneressource2
          if (!props.data || !props.data.hasOwnProperty('personneressource2')) {
            props.dispatch(registerField("adForm", 'personneressource2', 'Field'));
          }
          if (donnees && donnees.personneressource2 && donnees.personneressource2 !== null) props.updateField('personneressource2', `${donnees.personneressource2}`)
    
          //contactpersonneressource2
          if (!props.data || !props.data.hasOwnProperty('contactpersonneressource2')) {
            props.dispatch(registerField("adForm", 'contactpersonneressource2', 'Field'));
          }
          if (donnees && donnees.contactpersonneressource2 && donnees.contactpersonneressource2 !== null) props.updateField('contactpersonneressource2', `${donnees.contactpersonneressource2}`)
    
          //code adherent
          if (!props.data || !props.data.hasOwnProperty('codeadherent')) {
            props.dispatch(registerField("adForm", 'codeadherent', 'Field'));
          }
          if (donnees && donnees.codeadherent && donnees.codeadherent !== null) props.updateField('codeadherent', `${donnees.codeadherent}`)
    
          if (!props.data || !props.data.hasOwnProperty('mode')) {
            props.dispatch(registerField("adForm", 'mode', 'Field'));
          }
          props.updateField('mode', "UPDATE")
    
          //codeConseiller
          if (!props.data || !props.data.hasOwnProperty('codeagent')) {
            props.dispatch(registerField("adForm", 'codeagent', 'Field'));
          }
          if (donnees && donnees.codeConseiller && donnees.codeConseiller !== null) props.updateField('codeagent', `${donnees.codeConseiller}`)
    
          //nomagent
          if (!props.data || !props.data.hasOwnProperty('nomagent')) {
            props.dispatch(registerField("adForm", 'nomagent', 'Field'));
          }
          if (donnees && donnees.nomagent && donnees.nomagent !== null) props.updateField('nomagent', `${donnees.nomagent}`)
    
          //dateeffet
          if (!props.data || !props.data.hasOwnProperty('dateEffet')) {
            props.dispatch(registerField("adForm", 'dateEffet', 'Field'));
          }
          if (donnees && donnees.dateeffet && donnees.dateeffet !== null) props.updateField('dateEffet', `${dayjs(donnees.dateeffet).format('YYYY-MM-DD')}`)
    
          //capital
          if (!props.data || !props.data.hasOwnProperty('capitalSouscrit')) {
            props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
          }
          if (donnees && donnees.capital && donnees.capital !== null) props.updateField('capitalSouscrit', `${donnees.capital}`)
          //duree
          if (!props.data || !props.data.hasOwnProperty('duree')) {
            props.dispatch(registerField("adForm", 'duree', 'Field'));
          }
          if (donnees && donnees.duree && donnees.duree !== null) props.updateField('duree', `${donnees.duree}`)
          //dureerente
          if (!props.data || !props.data.hasOwnProperty('dureerente')) {
            props.dispatch(registerField("adForm", 'dureerente', 'Field'));
          }
          if (donnees && donnees.dureerente && donnees.dureerente !== null) props.updateField('dureerente', `${donnees.dureerente}`)
          //id
          if (!props.data || !props.data.hasOwnProperty('id')) {
            props.dispatch(registerField("adForm", 'id', 'Field'));
          }
          if (donnees && donnees.id && donnees.id !== null) props.updateField('id', `${donnees.id}`)
    
          //organisme
          if (!props.data || !props.data.hasOwnProperty('organismePayeur')) {
            props.dispatch(registerField("adForm", 'organismePayeur', 'Field'));
          }
          if (donnees && donnees.organisme && donnees.organisme !== null) props.updateField('organismePayeur', `${donnees.organisme}`)
    
          //periodicite
          if (!props.data || !props.data.hasOwnProperty('periodicite')) {
            props.dispatch(registerField("adForm", 'periodicite', 'Field'));
          }
          if (donnees && donnees.periodicite && donnees.periodicite !== null) props.updateField('periodicite', `${donnees.periodicite}`)
          //periodiciterente
          if (!props.data || !props.data.hasOwnProperty('periodiciterente')) {
            props.dispatch(registerField("adForm", 'periodiciterente', 'Field'));
          }
          if (donnees && donnees.periodiciterente && donnees.periodiciterente !== null) props.updateField('periodiciterente', `${donnees.periodiciterente}`)
    
          //montant de la rente
          if (!props.data || !props.data.hasOwnProperty('montantrente')) {
            props.dispatch(registerField("adForm", 'montantrente', 'Field'));
          }
          if (donnees && donnees.montantrente && donnees.montantrente !== null) props.updateField('montantrente', `${donnees.montantrente}`)
    
          //primepricipale
          if (!props.data || !props.data.hasOwnProperty('primePrincipale')) {
            props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
          }
          if (donnees && donnees.primepricipale && donnees.primepricipale !== null) props.updateField('primePrincipale', `${donnees.primepricipale}`)
          //surprime
          if (!props.data || !props.data.hasOwnProperty('surprime')) {
            props.dispatch(registerField("adForm", 'surprime', 'Field'));
          }
          if (donnees && donnees.surprime && donnees.surprime !== null) props.updateField('surprime', `${donnees.surprime}`)
    
          //Mode paiement
          if (!props.data || !props.data.hasOwnProperty('modePaiement')) {
            props.dispatch(registerField("adForm", 'modePaiement', 'Field'));
          }
          if (donnees && donnees.modepaiement && donnees.modepaiement !== null) props.updateField('modepaiement', `${donnees.modepaiement}`)
    
          if (!props.data || !props.data.hasOwnProperty('numCompte')) {
            props.dispatch(registerField("adForm", 'numCompte', 'Field'));
          }
          if (donnees && donnees.numerocompte && donnees.numerocompte !== null) props.updateField('numCompte', donnees.numerocompte)
          var garantieProd
          if (!props.data || !props.data.hasOwnProperty('codeproduit')) {
            garantieProd = await dispatch(getGarantiesProduit(donnees.codeproduit))
          }
    
          if (garantieProd) {
            selectAssuresContrat(donnees.id).then(async (resAss) => {
              let dataAssure = []
              const addAssure = (defaultData, codeproduit) => {
                //selection des garanties
                let dureeCotisationMin = undefined
                let dureeCotisationMax = undefined
                let dureeContratMin = undefined
                let dureeContratMax = undefined
                let garantiesObligatoires
                let garantiesComplementaires
                let garantiesCompFacultatif
                let dateNaissance
                const dataGaranties = Array.from(defaultData.garantiesSouscrits)
                dateNaissance = defaultData.dateNaissanceAssure
                //récupération des garanties pour l'assuré
                let disponibiliteGarantie = []
                props.getGarantiesAssure(codeproduit, ageAssure(dateNaissance)).then((garanties) => {
                  if (garanties) {
                    garantiesObligatoires = garanties.filter(item => item.estobligatoire == 1)
                    garantiesCompFacultatif = garanties.filter(item => (item.estobligatoire == 0 && item.estcomplementaire == 0))
                    garantiesComplementaires = garanties.filter(item => (item.estobligatoire == 0 && item.estcomplementaire == 1))
    
                    let garantiesParcourru
                    defaultData.garantiesFacultatives = []
    
                    if ((garantiesObligatoires && garantiesObligatoires.length !== 0) || (garantiesCompFacultatif && garantiesCompFacultatif.length !== 0)) {
                      let result = false
                      let newGarantieOb = []
    
                      newGarantieOb = garantiesObligatoires.filter(garO => { return dataGaranties.findIndex(el => el.CodeProduitGarantie == garO.codeproduitgarantie) !== -1 })
                      garantiesObligatoires = newGarantieOb
                      garantiesParcourru = (garantiesObligatoires && garantiesObligatoires.length !== 0) ? garantiesObligatoires : garantiesCompFacultatif
    
                      defaultData.garantiesSouscrits = dataGaranties.filter((item) => {
                        result = false
                        garantiesParcourru.forEach(async (element) => {
                          if (element.codeproduitgarantie === item.CodeProduitGarantie) {
                            result = true;
                            item.estunique = element.estunique
                            item.description = element.description
                            item.checked = true
                            item.agemin = element.agemin
                            item.agemax = element.agemax
                            item.dureecotisationmin = element.dureecotisationmin
                            item.dureecotisationmax = element.dureecotisationmax
                            item.dureecontratmin = element.dureecontratmin
                            item.dureecontratmax = element.dureecontratmax
                            item.primemin = element.primemin
                            item.montantgarantie = element.montantgarantie
                            item.type = element.type
                            item.estobligatoire = element.estobligatoire
                            item.estprincipal = element.estprincipal
                            item.estcomplementaire = element.estcomplementaire
                            item.branche = element.branche
                            if (element.type === 'Decces') {
                              getPrimesCapital(donnees.codeproduit, item.CodeProduitGarantie).then(primec => {
                                item.capitaux = primec
                              })
                            } else {
                              getCapitauxGaranies(donnees.codeproduit, item.CodeProduitGarantie).then(cpGar => {
                                item.capitaux = cpGar
                              })
                            }
    
                            if (dureeCotisationMin !== undefined && element.dureecotisationmin < dureeCotisationMin) {
                              dureeCotisationMin = element.dureecotisationmin
                            } else {
                              dureeCotisationMin = element.dureecotisationmin
                            }
                            if (dureeCotisationMax !== undefined && element.dureecotisationmax > dureeCotisationMax) {
                              dureeCotisationMax = element.dureecotisationmax
                            } else {
                              dureeCotisationMax = element.dureecotisationmax
                            }
                            if (dureeContratMin !== undefined && element.dureecontratmin < dureeContratMin) {
                              dureeContratMin = element.dureecontratmin
                            } else {
                              dureeContratMin = element.dureecontratmin
                            }
                            if (dureeContratMax !== undefined && element.dureecontratmax > dureeContratMax) {
                              dureeContratMax = element.dureecontratmax
                            } else {
                              dureeContratMax = element.dureecontratmax
                            }
    
                            if (element.estunique == 1) {
                              disponibiliteGarantie.push(item.CodeProduitGarantie)
                            }
                            if (garantiesObligatoires.length !== 0 && element.estobligatoire === 0) {
                              defaultData.garantiesFacultatives.push(item)
                            }
                            if (donnees.codeproduit === 'YKL_2004' || donnees.codeproduit === 'YKV_2004' || donnees.codeproduit === 'YKF_2004') {
                              const ageAss = parseInt(ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD')))
                              const maDuree = (70 - ageAss)
                              if (maDuree) {
                                dureeCotisationMin = maDuree
                                dureeCotisationMax = maDuree
                                dureeContratMin = maDuree
                                dureeContratMax = maDuree
                              }
                            }
                            element.estprincipal == 1 && affectationDuree(dureeCotisationMin, dureeCotisationMax, dureeContratMin, dureeContratMax, false)
                            //affectationDuree(dureeCotisationMin,dureeCotisationMax,dureeContratMin,dureeContratMax,false)
                            return
                          }
                        });
                        return result
                      })
                      //
                      if (garantiesComplementaires && garantiesComplementaires.length !== 0) {
    
                        defaultData.garantiesFacultatives = dataGaranties.filter((item) => {
    
                          result = false
                          garantiesComplementaires.forEach(async (element) => {
                            if (element.codeproduitgarantie === item.CodeProduitGarantie) {
    
                              result = true;
                              item.checked = false
                              item.estunique = element.estunique
                              item.description = element.description
                              item.agemin = element.agemin
                              item.agemax = element.agemax
                              item.dureecotisationmin = element.dureecotisationmin
                              item.dureecotisationmax = element.dureecotisationmax
                              item.dureecontratmin = element.dureecontratmin
                              item.dureecontratmax = element.dureecontratmax
                              item.dureecontratmax = element.dureecontratmax
                              item.primemin = element.primemin
                              item.montantgarantie = element.montantgarantie
                              item.type = element.type
                              item.estobligatoire = element.estobligatoire
                              item.estcomplementaire = element.estcomplementaire
                              item.estprincipal = element.estprincipal
                              item.branche = element.branche
                              if (element.type === 'Decces') {
                                getPrimesCapital(donnees.codeproduit, item.CodeProduitGarantie).then(primec => {
                                  item.capitaux = primec
                                })
                              } else {
                                getCapitauxGaranies(donnees.codeproduit, item.CodeProduitGarantie).then(cpGar => {
                                  item.capitaux = cpGar
                                })
                              }
                              if (element.estunique == 1) {
                                disponibiliteGarantie.push(item.CodeProduitGarantie)
                              }
                              return
                            }
                          });
                          return result
                        })
                      }
                      defaultData.monIndex = (dataAssure.length + 1)
                      dataAssure.push(defaultData)
                    }
                  }
                })
              }
              let localGaranties
              if (resAss) {
                if (Array.isArray(resAss)) {
                  resAss.map(async (res, idx) => {
                    localGaranties = Array.from(garantieProd)
                    const mesGaranties = []
                    const garanties = await selectGarantiesContrat(donnees.id)
                    if (!props.data || !props.data.hasOwnProperty('garantiesContrat')) {
                      props.dispatch(registerField("adForm", 'garantiesContrat', 'Field'));
                    }
                    if (garanties) props.updateField('garantiesContrat', garanties)
    
                    if (garanties) {
                      if (Array.isArray(garanties)) {
                        garanties.map(gar => {
                          const currentGarantie = localGaranties.filter(item => item.CodeProduitGarantie === gar.codeproduitgarantie)[0]
                          currentGarantie['checked'] = true
                          currentGarantie['id'] = gar.id
                          currentGarantie['capitalGarantie'] = gar.capitalgarantie
                          currentGarantie['prime'] = gar.prime
                          currentGarantie['primeTotal'] = gar.primetotal
                          mesGaranties.push(currentGarantie)
                        })
                      } else {
                        const currentGarantie = localGaranties.filter(item => item.CodeProduitGarantie === garanties.codeproduitgarantie)[0]
                        currentGarantie['id'] = garanties.id
                        currentGarantie['capitalGarantie'] = garanties.capitalgarantie
                        currentGarantie['prime'] = garanties.prime
                        currentGarantie['primeTotal'] = garanties.primetotal
                        currentGarantie['checked'] = true
                        mesGaranties.push(currentGarantie)
                      }
                    }
    
                    addAssure({
                      id: res.id,
                      numeroCniAssure: res.numeropiece ? res.numeropiece : "",
                      nomAssure: res.nom ? res.nom : "",
                      prenomAssure: res.prenom ? res.prenom : "",
                      dateNaissanceAssure: res.datenaissance ? dayjs(res.datenaissance).format('DD/MM/YYYY') : "",
                      lieuNaissanceAssure: res.lieunaissance ? res.lieunaissance : "",
                      lieuResidenceAssure: res.lieuresidence ? res.lieuresidence : "",
                      filiationAssure: res.filiation ? res.filiation : "",
                      emailAssure: res.email ? res.email : "",
                      telephoneAssure: res.mobile ? res.mobile : "",
                      monIndex: idx,
                      garantiesSouscrits: mesGaranties
                    }, donnees.codeproduit)
                  })
                } else {
                  localGaranties = Array.from(garantieProd)
                  const garanties = await selectGarantiesContrat(donnees.id)
                  if (!props.data || !props.data.hasOwnProperty('garantiesContrat')) {
                    props.dispatch(registerField("adForm", 'garantiesContrat', 'Field'));
                  }
                  if (garanties) props.updateField('garantiesContrat', garanties)
                  const mesGaranties = []
                  if (garanties) {
                    if (Array.isArray(garanties)) {
                      garanties.map(gar => {
                        const currentGarantie = localGaranties.filter(item => item.CodeProduitGarantie === gar.codeproduitgarantie)[0]
                        currentGarantie.id = gar.id
                        currentGarantie.checked = true
                        currentGarantie.capitalGarantie = gar.capitalgarantie
                        currentGarantie.prime = gar.prime
                        currentGarantie.primeTotal = gar.primetotal
                        mesGaranties.push(currentGarantie)
                      })
                    } else {
                      const currentGarantie = localGaranties.filter(item => item.CodeProduitGarantie === garanties.codeproduitgarantie)[0]
                      currentGarantie.id = garanties.id
                      currentGarantie.checked = true
                      currentGarantie.capitalGarantie = garanties.capitalgarantie
                      currentGarantie.prime = garanties.prime
                      currentGarantie.primeTotal = garanties.primetotal
                      mesGaranties.push(currentGarantie)
                    }
                  }
    
                  addAssure({
                    id: resAss.id,
                    nomAssure: resAss.nom ? resAss.nom : "",
                    prenomAssure: resAss.prenom ? resAss.prenom : "",
                    dateNaissanceAssure: resAss.datenaissance ? dayjs(resAss.datenaissance).format('DD/MM/YYYY') : "",
                    lieuNaissanceAssure: resAss.lieunaissance ? resAss.lieunaissance : "",
                    numeroCniAssure: resAss.numeropiece ? resAss.numeropiece : "",
                    lieuResidenceAssure: resAss.lieuresidence ? resAss.lieuresidence : "",
                    filiationAssure: resAss.filiation ? resAss.filiation : "",
                    emailAssure: resAss.email ? resAss.email : "",
                    telephoneAssure: resAss.mobile ? resAss.mobile : "",
                    garantiesSouscrits: mesGaranties,
                    monIndex: 1
                  }, donnees.codeproduit)
                }
              }
              if (!props.data || !props.data.hasOwnProperty('assures')) {
                props.dispatch(registerField("adForm", 'assures', 'Field'));
              }
              if (dataAssure) props.updateField('assures', dataAssure)
            })
          }
    
          selectBeneficairesContrat(donnees.id).then((res, idx) => {
            let dataBeneficaires = []
    
            if (res) {
    
              if (Array.isArray(res)) {
                res.map((ben, i) => {
                  dataBeneficaires.push({
                    id: ben.id,
                    nomBeneficiaire: ben.nom ? ben.nom : "",
                    prenomBeneficiaire: ben.prenom ? ben.prenom : "",
                    dateNaissanceBeneficiaire: ben.datenaissance ? ben.datenaissance : "",
                    lieuNaissanceBeneficiaire: ben.lieunaissance ? ben.lieunaissance : "",
                    lieuResidenceBeneficiaire: ben.lieuresidence ? ben.lieuresidence : "",
                    filiationBeneficiaire: ben.filiation ? ben.filiation : "",
                    emailBeneficiaire: ben.email ? ben.email : "",
                    telephoneBeneficiaire: ben.mobile ? ben.mobile : "",
                    taux: ben.taux ? ben.part : ben.part,
                    monIndex: i,
                    type: null
                  })
                })
    
              } else {
                dataBeneficaires.push({
                  id: res.id,
                  nomBeneficiaire: res.nom ? res.nom : "",
                  prenomBeneficiaire: res.prenom ? res.prenom : "",
                  dateNaissanceBeneficiaire: res.datenaissance ? res.datenaissance : "",
                  lieuNaissanceBeneficiaire: res.lieunaissance ? res.lieunaissance : "",
                  lieuResidenceBeneficiaire: res.lieuresidence ? res.lieuresidence : "",
                  filiationBeneficiaire: res.filiation ? res.filiation : "",
                  emailBeneficiaire: res.email ? res.email : "",
                  telephoneBeneficiaire: res.mobile ? res.mobile : "",
                  taux: res.taux && res.taux,
                  monIndex: 1,
                  type: null
                })
              }
            }
    
            if (!props.data || !props.data.hasOwnProperty('beneficiaires')) {
              props.dispatch(registerField("adForm", 'beneficiaires', 'Field'));
            }
            if (dataBeneficaires) props.updateField('beneficiaires', dataBeneficaires)
          })
    
          if (!props.data || !props.data.hasOwnProperty('beneficiaireauterme')) {
            props.dispatch(registerField("adForm", 'beneficiaireauterme', 'Field'));
          }
          if (donnees && donnees.beneficiaireauterme && donnees.beneficiaireauterme !== null) props.updateField('beneficiaireauterme', donnees.beneficiaireauterme.split(";"))
    
          if (!props.data || !props.data.hasOwnProperty('beneficiaireaudeces')) {
            props.dispatch(registerField("adForm", 'beneficiaireaudeces', 'Field'));
          }
          if (donnees && donnees.beneficiaireaudeces && donnees.beneficiaireaudeces !== null) props.updateField('beneficiaireaudeces', donnees.beneficiaireaudeces.split(";"))
          
          dispatch({type:'SET_MODE',payload:'UP'});
          //bénéficaires
          history.push(`/${layoutRoot}/modification-proposition`, { step: 1 });
        }
    
  }
  const actualizList = () => {
    const dataSend = {}
    selectPropositionByUserNoDispatch(dataSend).then(res => {
      if (res) {
        setPropoData({
          ['propoAll']: res,
          ['propoEnAttente']: res.filter(item => Number(item.etape) === 1),
          ['propoTransmis']: res.filter(item => Number(item.etape) === 2),
          ['propoAccepter']: res.filter(item => Number(item.etape) === 3),
          ['propoRejeter']: res.filter(item => Number(item.etape) === 4),
          ['propoAnnuler']: res.filter(item => Number(item.etape) === 0),
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  const handleFilterData = () => {
    const dataSend = filterField
    selectPropositionByUserNoDispatch(dataSend).then(res => {
      if (res) {
        setPropoData({
          ['propoAll']: res,
          ['propoEnAttente']: res.filter(item => Number(item.etape) === 1),
          ['propoTransmis']: res.filter(item => Number(item.etape) === 2),
          ['propoAccepter']: res.filter(item => Number(item.etape) === 3),
          ['propoRejeter']: res.filter(item => Number(item.etape) === 4),
          ['propoAnnuler']: res.filter(item => Number(item.etape) === 0),
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  const handleTransmission = (evt, data) => {
    setDinfo({
      ['ddata']: data,
      ['dmessage']: "Voulez-vous vraiment transmettre les propositions ?",
      ['dTexteButton']: "TRANSMETTRE",
      ['dmode']: 2,
      ['daction']: () => transmissionProposition(data)
    })
    setDopen(true)
  }
  const transmissionProposition = async (donnees) => {
    if (!donnees) { return }
    if (donnees.length !== 0) {
      await Promise.all(donnees.map((souscription) => {
        return props.updateProposition({ id: souscription.id, typeop: 'TR' }).then((ctr) => {
          if (ctr) {
            handleFilterData()
            return ctr
          } else {
            return null
          }
        })
      })).then(resultat => {
        if (resultat) {
          setAinfo({
            ['amessage']: "Tranmission effectué avec succès",
            ['amode']: "succes"
          })
          handleDopen(false)
          setAopen(true)
        } else {
          setAinfo({
            ['amessage']: "ERreur incconnu lors de la transmission",
            ['amode']: "error"
          })
          handleDopen(false)
          setAopen(true)
        }
      }).catch((err) => {
        setAinfo({
          ['amessage']: "Erreur incconnu lors de la transmission",
          ['amode']: "error"
        })
        handleDopen(false)
        setAopen(true)
      })
    }
  }

  const renderAll = () => {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Souscripteur', field: 'nom', render: rowData => (rowData.nom + " " + rowData.prenom) },
            { title: 'Produit', field: 'libelleproduit' },
            { title: 'Date effet', field: 'dateeffet', render: rowData => dayjs(rowData.dateeffet).format('DD/MM/YYYY') },
            { title: 'Prime (F CFA)', field: 'prime', render: rowData => formatNumber(parseInt(rowData.prime)) },
            { title: 'Durée (année)', field: 'duree' },
            {
              title: 'Périodicite', field: 'periodicite', render: rowData => {
                switch (rowData.periodicite) {
                  case 'M':
                    return 'Mensuelle'
                  case 'T':
                    return 'Trimestrielle'
                  case 'S':
                    return 'Semestrielle'
                  case 'A':
                    return 'Annuelle'
                  case 'U':
                    return 'Unique'
                  default:
                    return 'Inconnu'
                }
              }
            },
            { title: 'Capital (F CFA)', field: 'capital', render: rowData => ((rowData.capital && rowData.capital !== 'null') ? formatNumber(parseInt(rowData.capital)) : "") },
            { title: 'Etat', field: 'Etapelibelle' },
            { title: 'Utilisateur', field: 'nomagent' },
            { title: 'Agence', field: 'nomagence' },
          ]}
          data={propoData.propoAll?propoData.propoAll:[]}
          title="Liste des propositions"
          localization={{
            toolbar: {
              searchPlaceholder: 'Trouver une proposition',
              searchTooltip: 'Trouver une proposition',
              exportTitle: 'Exportation',
              exportAriaLabel: 'Exportation',
              exportCSVName: 'Exporter en Excel',
              exportPDFName: 'Exporter en PDF',
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-Proposotion"),
            searchFieldStyle: {
              fontSize: 18,
              width: '100%',
              height: 50,
            },
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20,50,100, 150, 200, 300, 400, 500, 1000]
          }}
          components={{
            Action: props => {
              return (
                <CustomMenu
                  edit={editProposition}
                  transmission={transmissionProposition}
                  data={props.data}
                  payerprime = {handlePayerPrime}
                />
              )
            }
          }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) => alert("You want to add a new row")
            },
          ]}
        />
      </div>
    )
  }
  const renderNonTransmis = () => {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Souscripteur', field: 'nom', render: rowData => (rowData.nom + " " + rowData.prenom) },
            { title: 'Produit', field: 'libelleproduit' },
            { title: 'Date effet', field: 'dateeffet', render: rowData => dayjs(rowData.dateeffet).format('DD/MM/YYYY') },
            { title: 'Prime (F CFA)', field: 'prime', render: rowData => formatNumber(parseInt(rowData.prime)) },
            { title: 'Durée (année)', field: 'duree' },
            {
              title: 'Périodicite', field: 'periodicite', render: rowData => {
                switch (rowData.periodicite) {
                  case 'M':
                    return 'Mensuelle'
                  case 'T':
                    return 'Trimestrielle'
                  case 'S':
                    return 'Semestrielle'
                  case 'A':
                    return 'Annuelle'
                  case 'U':
                    return 'Unique'
                  default:
                    return 'Inconnu'
                }
              }
            },
            { title: 'Capital (F CFA)', field: 'capital', render: rowData => ((rowData.capital && rowData.capital !== 'null') ? formatNumber(parseInt(rowData.capital)) : "") },
            { title: 'Saisie le', field: 'saisiele', render: rowData => dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Utilisateur', field: 'nomagent' },
            { title: 'Agence', field: 'nomagence' },
          ]}
          data={propoData.propoEnAttente?propoData.propoEnAttente:[]}
          title="Propositions en attente de transmission"
          localization={{
            toolbar: {
              searchPlaceholder: 'Trouver une proposition',
              searchTooltip: 'Trouver une proposition',
              nRowsSelected: '{0} Ligne(s) sélectionnée(s)',
              exportTitle: 'Exportation',
              exportAriaLabel: 'Exportation',
              exportCSVName: 'Exporter en Excel',
              exportPDFName: 'Exporter en PDF',
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}

          options={{
            exportButton: true,
            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-Proposotion-EnNonTransmis"),
            searchFieldStyle: {
              fontSize: 18,
              width: '100%',
              height: 50,
            },
            selection: true,
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20,50,100, 150, 200, 300, 400, 500, 1000]
          }}

          actions={[
            {
              tooltip: 'Transmettre',
              icon: 'send',
              onClick: handleTransmission
            },
          ]}
        />
      </div>
    )
  }
  const renderTransmis = () => {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Souscripteur', field: 'nom', render: rowData => (rowData.nom + " " + rowData.prenom) },
            { title: 'Produit', field: 'libelleproduit' },
            { title: 'Date effet', field: 'dateeffet', render: rowData => dayjs(rowData.dateeffet).format('DD/MM/YYYY') },
            { title: 'Prime (F CFA)', field: 'prime', render: rowData => formatNumber(parseInt(rowData.prime)) },
            { title: 'Durée (année)', field: 'duree' },
            {
              title: 'Périodicite', field: 'periodicite', render: rowData => {
                switch (rowData.periodicite) {
                  case 'M':
                    return 'Mensuelle'
                  case 'T':
                    return 'Trimestrielle'
                  case 'S':
                    return 'Semestrielle'
                  case 'A':
                    return 'Annuelle'
                  case 'U':
                    return 'Unique'
                  default:
                    return 'Inconnu'
                }
              }
            },
            { title: 'Capital (F CFA)', field: 'capital', render: rowData => ((rowData.capital && rowData.capital !== 'null') ? formatNumber(parseInt(rowData.capital)) : "") },
            { title: 'Saisie le', field: 'saisiele', render: rowData => dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Date transmission', field: 'transmisle', render: rowData => dayjs(rowData.transmisle).format('DD/MM/YYYY') },
            { title: 'Utilisateur', field: 'nomagent' },
            { title: 'Agence', field: 'nomagence' },
          ]}
          data={propoData.propoTransmis?propoData.propoTransmis:[]}
          title="Propositions transmis"
          localization={{
            toolbar: {
              searchPlaceholder: 'Trouver une proposition',
              searchTooltip: 'Trouver une proposition',
              exportTitle: 'Exportation',
              exportAriaLabel: 'Exportation',
              exportCSVName: 'Exporter en Excel',
              exportPDFName: 'Exporter en PDF',
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}

          options={{
            exportButton: true,
            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-Proposotion-EnTransmis"),
            searchFieldStyle: {
              fontSize: 18,
              width: '100%',
              height: 50,
            },
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20,50,100, 150, 200, 300, 400, 500, 1000]
          }}

          components={{
            Action: props => {
              return (
                <CustomMenu
                  edit={editProposition}
                  transmission={transmissionProposition}
                  data={props.data}
                  payerprime = {handlePayerPrime}
                />
              )
            }
          }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) => alert("You want to add a new row")
            },
          ]}
        />
      </div>
    )
  }
  const renderAccepter = () => {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Souscripteur', field: 'nom', render: rowData => (rowData.nom + " " + rowData.prenom) },
            { title: 'Produit', field: 'libelleproduit' },
            { title: 'Date effet', field: 'dateeffet', render: rowData => dayjs(rowData.dateeffet).format('DD/MM/YYYY') },
            { title: 'Prime (F CFA)', field: 'prime', render: rowData => formatNumber(parseInt(rowData.prime)) },
            { title: 'Durée (année)', field: 'duree' },
            {
              title: 'Périodicite', field: 'periodicite', render: rowData => {
                switch (rowData.periodicite) {
                  case 'M':
                    return 'Mensuelle'
                  case 'T':
                    return 'Trimestrielle'
                  case 'S':
                    return 'Semestrielle'
                  case 'A':
                    return 'Annuelle'
                  case 'U':
                    return 'Unique'
                  default:
                    return 'Inconnu'
                }
              }
            },
            { title: 'Capital (F CFA)', field: 'capital', render: rowData => ((rowData.capital && rowData.capital !== 'null') ? formatNumber(parseInt(rowData.capital)) : "") },
            { title: 'Saisie le', field: 'saisiele', render: rowData => dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Date transmission', field: 'transmisle', render: rowData => rowData.transmisle && dayjs(rowData.transmisle).format('DD/MM/YYYY') },
            { title: 'Date acceptation', field: 'accepterle', render: rowData => rowData.accepterle && dayjs(rowData.accepterle).format('DD/MM/YYYY') },
            { title: 'Utilisateur', field: 'nomagent' },
            { title: 'Agence', field: 'nomagence' },
          ]}
          data={propoData.propoAccepter?propoData.propoAccepter:[]}
          title="Propositions acceptées"
          localization={{
            toolbar: {
              searchPlaceholder: 'Trouver une proposition',
              searchTooltip: 'Trouver une proposition',
              exportTitle: 'Exportation',
              exportAriaLabel: 'Exportation',
              exportCSVName: 'Exporter en Excel',
              exportPDFName: 'Exporter en PDF',
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}

          options={{
            exportButton: true,
            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-Proposotion-EnAccepter"),
            searchFieldStyle: {
              fontSize: 18,
              width: '100%',
              height: 50,
            },
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20,50,100, 150, 200, 300, 400, 500, 1000]
          }}

          components={{
            Action: props => {
              return (
                <CustomMenu
                  edit={editProposition}
                  transmission={transmissionProposition}
                  data={props.data}
                  payerprime = {handlePayerPrime}
                />
              )
            }
          }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) => alert("You want to add a new row")
            },
          ]}
        />
      </div>
    )
  }

  const renderRejeter = () => {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Souscripteur', field: 'nom', render: rowData => (rowData.nom + " " + rowData.prenom) },
            { title: 'Produit', field: 'libelleproduit' },
            { title: 'Date effet', field: 'dateeffet', render: rowData => dayjs(rowData.dateeffet).format('DD/MM/YYYY') },
            { title: 'Prime (F CFA)', field: 'prime', render: rowData => formatNumber(parseInt(rowData.prime)) },
            { title: 'Durée (année)', field: 'duree' },
            { title: 'Date transmission', field: 'transmisle', render: rowData => rowData.transmisle && dayjs(rowData.transmisle).format('DD/MM/YYYY') },
            { title: 'Date réjet', field: 'annulerle', render: rowData => rowData.annulerle && dayjs(rowData.annulerle).format('DD/MM/YYYY') },
            { title: 'Motif réjet', field: 'motifrejet' },
            { title: 'Utilisateur', field: 'nomagent' },
            { title: 'Agence', field: 'nomagence' },
            {title:'Action',render:rowData=>(<div>
                <CustomMenu
                  edit={editProposition}
                  transmission={transmissionProposition}
                  data={props.data}
                  handleFilterData={handleFilterData}
                />
              
            </div>)}
          ]}
          data={propoData.propoRejeter?propoData.propoRejeter:[]}
          title="Propositions réjétées"
          localization={{
            toolbar: {
              searchPlaceholder: 'Trouver une proposition',
              searchTooltip: 'Trouver une proposition',
              exportTitle: 'Exportation',
              exportAriaLabel: 'Exportation',
              exportCSVName: 'Exporter en Excel',
              exportPDFName: 'Exporter en PDF',
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}

          options={{
            exportButton: true,
            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-Proposotion-EnRejete"),
            searchFieldStyle: {
              fontSize: 18,
              width: '100%',
              height: 50,
            },
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20,50,100, 150, 200, 300, 400, 500, 1000]
          }}
        />
      </div>
    )
  }

  const renderAnnuler = () => {
    return (
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Souscripteur', field: 'nom', render: rowData => (rowData.nom + " " + rowData.prenom) },
            { title: 'Produit', field: 'libelleproduit' },
            { title: 'Date effet', field: 'dateeffet', render: rowData => dayjs(rowData.dateeffet).format('DD/MM/YYYY') },
            { title: 'Prime (F CFA)', field: 'prime', render: rowData => formatNumber(parseInt(rowData.prime)) },
            { title: 'Durée (année)', field: 'duree' },
            { title: 'Date transmission', field: 'transmisle', render: rowData => rowData.transmisle && dayjs(rowData.transmisle).format('DD/MM/YYYY') },
            { title: 'Date annulation', field: 'annulerle', render: rowData => rowData.annulerle && dayjs(rowData.annulerle).format('DD/MM/YYYY') },
            { title: 'Motif annulation', field: 'motifrejet' },
            { title: 'Utilisateur', field: 'nomagent' },
            { title: 'Agence', field: 'nomagence' },
            
          ]}
          data={propoData.propoAnnuler?propoData.propoAnnuler:[]}
          title="Propositions annulées"
          localization={{
            toolbar: {
              searchPlaceholder: 'Trouver une proposition',
              searchTooltip: 'Trouver une proposition',
              exportTitle: 'Exportation',
              exportAriaLabel: 'Exportation',
              exportCSVName: 'Exporter en Excel',
              exportPDFName: 'Exporter en PDF',
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}

          options={{
            exportButton: true,
            exportCsv: (columns, data) => exportToExcel(columns, data, "LLV-Liste-Proposotion-EnAnnule"),
            searchFieldStyle: {
              fontSize: 18,
              width: '100%',
              height: 50,
            },
            actionsColumnIndex: -1,
            pageSize: 20,
            pageSizeOptions: [20, 100, 150, 200, 300, 400, 500, 1000]
          }}

        />
      </div>
    )
  }

  //filter
  const [filterField, setFilterField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      partenaire: credentials ? credentials.codereseau : 0,
      zone: [5, 7, 10].includes(credentials.coderole) ? credentials.codezone : 0,
      agence: [5, 10].includes(credentials.coderole) ? credentials.codeequipe : 0,
  });
  const handleFilterChange = (event) => {
    const { name, value } = event.target
    setFilterField({
      [name]: value
    })
  }

  const [zoneList, setZoneList] = useState([])
  const handleZoneList = () => {
    if (filterField.partenaire && filterField.partenaire !== '') {
      getZonesReseau(filterField.partenaire).then(res => {
        if (res) {
          setZoneList(res)
        }
      })
    } else {
      getZonesNoDispatch().then(res => {
        if (res) {
          setZoneList(res)
        }
      })
    }
  }
  const [agenceList, setAgenceList] = useState([])
  const handleAgenceList = () => {
    if (filterField.partenaire && filterField.partenaire !== '') {
      getEquipesReseau(filterField.partenaire).then(res => {
        if (res) {
          setAgenceList(res)
        }
      })
    } else if (filterField.zone && filterField.zone !== '') {
      getEquipesZones(filterField.zone).then(res => {
        if (res) {
          setAgenceList(res)
        }
      })
    } else {
      getEquipesNoDispatch().then(res => {
        if (res) {
          setAgenceList(res)
        }
      })
    }
  }

  useEffect(() => {
    handleZoneList()
  }, [filterField.partenaire])
  useEffect(() => {
    handleAgenceList()
  }, [filterField.zone, filterField.partenaire])

  {/*selection des contrat de la journée*/ }
  useEffect(() => {
    const currentdata = { "dateSaisieDebut": dayjs().format('YYYY-MM-DD'), "dateSaisieFin": dayjs().format('YYYY-MM-DD') }
    selectPropositionByUserNoDispatch(currentdata).then(res => {
      if (res) {
        setPropoData({
          ['propoAll']: res,
          ['propoEnAttente']: res.filter(item => Number(item.etape) === 1),
          ['propoTransmis']: res.filter(item => Number(item.etape) === 2),
          ['propoAccepter']: res.filter(item => Number(item.etape) === 3),
          ['propoRejeter']: res.filter(item => Number(item.etape) === 4),
          ['propoAnnuler']: res.filter(item => Number(item.etape) === 0),
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }, [])
  const handlePayerPrime =(donnees)=>{
    handleFonctionShow('PAI',donnees)
  }
  return (
    <div className={classes.root} ref={containerref}>
       <Paper className={classes.hPaper} variant="outlined">
            <div style={{display:'flex'}}>
                <Tooltip title="Retour"> 
                    <IconButton onClick={()=>handleFonctionShow('HOME')} color='primary'>
                        <HomeIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
                <div className={classes.hContainer}>
                    <Typography variant='h5'>Liste des propostions</Typography>
                </div>
            </div>
        </Paper>
        <Paper variant="outlined" className={classes.filterPaper}>
            <div style={{ padding: 10 }}>
                <form className={classes.filterForm} noValidate>
                        <div className={classes.filterItem}>
                            <TextField
                                id="dateSaisieDebut"
                                name="dateSaisieDebut"
                                label="Saisie du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="dateSaisieFin"
                                name="dateSaisieFin"
                                label="Au"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                        </div>
                        <div  className={classes.filterItem}>
                            <TextField
                                id="dateEffetDebut"
                                name="dateEffetDebut"
                                label="Effet du"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleFilterChange}
                                variant='outlined'
                            />
                            <TextField
                                id="dateEffetFin"
                                name="dateEffetFin"
                                label="Au"
                                type="date"
                                onChange={handleFilterChange}
                                className={classes.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                variant='outlined'
                            />
                        </div>
                        <div  className={classes.filterItem}>
                        {([9, 8].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Zone / inspection</InputLabel>
                                <Select
                                labelId="demo-simple-select-outlined-label"
                                id="zone"
                                name="zone"
                                value={filterField.zone}
                                onChange={handleFilterChange}
                                label="Zone / Inspection"
                                >
                            <MenuItem value="0">
                                <em>Aucun</em>
                            </MenuItem>
                            {(zoneList && zoneList.length !== 0) && (zoneList.map(zone => <MenuItem key={`${zone.id}`} value={zone.id}>{zone.libelle}</MenuItem>))}


                            </Select>
                        </FormControl>}
                        {([7, 8, 9].includes(credentials.coderole)) && <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">Agence/Equipe</InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="agence"
                            name="agence"
                            value={filterField.agence}
                            onChange={handleFilterChange}
                            label="Agence/Equipe"
                            >
                            <MenuItem value="0">
                                <em>Aucun</em>
                            </MenuItem>
                            {(agenceList && agenceList.length !== 0) && agenceList.map(ag => (<MenuItem key={ag.id} value={`${ag.id}`}>{ag.libelleequipe}</MenuItem>))}

                            </Select>
                        </FormControl>}
                        </div>
                        <Button color='primary' className={classes.buttonSearch} onClick={handleFilterData} >
                        FILTRER
                        </Button>
                    </form>

                    
            </div>
        </Paper>
        <div>

        <Tab.Container defaultActiveKey="0" >
          <Nav fill  variant="pills" className={classes.nav}>
            <Nav.Item className={classes.navLik}>
              <Nav.Link  className={classes.linked} eventKey="0"> Toutes propositions</Nav.Link>
            </Nav.Item>
            <Nav.Item className={classes.navLik}>
              <Nav.Link className={classes.linked} eventKey="1">En attente de transmission</Nav.Link>
            </Nav.Item>
            <Nav.Item className={classes.navLik}>
              <Nav.Link className={classes.linked} eventKey="2">Propositions transmises</Nav.Link>
            </Nav.Item>
            <Nav.Item className={classes.navLik}>
              <Nav.Link className={classes.linked} eventKey="3"> Acceptées</Nav.Link>
            </Nav.Item>
            <Nav.Item className={classes.navLik}>
              <Nav.Link className={classes.linked} eventKey="4">Rejetées</Nav.Link>
            </Nav.Item>
            <Nav.Item className={classes.navLik}>
              <Nav.Link className={classes.linked} eventKey="5"> Annulées</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
                <Tab.Pane eventKey="0">
                    <div className={classes.contentbox}>{renderAll()}</div></Tab.Pane>
                <Tab.Pane eventKey="1"><div className={classes.contentbox}>{renderNonTransmis()}</div></Tab.Pane>
                <Tab.Pane eventKey="2"><div className={classes.contentbox}>{renderTransmis()}</div></Tab.Pane>
                <Tab.Pane eventKey="3"><div className={classes.contentbox}>{renderAccepter()}</div></Tab.Pane>
                <Tab.Pane eventKey="4"><div className={classes.contentbox}>{renderRejeter()}</div></Tab.Pane>
                <Tab.Pane eventKey="5"><div className={classes.contentbox}>{renderAnnuler()}</div></Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        </div>
    
    </div>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapStateToProps = (state) => ({
  api: state.api,
  user: state.user,
  ui: state.UI,
  data: getFormValues('adForm')(state),
});
const mapActionsToProps = { selectPropositionByUser, updateProposition, getGarantiesAssure, updateField, }
export default connect(mapStateToProps, mapActionsToProps)(reduxForm({
  form: 'adForm', // a unique identifier for this form
  destroyOnUnmount: false,
})(PropositionList))


