import React,{useState,useEffect} from 'react'
import AccueilProduction from './AccueilProduction'
import AddProposition from './AddProposition'
import PropositionList from './PropositionList'
import { useSelector,connect } from 'react-redux'
import IncorporationAssure from './IncorporationAssure'
import Paiement from 'pages/Commons/Paiements/Paiement'

function ProductionContainer() {

  const [fonctionShow,setFonctionShow] =useState('HOME')
  const handleFonctionShow =(show,cdata)=>{
      if(show === 'UP' ){setMode('UP')}
      setData(cdata)
      setFonctionShow(show)
  }
  const [data,setData] =useState(null)
  const [mode,setMode] =useState('ADD')
 const currentmode = useSelector((state)=>state.UI.mode)
 useEffect(()=>{
  if(currentmode==='UP'){
    setFonctionShow('ADD')
  }
 },[currentmode])

  return (
    <div>
        {(fonctionShow==='HOME')&&<AccueilProduction  handleFonctionShow={handleFonctionShow} />}
        {(fonctionShow==='ADD')&&<AddProposition handleFonctionShow={handleFonctionShow} />}
        {(fonctionShow==='PAI')&&<Paiement data={data} handleFonction={handleFonctionShow} />}
        {(fonctionShow==='LIST')&&<PropositionList   handleFonctionShow={handleFonctionShow} />}
        {(fonctionShow==='INC')&&<IncorporationAssure handleFonctionShow={handleFonctionShow} />}
    </div>
  )
}

export default ProductionContainer
