import React, { useState, useEffect } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialTable, { MTableToolbar } from 'material-table'
import { getTypeOperationList } from 'redux/actions/apiActions'
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ImageIcon from '@material-ui/icons/Image';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },


});
const useStyles = makeStyles(theme => ({
    dcard: {
        marginBottom: 10,
        paddingBottom: 10,
        flexWrap: 'wrap'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    coreCustum: {
        borderRadius: 0,
        borderLeft: "10px solid #E35F5B"
    },
    itemTitle: {
        fontStyle: "italic"
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        marginBottom: 20,
        backgroundColor: "#FFF1EB",
        padding: 10
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
}))
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ModalInfosOperation(props) {
    const { open, handleClose, data, handleAlertClose, handleSaveOperation, handleAlertOpen } = props
    const [loading, setLoading] = useState(false)
    const [operations, setOperations] = useState(null)
    const classes = useStyles();

    const handleOperationList = () => {
        const type = (data.groupeoperation === 'MOD' ? "AVT" : "TECH")
        const opeFilter = (data.groupeoperation === 'SIN') && "'26','34','SINSEN'"
        setLoading(true)
        getTypeOperationList({ type, opeFilter }).then(res => {
            if (res) { setOperations(res) }
            setLoading(false)
        }).catch(err => {
            console.log(err);
            setLoading(false)
        })
    }
    useEffect(() => {
        handleOperationList()
    }, [data])

    const [selectedRow, setSelectedRow] = React.useState(null)
    const handleOperationSelect = (event, myrow) => {
        setSelectedRow(myrow)
    }
    const handleValide = async () => {
        if (selectedRow) {
            const dataSend = selectedRow
            dataSend['id'] = data.id
            handleSaveOperation(dataSend)
        } else {
            handleAlertOpen("Aucun contrat sélectionnés", false, handleAlertClose)
        }
    }

    const customFilter = (columFilter, tableFilter, valueSeach) => {
        const isproduit = (item) => {
            let result = false
            for (const key of columFilter) {
                if (item[key]) {
                    if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                        result = true
                        break;
                    } else {
                        result = false
                    }
                } else {
                    result = false
                }
            }
            return result
        }
        return tableFilter.filter(isproduit)
    }
    const [typeOpeData, setTypeOpeData] = React.useState({
        columns: [
            {
                title: 'code opération',
                field: 'CodeTypeAvenant',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData => <List className={classes.list}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar variant="square" sizes={50}>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${rowData.MonLibelle}`} secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                    display='block'
                                >
                                    {rowData.DelaiTraitement ? `Durée traitement ${rowData.DelaiTraitement} jour(s)` : ""}
                                </Typography>
                            </React.Fragment>
                        } />
                    </ListItem>
                </List>,
            },
            { title: 'Libelle', field: 'MonLibelle', hidden: true },
            { title: 'Durée traitement', field: 'DelaiTraitement', type: 'numeric', hidden: true }
        ]
    });
    const renderOperationTable = () => (
        <MaterialTable
            title={null}
            columns={typeOpeData.columns}
            data={query => new Promise((resolve, reject) => {
                const columnSearch = ['CodeTypeAvenant', 'MonLibelle', 'DelaiTraitement']
                resolve({
                    data: customFilter(columnSearch, operations, query.search)
                })
            })}
            onRowClick={((evt, selectedRow) => handleOperationSelect(evt, selectedRow))}
            components={{
                Toolbar: props => (
                    <div style={{ backgroundColor: '#e8eaf5' }}>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Touver une opération',
                    searchTooltip: 'Touver une opération'
                },
                body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                },
                pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                }
            }}
            options={{
                search: true,
                selection: true,
                showSelectAllCheckbox: false,
                searchFieldAlignment: "left",
                pageSize: 10,
                pageSizeOptions: [10, 20, 30, 40, 50, 100],
                searchFieldStyle: {
                    fontSize: 18,
                    width: '100%',
                    height: 50,
                },
                header: false,
            }}
            onSelectionChange={(rows, currentRow) => {
                const dataCopy = [...operations];
                rows.map(row => {
                    row.tableData.checked = false
                    dataCopy[row.tableData.id] = row;
                })
                setSelectedRow(currentRow)
                if (selectedRow && selectedRow.tableData.id == currentRow.tableData.id) {
                    dataCopy[selectedRow.tableData.id].tableData.checked = false
                    setSelectedRow(null)
                } else {
                    dataCopy[currentRow.tableData.id].tableData.checked = !currentRow.tableData.checked
                    setSelectedRow(currentRow)
                }
                setOperations(dataCopy)
            }}
        />
    )
    return (
        <div>
            <Dialog
                onClose={handleClose}
                fullWidth
                disableBackdropClick
                maxWidth={'md'}
                aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {`Edition opération #Ref ${data && data['codeoperation']} - ${data['id']} - Opération`}
                </DialogTitle>
                <DialogContent dividers>
                    {operations ? renderOperationTable() : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress color="secondary" />
                    </div>}

                </DialogContent>
                <DialogActions>
                    <Button className={classes.buttonSearch} autoFocus onClick={handleValide} color="primary" disabled={selectedRow ? false : true}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalInfosOperation