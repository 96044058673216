import { IconButton, Paper, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import EditIcon from '@material-ui/icons/Edit';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ModAdherent from './components/ModAdherent';
import {
    getVille,
    getCivilite,
    getProfession,
    getPays,
    getSociete
} from 'redux/actions/apiActions'
import { useSelector,connect } from 'react-redux';
import { reduxForm, change, getFormValues } from 'redux-form';
import dayjs from 'dayjs';
const  empObj =(nb)=>{
    let i = 0;
    let tbar =[]
    do {
        i += 1;
        tbar.push({})
       
    } while (i < nb);
    return tbar;
}
const AdherentConnexe = (props) => {
    const {api: { villes, professions, societes, civilites },dataForm } = props
    useEffect(() => {
        if (villes && villes.length == 0) {
            props.getVille();
        }
        if (civilites.length == 0) {
            props.getCivilite();
        }
        if (professions.length == 0) {
            props.getProfession();
        }
        if (societes.length == 0) {
            props.getSociete();
        }

        window.scrollTo(0, 0)

    }, []);
    let mesVilles = useSelector(state => state.api.villes)
    

    const [currentData,setCurrentData] = useState(null)
    const [indice,setIndice] = useState(1)
    const [mode,setMode] = useState('ADD')
    const [open,setOpen] = useState(false)
       const handleClose =(event,reason)=>{
        if (reason === 'backdropClick') {
            return
          }
        setOpen(false)
       }
       const handleTOpen =()=>{
        setOpen(true)
       }
       const newadhrent =(idx)=>{
            setMode('ADD')
            setIndice(idx)
            handleTOpen()
       }
      
       const handleInitialisedata =useCallback(async ()=>{
            let fData =[]
            if(dataForm && dataForm.nbadherent && parseInt(dataForm.nbadherent) !==0){
                if(dataForm.hasOwnProperty('adherentconex') && dataForm.adherentconex.length!==0 && parseInt(dataForm.nbadherent)>=dataForm.adherentconex.length){
                    const conxData = [...dataForm.adherentconex]
                    const nb = parseInt((dataForm.nbadherent)-dataForm.adherentconex.length)
                    if(nb>0){
                        fData = [...conxData,...empObj(nb)]
                    }else{
                        fData = [...conxData]
                    }
                }else{
                    const nb = parseInt((dataForm.nbadherent))
                    if(nb>0){
                        fData =[...empObj(nb)]
                    }
                    
                    fData.unshift({
                        civiliteSous:dataForm['civiliteSous'] || "",
                        nomSous:dataForm['nomSous'] || "",
                        prenomSous:dataForm['prenomSous'] || "",
                        datenaissance:dataForm['datenaissance'] || "",
                        lieuNaissanceSous:dataForm['lieuNaissanceSous'] || "",
                        naturePiece:dataForm['naturePiece'] || "",
                        pieceIdentiteSous:dataForm['pieceIdentiteSous'] || "",
                        lieuResidenceSous:dataForm['lieuResidenceSous'] || "",
                        adressePostaleSous:dataForm['adressePostaleSous'] || "",
                        emailSous:dataForm['emailSous'] || "",
                        mobileSous:dataForm['mobileSous'] || "",
                        mobile2Sous:dataForm['mobile2Sous'] || "",
                        telephoneSous:dataForm['telephoneSous'] || "",
                        professionSous:dataForm['professionSous'] || "",
                        employeur:dataForm['employeur'] || "",
                        personneressource:dataForm['personneressource'] || "",
                        contactpersonneressource:dataForm['contactpersonneressource'] || "",
                        personneressource2:dataForm['personneressource2'] || "",
                        contactpersonneressource2:dataForm['contactpersonneressource2'] || "",
                    })
                }
               
            }
            
            setCurrentData([...fData])
       })
       useEffect(()=>{
        handleInitialisedata()
       },[])

       useEffect(()=>{
        props.handleAdConnexe(currentData)
       },[currentData])
       const handleEdit =(idx)=>{
            setMode('MOD')
            setIndice(idx)
            handleTOpen()
       }
  return (
    <div className='gap-3 flex flex-col'>
        <ModAdherent 
            open={open} 
            handleClose={handleClose}
            mode={mode}
            setMode={setMode}
            indice={indice}
            data={currentData} 
            handleSetData={setCurrentData} 
            villes={mesVilles} 
            professions={professions} 
            societes={societes} 
            civilites={civilites} 
            secteurActivites={secteurActivites} 
        />
        <div className='flex flex-col items-center'>
            <span className='font-bold text-[26px]'>Adhérent(s) connexe(s)</span>
            <div className='bg-slate-200 flex items-center justify-center p-1 border-2 border-slate-700 rounded-md'>
                <span className='text-red-600 text-[14px] flex justify-center items-center'>
                    Ce type de contrat nécéssite plussieurs adhérents, veuillez cliquer sur le bouton "+" pour ajouter
                </span>
            </div>
        </div>

        {(currentData && currentData.length!==0)&&currentData.map((dt,xd)=>{
            if((Object.keys(dt)).length !==0){
                return (
                    <Paper variant="outlined" className='flex flex-row justify-between p-0 w-full h-min[200px]'>
                        <div className='flex flex-row justify-center py-2 items-center bg-slate-400 mr-2 h-full'>
                            <Typography variant='h1'>{xd+1}</Typography>
                        </div>
                        <div className='flex flex-col'>
                            <Typography variant='h3'>{`${dt['civiliteSous'] || ""} ${dt['nomSous'] || ""} ${dt['prenomSous'] || ""}`}</Typography>
                            <div className='flex flex-row gap-2'>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row'><span className='font-bold italic'>N° {`${dt['naturePiece'] || ""}`}:</span> <span>{`${dt['pieceIdentiteSous'] || ""}`}</span></div>
                                    <div className='flex flex-row'><span className='font-bold italic'>Né(e) le :</span><span>{`${dayjs(dt['datenaissance']).format('DD/MM/YYYY') || ""}`}</span></div>
                                    <div className='flex flex-row'><span className='font-bold italic'>Né(e) à :</span><span>{`${dt['lieuNaissanceSous'] || ""}`}</span></div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row'><span className='font-bold italic'>Reside à :</span><span>{`${dt['lieuResidenceSous'] || ""}`}</span></div>
                                    <div className='flex flex-row'><span className='font-bold italic'>Email :</span><span>{`${dt['emailSous'] || ""}`}</span></div>
                                    <div className='flex flex-row'><span className='font-bold italic'>Téléphone :</span><span>{`${dt['mobileSous'] || ""}`}</span></div>
                                </div>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row'><span className='font-bold italic'>Activité :</span>{`${dt['professionSous'] || ""}`}<span></span></div>
                                    <div className='flex flex-row'><span className='font-bold italic'>Secteur :</span><span>{`${dt['employeur'] || ""}`}</span></div>
                                    <div className='flex flex-row'><span className='font-bold italic'>En cas de besoins :</span>{`${dt['personneressource'] || ""}-${dt['contactpersonneressource'] || ""}`}<span></span></div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center ml-2'>
                            <IconButton onClick={()=>handleEdit(xd)}>
                                <EditIcon />
                            </IconButton>
                        </div>
                    </Paper>
                )
            }else{
                return(
                    <Paper variant="outlined" className='flex flex-row justify-between p-0 w-full h-min[200px]'>
                        <div className='flex flex-row justify-center items-center py-2 bg-slate-400 mr-2 h-full'>
                            <Typography variant='h1'>{xd+1}</Typography>
                        </div>
                        <div className='flex flex-col'>
                            <Typography variant='h3'></Typography>
                            <div className='flex flex-row gap-2'>
                                
                            </div>
                        </div>
                        <div className='flex flex-col items-center justify-center ml-2'>
                            <IconButton onClick={()=>newadhrent(xd)}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </div>
                    </Paper>
                )
            }
        })}
        

        
        
    </div>
  )
}

const mapStateToProps = (state) => {
    return {
        api: state.api,
        ui: state.UI,
        dataForm: getFormValues('addPret')(state),
        initialValues: state.form.addPret.values
    }
};

const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapActionsToProps = (state) => {
    return {
        updateField,
        getVille,
        getPays,
        getProfession,
        getCivilite,
        getSociete,
    }
}
export default connect(mapStateToProps, mapActionsToProps)(reduxForm({form:'addPret', destroyOnUnmount:false})(AdherentConnexe))


const secteurActivites = [
    { 'MonLibelle': 'AGENT ADMINISTRATIF' },
    { 'MonLibelle': "AGENT D'ASSURANCE / BANQUE / ETS FINANCIER" },
    { 'MonLibelle': 'ARCHITECTE' },
    { 'MonLibelle': 'ARTISAN (BOULANGER ,MENUSIER , COIFFEUR , CORDONNIER)' },
    { 'MonLibelle': 'ARTISTE (CHANTEUR , MUSICIEN , PEINTRE , DECORATEUR , CINEASTRE ,ACTEUR) ' },
    { 'MonLibelle': 'ASSISTANT( E ) DE DIRECTION (SECRETAIRE , ATTACHE DE DIRECTION, …)' },
    { 'MonLibelle': 'ASSISTANTE SOCIALE' },
    { 'MonLibelle': 'AVOCAT' },
    { 'MonLibelle': 'CADRE ADMINISTRATI F ( PRIVE )' },
    { 'MonLibelle': 'CADRE COMMERCIAL' },
    { 'MonLibelle': 'CADRE FINANCIER (PRIVE)' },
    { 'MonLibelle': 'CHIRURGIEN DENTISTE' },
    { 'MonLibelle': "CLERC DE NOTAIRE , D'HUISIER ,  D'AVOCAT" },
    { 'MonLibelle': 'COMMERCANT' },
    { 'MonLibelle': 'COMPTABLE PRIVE' },
    { 'MonLibelle': 'CONSEILLER  JURIDIQUE' },
    { 'MonLibelle': 'DELEGUE MEDICAL' },
    { 'MonLibelle': 'DIRECTEUR ADMINISTRATIF ET FINANCIER' },
    { 'MonLibelle': 'DIRECTEUR COMMERCIAL' },
    { 'MonLibelle': "DIRECTEUR D'ENTREPRISE" },
    { 'MonLibelle': 'DIRECTEUR TECHNIQUE' },
    { 'MonLibelle': 'ENSEIGNANT DU PRIMAIRE' },
    { 'MonLibelle': 'ENSEIGNANT DU SECONDAIRE' },
    { 'MonLibelle': 'ENSEIGNANT DU SUPERIEUR' },
    { 'MonLibelle': 'ENTREPRENEUR' },
    { 'MonLibelle': 'EXPERT COMPTABLE' },
    { 'MonLibelle': 'EXPLOITANT AGRICOLE OU FORESTIER' },
    { 'MonLibelle': 'FONCTIONNAIRE' },
    { 'MonLibelle': 'HAUT FONCTIONNAIRE' },
    { 'MonLibelle': 'HUISSIER DE JUSTICE' },
    { 'MonLibelle': 'INFIRMIER' },
    { 'MonLibelle': 'INFORMATICIEN' },
    { 'MonLibelle': 'INGENIEUR' },
    { 'MonLibelle': 'JOURNALISTE' },
    { 'MonLibelle': 'M EDECIN' },
    { 'MonLibelle': 'NOTAIRE' },
    { 'MonLibelle': 'OFFICIER (POLICE , MILITAIRE , GENDARMERIE)' },
    { 'MonLibelle': 'OPTICIEN' },
    { 'MonLibelle': 'OUVRIER' },
    { 'MonLibelle': 'PHARMACIEN' },
    { 'MonLibelle': 'PUBLICITAIR E' },
    { 'MonLibelle': 'RELIGIEUX' },
    { 'MonLibelle': 'RETRAITE' },
    { 'MonLibelle': 'SAGE FEMME' },
    { 'MonLibelle': 'SALARIE ORGANISME  INTERNATIONAL' },
    { 'MonLibelle': 'SANS  PROFESSION' },
    { 'MonLibelle': 'SOUS-OFFICIER (POLICE , MILITAIRE , GENDARMERIE)' },
    { 'MonLibelle': 'TECHNICIEN (AUTO , PHOTO , TEXTIL ,MODE)' },
    { 'MonLibelle': 'TRANSPORTEUR' },
    { 'MonLibelle': 'SANTE ET BIEN ETRE ' },
    { 'MonLibelle': 'EDUCATION' },
    { 'MonLibelle': 'CONSTRUCTION' },
    { 'MonLibelle': 'SOUS-TRAITANT GRANDES ENTREPRISES' },
    { 'MonLibelle': 'AUTRES PME' }]