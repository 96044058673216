import React,{useEffect,useReducer} from "react";

import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { connect,useDispatch,useSelector } from 'react-redux';
import RangeDatePicker from "components/RangeDatePicker";
import Chart from "util/chart";
import Typography from "@material-ui/core/Typography";
import {selectPropositionByUser,etatProductionAgent} from 'redux/actions/apiActions'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import HeaderFonction from 'pages/Banking/Etats/components/HeaderFonction'
import GridItem from "components/Grid/GridItem.js";
import {formatNumber} from 'application'
function EtatProduction(props) {

  const canvasRef = React.createRef();
  const {title, api:{propositionAll,produits} ,user:{credentials},handleCliqueFonction} = props;
  const [graphe,setGraphe]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    grSaisie:[],
    grTransmis:[],
    grAcceptes:[],
    grRejetes:[]
  });
  
  //calcul des totaux de lignes
  const handleTotalLigne =(code)=>{
    let totNombre = 0
    let totMontant =0
    switch (code) {
      case 'SAISIE':
        produits.map(produit=>{
          prod.prodSaisie.forEach(prod=>{
            if(produit.CodeProduit===prod.codeproduit){
              totNombre+= parseInt(prod.Nombre)
              totMontant+= parseInt(prod.Montant)
            }
          })
        })
          
        break;
      case 'TRANSMIS':
        produits.map(produit=>{
          prod.prodTransmis.forEach(prod=>{
            if(produit.CodeProduit===prod.codeproduit){
              totNombre+= parseInt(prod.Nombre)
              totMontant+= parseInt(prod.Montant)
            }
          })
        })
        break;
      case 'ACCEPTER':
        produits.map(produit=>{
          prod.prodAcceptes.forEach(prod=>{
            if(produit.CodeProduit===prod.codeproduit){
              totNombre+= parseInt(prod.Nombre)
              totMontant+= parseInt(prod.Montant)
            }
          })
        })
        break;
      case 'REJETER':
        produits.map(produit=>{
          prod.prodRejetes.forEach(prod=>{
            if(produit.CodeProduit===prod.codeproduit){
              totNombre+= parseInt(prod.Nombre)
              totMontant+= parseInt(prod.Montant)
            }
          })
        })
        break;
      default:
        break;
    }
    return {totNombre,totMontant}
  }
  const [prod,setProd]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    prodSaisie:[],
    prodTransmis:[],
    prodAcceptes:[],
    prodRejetes:[]
  });
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    dateDebut:"",
    dateFin:"",
    codeMembre:credentials.idmembre
  });
  const handledata =(Refdata)=>{
    if(Refdata && Refdata.length!==0){
      let dataMontant=[]
      let montant = 0
      produits.map(produit=>{
        montant=0
        Refdata.forEach(element => {
            if(element.codeproduit===produit.CodeProduit){
              montant =parseInt(element.Montant)
              return;
            }
        });
        dataMontant.push(montant)
      })
      return dataMontant
    }
  }

  //gestions des production
  const handleProduction = (codeProduit,element)=>{
    if(codeProduit){
     
      switch (element) {
        case 'SAISIE':
            return prod.prodSaisie.filter(item=>item.codeproduit===codeProduit)[0]
        case 'TRANSMIS':
         
          return prod.prodTransmis.filter(item=>item.codeproduit===codeProduit)[0]
        case 'ACCEPTER':
            return prod.prodAcceptes.filter(item=>item.codeproduit===codeProduit)[0]
        case 'REJETER':
            return prod.prodRejetes.filter(item=>item.codeproduit===codeProduit)[0]
        default:
          return {}
      }
    }
   
  } 

   //gestions des production
   const handleTotalColonne = (codeProduit)=>{
    let totNombre = 0
    let totMontant =0
    const allProd = prod.prodSaisie.concat(prod.prodTransmis).concat(prod.prodAcceptes).concat(prod.prodRejetes)
    if(codeProduit){
      allProd.forEach(prod=>{
        if(prod.codeproduit===codeProduit){
          totNombre+=parseInt(prod.Nombre)
          totMontant+=parseInt(prod.Montant)
        }
      })
     
    }
    return {totNombre,totMontant}
  } 
  const handleTotalGeneral =()=>{
    let totNombre = 0
    let totMontant =0
    const allProd = prod.prodSaisie.concat(prod.prodTransmis).concat(prod.prodAcceptes).concat(prod.prodRejetes)
    produits.map(produit=>{
      allProd.forEach(prod=>{
        if(produit.CodeProduit === prod.codeproduit){
          totNombre+=parseInt(prod.Nombre)
          totMontant+=parseInt(prod.Montant)
        } 
    })
    })
    
    return {totNombre,totMontant}
  }
  const handleChange =(event)=>{
    const {name,value}=event.target
    setInputField({
      [name]:value
    })
  }
let dataSend={}
const chartData= {
  datasets: [
    {
      label: "Saisies",
      type: 'bar',
      data: graphe.grSaisie,
      backgroundColor: "rgba(0,123,255,1)",
      borderColor: "rgba(0,123,255,1)",
      pointBackgroundColor: "#ffffff",
      pointHoverBackgroundColor: "rgb(0,123,255)",
      borderWidth: 1.5,
      pointRadius: 0,
      pointHoverRadius: 3
    },
    {
      label: "Transmises",
      type: 'bar',
      data: graphe.grTransmis,
      backgroundColor: "rgba(255,65,105,1)",
      borderColor: "rgba(255,65,105,1)",
      pointBackgroundColor: "#ffffff",
      pointHoverBackgroundColor: "rgba(255,65,105,1)",
      borderDash: [2, 2],
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 2,
      pointBorderColor: "rgba(255,65,105,1)"
    },
    {
      label: "Acceptées",
      type: 'bar',
      data: graphe.grAcceptes,
      backgroundColor: "rgba(0,128,0,1)",
      borderColor: "rgba(0,128,0,1)",
      pointBackgroundColor: "#ffffff",
      pointHoverBackgroundColor: "rgba(255,65,105,1)",
      borderDash: [2, 2],
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 2,
      pointBorderColor: "rgba(255,65,105,1)"
    },
    {
      label: "Réjétées",
      type: 'bar',
      data: graphe.grRejetes,
      backgroundColor: "rgba(255,0,0,1)",
      borderColor: "rgba(255,0,0,1)",
      pointBackgroundColor: "#ffffff",
      pointHoverBackgroundColor: "rgba(255,65,105,1)",
      borderDash: [2, 2],
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 2,
      pointBorderColor: "rgba(255,65,105,1)"
    }
  ]
}

produits.sort((a, b)=> (a.CodeProduit.localeCompare(b.CodeProduit)))
useEffect(() => {
  dataSend ={
    codeMembre:inputField.codeMembre?inputField.codeMembre:credentials.idmembre,
    dateDebut:inputField.dateDebut?inputField.dateDebut:null,
    dateFin:inputField.dateFin?inputField.dateFin:null,
  }
  etatProductionAgent(dataSend).then(resData=>{
    if(resData){
      const {maproduction} =resData
      maproduction.sort((a, b)=> (a.codeproduit.localeCompare(b.codeproduit)));
      const prodrejeter = maproduction.filter(item=>item.etape==0)
      const prodSaisie = maproduction.filter(item=>item.etape==1)
      const prodTransmis = maproduction.filter(item=>item.etape==2)
      const prodAccepter = maproduction.filter(item=>item.etape==3)
      //contrat rejete
      if(prodrejeter && prodrejeter.length!==0){
        setGraphe({['grRejetes']:handledata(prodrejeter)})
        setProd({['prodRejetes']:prodrejeter})  
      }
      //contrat saisie
      if(prodSaisie && prodSaisie.length!==0){
        setGraphe({['grSaisie']:handledata(prodSaisie)}) 
        setProd({['prodSaisie']:prodSaisie}) 
      }
      //contrat acepter
      if(prodTransmis && prodTransmis.length!==0){
        setGraphe({['grTransmis']:handledata(prodTransmis)}) 
        setProd({['prodTransmis']:prodTransmis}) 
      }
      //contrat acepter
      if(prodAccepter && prodAccepter.length!==0){
        setGraphe({['grAcceptes']:handledata(prodAccepter)}) 
        setProd({['prodAcceptes']:prodAccepter}) 
      }
    }
  })
}, [])
//après modification des données
  useEffect(() => {
    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                
                 const produit = produits[index]
                 if(produit){
                    return produit.MonLibelle.toUpperCase();
                 }else{
                   return index;
                 }
                  
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 5000,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  return tick;
                }
              }
            }
          ]
        },
        
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...props.chartOptions
    };
    chartData.labels= Array.from(new Array(produits.length), (_, i) => (i === 0 ? 1 : i))
    const ChartetatProduction = new Chart(canvasRef.current, {
      type: "LineWithLine",
      data: chartData,
      options: chartOptions
    });
   
    // They can still be triggered on hover.
    const buoMeta = ChartetatProduction.getDatasetMeta(0);
  
    if(chartData.datasets[0].data.length!==0){
      buoMeta.data[0]._model.radius = 0;
      buoMeta.data[
        chartData.datasets[0].data.length - 1
      ]._model.radius = 0;
  
    }
    
    // Render the chart.
    ChartetatProduction.render();
  }, [graphe])
 
  
    return (
      <>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <HeaderFonction history={props.history} handleFonctionMini={handleCliqueFonction} />
      </GridItem>
      <Card small className="h-100">
        <CardHeader className="border-bottom">
        <Typography className="m-0" variant='h5' component='h5'> {title}</Typography>
        </CardHeader>
        <CardBody className="pt-0">
          <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
              <RangeDatePicker handleChange={handleChange} />
            </Col>
          </Row>
          <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="d-flex mb-2 mb-sm-0">
            <TableContainer component={Paper}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        {produits.map(produit=>(
                            <TableCell key={produit.CodeProduit} colSpan={2} align='center'>{produit.MonLibelle.toUpperCase()}</TableCell>
                              
                          ))}
                           <TableCell colspan={2} align='center'>TOTAL</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Propositions</TableCell>
                            {produits.map(produit=>(
                              <>
                                <TableCell  key={`${produit.CodeProduit}-n`} align='center'>Nombre</TableCell>
                                <TableCell  key={`${produit.CodeProduit}-m`} align='center'>Montant</TableCell>
                              </>
                          ))}
                           <TableCell>Nombre</TableCell>
                           <TableCell>Montant</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}}>Saisies</TableCell>
                          {produits.map(produit=>{
                            const mesValeur =handleProduction(produit.CodeProduit,'SAISIE')
                            const nombre=mesValeur?mesValeur.Nombre:0
                            const montant=mesValeur?mesValeur.Montant:0
                            handleTotalLigne(nombre,montant,produit.CodeProduit)
                          return(<><TableCell key={`${produit.CodeProduit}-s-n`} align='center'>{nombre?formatNumber(nombre):0}</TableCell><TableCell  key={`${produit.CodeProduit}-s-m`} align='center'>{montant?formatNumber(montant):0}</TableCell></>)
                          })}
                          
                           
                            <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='center'>{formatNumber(handleTotalLigne('SAISIE').totNombre)}</TableCell>
                            <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='right'>{formatNumber(handleTotalLigne('SAISIE').totMontant)}</TableCell>
                          
                            
                         
                        </TableRow> 
                        <TableRow>
                          <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}}>Transmises</TableCell>
                          {produits.map(produit=>{
                            const mesValeur =handleProduction(produit.CodeProduit,'TRANSMIS')
                            const nombre=mesValeur?mesValeur.Nombre:0
                            const montant=mesValeur?mesValeur.Montant:0
                            handleTotalLigne(nombre,montant,produit.CodeProduit)
                          return(<><TableCell key={`${produit.CodeProduit}-t-n`} align='center'>{nombre?formatNumber(nombre):0}</TableCell><TableCell  key={`${produit.CodeProduit}-t-m`} align='center'>{montant?formatNumber(montant):0}</TableCell></>)
                          })}
                            <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='center'>{formatNumber(handleTotalLigne('TRANSMIS').totNombre)}</TableCell>
                            <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='right'>{formatNumber(handleTotalLigne('TRANSMIS').totMontant)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}}>Acceptées</TableCell>
                          {produits.map(produit=>{
                            const mesValeur =handleProduction(produit.CodeProduit,'ACCEPTER')
                            const nombre=mesValeur?mesValeur.Nombre:0
                            const montant=mesValeur?mesValeur.Montant:0
                            handleTotalLigne(nombre,montant,produit.CodeProduit)
                            return(<><TableCell key={`${produit.CodeProduit}-a-n`} align='center'>{nombre?formatNumber(nombre):0}</TableCell><TableCell  key={`${produit.CodeProduit}-a-m`} align='center'>{montant?formatNumber(montant):0}</TableCell></>)
                          })}
                            <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='center'>{formatNumber(handleTotalLigne('ACCEPTER').totNombre)}</TableCell>
                            <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='right'>{formatNumber(handleTotalLigne('ACCEPTER').totMontant)}</TableCell>
                        </TableRow> 
                        <TableRow>
                          <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}}>Réjétées</TableCell>
                          {produits.map(produit=>{
                            const mesValeur =handleProduction(produit.CodeProduit,'REJETER')
                            const nombre=mesValeur?mesValeur.Nombre:0
                            const montant=mesValeur?mesValeur.Montant:0
                            handleTotalLigne(nombre,montant,produit.CodeProduit)
                          return(<><TableCell key={`${produit.CodeProduit}-r-n`} align='center'>{nombre?formatNumber(nombre):0}</TableCell><TableCell  key={`${produit.CodeProduit}-r-m`} align='center'>{montant?formatNumber(montant):0}</TableCell></>)
                          })}
                          <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='center'>{formatNumber(handleTotalLigne('REJETER').totNombre)}</TableCell>
                            <TableCell style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}} align='right'>{formatNumber(handleTotalLigne('REJETER').totMontant)}</TableCell>
                        </TableRow>  
                        <TableRow style={{backgroundColor:'#F2F2F2',fontStyle:'bold',fontSize:15}}>
                          <TableCell>TOTAL</TableCell>
                          {produits.map(produit=>{
                            const {totNombre,totMontant} =handleTotalColonne(produit.CodeProduit)
                            return(<><TableCell key={`${produit.CodeProduit}-x-n`} align='center'>{totNombre?formatNumber(totNombre):0}</TableCell><TableCell key={`${produit.CodeProduit}-x-m`} align='center'>{totMontant?formatNumber(totMontant):0}</TableCell></>)
                          })}
                           <TableCell style={{backgroundColor:'#000',fontStyle:'bold',fontSize:15,color:'#fff'}} align='center'>{formatNumber(handleTotalGeneral().totNombre)}</TableCell>
                           <TableCell style={{backgroundColor:'#000',fontStyle:'bold',fontSize:15,color:'#fff'}} align='right'>{formatNumber(handleTotalGeneral().totMontant)}</TableCell>
                        </TableRow>      
                    </TableBody>
                    </Table>
                </TableContainer>
            </Col>
          </Row>
          <canvas
            height="120"
            ref={canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
      </Card>
      </>
    );
  
}

EtatProduction.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

EtatProduction.defaultProps = {
  
  title: "Etat de la production",
  
};

const mapActionsToProps = {
  selectPropositionByUser
}

const mapStateProps =(state)=>({
  api:state.api,
  user:state.user
})
export default connect(mapStateProps,mapActionsToProps)(EtatProduction)

