import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import { getDocument, deleteDocument } from 'redux/actions/apiActions'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { PDFReader } from 'react-read-pdf'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import Popover from '@material-ui/core/Popover';
import config from 'util/config'
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    table: {
        minWidth: 650,
    },
    buttonDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: 0,
        marginRight: 20,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
}));
export default function ImportDocumentList(props) {
    const { open, handleClose, data,source } = props
    const classes = useStyles();
    const { url } = config
    const [loading, setLoading] = useState(false)
    const [mesDocument, setMesDocuments] = useState(null)
    useEffect(() => {
        if (data && open) {
            setLoading(true)
            getDocument(data.id,source).then(res => {
                if (res) {
                    setMesDocuments(res.documents)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            }).catch(err => {
                console.log(err);
                setLoading(false)
            })
        }
    }, [data.id, open])

    const [type, setType] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handledClose = () => {
        setAnchorEl(null);
    };

    const handleDeletedoc = (id) => {
        deleteDocument(id).then(res => {
            if (res) {
                getDocument(data.id).then(res => {
                    if (res) {
                        setMesDocuments(res.documents)
                    }
                })
            }
        })
    }

    const [currentDoct, setCurrentDoc] = useState(null)
    const handleShowDoc = (event, doc) => {
        setAnchorEl(event.currentTarget);
        if (doc && doc.hasOwnProperty('filename')) {
            let ctype = doc.filename.split('.')[1]
            setType(ctype.toLowerCase())
            setCurrentDoc(doc)
        }
    }
    const dopen = Boolean(anchorEl);
    const id = dopen ? 'simple-popover' : undefined;

    const [currentPage, setCurrentPage] = useState(1)
    const [allPage, setAllPage] = useState(1)
    const [showAll, setShowAll] = useState(false)
    const handleDocInfo = (infoDoc) => {
        setAllPage(infoDoc)
        setShowAll(true)
    }
    const handleNextPage = () => {
        if (currentPage < allPage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const handleBackPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const guideLine = () => {
        return (
            <GridContainer direction="row" justify="center" alignItems="center">
                <GridItem xs={1} sm={1} md={1} lg={1} style={{ marginRight: 25 }}>
                    <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage > 1)} onClick={() => setCurrentPage(1)}>
                        <FirstPageIcon color={(currentPage > 1) ? 'primary' : "secondary"} style={{ fontSize: 40 }} />
                    </IconButton>
                </GridItem>
                <GridItem xs={1} sm={1} md={1} lg={1} style={{ marginRight: 25 }} >
                    <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage > 1)} onClick={handleBackPage}>
                        <NavigateBeforeIcon color={(currentPage > 1) ? 'primary' : "secondary"} style={{ fontSize: 40 }} />
                    </IconButton>
                </GridItem>
                <GridItem xs={1} sm={1} md={1} lg={1} style={{ marginRight: 25 }}>
                    <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage < allPage)} onClick={handleNextPage}>
                        <NavigateNextIcon color={(currentPage < allPage) ? 'primary' : "secondary"} style={{ fontSize: 40 }} />
                    </IconButton>
                </GridItem>
            </GridContainer>)
    }

    return (
        <Dialog
            maxWidth='md'
            disableBackdropClick
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Liste des documents importés N°: #{props.data.id}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        {(mesDocument && mesDocument.length !== 0) ? <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>#</TableCell>
                                        <TableCell align="right">Libellé</TableCell>
                                        <TableCell align="right">Document</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mesDocument.map(doc => (
                                        <TableRow key={doc.id}>
                                            <TableCell component="th" scope="row">
                                                {doc.id}
                                            </TableCell>
                                            <TableCell align="right">{doc.libelle}</TableCell>
                                            <TableCell align="right">{doc.filename}</TableCell>
                                            <TableCell align="right">
                                                <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <IconButton edge="start" color="inherit" onClick={(e) => handleShowDoc(e, doc)} aria-label="close">
                                                        <VisibilityIcon fontSize="large" />
                                                    </IconButton>
                                                    <IconButton edge="start" color="inherit" onClick={() => handleDeletedoc(doc.id)} aria-label="close">
                                                        <DeleteIcon fontSize="large" />
                                                    </IconButton>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer> : (loading ? <CircularProgress color="primary" /> : <Typography variant="h6">Aucun document</Typography>)}
                        {currentDoct && <Popover
                            id={id}
                            open={dopen}
                            anchorPosition={{ top: 200, left: 400 }}
                            onClose={handledClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            {(type == 'png' || type == 'jpg' || type == 'jpeg' || type == 'gif') ? (
                                <Card>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                                        <Typography variant="h6" className={classes.title}>
                                            {currentDoct.libelle ? currentDoct.libelle : "# Aucun titre"}
                                        </Typography>
                                        <IconButton edge="start" color="inherit" onClick={handledClose} aria-label="close">
                                            <CloseIcon color='primary' fontSize="large" />
                                        </IconButton>
                                    </div>


                                    <CardMedia
                                        component="img"
                                        alt={currentDoct.libelle}
                                        image={`${url + currentDoct.filename}`}
                                        title={currentDoct.libelle}
                                    />
                                    <CardActions>
                                        <Button aria-describedby={id} size="large" color="primary" ><SettingsOverscanIcon /></Button>
                                    </CardActions>
                                </Card>
                            ) : (
                                <Card>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                                        <Typography variant="h6" compoenent='h6'>
                                            {currentDoct.libelle ? currentDoct.libelle : "# Aucun titre"}
                                        </Typography>
                                        <IconButton edge="start" color="inherit" onClick={handledClose} aria-label="close">
                                            <CloseIcon color='primary' fontSize="large" />
                                        </IconButton>
                                    </div>
                                    <CardContent>
                                        <div style={{ overflow: 'scroll', height: 600 }}>
                                            <PDFReader page={currentPage} url={`${url + currentDoct.filename}`} onDocumentComplete={handleDocInfo} />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>{guideLine()}<Typography color='primary' variant='h4' component='h4' >{`${currentPage}/${allPage} Page(s)`}</Typography></div>

                                    </CardContent>
                                </Card>
                            )}

                        </Popover>}
                    </GridItem>
                </GridContainer>
            </DialogContent>
            <DialogActions>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Button type="button" onClick={handleClose}>
                        Fermer
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}



const documents = [
    { titre: "Bulletin de souscription", fichier: "" },
    { titre: "Pièce d\'identité en cours de validité", fichier: "" },
    { titre: "Autres pièces", fichier: "" }
]