import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { connect } from 'react-redux'
import { reduxForm, change, Field, registerField, getFormValues } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import CustomInput from "components/CustomInput/CustomInput.js";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import Button from "components/CustomButtons/Button.js";
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import { getFiliations } from 'redux/actions/apiActions'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: 20,
        '& :hover': {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 0,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            padding: '10 30px',
            textDecoration: 'none'
        }
    },

    sswitch: {
        width: 60,
        height: 40,
        padding: 0,
        margin: theme.spacing(1)
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            // This is the part that animates the thumb when the switch is toggled (to the right)
            transform: 'translateX(16px)',
            // This is the thumb color
            color: theme.palette.common.white,
            '& + $track': {
                // This is the track's background color (in this example, the iOS green)
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },

    },
    thumb: {
        width: 36,
        height: 36,
    },
    track: {
        borderRadius: 19,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[300],
        height: 30,
        opacity: 1,
        marginTop: 4,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    switcLabel: {
        fontSize: 18
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    loading: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonAdd: {
        width: '100%',
        marginBottom: 20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: 30,
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },

    buttonAddChild: {
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.primary.main,
        }
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    dcard: {
        marginBottom: 10,
        paddingBottom: 10
    },
    paper: {
        marginBottom: 20,
        padding: 20
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
      btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:theme.palette.primary.main,
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
}));

function DeclarantOperation(props) {
    const classes = useStyles();
    const { handleSubmit, handleNext, handleBack, activeStep, steps, dataForm } = props
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
        getFiliations().then((fil) => { setFiliations(fil); })
    }, [])
    return (
        <form onSubmit={handleSubmit(handleNext)}>
            <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.heading}>Etape {activeStep ? (activeStep + 1) : 1} : {(steps) ? steps[activeStep] : ""}</Typography>
                <Divider />
            </Paper>
            <Paper elevation={3} className={classes.paper}>
                <GridContainer>
                    <GridItem sm={12} xs={12} md={6} lg={6}>
                        <Field
                            required
                            id="nomdeclarant"
                            name="nomdeclarant"
                            component={CustomInput}
                            label="Nom"
                            formControlProps={{
                                fullWidth: true
                            }}
                            type='texte'
                            variant="outlined"
                        />
                        <Field
                            required
                            id="prenomdeclarant"
                            name="prenomdeclarant"
                            component={CustomInput}
                            label="Prénom"
                            formControlProps={{
                                fullWidth: true
                            }}
                            type='texte'
                            variant="outlined"
                        />

                        <Field
                            label="Lien de parenté"
                            id="filiationdeclarant"
                            name="filiationdeclarant"
                            formControlProps={{
                                fullWidth: true
                            }}
                            component={CustomSelect}
                            options={filiations}
                            variant="outlined"
                        />
                    </GridItem>
                    <GridItem sm={12} xs={12} md={6} lg={6}>
                        <Field
                            id="emaildeclarant"
                            name="emaildeclarant"
                            component={CustomInput}
                            label="Email"
                            formControlProps={{
                                fullWidth: true
                            }}
                            type='email'
                            variant="outlined"
                        />
                        <Field
                            label="Téléphone"
                            id="telephonedeclarant"
                            name="telephonedeclarant"
                            variant="outlined"
                            component={CustomPhoneInput}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />


                    </GridItem>

                </GridContainer>
            </Paper>
            <Paper elevation={3} className={classes.paper}>
                <LabelledOutline label="Agent décalarant/Agence">

                </LabelledOutline>
            </Paper>
            <Paper elevation={3} className={classes.paper}>
                <Divider style={{ marginTop: 20 }} />
                <div style={{ display: "flex", justifyContent: "space-between", padding: 20 }}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                    >
                        Retour
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        type='submit'
                        className={classes.button}
                        large
                    >
                        {activeStep === steps.length - 1 ? 'Valider' : 'Suivant'}
                    </Button>
                </div>
            </Paper>
        </form>
    )
}
const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    user: state.user,
    api: state.api,
    dataForm: getFormValues('addOper')(state),
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false })(DeclarantOperation))
