import React,{useState,useReducer} from "react";
import { MenuItem, Menu } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import {generateBulletinSouscription} from 'pages/Banking/Propositions/EditionBulletin' 
import {updateProposition} from 'redux/actions/apiActions'
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import DialogBox from './DialogBox'
import {connect} from 'react-redux'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import ImportDocument from 'pages/Banking/Propositions/ImportDocument'
import ImportDocumentList from 'pages/Banking/Propositions/ImportDocumentList'
import SendIcon from '@material-ui/icons/Send';
function CustomMenu (props){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dopen, setDopen] = useState(false)
  const handleDopen =(etape)=>{
    setDopen(etape)
  }
  const [dinfo, setDinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      dmessage:null,
      ddescription:null,
      dTexteButton:null,
      dmode:null
    });
    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      motif:null
    });
    const handleChange =(event)=>{
      const  {name,value}=event.currentTarget
      setInputField({
        [name]:value
      })
    }
    const [dlopen, setDlopen] = useState(false)
    const handleDlopen =()=>{
      setDlopen(true)
    }
    const handleDlClose =()=>{
      setDlopen(false)
    }
  
    //gestion importation de fichier
    const [openi,setOpeni] = React.useState(false)
    const handleOpeni= ()=>{
      setOpeni(true)
    }
    const handleClosei= ()=>{
      setOpeni(false)
    }

    const handleBulletin =async (data)=>{
        generateBulletinSouscription(data,props.user.credentials)
    }

    const handleAnnulationRejet =()=>{
      setDinfo({
        ['dTitle']:"Voulez vous vraiment annuler/rejeter cet prêt ?",
        ['dTexteButton']:'Annuler/Rejeter',
        ['dmode']:1
      })
      setDopen(true)
    }

    const handleMenuClick=(type,donnees)=> {
      switch (type) {
        case "IMPORT_FILE":
          handleOpeni()
          break;
        case "LIST_FILE":
            handleDlopen()
            break;
        case "BULLETIN":
          handleBulletin(donnees)
          break;
        case 'EDIT_CONTRAT':
              props.edit(donnees)
          break;
        case 'TRANS':
            props.transmission([donnees])
          break;
        case 'ANNULER':
          handleAnnulationRejet()
          break;
        case 'PAYER_PRIME':
          props.payerprime(donnees)
          break;
        default:
          break;
      }
      handleClose()
    }

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
      setAnchorEl(null);
    }
    const handleRejet = ()=>{
      const mydata ={
        id:data.id,
        typeop:"AN",
        motifrejet:inputField.motif
      }
      props.updateProposition(mydata).then((res)=>{
        handleDopen(false)
      })     
    }

  const {data} = props;    
      return(
        <React.Fragment>
          <DialogBox data={data} dopen={dopen} dinfo={dinfo} daction={handleRejet} handleChange={handleChange} handleDopen={handleDopen}/>
          <ImportDocument open={openi} handleOpen={handleOpeni} handleClose={handleClosei} data={data} />
          <ImportDocumentList open={dlopen} handleOpen={handleDlopen} handleClose={handleDlClose} data={data} />
          <IconButton
            data-id={data.id}
            aria-label="actions"
            aria-controls='long-menu'
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="card-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {(Number(data.etape) ===1 || Number(data.etape) ===4)?(
              <MenuItem  onClick={()=>handleMenuClick('EDIT_CONTRAT',data) }>
                <ListItemIcon>
                    <NoteAddIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Modifier la proposition</Typography>
            </MenuItem>
            
            ):null}
           <MenuItem disabled>
                <Divider />
              </MenuItem>
            {(Number(data.etape) ===1 || Number(data.etape) ===4)?(
             
                <MenuItem onClick={()=>handleMenuClick('IMPORT_FILE',data) } >
                  <ListItemIcon>
                    <AssignmentReturnedIcon fontSize="small" color='primary' />
                </ListItemIcon>
                  <Typography variant="inherit">Joindre des documents</Typography>
                </MenuItem>
            ):null}
            {(Number(data.etape) ===1 || Number(data.etape) ===2 || Number(data.etape) ===4)?(
              <MenuItem  onClick={()=>handleMenuClick('PAYER_PRIME',data) }>
                <ListItemIcon>
                    <NoteAddIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Réglé une prime</Typography>
            </MenuItem>
            
            ):null}
             <MenuItem onClick={()=>handleMenuClick('LIST_FILE',data) } >
                    <ListItemIcon>
                      <AssignmentIcon fontSize="small" color='primary' />
                    </ListItemIcon>
                    <Typography variant="inherit">Liste des documents</Typography>
            </MenuItem>
            <MenuItem disabled>
                <Divider />
              </MenuItem>
            {((Number(data.etape) ===1 || Number(data.etape) ===4) && Number(data.etape) !==0)&&(
            
              <MenuItem onClick={()=>handleMenuClick('ANNULER',data) }>
                <ListItemIcon>
                  <CloseIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Marquer comme annulée</Typography>
              </MenuItem>
              )
              }
              {(Number(data.etape) ===4)?(
             
             <MenuItem onClick={()=>handleMenuClick('TRANS',data) } >
               <ListItemIcon>
                 <SendIcon fontSize="small" color='primary' />
             </ListItemIcon>
               <Typography variant="inherit">Retransmettre</Typography>
             </MenuItem>
         ):null}
               <MenuItem disabled>
                <Divider />
              </MenuItem>
              
              <MenuItem onClick={()=>handleMenuClick('BULLETIN',data)}>
                <ListItemIcon>
                      <AssignmentIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Bulletin de souscription</Typography>
              </MenuItem>
            
          </Menu>
      </React.Fragment>
    );
      
  }
  const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user
  });
  const mapActionsToProps = {updateProposition}
  export default connect(mapStateToProps,mapActionsToProps)(CustomMenu)
