import React ,{useState,useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Grid,Box} from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import { withStyles } from '@material-ui/core/styles';
import ProfilModalForm from './ProfilModalForm'
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { Field, reduxForm,change,registerField,getFormValues } from 'redux-form'
import {connect,useDispatch} from 'react-redux'
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment";
//
import MuiAlert from '@material-ui/lab/Alert';
import {getGarantiesProduit,getGarantiesAssure,getPrimesCapital,getCapitauxGaranies} from 'redux/actions/apiActions'
import {ageAssure} from 'redux/actions/userActions'
import Snackbar from '@material-ui/core/Snackbar';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import clsx from 'clsx';

import dayjs from 'dayjs'
import GridItem from 'components/Grid/GridItem';
import ModalLoading from 'components/ModalLoading'
import GarantiesInfo from './GarantiesInfo'
const styles = theme =>({
  root: {
    backgroundColor:theme.palette.primary.main,
    borderRadius:50,
    border: 1,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    marginBottom:20,
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      boxShadow: '0 3px 5px 2px rgba(6, 6, 6, .3)',
    }
  },

    table: {
        minWidth: 650,
      },
      btnAdd:{
        padding:20,
        marginBottom:10,
          '.MuiButton-outlined':{
              root:{
                focused: {
                  border: '1px solid #4A90E2'
                },
              }
          }
      },

      btnNext:{
        backgroundColor:theme.palette.primary.main,
        height:40,
        width:150,
        borderRadius:20,
        color:theme.palette.primary.contrastText
      },
      assureInfoRow:{
        display:'flex',
        flexDirection:'row'
      },
      popover: {
        pointerEvents: 'none',
      },
      paper: {
        padding: theme.spacing(1),
      },
})
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


 function ProfilAssure(props) {
    const dispatch = useDispatch();
    const { handleSubmit, reset,classes, className,data,UI:{loading,errors}} = props
    const [mesAssures,setMesAssures] = useState([])
    const [mesGaranties, setMesGaranties] = useState(null)
    const [open, setOpen] = React.useState(false);
    const [modeOpen,setModeOpen] = React.useState('autreAssure')
    const [moimemeAssure, setMoimemeAssure] = React.useState(false);
    const [myErrors,setMyErrors]= useState(null)
    const [checked,setChecked] =useState([])  
    
    const [myLoading,setMyLoading] = React.useState(loading)
    const handleOpen = (mode) => {
      setModeOpen(mode)
      setOpen(true);
    };


    //gestion alert message
    const [openAlert, setOpenAlert] = React.useState(false);
    const handleOpenAlert = () => {
      setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenAlert(false);
      setMyErrors(null)
    };
  
    
//affectation des durée
  const affectationDuree=(dureeCotisationMin,dureeCotisationMax,dureeContratMin,dureeContratMax,optionnelle)=>{ 
    const {updateField} =props
  
    //durée cotisation mini
    if(!data.hasOwnProperty('dureecontratmin')){
      props.dispatch(registerField("adForm",'dureecontratmin','Field'));
    }else{
      if(optionnelle && (parseInt(dureeContratMin) >parseInt(data.dureecontratmin))){dureeContratMin=props.data.dureecontratmin}
    }
    
    
    updateField('dureecontratmin',dureeContratMin)

    //durée cotisation maximum
    if(!data.hasOwnProperty('dureecontratmax')){
      props.dispatch(registerField("adForm",'dureecontratmax','Field'));
    }else{
      if(optionnelle && (parseInt(dureeContratMax) <parseInt(props.data.dureecontratmax))){dureeContratMax=props.data.dureecontratmax}
    }
    updateField('dureecontratmax',dureeContratMax)
    //duré cotisation mini
    if(!props.data.hasOwnProperty('dureecotisationmin')){
      props.dispatch(registerField("adForm",'dureecotisationmin','Field'));
      }else{
        if(optionnelle && (parseInt(dureeCotisationMin) >parseInt(props.data.dureecotisationmin))){dureeCotisationMin=props.data.dureecotisationmin}
      }
      updateField('dureecotisationmin',dureeCotisationMin)
      //durée cotisation max
      if(!props.data.hasOwnProperty('dureecotisationmax')){
        props.dispatch(registerField("adForm",'dureecotisationmax','Field'));
      }else{
        if(optionnelle && (parseInt(dureeCotisationMax) <parseInt(props.data.dureecotisationmax))){dureeCotisationMax=props.data.dureecotisationmax}
      }
      updateField('dureecotisationmax', dureeCotisationMax)
                
  }


    //Ajout et insertion d'un assuré
  const addAssure =(defaultData,moimeme)=>{
    setMyLoading(true)
     
      const {updateField,getGarantiesAssure}=props
       //verification de la présence de l'assuré
      if(mesAssures && mesAssures.length!==0){
        let estPresent = false
        mesAssures.filter((item)=>{
          if(item.numeroCniAssure===defaultData.numeroCniAssure){
            estPresent=true
            setMyLoading(false)
            return
          }
        })
        if(estPresent){
          setMyLoading(false)
          setMyErrors({type:'warning',message:'Cet assuré existe déjà'})
          handleOpenAlert()
          return false
        }
      }

      //vérification de la présence des garanties
      if(mesGaranties && mesGaranties.length===0){
        setMyLoading(false)
        setMyErrors({type:'error',message:'Aucune garanties trouvées'})
        handleOpenAlert()
        return false
      }

      //vérification de la présence des information sur l'assurée
      if(!defaultData){
        setMyLoading(false)
        setMyErrors({type:'error',message:'Erreur sur l\'assurée'})
        handleOpenAlert()
        return false
      }
     
      
      //selection des garanties
       let dureeCotisationMin=undefined
       let dureeCotisationMax=undefined
       let dureeContratMin=undefined
       let dureeContratMax=undefined
     
       let garantiesObligatoires
       let garantiesComplementaires
       let codeproduit
       let dateNaissance
       codeproduit = data.produit.CodeProduit
       dateNaissance = moimeme?data.dateNaissanceSous:defaultData.dateNaissanceAssure
      //récupération des garanties pour l'assuré
      getGarantiesAssure(codeproduit,ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD'))).then((garanties)=>{

        if(garanties){ 
          garantiesObligatoires =garanties.filter(item=>item.estobligatoire==1)
          garantiesComplementaires = garanties.filter(item=>item.estobligatoire==0)
          
          let disponibiliteGarantie=[]
          let garantiesParcourru
          defaultData.garantiesFacultatives=[]
        if((garantiesObligatoires && garantiesObligatoires.length!==0) || (garantiesComplementaires && garantiesComplementaires.length!==0)){  
          let result = false
          garantiesParcourru = garantiesObligatoires.length!==0 ? garantiesObligatoires:garantiesComplementaires
          defaultData.garantiesSouscrits=mesGaranties.filter((item)=>{
              result= false
              garantiesParcourru.forEach(async (element) => { 
                  if(element.codeproduitgarantie ===item.CodeProduitGarantie)
                  {
                    result=true;
                    item.estunique = element.estunique
                    item.description = element.description
                    item.checked=true
                    item.agemin = element.agemin
                    item.agemax = element.agemax
                    item.dureecotisationmin = element.dureecotisationmin
                    item.dureecotisationmax = element.dureecotisationmax
                    item.dureecontratmin = element.dureecontratmin
                    item.dureecontratmax = element.dureecontratmax
                    item.primemin = element.primemin
                    item.montantgarantie = element.montantgarantie
                    item.type = element.type
                    item.estobligatoire = element.estobligatoire
                    item.estprincipal =  element.estprincipal
                    item.branche =  element.branche
                    item.capitaux =(element.type==='Decces')? (await getPrimesCapital(props.data.produit.CodeProduit,item.CodeProduitGarantie)):(await getCapitauxGaranies(props.data.produit.CodeProduit,item.CodeProduitGarantie))
                    
                    if(dureeCotisationMin!==undefined && element.dureecotisationmin<dureeCotisationMin) {
                      dureeCotisationMin = element.dureecotisationmin
                    }else{
                      dureeCotisationMin = element.dureecotisationmin
                    }
                    if(dureeCotisationMax!==undefined && element.dureecotisationmax>dureeCotisationMax) {
                      dureeCotisationMax = element.dureecotisationmax
                    }else{
                      dureeCotisationMax = element.dureecotisationmax
                    }
                    if(dureeContratMin!==undefined && element.dureecontratmin<dureeContratMin) {
                      dureeContratMin = element.dureecontratmin
                    }else{
                      dureeContratMin = element.dureecontratmin
                    }
                    if(dureeContratMax!== undefined && element.dureecontratmax>dureeContratMax) {
                      dureeContratMax = element.dureecontratmax
                    }else{
                      dureeContratMax = element.dureecontratmax
                    }
                    
                    if(element.estunique ===1){
                      disponibiliteGarantie.push(item.CodeProduitGarantie)
                    }
                    if(garantiesObligatoires.length!==0 && element.estobligatoire===0){
                      
                      defaultData.garantiesFacultatives.push(item)
                    }
                    if(props.data.produit.CodeProduit ==='YKL_2004' || props.data.produit.CodeProduit ==='YKV_2004' || props.data.produit.CodeProduit ==='YKF_2004'){
                      const maDuree = (70-parseInt(ageAssure(dayjs(dateNaissance).format('YYYY-MM-DD'))))
                      if(maDuree){
                        dureeCotisationMin=maDuree
                        dureeCotisationMax=maDuree
                        dureeContratMin=maDuree
                        dureeContratMax=maDuree
                      }
                    }
                    affectationDuree(dureeCotisationMin,dureeCotisationMax,dureeContratMin,dureeContratMax,false)
                    return 
                  }
              });
              return result
            })
            //
          
           
           
            if(garantiesObligatoires.length!==0 && garantiesComplementaires.length!==0){
              
              defaultData.garantiesFacultatives = mesGaranties.filter((item)=>{
              
                result= false
                garantiesComplementaires.forEach(async (element) => {
                    if(element.codeproduitgarantie ===item.CodeProduitGarantie)
                    {

                      result=true;
                      item.checked=false
                      item.estunique = element.estunique
                      item.description = element.description
                      item.agemin = element.agemin
                      item.agemax = element.agemax
                      item.dureecotisationmin = element.dureecotisationmin
                      item.dureecotisationmax = element.dureecotisationmax
                      item.dureecontratmin = element.dureecontratmin
                      item.dureecontratmax = element.dureecontratmax
                      item.dureecontratmax = element.dureecontratmax
                      item.primemin = element.primemin
                      item.montantgarantie = element.montantgarantie
                      item.type = element.type
                      item.estobligatoire = element.estobligatoire
                      item.estprincipal =  element.estprincipal
                      item.branche =  element.branche
                      item.capitaux =(element.type==='Decces')? (await getPrimesCapital(props.data.produit.CodeProduit,item.CodeProduitGarantie)):(await getCapitauxGaranies(props.data.produit.CodeProduit,item.CodeProduitGarantie))
            
                      if(element.estunique ===1){
                        disponibiliteGarantie.push(item.CodeProduitGarantie)
                      }
                      return 
                    }
                });
                return result
              })
            }
          
            defaultData.monIndex = (mesAssures.length+1)
            setMesAssures([...mesAssures,defaultData])
            //réduction des garnties disponible
            let garantiesFree = mesGaranties.filter((item)=>{
              result=true
              disponibiliteGarantie.forEach(elt=>{
                if(elt==item.CodeProduitGarantie)
                {
                  result=false;
                  return 
                }
              })
              return result
            })
            setMyLoading(false)
            setMesGaranties(garantiesFree)
        }else{
          setMyLoading(false)
          setMyErrors({type:'error',message:'Aucune garanties trouvée'})
          handleOpenAlert()
          return false
        }
       }else{
        setMyLoading(false)
        setMyErrors({type:'error',message:'Aucune garanties trouvée'})
        handleOpenAlert()
        return false
       }
      }).catch((err)=>{
        console.log(err);
        setMyLoading(false)
        setMyErrors({type:'error',message:'Erreur lors de la récupération des garanties'})
        handleOpenAlert()
        return false
      });
  }

  //moi même assuré
  const handleMoimemeAssure =()=>{
      const moimeme =!moimemeAssure;
      let mesdonne={}
      setMoimemeAssure(moimeme)
      if(moimeme){
        mesdonne.nomAssure =data.nomSous && data.nomSous 
          mesdonne.prenomAssure =data.prenomSous && data.prenomSous
          mesdonne.dateNaissanceAssure =data.dateNaissanceSous && data.dateNaissanceSous
          mesdonne.lieuNaissanceAssure =data.lieuNaissanceSous && data.lieuNaissanceSous
          mesdonne.numeroCniAssure =data.pieceIdentiteSous && data.pieceIdentiteSous
          mesdonne.lieuResidenceAssure =data.lieuResidenceSous && data.lieuResidenceSous
          mesdonne.filiationAssure ='Moi même'
          mesdonne.emailAssure =data.emailSous && data.emailSous
          mesdonne.telephoneAssure =data.mobileSous && data.mobileSous
          mesdonne.monIndex = (mesAssures.length+1)
          addAssure(mesdonne,true)
      }else{
        setModeOpen('autreAssure')
      }
    }

      const handleClose = () => {
      setOpen(false);
    };

    const handleToggle = value => () => {
      const currentIndex = checked.findIndex(item =>item.IdProduitGarantie===value.IdProduitGarantie);
      const newChecked = [...checked];
      if (currentIndex === -1) {
        newChecked.push(value);
        affectationDuree(value.dureecotisationmin,value.dureecotisationmax,value.dureecontratmin,value.dureecontratmax,true)
      } else {
        newChecked.splice(currentIndex, 1);
      }
      let assuresList
      let assureGar
      
      if(newChecked && newChecked.length!==0){
        assuresList = mesAssures.map(assure=>{
          assureGar = assure.garantiesFacultatives.map(garantie=>{ 
            let result = false    
            newChecked.map(elt=>{
                if(elt.CodeProduitGarantie ===garantie.CodeProduitGarantie){
                  result =true 
                  return
                }
            })
            garantie.checked=result
            return garantie
          })
          const obGarantie = Object.assign(assureGar)
         
          assure.garantiesFacultatives =obGarantie
          return assure
        })
      }
      if(assuresList && assuresList.length!==0){
        setMesAssures(Object.assign(assuresList))
      }
      setChecked(newChecked); 
    };

    //Ajouter un nouvel assuré
    const handleAddAssure =(mesdonne)=>{
      if(mesdonne){
        addAssure(mesdonne,false)
        //setMesAssures([...mesAssures,mesdonne])
        handleClose()
      }
    }
   
    //suppression de l'assuré
    const deleteAssure =(assure)=>{
        let crurrentAsuree = mesAssures
        let currentGaranties =assure.garantiesSouscrits.filter((item)=>{
          let result= false
          if (mesGaranties.length!==0){
            mesGaranties.forEach((elt)=>{
              if(elt.CodeProduitGarantie !== item.CodeProduitGarantie){
                result = true
                return 
              }
            })
          }else{
            result = true
          }
          return result
        })
        crurrentAsuree.garantiesFacultatives=[] 
       // setMesGaranties(mesGaranties.concat(currentGaranties))      
        setMesAssures(crurrentAsuree.filter((item=>item.monIndex!==assure.monIndex)))
    }
        
    useEffect(() => {
      if(data && data.hasOwnProperty('produit')){
        props.getGarantiesProduit(data.produit.CodeProduit).then((data)=>{   
          if(data) {
            setMesGaranties(data)
          }
        })
      }
      
    }, []); 
   
    const choixAssure=()=>{
      return (
        <Grid container>
            <FormControlLabel
            control={
              <Checkbox checked={moimemeAssure} onChange={handleMoimemeAssure} value="moimemeAssure" />
            }
            label="Je suis moi même l'assuré ? (Adhérent égale Assuré(e))"
          />
          <Box display='flex' flexDirection='flex-end'>
            <Button className ={clsx(classes.root, className)}  onClick={()=>handleOpen('autreAssure')}> <AddIcon fontSize='large' /><Typography>Ajouter un autre assuré</Typography></Button>
          </Box>
      </Grid>
      )
    }
    //Affichage des assurée
    const renderAssure =(props)=>{
      props.input.onChange(mesAssures)
      
      return( mesAssures.length!==0? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Assuré(e)</TableCell>
                <TableCell align="left">Garanties</TableCell>
                <TableCell align="left">Garanties complementaires</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {mesAssures.map((assure,index) => { 
                return (<TableRow key={assure.numeroCniAssure}>
                  <TableCell component="th" scope="row">
                      <Typography component="h5" variant="h5">{`${assure.nomAssure} ${assure.prenomAssure}`}</Typography>
                      <div className={classes.assureInfoRow}><Typography>N°CNI : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${assure.numeroCniAssure} `}</Typography></div>
                      <div className={classes.assureInfoRow}>
                        <Typography>Né(e) le : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${assure.dateNaissanceAssure} `}</Typography>
                        <Typography> à : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${assure.lieuNaissanceAssure} `}</Typography>
                      </div>
                      <div className={classes.assureInfoRow}><Typography>Lieu de résidence : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${assure.lieuResidenceAssure} `}</Typography></div>
                      <div className={classes.assureInfoRow}><Typography>Lien de parenté : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${assure.filiationAssure} `}</Typography></div>
                      <div className={classes.assureInfoRow}><Typography>Téléphone : </Typography><Typography variant="subtitle1" color="textSecondary"> {` ${assure.telephoneAssure} `}</Typography></div>
                      <div className={classes.assureInfoRow}><Typography>Email : </Typography><Typography variant="subtitle1" color="textSecondary"> {`${assure.emailAssure}`}</Typography></div>
                  </TableCell>
                  <TableCell align="left">{
                    assure.garantiesSouscrits.length!==0 && assure.garantiesSouscrits.map((garantie,index)=>(<Typography key={index}> - {garantie.MonLibelle +'\n'}</Typography> ))
                    }</TableCell>
                    <TableCell align="left">
                    {(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0)&& <>
                      <Typography variant="subtitle1" color="textSecondary">Je souhaite souscrire à la garantie</Typography>
                      {assure.garantiesFacultatives.map((garantie)=>(
                        <ListItem  key={garantie.IdProduitGarantie} role={undefined} dense button onClick={handleToggle(garantie)}>
                          <ListItemIcon> 
                            <Checkbox
                              edge="start"
                              checked={checked.findIndex(item =>item.IdProduitGarantie===garantie.IdProduitGarantie) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': garantie.IdProduitGarantie }}
                            />
                          </ListItemIcon>
                          <ListItemText id={garantie.IdProduitGarantie} primary={garantie.MonLibelle.toUpperCase()} />
                             
                            <ListItemSecondaryAction>
                            <GarantiesInfo data={garantie}/>   
                          </ListItemSecondaryAction>                
                          </ListItem>
                      ))
                    }</>}
                   </TableCell>
                  <TableCell align="right">
                    <IconButton
                      data-id={assure.numeroCniAssure}
                      aria-label="actions"
                      aria-haspopup="true"
                      onClick={()=>deleteAssure(assure)}
                    >
                    <DeleteIcon />
                    </IconButton>
                     
                    </TableCell>
                </TableRow>
               
            )})}
            </TableBody>
          </Table>
      </TableContainer>):<Typography>Aucun Assuré </Typography>
     )}
   
     //processus principal
    return (

    <Grid item sm={12} xs={12} >
        {myErrors&&(
          <Snackbar
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity={myErrors.type}>
            {myErrors.message}
        </Alert>
        </Snackbar>
        )}
        {choixAssure()}
        <ProfilModalForm 
          handleClose={handleClose} 
          handleOpen={handleOpen} 
          open={open} 
          handleAddAssure={handleAddAssure}  
          mesAssures={mesAssures}
          mesGaranties={mesGaranties} 
          modeOpen={modeOpen}
        />
       {!myLoading?(
         <>
          <Field 
            name="assures"
            component={renderAssure}
        /> 
      
         
            <Divider component="hr" className={classes.hTrait}/>
            <Grid container direction="row" justify="space-between" alignItems="center">        
             
                <Button type="button"  onClick={props.handleBack}>
                  Retour
                </Button>
             
              
                <Button type= 'submit' color='primary' onClick={handleSubmit} disabled={mesAssures.length===0?true:false}>
                  Continuer
                  <NavigateNextOutlinedIcon /> 
                </Button>
            
            </Grid>
        
         </>
       ):(
        <ModalLoading myopen={myLoading} />
       )}
          
      
      
</Grid>
      
    );
  }
  const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
  const mapActionsToProps = {
    getGarantiesAssure,
    getGarantiesProduit,
    updateField
  }

  const mapStateProps =(state)=>({
    data:getFormValues('adForm')(state),
    UI:state.UI
  })
  ProfilAssure =withStyles(styles)(ProfilAssure)

  export default connect(mapStateProps,mapActionsToProps)(reduxForm({form: 'adForm',destroyOnUnmount: false})(ProfilAssure))
