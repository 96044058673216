import React,{useState,useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs'
import {formatNumber} from 'application'
import Button from '@material-ui/core/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {getBordereauProposition} from "redux/actions/apiActions"
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import StopIcon from '@material-ui/icons/Stop';
function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return 'Confirmation de la liste';
      case 1:
        return 'Choix du borderau';
      case 2:
        return 'Terminer la validation';
      default:
        return 'Aucune étape';
    }
  }
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
  });
  function getSteps() {
    return ['Confirmation de la liste', 'Choix du bordereau', 'Terminer la validation'];
  }
function ValidationList(props) {
    const {open,handleClose,data,viderListe,retirer,activeStep,handleNext,handleBack,rapport,handleValidate,downloadRapport}=props
    const [bordereauxProposition,setBordereauxProposition] =useState([]) 
    const [bordereauChecked,setBordereauChecked]=useState(null)
    const classes = useStyles();
    const steps = getSteps();
    const handleCheckedord =(bord)=>{
       if(bordereauChecked && bordereauChecked.IDTblPropositionBordereau!==bord.IDTblPropositionBordereau){
            setBordereauChecked(bord) 
       }else if(bordereauChecked && bordereauChecked.IDTblPropositionBordereau===bord.IDTblPropositionBordereau){
        setBordereauChecked(null)
       }else{
        setBordereauChecked(bord) 
       } 
    }
    
    useEffect(() => {
        getBordereauProposition().then(res=>{
            if(res){
                setBordereauxProposition(res)
            }
        })
    }, [])

    return (
        <Dialog 
            maxWidth='md' 
            fullWidth
            open={open} 
            onClose={handleClose} 
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between', alignItems:'center', marginBottom:10}}>
                <Typography component='h5' variant='h5'>Liste de validation</Typography> 
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                </div>
                <Divider />
                <div>
                <Stepper alternativeLabel activeStep={activeStep}>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
                </div>
            </DialogTitle>
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                       {(activeStep===1)&&(<Table aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Adhérent</TableCell>
                                <TableCell align="center">Date d'effet</TableCell>
                                <TableCell align="center">Produit</TableCell>
                                <TableCell align="center">Prime</TableCell>
                                <TableCell align="right">Capital</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map(row=>(
                                    <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                      {row.id}
                                    </TableCell>
                                    <TableCell align="center">{`${row.nom} ${row.prenom}`}</TableCell>
                                    <TableCell align="center">{dayjs(row.dateeffet).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="center">{`${row.codeproduit} ${row.libelleproduit}`}</TableCell>
                                    <TableCell align="center">{parseInt(row.prime)?`${formatNumber(parseInt(row.prime))} F CFA`:""}</TableCell>
                                    <TableCell align="center">{row=>parseInt(row.capital)?`${formatNumber(parseInt(row.capital))} F CFA`:""}</TableCell>
                                    <TableCell align="center">
                                    <IconButton edge="start" color="inherit" onClick={()=>retirer(row.id)}  aria-label="close">
                                        <HighlightOffIcon />
                                    </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                        </Table>)}
                        {(activeStep===2)&&(
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Choix</TableCell>
                                        <TableCell>#</TableCell>
                                        <TableCell align="center">Code</TableCell>
                                        <TableCell align="center">Libellé</TableCell>
                                        <TableCell align="center">Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(bordereauxProposition && bordereauxProposition.length!==0)&&(bordereauxProposition.map(bord=>(
                                       <TableRow key={bord.IDTblPropositionBordereau}>
                                       <TableCell component="th" scope="row">
                                       <IconButton edge="start" color="inherit" onClick={()=>handleCheckedord(bord)} aria-label="close">
                                           { (bordereauChecked && bord.IDTblPropositionBordereau===bordereauChecked.IDTblPropositionBordereau)?(<CheckBoxIcon style={{ fontSize: 40 }} color="primary" />):( <CheckBoxOutlineBlankIcon />)}
                                           
                                        </IconButton>
                                       </TableCell>
                                       <TableCell align="center">{`${bord.IDTblPropositionBordereau}`}</TableCell>
                                       <TableCell align="center">{`${bord.CodeLot}`}</TableCell>
                                       <TableCell align="center">{bord.MonLibelle}</TableCell>
                                       <TableCell align="center">{dayjs(bord.SaisieLe).format('DD/MM/YYYY')}</TableCell>
                                     </TableRow> 
                                    )))}
                                </TableBody>
                            </Table>
                        )}
                        {(activeStep===3)&&(
                         <Alert icon={null} severity="warning">
                           <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:20}}>
                          <Typography color='primary' component='h2' component='h2'>Vous vous appretez à valider</Typography>
                          <Typography component='h1' variant='h1'>{data.length} proposition (s) </Typography>
                          <Typography color='primary' component='h2' component='h2'>sur le bordereau</Typography>
                          <Typography component='h2' variant='h2'>N° : {bordereauChecked&&bordereauChecked.CodeLot}  </Typography>
                      </div>
                    </Alert>
                           
                        )}
                        {
                          (activeStep===4)&&(
                            <div className={classes.demo}>
                              {(rapport && rapport.length!==0)?(
                                <Table aria-label="simple table">
                                  <TableBody>
                                      {rapport.map((r,i)=>(
                                        <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                          {dayjs(r.date).format('DD/MM/YYYY:HH:MM')}
                                        </TableCell>
                                        <TableCell align="rigth">{`${r.libelle}`}</TableCell>
                                        <TableCell align="center">{r.resultat==='OK'?<CheckCircleIcon color='primary'/>:""}</TableCell>
                                        <TableCell align="center">{r.resultat}</TableCell>
                                        <TableCell align="center">{r.ref}</TableCell>
                                      </TableRow> 
                                      ))}
                                  </TableBody>
                                 </Table> 
                              ):(<CircularProgress color='primary' />)}
                              <div style={{display:'flex',alignItems:'center',justifyContent:'center', marginTop:20,marginBottom:20}}>
                                <Button variant='contained' size='large' color="seconndary" onClick={downloadRapport} style={{marginRight:20}}>
                                    <GetAppIcon style={{ fontSize: 40 }}  /> Télécharger le rapport
                                </Button>
                                <Button variant='contained' size='large' color="primary" onClick={props.handleFin}>
                                    <StopIcon style={{ fontSize: 40 }} /> Terminer
                                </Button>
                              </div>
                              
                            </div>
                          )
                        }
                    </GridItem>
                </GridContainer>
                <Divider />
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between', marginTop:20}}>
                    <Button disabled={activeStep===1} variant='contained' onClick={handleBack} color="secondary">
                        Retour
                    </Button>
                    <Button disabled={(!data || data.length===0)} variant='outlined' onClick={viderListe} color="primary">
                        Effacer la liste
                    </Button>
                    {(activeStep===3 || activeStep===4)?( <Button disabled={(!data || data.length===0 || activeStep>3)} onClick={()=>handleValidate(bordereauChecked,data)} variant='contained' size='large' color="primary">
                        Terminer
                    </Button>):(
                         <Button disabled={(!data || data.length===0)} onClick={handleNext}  variant='contained' size='large' color="primary">
                         Continuer
                     </Button>
                    )}
                   
            </div>
            </DialogContent>
            
      </Dialog>
    )
}

export default ValidationList
