import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import CustomInput from 'components/CustomInput/CustomInput'
import TextField from '@material-ui/core/TextField';
import {getEquipesZones,getZonesReseau,getRolesReseau,getReseauxNoDispatch,changerEquipeUser} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import dayjs from 'dayjs'
import CircularProgress from '@material-ui/core/CircularProgress';
import CustumModal from 'components/CustumModal';
import { Button } from '@material-ui/core';
import swal from 'sweetalert';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        justifyContent:'center',
        alignItems:'center'
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));

function ChangerEquipeUser(props){
    const classes = useStyles();
    //intialisation des élement du state
    const {userData,mopen,handleCloseMod}=props //recuperation des valieur du props

    const [zones,setZones] = useState(null)
    const [equipes,setEquipes] = useState(null)
    const [reseaux,setReseaux] = useState(null)
    const [rolesReseau,setRolesReseau] = useState(null)
    const [userinfos,setUserinfos] = useState(null)

    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
        codeuser:userData.idmembre?userData.idmembre:'',
        codezone:userData.codezone?userData.codezone:'',
        codeequipe:userData.codeequipe?userData.codeequipe:'',
        codereseau:userData.codereseau?userData.codereseau:'',
        coderole:userData.coderole?userData.coderole:'',
        profession:userData.profession?userData.profession:''
    });
  
    const [loading,setLoading] = useState(false)
    const handleChange = (event)=>{   
        const {name,value} = event.target
        setInputField({
            [name]:value
        })
    }
    //agence actuelle
    useEffect(()=>{
        if(reseaux && zones && equipes  && userData){
            let currentInfos =""
            const currentRezo = reseaux.filter(item=>item.id==userData.codereseau)
            const currentZone = zones.filter(item=>item.id==userData.codezone)
            const currentEquipe = equipes.filter(item=>item.id==userData.codezone)
            if(currentEquipe!==undefined && currentEquipe && currentEquipe.length!==0){
                currentInfos= `${currentEquipe[0].libelleequipe}`
            }
            if(currentInfos && currentZone!==undefined && currentZone && currentZone.length!==0){
                currentInfos+= ` / ${currentZone[0].libellezone}`
            }
   
            if(currentInfos && currentRezo!==undefined && currentRezo && currentRezo.length!==0){
                currentInfos+= ` / ${currentRezo[0].libelle}`
            }
         
            setUserinfos(currentInfos)
        }
    },[reseaux,zones,equipes,userData])
 
    useEffect(()=>{
        if(inputField.codereseau!==''){
            getZonesReseau(inputField.codereseau).then(dataZone=>{
                if(dataZone){
                    setZones(dataZone)
                }
            })
        }
        if(inputField.codereseau!==''){
            getRolesReseau(inputField.codereseau).then(dataRole=>{
                if(dataRole){
                    setRolesReseau(dataRole)
                }
            })
        }
    },[inputField.codereseau])
    useEffect(() => {
      if(inputField.codezone!==''){
        getEquipesZones(inputField.codezone).then(dataEquipe=>{
            if(dataEquipe){
                setEquipes(dataEquipe)
            }
        })
      }
   },[inputField.codezone])

   useEffect(() => {
    getReseauxNoDispatch().then(drezo=>{
      if(drezo){
          setReseaux(drezo)
      }
    })
 },[])
    const handleSaveUtilisateur =(event)=>{
        if(inputField.idmembre===""){
            swal("Attention !","veuillez selectionner un utilisateur","warning") 
            return
        }
      setLoading(true)
    changerEquipeUser(inputField).then(dataUser=>{
        if(dataUser){
            setLoading(false)
            swal("Bien !","Modification effectuée avec succès","success")
            handleCloseMod()
        }else{
            setLoading(false)
            swal("Ooops","Erreur lors de l'enregistrement",'error')
            return false
        }
        return
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        swal("Ooops !",err,'error')
        return
    })
    event.stopPropagation()
    return 
  }

  //processus principale
return (
        
        <CustumModal title="Change d'équipe/agence" open={mopen} handleClose ={handleCloseMod} width='sm' actions={
                <div className='flex flex-row justify-between items-center'>
                    <Button onClick={handleCloseMod} variant='outlined' className='py-2 px-4 bg-secondary-main text-white rounded-full border-0 shadow-md'>Annuler</Button>
                    <Button variant='outlined' type='submit'  className='py-2 px-4 bg-primary-main hover:bg-secondary-main text-white rounded-full border-0 shadow-md'>Enregistrer</Button>
                </div>
               } >
                <form onSubmit={handleSaveUtilisateur}>
                    {!loading?(
                    <div className='flex flex-col'>
                        <LabelledOutline label="Informations personnelles" >
                            <div className='flex flex-row gap-2'>
                                <Typography variant='subtitle1'>Nom :</Typography><Typography variant='h6'>{userData.nom?userData.nom:""}</Typography>
                                <Typography variant='subtitle1'>Prenom :</Typography><Typography variant='h6'>{userData.prenom?userData.prenom:""}</Typography>
                            </div>
                            <div className='flex flex-row gap-2'>
                            <Typography variant='subtitle1'>Né(e) le :</Typography><Typography variant='h6'>{userData.datenaissance?dayjs(userData.datenaissance).format('DD/MM/YYYY'):""}</Typography>
                            <Typography variant='subtitle1'>Genre:</Typography><Typography variant='h6'>{userData.sexe?userData.sexe:""}</Typography>   
                            </div>
                        </LabelledOutline>       
                        <LabelledOutline label="Agence actuelle" >
                            <Typography variant='h6'>{userinfos&&userinfos}</Typography>
                        </LabelledOutline>
                        <LabelledOutline label="Nouvelle agence" > 
                            {(reseaux)&&<TextField
                                    variant="outlined"
                                    id="codereseau"
                                    name ='codereseau'
                                    select
                                    label="Réseau de commercialisation"
                                    value={inputField.codereseau&&inputField.codereseau}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginTop:20,marginBottom:10}}
                                    >
                                    <MenuItem key={0} value={0} />
                                        
                                    {(reseaux && reseaux.length !==0)&& reseaux.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.libelle}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                            {zones&&<TextField
                                    variant="outlined"
                                    id="codezone"
                                    name ='codezone'
                                    select
                                    label="Zone/Departement"
                                    value={inputField.codezone}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginTop:20,marginBottom:10}}
                                    >
                                        <MenuItem key={0} value={0} />
                                    {(zones && zones.length !==0)&& zones.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.libellezone}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                            {equipes&&<TextField
                                    variant="outlined"
                                    id="codeequipe"
                                    name ='codeequipe'
                                    select
                                    label="Equipe/Agence"
                                    value={inputField.codeequipe}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{marginTop:20,marginBottom:10}}
                                    >
                                        <MenuItem key={0} value={0} />
                                    {(equipes && equipes.length !==0)&& equipes.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.libelleequipe}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            }
                                       
                            <CustomInput
                                variant="outlined"
                                id="profession"
                                name='profession'
                                label="Profession"
                                type="text"
                                value = {inputField.profession}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={handleChange}
                            />
                                       
                                 
                        </LabelledOutline>
                        {rolesReseau&&<LabelledOutline label="Profil" >
                                  
                            <TextField
                                variant="outlined"
                                id="coderole"
                                name ='coderole'
                                select
                                label="Profile"
                                value={inputField.coderole}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginTop:20,marginBottom:10}}
                                >
                                    <MenuItem key={0} value={0} />
                                {(rolesReseau && rolesReseau.length !==0)&& rolesReseau.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.role}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </LabelledOutline>}
                          
                        </div>):(<div className={classes.root}><CircularProgress color="primary" /></div>)}
                    
                </form>  
                </CustumModal>
           
    )
}

export default ChangerEquipeUser