import React, { Fragment, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Field, reduxForm, getFormValues, change } from 'redux-form'
import { connect, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core'
import DocumentCard from './DocumentCard'
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Divider from '@material-ui/core/Divider';
import { uploadDocuments } from 'redux/actions/apiActions'
import ModalLoading from 'components/ModalLoading'
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    border: 1,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0 3px 5px 2px rgba(6, 6, 6, .3)',
    }
  },

  table: {
    minWidth: 650,
  },
  btnAdd: {
    padding: 20,
    marginBottom: 10,
    '.MuiButton-outlined': {
      root: {
        focused: {
          border: '1px solid #4A90E2'
        },
      }
    }
  },

  assureInfoRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  card_root:{
    width:'75%',
    marginLeft:'auto',
    marginRight:'auto',
    [theme.breakpoints.down('sm')]: {
      width:'100%',
    },
    [theme.breakpoints.up('md')]: {
      width:'75%',
    },
    [theme.breakpoints.up('lg')]: {
      width:'75%',
    },

  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  hTrait:{
    marginTop:20,
    marginBottom:20
},
}))
function DocumentSouscription(props) {
  const { handleSubmit, pristine, reset, submitting, data, svdata, handleTOpen, handleRedirectSouscription } = props
  const classes = useStyles();
  const [myErrors, setMyErrors] = useState(null)
  const [openAlert, setOpenAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setMyErrors(null)
  };
  const dispatch = useDispatch()

  const handleSaveDoc = (values) => {
    const id = (values && values.hasOwnProperty('id')) ? values.id : (svdata && svdata.hasOwnProperty('id')) ? svdata.id : undefined

    if (id) {
      const mesDocs = values.documents
      if (mesDocs && mesDocs.length !== 0) {
        uploadDocuments(id, mesDocs).then(res => {
          handleTOpen()
        }).catch(err => {
          console.log(err);
          handleRedirectSouscription(svdata)
          dispatch(reset('adForm'))
        })
      }
    }
  }

  const handleIgnorer = () => {
    handleRedirectSouscription(svdata)
    dispatch(reset('adForm'))
  }
  return (
    <Fragment>

      {!loading ? (
        <div className={classes.card_root}>
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Field
                id='documents'
                name='documents'
                component={DocumentCard}
                documentType={documents}
              />
            </GridItem>
          </GridContainer>

          <Divider component="hr" className={classes.hTrait} />
          <Grid container direction="row" justify="space-between" alignItems="center">

            <Button className={classes.btnback} type="button" onClick={props.handleBack}>
              Retour
            </Button>
            <Button type="button" color='info' onClick={handleIgnorer}>
              Ignorer
            </Button>
            <Button type='submit' className={classes.btnnext} onClick={handleSubmit(handleSaveDoc)} disabled={props.data && (props.data.hasOwnProperty('documents') ? (props.data.documents.length === 0 ? true : false) : false)}>
              Terminer
              <NavigateNextOutlinedIcon />
            </Button>

          </Grid>
        </form>
        </div>
      ) : (
        <ModalLoading myopen={loading} />
      )}
    </Fragment>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapActionsToProps = {
  updateField
}

const documents = [
  { titre: "Bulletin de souscription", fichier: "" },
  { titre: "Pièce d\'identité CNI", fichier: "" },
  { titre: "Autres pièces", fichier: "" }
]

const mapStateProps = (state) => ({
  data: getFormValues('adForm')(state),
})


export default connect(mapStateProps, mapActionsToProps)(reduxForm({ form: 'adForm', destroyOnUnmount: false })(DocumentSouscription))

