import React, { useReducer,useEffect,useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import {connect,useDispatch} from 'react-redux'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {updateZone} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';

function UpdateZone(props){
    const {zoneData,ropen,handleCloseAdd}=props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    codereseau:zoneData.codereseau,
    codezone:zoneData.codezone?zoneData.codezone:"",
    libellezone:zoneData.libellezone?zoneData.libellezone:"",
    coderesponsable:zoneData.coderesponsable?zoneData.coderesponsable:"",
    nomresponsable:zoneData.nomresponsable?zoneData.nomresponsable:""
    });
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveZone=()=>{
    if(inputField.codereseau==='')return
    if(inputField.libellezone.trim()==='')return
    if(inputField.codezone.trim()==='')return
    props.updateZone(zoneData.id,inputField).then(data=>{
        if(data){
            handleCloseAdd()
        }else{
            return false
        }
    }).catch(err=>{
        console.log(err);
        return
    })
  }
    return (
        <Dialog 
            maxWidth='md' 
            fullWidth
            open={ropen} 
            onClose={handleCloseAdd} 
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Modifier un reseau - #{zoneData.id}</DialogTitle>
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="codezone"
                                    name='codezone'
                                    label="code"
                                    type="text"
                                    value = {inputField.codezone}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="libellezone"
                                    name ='libellezone'
                                    onChange={handleChange}
                                    label="Nom de la zone / inspeection /département"
                                    type="text"
                                    value = {inputField.libellezone}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="coderesponsable"
                                    name='coderesponsable'
                                    label="code responsable"
                                    type="text"
                                    value = {inputField.coderesponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="nomresponsable"
                                    name ='nomresponsable'
                                    onChange={handleChange}
                                    label="Nom responsable"
                                    type="text"
                                    value = {inputField.nomresponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
                <Divider />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseAdd}  variant='outlined' color="secondary">
                    Annuler
                </Button>
                <Button onClick={handleSaveZone}  variant='outlined' color="primary">
                    Modifier
                </Button>
            </DialogActions>
      </Dialog>
    )
}
UpdateZone.propTypes = {
    zoneData: PropTypes.object.isRequired,
    ropen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
  });
  const mapActionsToProps = {updateZone}
export default connect(mapStateToProps,mapActionsToProps)(UpdateZone)