import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles'
import MaterialTable from 'material-table';
import {connect} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import {getRoles,createRole} from 'redux/actions/settingsAction'
import AddRole from './AddRole'
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Paper,Divider, Typography, IconButton } from '@material-ui/core'
const useStyle = makeStyles(theme=>({
  root:{
      display:'flex',
      flexDirection:'column',
      width:'75%',
      marginLeft:'auto',
      marginRight:'auto',
      [theme.breakpoints.down('sm')]: {
          width:'100%',
      },
      [theme.breakpoints.up('md')]: {
      width:'75%',
      },
      marginBottom:20,
      marginTop:20
  },
  hPaper:{
    padding:10,
    marginBottom:20,
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth:8
},
  hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
  },
  actionContainer:{
    display:'flex',
    justifyContent:'space-between'
  },
  paperContainer:{
    marginBottom:20,
    marginTop:20
  }
}))

function RoleList(props){
  const classes = useStyle()
    const theme = useTheme();
    const [addopen, setAddopen] = React.useState(false);
    const [roles,setRoles] = React.useState([]); 
    useEffect(() => {
      getRoles().then(dataRole=>{   
        setRoles(dataRole)
      })
    }, [])
    
    const handleClickAddOpen = () => {
      setAddopen(true);
    };

  const handleCloseAdd = () => {
    getRoles().then(dataRole=>{   
      setRoles(dataRole)
    })
    setAddopen(false)
  };
  const handleRole = ()=>{
    getRoles().then(dataRole=>{   
      setRoles(dataRole)
    })
  }
    return (
      <div className={classes.root}>
            <AddRole mopen={addopen} handleCloseMod={handleCloseAdd} handleMopen={handleClickAddOpen} />

            
            <Paper className={classes.hPaper} variant="outlined">
                <div className='flex flex-row items-center justify-between'>
                  <Typography variant='h5'>Gestion des profils</Typography>
                 
                  <Tooltip title="Nouveau profil"> 
                  <IconButton onClick={handleClickAddOpen} color='primary' size='medium'>
                      <Add style={{fontSize: 40}} />
                  </IconButton> 
                </Tooltip>
                </div>
            </Paper>
            <div className={classes.paperContainer}>    
            
              <MaterialTable
                columns={[
                  { title: '#', field: 'id' },
                  { title: 'Role', field: 'role'},
                  { title: 'Reseau', field: 'codereseau',hidden:true},
                  { title: 'id', field: 'idreseau',hidden:true},
                  { title: 'Reseau', field: 'libellereseau'},
                  { title:'Action',render:rowData=>(<div className={classes.actionContainer}>
                      <CustomMenu 
                        data={rowData} 
                        handleRole={handleRole}      
                        />
                  </div>)}
                ]}
                data={roles}
                title=""
                localization={{
                  toolbar:{
                      searchPlaceholder:'Rechercher',
                      searchTooltip:'Rechercher'
                  },
                  body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                  },
                  pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                  }
                }}
                
                options={{
                  exportButton: true,
                  searchFieldStyle:{
                    fontSize:18,
                    width:'100%',
                    height:50,
                  },
                  actionsColumnIndex: -1
                }}
              />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user
  });
  const mapActionsToProps = {createRole}
export default connect(mapStateToProps,mapActionsToProps)(RoleList)