import React,{useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/CustomButtons/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import {addPartenairePersonnes} from 'redux/actions/settingsAction'
import swal from 'sweetalert';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles'
import { green } from '@material-ui/core/colors';
import { IconButton } from '@material-ui/core';
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'

const useStyle =makeStyles(theme=>({
  dialogHead:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  hbande:{
    height:20,
    width:'100%',
    backgroundColor:green[400]
  },
  btContainer:{
    display:'flex',
    justifyContent:'space-between',
    padding:10
  },
}))

function AddPersonnesRessources(props) {
  const {mode,data,open,handleClose,actualizPerso} =props
  const classes = useStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [inputField,setInputField] = React.useState({})
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }
console.log(mode);
  //ajouter une personne resosurces
  const addPersonneRessource =(e)=>{
    e.preventDefault()
    let currentData ={...inputField}
   if(mode === 'UP'){
    addPartenairePersonnes(data['id'],currentData).then(res=>{
      if(res){
        setInputField({})
        actualizPerso()
        handleClose()
      }else{
        swal("Warning","Une erreur s'est produit lors de l'opération",'warning')
      }
    }).catch(err=>{
      console.log(err);
      swal("Error","Une erreur s'est produit lors de l'opération",'error')
    })
   }
    if(mode==='ADD'){
      if(currentData['nom'] && currentData['prenom']){
        let dataPersonnes
        if(data.hasOwnProperty('personnesressources')){
          dataPersonnes = data['personnesressources']
        }else{
          dataPersonnes=[]
        }
        currentData['id']=dataPersonnes.length+1
        let newdataPersonnes = [...dataPersonnes,currentData]
       if(props.hasOwnProperty('changedata')){
        props.changedata({...data,['personnesressources']:newdataPersonnes})
       }
        setInputField({})
      }else{
        return false
      }
      handleClose()
    }
  }

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        <div className={classes.dialogHead}>
            Ajouter une personne
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
        </div>
      </DialogTitle>
      <div className={classes.hbande}></div>
    <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <CustomInput
                required
                autoFocus
                variant="outlined"
                id="nom"
                name='nom'
                label="Nom"
                type="text"
                value = {inputField.nom}
                formControlProps={{
                    fullWidth: true
                }}
                onChange={handleChange}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <CustomInput
                autoFocus
                required
                variant="outlined"
                id="prenom"
                name ='prenom'
                onChange={handleChange}
                label="Prénom"
                type="text"
                value = {inputField.prenom}
                formControlProps={{
                    fullWidth: true
                }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
              <CustomInput
                autoFocus
                required
                variant="outlined"
                id="email"
                name ='email'
                onChange={handleChange}
                label="email"
                type="text"
                value = {inputField.email}
                formControlProps={{
                    fullWidth: true
                }}
              />
          </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomPhoneInput
                required
                variant="outlined"
                id="telephone"
                name ='telephone'
                onChange={(v)=>setInputField({...inputField,['telephone']:v})}
                label="Téléphone"
                type="text"
                value = {inputField.telephone}
                formControlProps={{
                    fullWidth: true
                }}
                
            />
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <CustomPhoneInput
              required
              variant="outlined"
              id="mobile"
              name ='mobile'
              onChange={(v)=>setInputField({...inputField,['mobile']:v})}
              label="Mobile"
              type="text"
              value = {inputField.mobile}
              formControlProps={{
                  fullWidth: true
              }}
                
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
            <CustomSimpleSelect
                required
                id='fonction'
                name="fonction" 
                label="Fonction"
                formControlProps={{
                  fullWidth: true,
                }}
                variant="outlined"
                onChange={handleChange}
                value = {inputField['fonction'] ||""}
                options={["Directeur générale","Directeur/Responsable ressource humaimne","Directeur/responsable commerciale","Agent commerciale","Autre"]}
            />
            </GridItem>
      </GridContainer>
      <Divider />
    </DialogContent>
    <DialogActions>
      <div className={classes.btContainer}>
        <Button onClick={handleClose} round variant='outlined' color="warning">Annuler</Button>
        <Button onClick={addPersonneRessource} round variant='outlined' color="success">Ajouter</Button>
      </div>
    </DialogActions>
</Dialog>
  )
}

export default AddPersonnesRessources