import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// MUI Stuff
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// Redux stuff
import { connect } from 'react-redux';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Divider } from '@material-ui/core';

const styles =(theme) =>({
    ...theme.spread
})

class Signup extends React.Component {
    state ={
        oldlogin:'',
        oldPassword:'',
        email:'',
        telephone:'',
        nom:'',
        prenom:'',
        password:'',
        ponfirmPassword:'',
        errors:{},
        loading:false
    }
    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
          loading: true
        });
        const newUserData = {
          email: this.state.email,
          password: this.state.password,
          confirmPassword: this.state.confirmPassword,
          handle: this.state.handle
        };
       // this.props.signupUser(newUserData, this.props.history);
      };
      handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
      };
    render(){
        const {classes} = this.props
        const {errors,loading} = this.state
        return(
            <Grid container className={classes.form}>
                    <Grid item sm />
            <Grid item sm>
                 <LockOpenIcon color='primary' fontSize='large' className={classes.image} />
                <Typography variant="h2" className={classes.pageTitle}>
                   Inscription
                </Typography>
            <form noValidate onSubmit={this.handleSubmit}>
                <TextField
                    id="login"
                    name="login"
                    type="text"
                    label="Votre login"
                    className={classes.textField}
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    value={this.state.login}
                    onChange={this.handleChange}
                    fullWidth
                />
               
                <TextField
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    className={classes.textField}
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    value={this.state.email}
                    onChange={this.handleChange}
                    fullWidth
                />
              
                <TextField
                    id="nom"
                    name="nom"
                    type="text"
                    label="Nom"
                    className={classes.textField}
                    helperText={errors.nom}
                    error={errors.nom ? true : false}
                    value={this.state.nom}
                    onChange={this.handleChange}
                    fullWidth
                    />
                    <TextField
                        id="prenom"
                        name="prenom"
                        type="text"
                        label="Prénom"
                        className={classes.textField}
                        helperText={errors.prenom}
                        error={errors.prenom ? true : false}
                        value={this.state.prenom}
                        onChange={this.handleChange}
                        fullWidth
                    />

                <TextField
                id="password"
                name="password"
                type="password"
                label="Nouveau mot de passe"
                className={classes.textField}
                helperText={errors.password}
                error={errors.password ? true : false}
                value={this.state.password}
                onChange={this.handleChange}
                fullWidth
                />
                <TextField
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                label="Confirmation mot de passe"
                className={classes.textField}
                helperText={errors.confirmPassword}
                error={errors.confirmPassword ? true : false}
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                fullWidth
                />
               
                {errors.general && (
                <Typography variant="body2" className={classes.customError}>
                    {errors.general}
                </Typography>
                )}
                <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
                >
              Activer mon compte
              {loading && (
                <CircularProgress size={30} className={classes.progress} />
              )}
            </Button>
            <br />
            <small>
              Vous avez déjà activer votre compte ?  <Link to="/connexion">Connectez vous ici</Link>
            </small>
          </form>
            </Grid>
            <Grid item sm />
            </Grid>
            
       
            );
    }
}

export default withStyles(styles)(Signup)