import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import { renderToString } from "react-dom/server";
import { ageAssure, getPrivilegesModule } from 'redux/actions/userActions'
import dayjs from 'dayjs'
import { selectGarantiesContrat, selectAssuresContrat, selectBeneficairesContrat, getExamenExiger, getTableMortaliteAssure, getTauxPartenaire } from 'redux/actions/apiActions'
import XLSX from 'xlsx';
import { getPrimeGarantie } from 'redux/actions/apiActions';
import { getPrimeReference } from 'redux/actions/apiActions';
import { getPrimeAccessoireProduit } from 'redux/actions/apiActions';
import { getGarantiesAssure } from 'redux/actions/apiActions';
import { getFacteurFraction } from 'redux/actions/apiActions';
import { getTanchePrime } from 'redux/actions/apiActions';

export const formatNumber = (num) => {
  return num ? num.toString().replace(/ /g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') : ""
}
export const roundMultiple = (nombre, multible)=> {
  return Math.round(nombre / multible) * multible;
}
export const fileExist = (path) => {
  try {
    return Boolean(new URL(path));
  } catch (e) { return false; }
}

export const etatEncaissement = async (data,user=null) => {
  const { assures, payeur, details, enc: { nonRegle, confirmer, partielle } } = data
  const headRows1 = () => ([{ libelle: 'Libelle', valeur: 'Valeur' }])

  let doc = new jsPDF();
  let pageSize = doc.internal.pageSize
  
  const footer = () => {
    doc.setFontSize(7)
    doc.setFont(undefined, 'italic')
    doc.setLineWidth(0.1);
    doc.line(10, pageHeight - 35, pageWidth - 10, pageHeight - 35);
    const splitTitle2 = doc.splitTextToSize(`Société Anonyme d’Assurance et Réassurance VIE au capital de 5 000.000.000 F CFA libéré à hauteur de 3 000 000 000 F CFA.\nEntreprise régie par le code des Assurances CIMA.\nSiège social ABIDJAN-PLATEAU – Immeuble Woodin Center – 4ème étage- Avenue Noguès\n 01 B.P. 11885 Abidjan 01 – Tél. : (225)20 22 94 64 – Fax : (225)20 22 95 92 –  : R.C. N°179951 Abidjan – C.C. N°9511213Y`, 180);
    doc.text(100, pageHeight - 30, splitTitle2, null, 'center')
    doc.setFontSize(11)
    doc.setFont(undefined, 'normal')
  }
  let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
  let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
  let img = new Image(35, 23)
  img.src = require('assets/images/logo_yako.jpg')
  doc.addImage(img, "JPEG", 10, 5, 35, 23);
  doc.setFontSize(11)
  doc.setFont(undefined, 'bold')
 
  doc.text(20, 25, `ETAT DES COTISATIONS  POLICE N° ${details[0]['IDUnique']}`);
  doc.setFont(undefined, 'normal')
  user&&doc.text(165,20,user['nom']+" "+user['prenom'])
  doc.text(165, 25, dayjs().format("DD/MM/YYYY HH:MM"));
  let finalY = 28
  doc.autoTable({
    startY: finalY + 2,
    head: headRows1(),
    body: [
      ["N° Formulaire : ", `${details[0]['CodepropositionForm']}`],
      ["Produit: ", `${details[0]['produit']}`],
      ["Agent : ", `${details[0]['CodeConseiller']} - ${details[0]['NomAgent']}`],
      ["Statut : ", details[0]['OnStdbyOff'] == 1 ? "En cours" : (details[0]['OnStdbyOff'] == 2 ? "En veille" : (details[0]['OnStdbyOff'] == 3 ? "Arrêté" : "Inconnu"))],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 100,
    margin: {
      left: 10
    },
  })
  doc.autoTable({
    startY: finalY + 2,
    head: headRows1(),
    body: [
      ["ID.................. : ", `${details[0]['IdProposition']}`],
      ["GUID:............... ", `${details[0]['GUUID']}`],
      ["N° Proposition...... : ", `${details[0]['IDUnique']}`],
      ["N° Controle......... : ", `${details[0]['CodeProposition']}`],
    ],
    theme: "plain",
    showHead: false,
    tableLineWidth: 0.1,
    tableLineColor: 10,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 100,
    margin: {
      left: 90
    },
  })
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle2: 'Libelle2', valeur2: 'Valeur2', libelle3: 'Libelle3', valeur3: 'Valeur3' }],
    body: [["Effet adhésion : ", `${details[0]['DateEffetReel']}`, "Durée adhésion : ", `${details[0]['DureeCotisationAns']} Ans`, "Fin cotisation : ", `${details[0]['FinAdhesion']}`]],
    theme: "plain",
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle2: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur2: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle3: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur3: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
      { header: 'Libelle2', dataKey: 'libelle2' },
      { header: 'valeur2', dataKey: 'valeur2' },
      { header: 'Libelle3', dataKey: 'libelle3' },
      { header: 'valeur3', dataKey: 'valeur3' },
    ],
    tableWidth: '100%'
  })
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  doc.setLineWidth(0.1);
  doc.line(10, finalY + 2, pageWidth - 10, finalY + 2);
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle2: 'Libelle2', valeur2: 'Valeur2', libelle3: 'Libelle3', valeur3: 'Valeur3' }],
    body: [
      ["Nbre Emission : ", `${details[0]['NbreEmission']}`, "Nbre Réglement : ", `${details[0]['NbreEncaissment']}`, "Nbre Impayés : ", `${details[0]['NbreImpayes']}`],
      ["Emission : ", `${formatNumber(parseInt(details[0]['TotalEmission'])).toString()}`, "Réglements : ", `${formatNumber(parseInt(details[0]['TotalEncaissement']).toString())}`, "Impayés : ", `${formatNumber(parseInt(details[0]['TotalImpayes']).toString())}`]
    ],
    theme: "plain",
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle2: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur2: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle3: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur3: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
      { header: 'Libelle2', dataKey: 'libelle2' },
      { header: 'valeur2', dataKey: 'valeur2' },
      { header: 'Libelle3', dataKey: 'libelle3' },
      { header: 'valeur3', dataKey: 'valeur3' },
    ],
    tableWidth: '100%'
  })
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ADHERENT / SOUSCRIPTEUR' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 8 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle2: 'Libelle2', valeur2: 'Valeur2' }],
    body: [
      ["Nom : ", `${details[0]['nomSous']}`, "Prenom : ", `${details[0]['PrenomSous']}`],
    ],
    theme: "plain",
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle2: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur2: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle3: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur3: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
      { header: 'Libelle2', dataKey: 'libelle2' },
      { header: 'valeur2', dataKey: 'valeur2' },
      { header: 'Libelle3', dataKey: 'libelle3' },
      { header: 'valeur3', dataKey: 'valeur3' },
    ],
    tableWidth: '100%'
  })
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ASSURES' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 8 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  let headAssure = () => ([
    { nomAssure: 'Nom', garantie: 'Garantie', dateEffet: 'Date effet', fraisAccs: 'Frais Accs.', primeHorsAccs: 'Prime Hors Accs.', primeTotal: 'Prime total', periodicite: 'Periodicites', status: 'Statut' }
  ]);
  let body = []
  const bodyAssure = async () => {
    let totFraisAcces = 0
    let totprimePrinc = 0
    let totPrime = 0
    if (assures) {
      assures.map((assure) => {
        totFraisAcces += Number(assure['FraisAcces'])
        totprimePrinc += Number(assure['PrimePrincipale'])
        totPrime += Number(assure['Prime'])
        body.push({
          nomAssure: assure['NomPrenom'] || "",
          garantie: assure['MonLibelle'] || "",
          dateEffet: dayjs(assure['DateEffet']).format('DD/MM/YYYY'),
          fraisAccs: (assure['FraisAcces'] && Number(assure['FraisAcces']) !== 0) ? formatNumber(parseInt(assure['FraisAcces']).toString()) : "",
          primeHorsAccs: (assure['PrimePrincipale'] && Number(assure['PrimePrincipale']) !== 0) ? formatNumber(parseInt(assure['PrimePrincipale']).toString()) : "",
          primeTotal: (assure['Prime'] && Number(assure['Prime']) !== 0) ? formatNumber(parseInt(assure['Prime']).toString()) : "",
          periodicite: assure['CodePerodicite'] === 'M' ? "Mensuelle" : (assure['CodePerodicite'] === 'T' ? "Trimestrielle" : (assure['CodePerodicite'] === 'S' ? "Smestrielle" : (assure['CodePerodicite'] === 'A' ? "Annuelle" : (assure['CodePerodicite'] === 'U' ? "Versement unique" : "Inconnu")))),
          status: ""
        })
      })
      if (body.length !== 0) {
        body.push({
          nomAssure: "",
          garantie: "",
          dateEffet: "",
          fraisAccs: totFraisAcces !== 0 ? formatNumber(totFraisAcces) : "",
          primeHorsAccs: totprimePrinc !== 0 ? formatNumber(totprimePrinc) : "",
          primeTotal: totPrime !== 0 ? formatNumber(totPrime) : "",
          periodicite: "",
          status: ""
        })
      }
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 2,
    head: headAssure(),
    body: await bodyAssure(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'PAYEUR(S) DE PRIME' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 8 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  headAssure = () => ([
    { nomPayeur: 'Nom', modePaiement: 'Mode Paiement', organisme: 'Organisme', numeroCompte: 'N° Compte' }
  ]);
  body = []
  let bodyPayeur = async () => {
    if (payeur) {
      payeur.map((pay) => {
        body.push({
          nomPayeur: pay['NomPrenom'] || "",
          modePaiement: pay['CodeModePaiement'] || "",
          organisme: pay['Societe'] || "",
          numeroCompte: pay['NumCompte'] || ""
        })
      })
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 3,
    head: headAssure(),
    body: await bodyPayeur(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'PRIMES NON REGLEES' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 8 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [244, 244, 244], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  headAssure = () => ([
    { ordre: '', idpresentation: 'N°', dateEmission: 'Date', montantEmission: 'Montant', montantRegle: 'Monatnt Réglé', dateReglement: 'Date Reglt', refReglt: 'Ref. Reglt', modeReglement: 'Mode réglément', status: "Statut" }
  ]);
  body = []
  let bodyNonRegle = async () => {
    let totEmis = 0;
    let ixd = 0
    let totReglt = 0;
    if (nonRegle) {
      nonRegle.map((en) => {
        totEmis += Number(en['MontantNet'])
        totReglt += Number(en['RegltMontant'])
        ixd++
        body.push({
          ordre: ixd,
          idpresentation: en['IdPresentation'] || "",
          dateEmission: en['MaDate'] || "",
          montantEmission: (en['MontantNet'] && Number(en['MontantNet']) !== 0) ? formatNumber(parseInt(Number(en['MontantNet'])).toString()) : "",
          montantRegle: (en['RegltMontant'] && Number(en['RegltMontant']) !== 0) ? formatNumber(parseInt(Number(en['RegltMontant'])).toString()) : "",
          dateReglement: en['RegltDate'] || "",
          refReglt: en['RegltRef'] || "",
          modeReglement: en['RegltCodePaiement'] || "",
          status: en['Statut'] || "",
        })
      })
      if (body.length !== 0) {
        body.push({
          ordre: "",
          idpresentation: "",
          dateEmission: "",
          montantEmission: totEmis !== 0 ? totEmis && formatNumber(parseInt(totEmis).toString()) : "",
          montantRegle: totReglt !== 0 ? totReglt && formatNumber(parseInt(totReglt).toString()) : "",
          dateReglement: "",
          refReglt: "",
          modeReglement: "",
          status: "",
        })
      }
    }
    return body
  }
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  doc.autoTable({
    startY: finalY + 2,
    head: headAssure(),
    body: await bodyNonRegle(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
    columnStyles: {
      montantEmission: { halign: 'center', },
      montantRegle: { halign: 'center', border: "1px solid black" },
      modeReglement: { halign: 'center' },
    },
  });
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }

  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'PRIMES REGLEES PARTIELLEMENTS' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [244, 244, 244], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  body = []
  let bodyPartielle = async () => {
    let totEmis = 0;
    let totReglt = 0;
    if (partielle) {
      partielle.map((en, ix) => {
        totEmis += Number(en['MontantNet'])
        totReglt += Number(en['RegltMontant'])
        body.push({
          ordre: (ix + 1),
          idpresentation: en['IdPresentation'] || "",
          dateEmission: en['MaDate'] || "",
          montantEmission: (en['MontantNet'] && Number(en['MontantNet']) !== 0) ? formatNumber(parseInt(Number(en['MontantNet'])).toString()) : "",
          montantRegle: (en['RegltMontant'] && Number(en['RegltMontant']) !== 0) ? formatNumber(parseInt(Number(en['RegltMontant'])).toString()) : "",
          dateReglement: en['RegltDate'] || "",
          refReglt: en['RegltRef'] || "",
          modeReglement: en['RegltCodePaiement'] || "",
          status: en['Statut'] || "",
        })
      })
      if (body.length !== 0) {
        body.push({
          ordre: "",
          idpresentation: "",
          dateEmission: "",
          montantEmission: totEmis !== 0 ? totEmis && formatNumber(parseInt(totEmis).toString()) : "",
          montantRegle: totReglt !== 0 ? totReglt && formatNumber(parseInt(totReglt).toString()) : "",
          dateReglement: "",
          refReglt: "",
          modeReglement: "",
          status: "",
        })
      }
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 2,
    head: headAssure(),
    body: await bodyPartielle(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
    columnStyles: {
      montantEmission: { halign: 'center', },
      montantRegle: { halign: 'center', border: "1px solid black" },
      modeReglement: { halign: 'center' },
    },
  });
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'PRIMES REGLEES' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [244, 244, 244], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  if (finalY >= pageHeight) {
    footer()
    doc.addPage();
    finalY = 0
  }
  body = []
  let bodyRegle = async () => {
    let totEmis = 0;
    let ixd = 0
    let totReglt = 0;
    if (confirmer) {
      confirmer.map((en, ix) => {
        ixd++
        totEmis += Number(en['MontantNet'])
        totReglt += Number(en['RegltMontant'])

        body.push({
          ordre: (ix + 1),
          idpresentation: en['IdPresentation'] || "",
          dateEmission: en['MaDate'] || "",
          montantEmission: (en['MontantNet'] && Number(en['MontantNet']) !== 0) ? formatNumber(parseInt(Number(en['MontantNet'])).toString()) : "",
          montantRegle: (en['RegltMontant'] && Number(en['RegltMontant']) !== 0) ? formatNumber(parseInt(Number(en['RegltMontant'])).toString()) : "",
          dateReglement: en['RegltDate'] || "",
          refReglt: en['RegltRef'] || "",
          modeReglement: en['RegltCodePaiement'] || "",
          status: en['Statut'] || "",
        })
      })
      if (body.length !== 0) {
        body.push({
          ordre: "",
          idpresentation: "",
          dateEmission: "",
          montantEmission: totEmis !== 0 ? totEmis && formatNumber(parseInt(totEmis).toString()) : "",
          montantRegle: totReglt !== 0 ? totReglt && formatNumber(parseInt(totReglt).toString()) : "",
          dateReglement: "",
          refReglt: "",
          modeReglement: "",
          status: "",
        })
      }
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 2,
    head: headAssure(),
    body: await bodyRegle(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
    columnStyles: {
      montantEmission: { halign: 'center', },
      montantRegle: { halign: 'center', border: "1px solid black" },
      modeReglement: { halign: 'center' },
    },
  });
  finalY = doc.previousAutoTable.finalY
  footer()

  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));

  //doc.output('dataurlnewwindow');

}

export const generateBulletin = async (data) => {
  const { user } = data
  let signature = new Image(20, 5)
  signature.src = require('assets/images/Signature_Dta.jpg')

  const headRows1 = () => ([{ libelle: 'Libelle', valeur: 'Valeur' }])
  const headTable = () => ([{ id: '#', nature: 'Nature', reponse: 'Reponse', periode: 'Date/période', lieu: 'Lieu traitement' }])
  const bodyTable = () => {
    let body = []
    let maperiodeDeb
    let periodeFin
    const { questionnaires } = data
    let idx = 0
    if (questionnaires && questionnaires.length !== 0) {
      questionnaires.forEach((element, index) => {
        if (index !== 5) {
          idx++
        }
        maperiodeDeb = element.periodetraitementdeb
        periodeFin = element.periodetraitementfin
        body.push({
          id: (index !== 5) ? idx : "",
          nature: element.nature,
          reponse: element.reponse,
          valeur: element.valeur,
          periode: (maperiodeDeb && periodeFin) && (dayjs(maperiodeDeb).format('DD/MM/YYY') + "-" + dayjs(periodeFin).format('DD/MM/YYY')),
          lieu: element.lieutraitement
        })
      });
    }
    return body
  }

  const { branche, partenaire ,codepartenaire} = user
  let doc = new jsPDF();
  let pageSize = doc.internal.pageSize
  let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
  let img = new Image(35, 23)
  img.src = require('assets/images/logo_yako.jpg')
  //doc.addImage(img, "JPEG", 10, 10, 40, 10);
  doc.addImage(img, "JPEG", 10, 5, 35, 23);
  if (branche !== 'BANKASS' && branche !== 'COURTAGE') {
    let logoEnov = new Image(35, 13)
    logoEnov.src = require('assets/images/logoYnovGreen_b.png')
    doc.addImage(logoEnov, "JPEG", (pageWidth-80), 5, 35, 13);
  } else {
    let logoPart = new Image(25, 10)
    if (codepartenaire === 'AFC') {
      logoPart.src = require('assets/images/AFC.jpg')
    } else if(codepartenaire === '092') {
      logoPart.src = require('assets/images/BNI.jpg')
    }else{
      logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
    }
    doc.addImage(logoPart, "JPEG", 180, 10, 20, 20);
  }

  let radioGroup = new jsPDF.API.AcroFormRadioButton();
  radioGroup.value = "Test";
  radioGroup.fieldName = "Form";
  doc.addField(radioGroup);

  doc.setFontSize(16)
  doc.setFont(undefined, 'bold')
  doc.text(65, 15, 'BULLETIN INDIVIDUEL D\'ADHESION');

  doc.setFontSize(10)
  doc.setFont(undefined, 'normal')
  { doc.text(65, 20, 'DEMANDE INDIVIDUEL D\'AFFILIATION AU PRÊT'); }

  doc.setDrawColor(0)
  doc.setFillColor(255, 255, 255)
  doc.rect(100, 22, 75, 10, 'FD')
  doc.setFont(undefined, 'bold')
  doc.setFontSize(12)
  doc.text(105, 29, 'N° : YAAV-' + dayjs().format('MMYYYY') + `/${data.id}`)

  doc.setFont(undefined, 'normal')
  doc.setFontSize(8)
  var splitTitle = doc.splitTextToSize(`Je soussigné ${data.nom} ${data.prenom} demande mon affiliation à l'Assurance en référence et désigne comme bénéficiaire de ladite Assurance la CONTRACTANTE ci-après désigné : ${user.partenaire}`, 180);
  doc.text(10, 35, splitTitle)
  let finalY = 35

  doc.autoTable({
    startY: 35,
    head: [{ libelle: 'Libelle', valeur: 'valeur' }],
    body: [
      ['Conseiller :', data.nomagent],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    margin: {
      left: 105,
    },
  })

  finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Adhérent / Assuré(e)' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 10 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })

  finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 2,
    head: headRows1(),
    body: [
      ["Civilité : ", data.adherent?.civilite],
      ["Nom et prénoms: ", data.adherent?.nom + ' ' + data.adherent?.prenom],
      ["Né(e) le : ", dayjs(data.adherent?.datenaissance).format('DD/MM/YYYY')],
      [" A : ", data.adherent?.lieunaissance],
      ["N° " + data.adherent?.naturepiece + " :", data.adherent?.numeropiece],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 75,
    margin: {
      left: 10
    },
  })
  doc.autoTable({
    startY: finalY + 2,
    head: headRows1(),
    body: [
      ["Profession :", data.adherent?.profession],
      ["Mobile : ", data.adherent?.mobile],
      ["Téléphone : ", data.adherent?.telephone],
      ["Email : ", data.adherent?.email],
      ["Adresse : ", data.adherent?.bp],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    margin: { left: 105 },
    tableWidth: 75,
  })

  finalY = doc.previousAutoTable.finalY
  doc.setLineWidth(0.1);
  doc.line(10, finalY + 2, 200, finalY + 2);
  doc.setFontSize(10)
  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle2: 'Libelle2', valeur2: 'Valeur2', libelle3: 'Libelle3', valeur3: 'Valeur3' }],
    body: [
      ["Type de prêt :", (data.typepret === 'immobilier' ? 'Prêt Immobilier' : (data.typepret === 'consommation' ? 'Prêt à la consommation' : (data.typepret === 'decouvert' ? 'Decouvert/Avance de trésorie' : 'Autre type de prêt'))),"Durée du prêt (Mois): "+data.duree ,"Montant du prêt (F CFA) : "+formatNumber(data.montantpret)],
      ["Mise en place prévue le ", dayjs(data.effetgarantie).format('DD/MM/YYYY'),"Mise en place effective :", data.datemiseenplace ? dayjs(data.datemiseenplace).format('DD/MM/YYYY') : "","Ref:", data.referencepret ? data.referencepret : "", ],
    ],

    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], fontSize: 9, tableWidth: 100, },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255],fontSize:9,tableWidth: 100,},
      libelle2: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], fontSize: 9, tableWidth: 100, },
      valeur2: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255],fontSize: 9, tableWidth: 100, },
      libelle3: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], fontSize: 9, tableWidth: 100, },
      valeur3: { textColor: 0, fontStyle: 'bold', halign: 'left', fillColor: [255, 255, 255], fontSize: 9, tableWidth: 100, }
    },
    rowStyles: {
      1: { rowHeight: 50 }
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
      { header: 'Libelle2', dataKey: 'libelle2' },
      { header: 'valeur2', dataKey: 'valeur2' },
      { header: 'Libelle3', dataKey: 'libelle3' },
      { header: 'valeur3', dataKey: 'valeur3' },
    ],
    margin: {
      left: 10,
      right: 10
    },
  })
  finalY = doc.previousAutoTable.finalY

  doc.setFontSize(10)
  doc.setFont(undefined, 'bold')
  doc.text(11, finalY + 5, 'Périodicité de remboursement : ');
  doc.setFont(undefined, 'normal')
  doc.text(70, finalY + 5, (data.remboursement === 'Mensuelle' ? 'Mensuelle' : (data.remboursement === 'Trimestrielle' ? 'Trimestrielle' : (data.remboursement === 'Semestrielle' ? 'Semestrielle' : (data.remboursement === 'Annuelle' ? 'Annuelle' : (data.remboursement === 'Unique' ? 'Versement unique' : "Inconnu"))))));
 
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 2,
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.2,
    padding:{
      bottom:10
    },
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle2: 'Libelle2', valeur2: 'Valeur2', libelle3: 'Libelle3', valeur3: 'Valeur3' }],
    body: [
      [codepartenaire==='PAMFCI'?"Prime couverture de prêt :":"Prime emprunteur : ", data.primeht ? formatNumber(data.primeht) : 0],
      [`Risque ${partenaire.toUpperCase()} : `, data.primerisque ? formatNumber(data.primerisque) : 0],
      ["Prime YAKO : ", data.primeobseque ? formatNumber(data.primeobseque) : 0],
      ["Surprime : ", data.montantsurprime ? formatNumber(data.montantsurprime) : 0],
      ["Prime Totale (F CFA): ", data.prime ? formatNumber(data.prime) : 0],
    ],
   
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'normal', halign:'right', fillColor: [255, 255, 255], fontSize: 9},
      valeur: { textColor: 0, fontStyle: 'bold',cellPadding:1, halign: 'left', fillColor: [255, 255, 255],fontSize:10,tableWidth: 100},
      valeur2: { textColor: 0, fontStyle: 'bold',fillColor: [255, 255, 255],fontSize:9,tableWidth: 100, valign: 'middle'},
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
      { header: 'Libelle2', dataKey: 'libelle2' },
      { header: 'valeur2', dataKey: 'valeur2' },
    ],
    margin: {
      left: 10,
      right: 10
    }, 
    createdCell: function(cell, opts) {
       if(cell.row.index==3){
        cell.cell.styles.cellPadding=2;
        cell.cell.styles.fontStyle='bold';
        cell.cell.styles.fontSize=10;
       }
      },
  })
  //
  finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Questionnaire relatif à l\'état de santé du proposant' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 10 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })
  finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle2: 'Libelle2', valeur2: 'Valeur2', libelle3: 'Libelle3', valeur3: 'Valeur3' }],
    body: [
      ["TAILLE (Cm) : ", data.taille && data.taille, "POIDS(KG): ", data.poids && data.poids, "TENSION ARTERIELLE: ", data.tension && data.tension],
      (data.fumezvous === 'Oui') ? ["FUMEZ-VOUS? : ", data.fumezvous && data.fumezvous.toUpperCase(), "Nombre de cigarette/Jour: ", data.nbcigarettejour, "BUVEZ-VOUS L'ALCOOL? : ", data.buvezvous.toUpperCase(),] : ["FUMEZ-VOUS? : ", data.fumezvous, "BUVEZ-VOUS L'ALCOOL? : ", data.buvezvous && data.buvezvous.toUpperCase(),],
      ["VOS DISTRACTIONS : ", data.distraction &&(Array.isArray(data.distraction) ? data.distraction.join().toUpperCase():data.distraction.toUpperCase()),],
      (data.hasOwnProperty('estsportif') && data.estsportif === 'Oui') ? ["PRATIQUEZ-VOUS UNE ACTIVITE SPORTIVE? : ", data.estsportif && data.estsportif.toUpperCase(), "type de sport: ", data.sportpratique] : ["PRATIQUEZ-VOUS UNE ACTIVITE SPORTIVE? : ", data.estsportif && data.estsportif.toUpperCase()],
      (data.estenarrettravail === 'Oui') ? ["ETES-VOUS EN ARRET DE TRAVAIL? : ", data.estenarrettravail && data.estenarrettravail.toUpperCase(), "Si oui, depuis le: ", dayjs(data.datearrettravail).format('DD/MM/YYY'), "Motif :", data.motifarret] : ["ETES-VOUS EN ARRET DE TRAVAIL? : ", data.estenarrettravail && data.estenarrettravail.toUpperCase(),],
      (data.estenarrettravail === 'Oui') ? ["Date réprise? : ", dayjs(data.datereprisetravail).format('DD/MM/YYY'), "CAUSE: ", data.causearrettravail && data.causearrettravail, "Date :", data.datecausetravail && data.datecausetravail] : [],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], fontSize: 8 },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle2: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], fontSize: 8 },
      valeur2: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      libelle3: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], fontSize: 8 },
      valeur3: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] }
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
      { header: 'Libelle2', dataKey: 'libelle2' },
      { header: 'valeur2', dataKey: 'valeur2' },
      { header: 'Libelle3', dataKey: 'libelle3' },
      { header: 'valeur3', dataKey: 'valeur3' },
    ],
    margin: {
      left: 10,
      right: 10
    },
  })
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 2,
    head: headTable(),
    body: bodyTable(),
    showHead: true,
    tableLineColor: [0, 0, 0],
    tableLineWidth: 0.2,
    headStyles: {
      fillColor: [255, 255, 255],
      fontSize: 10,
      textColor: 0,
      halign: 'center',
      valign: 'center'
    },
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 8, valign: 'center', cellWidth: 'auto', cellPadding: 1 },
    columnStyles: {
      id: { textColor: 0, fontStyle: 'norma', fillColor: [255, 255, 255], fontSize: 8, valign: 'center' },
      nature: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255], fontSize: 8, valign: 'center' },
      reponse: { textColor: 0, fontStyle: 'normal', fillColor: [255, 255, 255], fontSize: 8, valign: 'center' },
      periode: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255], fontSize: 8, valign: 'center' }
    },
    columns: [
      { header: '#', dataKey: 'id' },
      { header: 'Questions', dataKey: 'nature' },
      { header: 'Reponse', dataKey: 'reponse' },
      { header: 'Date/periode', dataKey: 'periode' },
    ],
    margin: {
      left: 10,
      right: 10
    },
  })
  finalY = doc.previousAutoTable.finalY
  
  doc.setFontSize(8)
  doc.setFont(undefined, 'normal')
  let myText = doc.splitTextToSize("Je certifie, avoir répondu avec exatitude et sincérité aux questions ci-dessus, n'avoir rien déclaré ou omis qui puisse induire en erreur YAKO AFRICA et reconnait être informé des dispositions de l\'Article 18 du Code des Assurances CIMA sanctionnant toute réticence ou fausse déclaration. Je déclare avoir pris connaissance des garanties offertes par le contrat souscrit par la contractante", pageWidth - 25, {})
  doc.text(myText, 10, finalY + 5)

  doc.setFontSize(9)
  doc.setFont(undefined, 'normal')
  doc.text("BON POUR AUTORISATION A L'ASSURANCE", 90, finalY + 18)
  doc.setFontSize(9)
  doc.text(10, finalY + 18, 'Fait à ................................. le ' + new Date().toLocaleDateString())

  doc.text(10, finalY + 22, ` YAKO AFRICA                                                            ADHERENT (Signature précédée de la mention LU et APPROUVE)`)
  doc.addImage(signature, "PNG", 34, finalY + 18, 40, 20)

  {/* OPTION YAKO */ }

if(data && (data.hasOwnProperty('unableyako') && !data.unableyako)){
  if(data && data.hasOwnProperty('typeadherent') && data.typeadherent==='Groupe'){
  
    data.adherentconnexe.forEach(async (adherent)=>{
      doc.addPage();
      doc.addImage(img, "JPEG", 10, 5, 35, 23);
      if (branche !== 'BANKASS' && branche !== 'COURTAGE') {
        let logoEnov = new Image(35, 13)
        logoEnov.src = require('assets/images/logoYnovGreen_b.png')
        doc.addImage(logoEnov, "JPEG", (pageWidth-80), 5, 35, 13);
      } else {
        let logoPart = new Image(25, 10)
        if (codepartenaire === 'AFC') {
          logoPart.src = require('assets/images/AFC.jpg')
        } else if(codepartenaire === '092') {
          logoPart.src = require('assets/images/BNI.jpg')
        }else{
          logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
        }
        doc.addImage(logoPart, "JPEG", 180, 10, 20, 20);
      }

      doc.setFontSize(16)
      doc.setFont(undefined, 'bold')
      doc.text(65, 15, 'BULLETIN INDIVIDUEL D\'ADHESION');

      doc.setDrawColor(0)
      doc.setFillColor(255, 255, 255)
      doc.rect(100, 22, 75, 10, 'FD')
      doc.setFont(undefined, 'bold')
      doc.setFontSize(12)
      doc.text(105, 29, 'N° : YAAV-' + dayjs().format('MMYYYY') + `/${data.id}`)
      doc.setFont(undefined, 'normal')
      doc.setFontSize(8)

      finalY = 40
  if (data.hasOwnProperty('numerocompte') && data.numerocompte) {
    doc.autoTable({
      startY: 40,
      head: [{ libelle: 'Libelle', valeur: 'valeur' }, { libelle2: 'Libelle', valeur2: 'valeur' }, { libelle3: 'Libelle', valeur3: 'valeur' }],
      body: [
        ['N° compte :', data.numerocompte, 'Agence :', data.agence, 'Conseiller :', data.nomagent],
      ],
      showHead: false,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
        valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
        libelle2: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
        valeur2: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
        libelle3: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
        valeur3: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
        { header: 'valeur', dataKey: 'valeur' },
        { header: 'Libelle', dataKey: 'libelle2' },
        { header: 'valeur', dataKey: 'valeur2' },
        { header: 'Libelle', dataKey: 'libelle3' },
        { header: 'valeur', dataKey: 'valeur3' },
      ],
      margin: {
        left: 10,
      },
    })
  }

  if (data.hasOwnProperty('numerocompte') && data.numerocompte) {
    finalY = doc.previousAutoTable.finalY
  }

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Adhérent / Assuré(e)' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })

  finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 2,
    head: headRows1(),
    body: [
      ["Civilité : ", adherent.civilite],
      ["Nom et prénoms: ", adherent.nom + ' ' + adherent.prenom],
      ["Né(e) le : ", dayjs(adherent.datenaissance).format('DD/MM/YYYY')],
      [" A : ", adherent.lieunaissance],
      ["N° " + adherent.naturepiece + " :", adherent.numeropiece],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 75,
    margin: {
      left: 10
    },
  })
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      ["Profession :", adherent.profession],
      ["Mobile : ", adherent.mobile],
      ["Téléphone : ", adherent.telephone],
      ["Email : ", adherent.email],
      ["Adresse : ", adherent.bp],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    margin: { left: 105 },
    tableWidth: 75,
  })

  finalY = doc.previousAutoTable.finalY

  //Assuré garantie
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Garanties & Primes' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })

  finalY = doc.previousAutoTable.finalY
  
  
  
  doc.autoTable({
    startY: finalY + 3,
    head: [
      { nomAssure: 'Nom', garantie: 'Garantie', capitalGarantie: 'Capital (F CFA)',  totalprime: 'Prime (F CFA)' }
    ],
    body: [
      {
        nomAssure: adherent.nom + ' ' + adherent.prenom,
        garantie: 'YAKO',
        capitalGarantie: data.hasOwnProperty('capital') ? formatNumber(parseInt(data.capital)) : '',
        totalprime: data.primeobseque ? formatNumber(data.primeobseque) : ''
      }
    ],
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255],halign:'center' },
    columnStyles: {
      totalprime: { textColor: 0, fontStyle: 'bold', halign: 'center', fontSize: '10' },
      garantie: { textColor: 0, halign: 'center' },
      capitalGarantie: { textColor: 0, halign: 'center' },
    },
  });
  
  finalY = doc.previousAutoTable.finalY
  doc.setFontSize(10)
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFontType('bold')
  doc.text('PRIME TOTALE (F CFA) : ' + formatNumber(data.primeobseque), 128, finalY + 5)

  doc.autoTable({
    startY: finalY + 10,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Bénéficaires' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })

  finalY = doc.previousAutoTable.finalY
  
  doc.autoTable({
    startY: finalY + 3,
    head: [
      { nomBeneficiaire: 'Nom', filiationBeneficiaire: 'Filiation', dateNaissanceBeneficiaire: 'Né(e) le', lieuNaissanceBeneficiaire: 'Lieu naissance', telephoneBeneficiaire: 'Téléphone' }
    ],
    body: (adherent.beneficiaireconnexe && adherent.beneficiaireconnexe.length!==0)?adherent.beneficiaireconnexe.map((beneficiaire) => {
      return {
        nomBeneficiaire: beneficiaire.nom + ' ' + beneficiaire.prenom,
        filiationBeneficiaire: beneficiaire.filiation,
        dateNaissanceBeneficiaire: beneficiaire.datenaissance?dayjs(beneficiaire.datenaissance).format('DD/MM/YYYY'):"",
        lieuNaissanceBeneficiaire: beneficiaire.lieunaissance,
        telephoneBeneficiaire: beneficiaire.mobile
      }
    }):[],
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });

  finalY = doc.previousAutoTable.finalY
  doc.setDrawColor(0, 0, 0);

  doc.rect(10, finalY + 22, pageWidth - 15, 40, 'D')
  doc.setFontSize(8)
  doc.setFont(undefined, 'italic')
  doc.text(20, finalY + 20, "CONDITIONS GENERALES")
  myText = doc.splitTextToSize("1.	Le présent contrat a pour objet de garantir le paiement d’un capital en cas de décès de l'assuré", pageWidth - 20, {})
  doc.text(myText, 30, finalY + 30)
  myText = doc.splitTextToSize("2.	En cas de décès l’assuré(e), il est servi au titre du présent contrat un capital de 1 000 000 F CFA aux bénéficiaires désignés.", pageWidth - 20, {})
  doc.text(myText, 30, finalY + 35)
  if(codepartenaire==='PAMFCI'){
    myText = doc.splitTextToSize("3.	La garantie court sur un (1) an et elle est renouvelable par tacite reconduction jusqu’à la fin de la durée de remboursement du prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 40)	 
    myText = doc.splitTextToSize("4.	La garantie YAKO est effective dès que la demande d’adhésion a été acceptée et au plus tôt à la date de mise en place du prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 45)
    myText = doc.splitTextToSize("5.	Aucune opération d’avance sur police ou rachat partiel ne peut être effectué sur ce contrat.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 50)
    myText = doc.splitTextToSize("6.	En cas de remboursement anticipé du prêt, la garantie YAKO court jusqu’à son terme.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 55)
    myText = doc.splitTextToSize("7.	En cas de rachat, la prime YAKO non acquise du prêt racheté sera déduite de la prime YAKO du nouveau prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 60)
  }else{
    myText = doc.splitTextToSize("3.	Les garanties couvrent toute la durée du prêt et sont effectives dès que la demande d’adhésion a été acceptée et au plus tôt à la date de mise en place du prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 40)
    myText = doc.splitTextToSize("4.	Aucune opération d’avance sur police ou rachat partiel ne peut être effectué sur ce contrat.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 50)
    myText = doc.splitTextToSize("5.	En cas de remboursement anticipé du prêt, la garantie YAKO court jusqu'à son terme.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 55)
    myText = doc.splitTextToSize("6.	En cas de rachat, la prime YAKO non acquise du prêt racheté sera déduite de la prime YAKO du nouveau prêt.", pageWidth - 20, {})
  }
  
  
  doc.text(myText, 30, finalY + 60)

  doc.setFontSize(9)
  doc.setFont(undefined, 'normal')
  doc.text(10, finalY + 75, 'Fait à ................................. le ' + new Date().toLocaleDateString())
  doc.text(10, finalY + 82, ` YAKO AFRICA                                          ADHERENT (Signature précédée de la mention LU et APPROUVE)`)
  doc.addImage(signature, "PNG", 10, finalY + 87, 40, 20)
    })
}else{
  doc.addPage();

  doc.addImage(img, "JPEG", 10, 5, 35, 23);
  if (branche !== 'BANKASS' && branche !== 'COURTAGE') {
    let logoEnov = new Image(35, 13)
    logoEnov.src = require('assets/images/logoYnovGreen_b.png')
    doc.addImage(logoEnov, "JPEG", (pageWidth-80), 5, 35, 13);
  } else {
    let logoPart = new Image(25, 10)
    if (codepartenaire === 'AFC') {
      logoPart.src = require('assets/images/AFC.jpg')
    } else if(codepartenaire === '092') {
      logoPart.src = require('assets/images/BNI.jpg')
    }else{
      logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
    }
    doc.addImage(logoPart, "JPEG", 180, 10, 20, 20);
  }

  doc.setFontSize(16)
  doc.setFont(undefined, 'bold')
  doc.text(65, 15, 'BULLETIN INDIVIDUEL D\'ADHESION');

  doc.setDrawColor(0)
  doc.setFillColor(255, 255, 255)
  doc.rect(100, 22, 75, 10, 'FD')
  doc.setFont(undefined, 'bold')
  doc.setFontSize(12)
  doc.text(105, 29, 'N° : YAAV-' + dayjs().format('MMYYYY') + `/${data.id}`)
  doc.setFont(undefined, 'normal')
  doc.setFontSize(8)

  finalY = 40
  if (data.hasOwnProperty('numerocompte') && data.numerocompte) {
    doc.autoTable({
      startY: 40,
      head: [{ libelle: 'Libelle', valeur: 'valeur' }, { libelle2: 'Libelle', valeur2: 'valeur' }, { libelle3: 'Libelle', valeur3: 'valeur' }],
      body: [
        ['N° compte :', data.numerocompte, 'Agence :', data.agence, 'Conseiller :', data.nomagent],
      ],
      showHead: false,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
        valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
        libelle2: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
        valeur2: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
        libelle3: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
        valeur3: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
      },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
        { header: 'valeur', dataKey: 'valeur' },
        { header: 'Libelle', dataKey: 'libelle2' },
        { header: 'valeur', dataKey: 'valeur2' },
        { header: 'Libelle', dataKey: 'libelle3' },
        { header: 'valeur', dataKey: 'valeur3' },
      ],
      margin: {
        left: 10,
      },
    })
  }

  if (data.hasOwnProperty('numerocompte') && data.numerocompte) {
    finalY = doc.previousAutoTable.finalY
  }

  doc.autoTable({
    startY: finalY + 2,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Adhérent / Assuré(e)' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })

  finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 2,
    head: headRows1(),
    body: [
      ["Civilité : ", data.adherent?.civilite],
      ["Nom et prénoms: ", data.adherent?.nom + ' ' + data.adherent?.prenom],
      ["Né(e) le : ", dayjs(data.adherent?.datenaissance).format('DD/MM/YYYY')],
      [" A : ", data.adherent?.lieunaissance],
      ["N° " + data.adherent?.naturepiece + " :", data.adherent?.numeropiece],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 75,
    margin: {
      left: 10
    },
  })
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      ["Profession :", data.adherent?.profession],
      ["Mobile : ", data.adherent?.mobile],
      ["Téléphone : ", data.adherent?.telephone],
      ["Email : ", data.adherent?.email],
      ["Adresse : ", data.adherent?.bp],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    margin: { left: 105 },
    tableWidth: 75,
  })

  finalY = doc.previousAutoTable.finalY

  //Assuré garantie
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Garanties & Primes' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })
  finalY = doc.previousAutoTable.finalY
  let headGaranties = () => ([
    { nomAssure: 'Nom', garantie: 'Garantie', capitalGarantie: 'Capital (F CFA)',  totalprime: 'Prime (F CFA)' }
  ]);
  let bodyGaranties = async () => {
    let body = []

    body.push({
      nomAssure: data.adherent?.nom + ' ' + data.adherent?.prenom,
      garantie: 'YAKO',
      capitalGarantie: data.hasOwnProperty('capital') ? formatNumber(parseInt(data.capital)) : '',
      totalprime: data.primeobseque ? formatNumber(data.primeobseque) : ''
    })
    return body
  }
  doc.autoTable({
    startY: finalY + 3,
    head: headGaranties(),
    body: await bodyGaranties(),
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255],halign:'center' },
    columnStyles: {
      totalprime: { textColor: 0, fontStyle: 'bold', halign: 'center', fontSize: '10' },
      garantie: { textColor: 0, halign: 'center' },
      capitalGarantie: { textColor: 0, halign: 'center' },
    },
  });
  finalY = doc.previousAutoTable.finalY
  doc.setFontSize(10)
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFontType('bold')
  doc.text('PRIME TOTALE (F CFA) : ' + formatNumber(data.primeobseque), 135, finalY + 5)


  doc.autoTable({
    startY: finalY + 10,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'Bénéficaires' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: {
      left: 10
    },
  })

  finalY = doc.previousAutoTable.finalY
  const auDecesbody = async () => {

    let dataAuDeces = []

    if (data.hasOwnProperty('beneficiaireaudeces') && data.beneficiaireaudeces) {
      const benefAuDeces = data.beneficiaireaudeces.split(';');
      if (benefAuDeces) {
        benefAuDeces.map(audeces => {
          if (audeces === 'conjoint') {
            dataAuDeces.push({ "libelle": "- Le conjoint non divorcé, ni séparé de corps" })
          }
          if (audeces === 'enfants') {
            dataAuDeces.push({ "libelle": "- Les enfants nés et à naître" })
          }
          if (audeces === 'autre') {
            dataAuDeces.push({ "libelle": "- Autre" })
          }
        })

      }
    }
    return dataAuDeces
  }
  if (data.hasOwnProperty('beneficiaireaudeces')) {

    doc.autoTable({
      startY: finalY + 3,
      head: [{ libelle: 'En cas de décès avant le terme' }],
      body: await auDecesbody(),
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'normal', fillColor: [255, 255, 255], }
      },
      headStyles: { fillColor: [255, 255, 255], textColor: 0, fontStyle: 'bold', valign: 'center' },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
      ],
      margin: { left: 20 },
      tableWidth: 75,
    })
  }

  finalY = doc.previousAutoTable.finalY
  const headBenficaire = () => ([
    { nomBeneficiaire: 'Nom', filiationBeneficiaire: 'Filiation', dateNaissanceBeneficiaire: 'Né(e) le', lieuNaissanceBeneficiaire: 'Lieu naissance', telephoneBeneficiaire: 'Téléphone' }
  ]);

  const bodyBeneficaire = async () => {
    let body = []
    const beneficiaires =data.hasOwnProperty('beneficiaires') ? data.beneficiaires :(data.hasOwnProperty('beneficaires') ? data.beneficaires :await selectBeneficairesContrat(data.id, "PRET"))
    

    if (beneficiaires) {
      beneficiaires.map((beneficiaire) => {
        body.push({
          nomBeneficiaire: beneficiaire.nom + ' ' + beneficiaire.prenom,
          filiationBeneficiaire: beneficiaire.filiation,
          dateNaissanceBeneficiaire: dayjs(beneficiaire.datenaissance).format('DD/MM/YYYY'),
          lieuNaissanceBeneficiaire: beneficiaire.lieunaissance,
          telephoneBeneficiaire: beneficiaire.mobile
        })
      })
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 3,
    head: headBenficaire(),
    body: await bodyBeneficaire(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });

  finalY = doc.previousAutoTable.finalY
  doc.setDrawColor(0, 0, 0);

  doc.rect(10, finalY + 22, pageWidth - 15, 40, 'D')
  doc.setFontSize(8)
  doc.setFont(undefined, 'italic')
  doc.text(20, finalY + 20, "CONDITIONS GENERALES")
  myText = doc.splitTextToSize("1.	Le présent contrat a pour objet de garantir le paiement d’un capital en cas de décès de l'assuré", pageWidth - 20, {})
  doc.text(myText, 30, finalY + 30)
  myText = doc.splitTextToSize("2.	En cas de décès l’assuré(e), il est servi au titre du présent contrat un capital de 1 000 000 F CFA aux bénéficiaires désignés.", pageWidth - 20, {})
  doc.text(myText, 30, finalY + 35)
  if(codepartenaire==='PAMFCI'){
    myText = doc.splitTextToSize("3.	La garantie court sur un (1) an et elle est renouvelable par tacite reconduction jusqu’à la fin de la durée de remboursement du prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 40)	 
    myText = doc.splitTextToSize("4.	La garantie YAKO est effective dès que la demande d’adhésion a été acceptée et au plus tôt à la date de mise en place du prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 45)
    myText = doc.splitTextToSize("5.	Aucune opération d’avance sur police ou rachat partiel ne peut être effectué sur ce contrat.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 50)
    myText = doc.splitTextToSize("6.	En cas de remboursement anticipé du prêt, la garantie YAKO court jusqu’à son terme.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 55)
    myText = doc.splitTextToSize("7.	En cas de rachat, la prime YAKO non acquise du prêt racheté sera déduite de la prime YAKO du nouveau prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 60)
  }else{
    myText = doc.splitTextToSize("3.	Les garanties couvrent toute la durée du prêt et sont effectives dès que la demande d’adhésion a été acceptée et au plus tôt à la date de mise en place du prêt.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 40)
    myText = doc.splitTextToSize("4.	Aucune opération d’avance sur police ou rachat partiel ne peut être effectué sur ce contrat.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 50)
    myText = doc.splitTextToSize("5.	En cas de remboursement anticipé du prêt, la garantie YAKO court jusqu'à son terme.", pageWidth - 20, {})
    doc.text(myText, 30, finalY + 55)
    myText = doc.splitTextToSize("6.	En cas de rachat, la prime YAKO non acquise du prêt racheté sera déduite de la prime YAKO du nouveau prêt.", pageWidth - 20, {})
  }
  
  
  doc.text(myText, 30, finalY + 60)

  doc.setFontSize(9)
  doc.setFont(undefined, 'normal')
  doc.text(10, finalY + 75, 'Fait à ................................. le ' + new Date().toLocaleDateString())
  doc.text(10, finalY + 82, ` YAKO AFRICA                                          ADHERENT (Signature précédée de la mention LU et APPROUVE)`)
  doc.addImage(signature, "PNG", 10, finalY + 87, 40, 20)
}//FIN IF
  
}



  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));

}

//générer fiche de demande d'examen
export const genFicheDemandeExamen = async (data) => {

  let doc = new jsPDF();
  const { branche, partenaire,codepartenaire } = data.user
  let pageSize = doc.internal.pageSize
  let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()

  let img = new Image(35, 23)
  img.src = require('assets/images/logo_yako.jpg')
  const headRows1 = () => ([{ libelle: 'Libelle', valeur: 'Valeur' }])


  doc.addImage(img, "JPEG", 10, 5, 35, 23);
  if (branche !== 'BANKASS' && branche !== 'COURTAGE') {
    let logoEnov = new Image(35, 13)
    logoEnov.src = require('assets/images/logoYnovGreen_b.png')
    doc.addImage(logoEnov, "JPEG", (pageWidth-80), 5, 35, 13);
  } else {
    let logoPart = new Image(25, 10)
    if (codepartenaire === 'AFC') {
      logoPart.src = require('assets/images/AFC.jpg')
    } else if(codepartenaire === '092') {
      logoPart.src = require('assets/images/BNI.jpg')
    }else{
      logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
    }
    doc.addImage(logoPart, "JPEG", 180, 10, 20, 20);
  }

  const exparam = {
    montantPret: parseInt(data.montantpret),
    age: ageAssure(data.adherent.datenaissance),
    duree: data.duree
  }
  let examens = []

  // (parseInt(mesDonnees.duree)%12)<=6 ? parseInt((parseInt(mesDonnees.duree)/12)):parseInt((parseInt(mesDonnees.duree)/12)+1)
  const examenExiger = await getExamenExiger(exparam)

  if (examenExiger && examenExiger.length !== 0) {
    examenExiger.map(exam => {
      examens.push(exam.examens)
    })

  }

  doc.setDrawColor(61,138,65)
  doc.setFillColor(61,138,65)

  doc.rect(15, 30, (pageWidth - 25), 8, 'FD')

  doc.setFontSize(12)
  doc.setTextColor(255, 255, 255)
  doc.setFont(undefined, 'bold')
  doc.text(60, 35, 'FICHE DE DEMANDE D\'EXAMEN MEDICAL');

  doc.setTextColor(0)

  doc.setFont(undefined, 'italic')
  doc.text(45, 45, 'Date demande :');

  doc.setFont(undefined, 'bold')
  doc.text(80, 45, dayjs().format('DD/MM/YYYY'));

  doc.setFontSize(13)
  doc.setFont(undefined, 'bold')
  doc.text(20, 55, "LEMPRUNTEUR/" + dayjs().format('MMYYYY') + data.id);

  doc.autoTable({
    startY: 55,
    head: headRows1(),
    body: [

      ["NOM :", data.adherent?.nom ? data.adherent.nom : ''],
      ["Prénom : ", data.adherent.prenom ? data.adherent.prenom : ''],
      ["Date naissance : ", data.adherent.datenaissance ? dayjs(data.adherent.datenaissance).format('DD/MM/YYYY') : ''],
      ["Adresse : ", data.adherent.adressePostale ? data.adherent.adressePostale : ''],
      ["Téléphone : ", (data.adherent.mobile && data.adherent.mobile )+ (data.adherent.telephone ? ` / ${data.adherent.telephone}` : "")],
      ["Email : ", data.adherent.email ? data.adherent.email : ''],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    margin: { left: 105 },
    tableWidth: 75,
  })
  let finalY = doc.previousAutoTable.finalY
  doc.setLineWidth(0.1);
  doc.setDrawColor(0);
  doc.line(20, finalY + 5, pageWidth - 15, finalY + 5);

  doc.setLineWidth(0.1);
  doc.setDrawColor(0);
  doc.line(20, finalY + 30, pageWidth - 15, finalY + 30);

  doc.setFontSize(13)
  doc.setFont(undefined, 'italic')
  doc.text(25, finalY + 10, "PARTENAIRE BANCAIRE");
  doc.setFontSize(13)
  doc.setFont(undefined, 'italic')
  doc.text(pageWidth / 2, finalY + 10, "Cachet :");


  doc.setFontSize(13)
  doc.setFont(undefined, 'normal')
  doc.text(30, finalY + 20, partenaire);

  doc.setFontSize(13)
  doc.setFont(undefined, 'bold')
  
  doc.text((pageWidth / 2) - 25, finalY + 35, "EXAMENS EXIGES");
  doc.setFont(undefined, 'normal')

  doc.autoTable({
    startY: finalY + 40,
    head: [{ libelle: 'Libelle' }],
    body: [examens],
    showHead: false,
    columnStyles: {
      libelle: {
        textColor: 0,
        fontStyle: 'bold',
        fillColor: [255, 255, 255],
        minCellWidth: pageWidth - 30,
        halign: 'center',
        cellPadding: 10,
        lineWidth: 0.1,
        lineColor: 0,
        cellWidth: 'auto'
      },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: { left: 20 },
  })
  finalY = doc.previousAutoTable.finalY
  if(data['medecin']){
    doc.setFontSize(13)
  doc.setFont(undefined, 'bold')
 
  doc.text((pageWidth / 2) - 25, finalY + 20, "MEDECIN TRAITANT");
  doc.setFont(undefined, 'normal')
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY +25,
    tableLineWidth: 0,
    padding:{
      bottom:10
    },
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle2: 'Libelle2', valeur2: 'Valeur2', libelle3: 'Libelle3', valeur3: 'Valeur3' }],
    body: [
      [`N° OMCI :`,data.medecin['numonmci'] || ""],
      [`Nom : `, `${data.medecin['titre']} ${data.medecin['nom'] || ""} ${data.medecin['prenom']}`],
      ["Contacts : ", `(+225) ${data.medecin['mobile'] || ""} ${data.medecin['mobile2']}`],
      ["Ville : ",  `${data.medecin['ville']}`],
    ],
   
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 10, valign: 'center', cellPadding: 2 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'normal', halign:'right', fillColor: [255, 255, 255], fontSize: 10},
      valeur: { textColor: 0, fontStyle: 'bold',cellPadding:1, halign: 'left', fillColor: [255, 255, 255],fontSize:10,tableWidth: 100},
      valeur2: { textColor: 0, fontStyle: 'bold',fillColor: [255, 255, 255],fontSize:10,tableWidth: 100, valign: 'middle'},
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
      { header: 'Libelle2', dataKey: 'libelle2' },
      { header: 'valeur2', dataKey: 'valeur2' },
    ],
    margin: {
      left: (pageWidth / 3),
      right: 10
    }, 
    createdCell: function(cell, opts) {
       if(cell.row.index==3){
        cell.cell.styles.cellPadding=2;
        cell.cell.styles.fontStyle='bold';
        cell.cell.styles.fontSize=10;
       }
      },
  })
  }
  

  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));



}

export const garantieReduicer = (dataFilter = [], dataMatch = []) => {
  if (dataFilter && dataFilter.length !== 0 && dataMatch && dataMatch.length !== 0) {
    const dataMatcher = dataFilter.filter(item => {
      let result = true
      dataMatch.forEach((element) => {
        if ((item.CodeProduitGarantie === element.CodeProduitGarantie)) {
          result = false
          return
        } else {
          result = true
        }
      })
      return result
    })
    return dataMatch.concat(dataMatcher.filter(item => item.checked === true))
  } else {
    return dataMatch
  }
}


export const generateBulletinSouscription = async (data, credentials) => {
  const headRows1 = () => ([{ libelle: 'Libelle', valeur: 'Valeur' }])

  let doc = new jsPDF();

  let img = new Image(35, 23)
  img.src = require('assets/images/logo_yako.jpg')
  //doc.addImage(img, "JPEG", 15, 10, 45, 15);
  doc.addImage(img, "JPEG", 10, 5, 35, 23);
  
  let signature = new Image(40, 10)
  signature.src = require('assets/images/Signature_Dta.jpg')

  const { branche, partenaire, codepartenaire } = credentials
  if (branche !== 'BANKASS' && branche !== 'COURTAGE') {
    let logoEnov = new Image(25, 10)
    logoEnov.src = require('assets/images/logoYnovGreen_b.png')
    doc.addImage(logoEnov, "JPEG", 180, 10, 35, 13);
  } else {
    let logoPart = new Image(25, 10)
    if (codepartenaire === 'AFC') {
      logoPart.src = require('assets/images/AFC.jpg')
    } else if(codepartenaire === '092') {
      logoPart.src = require('assets/images/BNI.jpg')
    }else{
      logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
    }

    doc.addImage(logoPart, "JPEG", 180, 10, 20, 20);
  }

  let codeRef = data.codeproduit.substring(0, 3)
  if (partenaire && partenaire.trim() !== '') {
    codeRef = partenaire.toUpperCase() + '-' + codeRef
  }

  doc.text(80, 25, 'BULLETIN DE SOUSCRIPTION');
  doc.setLineWidth(0.1);
  doc.line(15, 30, 195, 30);
  doc.setFont(undefined, 'bold')
  doc.setFontSize(14)
  doc.text(80, 35, 'N° : ' + codeRef + '-' + dayjs().format('MMYYYY') + data.id)
  doc.autoTable({
    startY: 38,
    head: [{
      libelle: 'Libelle', valeur: 'Valeur',
      libelle: 'Libelle', valeur: 'Valeur',
      libelle: 'Libelle', valeur: 'Valeur'
    }],
    body: [
      ['Produit :', data.libelleproduit ? data.libelleproduit : "", 'Conseiller :', `${data.codeConseiller ? data.codeConseiller : ""} ${(data.nomagent) ? ('/' + data.nomagent) : ''}`, 'Agence :', credentials.agence ? credentials.agence : ""],
      [],
    ],
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center' },
    showHead: false,
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],

  });


  let finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ADHERENT / SOUSCRIPTEUR' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })


  finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      [`N° ${data.naturepiece ? data.naturepiece : " CNI"} : ${data.numeropiece ? data.numeropiece : ""}`],
      ["Nom : ", data.nom],
      ["Prénom : ", data.prenom],
      ["Né(e) le : ", dayjs(data.datenaissance).format('DD/MM/YYYY')],
      [" A : ", data.lieunaissance],
      ["Lieu de résidence : ", data.lieuresidence],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center' },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 75,
  })
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      ["Profession :", `${data.profession ? data.profession : ""}`],
      ["Secteur d'activité : ", `${data.employeur ? data.employeur : ""}`],
      ["Email : ", `${data.email !== 'null' && data.email}`],
      ["Téléphone : ", `${data.telephone ? data.telephone : ""}`],
      ["Mobile : ", `${data.mobile ? data.mobile : ""} ${data.mobile1 ? "/" + data.mobile1 : ""}`],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    margin: { left: 105 },
    tableWidth: 75,
  })

  finalY = doc.previousAutoTable.finalY

  //Affectation des assurées
  doc.autoTable({
    startY: finalY + 10,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ASSURES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' }
    ],
  })
  finalY = doc.previousAutoTable.finalY


  const headAssure = () => ([
    { nomAssure: 'Nom', filiationAssure: 'Filiation', dateNaissanceAssure: 'Né(e) le', lieuNaissanceAssure: 'Lieu naissance', lieuResidenceAssure: 'Lieu Résidence' }
  ]);
  let body = []
  const bodyAssure = async () => {

    const assures = await selectAssuresContrat(data.id)
    if (assures) {
      assures.map((assure) => {
        body.push({
          nomAssure: assure.nom + ' ' + assure.prenom,
          filiationAssure: assure.filiation,
          dateNaissanceAssure: dayjs(assure.datenaissance).format('DD/MM/YYYY'),
          lieuNaissanceAssure: assure.lieunaissance,
          lieuResidenceAssure: assure.lieuresidence
        })
      })
    }
    return body
  }

  doc.autoTable({
    startY: finalY + 3,
    head: headAssure(),
    body: await bodyAssure(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });
  finalY = doc.previousAutoTable.finalY

  //Affectation des bénéficiaire
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'BENEFICIAIRES / REPRESENTANTS DESIGNES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })

  finalY = doc.previousAutoTable.finalY

  const headBenficaire = () => ([
    { nomBeneficiaire: 'Nom', filiationBeneficiaire: 'Filiation', dateNaissanceBeneficiaire: 'Né(e) le', lieuNaissanceBeneficiaire: 'Lieu naissance', telephoneBeneficiaire: 'Téléphone' }
  ]);
  const bodyBeneficaire = async () => {
    let body = []
    const beneficiaires = await selectBeneficairesContrat(data.id,null)
    if (beneficiaires) {
      beneficiaires.map((beneficiaire) => {
        body.push({
          nomBeneficiaire: beneficiaire.nom + ' ' + beneficiaire.prenom,
          filiationBeneficiaire: beneficiaire.filiation,
          dateNaissanceBeneficiaire: dayjs(beneficiaire.datenaissance).format('DD/MM/YYYY'),
          lieuNaissanceBeneficiaire: beneficiaire.lieunaissance,
          telephoneBeneficiaire: beneficiaire.mobile
        })
      })
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 3,
    head: headBenficaire(),
    body: await bodyBeneficaire(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });

  //Assuré garantie
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'GARANTIES & PRIMES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' }
    ],
  })
  finalY = doc.previousAutoTable.finalY

  const headGaranties = () => ([
    { nomAssure: 'Nom', garantie: 'Garantie', capitalGarantie: 'Capital', primeGarantie: 'Prime', primeAccesoire: 'Accéssoire', totalprime: 'Total prime' }
  ]);
  const bodyGaranties = async () => {
    let body = []
    let garanties = await selectGarantiesContrat(data.id)
    let cumulprime = 0
    let cumulprimeTotal = 0

    if (garanties) {
      garanties.map((garantie,index,dataAll) => {

        if(garantie.codeproduitgarantie === 'PTYS'){
          const garPers =dataAll.filter(item=>item.codeproduitgarantie ==='PERSIT')[0]
          cumulprimeTotal =garPers ? (parseInt(garPers.primetotal) + parseInt(garantie.primetotal)) : parseInt(garantie.primetotal)
          cumulprime =garPers ? (parseInt(garPers.prime) + parseInt(garantie.prime)) : parseInt(garantie.prime)
          
        }else{
          cumulprimeTotal =parseInt(garantie.primetotal)
          cumulprime = parseInt(garantie.prime)
        }
        if(garantie.codeproduitgarantie !== 'PERSIT'){
          body.push({
            nomAssure: garantie.nom + ' ' + garantie.prenom,
            garantie: garantie.monlibelle,
            capitalGarantie: garantie.capitalgarantie ? formatNumber(parseInt(garantie.capitalgarantie)) : '',
            primeGarantie: garantie.prime ? formatNumber(parseInt(cumulprime)) : '',
            primeAccesoire: garantie.primeaccesoire ? formatNumber(garantie.primeaccesoire) : '',
            totalprime: garantie.primetotal ? formatNumber(cumulprimeTotal) : ''
          })
        }
      })
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 3,
    head: headGaranties(),
    body: await bodyGaranties(),
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
    columnStyles: {
      totalprime: { textColor: 0, fontStyle: 'bold', halign: 'right', fontSize: '10' },
    },
  });
  finalY = doc.previousAutoTable.finalY
console.log(data);
  doc.setFontSize(12)
  doc.setFont(undefined, 'bold')
  if(data.fraisadhesion){
    doc.text('FRAIS D\'ADHESION :'  + formatNumber(data.fraisadhesion), 35, finalY + 5)
  }
  doc.text('PRIME TOTALE : ' + formatNumber(data.prime), 135, finalY + 5)


  doc.setLineWidth(0.1);
  doc.line(60, finalY + 8, 150, finalY + 8);

  doc.autoTable({
    startY: finalY + 10,
    head: [{ capitalSous: 'Capital souscrit', dateeffet: 'Date effet', dure: 'Durée', echeance: 'Echéance' }],
    body: [[`${data.capital ? formatNumber(parseInt(data.capital)) : ""}`, `${data.dateeffet ? dayjs(data.dateeffet).format('DD/MM/YYYY') : ""}`, `${data.duree ? data.duree : ""}`, `${(data.duree && data.duree) ? dayjs(data.dateeffet).add(data.duree, 'year').format('DD/MM/YYYY') : ""}`]],
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });
  if(data.codeproduit ==='PVRPRE'){
    //TO DO SIMULATION
  }
  //paiement des PRIMES
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      [{ content: 'PAIEMENT DES PRIMES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle: 'Libelle', valeur: 'Valeur' }],
    body: [
      ['Mode de paiement : ', data.modepaiement, 'Organisme :', data.organisme],
      ['Agence :', data.agence, 'N° Compte', data.numerocompte]
    ],
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', halign: 'left' },
    showHead: false,
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255], cellPadding: 0.5 },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    tableWidth: '100%',
  })


  finalY = doc.previousAutoTable.finalY
  doc.setFontSize(9)
  doc.setFont(undefined, 'bold')
  doc.text(15, finalY + 15, 'Fait à ..................... le ' + new Date().toLocaleDateString())

  doc.text(20, finalY + 25, ` YAKO AFRICA                                                SOUSCRIPTEUR                                                  ${(branche === 'BANKASS') ? partenaire.toUpperCase() : 'L\'ASSURE(E)'}`)

  doc.addImage(signature, "JPEG", 15, finalY + 25, 15, 20)
 
  doc.addPage();

  let cgu = new Image()
  
  if (data.codeproduit === "YKV_2004") {
    cgu.src = require('assets/images/cg_ykv.png').default
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "YKL_2004") {
    cgu.src = require('assets/images/cg_ykl.png').default
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "YKF_2004") {
    cgu.src = require('assets/images/cg_ykf2004.png').default
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "PFA_BNI") {
    cgu.src = require('assets/images/cgu_pfa_bni.png').default
    doc.addImage(cgu, "PNG", 10, 10)
  }

  if (data.codeproduit === "PVRBNI") {
    cgu.src = require('assets/images/cg_pvr.png').default
    cgu.width = 400
    doc.addImage(cgu, "PNG", 0, 0, 220, 330)
  }
  if(data.codeproduit === 'PVRPRE'){
    doc.setFontSize(7)
    let pageHeight = doc.internal.pageSize.getHeight();
    let pageWidth = doc.internal.pageSize.getWidth();
     var htmlElement =  `<div id="biapremium" style="margin:0px;padding:0px;width:${pageWidth}px;">
     <div style='text-align:center;margin:0px;padding:0px;'>
      <span style="margin:0px;padding:0px;text-decoration:underline;text-decoration-thickness:1px;text-align:center;color:black;font-weight:bold;font-size:5px;">EXTRAIT DES CONDITIONS GENERALES PLAN VERT RETRAITE PREMIUM</span>
     </div><div style="margin:0; padding:0;display:flex;flex-direction:row;width:100%">
         <div style="margin:0px;padding:0px;width:50%;margin-right:5px">
           <span style="margin:0px;padding:0px;text-align:center;color:black;font-weight:bold;font-size:4px;">OBJET DE L’ASSURANCE</span>
           <p style="margin:0px;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;"><strong>Plan Vert Retraite PREMIUM BNI</strong> est une convention
              d’assurance groupe souscrite par la BNI en faveur des
              personnes physiques titulaires de comptes chèques
              ouverts dans ses livres et des personnes morales titulaires
              de compte chèques ouverts dans ses livres, pour le
              compte de leurs salariés.
              Il a pour objet la constitution d’un capital à terme en vue
              de :<br /> <span style="margin-left:10px"> - Préparer sa retraite pendant la vie professionnelle;</span><br />
              <span style="margin-left:10px"> -Disposer d’un complément de revenus une fois à
              la retraite, versé sous forme de capital ou de
              rente;</span><br /><span style="margin-left:10px"> -Protéger financièrement sa famille en cas de décès<br />
              de l’assuré avant le terme du contrat</span>.
              </p><span style="text-align:center;color:black;font-weight:bold;font-size:4px;">QUALITE DE L’ASSURE</span>
              <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              Toute personne physique, titulaire d’un compte chèque à
              la BNI ou dont l’entreprise dispose d’un compte à la BNI
              dans le cadre d’un contrat collectif, âgée de <strong>DIX-HUIT
              (18) à CINQUANTE CINQ (55) ANS</strong> au moment de la
              souscription.</p>
              <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">NATURE DES GARANTIES</span>
              <p style="margin:0;padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              <strong>Plan Vert Retraite PREMIUM BNI</strong> offre deux (2)
              garanties dénommées : <strong>EPARGNE PLUS</strong> et
              <strong>PROTECTYS</strong>.<br />
              <span style="margin-left:10px;">
              <strong>&#8226; Garantie « EPARGNE PLUS</strong></span><br />
              Elle permet la constitution progressive d’un capital payé
              aux bénéficiaires en cas de survie de l’Assuré au terme du
              contrat.
              En cas de décès ou d’invalidité totale et définitive de
              l’Assuré avant le terme, il est payé la valeur du contrat à
              la date du décès ou de l’invalidité absolue et définitive,
              au(x) bénéficiaire(s).<br />
              <span style="margin-left:10px;"><strong>&#8226; Garantie PROTECTYS</strong></span><br />
              En cas de décès ou d’invalidité absolue et définitive six
              (6) mois fermes après la date de prise d’effet du contrat,
              sauf s’il est de nature accidentelle, elle engage <strong></strong>YAKO
              AFRICA</strong> à payer une prestation égale au capital décès
              souscrit.
              </p><span style="text-align:center;color:black;font-weight:bold;font-size:4px;">PRISE D’EFFET ET DUREE</span>
              <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              Le contrat prend effet le premier jour du mois suivant la
              date de réception au siège de <strong>YAKO AFRICA </strong>du
              paiement de la première prime.
              La durée minimale est de 5 ans.</p>
              <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">RACHAT PARTIEL / RACHAT TOTAL</span>
              <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              <strong>Plan Vert Retraite PREMIUM BNI</strong> peut être racheté totalement ou
              partiellement lorsqu’au moins 15% des primes prévues au contrat ou
              deux primes annuelles ont été effectivement versées.
              La valeur de rachat est égale à l’épargne constituée minorée d’une
              pénalité de 5% si le rachat intervient dans les DIX (10) premières
              années du contrat.
              L’adhérent peut faire un seul rachat partiel par année.
              Le rachat total de Plan Vert Retraite PREMIUM BNI entraine la
              cessation de toutes les garanties et la résiliation du contrat.
            </p>
         </div>
         <div style="width:50%;margin:0px;padding:0px">
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">PRÊT PERSONNEL ZO’</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            A la demande de l’adhérent, si celui-ci remplit toutes les conditions,
            la <strong>BNI</strong> s’engage à lui accorder un prêt dit <strong>« Prêt personnel ZO’ »</strong>,
            garanti par le nantissement d’une partie de l’épargne constituée.
            Ces conditions sont les suivantes :<br />
            <span style="margin-left:10px">- L’adhérent doit être à jour de ses cotisations ;</span><br />
            <span style="margin-left:10px">- L’adhérent doit avoir effectivement cotisé un
            montant équivalent à au moins <strong>UN (1) AN de
            prime</strong> ;</span><br />
            <span style="margin-left:10px">- L’adhérent ne doit avoir aucun encours rattaché
            au contrat concerné.</span><br />
            Le montant du prêt octroyé par la <strong>BNI</strong> est limité à <strong>75,00%</strong> de la
            valeur du contrat.
            L’intervalle de temps entre les demandes de prêt pour un contrat
            donné est de <strong>12 mois</strong>.
            </p>
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">BONUS PERSISTANCE</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">Il permet de bonifier l’épargne de l’adhérent d’un montant équivalent
à 4% du capital décès <strong>PROTECTYS souscrit.
Pour en bénéficier, l’adhérent, ne devra effectuer aucune opération
(hormis le prêt personnel ZO’) sur son contrat jusqu’au dixième
anniversaire de celui-ci.</p>
<span style="text-align:center;color:black;font-weight:bold;font-size:4px;">PIECES A FOURNIR</span>
<p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
<strong>Au terme du contrat</strong><br />
<span style="margin-left:10px">
1-Un formulaire de demande de prestation disponible sur <strong>«
Ynov (fonctionnalité e. Prestations »</strong> dûment renseigné ;</span>
<span style="margin-left:10px">2-L’original de la police d’assurance et de ses avenants, à défaut
une déclaration de perte délivrée par un commissariat de
police ;</span>
<span style="margin-left:10px">3-Une copie de la carte nationale d’identité, de la carte
consulaire ou de la carte de séjour de l’Assuré, à défaut la
copie de l’acte de naissance ou du jugement supplétif de
l’assuré ;</span><br/>
<strong>En cas de décès toutes causes</strong><br />
<span style="margin-left:10px">1-Un formulaire de déclaration de sinistre disponible sur <strong>«
Ynov (fonctionnalité e. Prestations »</strong> dûment renseigné ;
  <span style="margin-left:10px">2-L’original de la police d’assurance et de ses avenants, à défaut
une déclaration de perte délivrée par un commissariat de
police ;</span>
<span style="margin-left:10px">3-Une copie de la carte nationale d’identité, de la carte
consulaire ou de la carte de séjour de l’Assuré, à défaut la
copie de l’acte de naissance ou du jugement supplétif de
l’assuré ;</span>
<span style="margin-left:10px">4-Les pièces d’identification du bénéficiaire consistant en l’un
des documents suivants :
(Copie de la carte nationale d’identité ; Copie de la carte
consulaire ; Acte de naissance, jugement supplétif.) ;</span>
<span style="margin-left:10px">5-Un acte de décès de l’Adhérent ;</span>
<span style="margin-left:10px">6-Un certificat de décès de l’Adhérent.</span>
<br />
<strong>En cas d’invalidité absolue et définitive</strong><br />
<span style="margin-left:10px">1-Un formulaire de demande de prestation disponible sur «
Y.nov (fonctionnalité e. Prestations » dûment renseigné ;</span>
  <span style="margin-left:10px">2-L’original de la police d’assurance et de ses avenants, à défaut
une déclaration de perte délivrée par un commissariat de
police ;</span>
<span style="margin-left:10px">3-Une copie de la carte nationale d’identité, de la carte
consulaire ou de la carte de séjour de l’Assuré, à défaut la
copie de l’acte de naissance ou du jugement supplétif de
l’assuré ;</span>
<span style="margin-left:10px">4-Un certificat médical attestant l’état d’invalidité ;</span>
<span style="margin-left:10px">5-Un rapport médical complet du médecin traitant.</span>
</p>
         </div>
       
     </div>
   </div>`;

   
      const opt = {
        callback: function (bia) {
          //bia.addPage('a4','p')
          bia.setFontSize(7)
          return bia;
        },
        margin: [5, 5, 5, 5],
        html2canvas: {
          allowTaint: true,
          dpi: 300,
          letterRendering: true,
          logging: false,
          scale: .9
      },
      width:pageWidth,
      y: (pageHeight-20),
      autoPaging: 'text'
    };
    await doc.html(htmlElement, opt);
  }
  if(data.codeproduit === 'YKE_2018'){
    doc.setFontSize(7)
    let pageHeight = doc.internal.pageSize.getHeight();
    let pageWidth = doc.internal.pageSize.getWidth();
     var htmlElement =  `<div id="biapremium" style="margin:0px;padding:0px;width:${pageWidth}px;">
     <div style='display:flex;flex-direction:row;justify-content:center;align-items:center; text-align:center;margin:0px;padding:0px; border:1px solid black;border-radius:5px;'>
      <span style="margin:0px;padding:0px;text-align:center;color:black;font-weight:bold;font-size:5px;">RESUME DES CONDITIONS GENERALES YAKO ETERNITE</span>
     </div><div style="margin:0; padding:0;display:flex;flex-direction:row;width:100%">
         <div style="margin:0px;padding:0px;width:50%;margin-right:5px">
           <span style="margin:0px;padding:0px;text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">OBJET DU CONTRAT - NATURE DES GARANTIES</span>
           <p style="margin:0px;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;"><br />
              Le Contrat d’Assurance YAKO ETERNITE se compose de deux garanties de base <strong>HOMMAGE</strong> et <strong>SURETE</strong> et d’une garantie accessoire <strong>SENIOR</strong> 
              <br /><span style="text-decoration:underline;"><strong>Garantie HOMMAGE</strong></span><br />
La garantie <strong>HOMMAGE</strong> donne droit à une prestation de montant égal à la provision mathématique.</p>
<p style="margin:0px;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
<br /><span style="text-decoration:underline;"><strong>Garantie SURETE</strong></span><br />
En cas de décès de l’assuré avant le 1er  anniversaire du contrat (à partir de la date d’effet) sauf s’il est de nature accidentelle, il est servi une prestation en numéraire égale au cumul des primes payées diminué du coût de police au titre de la garantie HOMMAGE en remplacement de la provision mathématique.
</p>
<p style="margin:0px;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;"><br />Si le décès de l’assuré est de nature accidentelle ou intervient après le 1er anniversaire du contrat (12 mois fermes après la date de prise d’effet, la garantie SURETE  donne droit à une prestation supplémentaire à la provision mathématique de la garantie HOMMAGE.</p>
<p style="margin:0px;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;"><br />
Elle est égale au cumul des primes au titre de la garantie HOMMAGE restant à échoir entre la date de décès et le 5ème anniversaire de la souscription.
</p>
<p style="margin:0px;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
<br /><span style="text-decoration:underline;"><strong>Garantie SENIOR</strong></span><br />
La garantie accessoire SENIOR, au choix du souscripteur permet de couvrir un second assuré. 
Elle engage l’assureur, en cas de décès au paiement d’une prestation égale à la provision mathématique en couverture de la garantie.
           </p>
           <span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline">EXCLUSIONS A LA GARANTIE SURETE</span>
              <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              Si le décès de l’assuré intervient dans les cinq(5) premières années à la suite de l’un des évènements ci-dessous identifiés, l’engagement de l’assureur se limitera au paiement de la provision mathématique.<br />
<span style="margin-left:10px">-suicide conscient et volontaire de l’assuré avant deux années effectives d’assurances ;</span><br />
<span style="margin-left:10px">-participation à une émeute ou une rixe ;</span><br />
<span style="margin-left:10px">-acte intentionnel du (des) bénéficiaires ;</span><br />
<span style="margin-left:10px">-conséquences d’épidémies et autres catastrophes reconnues comme telles par les autorités ;</span><br />
<span style="margin-left:10px">-en cas de guerre civile ou étrangère, les conditions de couverture seront déterminées par la législation en vigueur ;</span><br />
<span style="margin-left:10px">- conséquences de la pratique des sports et jeux dangereux ainsi que les sauts en parachute non justifiés par une situation critique de l’appareil.</span><br />

            </p>
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline">PROVISION MATHEMATIQUE</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            La provision mathématique en couverture des garanties <strong>HOMMAGE</strong> et <strong>SENIOR</strong> est égale au cumul des primes nettes versées capitalisées au taux d’intérêt garanti de 3,5% l’an et, augmenté des participations aux bénéfices  financiers et techniques.
            <br />Au titre de la garantie HOMMAGE :
            <span style="margin-left:10px">-	La prime nette sur la 1ère année s’élève à <strong>53,00%</strong> de la prime brute hors accessoires et taxes</span>
            <span style="margin-left:10px">-	A partir de la 2ème année, la prime nette est égale à <strong>92,00%</strong> de la prime brute hors accessoires et taxes</span><br />
La prime nette de la garantie SENIOR  est égale à <strong>94,50%</strong> de la prime brute hors frais accessoires et taxes.
            </p>
         </div>
         <div style="width:50%;margin:0px;padding:0px">
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">QUALITE DE L’ASSURE</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            Les garanties HOMMAGE et SURETE sont souscrites sur la tête d’une personne âgée d’au moins <strong>12 ans</strong> et d’au plus <strong>74 ans</strong> révolus à la date d’effet du contrat. L’Assuré au titre de la garantie accessoire SENIOR doit être âgé d’au moins <strong>75 ans</strong> révolus à la date d’effet du contrat.
            </p>

            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">PRISE D’EFFET ET DUREE</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              Le contrat prend effet le premier jour du mois suivant la date de paiement de la première prime. Il est établi pour une durée viagère
            </p>
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">RENONCIATION</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            Comme l’indique l’article 65 du code CIMA, le souscripteur peut renoncer au contrat dans le délai de 30 jours, à compter de la date de paiement de la 1ère prime .La renonciation entraine la restitution des primes payées, déduction faite du coût de police.
            </p>
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">RACHAT TOTAL</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            Le souscripteur peut mettre fin au contrat YAKO ETERNITE à tout moment.
            </p>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            Si la résiliation intervient avant le paiement effectif d’une prime annuelle ou de 15,00% de l’ensemble des primes prévues au contrat, la valeur de rachat est égale à la provision mathématique de la garantie SENIOR.
            </p>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            En cas de paiement effectif de 15,00% de l’ensemble des primes prévues au contrat ou une (1) prime annuelle ,la valeur brute de rachat est égale à la somme des montants ci-après :<br />
            <span style="margin-left:10px">-la provision mathématique de la garantie SENIOR</span><br/>
            <span style="margin-left:10px">-la provision mathématique de la garantie HOMMAGE diminuée d’une pénalité  de 5,00% .La pénalité est nulle si la demande de résiliation intervient après la  5ème année d’assurance.</span><br/>

            </p>
         </div>
       
     </div>
   </div>`;

   
      const opt = {
        callback: function (bia) {
          //bia.addPage('a4','p')
          bia.setFontSize(7)
          return bia;
        },
        margin: [5, 5, 5, 5],
        html2canvas: {
          allowTaint: true,
          dpi: 300,
          letterRendering: true,
          logging: false,
          scale: .9
      },
      width:pageWidth,
      y: (pageHeight-10),
      autoPaging: 'text'
    };
    await doc.html(htmlElement, opt);
  }
  var blob = doc.output("blob");
  window.open(URL.createObjectURL(blob));

}

export const verifRule = (mod, rule, privileges) => {
  let value = false
  if (privileges && privileges.length !== 0) {
    const ind = privileges.findIndex(item => (item.codemodule === mod && item.codeprivilege === rule && item.estactif == 1));
    return (ind === -1) ? false : true
  } else {
    getPrivilegesModule(mod).then(res => {
      if (res && res.length !== 0) {
        const ind = res.findIndex(item => (item.codemodule === mod && item.codeprivilege === rule && item.estactif == 1));
        return ((ind === -1) ? false : true)
      } else {
        return false;
      }

    }).catch(err => {
      console.log(err);
      return false
    })
  }
}

export const evaluationPret = async (ageAssure, capital, duree, partenaire,periodicite) => {

  if (ageAssure && capital && duree && partenaire) {
    const x = (ageAssure * 12);
    const n = parseInt(duree);
    let txg
    let txa
    //taux de gestion 
    const datg = await getTauxPartenaire("g", partenaire)
    if (datg) txg = (datg.taux / 100)


    //taux acquisition
    const datac = await getTauxPartenaire("a", partenaire)
    if (datac) txa = (datac.taux / 100)

    //table de mortalité assuré
    let mx, mxt, dx, nx, nxt, pup, tx_actualisation_fg, frais_de_gestion
    const tbm = await getTableMortaliteAssure(x)

    if (tbm) {
      mx = Number(tbm.mx)
      dx = Number(tbm.dx)
      nx = Number(tbm.nx)
    }
    //table de mortalité au terme
    const tbmt = await getTableMortaliteAssure(x + n)

    if (tbm) {
      mxt = Number(tbmt.mx)
      nxt = Number(tbmt.nx)
    }

    if (txg && txa && mx && dx && nx && mxt && nxt) {
      //calcul  de prime unique pure
      pup = capital * (mx - mxt) / dx;

      //calcul frais de gestion
      tx_actualisation_fg = (nx - nxt) / dx
      frais_de_gestion = Number((txg / 12) * tx_actualisation_fg * capital)
     

      //calcul de la prime unique
      const puc = Math.round((pup + frais_de_gestion) / (1 - txa))
      let pucreel = puc
      switch (periodicite) {
        case 'Mensuelle':
           pucreel = (puc/(0.96*duree))
          break;
        case 'Trimestrielle':
            pucreel = (puc/(0.96*duree))*3
          break;
        case 'Semestrielle':
            pucreel = (puc/(0.96*duree))*6
          break;
        case 'Annuelle':
            pucreel = (puc/(0.96*duree))*12
          break;
        default:
            pucreel = puc
          break;
      }
      return pucreel;
    } else {
      return null
    }
  } else {
    return null
  }
}
export const Cgu = () => {
  return (
    <table class="table-cg">
      <tr><td><img src={require("assets/images/cgu_ykl1.png")} /></td></tr>
      <tr><td><img src={require("assets/images/cgu_ykl1.png")} /></td></tr>
    </table>
  )
}

export const handleMoimemeAssure = (data) => {
  let mesdonne = {}
  if (data) {
    mesdonne.nomAssure = data.nomSous && data.nomSous
    mesdonne.prenomAssure = data.prenomSous && data.prenomSous
    mesdonne.dateNaissanceAssure = data.dateNaissanceSous && dayjs(data.dateNaissanceSous).format('YYYY-MM-DD')
    mesdonne.lieuNaissanceAssure = data.lieuNaissanceSous && data.lieuNaissanceSous
    mesdonne.numeroCniAssure = data.pieceIdentiteSous && data.pieceIdentiteSous
    mesdonne.lieuResidenceAssure = data.lieuResidenceSous && data.lieuResidenceSous
    mesdonne.filiationAssure = 'Moi même'
    mesdonne.emailAssure = data.emailSous && data.emailSous
    mesdonne.telephoneAssure = data.mobileSous && data.mobileSous
    mesdonne.monIndex = 1
    return mesdonne;
  } else {
    return null
  }
}

export const objectsArrayAreEqual = (a, b) => {
  for (var prop in a) {
    if (a.hasOwnProperty(prop)) {
      if (b.hasOwnProperty(prop)) {
        if (typeof a[prop] === 'object') {
          if (!objectsArrayAreEqual(a[prop], b[prop])) return false;
        } else {
          if (a[prop] !== b[prop]) return false;
        }
      } else {
        return false;
      }
    }
  }
  return true;
}

export const compare = (a, b) => {
  if (a.last_nom < b.last_nom) {
    return -1;
  }
  if (a.last_nom > b.last_nom) {
    return 1;
  }
  return 0;
}


//bulletin de commission
export const bulletinCommission =(data,user,per)=>{
    if(data){
      const {ficheAgent, commisInter, details} = data
      const { branche, partenaire ,codepartenaire} = user
      let doc = new jsPDF('p','px');
      let pageSize = doc.internal.pageSize
      let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    
      let img = new Image(60, 20)
      img.src = require('assets/images/logo_yako.jpg')
      doc.addImage(img, "JPEG",200, 20, 60, 20);
      doc.setFontSize(7)


     let totalCumulCom =0
     let totalRetenur =0
     let totalImposable =0
     let totalGain = 0
     let taux = 7.5
     let totalAllGain =0
      
    let source =<div style={{width:'100%',padding:0}}>
        <table style={{textAlign:'center', width:(pageWidth-20),padding:0}}>
          <tr style={{textAlign:'center'}}>
            <td style={{fontSize:14,width:'85%',textAlign:'center'}}>BULLETIN &nbsp; DE COMMISSION</td>

            <td style={{fontSize:4,textAlign:'right'}}>{dayjs().format('DD/MM/YYYY')}</td>
          </tr>
        </table>

        <div ></div>
        <table  style={{lineHeight:1.5,borderCollapse:'collapse', width:(pageWidth-20), fontSize:5,padding:0}}>
          <tr><td colSpan={3} style={{border:'ridge #ccc',borderWidth:0.1, width:'100%'}}>INTERMEDIAIRE</td></tr>
          <tr>
            <td><span style={{fontWeight:'bold'}}>Code : </span>{ficheAgent[0].CodeIntermediaire}</td>
            <td><span style={{fontWeight:'bold'}}>Nom et Prénom : </span>{ficheAgent[0].NomAgent}</td>
            <td><span style={{fontWeight:'bold'}}>Fonction : </span>{ficheAgent[0].fonctionAgent}</td>
          </tr>
          <tr>
            <td><span style={{fontWeight:'bold'}}>Réseau : </span>{ficheAgent[0].NomInspection}</td>
            <td><span style={{fontWeight:'bold'}}>Equipe : </span>{ficheAgent[0].NomEquipe}</td>
            <td><span style={{fontWeight:'bold'}}>Famille : </span>{ficheAgent[0].familleReseau}</td>
          </tr>
        </table>
        <div style={{width:(pageWidth-20),textAlign:'right',padding:0 }}>
          <span style={{fontWeight:'bold',fontSize:4}}>Période commission : </span>
          <span style={{textAlign:'right',fontSize:4}}>{per}</span>
        </div>

        <table style={{lineHeight:1.5,borderCollapse:'collapse',fontSize:5, width:(pageWidth-20)}}>
          <tr><td colSpan={4} style={{border:'ridge #ccc',borderWidth:0.1, width:'100%'}}>DETAILS RUBRIQUES</td></tr>
          <tr style={{borderBottom:'solid #ccc', borderWidth:0.1}}>
            <th style={{textAlign:'center',fontStyle:'italic'}}>Code</th>
            <th style={{textAlign:'center',fontStyle:'italic'}}>Libellé rubrique commission</th>
            <th style={{textAlign:'center',fontStyle:'italic'}}>Gain</th>
            <th style={{textAlign:'center',fontStyle:'italic'}}>Retenu</th>
          </tr>
          {commisInter&& commisInter.length!==0&&(
            commisInter.map(row=>{
              totalCumulCom += Number(row['CumulCommission'])
              totalRetenur += Number(row['CumulRetenue'])
              if(['COMPROD','COMCHEF','COMINSP'].includes(row['TypeOp'])){
                totalImposable += Number(row['CumulCommission'])
              }else{
                totalGain += Number(row['CumulCommission'])
              }
              return (
              <tr>
                <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'left',padding:0}}>{row['TypeOp']||""}</td>
                <td style={{borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'left',padding:0}}>{row['MonLibelle']||""}</td>
                <td style={{borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right'}}>{formatNumber(Math.round(Number(row['CumulCommission'])))} FCFA</td>
                <td style={{borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right'}}>{formatNumber(Math.round(Number(row['CumulRetenue'])))} FCFA</td>
              </tr>)})
          )}
          <tr>
            <td colSpan={2}></td>
            <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right',padding:0,fontWeight:'bold'}}>{formatNumber(Math.round(totalCumulCom))} FCFA</td> 
            <td style={{borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right',padding:0,fontWeight:'bold'}}>{formatNumber(Math.round(totalRetenur))} FCFA</td>
          </tr>

          <tr>
            <td colSpan={2}></td>
            <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right',padding:0}}>Taux Impôt</td> 
            <td style={{borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right',padding:0}}>{taux}</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>Base imposable</td> 
            <td style={{borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>{formatNumber(Math.round(totalImposable))} FCFA</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>Montant impôt</td> 
            <td style={{borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>{formatNumber(Math.round((totalImposable*taux)/100))} FCFA</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>Solde net d'impôt</td> 
            <td style={{borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>{formatNumber(Math.round((totalImposable- (Math.round((totalImposable*taux )/100)))))} FCFA</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>Total Gain</td> 
            <td style={{borderBottom:'dashed #ccc', borderWidth:0.1,textAlign:'right',padding:0}}>{formatNumber(Math.round(totalGain))} FCFA</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td style={{borderLeft:'dashed #ccc',borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right',padding:0}}>Total Rétenu</td> 
            <td style={{borderBottom:'dashed #ccc', borderWidth:0.1, textAlign:'right',padding:0}}>{formatNumber(Math.round(totalRetenur))} FCFA</td>
          </tr>
          <tr>
            <td colSpan={2}></td>
            <td style={{fontSize:7,fontWeight:'bold',textAlign:'right'}}>Net à payer</td> 
            <td style={{fontSize:7,fontWeight:'bold',textAlign:'right'}}>{formatNumber(((Math.round(totalImposable-(Math.round((totalImposable*taux )/100))))+totalGain-totalRetenur))} FCFA</td>
          </tr>
          </table>
          
          <table style={{lineHeight:1.5,borderCollapse:'collapse', fontSize:6, width:(pageWidth-10)}}>
            <tr><td colSpan={13} style={{border:'ridge #ccc',borderWidth:0.1, width:'100%'}}>DETAILS</td></tr>
            <tr style={{borderBottom:'solid #ccc', borderWidth:0.1}}>
              <th style={{textAlign:'center',fontSize:5}}>Code</th>
              <th style={{textAlign:'center',fontSize:5}}>Payeur de prime</th>
              <th style={{textAlign:'center',fontSize:5}}>N° propo.</th>
              <th style={{textAlign:'center',fontSize:5}}>Produit</th>
              <th style={{textAlign:'center',fontSize:5}}>Effet</th>
              <th style={{textAlign:'center',fontSize:5}}>Prime</th>
              <th style={{textAlign:'center',fontSize:5}}>Base</th>
              <th style={{textAlign:'center',fontSize:5}}>Per.</th>
              <th style={{textAlign:'center',fontSize:5}}>Enc.</th>
              <th style={{textAlign:'center',fontSize:5}}>D.règlt</th>
              <th style={{textAlign:'center',fontSize:5}}>Mode</th>
              <th style={{textAlign:'center',fontSize:5}}>Tx</th>
              <th style={{textAlign:'center',fontSize:5}}>Gain</th>
            </tr>
              {(details && details.length!==0)&&(details.map(detail=>{
              totalAllGain +=Number(detail['MontantIn'])
              return (<tr>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['IdPresentation']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['Souscripteur']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['IdProposition']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['CodeProduit']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{dayjs(detail['DateEffetReel']).format('DD/MM/YYYY')}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{formatNumber(Math.round(Number(detail['MontantNet'])))}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{formatNumber(Math.round(Number(detail['PartCom'])))}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['CodePerodicite']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['RangEnc']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['RegltDate']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['CodePaiement']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{detail['Taux']}</td>
                <td  style={{textAlign:'center',fontSize:5}}>{formatNumber(Math.round(Number(detail['MontantIn'])))}</td>
              </tr>)}))}
              <tr style={{borderTop:'solid #ccc', borderWidth:0.1}}>
                <td colSpan={12}></td>
                <td style={{textAlign:'center',fontSize:7,fontWeight:'bold'}}>{formatNumber(Math.round(totalAllGain))} FCFA</td>
              </tr>
          </table>
      </div>

      let elementAsString = renderToString(source);
      doc.html(elementAsString,{callback:function(doc) {
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
      },x:10,y:40})
       

      //var blob = doc.output("blob");
     // window.open(URL.createObjectURL(blob));
    }
}



export const ficheOperation =(data,user)=>{
  let signature = new Image(20, 5)
  signature.src = require('assets/images/Signature_Dta.jpg')
  if(data){
    const { branche, partenaire ,codepartenaire} = user
    let doc = new jsPDF('l','px','a6');
    let pageSize = doc.internal.pageSize
    let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
    let pageHeight =  pageSize.height ? pageSize.height : pageSize.getHeight()
    let img = new Image(40, 10)
    img.src = require('assets/images/logo_yako.jpg')
    //doc.addImage(img, "JPEG", 10, 10, 50, 25);
    doc.addImage(img, "JPEG", 10, 5, 35, 15);
    if (branche !== 'BANKASS') {
      let logoEnov = new Image(25, 10)
      logoEnov.src = require('assets/images/logoYnovGreensmall.png')
      doc.addImage(logoEnov, "JPEG", pageWidth-50, 10, 25, 25);
    } else {
      let logoPart = new Image(25, 10)
      if (codepartenaire === 'AFC') {
        logoPart.src = require('assets/images/AFC.jpg')
      } else if(codepartenaire === '092') {
        logoPart.src = require('assets/images/BNI.jpg')
      }else{
        logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
      }
  
      doc.addImage(logoPart, "JPEG", pageWidth-30, 5, 25, 25);
    }


    doc.setFontSize(7)
  let source =<div style={{width:'100%',padding:0}}>
      <table style={{textAlign:'center', width:(pageWidth-20),padding:0}}>
        
        <tr>
          <td style={{width:'80%',textAlign:'center'}} valign="top">
           <span style={{fontSize:10,textAlign:'center',margin:0}}> ACCUSE &nbsp; DE  &nbsp; RECEPTION </span> <br />
           <span style={{fontSize:8,textAlign:'center',margin:0}}>{(data.groupeoperation==='SIN'?"DECLARATION DE SINISTRE":(data.groupeoperation==='PRES')?"DEMANDE DE PRESTATION":"DEMANDE DE MODIFICATION")}</span><br />
           <span style={{fontSize:8,textAlign:'center',margin:0}}>N° : {data.codeoperation} / {data.id}</span>
          </td>

          <td valign='top' style={{fontSize:5,textAlign:'right',paddingTop:10}}>
            {user&&`${user['nom']} ${user['prenom']}`}<br />
            {dayjs().format('DD/MM/YYYY HH:MM')}
          </td>
        </tr>
      </table>
      <table  style={{lineHeight:1.5,borderCollapse:'collapse', width:(pageWidth-20), fontSize:5,padding:0}}>
        <tr><td colSpan={3} style={{border:'ridge #ccc',borderWidth:0.1, width:'100%',textAlign:'left'}}>CONTRAT</td></tr>
        <tr>
          <td><span style={{fontWeight:'bold'}}>ID : </span>{data.idproposition}</td>
        </tr>
        <tr>
          <td><span style={{fontWeight:'bold'}}>Adhérent : </span>{data.adherent}</td>
        </tr>
        <tr>
          <td><span style={{fontWeight:'bold'}}>Date d'effet : </span>{dayjs(data.dateeffet).format('DD/MM/YYYY')}</td>
        </tr>
        <tr>
          <td><span style={{fontWeight:'bold'}}>Produit : </span>{data.libelleproduit} / {data.libelleproduitformule}</td>
        </tr>
      </table>

      <table  style={{lineHeight:1.5,borderCollapse:'collapse', width:(pageWidth-20), fontSize:5,padding:0}}>
        <tr><td colSpan={4} style={{border:'ridge #ccc',borderWidth:0.1, width:'100%'}}>OPERATION</td></tr>
        <tr style={{borderBottom:'solid #ccc', borderWidth:0.1}}>
          <td colSpan={3}><span style={{fontWeight:'bold',fontSize:11}}>{data.libelletypeoperation}</span></td>
        </tr>
        <tr>
          <td><span style={{fontWeight:'bold'}}>Date opération : </span>{dayjs(data.dateoperation).format('DD/MM/YYYY')}</td> 
          <td><span style={{fontWeight:'bold'}}>Délai de traitement : </span>{data.delaitraitement} jour(s) ouvré(s)</td>
          <td><span style={{fontWeight:'bold'}}>Date fin prévu : </span>--{/*data.datefinprevu&&dayjs(data.datefinprevu).format('DD/MM/YYYY')*/}</td>
        </tr>
        <tr>
          <td><span style={{fontWeight:'bold'}}>Partenaire : </span>{data.partenaire}</td> 
          <td><span style={{fontWeight:'bold'}}>Agence: </span>{data.nomagence}</td>
          <td><span style={{fontWeight:'bold'}}>Opérateur : </span>{data.utilisateur}</td>
        </tr>
      </table>
      <table style={{lineHeight:1.5,borderCollapse:'collapse', width:(pageWidth-20),fontSize:5,padding:0,marginRight:20,marginTop:10}}>
          <tr>
            <td style={{width:'60%',textAlign:'left'}}>
              <span style={{fontWeight:'bold'}}>
                YAKO AFRICA ASSURANCES VIE <br />
              </span>
            </td>
            <td style={{width:'40%',textAlign:'center'}}>
              <span style={{fontWeight:'bold'}}>
                Signature/Cachet <br />
                {data.partenaire}
              </span>
            </td>
          </tr>
      </table>
     
    </div>
    
    doc.addImage(signature, "PNG", 20, (pageHeight-30), 40, 20)
    let elementAsString = renderToString(source);
    doc.html(elementAsString,{callback:function(doc) {
      var blob = doc.output("blob");
      window.open(URL.createObjectURL(blob));
    },x:10,y:25})
     

    //var blob = doc.output("blob");
   // window.open(URL.createObjectURL(blob));
  }
}

export const transformePeriode=(per)=>{
  let debut, fin
  let last
  switch (per) {
    case 'day':
        debut= dayjs().format('YYYY-MM-DD')
        fin = debut
      break;
    case 'yesterday':
        debut=dayjs().add(-1, 'day').format('YYYY-MM-DD')
        fin = dayjs().add(-1,'day').format('YYYY-MM-DD')
      break;
    case 'last7week':
        debut= dayjs().add(-7,'day').format('YYYY-MM-DD')
        fin = dayjs().format('YYYY-MM-DD')
      break;
    case 'currentweek':
        debut= dayjs().startOf('week').format('YYYY-MM-DD')
        fin = dayjs().endOf('week').format('YYYY-MM-DD')
      break;
    case 'lastweek':
        last = dayjs().startOf('week').add(-1,'day').format('YYYY-MM-DD')
        debut=  dayjs(last).startOf('week').format('YYYY-MM-DD')
        fin = last
      break;
    case 'last30days':
        debut= dayjs().add(-30,'day').format('YYYY-MM-DD')
        fin = dayjs().format('YYYY-MM-DD')
      break;
    case 'currentmonth':
        debut= dayjs().startOf('month').format('YYYY-MM-DD')
        fin = dayjs().endOf('month').format('YYYY-MM-DD')
      break;
    case 'lastmonth':
        last  =dayjs().endOf('month').add(-1,'day').format('YYYY-MM-DD')
        debut= dayjs(last).startOf('month').format('YYYY-MM-DD')
        fin = last
      break;
    case 'currentyear':
        debut= dayjs().startOf('year').format('YYYY-MM-DD')
        fin = dayjs().endOf('year').format('YYYY-MM-DD')
      break;
    case 'lastyear':
        last = dayjs().startOf('year').add(-1,'day').format('YYYY-MM-DD')
        debut=  dayjs(last).startOf('year').format('YYYY-MM-DD')
        fin = last
      break;
    default:
        debut= dayjs().format('YYYY-MM-DD')
        fin = debut
      break;
  }
  return {debut,fin}
}

//custum filter material table

export const filtertable = (columFilter, tableFilter, valueSeach) => {
  const isPresent = (item) => {
      let result = false
      for (const key of columFilter) {
          if(typeof item[key] ==='string'){
            if (item[key]) {
              if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                result = true
                break;
              } else {
                  result = false
              } 
            } else {
                result = false
            }
          }
          
      }
      return result
  }
  return tableFilter.filter(isPresent)
}


//Convertion de fichier en données json en CSV
function convertToCSV(objArray) {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = '';

  for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
          if (line != '') line += ','

          line += array[i][index];
      }

      str += line + '\r\n';
  }

  return str;
}

function createHeadersFrom(data){
   let keysArray =[]
   const obj = {};
   if(data){
     for (const cobj of data){
      const cu = Object.keys(cobj)
      keysArray = [...keysArray,...cu]
     }
   }
   if(keysArray && keysArray.length!==0){
  
    for (const key of keysArray) {
         obj[key] = key.replace(/,/g, '')
    }   
  }
  return obj
}

export const  exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
      items.unshift(headers);
  }else{
    items.unshift(createHeadersFrom(items));
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items);

  var csv = convertToCSV(jsonObject);

  var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
  }
}

//conversion en excel
export const exportToExcel = (columns, data,fileTitle) => {
  // determining header labels
  let columnInfo={}
  let mappedData
  if(columns){
     columnInfo = columns.reduce(
      (acc, column) => {
          const headerLabel = column.title || column.field;
          acc.header.push(headerLabel);
          acc.map[column.field] = headerLabel;
          return acc;
      },
      { map: {}, header: [] }
    );
     mappedData = data.map(row =>
      Object.entries(row).reduce((acc, [key, value]) => {
          if (columnInfo.map[key]) {
              acc[columnInfo.map[key]] = value;
          }
          return acc;
      }, {})
    );
  }else{
      const headers = createHeadersFrom(data)
      columnInfo['header'] = Object.keys(headers)
      mappedData = data.map(row =>
        Object.entries(row).reduce((acc, [key, value]) => {
            if (headers[key]) {
                acc[headers[key]] = value;
            }
            return acc;
        }, {})
      );
  }
 
  

  var ws = XLSX.utils.json_to_sheet(mappedData, {
      header: columnInfo.header, // garanties the column order same as in material-table
  });

  /* add to workbook */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* generate an XLSX file and triggers the browser to generate the download*/
  XLSX.writeFile(wb, `${fileTitle}.xlsx`);
};

//export rapport pdf
export const rapportPdf = (datarp,details,user)=>{
  let doc = new jsPDF('l','px','a4');
  let pageSize = doc.internal.pageSize
  let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
  let pageHeight =  pageSize.height ? pageSize.height : pageSize.getHeight()
  let img = new Image(40, 10)

  if(datarp){
    img.src = require('assets/images/logo_yako.jpg')
    doc.addImage(img, "JPEG", 10, 10, 50, 25);
  }
  doc.setFontSize(7)
  let source =<div style={{width:'100%',padding:0}}>
      <table style={{textAlign:'center', width:(pageWidth-20),padding:0}}>
        <tr style={{textAlign:'center'}}>
          <td style={{width:'90%',textAlign:'center'}}>
           <span style={{fontSize:10,}}>RAPPORT &nbsp; D'OPERATIONS </span> <br />
          </td>

          <td style={{fontSize:4,textAlign:'right'}}>{dayjs().format('DD/MM/YYYY')}</td>
        </tr>
        {datarp['type']!=='all'&&<tr>
          <td colSpan={2} style={{fontSize:8,width:'100%',textAlign:'center'}}>
            <span style={{fontSize:8,}}>
              {datarp['titre'].toUpperCase()}
            </span>
          </td>
        </tr>}
      </table>
      <table>
        <tr>
          <td>
              N° :
              Periode : 
              Type période : 
              Type de rapport : 
              Branche :
          </td>
          <td>
            Equipe/Agence :
            Zone/Département :
            Reseau : 
            Partenaire :
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <th>Code</th>
          <th>Operation</th>
          <th>Date</th>
          <th>N° Courrier</th>
          <th>Proposition</th>
          <th>Adhérent</th>
          <th>Produit</th>
        </tr>
        {(details && details.length!==0)&&(details.map(dt=><tr>
          <td>{details['codeoperation']}</td>
          <td>{details['libelletypeoperation']}</td>
          <td>{details['dateoperation']}</td>
          <td>{details['idcourrier']}</td>
          <td>{details['idproposition']}</td>
          <td>{details['adherent']}</td>
          <td>{details['libelleproduit']}</td>
        </tr>))}
      </table>
  </div>

  let elementAsString = renderToString(source);
  doc.html(elementAsString,{callback:function(doc) {
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
  },x:10,y:25})
}


export const evaluationContrat = async (values) =>{
 
  const {produit,assures,periodicite,duree,capitalSouscrit,dateEffet,primePrincipale,ProdGaranties}=values
  let garantieCalcul =[]
  let mesGaranties = []
  let totalPrime =0
  let surPrime =0
  let primeAss =0
  if((assures && assures.length===0) || (!periodicite || periodicite==='') || (!duree || duree==='')){
    return false
  }
  let localGaranties =Array.from(ProdGaranties)
    let garantiesObligatoires
    let garantiesComplementaires
    let garantiesCompFacultatif
    
    switch (produit.CodeProduit){
      case 'YKE_2008': 
      case 'YKS_2008': 
      case 'YKF_2008': 
      case 'YKE_2018': 
      case 'YKL_2004': 
      case 'YKV_2004': 
      case 'YKF_2004': 
      case 'DOIHOO': 
      case 'CAD_EDUCPLUS': 
      case 'YKR_2021':
      case 'YKS_2018':
          //traitement des produit YAKO
         // assures.garantiesSouscrits =garantieCalcul
         getGarantiesAssure(produit.CodeProduit,ageAssure(dayjs(assures.dateNaissanceAssure).format('YYYY-MM-DD'))).then(garanties=>{
          if(garanties){
            garantiesObligatoires =garanties.filter(item=>item.estobligatoire==1)
            garantiesCompFacultatif = garanties.filter(item=>(item.estobligatoire==0 && item.estcomplementaire==0))
            garantiesComplementaires = garanties.filter(item=>(item.estobligatoire==0 && item.estcomplementaire==1))
            let garantiesParcourru
            assures.garantiesFacultatives=[]
            if((garantiesObligatoires && garantiesObligatoires.length!==0) || (garantiesCompFacultatif && garantiesCompFacultatif.length!==0)){
              let result = false
              let newGarantieOb =[]
              newGarantieOb = garantiesObligatoires.filter(garO=>{return localGaranties.findIndex(el=>el.CodeProduitGarantie==garO.codeproduitgarantie)!== -1})
              garantiesObligatoires = newGarantieOb 
              garantiesParcourru = (garantiesObligatoires && garantiesObligatoires.length!==0) ? garantiesObligatoires:garantiesCompFacultatif

            } 
          }
         })
          if(!capitalSouscrit || capitalSouscrit==='') {
            return false
          }   
          await Promise.all(assures.map(async (assure)=>{
            //création des paramètres de selection de la prime 
            const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                const capitalRef = (maGarantie.estobligatoire===1)?capitalSouscrit:values[`capital-${maGarantie.CodeProduitGarantie}`] 
                resolve({
                    CodeProduit:produit.CodeProduit,
                    CodeProduitFormule:produit.CodeProduitFormule,
                    codePeriodicite:periodicite,
                    duree:duree,
                    capitalSouscrit:capitalSouscrit,
                    dateEffet:dateEffet,
                    age:ageAssure(assure.dateNaissanceAssure),
                    codeGarantie:maGarantie.CodeProduitGarantie
                })
            })}

          // création paramètre de selection de la prime de référence par produit
          const setParamsRef = maGarantie=>{return new Promise((resolve,reject)=>{
            const capitalRef2 = (maGarantie.estobligatoire===1)?capitalSouscrit:values[`capital-${maGarantie.CodeProduitGarantie}`] 
            resolve({
                CodeProduit:produit.CodeProduit,
                CodeProduitFormule:produit.CodeProduitFormule,
                periodicite:periodicite,
                duree:duree,
                primePrincipale:primePrincipale,
                capitalgarantie:parseInt(capitalRef2),
                age:ageAssure(assure.datenaissanceAssure),
                codeGarantie:maGarantie.CodeProduitGarantie
            })
          })}
            
            
            return await Promise.all(garantieCalcul.map(async (garantie) => {
                return getPrimeGarantie(await setParams(garantie)).then(async(data)=>{
                  if(data){
                    primeAss=0                    
                    garantie.prime = data[0].Prime
                    garantie.capitalGarantie = data[0].Capital
                    garantie.primeAccesoire =  primeAss
                    garantie.primeTotal = (parseInt(data[0].Prime)+parseInt(primeAss))
                    totalPrime+=(parseInt(data[0].Prime)+parseInt(primeAss))
                    mesGaranties.push(garantie)
                  }else{
                    const capitalRef3 = (garantie.estobligatoire===1)?capitalSouscrit:values[`capital-${garantie.CodeProduitGarantie}`] 
                    const autreGarantie = await getPrimeReference({
                      CodeProduit:produit.CodeProduit,
                      CodeProduitFormule:produit.CodeProduitFormule,
                      periodicite:periodicite,
                      duree:duree,
                      capitalgarantie:parseInt(capitalRef3),
                      age:ageAssure(assure.datenaissanceAssure),
                      codeGarantie:garantie.CodeProduitGarantie
                  })
                  
                  if(autreGarantie && autreGarantie!==undefined && autreGarantie.length!==0 ){
                    garantie.prime = autreGarantie[0].Prime
                    garantie.capitalGarantie = autreGarantie[0].Capital
                    garantie.primeAccesoire =  0
                    garantie.primeTotal = (parseInt(autreGarantie[0].Prime))
                    totalPrime+=(parseInt(autreGarantie[0].Prime))
                    mesGaranties.push(garantie)
                  }else{
                    /*
                    if(garantie.CodeProduitGarantie ==='SENIOR' && props.data.hasOwnProperty(`prime-${garantie.CodeProduitGarantie}`)){
                      garantie.prime = props.data[`prime-${garantie.CodeProduitGarantie}`]
                      garantie.capitalGarantie =""
                      garantie.primeAccesoire =  0
                      garantie.primeTotal = (parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))    
                      totalPrime+=(parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))
                      mesGaranties.push(garantie)
                    }*/
                  }
                    
                  }
                    return garantie
                })

            })).then(mesgaranties=>{
                 assure.garantiesAssures = mesgaranties
                 return assure
            })
          })).then(async (mesAssures)=>{
              //calcul de la primeprincipale
              surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
              surPrime =(surPrime && surPrime[0])?surPrime[0].montant:0
              
              return {totalPrime,mesAssures,surPrime}
          })
        break;
      case 'CADENCE':
        
        let partPrime
        if(!primePrincipale || primePrincipale==='') {
          return false
        }
        totalPrime=0
       
        //get capital référence
        
        await Promise.all(assures.map(async (assure)=>{
          //récupération de la garantie
          const setParamsCapital ={
            CodeProduit:produit.CodeProduit,
            CodeProduitFormule:produit.CodeProduitFormule,
            periodicite:periodicite,
            duree:duree,
            primePrincipale:primePrincipale,
            age:ageAssure(assure.dateNaissanceAssure)
          }
          /*
          getCapitalReference(setParamsCapital).then(data=>{
            
            if(!props.hasOwnProperty('capitalSouscrit')){
              props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
            }
            props.updateField('capitalSouscrit',data.capital3)
            if(!props.hasOwnProperty('capitalSouscrit5')){
              props.dispatch(registerField("adForm",'capitalSouscrit5','Field'));
            }
            props.updateField('capitalSouscrit',data.capital5)

            if(!props.hasOwnProperty('cumulCotisation')){
              props.dispatch(registerField("adForm",'cumulCotisation','Field'));
            }
            props.updateField('cumulCotisation',data.cumulcotisation)
          })*/

          const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
              resolve({
                  CodeProduit:produit.CodeProduit,
                  CodeProduitFormule:produit.CodeProduitFormule,
                  codePeriodicite:periodicite,
                  duree:duree,
                  dateEffet:dateEffet,
                  capitalSouscrit:capitalSouscrit,// props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                  age:ageAssure(assure.dateNaissanceAssure ),
                  codeGarantie:maGarantie.CodeProduitGarantie
              })
          })}
          
          if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
            garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
          }else{
            garantieCalcul = assure.garantiesSouscrits
          }
          
         
          partPrime =(parseInt(primePrincipale)/2)
          
          return await Promise.all(garantieCalcul.map(async (garantie) => {
            if(garantie.CodeProduitGarantie==='SUR'){
              return getPrimeGarantie(await setParams(garantie)).then(async (data)=>{
                if(data){
                  garantie.prime = data[0].Prime
                  garantie.primeAccesoire =  0
                  garantie.primeTotal = parseInt(data[0].Prime)
                  garantie.capitalGarantie = capitalSouscrit// props.data[`capital-${garantie.CodeProduitGarantie}`]

                  totalPrime+=parseInt(data[0].Prime)
                  mesGaranties.push(garantie)
                  /*
                  if(!props.hasOwnProperty('capitalSouscrit')){
                    props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                  }
                  props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])*/
                }
                return garantie
              })
            }else if(garantie.CodeProduitGarantie==='DECESACC'){
              const maprimeDecess =   parseInt(primePrincipale)-(parseInt(primePrincipale)*0.95)
              garantie.prime =parseInt(maprimeDecess)
              garantie.primeAccesoire =  0
              garantie.primeTotal =parseInt(maprimeDecess)
              totalPrime+=parseInt(maprimeDecess)
              mesGaranties.push(garantie)
              return garantie
            }else{
              /*if(garantie.estprincipal===1){
                primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
              }else{
                primeAss=0
              }*/
              primeAss=0  
              garantie.prime = partPrime
              garantie.primeAccesoire =0
              garantie.primeTotal = (parseInt(partPrime))
              partPrime =parseInt(partPrime)

              totalPrime+=partPrime
              mesGaranties.push(garantie)
              return garantie
            } 
          })).then(mesgaranties=>{
               assure.garantiesAssures = mesgaranties
               return assure
          })

        })).then(async (mesAssures)=>{
            //calcul de la primeprincipale
            surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
            surPrime =surPrime?surPrime[0].montant:0 
            /*//assuré(e)s
            if(!props.hasOwnProperty('assures')){
              props.dispatch(registerField("adForm",'assures','Field'));
             }
            props.updateField('assures',mesAssures)
           //surprime
           if(!props.hasOwnProperty('surPrime')){
            props.dispatch(registerField("adForm",'surPrime','Field'));
           }
           props.updateField('surPrime',surPrime)
           //totalPrime
           if(!props.hasOwnProperty('totalPrime')){
            props.dispatch(registerField("adForm",'totalPrime','Field'));
           }
           setLoading(false)
           props.updateField('totalPrime',(parseInt(totalPrime)+parseInt(surPrime)))
           //props.onSubmit()*/
           return {totalPrime,mesAssures,surPrime}
        })
          
        break;
      case 'PFA_IND': case 'PFA_COL': case 'PFA_BNI':
      
        if(!primePrincipale || primePrincipale==='') {
          return false
        }

        await Promise.all(assures.map(async (assure)=>{
          
          const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
              resolve({
                  CodeProduit:produit.CodeProduit,
                  CodeProduitFormule:produit.CodeProduitFormule,
                  codePeriodicite:periodicite,
                  duree:duree,
                  dateEffet:dateEffet,
                  capitalSouscrit:capitalSouscrit,// props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                  age:ageAssure(assure.dateNaissanceAssure ),
                  codeGarantie:maGarantie.CodeProduitGarantie
              })
          })}
          
           
          if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
            garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
          }else{
            garantieCalcul = assure.garantiesSouscrits
          }
          
          return await Promise.all(garantieCalcul.map(async (garantie) => {
            if(garantie.CodeProduitGarantie==='SUR'){
              return getPrimeGarantie(await setParams(garantie)).then(data=>{
                if(data){
                  garantie.prime = data[0].Prime
                  garantie.primeAccesoire =  0
                  garantie.primeTotal = parseInt(data[0].Prime)
                  garantie.capitalGarantie =capitalSouscrit// props.data[`capital-${garantie.CodeProduitGarantie}`]
                  totalPrime+=parseInt(data[0].Prime)
                  mesGaranties.push(garantie)
                  /*
                  if(!props.hasOwnProperty('capitalSouscrit')){
                    props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                  }
                  props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])*/
                
                }
                return garantie
            })
            }else if (garantie.CodeProduitGarantie==='PERF'){
              /*if(garantie.estprincipal===1){
                primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
              } else{
                primeAss=0
              }*/
              primeAss=0  
              const maprimePerf =  (parseInt(primePrincipale)*(40/100))  
              garantie.prime =maprimePerf
              garantie.primeAccesoire =  0
              garantie.primeTotal =maprimePerf
              totalPrime+=parseInt(maprimePerf)
              mesGaranties.push(garantie)  
              return garantie
            }else{
                /*if(garantie.estprincipal===1){
                  primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                  primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                } else{
                  primeAss=0
                }*/
                primeAss=0
                const maprimeSecu = (parseInt(primePrincipale)*60/100)  
                garantie.prime =maprimeSecu
                garantie.primeAccesoire =  0
                garantie.primeTotal =maprimeSecu
                totalPrime+=maprimeSecu
                mesGaranties.push(garantie)  
            }  
          })).then(mesgaranties=>{
               assure.garantiesAssures = mesgaranties
               return assure
          })

        })).then(async (mesAssures)=>{
            //calcul de la primeprincipale
            surPrime =0 //await getPrimeAccessoireProduit(produit.CodeProduit,periodicite) 
            //surPrime = surPrime?surPrime[0].montant:0
            //assuré(e)s
           /* if(!props.hasOwnProperty('assures')){
              props.dispatch(registerField("adForm",'assures','Field'));
             }
            props.updateField('assures',mesAssures)
           //surprime
           if(!props.hasOwnProperty('surPrime')){
            props.dispatch(registerField("adForm",'surPrime','Field'));
           }
           props.updateField('surPrime',surPrime)
           //totalPrime
           if(!props.hasOwnProperty('totalPrime')){
            props.dispatch(registerField("adForm",'totalPrime','Field'));
           }
           setLoading(true)
           props.updateField('totalPrime',(parseInt(totalPrime)))
          // props.onSubmit()*/
          return {totalPrime,mesAssures,surPrime}
        })
        break;
      case 'PVRBNI':
            const {dureerente,montantrente,periodiciterente} =values
            if(!dureerente || dureerente==='') {
                return false
            }
            if(!montantrente || montantrente==='') {
                return false
            }
            if(!periodiciterente || periodiciterente==='') {
                return false
            }
            //determination de la prime net 1
            const dureeCotisation = duree
            const i_c = 0.035; const fg_c =0.05; const i_net_c = i_c*(1-fg_c)
            let per_c =12
            switch (periodicite) {
                case 'M':
                    per_c=12
                    break;
                case 'T':
                    per_c=4
                    break;
                case 'S':
                    per_c=2
                    break;
                default:
                    per_c=1
                    break;
            };
            const i_per =(Math.pow((1+i_net_c),(1/per_c)))-1
            let v_c = 1/(1+i_per)
            let d_c = i_per/(1+i_per)
            let valeur_a_c=(1-Math.pow(v_c,(dureeCotisation*per_c)))/d_c

            const tx_fg =0.04
            const tx_fa =0.05
            const tx_investi = 1-tx_fg-tx_fa
            const prime_net1 = (valeur_a_c*tx_investi)

            // determination prime rente
            const i_r = 0.035
            const fg_r =0.05
            const i_net_r = i_r*(1-fg_r)
            let per_r =12
            switch (periodiciterente) {
                case 'M':
                    per_r=12
                    break;
                case 'T':
                    per_r=4
                    break;
                case 'S':
                    per_r=2
                    break;
                default:
                    per_r=1
                    break;
            };
            const i_per_r =(Math.pow((1+i_net_r),(1/per_r)))-1
            const v_r = 1/(1+i_per_r)
            const d_r = i_per_r/(1+i_per_r)
            const dureeRentea = parseInt(dureerente)/12
            const dureeTotal = dureeRentea+parseInt(dureeCotisation)
            const valeur_a_r =(1-Math.pow(v_r,((dureeTotal-dureeCotisation)*per_r)))/d_r
            const vn = Math.pow(v_r,dureeCotisation*per_r)

            const tx_fg_r = (1+0.03)
            const primeRelle = Math.round(parseInt((parseInt(montantrente)*tx_fg_r*valeur_a_r*vn)/prime_net1))
            await Promise.all(assures.map(async (assure)=>{

                if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                    garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                }else{
                    garantieCalcul = assure.garantiesSouscrits
                }

                return await Promise.all(garantieCalcul.map(async (garantie) => {
                    garantie.prime = primeRelle
                    garantie.primeAccesoire =  0
                    garantie.primeTotal = parseInt(primeRelle)
                    garantie.capitalGarantie = 0

                    totalPrime+=primeRelle
                    return garantie

                })).then(mesgaranties=>{
                    assure.garantiesAssures = mesgaranties
                    return assure
                })

            })).then(async (mesAssures)=>{
                //calcul de la primeprincipale

                /*if(!props.hasOwnProperty('assures')){
                    props.dispatch(registerField("adForm",'primePrincipale','Field'));
                }
                props.updateField('primePrincipale',primeRelle)

                surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                surPrime =0; //surPrime?surPrime[0].montant:0
                //assuré(e)s

                if(!props.hasOwnProperty('assures')){
                    props.dispatch(registerField("adForm",'assures','Field'));
                }
                props.updateField('assures',mesAssures)
                //surprime
                if(!props.hasOwnProperty('surPrime')){
                    props.dispatch(registerField("adForm",'surPrime','Field'));
                }
                props.updateField('surPrime',surPrime)
                //totalPrime
                if(!props.hasOwnProperty('totalPrime')){
                    props.dispatch(registerField("adForm",'totalPrime','Field'));
                }
                setLoading(true)
                props.updateField('totalPrime',(parseInt(totalPrime)+parseInt(surPrime)))
                //props.onSubmit()*/
                return {totalPrime,mesAssures,surPrime}
            })
        break;
      default:
        break;
    }
}

export const compareStrings =(s1, s2)=>{
  // This condition will return true only if s1 and s2 hold true from equality
  if(s1 == s2){
      return  true;
  }
  
  return false;
}



export const primePvrPremium = async (data,user)=>{

    const {CodeProduit, datenaissanceassure, prime, periodicite,ageretraite } = data
    const dataSend ={
        "periodicite": periodicite,
        "prime":prime,
        "codeproduit":CodeProduit,
        "partenaire": user.codepartenaire
    }

    const pprime =prime
    const age_assure = ageAssure(dayjs(datenaissanceassure).format('YYYY-MM-DD'))
    const duree = (parseInt(ageretraite) - parseInt(ageAssure(dayjs(datenaissanceassure).format('YYYY-MM-DD'))))

    const tranche = await getTanchePrime(dataSend)

    let capital

    if(tranche){
        capital = tranche.capital
    }
    let facteur_a =0
    let facteur_b =0

    let facteurs = await getFacteurFraction(dataSend)
    if(facteurs){
        facteur_a = facteurs.facteur_a
        facteur_b = facteurs.facteur_b
    }
    let mx, mxt,dxt, dx, nx, nxt
    const tbm = await getTableMortaliteAssure(age_assure,'TDMCPN')
    //table de mortalité au terme
    const tbmt = await getTableMortaliteAssure((parseInt(age_assure) + duree),'TDMCPN')

    let m=12
    switch (periodicite) {
        case 'M':
            m=12
            break;
        case 'T':
            m=4
            break;
        case 'S':
            m=2
            break;
        case 'A':
            m=1
            break;
        default:
            m=12
            break;
    }
    const i_c = 0.035;
    const i_m = (Math.pow((1 + i_c), (1 / m))) - 1

    const tx_g_epargne_plus = 0.04 // Taux de gestion garantie épargne plus 
    const tx_a_epargne_plus = 0.05 //Taux d'acqusition garantie épargne plus

    const tx_g_protectys = 0.02 // Taux de gestion garantie protectys 
    const tx_a_protectys = 0 //Taux d'acqusition garantie protectys 

    const tx_g_persistance = 0.02 // Taux de gestion garantie persistance 
    const tx_a_persistance  = 0 // Taux d'acqusition garantie persistance 
    const v = 1/(1+i_c)
    if (tbm) {
        mx = Number(tbm.mx)
        dx = Number(tbm.ddx)
        nx = Number(tbm.nx)
      }
    if (tbmt) {
        mxt = Number(tbmt.mx)
        nxt = Number(tbmt.nx)
        dxt = Number(tbmt.ddx)
    }
    console.log(tbm);
    //calcul du facteur périodique
    const facteur_periodique  =(facteur_a*((nx-nxt)/dx))-(facteur_b*(1-(dxt/dx)))
    //facteur de persistance
    const facteur_persistance  =(1-Math.pow(v,10))/(1-Math.pow(v,1/m))

    const p = 0.92 //probabilité de demeuré en portefeuil
    const tx_bonus = 0.04 //taux de bonus

    //calcul de la prime pure de la garantie protecty
    const pup_protectys = capital*((mx-mxt)/dx)

    const tx_actualisation_fg =(nx-nxt)/dx

    //determinaion des frais de gestion
    const fg_protectys = (tx_g_protectys/12)*tx_actualisation_fg*capital
    const fg_persistance  =((tx_g_persistance/12)*tx_actualisation_fg*capital)
    const fg_epargne  = ((tx_g_epargne_plus/12)*tx_actualisation_fg*capital)

    //Calcul de la prime unique commerciale PUC_ protectys
    const puc_protectys = ((pup_protectys+fg_protectys)/(1-tx_a_protectys))
    //Calcul de la prime périodique pure
    const pp_p_protectys =((pup_protectys/facteur_periodique))
    //Calcul de la prime périodique commerciale
    const pp_c_protectys =  roundMultiple(pp_p_protectys/(1-tx_g_protectys),5)
    //Calcul de la prime persistance
    //Calcul de la prime périodique pure 

        //prime pure persistance jusqu'a 10 ans
    let  pup_persistance 
    let prime_c_epargne   

    //Calcul de la prime périodique commerciale 
    const primes_eplus =[]
    const primes_persistance =[]
    let epargne10
    let epargne
    let tabpm=[]

    for(let i=0;i<duree;i++){
       if(i<=10){
          pup_persistance = p*(tx_bonus*capital)*Math.pow(v,10)
       }else{
          pup_persistance = p*(tx_bonus*capital)*Math.pow(v,(duree-10))
       }

       const puc_persistance = (pup_persistance+fg_persistance)/(1-tx_a_persistance)

       const pp_p_peristance = pup_persistance/facteur_persistance
       
       const pp_c_peristance = roundMultiple(pp_p_peristance/(1-tx_g_persistance),5)

       if(i<=10){
          primes_persistance.push(pp_c_peristance)
       }
       
       if(i<=10){
          prime_c_epargne = pprime-(pp_c_protectys+pp_c_peristance)
          epargne10 = prime_c_epargne
        }else{
          prime_c_epargne = pprime-pp_c_protectys
          epargne = prime_c_epargne
        }

        tabpm.push(prime_c_epargne*((Math.pow(1+i_c,i)-1)/(1-Math.pow(1+i_c,-(1/m)))))
        primes_eplus.push(prime_c_epargne)
    }
                     
      let cumuleplus = primes_eplus.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue*m)
      },0);
      
      let cumulProtectys =   parseInt(pp_c_protectys)*duree*m 

      let cumulPersistance10 =   primes_persistance.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue*m)
      },0);    

      const cumulpm =  tabpm.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue*m)
      },0)  
      
      const capitalterme10 = ((Math.pow(1+i_c,10)-1)/(1-Math.pow(v,1/m)))*(epargne10*(1-tx_a_epargne_plus-tx_g_epargne_plus))
      const caputailterme =(duree <=10) ? capitalterme10 : (((Math.pow(1+i_c,duree-10)-1)/(1-Math.pow(v,1/m)))*(epargne*(1-tx_a_epargne_plus-tx_g_epargne_plus))+(Math.pow(1+i_c,duree-10)*capitalterme10))

      const detailsprime =  {
       "tx-interet":i_c,
      "tx-g-epargne":tx_g_epargne_plus,
      "tx_a_epargne":tx_a_epargne_plus,
      "tx-g-protectys":tx_g_protectys,
      "tx-a-protectys":tx_a_protectys,
      "tx-g-persistance":tx_g_persistance,
      "tx-a-persistance":tx_a_persistance,
      "v":v,
      "facteur-periodique":facteur_periodique,
      "facteur-persistance":facteur_persistance,
      "p":p,
      "tx-bonus":tx_bonus,
      "pup-protectys":pup_protectys,
      "tx-actualisation-fg":tx_actualisation_fg,
      "fg-protectys":fg_protectys,
      "fg-persistance":fg_persistance,
      "fg-epargne":fg_epargne,
      "puc-protectys":puc_protectys,
      "pp-p-protectys":pp_p_protectys,
      "pp-c-protectys":pp_c_protectys,
      "periodicite": periodicite,
      "prime":prime,
      "codeproduit":CodeProduit,
      "primeseplus": primes_eplus,
      "primespersistance": primes_persistance,
      "cumuleplus":cumuleplus,
      "cumulcotisation": (cumuleplus+cumulProtectys+cumulPersistance10) ,
      "capitalterme":caputailterme,
      "capitalterme10":capitalterme10,
      "bonuspersistance":(capital*tx_bonus),
      "duree":duree,
      "age":age_assure,
      "primeepargne10":epargne10,
      "primeepargne":epargne,
      "primeperistance":primes_persistance[0],
      "capitaldeces":capital,
      "primeprotectys":parseInt(pp_c_protectys)
    }
 
    return {...detailsprime}
    //calcul de la prime épargne plus

}