import React, { useReducer,useEffect,useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import MaterialTable,{MTableToolbar } from 'material-table';
import {connect,useDispatch} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {addZone,getReseaux,getZones,getModules,getPrivileges} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AddModule from './AddModule'
function ModuleList(props){
    const {settings:{reseaux,modules,privileges}}=props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [addopen, setAddopen] = React.useState(false);
    const  dispatch = useDispatch()
    useEffect(() => {
        dispatch(getReseaux())
        dispatch(getModules())
        dispatch(getPrivileges())
    }, [])
    
    const handleClickAddOpen = () => {
      setAddopen(true);
    };

  const handleCloseAdd = () => {
    setAddopen(false);
  };
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
      codereseau:'',
      codemodule:'',
      libelle:'',
      icon:'',
      url:''
    });
    
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveZone =()=>{
    if(inputField.codereseau==='')return
    if(inputField.libelle.trim()==='')return
    if(inputField.codemodule.trim()==='')return
    props.addZone(inputField).then(data=>{
        if(data){
            handleCloseAdd()
        }else{
            return false
        }
    }).catch(err=>{
        console.log(err);
        return
    })
  }
  
    return (
        <GridContainer>
            <AddModule mopen={addopen} handleCloseMod={handleCloseAdd} handleMopen={handleClickAddOpen} />
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
                  columns={[
                    { title: '#', field: 'id' },
                    { title: 'Code', field: 'codemodule'},
                    { title: 'Libelle', field: 'libelle'},
                    { title: 'icon', field: 'icon'},
                    { title: 'url', field: 'URL'},
                    { title: 'Reseau', field: 'codereseau',hidden:true},
                    { title: 'id', field: 'idreseau',hidden:true},
                    { title: 'Reseau', field: 'libellereseau'},
                  ]}
                  data={modules}
                  title="Liste des modules"
                  localization={{
                    toolbar:{
                        searchPlaceholder:'Rechercher',
                        searchTooltip:'Rechercher'
                    },
                    body: {
                      emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                      labelRowsSelect: 'Ligne(s)',
                      labelDisplayedRows: '{count} sur {from}-{to}',
                      firstTooltip: 'Prémière page',
                      previousTooltip: 'Précédent',
                      nextTooltip: 'Suivant',
                      lastTooltip: 'Dernière page'
                    }
                  }}
                  
                  options={{
                    exportButton: true,
                    searchFieldStyle:{
                      fontSize:18,
                      width:'100%',
                      height:50,
                    },
                    actionsColumnIndex: -1
                  }}
                  
                  components={{
                  Action: props=> {  
                      return(  
                        <CustomMenu 
                          data={props.data}       
                          />
                        )
                  },
                  Toolbar: props => (
                      <div>
                      <MTableToolbar {...props} />
                        <div style={{padding: '0px 10px',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                          <Button onClick={handleClickAddOpen} variant='outlined' color='primary' >
                              <Add style={{color:'#fff',fontSize: 30}} />
                          </Button> 
                        </div>
                        
                      </div>
                  )
                  }}
                  actions={[
                    {
                      icon: 'more_vert',
                      tooltip: 'Actions',
                      onClick: (event, rowData) =>alert("You want to add a new row"),
                    },
                  ]}
                  
                />
            </GridItem>
        </GridContainer>
    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user
  });
  const mapActionsToProps = {addZone}
export default connect(mapStateToProps,mapActionsToProps)(ModuleList)