import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import {Home,Assignment,Add, Folder} from "@material-ui/icons/";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from "react-router-dom";
import {selectPropositionByUser} from 'redux/actions/apiActions'
import {verifRule} from 'application'
import {  reduxForm } from 'redux-form'
import { connect } from 'react-redux';
const useStyles = makeStyles(theme =>({
    root: {
      justifyContent:'center',
      alignItem:'center',
      width: 500,
     marginBottom:40
    },
    fonctionAction:{
        marginBottom:20
    },
    btnAction:{
      width:80,
      height:80,
      backgroundColor:'#fff',
      color:theme.palette.primary.main,
      '&:hover':{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText
      },
      '&active':{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText
      }
    },
    
  }));


function HeaderFonction(props) {
    const {handleFonctionMini,privileges,actualise} = props
    let history = useHistory();
    const classes = useStyles()
    const [currentV, setCurrentV] = React.useState('recents')
    const handleChange = (event,newValue) => {  
     
        setCurrentV(newValue);
        handleFonctionMini(newValue)
        if(newValue==='ADDPROD'){
         history.push('/com/nouvelle-proposition');
        }
        if(newValue ==='ACT'){
          actualise()
        }
    }
  
    return (
       
        <BottomNavigation  value={currentV} component='div' onChange={handleChange} className={classes.root}>
            <BottomNavigationAction showLabel className={classes.btnAction}  label="" value="HOME" icon={<Home />} />
            {verifRule('prop','add',privileges)&&<BottomNavigationAction showLabel className={classes.btnAction} label="Nouvelle proposition" value="ADDPROD" icon={<Add />}/>}
            
            <BottomNavigationAction showLabel className={classes.btnAction} label="Liste propositions" value="PROD" icon={<Folder />} />
            <BottomNavigationAction showLabel className={classes.btnAction} label="Liste contrats" value="CONTRAT" icon={<Assignment />} />
            <BottomNavigationAction showLabel className={classes.btnAction} label="Rechercher un contrat" value="SEARCH" icon={<FindInPageIcon />} />
            <BottomNavigationAction showLabel className={classes.btnAction} label="Toutes les propositions" value="ACT" icon={<RefreshIcon />} />
        </BottomNavigation> 
         
    )
}


const mapPropsActions ={selectPropositionByUser}
const mapStateToProps = (state) => ({api:state.api.propositionAll});
export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(HeaderFonction))

