import styled from 'styled-components/macro'
import CardHeader from 'components/Card/CardHeader'
import Typography from '@material-ui/core/Typography';
export const Container = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: space-around;
`;


export const Header = styled(CardHeader)` 
    display:flex;
    justify-content:flex-start;
    margin:0;
`;
export const Base = styled.div`
    display:flex;
    justify-content:space-between
    width:70%;
    margin:auto;
    margin-top:20px;
    padding:20px;
   @media(max-width: 600px){
        display:flex;
        flex-direction:column;
        margin-left:0;
        width:100%;
    }
`;

export const Form = styled.div`
    display:flex
`;

export const Group = styled.div`
    display: flex;
`
export const Title = styled(Typography)`
    font-size:26px;
`;
export const SideBar = styled.section`
    display:flex;
    flex-direction:column;
    margin-left:20px;
`;

export const Text = styled(Typography)`

`;

export const TextSmall = styled(Typography)`
`;