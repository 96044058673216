import React from 'react'
import {Container,Inner,Card,Title,Details,Group,SmallText} from './style'

export default function Ask({children,...restProps}){
    return (<Container {...restProps}>{children}</Container>)
}

Ask.Inner = function AskInner({children,...restProps}){
    return (<Inner  {...restProps}>{children}</Inner>)
}

Ask.Card = function AskeCard({children,...restProps}){
    return (<Card  {...restProps}>{children}</Card>)
}

Ask.Title = function AskTitle({children,...restProps}){
    return (<Title  {...restProps}>{children}</Title>)
}


Ask.Details = function AskeDetails({children,...restProps}){
    return (<Details  {...restProps}>{children}</Details>)
}

Ask.Group = function AskeGroup({children,...restProps}){
    return (<Group  {...restProps}>{children}</Group>)
}

Ask.SmallText = function AskeSmallText({children,...restProps}){
    return (<SmallText  {...restProps}>{children}</SmallText>)
}