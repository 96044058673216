import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { PDFReader } from 'react-read-pdf'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { Divider, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import config from 'util/config'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import {getDocument} from 'redux/actions/apiActions'
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    table: {
        minWidth: 650,
      },
      buttonDetails:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        borderRadius:0,
        marginRight:20,
        color:theme.palette.secondary.main,
        '&:hover':{
          borderColor:theme.palette.primary.main,
        }
      },
  }));
function DocumentBox(props) {
    const {data} =props
    const {url,localurl}=config

    const classes = useStyles();
    const [documentList,setDocumentList] = useState(null) 
    const handleType =(doc)=>{
        if(doc){
           return doc.split('.')[1]
        }
        return false
    }

    const handleGetDocuments =()=>{
        if(data && data.hasOwnProperty('id')){
            getDocument(data.id,'OP').then(res=>{
                if(res){
                    setDocumentList(res['documents'])
                }
            })
        }
    }
    useEffect(() => {
        handleGetDocuments()
    }, [data])

    
    const [currentPage,setCurrentPage]=useState(1)
    const [allPage,setAllPage] = useState(1)
    const [showAll,setShowAll] =useState(false)
    const handleDocInfo =(infoDoc)=>{
        setAllPage(infoDoc)
        setShowAll(true)
    }
    const handleNextPage =()=>{
        if(currentPage<allPage){
            setCurrentPage(currentPage + 1)
        }
    }
    const handleBackPage =()=>{
        if(currentPage>1){
            setCurrentPage(currentPage - 1)
        }
    }
    const guideLine=()=>{  
        return (
          
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={1} sm={1} md={1} lg={1} style={{marginRight:25}}>
                <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage>1)} onClick={()=>setCurrentPage(1)}>
                      <FirstPageIcon color={(currentPage>1)?'primary':"secondary"} style={{ fontSize: 40 }} />
                </IconButton>
            </GridItem>
           <GridItem xs={1} sm={1} md={1} lg={1} style={{marginRight:25}} >
             <IconButton color='inherit' className={classes.buttonDetails}  disabled={!(currentPage>1)} onClick={handleBackPage}>
                   <NavigateBeforeIcon color={(currentPage>1)?'primary':"secondary"} style={{ fontSize: 40 }} />
             </IconButton>
           </GridItem>
           <GridItem xs={1} sm={1} md={1} lg={1} style={{marginRight:25}}>
             <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage<allPage)} onClick={handleNextPage}>
                   <NavigateNextIcon color={(currentPage<allPage)?'primary':"secondary"} style={{ fontSize: 40 }} />
               </IconButton>
           </GridItem>
    
      </GridContainer>)
    }

  return (
    <div>
        <div style={{marginTop:20, position:'sticky',  objectFit:'contain'}}>
                {(documentList && documentList.lenght!==0)?(
                    documentList.map(doc=>{
                     
                        if(['png','jpg','jpeg','gif'].includes(handleType(doc.filename))){
                            return <Card>
                            <CardMedia
                                component="img"
                                alt={doc.libelle}
                                image={`${localurl+doc.filename}`}
                                title={doc.libelle}
                                />
                            <CardActions>
                                <Button  size="large" color="primary" ><SettingsOverscanIcon /></Button>
                            </CardActions>
                         </Card>
                        }else{
                            return <Card style={{width:'100%',height:'70%',overflow:'auto'}}>
                                    <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',padding:10}}>
                                        <Typography variant="h6" compoenent ='h6'>
                                            {data.libelle?data.libelle:"# Aucun titre"}
                                        </Typography>
                                    </div>
                                <CardContent>
                                    <div style={{overflow:'scroll'}}>
                                        <PDFReader page={currentPage}  url={`${localurl+doc.filename}`} onDocumentComplete={handleDocInfo}  /> 
                                    </div>
                                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                        {guideLine()}<Typography color='primary' variant='h4' component ='h4' >{`${currentPage}/${allPage} Page(s)`}</Typography>
                                    </div>               
                                </CardContent>
                            </Card> 
                        }
                    })
                ):(<Typography>Aucun fichier trouvver</Typography>)}
                  
        </div>
    </div>
  )
}

export default DocumentBox