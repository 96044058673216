import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { connect } from 'react-redux';
import RangeDatePicker from "components/RangeDatePicker";
import Chart from "util/chart";
import Typography from "@material-ui/core/Typography";
import { selectPropositionByUser, getEvolutionProductionAgent } from 'redux/actions/apiActions'
import dayjs from 'dayjs'
function EvolutionProduction(props) {

  const canvasRef = React.createRef();
  const [graphe, setGraphe] = React.useReducer((state, newState) => ({ ...state, ...newState }),
    {
      grCurrentMonth: [],
      grLastMonth: [],

    });
  const { title, user: { credentials } } = props;

  const handleDataEvolution = (data) => {
    setGraphe({
      ['grCurrentMonth']: data.dataCurrentMonth,
      ['grLastMonth']: data.datatLastMonth
    })
  }

  const reduceDataMonth = (mdata, dateDebut, dateFin) => {
    let response = []
    let result = 0
    if (mdata && mdata.length !== 0) {
      for (let d = dateDebut; d <= dateFin; d.setDate(d.getDate() + 1)) {
        result = 0
        mdata.forEach(el => {
          if (dayjs(el.saisiele).format('YYYY-MM-DD') === dayjs(d).format('YYYY-MM-DD')) {
            result = el.total
            return
          } else {
            return false
          }
        })
        response.push(result)
      }
    }
    return response
  }
  const chartData = {
    title: title,
    labels: Array.from(new Array((graphe.grCurrentMonth.length - graphe.grLastMonth.length) > 0 ? graphe.grCurrentMonth.length : graphe.grLastMonth.length), (_, i) => (i === 0 ? 1 : i)),
    datasets: [
      {
        label: "Mois en cours",
        fill: "start",
        data: graphe.grCurrentMonth,
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "Mois dernier",
        fill: "start",
        data: graphe.grLastMonth,
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgba(255,65,105,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(255,65,105,1)",
        borderDash: [3, 3],
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
        pointBorderColor: "rgba(255,65,105,1)"
      }
    ]
  }
  useEffect(() => {
    getEvolutionProductionAgent(credentials.idmembre).then(resData => {
      
      if (resData) {
        const { currentMonth, lastMonth, periodes } = resData

        const data = {
          dataCurrentMonth: reduceDataMonth(currentMonth, new Date(periodes.currentDeb), new Date(periodes.currentFin)),
          datatLastMonth: reduceDataMonth(lastMonth, new Date(periodes.lastDeb), new Date(periodes.lastFin))
        }
        handleDataEvolution(data)
      }
    })
  }, [])
  useEffect(() => {
    //autre traitement
    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return index % 7 !== 0 ? "" : tick;
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 100,
                callback(tick, index) {
                  return tick;
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        }
      },
      ...props.chartOptions
    };

    const DashEvolutionProd = new Chart(canvasRef.current, {
      type: "LineWithLine",
      data: chartData,
      options: chartOptions
    });

    // They can still be triggered on hover.
    const buoMeta = DashEvolutionProd.getDatasetMeta(0);
    if (chartData.datasets[0].data.length !== 0) {
      buoMeta.data[0]._model.radius = 0;
      buoMeta.data[
        chartData.datasets[0].data.length - 1
      ]._model.radius = 0;
    }


    // Render the chart.
    DashEvolutionProd.render();
  }, [graphe])

  return (
    <Card small className="h-100" style={{flex:1,marginRight:20}}>
      <CardHeader className="border-bottom">
        <Typography className="m-0" variant='h5' component='h5'> {title}</Typography>
      </CardHeader>
      <CardBody className="pt-0">

        <canvas
          height="120"
          ref={canvasRef}
          style={{flex:1, maxWidth: "100% !important" }}
        />
      </CardBody>
    </Card>
  );
}

EvolutionProduction.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};


const mapActionsToProps = {
  selectPropositionByUser
}

const mapStateProps = (state) => ({
  api: state.api,
  user: state.user
})
export default connect(mapStateProps, mapActionsToProps)(EvolutionProduction)
//export default EvolutionProduction;
