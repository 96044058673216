import React, { useReducer,useEffect,useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import MaterialTable,{MTableToolbar } from 'material-table';
import {connect,useDispatch} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInput'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import {addEquipe,getReseaux,getZonesReseau,getEquipesReseau} from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import HeaderFonction from '../components/HeaderFonction'
import {verifRule} from 'application'
function EquipeList(props){
    const {settings:{reseaux},user:{credentials}}=props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [addopen, setAddopen] = React.useState(false);
    const [equipes,setEquipes] = React.useState([]);
    const [zones,setZones] = React.useState([]);
    const  dispatch = useDispatch()

    useEffect(() => {
        if(credentials){
          getEquipesReseau(credentials.codereseau).then(resData=>{
            if(resData){
              setEquipes(resData)
            }
          })
        }
      //  dispatch(getEquipes())
    }, [])
    
    
    const handleClickAddOpen = () => {
      setAddopen(true);
    };

  const handleCloseAdd = () => {
    setAddopen(false);
  };

  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    codeequipe:'',
    codezone:'',
    libelleequipe:'',
    coderesponsable:'',
    nomresponsable:'',
    codereseau:credentials.codereseau
    });

    useEffect(() => {
      if(inputField.codereseau!==''){
        getZonesReseau(inputField.codereseau).then(datar=>{
          if(datar){
            setZones(datar)
          }
        })
      }
    },[inputField.codereseau])
 
   
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveEquipe =()=>{
    if(inputField.codezone==='')return
    if(inputField.libelleequipe.trim()==='')return
    if(inputField.codeequipe.trim()==='')return
    props.addEquipe(inputField).then(data=>{
        if(data){
            handleCloseAdd()
        }else{
            return false
        }
    }).catch(err=>{
        console.log(err);
        
        return
    })
  }
  const handleFonctionMini =(newvaleur)=>{
    props.handleCliqueFonction(newvaleur)
  }
    return (
      <div style={{marginTop:75}}>
        <GridContainer>
           <GridItem xs={12} sm={12} md={12} lg={12}>
              <HeaderFonction handleFonctionMini={handleFonctionMini}/>
          </GridItem>
            <Dialog maxWidth='md' fullWidth open={addopen} onClose={handleCloseAdd} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ajouter une équipe </DialogTitle>
        <DialogContent>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                    required
                    autoFocus
                    variant="outlined"
                    id="codeequipe"
                    name='codeequipe'
                    label="code"
                    type="text"
                    value = {inputField.codeequipe}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={handleChange}
                />
                </GridItem>
                <GridItem xs={12} sm={8} md={8} lg={8}>
                <CustomInput
                    autoFocus
                    required
                    variant="outlined"
                    id="libelleequipe"
                    name ='libelleequipe'
                    onChange={handleChange}
                    label="Nom de l'équipe / agence"
                    type="text"
                    value = {inputField.libelleequipe}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                    required
                    autoFocus
                    variant="outlined"
                    id="coderesponsable"
                    name='coderesponsable'
                    label="code responsable"
                    type="text"
                    value = {inputField.coderesponsable}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={handleChange}
                />
                </GridItem>
                <GridItem xs={12} sm={8} md={8} lg={8}>
                <CustomInput
                    autoFocus
                    required
                    variant="outlined"
                    id="nomresponsable"
                    name ='nomresponsable'
                    onChange={handleChange}
                    label="Nom responsable"
                    type="text"
                    value = {inputField.nomresponsable}
                    formControlProps={{
                        fullWidth: true
                    }}
                />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                <TextField
                     variant="outlined"
                     id="codezone"
                     name ='codezone'
                     select
                     label="Zone/Inspection/Departement"
                     value={inputField.codezone}
                     onChange={handleChange}
                     fullWidth
                     InputLabelProps={{
                        shrink: true,
                    }}
                    style={{marginTop:20,marginBottom:10}}
                    >
                       <MenuItem value='' />
                    {(zones.length !==0)&& zones.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.libellezone}
                        </MenuItem>
                    ))}
                    </TextField>
                </GridItem>
            </GridContainer>       
                <Divider />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAdd} variant='outlined' color="secondary">Annuler</Button>
          <Button onClick={handleSaveEquipe} variant='outlined' color="primary">Ajouter</Button>
        </DialogActions>
      </Dialog>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
                  columns={[
                    { title: '#', field: 'id' },
                    { title: 'Code', field: 'codeequipe'},
                    { title: 'Libelle', field: 'libelleequipe'},
                    { title: 'Code responsable', field: 'coderesponsable'},
                    { title: 'Nom responsable.', field: 'nomresponsable'},
                    { title: 'Zone', field: 'codezone'},
                  ]}
                  data={equipes}
                  title="Gestion des équipes/agences"
                  localization={{
                    toolbar:{
                        searchPlaceholder:'Rechercher',
                        searchTooltip:'Rechercher'
                    },
                    body: {
                      emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                      labelRowsSelect: 'Ligne(s)',
                      labelDisplayedRows: '{count} sur {from}-{to}',
                      firstTooltip: 'Prémière page',
                      previousTooltip: 'Précédent',
                      nextTooltip: 'Suivant',
                      lastTooltip: 'Dernière page'
                    }
                  }}
                  
                  options={{
                    exportButton: true,
                    searchFieldStyle:{
                      fontSize:18,
                      width:'100%',
                      height:50,
                    },
                    actionsColumnIndex: -1
                  }}
                  
                  components={{
                  Action: props=> {  
                      return(  
                        <CustomMenu 
                          data={props.data}       
                          />
                        )
                  },
                  Toolbar: props => (
                      <div>
                      <MTableToolbar {...props} />
                      {verifRule('agence','add')&&(<div style={{padding: '0px 10px',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                          <Button onClick={handleClickAddOpen} variant='outlined' color='primary' >
                              <Add style={{color:'#fff',fontSize: 30}} />
                          </Button> 
                        </div>)}
                        
                        
                      </div>
                  )
                  }}
                  actions={[
                    {
                      icon: 'more_vert',
                      tooltip: 'Actions',
                      onClick: (event, rowData) =>alert("You want to add a new row"),
                    },
                  ]}
                  
                />
            </GridItem>
        </GridContainer>
        </div>
    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user
  });
  const mapActionsToProps = {addEquipe}
export default connect(mapStateToProps,mapActionsToProps)(EquipeList)