import React,{useState} from "react";
import { MenuItem, Menu } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateRole from './UpdateRole'
import PrivilegesRole from './PrivilegesRole'
import {connect,useDispatch} from 'react-redux'
import { getModules,deleteRole } from "redux/actions/settingsAction";
import LockOpenIcon from '@material-ui/icons/LockOpen';
function CustomMenu (props){
  const {data,handleRole} = props;  
  const dispatch =useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ropen, setRopen] = useState(false)
  const handleRopen =()=>{
    setRopen(true)
    handleClose()
  }
  const handleCloseAdd =()=>{
    setRopen(false)
    handleClose()
  }
  const [eopen, setEopen] = useState(false)
  const handleEopen =()=>{
    setEopen(true)
    handleClose()
  }
  const handleCloseEquipe =()=>{
    setEopen(false)
    handleClose()
  }
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  
  
const handleDelete=()=>{
  deleteRole(data.id,data.idreseau).then(mrx=>{
    if(mrx){
      handleRole()
    }
  })
}

   
      return(
        <React.Fragment>
         <UpdateRole roleData={data} mopen={ropen} handleCloseMod={handleCloseAdd}/>
         <PrivilegesRole roleData={data} eopen={eopen} handleCloseEquipe={handleCloseEquipe}/>
          <IconButton
            data-id={data.id}
            aria-label="actions"
            aria-controls='long-menu'
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="card-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
          
              <MenuItem onClick={handleRopen}>
              <ListItemIcon>
                  <NoteAddIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Modifier</Typography>
            </MenuItem>   
            <MenuItem disabled>
                <Divider />
            </MenuItem>
          
              <MenuItem onClick={handleEopen}>
                <ListItemIcon>
                  <LockOpenIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Droits & privilèges</Typography>
              </MenuItem>
            <MenuItem disabled>
                <Divider />
            </MenuItem> 
              <MenuItem disabled>
                <Divider />
              </MenuItem>
              <MenuItem onClick={handleDelete} >
                <ListItemIcon>
                      <DeleteIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Supprimer</Typography>
              </MenuItem>
          </Menu>
      </React.Fragment>
    );
      
  }
  const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    ui:state.UI
  });
  const mapActionsToProps = {}
  export default connect(mapStateToProps,mapActionsToProps)(CustomMenu)
