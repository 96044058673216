import React,{useState,useReducer,useEffect} from "react";
import {reduxForm,change,getFormValues } from 'redux-form';
import {connect} from 'react-redux'
import FormationFonctions from 'pages/Banking/Formation/FormationFonctions'
import ProduitDiscover from 'pages/Banking/Formation/ProduitDiscover'
import MiniHeader from "components/MiniHeader";
function FormationHome(props){
    const [fonctionnalite,setFonctionalite]=useState('HOME')
    const handleCliqueFonction = element=>{
        setFonctionalite(element)
    }
    const [details,setDetails] =useReducer((state,newSate)=>({...state,...newSate}),{
        produit:'',
        qr:[],
        tb:0
      })    
    useEffect(() => {
        document.title ="ENOV - Formation"
    })
    const handleDetails = (qr,produit,fonct,tb)=>{
        setDetails({
            ['produit']:produit,
            ['qr']:qr,
            ['tb']:tb
        })
        setFonctionalite(fonct)
    }
      return(
        <div>
            <MiniHeader titre="Apprendre" />
            {fonctionnalite==='HOME'&&  <FormationFonctions handleDetails={handleDetails} history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {fonctionnalite==='DISCOV'&&  <ProduitDiscover handleDetails={handleDetails} qr={details.qr} prod={details.produit} tb={details.tb} history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(FormationHome))
  
