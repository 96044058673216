import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm, change, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import DoneIcon from '@material-ui/icons/Done';
import { Divider } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { operationStatByUser,updateOperation } from 'redux/actions/apiActions'
import { formatNumber } from 'application'
import OperationProdList from "./OperationProdList";
import OperationProdDetails from "./OperationProdDetails";
import OperationValidate from "./OperationValidate";
import FicheResumeOperation from "./FicheResumeOperation";
import RejetForm from 'pages/Producteur/Operations/RejetForm';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import OperationJournal from "pages/Commons/Journaux/OperationJournal";
import OperationRapport from "pages/Commons/Rapport/OperationRapport";
import OperationExport from "./OperationExport";
import OperationDash from "./OperationDash";
import OperationRapportAdd from "pages/Commons/Rapport/OperationRapportAdd";
const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 20,
        '& :hover': {
            background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
            border: 0,
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            padding: '10 30px',
            textDecoration: 'none'
        }
    },
    chip: {
        margin: theme.spacing(0.5),
        fontSize: 20
    },
    media: {
        height: 140,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight:20
    },
    line: {
        marginBottom: 20
    },
    buttonDetails: {
        width: '100%',
        borderWidth: 1,
        borderColor: theme.palette.secondary.main,
        backgroundColor: '#fff',
        color: theme.palette.secondary.main,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    actionContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:20,
        flexWrap:'wrap',
        marginBottom:20,
    },
    btnadd:{
        display:'flex',
        paddingLeft:20,
        paddingRight:20,
        paddingTop:10,
        paddingBottom:10,
        justifyContent:'space-between',
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        alignItems:'center',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'#fff',
        }

    },
}));

function OperationHome(props) {
    const classes = useStyles();
    const [currentStatSin, setCurrentStatSin] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatPres, setCurrentStatPres] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [currentStatMod, setCurrentStatMod] = useReducer((state, newState) => ({ ...state, ...newState }), {})
    const [etats, setEtates] = useReducer((state, newState) => ({ ...state, ...newState }), {
        etape: null,
        mode: null
    })
    const [currentView, setCurrentView] = useState("HOME")
    const handleCurrentView = (myView) => {
        setCurrentView(myView)
    }
    useEffect(() => {
        //Etat des sinistre
        operationStatByUser({ groupe: "ETAPE", groupeoperation: "SIN" }).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => item.Libelle === 'nonTransmis')
                const nonTransmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'transmis')
                const transmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'accepter')
                const accepter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'rejeter')
                const rejeter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                setCurrentStatSin({
                    ['nonTransmis']: nonTransmis,
                    ['transmis']: transmis,
                    ['accepter']: accepter,
                    ['rejeter']: rejeter,
                })
            }
        })
        //Etat des prestation
        operationStatByUser({ groupe: "ETAPE", groupeoperation: "PRES" }).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => item.Libelle === 'nonTransmis')
                const nonTransmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'transmis')
                const transmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'accepter')
                const accepter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'rejeter')
                const rejeter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                setCurrentStatPres({
                    ['nonTransmis']: nonTransmis,
                    ['transmis']: transmis,
                    ['accepter']: accepter,
                    ['rejeter']: rejeter,
                })
            }
        })
        //Etat des Modifications
        operationStatByUser({ groupe: "ETAPE", groupeoperation: "MOD" }).then(res => {
            if (res) {
                let dataEl
                dataEl = res.filter(item => item.Libelle === 'nonTransmis')
                const nonTransmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'transmis')
                const transmis = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'accepter')
                const accepter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                dataEl = res.filter(item => item.Libelle === 'rejeter')
                const rejeter = (dataEl && dataEl.length !== 0) ? dataEl[0]['Nombre'] : 0
                setCurrentStatMod({
                    ['nonTransmis']: nonTransmis,
                    ['transmis']: transmis,
                    ['accepter']: accepter,
                    ['rejeter']: rejeter,
                })
            }
        })
    }, [])
    const showList = (mode, etap, v) => {
        setEtates({
            ['etape']: etap ? etap : null,
            ['mode']: mode ? mode : null
        })
        handleCurrentView(v)
    }
    const handleSaveOperation = (values) => {
        if (values) {
            let mesDonnees
            mesDonnees = JSON.stringify(values, null, 2)
            mesDonnees = JSON.parse(mesDonnees)
            //setLoading(true)
            updateOperation(mesDonnees).then(async (res) => {
                if (res) {
                    /*setCppen(false)
                    setSvData(res)
                    setCurrentData(res)
                    setLoading(false)
                    handleAlertOpen("Enregistrement effectué avec succès", true, handleTOpen)*/
                } else {
                    /*
                    setLoading(false)
                    handleCclose()
                    handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)*/
                }
            }).catch(err => {
                /*
                console.log(err);
                setLoading(false)
                handleCclose()
                handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)*/
            })
        }
    }
    const [operData,setOperData] = useState([])
    const handleSetData = (dt)=>{
        setOperData(dt)
    }
    const handleUpdateOperation = (dat,mod)=>{
        updateOperation({}).then(res=>{
            
        })
    }
    
    const handleNextOperation = (dataList,currentdata) =>{

    }
    const [ropen,setRopen] = useState(false)
    const handleRClose =()=>{
        setRopen(false)
    }
    const handleROpen=()=>{

    }
    return (
        <>
        {ropen && <RejetForm 
                        open={ropen} 
                        handleClose={handleRClose} 
                        data={operData} 
                        handleSaveOperation={handleSaveOperation} />}

            {currentView === "HOME" && <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Typography variant='h5'>STATISTIQUES</Typography>
                    <Divider light className={classes.line} />
                    <div className={classes.actionContainer}>
                        <Button className={classes.btnadd} onClick={()=>handleCurrentView('ODASH')}>
                           <Avatar aria-label="recipe" className={clsx(classes.large, classes.pink)}><DashboardIcon /></Avatar>
                           <Typography align='center' gutterBottom variant="h5" component="h5">Tableau de bord</Typography>
                        </Button>
                        <Button className={classes.btnadd} onClick={()=>handleCurrentView('JOURNAL')}>
                           <Avatar aria-label="recipe" className={clsx(classes.large, classes.pink)}><FormatListBulletedIcon /></Avatar>
                           <Typography align='center' gutterBottom variant="h5" component="h5">Journaux</Typography>
                        </Button>
                        <Button className={classes.btnadd} onClick={()=>handleCurrentView('RAPPORT')}>
                           <Avatar aria-label="recipe" className={clsx(classes.large, classes.pink)}><EqualizerIcon /></Avatar>
                           <Typography align='center' gutterBottom variant="h5" component="h5">Rapport</Typography>
                        </Button>
                        <Button className={classes.btnadd} onClick={()=>handleCurrentView('EXPORT')}>
                           <Avatar aria-label="recipe" className={clsx(classes.large, classes.pink)}><ImportExportIcon /></Avatar>
                           <Typography align='center' gutterBottom variant="h5" component="h5">Exporter</Typography>
                        </Button>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Typography varaiant='h2' component='h2'>SINISTRES</Typography>
                    <Divider light className={classes.line} />
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatSin.hasOwnProperty('nonTransmis') && currentStatSin.nonTransmis !== 0) ? <Chip
                                                label={formatNumber(currentStatSin.nonTransmis)}
                                                className={classes.chip}
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar>
                                            }

                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            Saisie non transmis
                                        </Typography>
                                        <Button onClick={(e) => showList("SIN", 1, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatSin.hasOwnProperty('transmis') && currentStatSin.transmis !== 0) ? <Chip
                                                label={formatNumber(currentStatSin.transmis)}
                                                className={classes.chip}
                                                color="primary"
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><ThumbUpIcon /></Avatar>}
                                        </div>

                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            En attente d'acceptation
                                        </Typography>
                                        <Button onClick={(e) => showList("SIN", 2, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatSin.hasOwnProperty('accepter') && currentStatSin.accepter !== 0) ? <Chip
                                                label={formatNumber(currentStatSin.accepter)}
                                                className={classes.chip}
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><DoneIcon /></Avatar>}
                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            Opérations Acceptées
                                        </Typography>
                                        <Button onClick={(e) => showList("SIN", 3, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Typography varaiant='h2' component='h2'>PRESTATIONS</Typography>
                    <Divider light className={classes.line} />
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatPres.hasOwnProperty('nonTransmis') && currentStatPres.nonTransmis !== 0) ? <Chip
                                                label={formatNumber(currentStatPres.nonTransmis)}
                                                className={classes.chip}
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar>
                                            }

                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            Saisie non transmis
                                        </Typography>
                                        <Button onClick={(e) => showList("PRES", 1, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatPres.hasOwnProperty('transmis') && currentStatPres.transmis !== 0) ? <Chip
                                                label={formatNumber(currentStatPres.transmis)}
                                                className={classes.chip}
                                                color="primary"
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><ThumbUpIcon /></Avatar>}
                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            En attente de validations
                                        </Typography>
                                        <Button onClick={(e) => showList("PRES", 2, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatPres.hasOwnProperty('accepter') && currentStatPres.accepter !== 0) ? <Chip
                                                label={formatNumber(currentStatPres.accepter)}
                                                className={classes.chip}
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><DoneIcon /></Avatar>}
                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            Acceptées
                                        </Typography>
                                        <Button onClick={(e) => showList("PRES", 3, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Typography varaiant='h2' component='h2'>DEMANDES DE MODIFICATION</Typography>
                    <Divider light className={classes.line} />
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatMod.hasOwnProperty('nonTransmis') && currentStatMod.nonTransmis !== 0) ? <Chip
                                                label={formatNumber(currentStatMod.nonTransmis)}
                                                className={classes.chip}
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassFullIcon /></Avatar>
                                            }

                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            Saisie non transmis
                                        </Typography>
                                        <Button onClick={(e) => showList("MOD", 1, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatMod.hasOwnProperty('transmis') && currentStatMod.transmis !== 0) ? <Chip
                                                label={formatNumber(currentStatMod.transmis)}
                                                className={classes.chip}
                                                color="primary"
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><ThumbUpIcon /></Avatar>}
                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            En attente de validations
                                        </Typography>
                                        <Button onClick={(e) => showList("MOD", 2, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4} lg={4}>
                            <Card className={classes.root}>
                                <CardActionArea>
                                    <CardContent>
                                        <div className={classes.avatar}>
                                            {(currentStatMod.hasOwnProperty('accepter') && currentStatMod.accepter !== 0) ? <Chip
                                                label={formatNumber(currentStatMod.accepter)}
                                                className={classes.chip}
                                            /> : <Avatar aria-label="recipe" className={clsx(classes.large)}><DoneIcon /></Avatar>}
                                        </div>
                                        <Typography align='center' gutterBottom variant="h6" component="h6">
                                            Acceptées
                                        </Typography>
                                        <Button onClick={(e) => showList("MOD", 3, 'LISTOP')} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>}
            {currentView === "LISTOP" && <OperationProdList handleSetData={handleSetData} etats={etats} handleCurrentView={handleCurrentView} />}
            {currentView === "DETAIL" && <OperationProdDetails  handleSetData={handleSetData} handleCurrentView={handleCurrentView} />}
            {currentView === "FICHE" && <FicheResumeOperation  handleSetData={handleSetData} handleUpdateOperation={handleUpdateOperation} handleNextOperation={handleNextOperation} handleCurrentView={handleCurrentView} dataForm={operData} />}
            {currentView === "VALIDATE" && <OperationValidate  handleSetData={handleSetData}  handleUpdateOperation={handleUpdateOperation} operData={operData} handleCurrentView={handleCurrentView} />}
            {currentView=="JOURNAL" && <OperationJournal  handleSetData={handleSetData} handleUpdateOperation={handleUpdateOperation} operData={operData} handleCurrentView={handleCurrentView}/> }
            {currentView=="RAPPORT" &&<OperationRapport  handleSetData={handleSetData} handleUpdateOperation={handleUpdateOperation} operData={operData} handleCurrentView={handleCurrentView} />}
            {currentView=="RAPPORTNEW" &&<OperationRapportAdd  handleSetData={handleSetData} handleUpdateOperation={handleUpdateOperation} operData={operData} handleCurrentView={handleCurrentView} />}
            {currentView=="EXPORT" &&<OperationExport  handleSetData={handleSetData} handleUpdateOperation={handleUpdateOperation} operData={operData} handleCurrentView={handleCurrentView} />}
            {currentView=="ODASH" &&<OperationDash handleSetData={handleSetData} handleUpdateOperation={handleUpdateOperation} operData={operData} handleCurrentView={handleCurrentView} />}
        </>

    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(OperationHome))