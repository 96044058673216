import React ,{useState,useEffect} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from "components/CustomButtons/Button.js";
import { withStyles } from '@material-ui/core/styles';
import InfoslModalFormBenef from './components/InfoslModalFormBenef'
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { connect, } from 'react-redux';
import { Field, reduxForm,getFormValues,change,registerField } from 'redux-form'
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import AddIcon from '@material-ui/icons/Add';
import { green } from '@material-ui/core/colors';
import dayjs from 'dayjs'
import { formatNumber } from 'application';

const styles = theme =>({
  root: {
    backgroundColor:theme.palette.primary.main,
    borderRadius:50,
    border: 1,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    marginBottom:20,
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      boxShadow: '0 3px 5px 2px rgba(6, 6, 6, .3)',
    }
  },
  form:{
    maxHeight: "100%",
  },
    table: {
        minWidth: 650,
      },
      btnAdd:{
        backgroundColor:theme.palette.primary.main,
        borderRadius:20,
        color:'white',
        '&:hover':{
          backgroundColor:theme.palette.secondary.main,
          color:'black'
        }
      },
      btnAction:{
        width:80,
        height:80,
        backgroundColor:'#fff',
        color:theme.palette.primary.main,
        '&:hover':{
          backgroundColor:theme.palette.primary.main,
          color:theme.palette.primary.contrastText
        }
      },
      btnNext:{
        backgroundColor:theme.palette.primary.main,
        height:40,
        width:150,
        borderRadius:20,
        color:theme.palette.primary.contrastText
      },
      paper:{
        padding:'20px',
    },
    heading: {
      fontSize:26,
      fontWeight: 'bold',
    },
    btnnext:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:green[400],
      paddingRight:20,
      paddingLeft:20,
      paddingTop:10,
      paddingBottom:10,
      color:'#ffffff',
      "&:hover":{
        backgroundColor:"#f9b233",
      },
      borderRadius:10,
    },
    btnback:{
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:"#f9b233",
      paddingRight:20,
      paddingLeft:20,
      paddingTop:10,
      paddingBottom:10,
      color:'#ffffff',
      "&:hover":{
        backgroundColor:"#3c3c3b",
      },
      borderRadius:10,
    },
})


  
 function InfosBeneficiaire(props) {
    const { handleSubmit, classes,data,handleNext,handleAttente,steps,activeStep } = props
    const [mesAssures,setMesAssures] = useState([])
    const [open, setOpen] = React.useState(false);
    const [moimemeBeneficiaire, setMoimemeBeneficiaire] = React.useState(false);
    const [modeBeneficaire,setModeBeneficaire] = React.useState('Tous');
    const [currentAd,setCurrentAd]=useState(null)
    const [cmode,setCmode] =useState('ADD')
    const [modeOpen,setModeOpen] = React.useState('autreBeneficaire')
        const handleOpen = () => {
          setOpen(true);
        };
    
        const handleClose = (event,reason) => {
          if (reason === 'backdropClick') {
            return
          }
          setOpen(false);
        };
        const handleMoimemeBeneficaire =(mytype=null)=>{
          const moimeme =!moimemeBeneficiaire;
          setMoimemeBeneficiaire(moimeme)
          if(moimeme){
           const currentBeneficiaire ={
              nomBeneficiaire: data.nomSous && data.nomSous, 
              prenomBeneficiaire: data.prenomSous && data.prenomSous,
              dateNaissanceBeneficiaire: data.dateNaissanceSous && data.dateNaissanceSous,
              lieuNaissanceBeneficiaire: data.lieuNaissanceSous && data.lieuNaissanceSous,
              lieuResidenceBeneficiaire: data.lieuResidenceSous && data.lieuResidenceSous,
              filiationBeneficiaire: 'Moi même',
              emailBeneficiaire: data.emailSous && data.emailSous,
              telephoneBeneficiaire: data.mobileSous && data.mobileSous,
              taux:'100',
              monIndex: (mesAssures.length+1),
              type:mytype
            } 
            let newbeneficiaires =  mesAssures
            newbeneficiaires =[...newbeneficiaires,currentBeneficiaire]
            props.updateField('beneficiaires',newbeneficiaires)
            setMesAssures(newbeneficiaires)
          }else{
            setModeOpen('autreBeneficiaire')
          }
        }
        const handleAddbenegroupe = (mesdonne)=>{
          if(currentAd && currentAd.hasOwnProperty('indice') && mesdonne){
            const cbeneficiaires = currentAd.hasOwnProperty('beneficiaires') ? [...currentAd.beneficiaires]:[]
            const allAdherent = [...data.adherentconex]
            const newBeneficaire = [...cbeneficiaires,mesdonne]
            currentAd.beneficiaires = newBeneficaire
            allAdherent[currentAd.indice] = currentAd
            props.updateField('adherentconex',allAdherent)
            if(currentAd.indice ===0){
              mesdonne.type =modeBeneficaire
              props.updateField('beneficiaires',[mesdonne])
              if(currentAd.indice ===0){
                setMesAssures([mesdonne])
              }
              
            }
          }
          setCmode('MOD')
          handleClose()
        }
        const deleteBeneficiairegroupe =(adherent,beneficiaire,ind)=>{
          if(adherent && beneficiaire && ind){
            const newad = adherent.beneficiaires.splice(ind,1)
            const allAdherent = [...data.adherentconex]
            allAdherent[currentAd.indice] = currentAd
            props.updateField('adherentconex',allAdherent)
          }
        }

        const handleAddAssure =(mesdonne)=>{
          if(mesdonne){
            mesdonne.type =modeBeneficaire
            let newbeneficiaires = mesAssures
            newbeneficiaires= [...newbeneficiaires,mesdonne]
            props.updateField('beneficiaires',newbeneficiaires)
            setMesAssures(newbeneficiaires)
            setCmode('MOD')
            handleClose()
          }  
        }
        const deletebeneficaire =(assure)=>{
            let crurrentAsuree = mesAssures   
            crurrentAsuree =  crurrentAsuree.filter((item=>item.monIndex!==assure.monIndex))
            props.updateField('beneficiaires',crurrentAsuree)
            setMesAssures(crurrentAsuree)
        }
     
        useEffect(() => {
          if(!data.hasOwnProperty('beneficiaires')){
            props.dispatch(registerField("addPret",'beneficiaires','Field'));
            props.updateField('beneficiaires',mesAssures)
          }else{
            if(mesAssures.length===0){
              setMesAssures(data.beneficiaires)
            }
          }
        }, [mesAssures])
   
      const renderBeneficiaire =(props)=>{
        const benefs =[...props.input.value] 
        return(benefs.length!==0? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" >Nom</TableCell>
                  <TableCell align="center" >Prénoms</TableCell>
                  <TableCell align="center">Né(e) le</TableCell>
                  <TableCell align="center">Lieu de naissance</TableCell>
                  <TableCell align="center">Lieu de residence</TableCell>
                  <TableCell align="center">Filiation</TableCell>
                  <TableCell align="center">Téléphone</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Taux (%)</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {benefs.map(benef => (
                  <TableRow key={benef.nomBeneficiaire}>
                    <TableCell align="center" component="th" scope="row">{benef.nomBeneficiaire}</TableCell>
                    <TableCell align="center">{benef.prenomBeneficiaire}</TableCell>
                    <TableCell align="center">{dayjs(benef.dateNaissanceBeneficiaire.toString()).isValid()?dayjs(benef.dateNaissanceBeneficiaire).format('DD/MM/YYYY'):""}</TableCell>
                    <TableCell align="center">{benef.lieuNaissanceBeneficiaire}</TableCell>
                    <TableCell align="center">{benef.lieuResidenceBeneficiaire}</TableCell>
                    <TableCell align="center">{benef.filiationBeneficiaire}</TableCell>
                    <TableCell align="center">{benef.telephoneBeneficiaire}</TableCell>
                    <TableCell align="center">{benef.emailBeneficiaire}</TableCell>
                    <TableCell align="center">{benef.taux}</TableCell>
                    <TableCell align="center">
                    <IconButton
                        data-id={benef.numeroCniAssure}
                        aria-label="actions"
                        aria-haspopup="true"
                        onClick={()=>deletebeneficaire(benef)}
                      >
                      <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
              ))}
              </TableBody>
            </Table>
        </TableContainer>):((!data.beneficiaireaudeces)?<Typography>Prière renseigner un bénéficiaire </Typography>:null)
      )}
     
      const handleOpenGroupe =(current)=>{
        setCurrentAd(current)
        setCmode('ADD')
        handleOpen()
        
      }


    return (
      <> <InfoslModalFormBenef cmode={cmode} setCmode={setCmode} handleClose={handleClose} handleOpen={handleOpen} open={open} handleAddAssure={data.typeadherent=='Groupe' ? handleAddbenegroupe:handleAddAssure} />

         <form onSubmit={handleSubmit(handleNext)} className={classes.form}>
              <Paper elevation={0} className={classes.paper}>
                  <Typography className={classes.heading}>Etape {activeStep?(activeStep+1):1} : {(steps)?steps[activeStep]:""}</Typography>
                  <Divider />

                  {(data && data.typeadherent=='Groupe')&&(
                    <div className='flex flex-col gap-2 my-3'>
                        {data.adherentconex.map((adconnexe,indice)=>(
                          <Paper variant='outlined'>
                            <div className='flex flex-row items-center justify-between bg-slate-300'>
                                <div className='flex flex-col justify-center items-center '>
                                  <span>Adherent</span>
                                  <Typography variant='h5'>{`${adconnexe.nomSous} ${adconnexe.prenomSous}`}</Typography>
                                </div>
                                <div className='flex flex-col justify-center items-center'>
                                  <span>Prime</span>
                                  <Typography variant='h5'>{`${formatNumber(data.primeobseque)}`}</Typography>
                                </div>
                                <div className='flex flex-col justify-center items-center'>
                                  <span>Capital</span>
                                  <Typography variant='h5'>{`${formatNumber('1000000')}`}</Typography>
                                </div>
                            </div>
                            <div className='py-3 flex justify-center items-center'>
                                <Button variant='outlined' onClick={()=>handleOpenGroupe({...adconnexe,"indice":indice})} className='flex rounded-full bg-white text-primary-main hover:text-secondary-main'><AddIcon /> Ajouter un bénéficiaire</Button>
                            </div>
                            {(adconnexe.hasOwnProperty('beneficiaires') && adconnexe.beneficiaires.length!==0)&&(
                                <TableContainer component={Paper}>
                                  <Table className={classes.table} aria-label="simple table">
                                    <TableBody>
                                    {adconnexe.beneficiaires.map((benef,xd) => (
                                      <TableRow key={benef.nomBeneficiaire}>
                                          <TableCell align="center" component="th" scope="row">{benef.nomBeneficiaire}</TableCell>
                                          <TableCell align="center">{benef.prenomBeneficiaire}</TableCell>
                                          <TableCell align="center">{dayjs(benef.dateNaissanceBeneficiaire.toString()).isValid()?dayjs(benef.dateNaissanceBeneficiaire).format('DD/MM/YYYY'):""}</TableCell>
                                          <TableCell align="center">{benef.lieuNaissanceBeneficiaire}</TableCell>
                                          <TableCell align="center">{benef.lieuResidenceBeneficiaire}</TableCell>
                                          <TableCell align="center">{benef.filiationBeneficiaire}</TableCell>
                                          <TableCell align="center">{benef.telephoneBeneficiaire}</TableCell>
                                          <TableCell align="center">{benef.emailBeneficiaire}</TableCell>
                                          <TableCell align="center">{benef.taux}</TableCell>
                                          <TableCell align="center">
                                          <IconButton
                                              data-id={benef.numeroCniAssure}
                                              aria-label="actions"
                                              aria-haspopup="true"
                                              onClick={()=>deleteBeneficiairegroupe({...adconnexe,"indice":indice},benef,xd)}
                                            >
                                            <DeleteIcon />
                                            </IconButton>
                                          </TableCell>
                                      </TableRow>
                                    ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                            )}
                            
                          </Paper>
                        ))}              
                     </div> )}
                  {(data && data.typeadherent=='Individuel')&&(<>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center', margin:20}}>
                            <Button className ={classes.btnAdd}  onClick={handleOpen} variant="contained"> 
                              <AddIcon style={{fontSize:40}} />
                                Cliquez ici pour ajouter un bénéficiaire
                            </Button>
                        </div>
                        <Field 
                            name="beneficiaires"
                            component={renderBeneficiaire}
                        />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Field
                                  required
                                  disabled
                                  id="capitalSouscrit" 
                                  name="capitalSouscrit" 
                                  component={CustomSimpleSelect} 
                                  label="Capital reversé au terme"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  InputProps={{
                                    readonly:"readonly"
                                  }}
                                  options={[1000000,1500000,2000000]}
                              />
                            </GridItem> 
                        </GridContainer>
                  </>)}

                    <Divider component="hr" className={classes.btnback}/>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',margin:20}}>        
                      <Button type="button" onClick={props.handleBack} variant="contained">
                        Retour
                      </Button>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleSubmit(handleAttente)}
                        className={classes.button}
                        variant="contained"
                        color="info"
                      >
                        Mettre en attente
                      </Button>
                      <Button type= 'submit' className={classes.btnnext}  disabled={((data.hasOwnProperty('beneficiaireaudeces') && data.beneficiaireaudeces?.length!==0)|| (data.hasOwnProperty('beneficiaires') && data.beneficiaires?.length!==0))?false:true}>
                        Continuer
                        <NavigateNextOutlinedIcon /> 
                      </Button>
                    </div>       
            </Paper>
            
            
      </form> 
      </>
     
      
    );
  }

  
  const updateField =(field, data)=>(dispatch)=>dispatch(change('addPret', field, data))
  const mapActionsToProps = {
    updateField
  }
  const mapStateProps =(state)=>({
    data:getFormValues('addPret')(state),
  })


  InfosBeneficiaire =withStyles(styles)(InfosBeneficiaire)
  
  export default connect(mapStateProps,mapActionsToProps)(reduxForm({form: 'addPret',destroyOnUnmount:false})(InfosBeneficiaire))
