import React, { useReducer, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import MaterialTable, { MTableToolbar } from 'material-table';
import { connect, useDispatch } from 'react-redux'
import CustomMenu from './CustomMenu'
import { Add } from '@material-ui/icons';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'

import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { addModule, getModulesReseau, AssocierModuleReseau, addPrivilegeModule, getPrivilegeModule, deleteModuleReseau } from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const ShowPrivilegeModule = (props) => {
    const classes = useStyles();
    const { onClose, open, module, privileges } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = () => {
        onClose();
    };


    return (

        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} style={{ padding: 20 }}>
            <DialogTitle id="simple-dialog-title">Droits & Privilèges</DialogTitle>
            {privileges.length !== 0 ? (<List>
                {privileges.map((privilege) => (
                    <ListItem key={privilege.codeprivilege}>
                        <ListItemText primary={privilege.libelle} />
                    </ListItem>
                ))}
            </List>) : (
                <Typography variant="subtitle1">Aucun droit défini</Typography>
            )}

        </Dialog>)
}
function ModulesReseau(props) {
    const classes = useStyles();
    const theme = useTheme();
    //intialisation des élement du state
    const { reseauData, mopen, handleCloseMod, handleMopen, allModules, allPrivilege } = props //recuperation des valieur du props

    const [modulesReseau, setModulesReseau] = React.useState([]); //Etat qui gère les module lié au reseau
    const [newModuleStep, setNewModuleStep] = React.useState(1); //gère les étape d'ajout d'un nouveau module
    const [newModule, setNewModule] = React.useState(false); //gère l'etat nouveau mouveau module
    const [modChecked, setModChecked] = React.useState([]); // géré la selection de module existant
    //assignation des module sélectionnées
    const handleCheked = (rows) => {
        setModChecked(rows)
    }
    useEffect(() => {
        getModulesReseau(reseauData.id).then(data => { if (data) { setModulesReseau(data); } })
    }, [])

    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            codemodule: "",
            libelle: "",
            icon: "",
            url: "",
            codereseau: reseauData.id
        });

    const handleChange = (event) => {
        const { name, value } = event.target
        setInputField({
            [name]: value
        })
    }

    const handleSaveModule = (event) => {
        if (inputField.codereseau === '') return
        if (inputField.codemodule.trim() === '') return
        if (inputField.libelle.trim() === '') return
        props.addModule(inputField).then(dataMod => {
            if (dataMod) {
                getModulesReseau(reseauData.id).then(data => { if (data) { setModulesReseau(data); } })
                setInputField({
                    ['codemodule']: '',
                    ['libelle']: '',
                    ['icon']: '',
                    ['url']: ''
                })
                setInputPrivilege({
                    ['codemodule']: dataMod.id
                })
                setNewModuleStep(2)
            } else {
                return false
            }
            return
        }).catch(err => {
            console.log(err);
            return
        })
        event.stopPropagation()
        return
    }
    const handleAssosierModule = () => {
        if (!newModule && modChecked.length !== 0) {
            props.AssocierModuleReseau(reseauData.id, modChecked).then((modData) => {
                if (modData) {
                    getModulesReseau(reseauData.id).then(data => { if (data) { setModulesReseau(data); } })
                    setModChecked([])
                } else {
                    return false
                }
            }).catch(err => {
                console.log(err);
                return
            })
        } else {
            return false
        }
    }

    //supression des modules ratacher au reseau
    const handleDeleteModuleReseau = (row) => {
        deleteModuleReseau(row.id, reseauData.id).then(mydata => {
            if (mydata) {
                getModulesReseau(reseauData.id).then(data => { if (data) { setModulesReseau(data); } })
            }
        })
    }

    //fonctions et etats lié au privilèges
    const [inputPrivilege, setInputPrivilege] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            codeprivilege: "",
            libelle: "",
            codemodule: ""
        });
    const handleChangePrivilege = (event) => {
        const { name, value } = event.target
        setInputPrivilege({
            [name]: value
        })
    }
    const [priChecked, setPriChecked] = React.useState([])
    const handlePrivilegeCheked = (rows) => {
        setPriChecked(rows)
    }
    const [modulePrivileges, setModulePrivilege] = React.useState([])
    const handleAddPrivilege = () => {
        if (inputPrivilege.codeprivilege !== '' && inputPrivilege.libelle !== '') {
            const newPrivilege = modulePrivileges
            const monindex = newPrivilege.findIndex(item => item.codeprivilege === inputPrivilege.codeprivilege)
            if (monindex === -1) {
                setModulePrivilege([...newPrivilege, { codeprivilege: inputPrivilege.codeprivilege, libelle: inputPrivilege.libelle }])
                setInputPrivilege({
                    ['codeprivilege']: '',
                    ['libelle']: '',
                })
            }
        }
        //ajout chècked liste
        if (priChecked.length !== 0) {
            const newPrivilege = modulePrivileges
            const myPrivileges = newPrivilege.filter(function (el) {
                if ((priChecked.findIndex(item => item.codeprivilege === el.codeprivilege)) === -1) {
                    return true
                } else {
                    return false
                }
            });
            const privilegeOk = myPrivileges.concat(priChecked)
            setModulePrivilege(privilegeOk)
        }
    }
    const savePrivilege = () => {
        if (inputPrivilege.codemodule === '') return
        addPrivilegeModule(inputPrivilege.codemodule, modulePrivileges).then(prilegesdata => {
            if (prilegesdata) {
                setModulePrivilege([])
                setNewModuleStep(1)
            }
        })
    }
    //supression de privilège ()
    const removePrivilege = (refPrivilege) => {
        const newprivilege = modulePrivileges.filter(item => item.codeprivilege !== refPrivilege)
        setModulePrivilege(newprivilege)
    }

    const [selPrivilegeModule, setSelPrivilegeModule] = React.useState([]);
    const [openPri, setOpenPri] = React.useState(false)
    const handleOpenPrivilegeModule = (row) => {
        getPrivilegeModule(row.id).then(dataPri => {
            if (dataPri) {
                setSelPrivilegeModule(dataPri)
                setOpenPri(true)
            }
        })
    }
    const hClosePrivileModule = () => {
        setOpenPri(false)
    }
    //fonctions gestion du rendu des différents écrans
    const renderPrivilegeStep = () => (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h6" className={classes.title}>Ajouter des privilèges au module #{inputPrivilege.codemodule}</Typography>
            </GridItem>
            <GridItem xs={6} sm={6} md={6} lg={6}>

                <LabelledOutline label="Nouveau privilège">

                    <GridContainer>
                        <GridItem xs={12} sm={5} md={5} lg={5}>
                            <CustomInput
                                required
                                autoFocus
                                variant="outlined"
                                id="codeprivilege"
                                name='codeprivilege'
                                label="code"
                                type="text"
                                value={inputPrivilege.codeprivilege}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                onChange={handleChangePrivilege}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={7} md={7} lg={7}>
                            <CustomInput
                                autoFocus
                                required
                                variant="outlined"
                                id="libelle"
                                name='libelle'
                                onChange={handleChangePrivilege}
                                label="Libelle module"
                                type="text"
                                value={inputPrivilege.libelle}
                                formControlProps={{
                                    fullWidth: true
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </LabelledOutline>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Button color="primary" onClick={handleAddPrivilege} disabled={!((inputPrivilege.libelle !== '' && inputPrivilege.codeprivilege !== '') || priChecked.length !== 0)} style={{ width: '100%' }}><ArrowForwardIosIcon /></Button>
                    </GridItem>


                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <MaterialTable
                            columns={[
                                { title: '#', field: 'codeprivilege', hidden: true },
                                { title: 'Libelle', field: 'libelle' },
                            ]}
                            data={allPrivilege}
                            title="Choisir dans la liste"
                            localization={{
                                body: {
                                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                                },
                                pagination: {
                                    labelRowsSelect: 'Ligne(s)',
                                    labelDisplayedRows: '{count} sur {from}-{to}',
                                    firstTooltip: 'Prémière page',
                                    previousTooltip: 'Précédent',
                                    nextTooltip: 'Suivant',
                                    lastTooltip: 'Dernière page'
                                }
                            }}
                            options={{
                                selection: true,
                                header: false,
                                paging: false,
                                showTextRowsSelected: false,
                                showFirstLastPageButtons: false,
                                maxBodyHeight: 300,
                                search: false
                            }}
                            onSelectionChange={(rows) => handlePrivilegeCheked(rows)}

                        />
                    </GridItem>
                </GridContainer>

            </GridItem>

            <GridItem xs={6} sm={6} md={6} lg={6}>
                {modulePrivileges.length !== 0 && (
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                {modulePrivileges.map((privilege) => (
                                    <TableRow key={privilege.codeprivilege}>
                                        <TableCell>{privilege.codeprivilege}</TableCell>
                                        <TableCell>{privilege.libelle}</TableCell>
                                        <TableCell>
                                            <IconButton edge="start" color="inherit" onClick={() => removePrivilege(privilege.codeprivilege)} aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Button color="primary" onClick={savePrivilege} disabled={(modulePrivileges.length === 0)} style={{ width: '100%', marginBottom: 20 }}>Enregistrer</Button>
            </GridItem>
        </GridContainer>
    )
    const renderCheckedModule = () => (
        <GridContainer>
            {(allModules.length !== 0) ? (
                <>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <MaterialTable
                            columns={[
                                { title: '#', field: 'id', hidden: true },
                                { title: 'Libelle', field: 'libelle' },
                            ]}
                            data={allModules}
                            title="Selectionnez dans la liste"
                            localization={{
                                toolbar: {
                                    searchPlaceholder: 'Rechercher',
                                    searchTooltip: 'Rechercher'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                                },
                                pagination: {
                                    labelRowsSelect: 'Ligne(s)',
                                    labelDisplayedRows: '{count} sur {from}-{to}',
                                    firstTooltip: 'Prémière page',
                                    previousTooltip: 'Précédent',
                                    nextTooltip: 'Suivant',
                                    lastTooltip: 'Dernière page'
                                }
                            }}

                            options={{
                                searchFieldStyle: {
                                    fontSize: 18,
                                    width: '100%',
                                    height: 50,
                                },
                                selection: true,
                                header: false,
                                paging: false,
                                showTextRowsSelected: false,
                                showFirstLastPageButtons: false,
                                maxBodyHeight: 150
                            }}
                            onSelectionChange={(rows) => handleCheked(rows)}

                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Button color="primary" onClick={handleAssosierModule} disabled={(modChecked.length === 0)} style={{ width: '100%', marginBottom: 20 }}>Ajouter</Button>
                    </GridItem></>) : (
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Button color="primary" size='large' onClick={() => setNewModule(true)} style={{ width: '100%', marginBottom: 20 }}><Typography>NOUVEAU MODULE</Typography> <AddIcon fontSize="large" /></Button>
                </GridItem>
            )}
        </GridContainer>
    )

    return (
        <Dialog
            maxWidth='md'
            open={mopen}
            disableBackdropClick
            onClose={handleCloseMod}
            fullWidth
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseMod} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Gestion des modules - Reseau : - #{reseauData.libelle}
                    </Typography>
                    {newModule ? (<IconButton edge="start" fontSize='large' color="inherit" onClick={() => setNewModule(false)} aria-label="close">
                        <PlaylistAddCheckIcon style={{ fontSize: 40 }} />
                    </IconButton>) : (
                        <IconButton edge="start" fontSize='large' color="inherit" onClick={() => setNewModule(true)} aria-label="close">
                            <AddIcon style={{ fontSize: 40 }} />
                        </IconButton>)}
                </Toolbar>
            </AppBar>
            <DialogContent>
                <ShowPrivilegeModule privileges={selPrivilegeModule} open={openPri} onClose={hClosePrivileModule} />
                {(newModule && newModuleStep === 2) ? (
                    renderPrivilegeStep()
                ) : (
                    <>
                        <GridContainer>
                            {newModule ? (
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} md={4} lg={4}>
                                            <CustomInput
                                                required
                                                autoFocus
                                                variant="outlined"
                                                id="codemodule"
                                                name='codemodule'
                                                label="code"
                                                type="text"
                                                value={inputField.codemodule}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={handleChange}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={8} md={8} lg={8}>
                                            <CustomInput
                                                autoFocus
                                                required
                                                variant="outlined"
                                                id="libelle"
                                                name='libelle'
                                                onChange={handleChange}
                                                label="Libelle module"
                                                type="text"
                                                value={inputField.libelle}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={4} md={4} lg={4}>
                                            <CustomInput
                                                required
                                                autoFocus
                                                variant="outlined"
                                                id="icon"
                                                name='icon'
                                                label="Icon"
                                                type="text"
                                                value={inputField.icon}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                onChange={handleChange}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={8} md={8} lg={8}>
                                            <CustomInput
                                                autoFocus
                                                required
                                                variant="outlined"
                                                id="url"
                                                name='url'
                                                onChange={handleChange}
                                                label="URL"
                                                type="text"
                                                value={inputField.url}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                            <Button color="primary" onClick={handleSaveModule} disabled={(inputField.libelle === '' || inputField.codemodule === '')} style={{ width: '100%', marginBottom: 20 }}>Ajouter</Button>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>) : renderCheckedModule()}
                        </GridContainer>
                        <GridContainer>
                            <Divider />
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <MaterialTable
                                    columns={[
                                        { title: '#', field: 'id' },
                                        { title: 'Code', field: 'codemodule' },
                                        { title: 'Libelle', field: 'libelle' },
                                        { title: 'Icon', field: 'icon' },
                                        { title: 'URL', field: 'url' },
                                    ]}
                                    data={modulesReseau}
                                    title="Modules associés"
                                    localization={{
                                        toolbar: {
                                            searchPlaceholder: 'Rechercher',
                                            searchTooltip: 'Rechercher'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Aucune donnée trouvée !'
                                        },
                                        pagination: {
                                            labelRowsSelect: 'Ligne(s)',
                                            labelDisplayedRows: '{count} sur {from}-{to}',
                                            firstTooltip: 'Prémière page',
                                            previousTooltip: 'Précédent',
                                            nextTooltip: 'Suivant',
                                            lastTooltip: 'Dernière page'
                                        }
                                    }}

                                    options={{
                                        exportButton: true,
                                        searchFieldStyle: {
                                            fontSize: 18,
                                            width: '100%',
                                            height: 50,
                                        },
                                        actionsColumnIndex: -1
                                    }}
                                    actions={[
                                        {
                                            icon: 'gavel',
                                            tooltip: 'Privilèges',
                                            onClick: (event, rowData) => handleOpenPrivilegeModule(rowData)
                                        },
                                        rowData => ({
                                            icon: 'delete',
                                            tooltip: 'Supprimer',
                                            onClick: (event, rowData) => handleDeleteModuleReseau(rowData),
                                            disabled: rowData.birthYear < 2000
                                        })
                                    ]}

                                />
                            </GridItem>
                        </GridContainer>
                    </>
                )}

            </DialogContent>
        </Dialog>
    )
}
ModulesReseau.propTypes = {
    reseauData: PropTypes.object.isRequired,
    mopen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    allModules: state.settings.modules,
    allPrivilege: state.settings.allPrivilege,
});
const mapActionsToProps = { addModule, AssocierModuleReseau }
export default connect(mapStateToProps, mapActionsToProps)(ModulesReseau)