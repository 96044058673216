import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  IdentificationContrat,
  OperationType,
  DocumentsOperation,
  ResumeOperation
} from 'pages/Operations/components'
import { makeStyles } from "@material-ui/core/styles";
import { formatNumber,ficheOperation } from 'application'
import Divider from '@material-ui/core/Divider';
import { 
  getTypeOperationList, 
  addOperation, 
  uploadDocuments, 
  operationProduitPart, 
  getDetailActeursSinistre
} from 'redux/actions/apiActions'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { reduxForm, change, registerField, reset,getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux'
import AlertPage from 'components/AlertPage'
import OperaTransmission from './components/OperaTransmission';
import { Paper,Tooltip,IconButton,Typography,Box } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import dayjs from 'dayjs';
import MiniHeader from 'components/MiniHeader';
import { green } from '@material-ui/core/colors';

function getSteps() {
  return ['Identification du contrat', "Type d'operation", 'Documents exigés', 'Résumé']
}

const useStyles = makeStyles(theme=>({
  core: {
    display: 'flex',
    flexFlow:1,
    flexDirection:'row',
    justifyContent: 'space-between',
    flexWrap:'wrap'
  },
  rootchild:{
    width:'75%',
    marginLeft:'auto',
    marginRight:'auto',
    marginTop:75,
    position:'relative',
    [theme.breakpoints.down('md')]:{
      width:'85%',
    },
    [theme.breakpoints.down('sm')]:{
      width:'95%',
    },
    [theme.breakpoints.down('sx')]:{
      width:'100%',
    }
  },
  hPaper:{
    padding:10,
    marginBottom:20,
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth:8,
    display:'flex',
    justifyContent:'space-between'
  },
  hPaperup:{
    padding:10,
    marginBottom:20,
    borderLeftColor: "#f9b233",
    borderLeftWidth:8,
    display:'flex',
    justifyContent:'space-between'
  },
  hContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
  },
  bCorps:{
    display:'flex',
    justifyContent:'space-between',
    padding:20
  },
  detailsContainer:{
    display: "flex",
    flexDirection: "column",
    backgroundColor: green[100],
    color: "#fff",
    width: "100%",
    padding:20,
    marginTop:20,
    marginBottom:20,
    borderRadius:10
  },
  infosContainer:{
    display:'flex',
    flexDirection:'column'
  },
  infos:{
    display:'flex',
    flexDirection:'column',
    marginTop:20,
    marginBottom:20,
    color:"#666"
  },
  assureInfoRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  itemBox:{
    width: '100%',
    left:0,
    right:0,
    display:'flex',
    justifyContent:'space-around'
  }
}))


function AddOperation(props) {
  const { mode, api: { produits }, handleCliqueFonction,dataForm,user:{credentials} } = props
  let containerref = useRef()
  const dispatch = useDispatch()
  const [operationList, setOperationList] = React.useState(0)
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps(mode);
  const classes = useStyles();
  const [mesdocs, setMesDoct] = React.useState([])

  const chekedDefaultSin = ()=>{
    if(mode==='SIN' && (operationList && operationList.length!==0) && activeStep == 0){
      const currentOp = operationList.filter(item =>item.CodeTypeAvenant === '26')[0]
      if(currentOp){
        if (!props.hasOwnProperty('operation')) {
          props.dispatch(registerField("addOper", 'operation', 'Field'));
        }
        props.updateField('operation', currentOp)
        setActiveStep(2)
      }
    }
  }
  
  //Liste de opération type
  const handleOperationList = useCallback(async () => {
    if (!props.hasOwnProperty('groupeoperation')) {
      props.dispatch(registerField("addOper", 'groupeoperation', 'Field'));
    }
    props.updateField('groupeoperation', mode)
    let opeFilter 
    const type = (mode === 'MOD' ? "AVT" : "TECH")
    opeFilter = (mode === 'SIN') && "'26','34','SINSEN'"
    if(mode === "PRES" && credentials['codepartenaire']==='092'){
      if(dataForm && dataForm.hasOwnProperty('codeproduit')){
        const currentFIlter=[]
        const res = await operationProduitPart(dataForm['codeproduit'],credentials['codepartenaire'])
        if(res && !res.hasOwnProperty('autorise')){
          res.forEach(p=>currentFIlter.push(`'${p.codeoperation}'`))
          opeFilter = `${currentFIlter.join(',')}`
        }
      }else{
        opeFilter =  "'32', '35','24','23','31','44','41','40','22','36','27','37'" 
      } 
    }
   
    const op = await getTypeOperationList({ type, opeFilter })
    if (op) { setOperationList(op)}
  },[mode,dataForm])

  useEffect(() => {
    if(dataForm && dataForm.hasOwnProperty('codeproduit')){
      handleOperationList()
    }
  },[mode,dataForm])


  const handleNext = () => {
    containerref.current.offsetParent.scrollTo({ behavior: 'smooth', top: 0 })
    setActiveStep(prevActiveSep => prevActiveSep + 1);
    chekedDefaultSin()
  }

  const handleBack = () => {
    containerref.current.offsetParent.scrollTo({ behavior: 'smooth', top: 0 })
    setActiveStep(prevActiveSep => prevActiveSep - 1);
  }

  const handleReset = () => {
    containerref.current.offsetParent.scrollTo({ behavior: 'smooth', top: 0 })
    setActiveStep(0);
  }


  const [loading, setLoading] = useState(false)
  const [alertInfo, setAlertInfo] = useState({})
  const handleAlertClose = (action = null) => {
    setAlertInfo({ open: false, message: "", succes: false })
    action && action()
  }
  const handleAlertOpen = (message, succes = false, action) => {
    setAlertInfo({ open: true, message: message, succes: succes, action: action })
  }
  const [svData, setSvData] = useState(null)
  const [topen, setTopen] = useState(false)
  const handleTOpen = () => {
    setTopen(true)
  }
  const handleTClose = () => {
    setTopen(false)
    setSvData(false)
    handleAlertClose()
  }
  const handleRedirectOperation = (data) => {
    setActiveStep(0)
    dispatch(reset('addOper'))
    handleCliqueFonction('HOME')
    // history.push('/bankass/suivi-operations', { operation: data });
  }
  
  const handleSaveOperation = (values) => {
    if (values) {
      let mesDonnees
      mesDonnees = JSON.stringify(values, null, 2)
      mesDonnees = JSON.parse(mesDonnees)
      mesDonnees.etape = 1
      mesDonnees.delaitraitement = mesDonnees['operation']['DelaiTraitement']
      mesDonnees.datefinprevu = mesDonnees['operation']['DelaiTraitement']? dayjs().add(parseInt(mesDonnees['operation']['DelaiTraitement']), 'day').format('DD/MM/YYYY'):null
      setLoading(true)
      addOperation(mesDonnees).then(async (res) => {
        if (res) {
          if (mesdocs && mesdocs.length !== 0) {
            await uploadDocuments(res.id, mesdocs, 'OP')
          }
          setSvData(res)
          setLoading(false)
          handleAlertOpen("Enregistrement effectué avec succès", true, handleTOpen)
          ficheOperation(res,credentials)
        } else {
          setLoading(false)
          handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)
        }
      }).catch(err => {
        console.log(err);
        setLoading(false)
        handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)
      })
    }
  }
  const handleShowHome =()=>{
    setActiveStep(0)
    dispatch(reset('addOper'))
    handleCliqueFonction('HOME')
  }

  const [infoAssur,setInfoAssur] = useState(null)
  useEffect(()=>{
    const handleGetAssure = () => {
      if(dataForm && dataForm.hasOwnProperty('codeproposition')){
        getDetailActeursSinistre({ type: 'ASSP', proposition: dataForm.codeproposition }).then(dataRes => {
          if(dataRes){
            setInfoAssur(dataRes) 
          }
        })
      }
    }
    handleGetAssure()
  },[dataForm])

  return <div><MiniHeader titre="Gestion des prestations" showbtn={false} plateforme='prestation'/>
  <div ref={containerref} className={classes.rootchild} >
    <AlertPage open={alertInfo['open'] || false} action={alertInfo['action'] || null} message={alertInfo['message'] || ""} succes={alertInfo['succes'] || false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
 
        <Paper className={mode==='UP'?classes.hPaperup:classes.hPaper} variant="outlined">
            <div style={{display:'flex'}}>
                <Tooltip title="Retour"> 
                    <IconButton onClick={handleShowHome} color='primary'>
                        <HomeIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
                <div className={classes.hContainer}>
                    <Typography variant='h5'>{mode==='SIN'?`Nouvelle déclaration de sinistre`:(mode=='PRES')?"Nouvelle demande de prestation":"Nouvelle demande modification"}</Typography>
                </div>
            </div>
            <Tooltip title="Retour"> 
                <IconButton onClick={handleShowHome} color='primary'>
                    <RotateLeftIcon style={{fontSize:40}} />
                </IconButton>
            </Tooltip>
        </Paper>

    <div style={{ display:'flex', justifyContent:'center'}}>
      
        <div className={classes.itemBox}>
          <div style={{flex:2,marginRight:20}}>
            {activeStep === 0 && <IdentificationContrat operations={operationList} handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep} handleReset={handleReset} handleShowHome={handleShowHome} mode={mode} />}
            {activeStep === 1 && <OperationType operations={operationList} handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep} handleReset={handleReset} />}
            {activeStep === 2 && <DocumentsOperation handleNext={handleNext} handleBack={handleBack} steps={steps} activeStep={activeStep} handleReset={handleReset} mesdocs={mesdocs} setMesDoct={setMesDoct} />}
            {activeStep === 3 && <ResumeOperation handleNext={handleSaveOperation} handleBack={handleBack} steps={steps} activeStep={activeStep} handleReset={handleReset} loading={loading} />}
          </div>
            {activeStep !== 0 &&<div style={{display:"flex",flexDirection:'column',flex:1,marginLeft:20}}>
              <Paper className={classes.detailsContainer} elevation={3}>
                  <div className={classes.infosContainer}>
                    <Typography variant='h5'>Contrat</Typography>
                    <Divider />
                    <div className={classes.infos}>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>#ID :</Typography>
                        <Typography variant='h6'>{`\xa0 ${dataForm['idproposition'] || ""}`}</Typography>
                      </Box>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Type :</Typography>
                        <Typography variant='h6'>{`\xa0 ${dataForm['libelleproduit'] || ""}`}</Typography>
                      </Box>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Date effet :</Typography>
                        <Typography variant='h6'> {`\xa0 ${dataForm['dateeffet'] || ""}`}</Typography>
                      </Box>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Date fin :</Typography>
                        <Typography variant='h6'> {`\xa0 ${dataForm['dateecheance'] || ""}`}</Typography>
                      </Box>
                    </div>
                  </div>
                  <div className={classes.infosContainer}>
                    <Typography variant='h5'>Adhérent</Typography>
                    <Divider />
                    <div className={classes.infos}>
                      <Box  className={classes.assureInfoRow}>
                        <Typography variant='h6'>{`\xa0 ${dataForm['adherent'] || ""}`}</Typography>
                      </Box>
                      
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Né(e) le :</Typography>
                        <Typography variant='h6'>{`\xa0 ${dataForm['datenaissance'] || ""}`}</Typography></Box>
                      
                    </div>
                  </div>
                  {infoAssur&&<div className={classes.infosContainer}>
                    <Typography variant='h5'>Assuré(es)</Typography>
                    <Divider />
                    <div className={classes.infos}>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Nom : </Typography>
                        <Typography variant='h6'>{`\xa0 ${infoAssur[0]['Nom'] || ""} ${infoAssur[0]['Prenom'] || ""}`}</Typography>
                      </Box>
                      
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Né(e) le :</Typography>
                        <Typography variant='h6'>{`\xa0 ${infoAssur[0].DateNaissance&&dayjs(infoAssur[0].DateNaissance).format('DD/MM/YYYY')}`}</Typography>
                      </Box>
                    
                    </div>
                  </div>}
                  <div className={classes.infosContainer}>
                    <Typography variant='h5'>Cotisation</Typography>
                    <Divider />
                    <div className={classes.infos}>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Nb Emis :</Typography>
                        <Typography variant='h6'>{`\xa0 ${dataForm['nombreemission'] ? formatNumber(parseInt(dataForm['nombreemission']).toString()) : ""}`}</Typography></Box>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Montant :</Typography>
                        <Typography variant='h6'>{`\xa0 ${dataForm['montantemission'] ? formatNumber(parseInt(dataForm['montantemission']).toString()) : ""}`}</Typography>
                      </Box>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Nb impayé :</Typography>
                        <Typography variant='h6'>{`\xa0 ${dataForm['nombreimpayes'] ? formatNumber(parseInt(dataForm['nombreimpayes']).toString()) : ""}`}</Typography>
                      </Box>
                      <Box  className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>Mt impayé :</Typography>
                        <Typography variant='h6'> {`\xa0 ${dataForm['montantimpayes'] ? formatNumber(parseInt(dataForm['montantimpayes']).toString()) : ""}`}</Typography></Box>
                    </div>
                  </div>
                  
                  
              </Paper>

              <Stepper activeStep={activeStep}  orientation="vertical" style={{borderRadius:10}} spacing={3}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel color='primary'>{label}</StepLabel>
                    </Step>
                ))}
              </Stepper>
              </div>
            }
         
         </div>
        
    </div>
    {(svData && topen) && <OperaTransmission step={activeStep} open={topen} handleClose={handleTClose} data={svData} handleRedirectOperation={handleRedirectOperation} handleBack={handleBack} btnshow={true} />}
  </div>
  </div>
}

const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
  updateField,
}
const mapStateToProps = (state) => ({
  user: state.user,
  api: state.api,
  dataForm: getFormValues('addOper')(state),
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false, })(AddOperation))
