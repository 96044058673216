import {
    SET_VILLE,
    SET_CIVILITE,
    SET_SITUATION,
    SET_PAYS,
    SET_SOCIETE,
    SET_PRODUIT,
    SET_PROFESSION,
    SET_GARANTIE_PRODUIT,
    SET_PRETS,
    SET_PROPOSITIONS,
    SET_CONTRATS,
    SET_BORDEREAUX_VALIDATION,
    CLEAR_BORDEREAUX_VALIDATION,
    SET_PRODUCTEUR_DATA
  } from '../types';

  let initialState = {
    loading: false,
    villes:[],
    civilites:[],
    sitmatrimoniale:[],
    professions:[],
    societes:[],
    produits:[],
    garantiesProduit:[],
    pretsAll:[],
    propositionAll:[],
    contratsAll:[],
    bordereauxValidation:[],
  };

  export default function(state = initialState, action) {
      
    switch (action.type) {
      case SET_VILLE: 
        return {
          ...state,
          villes:action.payload,
          loading: false,
        };
      case SET_CIVILITE:
        return {
          ...state,
          civilites:action.payload,
          loading: false,
        };
      case SET_PAYS:
        return {
          ...state,
          pays:action.payload,
          loading: false,
        };
      case SET_PROFESSION:
        return {
          ...state,
          professions:action.payload,
          loading: false,
        };
      case SET_PRETS:
        return {
          ...state,
          pretsAll:action.payload,
          loading: false,
        };
      case SET_PROPOSITIONS:
        return {
          ...state,
          propositionAll:action.payload,
          loading: false,
        };
        case SET_CONTRATS:
          return {
            ...state,
            contratsAll:action.payload,
            loading: false,
        };
      case SET_SITUATION:
        return {
             ...state,
            sitmatrimoniale:action.payload,
            loading: false,
          };
      case SET_SOCIETE:
        return {
            ...state,
            societes:action.payload,
            loading: false,
        };
      case SET_PRODUIT:
        return {
              ...state,
            produits:action.payload,
            loading: false,
        };
      case SET_GARANTIE_PRODUIT:
        return {
          ...state,
          garantiesProduit:action.payload,
          loading:false
        }
        case SET_BORDEREAUX_VALIDATION:
          return {
            ...state,
            bordereauxValidation:action.payload,
            loading:false
          }
        case CLEAR_BORDEREAUX_VALIDATION:
            return {
              ...state,
              bordereauxValidation:[],
              loading:false
        }
      default:
        return state;
    }
  }