import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomAlert from 'components/CustomAlert/CustomAlert'
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDate from 'components/CustomDate/CustomDate'
import {updatePret} from 'redux/actions/apiActions'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import {formatNumber} from 'application'
import Divider from '@material-ui/core/Divider';
import dayjs from 'dayjs';
import {reduxForm} from 'redux-form';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    assureInfoRow:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-between'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    
  }));
  
 function ModalRachatPret ({data,mopen,...props}){
    const classes = useStyles();

    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      daterachat:null,
      motifrachat:null,
    });
    const [myAlerte, setMyAlerte]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    aMessage:null,
    amode:'warning',
    });
    const [openA, setopenA] = useState(false)
    const handleAlerte = (aMessage,aopen,amode)=>{
      setopenA(true)
    setMyAlerte({
        ['message']:aMessage,
        ['mode']:amode
        })
    }
    const handleClickOpen = () => {
      props.handleMopen(true);
    };
    const handleClose = () => {
      props.handleClose(false);
    };
    const [libType,setLibType] = useState(null)
    useEffect(()=>{
      if(data.typepret){
        switch (data.typepret) {
          case 'immobilier':
            setLibType('Prêt Immobilier')
            break;
          case 'consommation':
            setLibType('Prêt à la consommation')
            break;
          case 'decouvert':
            setLibType('Decouvert/Avance de trésorie')
            break;
          default:
            setLibType("Prêt à la consommation")
            break;
        }
      }
    },[data])

    const handleChange =(event)=>{
      const  {name,value}=event.currentTarget
      setInputField({
        [name]:value
      })
    }
    const handleSave = ()=>{
        const mydata ={
          id:data.id,
          typeop:"RA",
          daterachat:inputField.daterachat,
          motifrachat:inputField.motifrachat,
          etat:6,
          modifiele:dayjs().format('YYYY-MM-DD'),
        }
        props.updatePret(mydata).then(reponse=>{
          if(reponse){
            swal("Bien!","pération de rachat effectué avec succès","success")
            handleClose()
          }else{
            swal("Oops!","Erreur lors de l'opération","error")
          }
        })
    }
    return(
      <>
      <CustomAlert open={setopenA} message={myAlerte.message} mode={myAlerte.mode} />
      <Dialog
          open={mopen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
              <div className='flex flex-row justify-between items-center'>
                <Typography variant='h5'>Saisie rachat de prêt</Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
           </DialogTitle>
          <DialogContent>
            <Divider />
          <div className='flex flex-row justify-between bg-green-50'>
              <div className='flex flex-col'>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Ref :
                    </Typography>
                    <Typography variant="subtitle1">
                    {`\xa0 ${data.id}`}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Partenaire :
                    </Typography>
                    <Typography variant="subtitle1">
                    {`\xa0 ${data.utilisateur?.partenaire}`}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Emprunteur :
                    </Typography>
                    <Typography variant="subtitle1">
                        {`\xa0 ${data.adherent?.nom} \xa0 ${data.adherent?.prenom}`}
                    </Typography>
                  </Box>
              </div>
              <div className='flex flex-col'>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Type prêt:
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${libType || ""}`} </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Montant du pret (F CFA) :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${formatNumber(data.montantpret)}`} </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Durée (mois) :
                    </Typography>
                    <Typography variant="subtitle1">{`\xa0 ${data.duree}`} </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title}>
                      Prime (F CFA) :
                  </Typography>
                  {data.prime&&(<Typography variant="subtitle1">{`\xa0 ${formatNumber(data.prime)}`} </Typography>)}
                </Box>    
              </div>
            
            </div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}><Divider /></GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomDate
                            required
                            label="Date de rachat"
                            id="daterachat"
                            name="daterachat"
                            formControlProps={{
                                fullWidth: true, 
                            }}
                            InputProps={{
                                type:'date',
                                inputProps: {min:dayjs(data.saisiele).format('YYYY-MM-DD')},
                            }}
                            variant="outlined"
                        />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                      <CustomInput
                          required
                          label="Motif de rachat"
                          id="motifrachat"
                          name="motifrachat"
                          formControlProps={{
                              fullWidth: true, 
                          }}
                          variant="outlined"
                      />
                  </GridItem>
                </GridContainer>
              </GridItem>
              
            </GridContainer>
          </DialogContent>

            <Divider />
          <div className='flex flex-row justify-between items-center my-4 mx-3'>
            <Button onClick={handleClose} variant='outlined' className='text-white bg-secondary-main border-0'>
              Annuler
            </Button>
            <Button onClick={handleSave} className='text-white bg-primary-main hover:bg-secondary-main' size="large" disabled={inputField.prime===null} color="primary" variant='outlined' autoFocus>
              Valider
            </Button>
            </div>
        </Dialog>
        </>
    )
  }
  const mapStateToProps = (state) => ({
    api: state.api,
  });
const mapActionsToProps = {
  updatePret
}
  
export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form:'addPret',destroyOnUnmount:false,})(ModalRachatPret))