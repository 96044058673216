import React, { useReducer,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {selectPretByUser} from 'redux/actions/apiActions'
import {connect,useDispatch} from 'react-redux'
import MaterialTable from 'material-table'
import dayjs from 'dayjs'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Add from '@material-ui/icons/Add';
import CustomMenu from './components/CustomMenu'
import pdf from 'assets/pdf/llv_rapport_du_medecin_examinateur.pdf'
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import {ageBorne} from 'redux/actions/userActions'
import CustomAlert from 'components/CustomAlert/CustomAlert'
import OptionsPret from './components/OptionsPret'
import { Grid } from '@material-ui/core';
import PretHeader from 'pages/Banking/Prets/components/PretHeader'
import XLSX from 'xlsx';
import {change,reset} from 'redux-form';
import { useHistory } from 'react-router-dom';
import PretFilter from 'pages/Banking/Prets/components/PretFilter'
import DetailPret from './components/DetailPret'
function formatNumber(num) {
  return num?num.toString().replace(/ /g,'').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '):""
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const exportToExcel = (columns, data) => {
  // determining header labels
  const columnInfo = columns.reduce(
      (acc, column) => {
          const headerLabel = column.title || column.field;
          acc.header.push(headerLabel);
          acc.map[column.field] = headerLabel;
          return acc;
      },
      { map: {}, header: [] }
  );
  
  const mappedData = data.map(row =>
      Object.entries(row).reduce((acc, [key, value]) => {
          if (columnInfo.map[key]) {
              acc[columnInfo.map[key]] = value;
          }
          return acc;
      }, {})
  );

  var ws = XLSX.utils.json_to_sheet(mappedData, {
      header: columnInfo.header, // garanties the column order same as in material-table
  });

  /* add to workbook */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* generate an XLSX file and triggers the browser to generate the download*/
  XLSX.writeFile(wb, `LLV-liste-Proposition.xlsx`);
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  corps:{
    width:'75%',
    marginLeft:"auto",
    marginRight:"auto",
    marginTop:75
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  assureInfoRow:{
    display:'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  buttonAdd:{
    width:'100%',
    marginBottom:20,
    borderWidth:1,
    borderStyle:'dashed',
    borderColor:theme.palette.secondary.main,
    borderRadius:30,
    '&:hover':{
      borderColor:theme.palette.primary.main,
    }
  },
  buttonAddChild:{
    color:theme.palette.secondary.main,
    '&:hover':{
      color:theme.palette.primary.main,
    }
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonSearch:{
    borderWidth:1,
    borderStyle:'dashed',
    borderColor:theme.palette.primary.main,
    backgroundColor:theme.palette.primary.main,
    color:'#FFF',
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      borderColor:theme.palette.secondary.main,
    }
  }
}));

 function PretList(props) {
  const classes = useStyles();
  const {pretsAll,layoutRoot,credentials}=props
  const dispatch =useDispatch()
  const [pretData,setPretData] = useReducer((state, newState) => ({ ...state, ...newState }),
  {
    demandeEnAttente:null,
    demandeEnCotation:null,
    pretsAccepter:null,
    pretsConfirmer:null,
    pretsRejeter:null,
    pretsCloturer:null,
    pretsRacheter:null,
    pretsAnnuler:null
  });

  const [myAlerte, setMyAlerte]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    aMessage:null,
    aopen:false,
    amode:null,
  });
 const handleAlerte = (aMessage,aopen,amode)=>{
   setMyAlerte({
     ['message']:aMessage,
     ['open']:aopen,
     ['mode']:amode
    })
 } 

  useEffect(() => {
    if(pretsAll && pretsAll.length!==0){
      setPretData({
        ['demandeEnAttente']:pretsAll.filter(item=>item.etat==-1),
        ['demandeEnCotation']:pretsAll.filter(item=>item.etat==1),
        ['pretsAccepter']:pretsAll.filter(item=>item.etat==2),
        ['pretsConfirmer']:pretsAll.filter(item=>item.etat==3),
        ['pretsRejeter']:pretsAll.filter(item=>item.etat==4),
        ['pretsCloturer']:pretsAll.filter(item=>item.etat==5),
        ['pretsRacheter']:pretsAll.filter(item=>item.etat==6),
        ['pretsAnnuler']:pretsAll.filter(item=>item.etat==0),
      })
    }else{
      dispatch(selectPretByUser())
    }
  },[])


  const history =useHistory()
  //Edition de la fiche de tarification
  const editFicheTarification =(data)=>{
      let doc = new jsPDF();
      let pageSize = doc.internal.pageSize
      let pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
      let pageHeight = pageSize.Height ? pageSize.Height : pageSize.getHeight()
      let img = new Image(25, 10)
      img.src = require('assets/images/logo_yako.jpg').default
      const headRows1 = ()=> ([{ libelle: 'Libelle', valeur: 'Valeur'}])
      doc.addImage(img, "JPEG", (pageWidth/2)-20, 5, 40, 18);
      
      doc.setDrawColor(0)
      doc.setFillColor(0)
      doc.rect(15,25,(pageWidth-25),8,'FD')
      
      doc.setFontSize(12)
      doc.setTextColor(255,255,255)
      doc.setFont(undefined,'bold')
      doc.text(60, 30, 'FICHE DE TARIFICATION TEMPORAIRE DECES');

      doc.setDrawColor(0)
      doc.setFillColor(255)
      doc.rect(15,48,(pageWidth-25),pageHeight-60,'FD')
      
      doc.autoTable({
        startY: 50,
        head:[{ libelle: 'Libelle'}],
        body: [
          [(data.nom+' '+data.prenom).toUpperCase()],
        ],
        showHead: false,
        styles: { overflow: 'ellipsize',fontSize:16},
        columnStyles: {
          libelle: { 
            textColor: 0, 
            fontStyle:'bold',
            fillColor:[255, 255, 255],
            cellWidth:'wrap',
            minCellWidth:pageWidth-60,
            halign:'left',
            lineWidth:0,
            
          },
        },
        columns: [
          { header: 'Libelle', dataKey: 'libelle' },
        ],
        margin: { left: 20 },
      })

      let finalY = doc.previousAutoTable.finalY
      doc.autoTable({
        startY: finalY+10,
        head:headRows1(),
        body: [

          ["AGE :", ageBorne(data.datenaissance,data.effetgarantie)],
          ["CAPITAL ASSURE : ",data.montantpret&&(formatNumber(data.montantpret))],
          ["DUREE DU PRÊT (en mois) : ",data.duree],
          ["TAUX PRIME : ",(parseFloat(data.txprimeunique)) + " %" ],
          ["TAUX SURPRIME : ",(parseFloat(data.txsurprime)) + " %"],
          ["MONTANT SURPRIME : ",data.montantsurprime&&(formatNumber(data.montantsurprime))],
          ["PRIME HORS TAXE : ",data.primeht&&(formatNumber(data.primeht))],
          ["FRAIS MEDICAUX : ",data.fraismedicaux],
          ["ACCESSOIRES : ",data.fraisaccessoires],
          ["PRIME TTC : ",data.prime&&(formatNumber(data.prime))],
        ],
        showHead: false,
        styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:12,cellPadding:5},
        columnStyles: {
          libelle: { textColor: 0, fontStyle: 'normal',halign:'left', fillColor:[255, 255, 255] },
          valeur: { textColor: 0, fontStyle: 'bold',halign:'right',fillColor:[255, 255, 255]},
        },
        columns: [
          { header: 'Libelle', dataKey: 'libelle' },
          { header: 'Valeur', dataKey: 'valeur' },
        ],
        margin: { left: 30 },
        tableWidth:150,
      })
      finalY = doc.previousAutoTable.finalY
      doc.autoTable({
        startY: finalY+20,
        head:headRows1(),
        body: [
          ["PRIME TTC : ",data.prime&&(formatNumber(data.prime))],
        ],
        showHead: false,
        styles: { overflow: 'ellipsize',cellWidth: 'auto',fontSize:16,cellPadding:5},
        columnStyles: {
          libelle: { textColor: 0, fontStyle: 'bold',halign:'left', fillColor:[255, 255, 255] },
          valeur: { textColor: 0, fontStyle: 'bold',halign:'right',fillColor:[255, 255, 255]},
        },
        columns: [
          { header: 'Libelle', dataKey: 'libelle' },
          { header: 'Valeur', dataKey: 'valeur' },
        ],
        margin: { left: 30 },
        tableWidth:150,
      })
      
      doc.output('dataurlnewwindow');

  }

  //telechargement rapport examinateur
  const downloadRapport = ()=>{
    window.open(pdf)
  }

  const handleNew = ()=>{
    dispatch(reset('addPret'))
    history.push(`/${layoutRoot}/add-pret`,{});
  }
 
  const renderActionBar = ()=>{
    return (
      
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={6} sm={6} md={6} lg={6}><OptionsPret rapport={downloadRapport}/></Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}> <Button size='large' variant='outlined' color='inherit' className={classes.buttonAdd} onClick={handleNew}>
         <Add  className={classes.buttonAddChild} fontSize="large" />
         Nouvelle cotation
      </Button>  </Grid> 
      </Grid>   
    )
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
console.log(pretsAll);
  const renderAll =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Etat', field: 'etat',render:rowData=>{
              switch (Number(rowData.etat)) {
                case -1:
                    return 'Mis en attente'
                case 1:
                    return 'En traitement'
                case 2:
                   return 'Valider'
                case 3:
                    return 'Accepter'
                case 4:
                    return 'Rejeter'
                case 5:
                  return 'A terme'
                case 0:
                    return 'Annuler'
                default:
                    return 'Inconnu'
                  break;
              }
            } },
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))},
            { title: 'Agence', field: '', render:rowData=>rowData.utilisateur?.nomagence},
            { title: 'Utilisateur', field: '', render:rowData=>(rowData.utilisateur?.nom+' '+rowData.utilisateur?.prenom)}
          ]}
          data={pretsAll}
          title="Liste des cotations"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              
              return(rowData&&<DetailPret data={rowData} />)}
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials} 
                  data={props.data}
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }
  const renderEnAttente =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.demandeEnAttente?pretData.demandeEnAttente:[]}
          title="Liste des cotations mis en attente"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)}
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials} 
                  data={props.data}
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }
  const renderEnExamen =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.demandeEnCotation?pretData.demandeEnCotation:[]}
          title="Liste des cotations en attente d'examens médicaux"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
            actionsColumnIndex: -1,
            pageSize:50,
            pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)}
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials}  
                  data={props.data} 
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }
  const renderValider =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.pretsAccepter?pretData.pretsAccepter:[]}
          title="Liste des cotations validés"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)}
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials} 
                  data={props.data} 
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }
  const renderEnCours =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'référence', field: 'referencepret'},
            { title: 'Date de mise en place', field: 'miseenplaceeffective',render:rowData=>rowData.miseenplaceeffective&&dayjs(rowData.miseenplaceeffective).format('DD/MM/YYYY') },
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.pretsConfirmer?pretData.pretsConfirmer:[]}
          title="Liste des cotations avec prêt mis en place"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-cotations-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                    rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)
            }
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials}  
                  data={props.data}
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }
  const renderRejeter =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Date réjet', field: 'daterejet',render:rowData=>dayjs(rowData.daterejet).format('DD/MM/YYYY') },
            { title: 'Motif réjet', field: 'moftifrejet'},
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.pretsRejeter?pretData.pretsRejeter:[]}
          title="Liste des cotations réjétées"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)
              }
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials}  
                  data={props.data} 
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }
  const renderCloturer =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Date réjet', field: 'daterejet',render:rowData=>dayjs(rowData.daterejet).format('DD/MM/YYYY') },
            { title: 'Motif réjet', field: 'moftifrejet'},
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.pretsCloturer?pretData.pretsCloturer:[]}
          title="Liste des cotations échues"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)
            }
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials}  
                  data={props.data} 
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }

  const renderRacheter =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(rowData.adherent?.nom +" "+rowData.adherent?.prenom)},
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Date rachat', field: 'daterachat',render:rowData=>rowData.daterachat&&dayjs(rowData.daterachat).format('DD/MM/YYYY') },
            { title: 'Motif rachat', field: 'motifrachat'},
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.pretsRacheter?pretData.pretsRacheter:[]}
          title="Liste des cotations avec prêt rachété"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)}
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials}  
                  data={props.data} 
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }

  const renderAnnuler =()=>{
    return(
      <div>
        <MaterialTable
          columns={[
            { title: 'Ref', field: 'id' },
            { title: 'Emprunteur', field: 'nom',render:rowData=>(
              rowData.adherent?.nom +" "+rowData.adherent?.prenom
            ) },
            { title: 'N° compte', field: 'numerocompte' },
            { title: 'Date', field: 'saisiele',render:rowData=>dayjs(rowData.saisiele).format('DD/MM/YYYY') },
            { title: 'Montant (F CFA)', field: 'montantpret',render:rowData=>formatNumber(parseInt(rowData.montantpret)) },
            { title: 'Durée (Mois)', field: 'duree'},
            { title: 'Date réjet', field: 'daterejet',render:rowData=>dayjs(rowData.daterejet).format('DD/MM/YYYY') },
            { title: 'Motif réjet', field: 'moftifrejet'},
            { title: 'Prime (F CFA)', field: 'prime', render:rowData=>formatNumber(parseInt(rowData.prime))}
          ]}
          data={pretData.pretsAnnuler?pretData.pretsAnnuler:[]}
          title="Liste cotations annulées"
          localization={{
            toolbar:{
                searchPlaceholder:'Trouver une cotation',
                searchTooltip:'Trouver une cotation',
                exportTitle: 'Exportation',
                exportAriaLabel: 'Exportation',
                exportCSVName: 'Exporter en Excel',
                exportPDFName: 'Exporter en PDF',  
            },
            body: {
              emptyDataSourceMessage: 'Aucune donnée trouvée !'
            },
            pagination: {
              labelRowsSelect: 'Ligne(s)',
              labelDisplayedRows: '{count} sur {from}-{to}',
              firstTooltip: 'Prémière page',
              previousTooltip: 'Précédent',
              nextTooltip: 'Suivant',
              lastTooltip: 'Dernière page'
            }
          }}
          options={{
            exportButton: true,
            exportCsv:(columns, data)=> exportToExcel(columns, data,"LLV-Liste-demande-prets"),
            searchFieldStyle:{
              fontSize:18,
              width:'100%',
              height:50,
            },
          actionsColumnIndex: -1,
          pageSize:50,
          pageSizeOptions:[50,100,150,200,300,400,500,1000]
          }}
          detailPanel={[{
            tooltip: 'Afficher le detail',
            render:rowData=>{
              let libType
              if(rowData.typepret){
                switch (rowData.typepret) {
                  case 'immobilier':
                    rowData.libType ='Prêt Immobilier'
                    break;
                  case 'consommation':
                    rowData.libType ='Prêt à la consommation'
                    break;
                  case 'decouvert':
                      rowData.libType ='Decouvert/Avance de trésorie'
                    break;
                  default:
                    rowData.libType="Autre type de prêt"
                    break;
                }
              }
              return(rowData&&<DetailPret data={rowData} />)}
          }]}
          components={{Action: props=> {  
            return(  
                <CustomMenu
                  user={credentials}  
                  data={props.data} 
                  editFicheTarification={editFicheTarification} 
                  rapport={downloadRapport}
                  />
                )
           } }}
          actions={[
            {
              icon: 'more_vert',
              tooltip: 'Actions',
              onClick: (event, rowData) =>alert("You want to add a new row")
            },
          ]}
        />
        </div>
     
    )
  }

  const handleFonctionMini =(newvaleur)=>{
    props.handleCliqueFonction(newvaleur)
  }
  
const handleFilterData =(dataS)=>{
  const dataSend = dataS
  props.selectPretByUser(dataSend).then(res=>{
    if(res){
      setPretData({
        ['demandeEnAttente']:res.filter(item=>item.etat===-1),
        ['demandeEnCotation']:res.filter(item=>item.etat===1),
        ['pretsAccepter']:res.filter(item=>item.etat===2),
        ['pretsConfirmer']:res.filter(item=>item.etat===3),
        ['pretsRejeter']:res.filter(item=>item.etat===4),
        ['pretsCloturer']:res.filter(item=>item.etat===5),
        ['pretsRacheter']:res.filter(item=>item.etat===6),
        ['pretsAnnuler']:pretsAll.filter(item=>item.etat===0),
      })
    }
  }).catch(err=>{
    console.log(err);
  })
}

  return (
    <div className={classes.corps}>
        <CustomAlert open={myAlerte.open} message={myAlerte.message} mode={myAlerte.mode} />
        <PretHeader history={history}  handleFonctionMini={handleFonctionMini} />
        <PretFilter credentials={credentials} handleFilterData={handleFilterData} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {props.element==='ALL'&& renderAll()}
            {props.element==='ENATTENTE'&&renderEnAttente()}
            {props.element==='ENTRAITEMENT'&&renderEnExamen()}
            {props.element==='VALIDER'&&renderValider()}
            {props.element==='MISENPLACE'&&renderEnCours()}
            {props.element==='REJETES'&&renderRejeter()}
            {props.element==='FERMER'&&renderCloturer()}
            {props.element==='RACHETER'&&renderRacheter()}
            {props.element==='ANNULER'&&renderAnnuler()}
          </GridItem>
        </GridContainer>
    
      </div>
  );
}  
const updateField =(field, data)=>(dispatch)=>dispatch(change('addPret', field, data))
const mapStateToProps = (state) => ({
        pretsAll:state.api.pretsAll,
        credentials:state.user.credentials,
        layoutRoot:state.UI.layoutRoot
});
const mapActionsToProps = {updateField,selectPretByUser}
export default connect(mapStateToProps,mapActionsToProps)(PretList)
