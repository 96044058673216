import React,{useEffect,useRef,useState} from 'react';
import { connect } from 'react-redux';
import {Field,getFormValues,reduxForm,formValueSelector,change,registerField} from 'redux-form';
import { Checkbox, Divider, ListItemText, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CustomDate from 'components/CustomDate/CustomDate'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber'
import Ask  from './components/ask'
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { green } from '@material-ui/core/colors';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
      },
      rootExpan: {
        width: '100%',
      },
      heading: {
        fontSize:26,
        fontWeight: 'bold',
      },
      paper:{
          padding:'20px',
      },
      list: {
        width: '100%',
       padding:5
      },
     
    separateurv:{
        marginLeft:20,
        marginRight:20,
        marginTop:10,
        marginBottom:10
    },
    btnnext:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:green[400],
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#f9b233",
        },
        borderRadius:10,
      },
      btnback:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:"#f9b233",
        paddingRight:20,
        paddingLeft:20,
        paddingTop:10,
        paddingBottom:10,
        color:'#ffffff',
        "&:hover":{
          backgroundColor:"#3c3c3b",
        },
        borderRadius:10,
      },
};
const useStyles = makeStyles(styles);
function InfoSante(props){
    const classes = useStyles();
    const { handleSubmit,handleBack,handleNext,handleAttente,steps,activeStep,dataForm } = props    
    const [detailsTab,setDetailsTab]= useState(null)
    const [tabpatologie,setTabpatologie]= useState(null)

    useEffect(()=>{
        let  currentElt = [];
        let pathologies =[]
        if(dataForm && dataForm.hasOwnProperty("fumezVous")) {
            if(dataForm["fumezVous"]==='Oui'){
                currentElt.push("fumezVous")
            }
        } 

        if(dataForm && dataForm.hasOwnProperty("estSportif")) {
            if(dataForm["estSportif"]==='Oui'){
                currentElt.push("estSportif")
            } 
        } 

        if(dataForm && dataForm.hasOwnProperty("estEnArretTravail")) {
            if(dataForm["estEnArretTravail"]==='Oui'){
                currentElt.push("estEnArretTravail")
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-1")) {
            if(dataForm["nature-1"]==='Oui'){
                currentElt.push("nature-1")
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-2")) {
            if(dataForm["nature-2"]==='Oui'){
                currentElt.push("nature-2")
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-3")) {
            if(dataForm["nature-3"]==='Oui'){
                currentElt.push("nature-3")
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-4")) {
            if(dataForm["nature-4"]==='Oui'){
                currentElt.push("nature-4")
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-5")) {
            if(dataForm["nature-5"]==='Oui'){
                currentElt.push("nature-5")
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-7")) {
            if(dataForm["nature-7"]==='Oui'){
                currentElt.push("nature-7")
                pathologies.push("nature-7")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-7')
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-8")) {
            if(dataForm["nature-8"]==='Oui'){
                currentElt.push("nature-8")
                pathologies.push("nature-8")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-8')
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-9")) {
            if(dataForm["nature-9"]==='Oui'){
                currentElt.push("nature-9")
                pathologies.push("nature-9")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-9')
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-10")) {
            if(dataForm["nature-10"]==='Oui'){
                currentElt.push("nature-10")
                pathologies.push("nature-10")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-10')
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-11")) {
            if(dataForm["nature-11"]==='Oui'){
                currentElt.push("nature-11")
                pathologies.push("nature-11")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-11')
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-12")) {
            if(dataForm["nature-12"]==='Oui'){
                pathologies.push("nature-12")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-12')
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-13")) {
            if(dataForm["nature-13"]==='Oui'){
                pathologies.push("nature-13")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-13')
            }
        }

        if(dataForm && dataForm.hasOwnProperty("nature-14")) {
            if(dataForm["nature-14"]==='Oui'){
                pathologies.push("nature-14")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-14')
               
            }
        }
        if(dataForm && dataForm.hasOwnProperty("nature-15")) {
            if(dataForm["nature-15"]==='Oui'){
                pathologies.push("nature-15")
            }else{
                pathologies = pathologies.filter(item=>item!=='nature-15')
               
            }
        }

        setDetailsTab(currentElt)
      
        if(pathologies && pathologies.length!==0){
            setTabpatologie(pathologies)
        }
        
    },[dataForm])

    hobbies.sort()
  
    const handleChange = (event) => {
        const {
            target: { value,name },
        } = event;
        props.dispatch(registerField("addPret",name,'Field'));
        props.updateField(name,(typeof value === 'string' ? value.split(',') : value))
    };

       const handleCheckedExamsupSante = ()=>{
            if(tabpatologie && tabpatologie.length!==0){
               
                if(dataForm && !dataForm.hasOwnProperty('examensup')){
                    props.dispatch(registerField("addPret",'examensup','Field'));
                }
                props.updateField('examensup',true)
            }else{
                if(dataForm && dataForm.hasOwnProperty('isnotconv') ){
                    if(dataForm.isnotconv===true){
                        props.updateField('examensup',true)
                    }else{
                        props.updateField('examensup',false)
                    }
                }else{
                    props.updateField('examensup',false)
                }
            }
       }

       useEffect(()=>{
        handleCheckedExamsupSante()
       },[dataForm,tabpatologie,activeStep])

    return (
        <form onSubmit={handleSubmit(handleNext)}>
           <Paper elevation={0} className={classes.paper}>
                <Typography className={classes.heading}>Etape {activeStep?(activeStep+1):1} : {(steps && activeStep)?steps[activeStep]:""}</Typography>
                <Divider />
            </Paper>
            <Ask>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Quelle est votre taille ? *</Ask.Title>
                        </Ask.Group>  
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv}/>
                            <Field required component={CustomInputNumber} id="taille" name='taille' formControlProps={{fullWidth: false}}variant="outlined"/>
                            <Ask.SmallText variant='h5'>(CM)</Ask.SmallText>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Quel est votre poids ? *</Ask.Title> 
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomInputNumber} id="poids" name='poids' formControlProps={{ fullWidth: false }} variant="outlined"/>
                            <Ask.SmallText variant='h5'>(KG)</Ask.SmallText>
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Quel est le résultat de votre prise de tension ? *</Ask.Title> 
                        </Ask.Group> 
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomSimpleSelect} label ='Tension minimale' id="diastolique" name='diastolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[75,80,85,90,95,100,105,106]}/>
                            <Field required component={CustomSimpleSelect} label ='Tension maximale' id="systolique" name='systolique' formControlProps={{ fullWidth: 50 }} variant="outlined" options={[130,135,140,145,150,155,160,165,170,175,180,186]}/>
                            
                        </Ask.Group>
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Fumez-vous ? *</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="fumezVous" name='fumezVous' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Buvez vous de l'alcool ? *</Ask.Title>
                        </Ask.Group>
                        
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="buvezVous" name='buvezVous' formControlProps={{ fullWidth: false}} options={[{"label":"Pas du tout","value":"Non"},{"label":"A l'ocassion","value":"Partiel"},{"label":"Régulièrement (au moins une fois par semaine)","value":"Oui"}]} row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Pratiquez-vous une activité sportive régulière ? *</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="estSportif" name='estSportif' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    {(detailsTab && detailsTab.includes('estSportif'))&&<Ask.Details>
                        <Ask.Card>
                            <Ask.Group><Ask.Title variant='body'>Quel type de sport pratiquez-vous ? * </Ask.Title></Ask.Group>
                            <Ask.Group>
                                <Field required component={CustomInput} id="sportPratique" name="sportPratique" formControlProps={{ fullWidth: false }}  variant="outlined"/>
                            </Ask.Group>
                        </Ask.Card>
                    </Ask.Details>}
                </Ask.Inner>
                <Ask.Inner>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Quelles sont vos distractions ? *</Ask.Title>
                        </Ask.Group>
                        
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Select 
                                required
                                id="distraction" 
                                name="distraction" 
                                label="distraction"
                                labelId="distraction"
                                variant="outlined"
                                multiple
                                fullWidth
                                style={{marginTop:20,marginBottom:20}}
                                value={dataForm.hasOwnProperty('distraction')?dataForm['distraction'] : []}
                                formControlProps={{ fullWidth: true}} 
                                input={<OutlinedInput label="Produit" />}
                                renderValue={(selected) => selected.join(', ')}
                                onChange={handleChange}
                               >
                                {(hobbies && hobbies.length!==0)?hobbies.map(hobbie=><MenuItem key={hobbie} value={`${hobbie}`}>
                                <Checkbox checked={dataForm.hasOwnProperty('distraction')?dataForm['distraction'].indexOf(`${hobbie}`) > -1:false} />
                                    <ListItemText primary={`${hobbie}`} />
                                </MenuItem>): <MenuItem key={1} value=''>
                                    <ListItemText primary='Aucune donnée fournie' />
                                </MenuItem>}
                               </Select>
                        </Ask.Group>
                        
                    </Ask.Card>
                </Ask.Inner>
                
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Avez-vous déjà été victime d'un accident ? *</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="nature-1" name='nature-1' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                   
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Suivez-vous ou avez-vous suivi un traitement médical ces 6(six) derniers mois ? *</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="nature-2" name='nature-2' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                   
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Avez-vous déjà subi une transfusion de sang ? *</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="nature-3" name='nature-3' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Avez-vous déjà subi des interventions chirurgicales ? *</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="nature-4" name='nature-4' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                </Ask.Inner>
                <Ask.Inner direction='column'>
                    <Ask.Card>
                        <Ask.Group>
                            <Ask.Title variant='h5'>Devez-vous subir une intervention chirurgicale ? *</Ask.Title>
                        </Ask.Group>
                        <Ask.Group>
                            <Divider orientation="vertical" flexItem className={classes.separateurv} />
                            <Field required component={CustomRadio} id="nature-5" name='nature-5' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                        </Ask.Group>
                        
                    </Ask.Card>
                    
                </Ask.Inner>
                </Ask>
                <Accordion expanded={true}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Etes-vous sous traitement médical pour, ou souffrez-vous d'une de ces maladies ?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Ask style={{marginLeft:'auto',marginRight:'auto'}}>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Diabète *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-7" name='nature-7' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                               
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Hypertension artérielle *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-8" name='nature-8' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Drépanocytose *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-9" name='nature-9' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Cirrhose de foie *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-10" name='nature-10' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Affections pulmonaires *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-11" name='nature-11' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Cancer *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-12" name='nature-12' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Anémie *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-13" name='nature-13' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Insuffisance rénale *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-14" name='nature-14' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                               
                            </Ask.Inner>
                            <Ask.Inner direction='column'>
                                <Ask.Card>
                                    <Ask.Group>
                                        <Ask.Title variant='h5'>Avez-vous déjà été victime d’un AVC ? *</Ask.Title>
                                    </Ask.Group>
                                    <Ask.Group>
                                        <Divider orientation="vertical" flexItem className={classes.separateurv} />
                                        <Field required component={CustomRadio} id="nature-15" name='nature-15' formControlProps={{ fullWidth: false }} options={[{"label":"Oui","value":"Oui"},{"label":"Non","value":"Non"}]}  row/>
                                    </Ask.Group>
                                    
                                </Ask.Card>
                                
                            </Ask.Inner>
                        </Ask>
                    </AccordionDetails>
                </Accordion>

            <Divider />
                <div style ={{display:"flex",justifyContent:"space-between",padding:20}}>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.btnback}
                        
                    >
                        Retour
                    </Button>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleSubmit(handleAttente)}
                        className={classes.buton}
                        variant="contained"
                        color="info"
                    >
                        Mettre en attente
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type= 'submit' 
                        className={classes.btnnext}
                        large
                    >
                        {activeStep === steps.length - 1 ? 'Valider' : 'SUivant'}
                    </Button>
                </div>

            </form>

    )
}
const selector = formValueSelector('addPret')
const mapStateToProps = (state) => {
    const {duree,dateEcheanceFin,dateEcheanceDebut,questionnaires,montantPret} = selector(state,'duree','dateEcheanceFin','dateEcheanceDebut','questionnaires','montantPret')
    return {
        api: state.api,
        ui: state.UI,
        user:state.user,
        dataForm:getFormValues('addPret')(state),
        initialValues: state.form.addPret.values
    }
  };
const updateField =(field, data)=>(dispatch)=>dispatch(change('addPret',field,data))
const mapActionsToProps = {
  updateField,
}
export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form:'addPret',destroyOnUnmount:false,})(InfoSante))

const hobbies =["Lecture","Écriture","Peinture","Dessin","Photographie","Jardinage","Cuisine","Randonnée","Camping","Voyages","Musique","Danse","Yoga","Méditation","Fitness et exercice physique","Natation","Course à pied","Cyclisme","Escalade","Arts martiaux","Jeux de société","Jeux vidéo","Collection d'objets","Bricolage et travaux manuels","Couture","Tricot","Modélisme (avions, trains, voitures, etc.)","Lecture de bandes dessinées","Cinéma","Théâtre","Voyage"]