import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper,Tooltip,Button } from '@material-ui/core'
import {Home,Assignment,Add, Folder} from "@material-ui/icons/";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import {verifRule} from 'application'
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
const useStyles =makeStyles(theme=>({
    root:{
        display:'flex',
        alignItems:'center',
        position:'fixed',
        right:20,
        marginTop:40,
        [theme.breakpoints.down('sm')]:{
            position:'relative',
            marginTop:20,
            alignItems:'center',
        },
    },
    btnContainer:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        borderRadius:10,
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)",   
        [theme.breakpoints.down('sm')]:{
            flexDirection:'row',
            alignItems:'center',
        },
    },
    btn:{
        paddingLeft:20,
        paddingRight:20,
        marginBottom:20,
        "&:hover":{
            color:theme.palette.primary.main
        }
    }
}))
const ESMinToolsBar = (props) => {
    const classes =useStyles()
    const history = useHistory()
    const {user: { privileges,credentials },ui: { layoutRoot } } = props
    const handleClickOption =(op)=>{
        history.push(op)
    }
  return (
    <div className={classes.root}>
        <Paper className={classes.btnContainer}>
            <Tooltip title="Accueil"><Button onClick={()=>handleClickOption(`/${layoutRoot}/esouscription`)} className={classes.btn}><Home style={{fontSize:35}}/></Button></Tooltip>
            {verifRule('prop','add',privileges)&&<Tooltip title="Nouvelle propositon"><Button onClick={()=>handleClickOption(`/${layoutRoot}/esouscription/simulateurs`)} className={classes.btn}><Add style={{fontSize:35}}/></Button></Tooltip>}
            <Tooltip title="Liste de propositions"><Button onClick={()=>handleClickOption(`/${layoutRoot}/esouscription/production`)} className={classes.btn}><Folder style={{fontSize:35}}/></Button></Tooltip>
            <Tooltip title="Liste des contrat"><Button onClick={()=>handleClickOption(`/${layoutRoot}/esouscription/contrats`)} className={classes.btn}><Assignment style={{fontSize:35}}/></Button></Tooltip>
            <Tooltip title="Rechercher un contrat"><Button onClick={()=>handleClickOption(`/${layoutRoot}/esouscription/recherche-contrat`)} className={classes.btn}><FindInPageIcon style={{fontSize:35}}/></Button></Tooltip>
        </Paper>
    </div>
  )
}

const mapPropsActions = {}
const mapStateToProps = (state) => ({
    user: state.user,
    ui: state.UI,
});
export default connect(mapStateToProps, mapPropsActions)(ESMinToolsBar)
 