import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm,change,registerField,getFormValues,reset } from 'redux-form';
import {connect} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import GroupWorkIcon from '@material-ui/icons/GroupWork';

const useStyles = makeStyles((theme) =>({
    root: {
      margin:20,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
      '& :hover':{
        background: theme.palette.primary.main,
        border: 0,
        padding: '10 30px',
        color:'white'
      }
    },
    
    media: {
      height: 140,
    },
    avatar: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        alignSelf:'center'
      },
      pink: {
        color:theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      eheader:{
        display:'flex',
        width:'100%',
        paddingTop:20,
        paddingBottom:20,
        backgroundColor:theme.palette.primary.main
    },
    boxheader:{
        display:'flex',
        width:'60%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'60%',
        },
        justifyContent:'space-between',
        alignItems:'center',
        marginLeft:'auto',
        marginRight:'auto'
    },
    cardLogo:{
        display:'flex',
        flexDirection:'column',
        margin:20,
        justifyContent:'center',
        alignItems:'center'
    },
    img:{
        objectFit: 'fill',
        width:50,
        borderRadius:100,
        
    },
    rcontainer:{
        display:'flex',
        flexDirection:'column',
        marginTop:75,
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto'
    }
  }));
function AdminFonctions(props){
    const classes = useStyles();
    const {handleCliqueFonction,user:{credentials}}=props
    const {codepartenaire} = credentials
    return(
        <div >
            <div className={classes.eheader}>
                <div className={classes.boxheader}>
                    <div className={classes.cardLogo}>
                        <img className={classes.img} src={require(`assets/images/${(codepartenaire &&codepartenaire!=='')?codepartenaire+'.jpg':"LLV.jpg"}`).default} />
                        <span style={{fontStyle:'bold',color:'white',fontSize:26}}>{credentials['partenaire']||""}</span>
                    </div>
                    <span style={{fontStyle:'bold',color:'white',fontSize:30}}>Paramètres</span>
                    <div></div>
                </div>
            </div>
        <div className={classes.rcontainer}>
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <Typography variant='h5'>Gestion des utilisateurs</Typography>
                <Divider />
            </GridItem>
            <div style={{display:'flex',flexWrap:'wrap'}}>
                <Card className={classes.root}>
                    <CardActionArea  onClick={event => handleCliqueFonction('USER')}>
                        <CardContent>
                            <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><PeopleAltIcon /></Avatar></div>
                                <Typography align='center' gutterBottom variant="h5" component="h5">
                                Utilisateurs
                                </Typography>
                                <Typography align='center' variant="caption" color="textSecondary" component="p">
                                    Gerer les utilisatteurs de l'application (créer, editer, attribuer des rôles ...)
                                </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card className={classes.root}>
                    <CardActionArea  onClick={event => handleCliqueFonction('AGENCE')}>
                            
                        <CardContent>
                            <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><GroupWorkIcon /></Avatar></div>
                                <Typography align='center' gutterBottom variant="h5" component="h5">
                                Agences
                                </Typography>
                                <Typography align='center' variant="caption" color="textSecondary" component="p">
                                    Gerer vos agences (créer, editer, supprimer ...)
                                </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <Card className={classes.root}>
                    <CardActionArea onClick={event => handleCliqueFonction('ZONE')}>
                        <CardContent>
                            <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><BusinessCenterIcon /></Avatar></div>
                        
                            <Typography align='center' gutterBottom variant="h5" component="h5">
                               Zones
                            </Typography>
                            <Typography align='center' variant="caption" color="textSecondary" component="p">
                                Gerer  les zones (créer, editer, supprimer ...)
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </div>
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(AdminFonctions))