import React,{useEffect,useState} from 'react'

import CustomInput from 'components/CustomInput/CustomInput'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/CustomButtons/Button'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';
import {Paper, Typography,Divider,Box } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { makeStyles } from '@material-ui/styles'
import { IconButton } from '@material-ui/core';
import config from 'util/config'
import { Nav, Tab } from 'react-bootstrap';
import AddIcon from '@material-ui/icons/Add'
import {
  updatePartenaire,
  getPersonnesRessources,
  deletePersonnesRessources,
  addPartenaireconvention,
  getPartenaireconventions,
  deleteconvention
} from 'redux/actions/settingsAction'
import AddPersonnesRessources from './AddPersonnesRessources';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete'
import AddConventions from './AddConventions';
import ReseauPartenaire from './ReseauPartenaire';
const useStyle =makeStyles(theme=>({
  root:{
    display:'flex',
    flexDirection:'column',
    width:'75%',
    marginLeft:'auto',
    marginRight:'auto',
    [theme.breakpoints.down('sm')]: {
        width:'100%',
    },
    [theme.breakpoints.up('md')]: {
      width:'50%',
    },
    [theme.breakpoints.up('lg')]: {
      width:'75%',
    },
    marginBottom:20
  },
dialogHead:{
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center'
  },
  hPaper:{
    padding:10,
    marginBottom:20,
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth:8,
    display:'flex',
    justifyContent:"space-between",
    alignItems:'center'
  },
  bPaper:{
    padding:10,
    borderRadius:0
  },
  hContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
  },
  loaddingContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:0
  },
  bhead:{
    padding:10
  },
  btContainer:{
    display:'flex',
    justifyContent:'space-between',
    padding:10
  },
  input: {
    display: "none",
  },
  faceImage: {
    color: theme.palette.primary.light,
  },
  logoContainer:{
    marginBottom:20,
    padding:10,
    display:'flex',
    justifyContent:'space-between'
  },
  logoview:{
    display:'flex',
    justifyContent:'flex-end'  
  },
  logo:{
    width:'30%'
  },
  infoContainer:{
    display:'flex',
    justifyContent:'space-between'
  },
  detailContainer:{
    display:'flex',
    marginBottom:20,
    marginTop:20
  },
  bx:{
    display:'flex',
    flexDirection:'column',
    marginLeft:20,

  },
  bxTitle:{
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-end',
    fontStyle:'bold'
  },
  pbPaper:{
    padding:10,
    borderRadius:0,
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:10,
    marginTop:20
  },
  pPaperStart:{
    padding:10,
    borderRadius:0,
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-sart',
    alignItems:'flex-sart',
    borderRadius:10,
    marginTop:20
  },
  addbtn:{
    width:'75%',
    padding:10,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    background:'transparent',
    borderRadius:15,
    border:'1px solid #f6f6f6',
    color:theme.palette.primary.main,
    fontSize:40,
    "&:hover":{
      backgroundColor:theme.palette.primary.main,
      color:'#ffffff',
      border:'none',
    },
    },
    secondAdd:{
      backgroundColor:theme.palette.primary.main,
      borderRadius:15,
      "&:hover":{
        backgroundColor:theme.palette.secondary.main,
        color:'#ffffff',
        border:'none',
      },
    },
    headEl:{
      display:'flex',
      flex:1,
      justifyContent:'end',
      alignItems:'center',
      marginBottom:20
    },
    bodEl:{
      display:'flex',
      flexDirection:'column',
      marginBottom:20
    }
  }))
 
function FichePartenaire(props) {
    const {data,handleFonctionShow} =props
    const classes = useStyle()
    const [pageData,setPageData] = useState({})
    const handlePersonneRessource =()=>{

      if(data){
        getPersonnesRessources(data['id']).then(res=>{
          if(res && res.length !== 0){
            setPageData({...pageData,'personnes':res})
          }
        })
      }
    }

    const [mode,setMode] = useState('ADD')

    const handleAdd = ()=>{
      handleFonctionShow('ADD')
    }
    const [open,setOpen] =useState(null)
    const handleOpen =(tp)=>{
      setOpen(tp)
    }
    const handleClose = ()=>{
      setOpen(null)
    }

    const handlePersonne =(mode)=>{
      setMode(mode)
      handleOpen('pr')
    }
    useEffect(()=>{
      handlePersonneRessource()
    },[])
  
  return (
    <div className={classes.root}>
           <AddPersonnesRessources open={(open==='pr')} data={data} mode={mode}  handleClose={handleClose} actualizPerso={handlePersonneRessource}/>
             <Paper className={classes.hPaper} variant="outlined">
                <Tooltip title="Retour"> 
                    <IconButton onClick={()=>handleFonctionShow('LIST')} color='primary'>
                        <ArrowBackIosIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
                <div lassName={classes.hContainer}><Typography>FICHE PARTENAIRE #{data['id']||""} {data['designation'] || ""}</Typography></div>   
                <Tooltip title="Nouveau partenaire"> 
                    <IconButton onClick={handleAdd} color='primary'>
                        <AddIcon className={classes.iconbtn} fontSize='large' />
                    </IconButton>
                </Tooltip>     
              </Paper>

              <Tab.Container defaultActiveKey="0">
                <Nav fill  variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="0"> <Typography variant='caption'>Informations générales</Typography></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="1">Personnes résources</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">Conventions/produits</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">Réseau/Equipe</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">Modules/Permissions</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                <Tab.Pane eventKey="0">
                    <Paper className={classes.bcontainer} variant="outlined">
                      <div className={classes.bhead}>
                          <Typography variant='caption'>Informations générales</Typography>
                      </div>
                      <Paper className={classes.bPaper} variant="outlined">
                        <div className={classes.infoContainer}>
                          <div className={classes.detailContainer}>
                            <Box className={classes.bxTitle} > 
                                <Typography>Code : </Typography>
                                <Typography>Raison sociale : </Typography>
                                <Typography>Activité principale : </Typography>
                                <Typography>Capital : </Typography>
                            </Box>
                            <Box className={classes.bx}> 
                                <Typography variant='body1'>{data['code'] || ""} </Typography>
                                <Typography variant='body1'>{data['designation'] || ""} </Typography>
                                <Typography variant='body1'>{data['activitesprincipales'] || "-"} </Typography>
                                <Typography variant='body1'>{data['captal'] || "-"} </Typography>
                            </Box>
                          </div>
                          {data['logo']&&<div>
                            <img className={classes.logo} src={config.localurl+"/"+data['logo'] } />
                          </div>}
                        
                        </div>
                            <Divider />
                            <div className={classes.detailContainer}>
                                <Box className={classes.bxTitle} > 
                                    <Typography>Forme juridique : </Typography>
                                    <Typography>Compte contribuable : </Typography>
                                    <Typography>N° RC : </Typography>
                                </Box>
                                <Box className={classes.bx}> 
                                    <Typography variant='body1'>{data['formejuridique'] || "-"} </Typography>
                                    <Typography variant='body1'>{data['comptecontribuable'] || "-"} </Typography>
                                    <Typography variant='body1'>{data['nrc'] || "-"} </Typography>
                                </Box>
                            </div>
                          </Paper>
                          <div className={classes.bhead}>
                            <Typography variant='caption'>Contacts</Typography>
                          </div>
                          <Paper className={classes.bPaper} variant="outlined">
                              <div className={classes.detailContainer}>
                                <Box className={classes.bxTitle} > 
                                    <Typography>Télphone : </Typography>
                                    <Typography>Mobile : </Typography>
                                    <Typography>Adresse email : </Typography>
                                    <Typography>Site web : </Typography>
                                </Box>
                                <Box className={classes.bx}> 
                                    <Typography variant='body1'>{data['telephone'] || "-"} </Typography>
                                    <Typography variant='body1'>{data['mobile1'] || "-"} {data['mobile2'] || "-"} </Typography>
                                    <Typography variant='body1'>{data['adresseemail'] || "-"} </Typography>
                                    <Typography variant='body1'>{data['siteweb'] || "-"} </Typography>
                                </Box>
                              </div>
                          </Paper>
                            {data['personnesressources']&&<div className={classes.bhead}>
                              <Typography variant='caption'>Personnes ressources</Typography>
                            </div>}
                            
                            {data['conventions']&&<div className={classes.bhead}>
                              <Typography variant='caption'>Conventions</Typography>
                            </div>}
                        </Paper>
                </Tab.Pane>
                <Tab.Pane eventKey="1">
                  {!(pageData.hasOwnProperty('personnes') && pageData.personnes && pageData.personnes.length!==0)&&<Paper className={classes.pbPaper} variant="outlined">
                      <Typography variant='h6'>Aucune donnée</Typography>
                      <Button className={classes.addbtn} style={{marginTop:25}} onClick={()=>handlePersonne('ADD')}>
                            <AddIcon sx={{ fontSize: 40 }} className={classes.prbtn} />
                        </Button>
                    </Paper>}
                    
                    {pageData.hasOwnProperty('personnes')&&<Paper className={classes.pbPaper} variant="outlined">
                        <div className={classes.headEl}><Button className={classes.secondAdd}  onClick={()=>handlePersonne('ADD')}>Ajouter une personne</Button></div>
                        <div className='bodEl'>

                        {(pageData.personnes && pageData.personnes.length!==0)?(<TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Nom</TableCell>
                              <TableCell align="center">Prenom</TableCell>
                              <TableCell align="center">Email</TableCell>
                              <TableCell align="center">Téléphone</TableCell>
                              <TableCell align="center">Mobile</TableCell>
                              <TableCell align="center">fonction</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pageData.personnes.map((personne) => (
                              <TableRow key={personne.id}>
                                <TableCell align="right">{personne.nom}</TableCell>
                                <TableCell align="right">{personne.prenom}</TableCell>
                                <TableCell align="center">{personne.email}</TableCell>
                                <TableCell align="center">{personne.telephone}</TableCell>
                                <TableCell align="center">{personne.mobile}</TableCell>
                                <TableCell align="right">{personne.fonction}</TableCell>
                                <TableCell align="right">
                                  <Tooltip title="Supprimer" > 
                                      <IconButton>
                                          <DeleteIcon />
                                      </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>):<Typography>Aucune donnée</Typography>}
                        </div>
                    </Paper>}
                    
                </Tab.Pane> 
                <Tab.Pane eventKey="2">
                  <AddConventions 
                      data={data} 
                      
                      mode='ADD' 
                    
                    />
                </Tab.Pane> 
                <Tab.Pane eventKey="3">
                    <ReseauPartenaire data={data} />
                    
                    
                </Tab.Pane> 
                <Tab.Pane eventKey="4">
                    <Paper className={classes.pbPaper} variant="outlined">
                      <Typography variant='h6'>Aucune donnée</Typography>
                      <Button className={classes.addbtn} style={{marginTop:25}} onClick={handlePersonne}>
                            <AddIcon sx={{ fontSize: 40 }} className={classes.prbtn} />
                        </Button>
                    </Paper>
                </Tab.Pane> 

                </Tab.Content>
            </Tab.Container>
      
          
    </div>
  )
}

export default FichePartenaire