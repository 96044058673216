import React, { useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import { updateProposition } from 'redux/actions/apiActions'
import Button from 'components/CustomButtons/Button'
import { Grid } from '@material-ui/core'
import Divider from "@material-ui/core/Divider";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
}));

function RejetForm(props) {
    const classes = useStyles();
    const theme = useTheme();
    //intialisation des élement du state
    const { ficheData, open, handleClose, handleOpen, user: { credentials } } = props //recuperation des valieur du props

    const [dataRejet, setDataRejet] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            motifrejet: "",
            typeop: 'RJ',
            id: ficheData.id
        });
    const handleChange = (e) => {
        const { name, value } = e.target
        setDataRejet({ [name]: value })
    }
    const saveRejet = () => {
        if (dataRejet.motifrejet == "") {
            return
        }

        props.updateProposition(dataRejet).then(res => {
            if (res) {
                handleClose()
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <Dialog
            maxWidth='sm'
            open={open}
            disableBackdropClick
            onClose={handleClose}
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card className={classes.root} variant="outlined">
                            <CardHeader title=" VEUILLEZ SAISIR UN MOTIF DE REJET" />
                            <CardContent>
                                <TextField
                                    id="motifRejet"
                                    name='motifrejet'
                                    label="Motif de rejet"
                                    multiline
                                    rows={4}
                                    onChange={handleChange}
                                    placeholder='Saisissez le motif de réjet'
                                    variant="outlined"
                                    fullWidth
                                />

                            </CardContent>
                        </Card>
                    </GridItem>
                </GridContainer>

            </DialogContent>
            <DialogActions>
                <Divider component="hr" />
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Button onClick={handleClose} type="button">
                        Retour
                    </Button>
                    <Button onClick={saveRejet} color="primary">
                        Rejeter
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
});
const mapActionsToProps = { updateProposition }
export default connect(mapStateToProps, mapActionsToProps)(RejetForm)