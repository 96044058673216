import React,{useReducer} from 'react'
import DocumentCard from 'pages/Banking/components/DocumentCard'
import { Grid } from '@material-ui/core'
import {uploadDocuments} from 'redux/actions/apiActions'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from "@material-ui/core/Typography";
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
export default function ImportDocument(props) {
    const {open,handleClose,handleOpen} = props
    const classes = useStyles();
    const [mesdocs,setMesDoct] = React.useState([])
    const handleMesDocs =(docs)=>{
        setMesDoct(docs)
    }
    const [info, setInfo] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      type:'error',
      message:"",
    });
    const [mopen,setMopen] = React.useState(false)
    const handleMclose=()=>{
        setMopen(false)
    }
    const handleSaveDoc=()=>{
      
        if(mesdocs && mesdocs.length!==0){
            uploadDocuments(props.data.id,mesdocs).then(res=>{
                if(res){
                    setInfo({
                        ['type']:'success',
                        ['message']:'Importation effectuée avec succès'
                    })
                    setMopen(true)
                   
                }else{
                    setInfo({
                        ['type']:'error',
                        ['message']:"Erreur d'importation des documents, veuillez reassayer"
                    })
                    setMopen(true)
                  
                } 
              
            }).catch(err=>{
              console.log(err);
              setInfo({
                ['type']:'error',
                ['message']:'Erreur lors de l\'importation'
              })
                setMopen(true)
            }) 
        }
      }
      
    return (
        <Dialog
            maxWidth='md'
            disableBackdropClick 
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        > 
      
            <Snackbar  anchorOrigin={{vertical: 'center', horizontal: 'center'}} open={mopen} autoHideDuration={6000} onClose={handleMclose}>
                <Alert onClose={handleMclose} severity={info.type}>
                    {info.message}
                </Alert>
            </Snackbar>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Importation de documents Proposition N°: #{props.data.id}
                    </Typography>

                    <IconButton edge="start" color="inherit" onClick={handleSaveDoc} aria-label="close">
                        <AssignmentReturnedIcon fontSize="large"/>
                    </IconButton>
                </Toolbar>
            </AppBar>
       
            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <DocumentCard documentType={documents} handleFile={handleMesDocs}/>
                    </GridItem>
                </GridContainer>
             </DialogContent>
             <DialogActions>
                <Grid container direction="row" justify="space-between" alignItems="center">        
                    <Button type="button"   onClick={handleClose}>
                        Annuler
                    </Button>      
                    
                    <Button type= 'submit' color='primary' onClick={handleSaveDoc}  >
                        Importer
                        <NavigateNextOutlinedIcon /> 
                    </Button>
                </Grid>
            </DialogActions>
      </Dialog>
    )
}



const documents=[
    {titre:"Bulletin de souscription",fichier:""},
    {titre:"Pièce justificatif d\'identité(CNI)",fichier:""},
    {titre:"RIB",fichier:""},
    {titre:"Signature",fichier:""},
    {titre:"Photo",fichier:""},
    {titre:"Autres pièces",fichier:""}
]