import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    SET_RESEAUX,
    SET_ZONES,
    SET_EQUIPES,
    SET_MODULES,
    SET_ALLPRIVILEGE,
    SET_ALLUTILISATEUR
} from '../types';
import axios from 'axios';

//gestion des reseaux
export const addReseau = (dataReseau) => (dispatch) => {
    if (!dataReseau) return false;
    dispatch({ type: LOADING_UI });
    return axios.post('/add-reseau', dataReseau).then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch(getReseaux())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const AssocierModuleReseau = (codereseau, dataReseau) => (dispatch) => {
    if (!dataReseau) return false;
    if (!codereseau) return false
    return axios.post(`/associer-module-reseau/${codereseau}`, { modules: dataReseau }).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}
export const updateReseau = (codeReseau, dataReseau) => (dispatch) => {
    if (!dataReseau) return false;
    if (!codeReseau) return false;
    dispatch({ type: LOADING_UI });

    return axios.post(`/update-reseau/${codeReseau}`, dataReseau).then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch(getReseaux())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}
export const getReseaux = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return axios.get('/reseaux-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch({ type: SET_RESEAUX, payload: res.data })
            dispatch({ type: LOADING_UI })
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}
export const getReseauxNoDispatch = () => {
    return axios.get('/reseaux-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getReseauxPartenaires = (codepartenaire) => {
    if(!codepartenaire) return false
    return axios.get('/reseaux-partenaires').then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const deleteReseaux = (codeReseau) => {
    if (!codeReseau) return false
    return axios.post(`/delete-reseau/${codeReseau}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

//gestion des zones
export const addZone = (dataZone) => (dispatch) => {
    if (!dataZone) return false;
    dispatch({ type: LOADING_UI });
    return axios.post('/add-zone', dataZone).then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch(getZones())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const updateZone = (codezone, dataZone) => (dispatch) => {
    if (!dataZone) return false;
    if (!codezone) return false;
    dispatch({ type: LOADING_UI });
    return axios.post(`/update-zone/${codezone}`, dataZone).then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch(getZones())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const getZones = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return axios.get('/zones-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch({ type: SET_ZONES, payload: res.data })
            dispatch({ type: LOADING_UI })
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}
export const getZonesNoDispatch = () => {
    return axios.get('/zones-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}
export const deleteZones = (idZone) => {
    return axios.post(`/delete-zone/${idZone}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getZonesReseau = (codeReseau) => {
    if (!codeReseau) return false
    return axios.get(`/liste-zone-reseau/${codeReseau}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}
//gestion des equipes
export const addEquipe = (dataEquipe) => (dispatch) => {
    if (!dataEquipe) return false;
    dispatch({ type: LOADING_UI });
    return axios.post('/add-equipe', dataEquipe).then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch(getEquipes())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const updateEquipe = (codeequipe, dataEquipe) => (dispatch) => {
    if (!dataEquipe) return false;
    dispatch({ type: LOADING_UI });
    return axios.post(`/update-equipe/${codeequipe}`, dataEquipe).then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch(getEquipes())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const getEquipes = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return axios.get('/equipes-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch({ type: SET_EQUIPES, payload: res.data })
            dispatch({ type: LOADING_UI })
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const getEquipesNoDispatch = () => {
    return axios.get('/equipes-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getEquipesZones = (codeZone) => {
    if (!codeZone) return false
    return axios.get(`/liste-equipe-zone/${codeZone}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getEquipesReseau = (codeReseau) => {
    if (!codeReseau) return false
    return axios.get(`/liste-equipe-reseau/${codeReseau}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getEquipesGroupReseau = (refReseau) => {
    if (!refReseau) return false
    return axios.post(`/liste-equipe-group-reseau`, refReseau).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const deleteEquipe = (idEquipe) => {
    return axios.post(`/delete-equipe/${idEquipe}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

//gestion des modules
export const addModule = (dataModule) => (dispatch) => {
    if (!dataModule) return false;
    dispatch({ type: LOADING_UI });
    return axios.post('/add-module', dataModule).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const updateModule = (codemodule, dataModule) => (dispatch) => {
    if (!dataModule) return false;
    if (!codemodule) return false;

    return axios.post(`/update-module/${codemodule}`, dataModule).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const getModules = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return axios.get('/module-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch({ type: SET_MODULES, payload: res.data })
            dispatch({ type: LOADING_UI })
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const getModulesReseau = (codeReseau) => {
    if (!codeReseau) return false
    return axios.get(`/liste-module-reseau/${codeReseau}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const addModuleReseau = (codeReseau, data) => {
    if (!data) return false;
    if (!codeReseau) return false;
    return axios.post(`/add-modules-reseau/${codeReseau}`, data).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}
export const deleteModule = (codeModule) => (dispatch) => {
    return axios.post('/delete-module', codeModule).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const deleteModuleReseau = (codeModule, codeReseau) => {
    if (!codeModule) return
    if (!codeReseau) return
    return axios.post(`/delete-module-reseau/${codeModule}/${codeReseau}`).then(res => {
        console.log(res.data);
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            return false
        }
        return false
    })
}

//gestions des rôles
export const createRole = (dataRole) => {
    if (!dataRole) return false;
    return axios.post('/add-role', dataRole).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const updateRole = (coderole, dataRole) => {

    if (!dataRole) return false;
    if (!coderole) return false;
    return axios.post(`/update-role/${coderole}`, dataRole).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getRoles = () => {
    return axios.get('/role-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getRolesReseau = (codeReseau) => {
    if (!codeReseau) return false
    return axios.get(`/liste-role-reseau/${codeReseau}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}


export const deleteRole = (coderole) => {
    if (!coderole) return
    return axios.post(`/delete-role/${coderole}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            return false
        }
        return false
    })
}

//gestion des privilèges
export const addPrivilege = (dataPrivilege) => {
    if (!dataPrivilege) return false;
    return axios.post('/add-privilege', dataPrivilege).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const addPrivilegeModule = (codeModule, dataPrivilege) => {
    if (!dataPrivilege) return false;
    if (!codeModule) return false

    return axios.post(`/add-privilege-module/${codeModule}`, { privileges: dataPrivilege }).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const updatePrivilege = (codeprivilege, dataPrivilege) => {
    if (!codeprivilege) return false;
    if (!dataPrivilege) return false;
    return axios.post(`/update-privilege/${codeprivilege}`, dataPrivilege).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}
export const getPrivileges = () => (dispatch) => {
    return axios.get('/privilege-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch({ type: SET_ALLPRIVILEGE, payload: res.data })
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}
export const getPrivilegeModule = (codeModule) => {
    if (!codeModule) return false
    return axios.get(`/liste-privilege-module/${codeModule}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}


export const addPrivilegeRole = (codeRole, dataPrivilege) => {
    if (!dataPrivilege) return false;
    if (!codeRole) return false

    return axios.post(`/add-privilege-role/${codeRole}`, { privileges: dataPrivilege }).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const updatePrivilegeRole = (codeRole, dataPrivilege) => {
    if (!dataPrivilege) return false;
    if (!codeRole) return false
    return axios.post(`/update-privilege-role/${codeRole}`, { privileges: dataPrivilege }).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}


export const getPrivilegeRole = (codeRole) => {
    if (!codeRole) return false
    return axios.get(`/liste-privilege-role/${codeRole}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getPrivilegeActifRole = (codeRole) => {
    if (!codeRole) return false
    return axios.get(`/liste-privilege-actif-role/${codeRole}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}
export const getPrivilegeRoleByModule = (codeRole, refModule) => {
    if (!codeRole) return false
    return axios.get(`/liste-privilege-role/${codeRole}/${refModule}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getAllPrivilegesRole = (codeRole) => {
    if (!codeRole) return false
    return axios.get(`/liste-all-privilege-role/${codeRole}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const deletePrivilegeRole = (codePrivilege, codeRole) => {
    if (!codePrivilege) return false
    if (!codeRole) return false
    return axios.post(`/delete-privilege-role/${codePrivilege}/${codeRole}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const deletePrivilege = (codePrivilege) => {
    return axios.post(`/delete-privilege/${codePrivilege}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}


//gestion des utilisateurs

export const addUtilisateur = (dataUtilisateur) => (dispatch) => {
    if (!dataUtilisateur) return false;
    console.log(dataUtilisateur);
    dispatch({ type: LOADING_UI });
    return axios.post('/add-utilisateur', dataUtilisateur).then(res => {
        console.log(res);
        if (res.status === 200 || res.status === 201) {
            dispatch(getUtilisateur())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        console.log(err);
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const saveUtilisateur = (codeuUilisateur, dataUtilisateur) => (dispatch) => {
    if (!dataUtilisateur) return false;
    if (!codeuUilisateur) return false;

    return axios.post(`/update-utilisateur/${codeuUilisateur}`, dataUtilisateur).then(res => {
        if (res.status === 200 || res.status === 201) {
            console.log(res.data);

            dispatch(getUtilisateur())
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}

export const getUtilisateur = () => (dispatch) => {
    dispatch({ type: LOADING_UI });
    return axios.get('/liste-utilisateur').then(res => {
        if (res.status === 200 || res.status === 201) {
            dispatch({ type: SET_ALLUTILISATEUR, payload: res.data })
            dispatch({ type: CLEAR_ERRORS })
            return res.data
        } else {
            dispatch({ type: SET_ERRORS, payload: { 'setttings': "Une erreur inconnu s'est produit lors de l'opération" } })
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
            dispatch({ type: SET_ERRORS, payload: { 'setttings': err.response.data } })
        }
        return false
    })
}
export const getUtilisateurNoDispatch = (params) => {
    return axios.post('/liste-utilisateur-params', params).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const getUtilisateurReseau = (codeReseau) => {
    if (!codeReseau) return false
    return axios.get(`/liste-utilisateur-reseau/${codeReseau}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const deleteUtilisateur = (codeUtilisateur) => {
    if (!codeUtilisateur) return
    return axios.post(`/delete-utilisateur/${codeUtilisateur}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}
export const historiqueEquipeuser = (codeUtilisateur) => {
    if (!codeUtilisateur) return
    return axios.get(`/historique-equipe-user/${codeUtilisateur}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }

    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const changerEquipeUser = (dataSend) => {
    if (!dataSend) return
    return axios.post(`/change-equipe-user`, dataSend).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }

    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const changerProfilUser = (dataSend) => {
    if (!dataSend) return
    return axios.post(`/change-profil-user`, dataSend).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }

    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const bloquerUser = (codeuser) => {
    if (!codeuser) return
    return axios.get(`/bloquer-profil-user/${codeuser}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const debloquerUser = (codeuser) => {
    if (!codeuser) return
    return axios.get(`/debloquer-profil-user/${codeuser}`).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

//gestion des branches
export const getBranche = () => {
    return axios.get('/branche-liste').then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response);
        }
        return false
    })
}
export const addBranche = (data) => {
    return axios.post('/add-branche', data).then(res => {
        console.log(res.data);
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        console.log(err);
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const updateBranche = (codebranche, data) => {
    if (codebranche) return
    return axios.put(`/update-branche/${codebranche}`, data).then(res => {
        if (res.status === 200 || res.status === 201) {
            return res.data
        } else {
            return false
        }
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

export const deleteBranche = (codebranche) => {
    if (!codebranche) return
    return axios.delete(`/delete-branche/${codebranche}`).then(res => {
        return res.data
    }).catch(err => {
        if (err.hasOwnProperty('response')) {
            console.log(err.response.data);
        }
        return false
    })
}

//gestion des partenaires
    export const getPartenaire = () => {
        return axios.get(`/partenaire-list`).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }
    export const storePartenaire = (data) => {
        if (!data) return
        return axios.post(`/add-partenaire`, data).then(res => {
            console.log(res)
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }

    export const updatePartenaire = (codepartenaire, data) => {
        if (!codepartenaire){return false} 
        
        return axios.post(`/update-partenaire/${codepartenaire}`, data).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err);
            if (err.hasOwnProperty('response')) {
                console.log(err.response);
            }
            return false
        })
    }
    export const deletePartenaire = (codepartenaire) => {
        if (!codepartenaire) return
        return axios.delete(`/delete-partenaire/${codepartenaire}`).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }
    export const selectPartenaire = (codepartenaire) => {
        if (!codepartenaire) return
    
        return axios.get(`/partenaire/${codepartenaire}`).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }
    //personnes ressources partenaire
    export const addPartenairePersonnes =(codepartenaire,data)=>{
        
        if (!data || !codepartenaire) return
        return axios.post(`/add-partenaire-personnes/${codepartenaire}`, data).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }
    export const getStructurePartenaire =(codepartenaire =null)=>{
        const url = codepartenaire ? `/partenaire-reseau/${codepartenaire}` : `/partenaire-reseau`
        console.log(url);
        return axios.get(url).then(res=>{
            if(res.status === 200 || res.status === 201){
                return res.data
            }else{
                return false
            }
        }).catch(err=>{
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }
    export const getPersonnesRessources = (codepartenaire)=>{
        if (!codepartenaire) return
        return axios.get(`/partenaire-personnes/${codepartenaire}`).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }

    export const deletePersonnesRessources = (codepersonne)=>{
        if (!codepersonne) return
        return axios.delete(`/delete-partenaire-personnes/${codepersonne}`).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }

    //conventions partenaires
    export const addPartenaireconvention =(codepartenaire,data)=>{
        if (!data || !codepartenaire) return
        return axios.post(`/add-partenaire-conventions/${codepartenaire}`, data).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }
    export const getPartenaireconventions = (codepartenaire)=>{
        if (!codepartenaire) return
        return axios.get(`/partenaire-conventions/${codepartenaire}`).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }

    export const deleteconvention = (code)=>{
        if (!code) return
        return axios.delete(`/delete-conventions/${code}`).then(res => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch(err => {
            console.log(err)
            if (err.hasOwnProperty('response')) {
                console.log(err.response.data);
            }
            return false
        })
    }


    //produit reseau
export const getProduitReseau = (codereseau) => {
    if (!codereseau) return false
    return axios
        .get(`/produit-reseau/${codereseau}`)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
};

export const addProduitReseau = (dataSend) => {
    if (!dataSend) return false
    
    return axios
        .post(`/add-produit-reseau`, dataSend)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
};

export const deleteProduitReseau = (id) => {
    if (!id) return false
    return axios
        .get(`/delete-produit-reseau/${id}`)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return true
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
};


export const changeStateProduitReseau = (dataSend) => {
    if (!dataSend) return false
    return axios
        .post(`/change-produit-reseau`, dataSend)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
};

export const getproduitBranche = (branche)=>{
    if(!branche)
      return false
      return axios.post(`/produit-branche/${branche}`).then(res=>{
      
        if(res.status===200 || res.status ===201){
          return res.data
        }else{
          return false
        }
    }).catch(err=>{
        if(err.hasOwnProperty('response'))
          console.log(err.response.data);
          return false
    })
  }
    export const getUserBranche =(branche)=>{
      if(!branche)
        return false
  
        return axios.post(`/user-branche/${branche}`).then(res=>{
          if(res.status===200 || res.status ===201){
            return res.data
          }else{
            return false
          }
      }).catch(err=>{
          if(err.hasOwnProperty('response'))
            console.log(err.response.data);
            return false
      })
  }
  export const addProduitBranche =(dataSend)=>{
    if (!dataSend) return false
    return axios
        .post(`/add-produit-branche`, dataSend)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
  }
  export const deleteProduitBranche =(id) =>{
    if (!id) return false
    return axios
        .get(`/delete-produit-branche/${id}`)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return true
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
  } 
  export const changeStateProduitBranche = (dataSend)=>{
    if (!dataSend) return false
    return axios
        .post(`/change-produit-branche`, dataSend)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
  }

  export const associateUserBranche =(dataSend)=>{
    if (!dataSend) return false
    return axios
        .post(`/associate-user-branche`, dataSend)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {
                return res.data
            } else {
                return false
            }
        }).catch((err) => {
            console.log(err);
            return false
        });
  }
  



