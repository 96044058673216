import React, { useState, useReducer } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";
import Typography from "@material-ui/core/Typography";
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form'
import CustomAlert from 'components/CustomAlert/CustomAlert';
import { updateOperation } from 'redux/actions/apiActions'
const useStyles = makeStyles(theme=>({
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
    marginRight:20
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  btnpay:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.dark,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
}))
function OperaTransmission(props) {
  const { open, handleClose, data } = props
  const classes = useStyles()
  const handleAnnuletrans = () => {
    props.handleRedirectOperation(data)
  }
  const [aopen, setAopen] = useState(false)
  const [aInfo, setAinfo] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      amessage: null,
      amode: null,
    });

  const transmissionOperation = async (donnees) => {
    if (!donnees) { return }
    updateOperation({ id: donnees.id, typeop: 'TR' }).then(ctr => {
      if (ctr) {
        setAinfo({
          ['amessage']: "Tranmission effectué avec succès",
          ['amode']: "succes"
        })
        setAopen(true)
      } else {
        setAinfo({
          ['amessage']: "Erreur incconnu lors de la transmission",
          ['amode']: "error"
        })
        setAopen(true)
      }
    }).catch((err) => {
      console.log(err);
      setAinfo({
        ['amessage']: "Erreur incconnu lors de la transmission",
        ['amode']: "error"
      })
      setAopen(true)
    })
    props.handleRedirectOperation(data)
  }

  return (
    <Dialog
      maxWidth='md'
      disableBackdropClick
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <CustomAlert aopen={aopen} message={aInfo.amessage} mode={aInfo.amode} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Typography component='h4' variant='h4'>Voulez-vous transmettre l'opération Réf : {data.id} pour validation ? </Typography>
            <Alert severity="warning">NB: En transmettant l'opération vous ne pourrez plus faire de modification sauf après réjet des services de YAKO AFRICA </Alert>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Button type="button"  classeName={classes.btnback} onClick={handleAnnuletrans}>
            Transmettre plustard
          </Button>
          <Button type="button" classeName={classes.btnnext} onClick={(e) => transmissionOperation(data)}>
            Transmettre
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
const mapActionsToProps = {}
const mapStateProps = (state) => ({})


export default connect(mapStateProps, mapActionsToProps)(reduxForm({ form: 'adForm', destroyOnUnmount: false })(OperaTransmission))


