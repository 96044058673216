import styled from 'styled-components/macro'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import {Button as MaterialButton} from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
export const Container = styled(Paper)`
    display:flex;
    justify-content:space-between;
    pading:20px;
    width: 100%;

    @media(max-width: 600px){
        display:flex;
        flex-direction:column;
        padding:10px;
       
    }
`

export const Groupe = styled.div`
    display:${({flex})=>flex};
    flex-direction: ${({direction})=>direction};
    width: 100%;
    margin-right:20px;
`
export const Card = styled.div`
    display:flex;
    flex-direction: ${({direction})=>direction};
    background-color:${({bg})=>bg};
    justify-content:start;
    padding:5px;
    margin-bottom:20px;
    width: 100%;
`
export const Title = styled(Typography)`
`

export const Input = styled(TextField)`
`
export const Text = styled(Typography)`
`
export const TextSmall = styled.p`
    font-size:16px;
`
export const Button = styled(MaterialButton)`
`