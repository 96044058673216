import React from 'react'
import {connect} from 'react-redux'
import { useRouteMatch,Switch,Route } from 'react-router-dom'
import OperationsHome from 'pages/Operations/OperationsHome'

function Esinistre() {
    const {path} =useRouteMatch()
  return (
    <div>
     <Switch>
            <Route path={`${path}`} component={OperationsHome} name='Gestion des sinistre' />
      </Switch>
    </div>
  )
}

const mapActionsToProps = {}
const mapStateProps =(state)=>({
    user:state.user,
    ui: state.UI,
 })
export default connect(mapStateProps,mapActionsToProps)(Esinistre)  
