import React, { useEffect, useReducer, useState } from "react";
import clsx from 'clsx'
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import styles from "assets/jss/llv/views/dashboardStyle.js";
import Paper from '@material-ui/core/Paper';
import "assets/styles/shards-dashboards.1.1.0.min.css";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import { logoutUser } from 'redux/actions/userActions'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import red from '@material-ui/core/colors/red';
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    padding: 20
  },
  line: {
    marginTop: 20,
    marginBottom: 20,
  },
  input: {
    marginBottom: 20
  },

  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  messageErr: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.light2,
    padding: 20,
    borderRadius: 5,
    marginRight: 20
  },
  messageSucces: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: '#c8e6c9',
    padding: 20,
    borderRadius: 5,
    marginRight: 20
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: 'inherit',

  },
  btnErr:{
    backgroundColor:theme.palette.secondary.main, 
    width: '100%', 
    marginTop: 20, 
    marginBottom: 20,
    "&:hover":{
      backgroundColor:theme.palette.secondary.light2, 
    } 
  },
  rcontainer:{
    display:'flex',
    flexDirection:'column',
    marginTop:75,
    width:'75%',
    marginLeft:'auto',
    marginRight:'auto'
}
}))

function ErrorPage(props) {
  const classes = useStyles();

  const { title, message, backAction } = props
  useEffect(() => {
    document.title = title
  })


  return (
    <div className={classes.rcontainer}>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <Paper elevation={3} className={classes.paper}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <Avatar className={clsx(classes.large)}><ErrorOutlineIcon color='primary' fontSize='large' /></Avatar>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <Typography variant="h4" component="h4" color="secondary">{message}</Typography>
              </GridItem>

              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Button onClick={backAction} className={classes.btnErr} >Retour</Button>
              </GridItem>
            </GridContainer>
          </Paper>
        </GridItem>
      </GridContainer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.UI
});
const mapActionsToProps = { logoutUser }
export default connect(mapStateToProps, mapActionsToProps)(ErrorPage)
 // export default ChangePassword