import React, { useState,useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import {reduxForm,change,registerField,getFormValues} from 'redux-form';
import { connect } from 'react-redux';
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Typography from '@material-ui/core/Typography';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import { makeStyles } from '@material-ui/core/styles';
import {formatNumber} from 'application';
import {verifRule} from 'application'
import TitleBox from 'components/TitleBox';
import swal from 'sweetalert';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import CustomDate from 'components/CustomDate/CustomDate';
import dayjs from 'dayjs';
import {getPrimesCapital,getPrimePrincipales} from 'redux/actions/apiActions'
import CustomCapitalSelect from 'components/CustomCapitalSelect/CustomCapitalSelect';
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    
    buttonAdd:{
      width:'100%',
      marginBottom:20,
      borderWidth:1,
      borderStyle:'dashed',
      borderColor:theme.palette.primary.main,
      backgroundColor:theme.palette.primary.main,
     
      color:'#FFF',
      '&:hover':{
        borderColor:theme.palette.secondary.main,
      }
    },
    textItem:{
        marginRight:10
    },
    formControl:{
        marginTop:20
    }
  }));
 
function Simulateur(props){
    const classes = useStyles();
    const [inputField,setInputField] =useState({
        produit:'',
        datenaissanceassure:'',
        ageassure:'',
        capital:'',
        montantrente:'',
        periodiciterente:'',
        dureerente:'',
        duree:'',
        periodicite:'',
        CodeProduit:'',
        prime:0,
        isValid:false,
    }) 
    const [capitaux,setCapitaux] =useState([]) 
    const {api:{produits}} = props  
    const handleChange =(event)=>{
        const {value,name} = event.target
        setInputField({
            ...inputField,
            [name]:value
        })
    }
    const handleChangeProd=(event)=>{
        const {value,name} = event.target
        console.log(value,name);
        setInputField({
            ...inputField,
            [name]:value,
            'produit':(produits && produits.length!==0)&&produits.filter(item=>item.CodeProduitFormule=== value)[0]
        })
    }
    const handleChangeSelect =(event,name)=>{
        const {value} = event.target
        setInputField({
            ...inputField,
            [name]:value
        })
    }

    //mise à jour du titre
    useEffect(() => {document.title ="YNOV - Simulateur"})

   
    
    const [loadingui,setLoading] = React.useState(false)

    const EvaluerLaprime = (CodeProduit)=>{
        const {dureerente,montantrente,periodiciterente,duree,periodicite} =inputField
                if(!dureerente || dureerente==='') {
                    setLoading(false)
                    swal("Ooops","Veuillez renseigner la durée de la rente","warning")
                    return false
                }
                if(!montantrente || montantrente==='') {
                    setLoading(false)
                    swal("Ooops","Veuillez renseigner le montant de la rente","warning")
                    return false
                }
                if(!periodiciterente || periodiciterente==='') {
                    setLoading(false)
                    swal("Ooops","Veuillez renseigner la périodicite de la rente","warning")
                    return false
                }
                if(!duree || duree==='') {
                    setLoading(false)
                    swal("Ooops","Veuillez renseigner la duréé du contrat","warning")
                    return false
                }
                if(!periodicite || periodicite==='') {
                    setLoading(false)
                    swal("Ooops","Veuillez renseigner la périodicité du contrat","warning")
                    return false
                }
                //determination de la prime net 1
                const dureeCotisation = duree
                const i_c = 0.035; const fg_c =0.05; const i_net_c = i_c*(1-fg_c)
                let per_c =12
                switch (periodicite) {
                    case 'M':
                        per_c=12
                        break;
                    case 'T':
                        per_c=4
                        break;
                    case 'S':
                        per_c=2
                        break;
                    default:
                        per_c=1
                        break;
                };
                const i_per =(Math.pow((1+i_net_c),(1/per_c)))-1
                let v_c = 1/(1+i_per)
                let d_c = i_per/(1+i_per)
                let valeur_a_c=(1-Math.pow(v_c,(dureeCotisation*per_c)))/d_c

                const tx_fg =0.04
                const tx_fa =0.05
                const tx_investi = 1-tx_fg-tx_fa
                const prime_net1 = (valeur_a_c*tx_investi)

                // determination prime rente
                const i_r = 0.035
                const fg_r =0.05
                const i_net_r = i_r*(1-fg_r)
                let per_r =12
                switch (periodiciterente) {
                    case 'M':
                        per_r=12
                        break;
                    case 'T':
                        per_r=4
                        break;
                    case 'S':
                        per_r=2
                        break;
                    default:
                        per_r=1
                        break;
                };
                const i_per_r =(Math.pow((1+i_net_r),(1/per_r)))-1
                const v_r = 1/(1+i_per_r)
                const d_r = i_per_r/(1+i_per_r)
                const dureeRentea = parseInt(dureerente)/12
                const dureeTotal = dureeRentea+parseInt(dureeCotisation)
                const valeur_a_r =(1-Math.pow(v_r,((dureeTotal-dureeCotisation)*per_r)))/d_r
                const vn = Math.pow(v_r,dureeCotisation*per_r)
                const tx_fg_r = (1+0.03)
                let primeRelle =(parseInt(montantrente)*tx_fg_r*valeur_a_r*vn)/prime_net1
                primeRelle =Math.round(primeRelle)
                setInputField({...inputField,'prime':primeRelle})
                
        }
   
    const nouvelleSouscription =  ()=>{
       
        if(!props.data || !props.data.hasOwnProperty('primePrincipale')){
            props.dispatch(registerField("adForm",'primePrincipale','Field'));
        }
        props.updateField('primePrincipale',inputField.prime)
       

        if(!props.data || !props.data.hasOwnProperty('totalPrime')){
          props.dispatch(registerField("adForm",'totalPrime','Field'));
        }
        props.updateField('totalPrime',(inputField.prime))

        if(!props.data || !props.data.hasOwnProperty('montantrente')){
            props.dispatch(registerField("adForm",'montantrente','Field'));
        }
        props.updateField('montantrente',inputField.montantrente)
        
        if(!props.data || !props.data.hasOwnProperty('periodiciterente')){
            props.dispatch(registerField("adForm",'periodiciterente','Field'));
        }
        props.updateField('periodiciterente',inputField.periodiciterente)

        if(!props.data || !props.data.hasOwnProperty('dureerente')){
            props.dispatch(registerField("adForm",'dureerente','Field'));
        }
        props.updateField('dureerente',inputField.dureerente)

          if(!props.data || !props.data.hasOwnProperty('duree')){
            props.dispatch(registerField("adForm",'duree','Field'));
           }
          props.updateField('duree',inputField.duree)

          if(!props.data || !props.data.hasOwnProperty('periodicite')){
            props.dispatch(registerField("adForm",'periodicite','Field'));
           }
          props.updateField('periodicite',inputField.periodicite);

          const {produits} = props.api;
          if(produits){
            const currentProduit= produits.filter(item=>item.CodeProduit==='PVRBNI')[0]
            if(!props.data || !props.data.hasOwnProperty('produit')){
                props.dispatch(registerField("adForm",'produit','Field'));
            }
            props.updateField('produit',currentProduit);
              
              props.history.push('/bankass/nouvelle-proposition',{step:1});
          }
    }
    const {privileges,credentials} = props.user
    useEffect(() => {
        if(inputField.produit.hasOwnProperty('CodeProduit') && inputField.produit!==''){
            getPrimesCapital(inputField.produit.CodeProduit).then((capital)=>{
                if(capital){
                 if(['YKL_2004','YKV_2004','YKF_2004'].includes(inputField.produit.CodeProduit)){
                   const currentCapitaux = []
                   capital.map(cp=>{
                     if(cp.capital ==430000){
                       cp.Libelle ="Option Honneur"
                     }
                     if(cp.capital ==750000){
                       cp.Libelle ="Option Elite"
                     }
                     if(cp.capital ==1400000 ){
                       cp.Libelle ="Option Prestige"
                     }
                     currentCapitaux.push(cp)
                   })
                   setCapitaux(currentCapitaux);
                 }else{
                   setCapitaux(capital);
                 }
                }
               })
        }
        
      }, [inputField.produit])
  
    return(
        <div>
            <TitleBox titre ="Simulateur de prime" />
            <div style={{width:'60%',marginLeft:'auto',marginRight:'auto'}}>
                <Paper style={{padding:20}}> 
                    <GridContainer>
                        <GridItem xs={12} sm={inputField.isValid ? 8 : 12} md={inputField.isValid ? 8 : 12} lg={inputField.isValid ? 8 : 12}>
                            <GridContainer>
                                {(produits && produits.length !==0) &&<GridItem xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={inputField.CodeProduit}
                                        name='CodeProduit'
                                        onChange={handleChangeProd}
                                        label="Produit"
                                        select
                                        variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                        style={{marginTop:20}}
                                        >
                                        <MenuItem value="">
                                            <em>Aucun</em>
                                        </MenuItem>
                                        {produits.map(produit=> <MenuItem key={produit.CodeProduitFormule}  value={produit.CodeProduitFormule}>{produit.Formule}</MenuItem>)}
                                       
                                        </TextField>
                                  
                                    </GridItem>}
                                {(inputField.produit && inputField.produit!=="" && !(['PVRBNI'].includes(inputField.produit.CodeProduit)))&&<GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomDate
                                        required
                                        label="Date de naissance de l'assuré"
                                        id="datenaissanceassure"
                                        name="datenaissanceassure"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        InputProps={{
                                            type: 'date',
                                            inputProps: { max: dayjs().add(-18, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                                        }}
                                        
                                        variant="outlined"
                                    />
                                </GridItem>}
                                
                                {(inputField.produit && inputField.produit!=='' && inputField.produit.CodeProduit!=='PVRBNI')&&<GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomCapitalSelect
                                        required
                                        id="capitalSouscrit" 
                                        name="capitalSouscrit" 
                                        devis ={credentials['devis']||""}
                                        label={['YKE_2008'].includes(inputField.produit['CodeProduit'])?"Garanties souscrites et primes":"Capital reversé au terme"}
                                        formControlProps={{
                                        fullWidth: true,
                                        }}
                                        options={capitaux}
                                    />
                                </GridItem>}
                                {(inputField.produit && inputField.produit!=='' && inputField.produit['CodeProduit'] ==='PVRBNI')&&<GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomSimpleSelect
                                        required
                                        id="montantrente"
                                        name="montantrente"
                                        label="Montant de la rente"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        value={inputField.montantrente}
                                        variant="outlined"
                                        options={[50000,75000,100000,150000,200000,250000,500000,1000000]}
                                        onChange={handleChange}
                                    />
                                </GridItem>}
                                {(inputField.produit && inputField.produit!=='' && inputField.produit['CodeProduit'] ==='PVRBNI')&&<GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomRadio
                                        required
                                        name="periodiciterente"
                                        id='periodiciterente'
                                        label="Périodicité de la rente"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        value={inputField.periodiciterente}
                                        row
                                        options={[
                                            {"label":"Mois","value":"M"},
                                            {"label":"Trimestre","value":"T"},
                                            {"label":"Semestre","value":"S"},
                                            {"label":"Année","value":"A"}
                                        ]}
                                        onChange={handleChange}
                                    />
                                </GridItem>}
                                {(inputField.produit && inputField.produit!=='' && inputField.produit['CodeProduit'] ==='PVRBNI')&&<GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomSimpleSelect
                                        required
                                        id="dureerente"
                                        name="dureerente"
                                        label="Durée du service de la rente (mois)"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        value={inputField.dureerente}
                                        options={[12,24,36]}
                                        onChange={handleChange}
                                    />
                                </GridItem>}
                                
                               {(inputField.produit && inputField.produit!=="" )&& <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomInput
                                        required
                                        id='duree'
                                        name="duree" 
                                        label="Durée du contrat en années (minimum 5 ans)"
                                        formControlProps={{
                                            fullWidth: true,
                                        
                                        }}
                                        value={inputField.duree}
                                        type='number'
                                        variant="outlined"
                                        onChange={handleChange}
                                    />
                                </GridItem>}
                                {(inputField.produit && inputField.produit!=="" )&& <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <CustomRadio
                                        name="periodicite"
                                        id='periodicite'
                                        onChange={handleChange}
                                        label="Périodicité de la cotisation à payer"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        row
                                        value={inputField.periodicite}
                                        options={[
                                            {"label":"Mois","value":"M"},
                                            {"label":"Trimestre","value":"T"},
                                            {"label":"Semestre","value":"S"},
                                            {"label":"Année","value":"A"}
                                        ]}
                                        />
                                </GridItem>}
                               
                            </GridContainer>
                        </GridItem>
                        {inputField.isValid&& <GridItem xs={12} sm={4} md={4} lg={4}>
                            <div style={{display:'flex', width:'100%',height:'50%',justifyContent:'center',flexDirection:'column',alignItems:'center', marginTop:50,borderWidth:1,borderStyle:'dashed', }}>
                                <Typography varaint='h5' component='h5'>PRIME MENSUELLE: </Typography>
                                <Typography style={{fontSize:30}} color='primary' varaint='h2' component='h3'>{inputField.prime?formatNumber(parseInt(inputField.prime)):0} F CFA </Typography>
                                <Divider />
                            </div> 
                        </GridItem>}
                       
                    </GridContainer>
                    {inputField.isValid&& <GridContainer>
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                            <Button variant='outlined' color='primary' className={classes.buttonAdd} onClick={EvaluerLaprime}>
                                Evaluer la prime
                            </Button>   
                        </GridItem>
                        {verifRule('prop','add',privileges)&&(<GridItem xs={12} sm={12} md={6} lg={6}>
                            <Button variant='outlined' color='secondary' disabled={inputField.prime===0} onClick={nouvelleSouscription}>
                                Nouvelle souscription
                            </Button>   
                        </GridItem>)}
                        
                    </GridContainer>}
                    
                </Paper>
            </div>
        </div>
        
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({form: 'adForm',destroyOnUnmount: false,})(Simulateur))
