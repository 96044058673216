import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Paper,Typography, IconButton,Tooltip } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        height: '100%',
    },
    hBox:{
        padding:20,
        marginBottom:20,
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth:8,
        display:'flex',
        justifyContent:'space-between'
    },
    hBoxRed:{
        padding:20,
        marginBottom:20,
        borderLeftColor: "#f9b233",
        borderLeftWidth:8,
        display:'flex',
        justifyContent:'space-between'
    }

  
}))

const TitleBox = ({titre,color,showHomeBtn,showRenitializ,reinitialiser,retourner}) => {
    const classes = useStyles()
  return (
    <div>
        <Paper className={color==='red'?classes.hBoxRed:classes.hBox} variant="outlined">
            <div style={{display:'flex',alignItems:'center'}}>
                {showHomeBtn&&<Tooltip title="Retour"> 
                    <IconButton onClick={retourner} color='primary'>
                        <ArrowBackIosIcon style={{fontSize:30}} />
                    </IconButton>
                </Tooltip>}
                <div className={classes.hContainer}>
                    <Typography variant='h5'>{titre}</Typography>
                </div>
            </div>
            {showRenitializ&&<Tooltip title="Réinitialiser"> 
                <IconButton onClick={reinitialiser} color='primary'>
                    <RotateLeftIcon style={{fontSize:40}} />
                </IconButton>
            </Tooltip>}
        </Paper>
    </div>
  )
}

export default TitleBox