import React from 'react'
import { Paper,Typography,Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomDate from 'components/CustomDate/CustomDate'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import { connect, useSelector } from 'react-redux'
import { Field, reduxForm, change, registerField, getFormValues, reset, untouch } from 'redux-form';
import dayjs from 'dayjs';

const useStyle = makeStyles(theme=>({
  root:{

  },
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:0
  },
  bPaper:{
    padding:10,
    borderRadius:0,
    borderRadius:10
  },
  btnContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:20
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  bhead:{
    padding:10
  }
}))
function StepAdherent(props) {
  const {handleSubmit,handleNext,pristine,submitting,handleBack,steps,activeStep} =props
  const classes = useStyle()
  let mesVilles = useSelector(state => state.api.villes)
  let professions = useSelector(state => state.api.professions)


  return (
    <div>
      <form onSubmit={handleSubmit(handleNext)}>
        <Paper className={classes.bcontainer} variant="outlined">
              <div className={classes.bhead}>
                    <Typography variant='caption'>Informations personnelles de l'adhérent</Typography>
              </div>
              <Paper className={classes.bPaper} variant="outlined">
                  <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                          <Field
                              required
                              label="Civilité "
                              id="civiliteSous"
                              name="civiliteSous"
                              options={[{ "label": "Madame", "value": "Madame" }, { "label": "Mademoiselle", "value": "Mademoiselle" }, { "label": "Monsieur", "value": "Monsieur" }]}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              row
                              component={CustomRadio}
                          />
                      </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                        <Field

                            required
                            label="Nom"
                            id="nomSous"
                            name="nomSous"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            component={CustomInput}
                            autoFocus
                        />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={7}>
                        <Field
                            required
                            label="Prénom"
                            id="prenomSous"
                            name="prenomSous"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            component={CustomInput}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                      <GridItem xs={12} sm={12} md={5}>
                          <Field
                              required
                              label="Date de naissance"
                              id="dateNaissanceSous"
                              name="dateNaissanceSous"
                              formControlProps={{
                                  fullWidth: true,
                              }}
                              InputProps={{
                                  type: 'date',
                                  inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                              }}
                              component={CustomDate}
                              variant="outlined"
                          />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={7}>
                          <Field
                              label="Lieu de naissance"
                              id="lieuNaissanceSous"
                              name="lieuNaissanceSous"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              component={CustomSelect}
                              options={mesVilles}
                              variant="outlined"

                          />
                      </GridItem>
                  </GridContainer>
                  
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                        <Field
                            required
                            label="N° pièce d'identité"
                            id="pieceIdentiteSous"
                            name="pieceIdentiteSous"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            component={CustomInput}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                        <Field
                            label="Nature de la pièce"
                            id="naturePiece"
                            name="naturePiece"
                            options={[{ "label": "Pièce d'identité", "value": "CNI" }, { "label": "Attestation", "value": "AT" }, { "label": "Passport", "value": "Passport" }]}
                            formControlProps={{
                                fullWidth: true
                            }}
                            component={CustomRadio}
                            row
                        />

                    </GridItem>
                </GridContainer>
              </Paper>
              <div className={classes.bhead}>
                    <Typography variant='caption'>Informations complementaires</Typography>
              </div>
              <Paper className={classes.bPaper} variant="outlined">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <Field
                          label="Lieu de résidence"
                          id="lieuResidenceSous"
                          name="lieuResidenceSous"
                          formControlProps={{
                              fullWidth: true
                          }}
                          component={CustomSelect}
                          options={mesVilles}
                          variant="outlined"
                      />

                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                      <Field

                          label="Profession"
                          id="professionSous"
                          name="professionSous"
                          formControlProps={{
                              fullWidth: true
                          }}
                          component={CustomSelect}
                          options={professions}

                      />
                  </GridItem>
              </GridContainer>
              </Paper>
              <div className={classes.bhead}>
                    <Typography variant='caption'>Contacts</Typography>
              </div>
              <Paper className={classes.bPaper} variant="outlined">
                
                      <GridContainer>
                          <GridItem xs={12} sm={12} md={12}>
                              <Field
                                  required
                                  label="email"
                                  id="emailSous"
                                  name="emailSous"
                                  variant="outlined"
                                  component={CustomInput}
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                                  type='email'
                              />
                          </GridItem>
                      </GridContainer>
                      <GridContainer>
                          <GridItem xs={12} sm={6} md={4} lg={4}>
                              <Field
                                  required
                                  label="Mobile 1"
                                  id="mobileSous"
                                  name="mobileSous"
                                  variant="outlined"
                                  component={CustomPhoneInput}
                                  formControlProps={{
                                      fullWidth: true
                                  }}

                              />

                          </GridItem>
                          <GridItem xs={12} sm={6} md={4} lg={4}>
                              <Field
                                  label="Mobile 2"
                                  id="mobile2Sous"
                                  name="mobile2Sous"
                                  variant="outlined"
                                  component={CustomPhoneInput}
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                              />
                          </GridItem>
                          <GridItem xs={12} sm={6} md={4} lg={4}>
                              <Field
                                  label="Téléphone"
                                  id="telephoneSous"
                                  name="telephoneSous"
                                  variant="outlined"
                                  component={CustomPhoneInput}
                                  formControlProps={{
                                      fullWidth: true
                                  }}

                              />
                          </GridItem>
                      </GridContainer>
              </Paper>
              <div className={classes.bhead}>
                    <Typography variant='caption'>Personnes à prévenir en cas de besoins</Typography>
              </div>
              <Paper className={classes.bPaper} variant="outlined">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={8} lg={8}>
                      <Field
                          label="Nom & prénom"
                          id="personneressource"
                          name="personneressource"
                          variant="outlined"
                          formControlProps={{
                              fullWidth: true
                          }}
                          component={CustomInput}

                      />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                      <Field
                          label="Contact"
                          id="contactpersonneressource"
                          name="contactpersonneressource"
                          variant="outlined"
                          formControlProps={{
                              fullWidth: true
                          }}
                          component={CustomPhoneInput}

                      />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8} lg={8}>
                        <Field
                            label="Nom & prénom"
                            id="personneressource2"
                            name="personneressource2"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            component={CustomInput}

                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={4}>
                        <Field
                            label="Contact"
                            id="contactpersonneressource2"
                            name="contactpersonneressource2"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            component={CustomPhoneInput}

                        />
                    </GridItem>
                </GridContainer>
              </Paper>
              <div className={classes.btnContainer}>
                    <Button className={classes.btnback} onClick={handleBack}>Retour</Button>
                    <Button type='submit' disabled={pristine || submitting}  className={classes.btnnext}>Continuer</Button>
              </div>
        </Paper>
      </form>
    </div>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {updateField}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(StepAdherent))


const secteurActivites = [
  { "MonLibelle": 'Agroalimentaire' },
  { "MonLibelle": 'Banque / Assurance' },
  { "MonLibelle": 'Bois / Papier / Carton / Imprimerie' },
  { "MonLibelle": 'BTP / Matériaux de construction' },
  { "MonLibelle": 'Édition / Communication / Multimédia' },
  { "MonLibelle": 'Électronique / Électricité' },
  { "MonLibelle": 'Études et conseils' },
  { "MonLibelle": 'Industrie pharmaceutique' },
  { "MonLibelle": 'Informatique / Télécoms' },
  { "MonLibelle": 'Machines et équipements / Automobile' },
  { "MonLibelle": 'Métallurgie / Travail du métal' },
  { "MonLibelle": 'Plastique / Caoutchouc' },
  { "MonLibelle": 'Services aux entreprises' },
  { "MonLibelle": 'Textile / Habillement / Chaussure' },
  { "MonLibelle": 'Transports / Logistique' },
  { "MonLibelle": 'Autre' }
]