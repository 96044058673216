import React from 'react'
import { Container, Base, Inner, Group, Title, Text, Label, CButton, DisButton } from './style/core'

export default function Core({ children, ...resProps }) {
    return <Container {...resProps}>{children}</Container>
}

Core.Base = function CoreBase({ children, ...restProps }) {
    return <Base {...restProps}>{children}</Base>
}

Core.Inner = function CoreInner({ children, ...restProps }) {
    return <Inner {...restProps}>{children}</Inner>
}

Core.Group = function CoreGroup({ children, ...restProps }) {
    return <Group {...restProps}>{children}</Group>
}
Core.Title = function CoreTitle({ children, ...restProps }) {
    return <Title {...restProps}>{children}</Title>
}

Core.Text = function CoreText({ children, ...restProps }) {
    return <Text {...restProps}>{children}</Text>
}

Core.Label = function CoreLabel({ children, ...restProps }) {
    return <Label {...restProps}>{children}</Label>
}

Core.CButton = function CoreCButton({ children, ...restProps }) {
    return <CButton {...restProps}>{children}</CButton>
}


Core.DisButton = function CoreDisButton({ children, ...restProps }) {
    return <DisButton {...restProps}>{children}</DisButton>
}

