import React,{useState} from 'react'
import PartenaireList from './PartenaireList'
import AddPartenaire from './AddPartenaire'
import FichePartenaire from './FichePartenaire'

function PartenaireHome() {
    const [fonctionShow,setFonctionShow] =useState('LIST')
    const handleFonctionShow =(show,cdata)=>{
        if(show === 'UP' ){setMode('UP')}
        setData(cdata)
        setFonctionShow(show)
    }
    const [data,setData] =useState(null)
    const [mode,setMode] =useState('ADD')
  return (
    <div>
        {(fonctionShow==='LIST')&&<PartenaireList fonctionShow={fonctionShow} handleFonctionShow={handleFonctionShow} />}
        {(fonctionShow==='ADD' || fonctionShow==='UP')&&<AddPartenaire data={data} mode={mode} fonctionShow={fonctionShow} handleFonctionShow={handleFonctionShow} />}
        {(fonctionShow==='FICHE')&&<FichePartenaire data={data} fonctionShow={fonctionShow} handleFonctionShow={handleFonctionShow} />}
    </div>
  )
}

export default PartenaireHome