import { Paper, Typography } from '@material-ui/core'
import React from 'react'
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PageComponent = ({colors="#f9b233",titre,children,size,leftButtons,rightButons}) => {
  return (
    <div className='flex flex-col w-full p-2'>
        <Paper className={
          classNames(`flex justify-between items-center py-4 border-l-[${colors}] border-l-8 m-2 lg:max-w-screen-md md:max-w-screen-md mx-auto w-full`)
          } variant="outlined">
            {leftButtons}
            <div className="flex justify-between items-center px-4">
                <Typography variant='h5'>{titre}</Typography>
            </div>
            {rightButons}
        </Paper>
        {children}
    </div>
  )
}

export default PageComponent