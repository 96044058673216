import React ,{useState,useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector,connect} from 'react-redux';
import {getFiliations} from 'redux/actions/apiActions'
import { Formik, Form } from "formik";
import * as yup from "yup";
import moment from "moment";
import CustomInput from 'components/CustomInput/CustomInput'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomDate from 'components/CustomDate/CustomDate'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import withWidth from '@material-ui/core/withWidth';

import {formValueSelector} from 'redux-form'

const useStyles = makeStyles(theme => ({
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  rootTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    
  },
}));

function ProfilModalFormBenef(props) {
  const classes =    useStyles()
  const {open,handleClose,handleAddAssure} = props

  let beneficiaireSchema = yup.object().shape({
        nomBeneficiaire: yup.string().required("nom du bénéficiaire ne doit pas être vide").min(2, "Nom souscripteur trop court"),
        prenomBeneficiaire: yup.string().required("Prénom du bénéficiaire ne doit pas être vide").min(2, "Prénom souscripteur trop court"),
        emailBeneficiaire: yup.string()
        .email("Adresse email incorrect")
        .required("Adresse email obligatoire"),
        dateNaissanceBeneficiaire: yup.date().required("Date de naissance obligatoire")
        .min(moment('01-01-1920','DD-MM-YYYY'),'La date de naissance doit être inféririeure au 01/01/1920'),
        telephoneBeneficiaire:yup.string().required("Numéro de téléphone obligatoire")
     });
  
      //get value off villes
    let  mesVilles = useSelector(state=>state.api.villes)
    if(mesVilles.length===0){
      mesVilles=JSON.parse(localStorage.APIVilles);
    }

    //get filaiation value
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
      getFiliations().then((fil)=>{
        setFiliations(fil);
      })
     
    }, [])
    
    const submit=(values)=>{
      let dataAssure =JSON.stringify(values,null,2);
      dataAssure = JSON.parse(dataAssure)
      handleAddAssure(dataAssure)
    }
    
    return (
      <Formik
      initialValues={{
       nomBeneficiaire: '', 
       prenomBeneficiaire: '',
       dateNaissanceBeneficiaire:'',
       lieuNaissanceBeneficiaire:'',
       lieuResidenceBeneficiaire:'',
       filiationBeneficiaire:'',
       telephoneBeneficiaire:'',
       emailBeneficiaire:'',
       taux:'',
       monIndex:0
      }}
      validationSchema={beneficiaireSchema}
     onSubmit={(values)=>submit(values)}
    >
      {({errors,handleReset,handleSubmit,isValid,dirty,isSubmitting,setFieldValue,handleChange, touched,values})=>(
        <Form>
            <Dialog fullWidth maxWidth={props.width} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Nouveau bénéficiaire</DialogTitle>
          <Divider />

          <DialogContent> 
            <GridContainer>
              <GridItem item xs={12} sm={12} md ={12} lg={12}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md ={4} lg={4}>
                    <CustomInput
                        required
                        label="Nom"
                        id="nomBeneficiaire"
                        name="nomBeneficiaire"
                        variant="outlined"
                        formControlProps={{
                            fullWidth: true
                        }}
                        value={values.nomBeneficiaire}
                        onChange={handleChange}
                        autoFocus
                        error={errors.nomBeneficiaire && touched.nomBeneficiaire}
                        helperText={
                        errors.nomBeneficiaire && touched.nomBeneficiaire
                            ? errors.nomBeneficiaire
                            : null
                        }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md ={8} lg={8}>
                    <CustomInput
                        required
                        label="Prénom"
                        id="prenomBeneficiaire"
                        name="prenomBeneficiaire"
                        variant="outlined"
                        formControlProps={{
                            fullWidth: true
                        }}
                        value={values.prenomBeneficiaire}
                        onChange={handleChange}
                        autoFocus
                        error={errors.prenomBeneficiaire && touched.prenomBeneficiaire}
                        helperText={
                        errors.prenomBeneficiaire && touched.prenomBeneficiaire
                            ? errors.prenomBeneficiaire
                            : null
                        }
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md ={4} lg={4}>
                    <CustomDate
                      required
                      label="Date de naissance"
                      id="dateNaissanceBeneficiaire"
                      name="dateNaissanceBeneficiaire"
                      formControlProps={{
                          fullWidth: true,
                      }}
                      InputProps={{
                          type:'date',
                          value:values.dateNaissanceBeneficiaire
                      }}
                      onChange={handleChange}
                      autoFocus
                      error={errors.dateNaissanceBeneficiaire && touched.dateNaissanceBeneficiaire}
                        helperText={
                          errors.dateNaissanceBeneficiaire && touched.dateNaissanceBeneficiaire
                            ? errors.dateNaissanceBeneficiaire
                            : null
                        }
                      />
                  </GridItem>
                  <GridItem xs={12} sm={8} md ={8} lg={8}>
                    <CustomSelect
                      label="Lieu de naissance"
                      id="lieuNaissanceBeneficiaire"
                      name="lieuNaissanceBeneficiaire"
                      formControlProps={{
                          fullWidth: true
                      }}
                      value={values.lieuNaissanceBeneficiaire.MonLibelle}
                      options={mesVilles}
                      onChange={handleChange}
                      error={errors.lieuNaissanceBeneficiaire && touched.lieuNaissanceBeneficiaire}
                      helperText={
                          errors.lieuNaissanceBeneficiaire && touched.lieuNaissanceBeneficiaire
                            ? errors.lieuNaissanceBeneficiaire
                            : null
                        }
                      />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem item xs={12} sm={6} md ={6} lg={6}>
                    <CustomSelect
                      label="Lieu de résidence"
                      id="lieuResidenceBeneficiaire"
                      name="lieuResidenceBeneficiaire"
                      formControlProps={{
                          fullWidth: true
                      }}
                      value={values.lieuResidenceBeneficiaire.MonLibelle}
                      options={mesVilles}
                      onChange={handleChange}
                      error={errors.lieuResidenceBeneficiaire && touched.lieuResidenceBeneficiaire}
                      helperText={
                          errors.lieuResidenceBeneficiaire && touched.lieuResidenceBeneficiaire
                            ? errors.lieuResidenceBeneficiaire
                            : null
                        }
                      />
                  </GridItem>
                  <GridItem  xs={12} sm={6} md ={6} lg={6}>
                          <CustomSelect
                              label="Lien de parenté"
                              id="filiationBeneficiaire"
                              name="filiationBeneficiaire"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={values.filiationBeneficiaire.MonLibelle}
                              options={filiations}
                              onChange={handleChange}
                              error={errors.filiationBeneficiaire && touched.filiationBeneficiaire}
                              helperText={
                                  errors.filiationBeneficiaire && touched.filiationBeneficiaire
                                  ? errors.filiationBeneficiaire
                                  : null
                              }
                            />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                        <GridItem  xs={12} sm={4} md ={4} lg={4}>
                          <CustomInput
                              required
                              label="Téléphone"
                              id="telephoneBeneficiaire"
                              name="telephoneBeneficiaire"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={values.telephoneBeneficiaire}
                              onChange={handleChange}
                              autoFocus
                              error={errors.telephoneBeneficiaire && touched.telephoneBeneficiaire}
                              helperText={
                                errors.telephoneBeneficiaire && touched.telephoneBeneficiaire
                                    ? errors.telephoneBeneficiaire
                                    : null
                              }
                            />
                        </GridItem>
                        <GridItem  xs={12} sm={8} md ={8} lg={8}>
                          <CustomInput
                              required
                              label="Addresse email"
                              id="emailBeneficiaire"
                              name="emailBeneficiaire"
                              type="email"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={values.emailBeneficiaire}
                              onChange={handleChange}
                              autoFocus
                              error={errors.emailBeneficiaire && touched.emailBeneficiaire}
                              helperText={
                              errors.emailBeneficiaire && touched.emailBeneficiaire
                                  ? errors.emailBeneficiaire
                                  : null
                              }
                            />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem item xs={12} sm={4} md ={4} lg={4}>
                          <CustomInput
                            required
                            label="Part"
                            id="taux"
                            name="taux"
                            variant="outlined"
                            formControlProps={{
                                fullWidth: true
                            }}
                            value={values.taux}
                            onChange={handleChange}
                            autoFocus
                            error={errors.taux && touched.taux}
                            helperText={
                            errors.taux && touched.taux
                                ? errors.taux
                                : null
                            }
                      />
                        </GridItem>
                      </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Annuler
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </Dialog>
        </Form>
      )}
      
    </Formik>
    );
  }
  const selector = formValueSelector('adForm')
  const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user
  });
  
  const mapActionsToProps = {
   
  };
  ProfilModalFormBenef = withWidth()(ProfilModalFormBenef)
  export default connect(mapStateToProps,mapActionsToProps)(ProfilModalFormBenef)
