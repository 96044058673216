import React, { useState, useReducer, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Field, reduxForm, change, registerField, FieldArray, getFormValues, reset } from 'redux-form';
import MaterialTable, { MTableToolbar } from 'material-table';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import Button from "components/CustomButtons/Button.js";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CustomDate from 'components/CustomDate/CustomDate'

import AddIcon from '@material-ui/icons/Add';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import CustomContrySelect from 'components/CustomContrySelect/CustomContrySelect'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getProduit } from 'redux/actions/apiActions'
import { DropzoneArea } from 'material-ui-dropzone'
import moment from "moment";
import "moment/locale/fr";
import { ageAssure, getBrancheCodeProduit } from 'redux/actions/userActions'
import SearchClient from 'components/SearchClient/SearchClient'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { addSouscription, getPrimesCapital, getPrimeGarantie } from 'redux/actions/apiActions'
import CustomCapitalSelect from 'components/CustomCapitalSelect/CustomCapitalSelect'
import ProfilAssure from 'pages/components/ProfilAssure'
import ProfilBeneficiaire from 'pages/components/ProfilBeneficiaire'
import ProfilSouscipteurInfo from 'pages/components/ProfilSouscipteurInfo'
import SouscriptionFicheresume from 'pages/components/SouscriptionFicheresume'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import { Grid, Box } from '@material-ui/core'
import DocumentSouscription from 'pages/components/DocumentSouscription'
import dayjs from 'dayjs';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import Alert from '@material-ui/lab/Alert';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import CustomInputNumber from 'components/CustomInputNumber/CustomInputNumber'
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
const customTyles = (theme, styles) => ({
    ...styles,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    rootExpan: {
        width: '100%',
    },
    heading: {
        fontSize: 26,
        fontWeight: 'bold',
    },
    paper: {
        width: '100%'
    },
    list: {
        width: '100%',
        padding: 5
    },
    containerRoot: {

    },
    buttonAdd: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    buttonSelect: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'transparent',
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
            backgroundColor: 'transparent',
        }
    },
    cadPrime: {
        borderLefColor: theme.palette.primary.main,
        borderWidth: 10,
        width: '100%',
        marginTop: 20
    },
    buttonPrime: {
        width: '100%',
        marginBottom: 10,
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        borderRadius: 20,
        '&:hover': {
            borderColor: theme.palette.primary.main,
        }
    },
    fileZone: {

    },
    buttonAction: {
        marginLeft: 10,
        height: 50
    }

})
const useStyles = makeStyles(customTyles)



function AddContrat(props) {

    const { handleSubmit, isValid, touched, errors, isDirty, isPristine, isInvalid, user } = props;
    const [activeStep, setActiveStep] = useState(0)

    const handleNext = () => {
        setActiveStep(activeStep + 1)
        handleEtpapeParcourru(activeStep + 1)
    };
    const handleRedirectSouscription = (data) => {
        if (user.credentials.hasOwnProperty('branche')) {
            if (user.credentials.branche === 'BANKASS') {
                setSvData(data)
                handleNext()
            } else {
                setSvData(null)
                props.history.push('/admin/propositions', { souscription: data });
            }
        } else {
            setSvData(null)
            props.history.push('/admin/propositions', { souscription: data });
        }
    }
    const [svData, setSvData] = useState(null)
    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1)
            setActiveStep(activeStep - 1)
        } else {
            setActiveStep(0)
            handleEtpapeParcourru(0)
        }
    };

    const dispatch = useDispatch();
    const handlegetProduit = () => {
        if (user.credentials.hasOwnProperty('branche')) {
            dispatch(getProduit(user.credentials.branche))
        }
    };

    useEffect(() => {
        handlegetProduit();
    }, [user]);

    let produits = useSelector(state => state.api.produits);

    const [primeCalculer, setPrimecalculer] = useState(false)
    const [primeLoading, setPrimeLoading] = useState(false)
    const [capitalLoading, setCapitalLoading] = useState(false)
    const [mesProduits, setMesproduits] = useState(produits);
    const [openAlert, setOpenAlert] = useState(false)
    const [capitaux, setCapitaux] = useState(null)
    const [primeGarantie, setPrimeGarantie] = useState(null)
    const [resume, setResume] = useState(false)
    const handleResume = () => {
        setResume(!resume)
    }

    const [parcourru, setParcourru] = useState([])
    const handleEtpapeParcourru = (etape) => {

        const currentIndex = parcourru.findIndex(elt => elt === etape);
        const newParcours = [...parcourru];
        if (currentIndex === -1) {
            newParcours.push(etape);
        }/* else {
            newParcours.splice(currentIndex, 1);
        }*/
        setParcourru(newParcours)
    }

    const handlePrimeGaranties = (grantiesPrimes) => {
        setPrimeGarantie(grantiesPrimes)
    }
    const handleAlerte = (val) => {
        setMesErrors(null)
        setOpenAlert(val)
    }
    //mise à jour des erreurs
    const handleErrors = (errors) => {
        setMesErrors(errors)
        setOpenAlert(true)
    }
    //modale search
    const [open, setOpen] = useState(false)
    const handleOpenCleintSeach = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const addClientSelect = (mesDonnes) => {
        if (mesDonnes) {
            setSouscripteurData({
                civiliteSous: mesDonnes.CodeCivilite === '1' ? 'M' : mesDonnes.CodeCivilite === '2' ? 'Mme' : mesDonnes.CodeCivilite === '3' ? 'Mlle' : 'M',
                nomSous: mesDonnes.Nom,
                prenomSous: mesDonnes.Prenom,
                dateNaissanceSous: moment(mesDonnes.DateNaissance).format('YYYY-MM-DD'),
                lieuNaissanceSous: mesDonnes.LieuNaissance,
                lieuResidenceSous: mesDonnes.LieuResidence,
                professionSous: mesDonnes.profession,
            })

        }
        setOpen(false)
    }
    //end modale close search



    //gestion des fichiers
    const classes = useStyles();
    const [mesErrors, setMesErrors] = useState(null)
    const [souscripteurData, setSouscripteurData] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            assures: '',
            beneficiaires: '',
            produitsSous: '',
        });


    //Fitre de la table de produit
    const customFilter = (columFilter, tableFilter, valueSeach) => {
        const isproduit = (item) => {
            let result = false
            for (const key of columFilter) {
                if (item[key]) {
                    if (item[key].toUpperCase().indexOf(valueSeach.toUpperCase()) !== -1) {
                        result = true
                        break;
                    } else {
                        result = false
                    }
                } else {
                    result = false
                }
            }
            return result
        }
        return tableFilter.filter(isproduit)
    }
    const findStep = (ref) => {
        if (parcourru.findIndex(elt => elt === ref) === -1) { return false } else { return true }
    }
    const [selectedRow, setSelectedRow] = useState(null)
    const [produitData, setProduitData] = React.useState({
        columns: [
            {
                title: 'code produit',
                field: 'codeProduit',
                cellStyle: { fontSize: 30, padding: 0, color: '#000', fontWeight: 'bold' },
                render: rowData => <List className={classes.list}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar variant="square" sizes={50}>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${rowData.MonLibelle}`} secondary={
                            <React.Fragment>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary"
                                    display='block'
                                >
                                    {rowData.DureCotisationAns ? `Durée ${rowData.DureCotisationAns}` : ""}
                                </Typography>
                                {rowData.CodeProduitNature && `${'\n'} Type : ${rowData.CodeProduitNature} \n Souscription : ${rowData.DureSouscriptionAns ? rowData.DureSouscriptionAns : ""}`}

                            </React.Fragment>
                        } />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => handleCheckedProduit(rowData)} style={{ backgroundColor: '#E35F5B', height: '100%', width: '100%' }} edge="end" aria-label="suivant">
                                <ArrowForwardIosIcon />

                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>,
            },
            { title: 'Libelle', field: 'MonLibelle', hidden: true },
            { title: 'Durée cotisation', field: 'DureeCotisationAns', type: 'numeric', hidden: true },
            { title: 'Durée souscription', field: 'DureeSouscriptionAnnee', type: 'numeric', hidden: true },
            { title: 'Type', field: 'CodeProduitNature', hidden: true },
        ]
    });



    let mesVilles = useSelector(state => state.api.villes)
    let societes = useSelector(state => state.api.societes)
    let professions = useSelector(state => state.api.professions)
    let dateMax = moment().add('year', -12).format('YYYY-MM-DD').split('T')[0]

    //selection d'une linge de produit

    const handleClientSelect = (event, myrow) => {
        setSelectedRow(myrow)

        if (!props.hasOwnProperty('produit')) {
            props.dispatch(registerField("adForm", 'produit', 'Field'));
        }
        props.updateField('produit', myrow)
        switch (myrow.CodeProduit) {
            case 'YKE_2008': case 'YKS_2008': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
                setPrimecalculer(true)
                getPrimesCapital(myrow.CodeProduit).then((data) => {
                    setCapitaux(data)
                })
                break;
            default:
                setPrimecalculer(false)
                break;
        }
    }
    const handleCheckedProduit = (myrow) => {

        setSelectedRow(myrow)

        if (!props.hasOwnProperty('produit')) {
            props.dispatch(registerField("adForm", 'produit', 'Field'));
        }
        props.updateField('produit', myrow)
        switch (myrow.CodeProduit) {
            case 'YKE_2008': case 'YKS_2008': case 'YKF_2008': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004':
                setPrimecalculer(true)
                getPrimesCapital(myrow.CodeProduit).then((data) => {
                    setCapitaux(data)
                })
                break;
            default:
                setPrimecalculer(false)
                break;
        }

        handleEtpapeParcourru(1)
        setActiveStep(1)
    }
    //stepper
    const getSteps = () => {
        return [
            'Produit',
            'Adhérent',
            'Assuré(e)s?',
            'Bénéficiaires?',
            'Paiement & périodicité',
            'Document'
        ];
    };

    const renderError = () => (
        <Dialog
            open={openAlert}
            onClose={() => handleAlerte(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='sm'
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{"Erreur"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {mesErrors && <Typography styles={{ color: 'red', fontSize: 14 }}>{mesErrors.general}</Typography>}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleAlerte(false)} color="secondary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )

    //affichage liste des produits
    const renderProduitTable = () => (
        <MaterialTable
            title={null}
            columns={produitData.columns}
            data={query => new Promise((resolve, reject) => {
                const columnSearch = ['CodeProduit', 'MonLibelle', 'DureeCotisationAns', 'DureeSouscriptionAnnee', 'CodeProduitNature']

                resolve({
                    data: customFilter(columnSearch, produits, query.search)
                })
            })}
            onRowClick={((evt, selectedRow) => handleClientSelect(evt, selectedRow))}
            components={{
                Toolbar: props => (
                    <div style={{ backgroundColor: '#e8eaf5' }}>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Trouver un produit',
                    searchTooltip: 'Trouver un produit'
                },
                body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                },
                pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                }
            }}
            options={{
                search: true,
                searchFieldAlignment: "left",
                searchFieldStyle: {
                    fontSize: 18,
                    width: '100%',
                    height: 50,
                },
                header: false,
                rowStyle: rowData => ({
                    backgroundColor: (selectedRow && selectedRow.tableData.id === rowData.tableData.id) ? '#FFF1EB' : '#FFF'
                })
            }}
        />
    )
    const NouvelleSouscription = () => {
        dispatch(reset('adForm'))
    }

    const renderActionBar = () => {
        return (
            <GridContainer className={classes.rootfab} style={{ textAlign: "right", marginBottom: 20 }} >
                <GridItem sm={3} xs={3} md={3} lg={3} >
                    <Typography className={classes.root}>
                        <Link href="/admin/pret-liste" >
                            Retour à la liste
                        </Link>
                    </Typography>
                </GridItem>
                <GridItem sm={9} xs={9} md={9} lg={9} >
                    <Button type='reset' onClick={NouvelleSouscription} color="secondary" aria-label="add" variant="contained" disableRipple={false} style={{ marginRight: 10 }}>
                        <AddIcon size='large' className={classes.extendedIcon} />
                        Nouvelle souscription
                    </Button>
                </GridItem>
            </GridContainer>

        )
    }

    const handleSubmitSend = (values) => {
        //verifier la présen des assure
        let dataSouscription
        dataSouscription = JSON.stringify(values, null, 2);
        dataSouscription = JSON.parse(dataSouscription)
    }

    const steps = getSteps()


    return (
        <>
            <GridContainer>
                <GridItem sm={12} xs={12} md={12} lg={12} >
                    {(activeStep > 0) ? (
                        <>
                            {renderActionBar()}
                            <GridContainer direction='column' spacing={1} className={classes.core}>
                                <GridItem sm={12} xs={12}>
                                    <Paper className={classes.paper}>
                                        <Stepper activeStep={activeStep} alternativeLabel>
                                            {steps.map(label => (
                                                <Step key={label}>
                                                    <StepLabel color='primary'>{label}</StepLabel>
                                                </Step>
                                            ))}
                                        </Stepper>
                                        <Divider component="hr" className={classes.hTrait} />
                                    </Paper>
                                </GridItem>
                            </GridContainer>
                            <GridContainer >
                                <GridItem style={{ marginTop: 10 }} xs={12} sm={12} md={12} lg={12}> <Alert severity="error">Tous les champs marqués d'un " * " sont obligatoires</Alert></GridItem>

                            </GridContainer>
                            <SearchClient open={open} mode={user.credentials.branche} handleClose={handleClose} addClientSelect={addClientSelect} />
                            {renderError()}
                            {(!resume || activeStep < 6) ? (
                                <>
                                    <ExpansionPanel
                                        className={classes.rootExpan}
                                        style={{ marginTop: 20 }}
                                        disabled={!findStep(1)}
                                        expanded={activeStep === 1}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"

                                        >
                                            <Typography className={classes.heading}>ADHERENT</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>

                                            <Paper elevation={0} className={classes.paper}>
                                                <form onSubmit={handleSubmit(handleNext)}>
                                                    <GridContainer container className={classes.containerRoot} >
                                                        <GridItem xs={12} sm={12} md={12} lg={12}>
                                                            <Button size='large' variant='outlined' className={classes.buttonSelect} color="inherit" onClick={handleOpenCleintSeach}>
                                                                <Typography color='primary'>Selectionnez un client / prospect</Typography>
                                                            </Button>
                                                            <Divider orientation="horizontal" style={{ marginBottom: 20, marginTop: 20 }} />
                                                        </GridItem>

                                                        <GridItem xs={12} sm={12} md={12} lg={12}>

                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <Field
                                                                        required
                                                                        label="Civilité "
                                                                        id="civiliteSous"
                                                                        name="civiliteSous"
                                                                        options={[{ "label": "Mme", "value": "Mme" }, { "label": "Mlle", "value": "Mlle" }, { "label": "M", "value": "M" }]}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                        row
                                                                        component={CustomRadio}
                                                                        autoFocus

                                                                    />
                                                                </GridItem>

                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field

                                                                        required
                                                                        label="Nom"
                                                                        id="nomSous"
                                                                        name="nomSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}
                                                                        autoFocus


                                                                    />
                                                                </GridItem>

                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        required
                                                                        label="Prénom"
                                                                        id="prenomSous"
                                                                        name="prenomSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}
                                                                        autoFocus


                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field
                                                                        required
                                                                        label="Date de naissance"
                                                                        id="dateNaissanceSous"
                                                                        name="dateNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true,
                                                                        }}

                                                                        InputProps={{
                                                                            type: 'date',
                                                                            inputProps: { max: dayjs().add(-12, 'year').format('YYYY-MM-DD'), min: dayjs().add(-100, 'year').format('YYYY-MM-DD') },
                                                                        }}
                                                                        component={CustomDate}
                                                                        variant="outlined"

                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        label="Lieu de naissance"
                                                                        id="lieuNaissanceSous"
                                                                        name="lieuNaissanceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={mesVilles}
                                                                        variant="outlined"

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={5}>
                                                                    <Field

                                                                        required
                                                                        label="N° pièce d'identité"
                                                                        id="pieceIdentiteSous"
                                                                        name="pieceIdentiteSous"
                                                                        variant="outlined"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomInput}
                                                                        autoFocus

                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={7}>
                                                                    <Field

                                                                        label="Nature de la pièce"
                                                                        id="naturePiece"
                                                                        name="naturePiece"
                                                                        options={[{ "label": "CNI", "value": "CNI" }, { "label": "Attestation", "value": "AT" }, { "label": "Passport", "value": "Passport" }]}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomRadio}
                                                                        row
                                                                        autoFocus

                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field
                                                                        label="Lieu de résidence"
                                                                        id="lieuResidenceSous"
                                                                        name="lieuResidenceSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={mesVilles}
                                                                        variant="outlined"
                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field

                                                                        label="Profession"
                                                                        id="professionSous"
                                                                        name="professionSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        component={CustomSelect}
                                                                        options={professions}

                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field
                                                                        label="Secteur d'activité"
                                                                        id="employeurSous"
                                                                        name="employeurSous"
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                        options={secteurActivites}
                                                                        component={CustomSimpleSelect}

                                                                    />
                                                                </GridItem>
                                                            </GridContainer>

                                                        </GridItem>

                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12} lg={12} style={{ borderWidthLeft: 1, borderLefColor: 'secondary', }}>


                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={6}>
                                                                    <Field

                                                                        label="email"
                                                                        id="emailSous"
                                                                        name="emailSous"
                                                                        variant="outlined"
                                                                        component={CustomInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        type='email'


                                                                    />

                                                                </GridItem>
                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        required
                                                                        label="Mobile 1"
                                                                        id="mobileSous"
                                                                        name="mobileSous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                    />

                                                                </GridItem>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        label="Mobile 2"
                                                                        id="mobile2Sous"
                                                                        name="mobile2Sous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                    />
                                                                </GridItem>
                                                                <GridItem xs={12} sm={3} md={3} lg={3}>
                                                                    <Field
                                                                        label="Téléphone"
                                                                        id="telephoneSous"
                                                                        name="telephoneSous"
                                                                        variant="outlined"
                                                                        component={CustomPhoneInput}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}

                                                                    />

                                                                </GridItem>


                                                            </GridContainer>
                                                            <GridContainer>
                                                                <GridItem xs={12} sm={12} md={12}>
                                                                    <LabelledOutline label="Personnes à contacter en cas de besoins">
                                                                        <GridContainer>
                                                                            <GridItem xs={12} sm={12} md={6}>
                                                                                <Field
                                                                                    label="Nom & prénom"
                                                                                    id="personneRessource"
                                                                                    name="personneRessource"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomInput}

                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={3} lg={3}>
                                                                                <Field
                                                                                    label="Contact"
                                                                                    id="contactPersonneRessource"
                                                                                    name="contactPersonneRessource"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomPhoneInput}

                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                        <GridContainer>
                                                                            <GridItem xs={12} sm={12} md={6}>
                                                                                <Field
                                                                                    label="Nom & prénom"
                                                                                    id="personneRessource2"
                                                                                    name="personneRessource2"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomInput}

                                                                                />
                                                                            </GridItem>
                                                                            <GridItem xs={12} sm={12} md={3} lg={3}>
                                                                                <Field
                                                                                    label="Contact"
                                                                                    id="contactPersonneRessource2"
                                                                                    name="contactPersonneRessource2"
                                                                                    variant="outlined"
                                                                                    formControlProps={{
                                                                                        fullWidth: true
                                                                                    }}
                                                                                    component={CustomPhoneInput}

                                                                                />
                                                                            </GridItem>
                                                                        </GridContainer>
                                                                    </LabelledOutline>
                                                                </GridItem>


                                                            </GridContainer>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <Divider component="hr" className={classes.hTrait} />


                                                    <Grid container direction="row" justify="space-between" alignItems="center">

                                                        <Button type="button" onClick={handleBack}>
                                                            Retour
                                                        </Button>


                                                        <Button type='submit' color='primary' >
                                                            Continuer
                                                            <NavigateNextOutlinedIcon />
                                                        </Button>

                                                    </Grid>
                                                </form>
                                            </Paper>

                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                    <ExpansionPanel disabled={!findStep(2)} expanded={activeStep === 2}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"

                                        >
                                            <Typography className={classes.heading}>ASSURE(E)S</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                {findStep(2) && <ProfilAssure handleBack={handleBack} step={activeStep} hBack={handleBack} onSubmit={handleNext} btnshow={true} />}

                                            </Paper>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <ExpansionPanel disabled={!findStep(3)} expanded={activeStep === 3}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"

                                        >
                                            <Typography className={classes.heading}>BENEFICIAIRES</Typography>
                                        </ExpansionPanelSummary>

                                        <ExpansionPanelDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                {findStep(3) && <ProfilBeneficiaire handleBack={handleBack} step={activeStep} hBack={handleBack} onSubmit={handleNext} btnshow={true} />}


                                            </Paper>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <ExpansionPanel disabled={!findStep(4)} expanded={activeStep === 4}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                        >
                                            <Typography className={classes.heading}>CONDITIONS DE PAIEMENT DE LA PRIME & PERIODICITE</Typography>
                                        </ExpansionPanelSummary>

                                        <ExpansionPanelDetails>
                                            <Paper elevation={0} className={classes.paper}>
                                                <GridContainer>
                                                    {findStep(4) && <ProfilSouscipteurInfo handleResume={handleResume} handleBack={handleBack} step={activeStep} hBack={handleBack} onSubmit={handleNext} btnshow={true} />}
                                                </GridContainer>

                                            </Paper>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                    <ExpansionPanel disabled={!findStep(5)} expanded={activeStep === 5}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"

                                        >
                                            <Typography className={classes.heading}>DOCUMENTS</Typography>
                                        </ExpansionPanelSummary>

                                        <ExpansionPanelDetails>
                                            <DocumentSouscription step={activeStep} svdata={svData} handleBack={handleBack} onSubmit={handleNext} btnshow={true} />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </>
                            ) : (
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12}>
                                        <SouscriptionFicheresume step={activeStep} handleResume={handleResume} handleBack={handleBack} onSubmit={() => handleSubmit(handleNext)} btnshow={true} handleRedirectSouscription={handleRedirectSouscription} />
                                    </GridItem>
                                </GridContainer>

                            )}

                        </>

                    ) :
                        (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    <div style={{ borderWidth: 2, borderStyle: 'dashed', marginBottom: 20 }}>
                                        <Typography style={{ textAlign: 'center' }} variant='h5' component='h4' color='primary'>Veuillez selectionner un produit pour demarrer la souscription</Typography>
                                    </div>

                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={12}>
                                    {renderProduitTable()}
                                </GridItem>
                            </GridContainer>
                        )
                    }
                </GridItem>
            </GridContainer>
        </>
    )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    addSouscription,
    updateField
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
})(AddContrat))

const secteurActivites = [
    'Agroalimentaire',
    'Banque / Assurance',
    'Bois / Papier / Carton / Imprimerie',
    'BTP / Matériaux de construction',
    'Chimie / Parachimie',
    'Commerce / Négoce / Distribution',
    'Édition / Communication / Multimédia',
    'Électronique / Électricité',
    'Études et conseils',
    'Industrie pharmaceutique',
    'Informatique / Télécoms',
    'Machines et équipements / Automobile',
    'Métallurgie / Travail du métal',
    'Plastique / Caoutchouc',
    'Services aux entreprises',
    'Textile / Habillement / Chaussure',
    'Transports / Logistique',
    'Autre',


]
