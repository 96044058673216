import React, { useState, useEffect, useReducer } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { getFiliations } from 'redux/actions/apiActions'
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const useStyles = makeStyles(theme => ({
    dcard: {
        marginBottom: 10,
        paddingBottom: 10,
        flexWrap: 'wrap'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    coreCustum: {
        borderRadius: 0,
        borderLeft: "10px solid #E35F5B"
    },
    itemTitle: {
        fontStyle: "italic"
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        marginBottom: 20,
        padding: 10
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
}))
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ModalInfoDeclarant(props) {
    const { open, handleClose, data, handleAlertClose, handleSaveOperation, handleAlertOpen } = props
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
        getFiliations().then((fil) => { setFiliations(fil); })
    }, [])

    const [filterField, setFilterField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            nomdeclarant: data.nomdeclarant,
            prenomdeclarant: data.prenomdeclarant,
            filiationdeclarant: data.filiationdeclarant,
            emaildeclarant: data.emaildeclarant,
            telephonedeclarant: data.telephonedeclarant
        });
    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFilterField({
            [name]: value
        })
    }
    const handleValide = () => {
        const dataSend = filterField
        dataSend['id'] = data.id
        handleSaveOperation(dataSend)
    }

    return (
        <div>
            <Dialog
                onClose={handleClose}
                fullWidth
                disableBackdropClick
                maxWidth={'md'}
                aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {`Edition opération #Ref ${data && data['codeoperation']} - ${data['id']} - Information sur le déclarant`}
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.paper}>
                        <GridContainer>
                            <GridItem sm={12} xs={12} md={6} lg={6}>
                                <CustomInput
                                    required
                                    id="nomdeclarant"
                                    name="nomdeclarant"
                                    label="Nom"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={filterField['nomdeclarant'] || ""}
                                    onChange={handleFilterChange}
                                    type='texte'
                                    variant="outlined"
                                />
                                <CustomInput
                                    required
                                    id="prenomdeclarant"
                                    name="prenomdeclarant"
                                    label="Prénom"
                                    value={filterField['prenomdeclarant'] || ""}
                                    onChange={handleFilterChange}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    type='texte'
                                    variant="outlined"
                                />

                                <CustomSelect
                                    label="Lien de parenté"
                                    id="filiationdeclarant"
                                    name="filiationdeclarant"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={filterField['filiationdeclarant'] || ""}
                                    onChange={handleFilterChange}
                                    options={filiations}
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem sm={12} xs={12} md={6} lg={6}>
                                <CustomInput
                                    id="emaildeclarant"
                                    name="emaildeclarant"
                                    label="Email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={filterField['emaildeclarant'] || ""}
                                    onChange={handleFilterChange}
                                    type='email'
                                    variant="outlined"
                                />
                                <CustomPhoneInput
                                    label="Téléphone"
                                    id="telephonedeclarant"
                                    name="telephonedeclarant"
                                    variant="outlined"
                                    value={filterField['telephonedeclarant'] || ""}
                                    onChange={handleFilterChange}

                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.buttonSearch} autoFocus onClick={handleValide} color="primary" >
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalInfoDeclarant