import React, {useEffect,useState,useReducer} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import MaterialTable,{MTableToolbar } from 'material-table';
import {connect} from 'react-redux'
import CustomMenu from './CustomMenu'
import Button from 'components/CustomButtons/Button'
import {Add} from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import {
  getZonesReseau,
  getRolesReseau,
  getEquipesZones,
  getUtilisateurNoDispatch,
  historiqueEquipeuser} from 'redux/actions/settingsAction'
import AddUtilisateur from './AddUtilisateur'
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs'
import HeaderFonction from '../components/HeaderFonction'
import {verifRule} from 'application'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CustomRadio from 'components/CustomRadio/CustomRadio'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent:'center',
    alignItems:'center'
  },
  details:{
    padding:20,
  },
  container: {
    display: 'flex',
    justifyContent:'stretch',
    flexWrap: 'wrap',
    width:'100%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonSearch:{
    borderWidth:1,
    borderStyle:'dashed',
    borderColor:theme.palette.primary.main,
    backgroundColor:theme.palette.primary.main,
   
    color:'#FFF',
    '&:hover':{
      backgroundColor:theme.palette.secondary.main,
      borderColor:theme.palette.secondary.main,
    }
  }
}));
function UtilisateurList(props){
    const classes = useStyles();
    const {user:{credentials,privileges}}=props
    const [loading,setLoading]=React.useState(false);
    const [addopen, setAddopen] = React.useState(false);
    const [allUtilisateur,setAllUtilisateur] = React.useState([])
    const [zones,setZones] = useState(null)
    const [equipes,setEquipes] = useState(null)
    const [rolesReseau,setRolesReseau] = useState(null)
    const [histDataUser,sethistDataUser] = React.useState(null);
    const [selectedRow, setSelectedRow] = React.useState(null)
    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
      codepartenaire:credentials.codepartenaire?credentials.codepartenaire:null,
      codebranche:credentials.branche?credentials.branche:null,
      codereseau:credentials.codereseau?credentials.codereseau:null,
      codezone:'',
      codeequipe:'',
      coderole:'',
      typemembre:'',
      memberok:'all'
    });
    useEffect(() => {
      if(credentials){
        setLoading(true)
        getUtilisateurNoDispatch(inputField).then(resData=>{
          if(resData){
            setAllUtilisateur(resData)
            setLoading(false)
          }else{
            setLoading(false)
          }
        }).catch(err=>{
          console.log(err)
          setLoading(false)
        })
      }
    }, [])
    
    const handleClickAddOpen = () => {
      setAddopen(true);
    };

    const handleCloseAdd = (e,reason) => {
      if (reason && reason === "backdropClick") {return;}
      setAddopen(false);
    };
 
    const handleFonctionMini =(newvaleur)=>{
      props.handleCliqueFonction(newvaleur)
    }
    useEffect(()=>{
      if(inputField.codereseau!==''){
        getZonesReseau(inputField.codereseau).then(dataZone=>{
            if(dataZone){
                setZones(dataZone)
            }
        })
      }
      if(inputField.codereseau!==''){
        getRolesReseau(inputField.codereseau).then(dataRole=>{
            if(dataRole){
                setRolesReseau(dataRole)
            }
        })
      }
  },[inputField.codereseau])
  useEffect(() => {
    if(inputField.codezone!==''){
      getEquipesZones(inputField.codezone).then(dataEquipe=>{
        if(dataEquipe){
            setEquipes(dataEquipe)
        }

      })
    }
  },[inputField.codezone])
  //filtrage des données
  const handleFilterData =()=>{
    setLoading(true)
    getUtilisateurNoDispatch(inputField).then(datauser=>{
      if(datauser){
        setAllUtilisateur(datauser)
        setLoading(false)
      }else{
        setLoading(false)
        return
      }
    }).catch(err=>{
      console.log(err);
      setLoading(false)
      return
    })
  }

  const handleChange = (event)=>{   
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
 const handleHistDataUser =(userData)=>{
    setSelectedRow(userData)
    if(userData){
      historiqueEquipeuser(userData.idmembre).then(res=>{
        if(res){
          sethistDataUser({user:userData,hist:res})
        }else{
          sethistDataUser({user:userData,hist:null})
        }
      }).catch(err=>{
        sethistDataUser({user:userData,hist:null})
      })
    }
  }
  
    useEffect(() => {
        //setSelectedRow(null)
        sethistDataUser(null)
    }, [])

    return (
      <div style={{marginTop:75}}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
                <HeaderFonction handleFonctionMini={handleFonctionMini}/>
          </GridItem>
        </GridContainer>

        <GridContainer style={{marginTop:20}}>
            <AddUtilisateur mopen={addopen} handleCloseMod={handleCloseAdd} handleMopen={handleClickAddOpen} />
            <GridItem xs={12} sm={12} md={12} lg={12}>
                <LabelledOutline label="Filtres">
                  <Paper elevation={0}>
                        <form className={classes.container} noValidate>
                          <div style={{flex:1,border:"1px solid #f3f3", padding:10}}>
                              <TextField
                                  variant="outlined"
                                  id="codezone"
                                  name ='codezone'
                                  select
                                  label="Zone/Departement"
                                  value={inputField.codezone}
                                  onChange={handleChange}
                                  fullWidth
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  style={{marginBottom:10}}
                                  >
                                  <MenuItem key={0} value={""}>Aucun</MenuItem>
                                  {(zones && zones.length !==0)&& zones.map((option) => (
                                      <MenuItem key={option.id} value={option.id}>
                                      {option.libellezone}
                                      </MenuItem>
                                  ))}
                              </TextField>
                              <TextField
                                variant="outlined"
                                id="codeequipe"
                                name ='codeequipe'
                                select
                                label="Equipe/Agence"
                                value={inputField.codeequipe&&inputField.codeequipe}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{marginBottom:10}}
                                >
                                   <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(equipes && equipes.length !==0)&& equipes.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.libelleequipe}
                                    </MenuItem>
                                ))}
                            </TextField>
                          </div>
                          <div style={{flex:1,border:"1px solid #f3f3", padding:10}}>
                            <CustomRadio 
                              id="memberok" 
                              name='memberok'
                              value={inputField.memberok&&inputField.memberok}
                              formControlProps={{ fullWidth: true}} 
                              onChange={handleChange}
                              options={[{"label":"Actif","value":"on"},{"label":"Bloquer","value":"off"},{"label":"Tous","value":"all"}]}
                              row
                            />
                            <TextField
                                variant="outlined"
                                id="coderole"
                                name ='coderole'
                                select
                                label="Profil"
                                value={inputField.coderole}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginBottom:10}}
                                >
                                   <MenuItem key={0} value={""}>Aucun</MenuItem>
                                {(rolesReseau && rolesReseau.length !==0)&& rolesReseau.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                    {option.role}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <Button variant='outlined' onClick={handleFilterData} color='primary' className={classes.buttonSearch}>
                          FILTRER
                        </Button> 
                      </form>     
                    </Paper>
                  </LabelledOutline>
                </GridItem>
            <GridItem xs={12} sm={12} md={histDataUser?8:12} lg={histDataUser?8:12}>
              {loading?(
                 <div className={classes.root}>
                   <CircularProgress color="primary" />
               </div>
              ):(
                <MaterialTable
                columns={[
                  { title: '#', field: 'idmembre' },
                  { title: 'Nom', field: 'nom'},
                  { title: 'Prénom', field: 'prenom'},
                  { title: 'Date naissence', field: 'datenaissance',render:rowDtat=>(rowDtat.datenaissance?dayjs(rowDtat.datenaissance).format('DD/MM/YYYY'):"")},
                  { title: 'Email', field: 'email'},
                  { title: 'Telephone', field: 'telephone',render:rowData=>((rowData.telephone && rowData.telephone2)?(rowData.telephone+" / "+rowData.telephone2):rowData.telephone)},
                  { title: 'Profile', field: 'role'},
                  { title: 'Reseau', field: 'libellereseau'},
                  { title: 'Zone', field: 'libellezone'},
                  { title: 'Equipe', field: 'libelleequipe'},
                ]}
                data={allUtilisateur}
                title="Gestion des utilisateurs"
                localization={{
                  toolbar:{
                      searchPlaceholder:'Rechercher',
                      searchTooltip:'Rechercher'
                  },
                  body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                  },
                  pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                  }
                }}
                
                options={{
                  exportButton: true,
                  searchFieldStyle:{
                    fontSize:18,
                    width:'100%',
                    height:50,
                  },
                  actionsColumnIndex: -1
                }}
                
                components={{
                Action: props=> {  
                    return(  
                      <CustomMenu 
                        data={props.data}       
                        />
                      )
                },
                Toolbar: props => (
              <div>
                   <MTableToolbar {...props} />
                      {verifRule('user','add',privileges)&&<div style={{padding: '0px 10px',display:'flex',alignItems:'center',justifyContent:'flex-start'}}>
                        <Button onClick={handleClickAddOpen} variant='outlined' color='primary' >
                            <Add style={{color:'#fff',fontSize: 30}} />
                        </Button> 
                      </div>}
                      
                    </div>
                )
                }}
                actions={[
                  {
                    icon: 'more_vert',
                    tooltip: 'Actions',
                    onClick: (event, rowData) =>alert("You want to add a new row"),
                  },
                ]}
                
              />
              )}
                
            </GridItem>
            {histDataUser&&(<GridItem xs={12} sm={12} md={4} lg={4} className={classes.details}>
                <Typography variant='h5'>Détails</Typography>
                <Paper elevation={0} className={classes.details}>
                  <Typography variant='subtitle1'>Dernière connexion :  </Typography>
                  <Typography variant='h6'>{(histDataUser)?(histDataUser.user.lastvisite?dayjs(histDataUser.user.lastvisite).format('DD/MM/YYYY'):""):""}  </Typography>
                 <Divider />
                 {(histDataUser && histDataUser.hasOwnProperty('hist'))?<>
                    <Typography variant='subtitle1'>Historique des mouvements en agence</Typography>
                    <Divider />
                    <Table>
                       <TableBody>
                          {histDataUser.hist.map(el=>(
                             <TableRow key={el['idequipeuser']||""}>
                                <TableCell align="left">{el['libelleequipe']}</TableCell>
                                <TableCell align="center">{el['dateadd']?dayjs(el['dateadd']).format('DD/MM/YYYY'):""}</TableCell>
                                <TableCell align="center">{el['dateupdate']?dayjs(el['dateupdate']).format('DD/MM/YYYY'):""}</TableCell>
                             </TableRow>
                          ))}
                       </TableBody>
                    </Table>
                 </>:null}
                </Paper>
            </GridItem>)}
        </GridContainer>
      </div>
        
    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user,
    ui:state.UI
  });
  const mapActionsToProps = {}
export default connect(mapStateToProps,mapActionsToProps)(UtilisateurList)