export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const LOADING_CORPS = 'LOADING_CORPS';
export const STOP_LOADING_CORPS = 'STOP_LOADING_CORPS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
// Pret reducer types
export const SET_PRETS = 'SET_SCREAMS';
export const SET_PRET = 'SET_SCREAM';
export const POST_PRET = 'LIKE_SCREAM';
export const SET_VILLE = 'SET_VILLE';
export const SET_CIVILITE = 'SET_CIVILITE';
export const SET_SITUATION = 'SET_SITUATION';
export const SET_PAYS = "SET_PAYS";
export const SET_SOCIETE = "SET_SOCIETE";
export const SET_PROFESSION = "SET_PROFESSION";
export const SET_PRODUIT = "SET_PRODUIT";
export const SET_GARANTIE_PRODUIT = "SET_GARANTIE_PRODUIT";
export const SET_USER_DETAIL = 'SET_USER_DETAIL';
export const SET_PROPOSITIONS ='SET_PROPOSITIONS';
export const SET_BORDEREAUX_VALIDATION ='SET_BORDEREAUX_VALIDATION';
export const CLEAR_BORDEREAUX_VALIDATION ='CLEAR_BORDEREAUX_VALIDATION';
export const SET_CONTRATS ='SET_CONTRATS';
export const SET_PRODUCTEUR_DATA = 'SET_PRODUCTEUR_DATA';
export const SET_PROD_CURRENT_DETAIL = 'SET_PROD_CURRENT_DETAIL'
//Settings reducer type

export const SET_RESEAUX = 'SET_RESEAUX';
export const SET_ZONES ='SET_ZONES';
export const SET_EQUIPES ='SET_EQUIPES';
export const SET_MODULES ='SET_SET_MODULES';
export const SET_ACTIONREF = 'SET_ACTIONREF';
export const CLEAR_ACTIONREF = 'CLEAR_ACTIONREF';
export const SET_ALLPRIVILEGE = 'SET_ALLPRIVILEGE';
export const SET_ALLUTILISATEUR = 'SET_ALLUTILISATEUR';
export const SET_PRIVILEGES = 'SET_PRIVILEGES';
export const SET_LAYOUT = 'SET_LAYOUT';

export const SET_MODE = 'SET_MODE';
export const SET_ROUTE = 'SET_ROUTE';
