import React, { useReducer,useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CustomInput from 'components/CustomInput/CustomInput'
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertPage from 'components/AlertPage'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button'
import {
    addReseau,
    getBranche,
    getPartenaire,
    getReseaux
  } from 'redux/actions/settingsAction'
import {connect,useDispatch} from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        flex:1,
        justifyContent:'center',
        alignItems:'center'
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));
function AddReseau(props){
   
    const {mopen,handleCloseMod,settings:{reseaux}} = props
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
      codereseau:'',
      libelle:'',
      coderesponsable:'',
      nomresponsable:'',
      codebranche:'',
      codepartenaire:''
    });
    
    const dispatch =useDispatch()
    const [branches,setBranches] = React.useState([])
    const [partenaires,setPartenaires] = React.useState([])
    const [loading,setLoading] = useState(false)
    const [alertInfo,setAlertInfo]=useState({})
    const handleAlertClose =(action=null)=>{
        setAlertInfo({open:false,message:"",succes:false})
        action&&action()
    }
    const handleAlertOpen =(message,succes=false,action)=>{
        setAlertInfo({open:true,message:message,succes:succes,action:action})
    }

    const handleChange = (event)=>{
        const {name,value} = event.target
        setInputField({
            [name]:value
        })
    }
    useEffect(() => {
        getBranche().then(data=>{
            if(data){
                setBranches(data);
            }
        })
        getPartenaire().then(data=>{
        if(data){
            setPartenaires(data);
        }
        })
        if(!reseaux){
        dispatch(getReseaux())
        }
    }, [])

  const handleSaveResseau =()=>{
    if(inputField.codereseau.trim()==='')return
    if(inputField.libelle.trim()==='')return
    setLoading(true)
    props.addReseau(inputField).then(data=>{
        if(data){
            setInputField({
                ['codereseau']:'',
                ['libelle']:'',
                ['coderesponsable']:'',
                ['nomresponsable']:'',
                ['codebranche']:'',
                ['codepartenaire']:''
               
            })
            handleAlertOpen("Enregistrement effectué avec succès",true,handleCloseMod)
            setLoading(false)
        }else{
            setLoading(false)
            handleAlertOpen("Erreur lors de l'enregistrement",false,null)
            return false
        }
      }).catch(err=>{
          console.log(err);
          setLoading(false)
          handleAlertOpen("Erreur lors de l'enregistrement",false,null)
          return
      })
  }

    return (
        <Dialog fullScreen={fullScreen} open={mopen} onClose={handleCloseMod} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Ajouter un reseau</DialogTitle>
              <DialogContent>
                {!loading?(<>
                  <AlertPage open={alertInfo['open']||false}  action={alertInfo['action']||null} message={alertInfo['message']||""} succes={alertInfo['succes']||false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                    <CustomInput
                        required
                        autoFocus
                        variant="outlined"
                        id="codereseau"
                        name='codereseau'
                        label="code"
                        type="text"
                        value = {inputField.codereseau}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8} lg={8}>
                    <CustomInput
                        autoFocus
                        required
                        variant="outlined"
                        id="libelle"
                        name ='libelle'
                        onChange={handleChange}
                        label="Nom réseau"
                        type="text"
                        value = {inputField.libelle}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                    <CustomInput
                        required
                        autoFocus
                        variant="outlined"
                        id="coderesponsable"
                        name='coderesponsable'
                        label="code responsable"
                        type="text"
                        value = {inputField.coderesponsable}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8} lg={8}>
                    <CustomInput
                        autoFocus
                        required
                        variant="outlined"
                        id="nomresponsable"
                        name ='nomresponsable'
                        onChange={handleChange}
                        label="Nom responsable"
                        type="text"
                        value = {inputField.nomresponsable}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        id="codepartenaire"
                        name ='codepartenaire'
                        select
                        label="Parténaire"
                        value={inputField.codepartenaire}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{marginTop:20,marginBottom:10}}
                        >
                        {(partenaires.length !==0)&& partenaires.map((option) => (
                            <MenuItem key={option.code} value={option.code}>
                            {option.designation}
                            </MenuItem>
                        ))}
                        </TextField>
                    </GridItem>
                </GridContainer>   
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        id="codebranche"
                        name ='codebranche'
                        select
                        label="Branche d'activité"
                        value={inputField.codebranche}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{marginTop:20,marginBottom:10}}
                        >
                        {(branches.length !==0)&& branches.map((option) => (
                            <MenuItem key={option.codebranche} value={option.codebranche}>
                            {option.libelle}
                            </MenuItem>
                        ))}
                        </TextField>
                    </GridItem>
                </GridContainer></>):(<div className={classes.root}><CircularProgress color="primary" /></div>)}
                      
                <Divider />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseMod} variant='outlined' color="secondary">Annuler</Button>
                <Button onClick={handleSaveResseau} variant='outlined' color="primary">Ajouter</Button>
              </DialogActions>
          </Dialog>

    )
}
const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user
  });
  const mapActionsToProps = {addReseau}
export default connect(mapStateToProps,mapActionsToProps)(AddReseau)
