import React from 'react'
import PropTypes from 'prop-types'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Field, reduxForm,reset } from 'redux-form'
import { connect,useDispatch } from 'react-redux';
import {Home,Assignment,Add, Folder} from "@material-ui/icons/";
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles(theme =>({
    root: {
      justifyContent:'center',
      alignItem:'center',
      width: 100,
     marginBottom:40
    },
    fonctionAction:{
        marginBottom:20
    },
    btnAction:{
      width:80,
      height:80,
      backgroundColor:'#fff',
      color:theme.palette.primary.main,
      '&:hover':{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText
      },
      '&active':{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText
      }
    },
    
  }));


function HeaderFonction(props) {
  const {handleFonctionMini} = props
    const classes = useStyles()
    const [currentV, setCurrentV] = React.useState('recents')

    const handleChange = (event,newValue) => {  
        setCurrentV(newValue);
        handleFonctionMini(newValue) 
    }
  
    return (
       
        <BottomNavigation  value={currentV} component='div' onChange={handleChange} className={classes.root}>
            <BottomNavigationAction showLabel className={classes.btnAction}  label="" value="HOME" icon={<Home />} />
        </BottomNavigation> 
         
    )
}

HeaderFonction.propTypes = {

}

export default HeaderFonction

