import styled from 'styled-components/macro'
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
export const Container = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    margin-bottom:20px;
`

export const Inner = styled(Paper)`
    display:flex;
    flex-direction:${({direction})=>direction||'column'};
    margin-top:10px;
    margin-bottom:10px;
`

export const Card = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-right:20px;
    padding-left:20px;
`
export const Title =styled(Typography)`
    margin-right:20px;
    margin-left:20px;
`
export const Group = styled.div`
    display:flex;
    flex:${({flex})=>flex||'1'};
    flex-direction=${({direction})=>direction || 'row'};
    justify-content:flex-start;
    align-items:center;
`
export const Details =styled.div`
    display:flex;
    flex-direction:column;
    border-left: 1px solid #888;
    margin-left:60px;
    margin-top:20px;
    margin-bottom:20px;
`
export const SmallText =styled.span`
    display:inline;
    font-size:12px;
`