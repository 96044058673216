import react from 'react'
import {Container,Groupe,Title,Input,Button,Text,Card,TextSmall} from './style/simulateur'

export default function Simulateur({children,...restProps}){
    return (<Container {...restProps}>{children}</Container>)
}

Simulateur.Group = function SimulateurGroupe({children,...restProps}){
    return (<Groupe {...restProps}>{children}</Groupe>)
}

Simulateur.Title = function SimulateurTitle({children,...restProps}){
    return (<Title {...restProps}>{children}</Title>)
}

Simulateur.Input = function SimulateurInput({children,...restProps}){
    return (<Input {...restProps} />)
}

Simulateur.Button = function SimulateurTitle({children,...restProps}){
    return (<Button {...restProps}>{children}</Button>)
}

Simulateur.Text = function SimulateurText({children,...restProps}){
    return (<Text {...restProps}>{children}</Text>)
}

Simulateur.Card = function SimulateurCard({children,...restProps}){
    return (<Card {...restProps}>{children}</Card>)
}

Simulateur.TextSmall = function SimulateurTextSmall({children,...restProps}){
    return (<TextSmall {...restProps}>{children}</TextSmall>)
}