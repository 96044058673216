import React,{useEffect} from 'react'
import CustomInput from 'components/CustomInput/CustomInput'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/CustomButtons/Button'
import {addBranche,updateBranche} from 'redux/actions/settingsAction'
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/styles'
import { green } from '@material-ui/core/colors';
import CustumModal from 'components/CustumModal';
const useStyle =makeStyles(theme=>({
  dialogHead:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  hbande:{
    height:20,
    width:'100%',
    backgroundColor:green[400]
  }
}))
function Updatebranche(props) {
      const {open,handleClose,data,mode,actualiz} =props
      const [inputField,setInputField] = React.useState({})
      const handleChange =(e)=>{
        const {name,value} = e.target
        setInputField({...inputField,[name]:value})
      }
      const [loading,setLoading] =React.useState(false)
      //savegarde des données
      const handleSaveBranche = () =>{
        if(mode !=='UP'){
          setLoading(true)
          addBranche(inputField).then(res=>{
            if(res){
              setLoading(false)
              swal('Succes','Eneregistrement effectué avec succès','succes')
              actualiz()
              handleClose()
            }else{
              setLoading(false)
              swal('Warning',"Erreur lors de l'enregistrement",'warning')
            }
          }).catch(err=>{
            setLoading(false)
            swal('Error',"Erreur lors de l'enregistrement",'error')
          })
        }else{
          setLoading(true)
          updateBranche(data['id',inputField]).then(res=>{
            if(res){
              setLoading(false)
              swal('Succes','Modification effectuée avec succès','succes')
              actualiz()
              handleClose()
            }else{
              setLoading(false)
              swal('Warning',"Erreur lors de l'opération",'warning')
            }
          }).catch(err=>{
            setLoading(false)
            swal('Error',"Erreur lors de l'opération",'error')
          })
        }
        
      }

      useEffect(()=>{
        if(mode == 'UP' && data){
          setInputField({...data})
          setLoading(false)
        }else{
          setInputField({})
          setLoading(false)
        }
      },[mode,data])

   
  return (<CustumModal 
            open={open} 
            handleClose={handleClose} 
            title={`${!(mode=='UP')?"Ajouter une branche":"Modifier la branche #"+data['id']} `}
            actions={
              <div className='flex flex-row justify-between items-center'>
                <Button onClick={handleClose} round={15} variant='outlined' color="warning">Annuler</Button>
                <Button onClick={handleSaveBranche} round={15} variant='outlined' disabled={loading} color="success">{(loading)?(<CircularProgress />):(mode==='UP')?"Modifier":"Ajouter"}</Button>
              </div>
            }
          >
            <div className='p-2 flex flex-col'>
                <CustomInput
                    required
                    autoFocus
                    variant="outlined"
                    id="codebranche"
                    name='codebranche'
                    label="Code"
                    type="text"
                    value = {inputField.codebranche}
                    formControlProps={{
                        fullWidth: true
                    }}
                    onChange={handleChange}
                />
                <CustomInput
                    autoFocus
                    required
                    variant="outlined"
                    id="libelle"
                    name ='libelle'
                    onChange={handleChange}
                    label="Nom branche"
                    type="text"
                    value = {inputField.libelle}
                    formControlProps={{
                        fullWidth: true
                    }}
                />      
            </div>
        </CustumModal>           
  )
}

export default Updatebranche