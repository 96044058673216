import React, { Component,useState } from 'react'
import { Field, reduxForm } from 'redux-form'

import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import MenuItem from '@material-ui/core/MenuItem'
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormLabel from '@material-ui/core/FormLabel';
import { Autocomplete } from '@material-ui/lab'


 export  const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    full,
    ...custom
  }) => (
    <TextField
      label={label}
      fullWidth={full ? true : false}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      value={input.value}
        onChange={input.onChange}
      {...input}
      {...custom}
    />
  )
export const rendertextFieldOrn = ({
    label,
    input,
    meta: { touched, invalid, error },
    full,
    ...custom
})=>(
  <FormControl fullWidth={full ? true : false} >
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Input
        id={input.name}
        value={input.value}
        onChange={input.onChange}
        {...input}
startAdornment={<InputAdornment position="start">{input.value ? input.value:""}</InputAdornment>}
      />
</FormControl>
)




  export const renderCheckbox = ({ input, label }) => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    </div>
  )
 export  const radioButton = ({ input,options,children, ...rest }) => (
    <FormControl>
      <RadioGroup {...input} {...rest} row>
          {options ? (
          options.map((option)=>(<FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />))
          ):children}
      </RadioGroup>
    </FormControl>
  )

  export const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
      return
    } else {
      return <FormHelperText>{touched && error}</FormHelperText>
    }
  }

  
  export const renderSelectField = ({
    input,
    label,
    meta: { touched, error },
    children,
    ...custom
  }) => (
    <FormControl error={touched && error}>
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Select
        native
        {...input}
        {...custom}
      >
        {children}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  )
  

      