import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import { PDFReader } from 'react-read-pdf'
import AttachmentIcon from '@material-ui/icons/Attachment';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import { Divider, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import config from 'util/config'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    table: {
        minWidth: 650,
      },
      buttonDetails:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        borderRadius:0,
        marginRight:20,
        color:theme.palette.secondary.main,
        '&:hover':{
          borderColor:theme.palette.primary.main,
        }
      },
  }));
function DocSideItem(props) {
    const {data} =props
    const {url}=config
    const classes = useStyles();
    const [type,setType]=useState(null)
    const handleType =()=>{
        if(data && data.hasOwnProperty('filename')){
            let  ctype =data.filename.split('.')[1]
            setType(ctype.toLowerCase()) 
        }
    }
  
    useEffect(() => {
        handleType()
    }, [data])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [currentPage,setCurrentPage]=useState(1)
    const [allPage,setAllPage] = useState(1)
    const [showAll,setShowAll] =useState(false)
    const handleDocInfo =(infoDoc)=>{
        setAllPage(infoDoc)
        setShowAll(true)
    }
    const handleNextPage =()=>{
        if(currentPage<allPage){
            setCurrentPage(currentPage + 1)
        }
    }
    const handleBackPage =()=>{
        if(currentPage>1){
            setCurrentPage(currentPage - 1)
        }
    }
    const guideLine=()=>{  
        return (
          
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={1} sm={1} md={1} lg={1} style={{marginRight:25}}>
                <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage>1)} onClick={()=>setCurrentPage(1)}>
                      <FirstPageIcon color={(currentPage>1)?'primary':"secondary"} style={{ fontSize: 40 }} />
                </IconButton>
            </GridItem>
           <GridItem xs={1} sm={1} md={1} lg={1} style={{marginRight:25}} >
             <IconButton color='inherit' className={classes.buttonDetails}  disabled={!(currentPage>1)} onClick={handleBackPage}>
                   <NavigateBeforeIcon color={(currentPage>1)?'primary':"secondary"} style={{ fontSize: 40 }} />
             </IconButton>
           </GridItem>
           <GridItem xs={1} sm={1} md={1} lg={1} style={{marginRight:25}}>
             <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage<allPage)} onClick={handleNextPage}>
                   <NavigateNextIcon color={(currentPage<allPage)?'primary':"secondary"} style={{ fontSize: 40 }} />
               </IconButton>
           </GridItem>
    
      </GridContainer>)
    }
    return (
        <div style={{marginTop:20}}>
            {(type && (type=='png' || type=='jpg' || type=='jpeg' || type=='gif'))?(<><Card>
                <CardHeader title={data.libelle} />
                <CardMedia
                    component="img"
                    alt={data.libelle}
                    height="60"
                    image={`${url+data.filename}`}
                    title={data.libelle}
                    />
                <CardActions>
                    <Button aria-describedby={id} size="large" color="primary" onClick={handleClick}><SettingsOverscanIcon /></Button>
                </CardActions>
             </Card> <Divider /></>):(
                 <div>
                    <Button aria-describedby={id} size="large" color="primary" onClick={handleClick}><AttachmentIcon /><Typography component='caption' variant='caption'>{data.libelle}</Typography></Button>
                    <Divider />
                 </div>
                )
             }
            <Popover
                id={id}
                open={open}
                anchorPosition={{ top: 200, left: 400 }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
            >
                {(type=='png' || type=='jpg' || type=='jpeg' || type=='gif')?(
                    <Card>
                        <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',padding:10}}>
                            <Typography variant="h6" compoenent ='h6'>
                                {data.libelle?data.libelle:"# Aucun titre"}
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon color='primary' fontSize="large"/>
                            </IconButton>
                        </div>
                    
                    <CardMedia
                        component="img"
                        alt={data.libelle}
                        image={`${url+data.filename}`}
                        title={data.libelle}
                        />
                    <CardActions>
                        <Button aria-describedby={id} size="large" color="primary" ><SettingsOverscanIcon /></Button>
                    </CardActions>
                 </Card>
                ):(
                    <Card>
                        <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between',padding:10}}>
                            <Typography variant="h6" compoenent ='h6'>
                                {data.libelle?data.libelle:"# Aucun titre"}
                            </Typography>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon color='primary' fontSize="large"/>
                            </IconButton>
                        </div>
                    <CardContent>
                    
                        <div style={{overflow:'scroll',height:'70%'}}>
                            <PDFReader page={currentPage}  url={`${url+data.filename}`} onDocumentComplete={handleDocInfo}  /> 
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>{guideLine()}<Typography color='primary' variant='h4' component ='h4' >{`${currentPage}/${allPage} Page(s)`}</Typography></div>
                                        
                    </CardContent>
                 </Card> 
                )}
               
            </Popover>           
        </div>
    )
}

export default DocSideItem
