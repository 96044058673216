import React,{useEffect} from 'react'
import { Paper,Divider, Typography, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {getPartenaire,deletePartenaire} from 'redux/actions/settingsAction'
import MaterialTable from 'material-table';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import swal from 'sweetalert'
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
const useStyle = makeStyles(theme=>({
    root:{
        display:'flex',
        flexDirection:'column',
        width:'75%',
        marginLeft:'auto',
        marginRight:'auto',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
        width:'75%',
        },
        marginBottom:20,
        marginTop:20
    },
    hPaper:{
        padding:10,
        marginBottom:20,
        borderLeftColor: theme.palette.primary.main,
        borderLeftWidth:8
    },
    bPaper:{
        padding:10,
        marginBottom:20,
        borderRadius:0
    },
    hContainer:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    loaddingContainer:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    bcontainer:{
        paddingTop:10,
        backgroundColor:'transparent',
        borderRadius:0
    },
    bhead:{
        padding:10
    }
}))

function PartenaireList(props) {
    const {handleFonctionShow} =props
    const classes = useStyle()
    const [open,setOpen] = React.useState(null)
    const handleOpen =(op)=>{
        setOpen(op)
    }
    const handleClose =()=>{
        setOpen(null)
    }
    const [partData,setPartData] =React.useState(null)
    const [loading,setLoading] =React.useState(false)
    useEffect(()=>{
        setLoading(true)
        getPartenaire().then(res=>{
            if(res){
                setLoading(false)
                setPartData(res)
            }
        }).catch(err=>{
            console.log(err);
            setLoading(false)
        })
    },[])

    const handleAdd = ()=>{
        handleFonctionShow('ADD')
    }
    const handleEdit =(cdata)=>{
        handleFonctionShow('UP',cdata)
    }
    
    const handleAtualiz =()=>{
        getPartenaire().then(res=>{
            if(res){
                setPartData(res)
            }
        })
    }

    const handleDelete =(cdata)=>{
        swal({
            title: "Attention etes-vous sur de vouloir supprimé partenaire?",
            text: "Cette opération supprimera toutes les données rattachées à ce partenaire!",
            icon: "warning",
            buttons: ["Annuler", "Supprimer"],
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                deletePartenaire(cdata['id']).then(res=>{
                    if(res){
                        handleAtualiz()
                        swal('Succes','Suppression effectué avec succès','succes')
                    }else{
                        swal('Warning',"Erreur lors de l'opération",'warning')
                    }
                }).catch(err=>{
                    swal('Error',"Erreur lors de l'opération",'error')
                
                })
            } else {
              swal("Suppression annuler!");
            }
        });
    }

    const handleShow = (type,cdata)=>{
        console.log(type);
        handleFonctionShow(type,cdata)
    }
    const handleShowDiag = (type,cdata)=>{
        handleOpen(type)
    }
  return (
    <div className={classes.root}>
        <Paper className={classes.hPaper} variant="outlined">
            <div className={classes.hContainer}>
                <Typography variant='h5'>Gestion des partenaires</Typography>
               
                <Tooltip title="Nouveau partenaire"> 
                    <IconButton onClick={handleAdd} color='primary'>
                        <AddIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
            </div>
        </Paper>
        <Paper className={classes.bcontainer} variant="outlined">
            <div className={classes.bhead}>
                <Typography variant='body'>Liste des parténaires</Typography>
                {(partData && partData.length!==0)&&<Chip className='ml-4' label={`Total(s) ${partData&&partData.length}`} />}
                </div>
            
            <Paper className={classes.bPaper} variant="outlined">
                {partData?(<MaterialTable
                    columns={[
                      { title: '#', field: 'id' },
                      { title: 'Code', field: 'code'},
                      { title: 'Nom', field: 'designation'},
                      { title: 'Commission', field: 'tauxcom'},
                      { title: 'Actions', render:rowData=>(
                        <div>
                             <Tooltip title="Voir la fiche du partenaire">
                                <IconButton onClick={()=>handleShow('FICHE',rowData)}>
                                    <DescriptionIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Modifier">
                                <IconButton onClick={()=>handleEdit(rowData)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip> 
                            <Tooltip title="Supprimer">
                                <IconButton onClick={()=>handleDelete(rowData)}>
                                    <DeleteIcon />
                                </IconButton> 
                            </Tooltip>
                               
                        </div>
                      )},
                    ]}
                    data={partData}
                    title=""
                    localization={{
                      toolbar:{
                          searchPlaceholder:'Rechercher',
                          searchTooltip:'Rechercher'
                      },
                      body: {
                        emptyDataSourceMessage: 'Aucune donnée trouvée !'
                      },
                      pagination: {
                        labelRowsSelect: 'Ligne(s)',
                        labelDisplayedRows: '{count} sur {from}-{to}',
                        firstTooltip: 'Prémière page',
                        previousTooltip: 'Précédent',
                        nextTooltip: 'Suivant',
                        lastTooltip: 'Dernière page'
                      }
                    }}
                    
                    options={{
                      exportButton: true,
                      searchFieldStyle:{
                        fontSize:18,
                        width:'100%',
                        height:50,
                      },
                      actionsColumnIndex: -1
                    }}
               />):(
                <div className={classes.loaddingContainer}>
                    {loading?<CircularProgress />:<Typography variant='h5'>Aucune données</Typography>}
                </div>
            )}
        </Paper>
        </Paper>
        
    </div>
  )
}

export default PartenaireList