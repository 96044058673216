import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import Typography from '@material-ui/core/Typography';
import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {getProspectAgent,getProspectReseau} from 'redux/actions/apiActions'
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';
import TitleBox from 'components/TitleBox';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
  },
  container:{
    display:'flex',
    flexDirection:'column',
    width:'75%',
    marginLeft:'auto',
    marginRight:'auto',
    marginBottom:20,
    [theme.breakpoints.down('md')]:{
      width:'85%',
    },
    [theme.breakpoints.down('sm')]:{
      width:'95%',
    },
    [theme.breakpoints.down('sx')]:{
      width:'100%',
    }
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  stCard:{
    display:'flex',
    flexDirection:'column',
    padding:20,
    marginLeft:20
  },
  card:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    justifyContent:'space-around',
    padding:20,
    margin:20,
    alignItems:'center'
  },
  icard:{
    display:'flex',
    flex:1,
    "&:hover":{
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
    },
    cursor:'pointer'
},
iStat:{
    display:'flex',
    marginTop:20,
    marginBottom:20
},
numberState:{
  fontSize:35,
  color:theme.palette.primary.main,
  textAlign:'center'
},
labelState:{
  color:'#666',
  fontWeight:'bold',
  textAlign:'center'
},
cardState:{
  display:'flex',
  width:'100%',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center'
},

itcard:{
  display:'flex',
  flex:1,
  boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
  "&:hover":{
      boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)"
  },
  cursor:'pointer'
},
newcard:{
  backgroundColor:theme.palette.primary
},
addbtn:{
  display:'flex',
  backgroundColor:theme.palette.primary.main,
  paddingLeft:25,
  paddingRight:25,
  fontWeight:500,
  "&:hover":{
    backgroundColor:theme.palette.secondary.main,
  }
}
}));

export default function ProspectList(props) {
  const classes = useStyles();
  const {handleCliqueFonction} =props
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [myprospects,setMyprospects] = React.useState([])
  const [prospectrezo,setProspectrezo] = React.useState([])
  const handleCurrentAgentProspect = ()=>{
    getProspectAgent().then(res=>{
      if(res){
        setMyprospects(res)
      }
    })
    getProspectReseau().then(res=>{
      if(res){
        setProspectrezo(res)
      }
    })
  }
  useEffect(()=>{
    handleCurrentAgentProspect()
  },[])
  const handleNewProspect = ()=>{
    handleCliqueFonction("ADDPRO")
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
          <TitleBox titre ="Gestion des prospects" showBtn={true} />

          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',alignItems:'center'}}>
              <div style={{display:'flex',marginTop:20,marginBotom:20,marginRight:20}}>
                  <Paper className={classes.stCard}>
                      <Typography variant='h5'>150</Typography>
                      <Typography variant='caption'>Totals prospect</Typography>
                  </Paper>
                  <Paper className={classes.stCard}>
                      <Typography variant='h5'>10%</Typography>
                      <Typography variant='caption'>Taux conversion</Typography>
                  </Paper>
              </div>   
                <div className={classes.iStat} >
                  <Card className={clsx([classes.itcard,classes.newcard])} style={{marginRight:15}}>
                      <CardContent>
                          <div className={classes.cardState}>
                              <span className={classes.numberState}>0</span>
                              <span className={classes.labelState}>
                                  Nouveau prospects
                              </span>
                          </div>
                      </CardContent>
                  </Card>
                  <Card className={classes.itcard} style={{marginRight:15}}>
                      <CardContent>
                          <div className={classes.cardState}>
                              <span className={classes.numberState}>0</span>
                              <span className={classes.labelState}>
                                  Total prospect équipe
                              </span>
                          </div>
                      </CardContent>
                  </Card>
                  <Card className={classes.itcard}style={{marginRight:15}}>
                      <CardContent>
                          <div className={classes.cardState}>
                              <span className={classes.numberState}>0</span>
                              <span className={classes.labelState}>
                                  Total prospect agent
                              </span>
                          </div>
                      </CardContent>
                  </Card>
                  
                  
                </div>
              </div>
          
          <div style={{display:'flex',flexDirection:'column',marginTop:20,marginBotom:20}}>
            <div style={{display:'flex',justifyContent:'flex-end'}}>
              <Button className={classes.addbtn}  onClick ={handleNewProspect}><AddIcon /></Button>
            </div>
            
      
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Mes prospects" />
              <Tab label="Prospect agent" />
            </Tabs>
            {value===0&&<div style={{padding:20}}>
              <Paper elevation={0}>
              <MaterialTable
                columns={[
                  { title: '#', field: 'id' },
                  { title: 'Prospect', field: 'nomprospect' },
                  { title: 'Date Naissance', field: 'datenaissance' },
                  { title: 'Lieu résidence', field: 'lieuresidence' },
                  { title: 'Campagne', field: 'campagne' },
                  { title: 'Date prospection', field: 'dateprospection' },
                  { title: 'Statut', field: 'statut' },
                  { title: 'Produit', field: 'produit' }
                ]}
                data={myprospects}
                title='Mes prospects'
                localization={{
                  toolbar: {
                    searchPlaceholder: 'Rechercher',
                    searchTooltip: 'Rechercher'
                  },
                  body: {
                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                  },
                  pagination: {
                    labelRowsSelect: 'Ligne(s)',
                    labelDisplayedRows: '{count} sur {from}-{to}',
                    firstTooltip: 'Prémière page',
                    previousTooltip: 'Précédent',
                    nextTooltip: 'Suivant',
                    lastTooltip: 'Dernière page'
                  }
                }}

                options={{
                  exportButton: true,
                  searchFieldStyle: {
                    fontSize: 18,
                    width: '100%',
                    height: 50,
                  },
                  actionsColumnIndex: -1
                }}
                actions={[
                  
                ]}
              />
            
              </Paper>
          </div>}
          {value===1&&<div style={{padding:20}}>
            <Paper>

            </Paper>
          </div>}
          </div>
      </div>
      
     
    </div>
  );
}