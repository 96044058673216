import styled from 'styled-components/macro'
import { Paper } from '@material-ui/core'
export const Container = styled.div`
    background: ${({ bg }) => ` url(${bg})`} no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height:100vh;
    @media (max-width : 600px){
        display:flex;
        height:auto;
    };
    @media (max-width : 400px){
        display:flex;
        height:auto;
    };
`
export const Inner = styled.div`
    display: flex;
    justify-content:center;
    align-items:center;
    max-width:100%;
    min-height:100%;
    @media (max-width: 760px) {
        flex-direction: column;
        max-width:100%
        justify-content:flex-start;
        padding:10px;
    };
    @media (max-width : 600px){
        align-items:center;
        height:auto;
    };
`
export const Header = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;

`
export const Logo = styled.div`
`
export const LogoPicture = styled.img`
`
export const Menu = styled.ul`
`
export const MenuItem = styled.li`
`
export const Body = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    max-width:100%;
    max-height:100%;
    @media (max-width: 760px) {
        flex-direction: column;
        max-width:100%;
        justify-content:space-between;
    }
    @media (max-width: 760px) {
        flex-direction: column;
        max-width:100%;
        justify-content:space-between;
        align-items:center
    }
`
export const Footer = styled.div`
`
export const Base = styled(Paper)`
`
export const Input = styled.input`
`
export const Label = styled.span``

export const Button = styled.button``

export const Title = styled.h2``
export const Text = styled.p``

export const Group = styled.div`
    display:flex;
    flex:${({ flex }) => flex || 1};
    flex-direction:${({ direction }) => direction || 'row'};
    justify-content:${({ palign }) => palign || 'flex-start'};
    align-items:${({ salign }) => salign || 'flex-start'};
`