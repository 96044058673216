import React from 'react'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux';
import {Home} from "@material-ui/icons/";
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const useStyles = makeStyles(theme =>({
    root: {
      justifyContent:'center',
      alignItem:'center',
      width: 500,
     marginBottom:40
    },
    fonctionAction:{
        marginBottom:20
    },
    btnAction:{
      width:80,
      height:80,
      backgroundColor:'#fff',
      color:theme.palette.primary.main,
      '&:hover':{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText
      },
      '&active':{
        backgroundColor:theme.palette.primary.main,
        color:theme.palette.primary.contrastText
      }
    },
    
  }));


function HeaderFonction(props) {
  const {handleFonctionMini,api:{bordereauxValidation}} = props
    const classes = useStyles()
    const [currentV, setCurrentV] = React.useState('recents')
    const handleChange = (event,newValue) => {  
        setCurrentV(newValue);
        if(newValue ==='HOME'){
          props.history.push('/producteur/home')
        }
        //handleFonctionMini(newValue)
    }
    
    return (
       
        <BottomNavigation  value={currentV} component='div' onChange={handleChange} className={classes.root}>
            <BottomNavigationAction showLabel className={classes.btnAction}  label="" value="HOME" icon={<Home />} />
            {(bordereauxValidation && bordereauxValidation.length!==0)&&(<Button type="button" color='warning'>
                        Liste de validation
                          <Badge badgeContent={bordereauxValidation.length} color="primary">
                                    <CheckBoxIcon style={{marginLeft:10}} /> 
                          </Badge>
            </Button>)}
        </BottomNavigation> 
         
    )
}
const mapPropsActions ={}
const mapStateToProps = (state) => ({
  api: state.api,
});

export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(HeaderFonction)) 

