import React, { useState } from 'react'
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

import TextField from '@material-ui/core/TextField';
import styles from "assets/jss/llv//components/customInputStyle.js";
const useStyles = makeStyles(styles);

export default function  CustomAgenceSelectBankass(props)  {
    
    const classes = useStyles();
    const {formControlProps,label,id,name,labelProps,inputProps,options,error,success,children,value,input,...custom} = props
    
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
      });

      const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
      });

      const marginTop = classNames({
        [classes.marginTop]: label === undefined
      });

    return (
        <FormControl
            {...formControlProps}
            
            className={formControlProps.className + " " + classes.formControl}
        >
          <TextField
            id={id}
            select
            label={label}
            value={input?input.value:value}
            onChange={input?input.onChange:props.onChange}
            SelectProps={{
              native: true,
            
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            name={name}
            {...custom}
          >
          <option  value=''></option>
          {(options && options.lenth!==0)&&options.map(option => (
            <option key={option} value={option.codeequipe}>
              {option.libelleequipe}
            </option>
          ))}
        </TextField>
        
         {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
        
    )
}

CustomAgenceSelectBankass.propTypes = {
    label: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
  };