import React, { useState, useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import { reduxForm, change, registerField,getFormValues } from 'redux-form';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card';
import 'jspdf-autotable'
import { getSearchContratsAgent, EtatCotisation, getProduitFormules } from 'redux/actions/apiActions'
import MaterialTable from 'material-table'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { green, pink } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CustomInput from "components/CustomInput/CustomInput.js";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import CustomDate from 'components/CustomDate/CustomDate'
import AlertPage from 'components/AlertPage'
import { etatEncaissement } from 'application'
import { Paper,Typography, IconButton,Tooltip } from '@material-ui/core'
import FicheDeclaration from './FicheDeclaration';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { ListAlt } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
//style
const useStyles = makeStyles(theme => ({
  contain:{
    display:'flex',
    position:'relative',
    flex:1,
    flexDirection:'column',
    width:'100%',
    marginBottom:20,
    flexWrap:'wrap',
    overflow:'auto'
  },
  root: {
    display:'flex',
    flex:0.5,
    justifyContent:'space-between',
    marginBottom: 20,
    marginLeft:20,
    '& :hover': {
      background: theme.palette.primary.main,
      border: 0,
      padding: '10 30px',
      textDecoration: 'none'
    }
  },
  
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  loading: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonAdd: {
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 30,
    color: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  buttonDetails: {
    width: '100%',
    borderWidth: 1,
    borderColor: theme.palette.secondary.main,
    backgroundColor: '#fff',
    color: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  buttonAddChild: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  btncontainer:{
    display:'flex',
    margin:20,
    padding:20,
    justifyContent:'space-between',
    alignItems:'center',


    [theme.breakpoints.down('sm')]: {
      flexDirection:'column',
      justifyContent:'cneter',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection:'row',
      justifyContent:'space-between',
    },
  },
  buttonSearch: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'40%',
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor:green[100],
    },
    [theme.breakpoints.down('md')]: {
      width:'100%',
    },
  },
  buttonReset: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'40%',
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    '&:hover': {
      borderColor: theme.palette.secondary.dark,
      backgroundColor:theme.palette.secondary.dark,
    },
    [theme.breakpoints.down('md')]: {
      width:'100%',
    },
  },
  buttonNewSearch: {
    display:'flex',
    flexGrow:1,
    justifyContent:'center',
    alignItems:'center',
    margin: 20,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    }
  },
  media: {
    height: 140,
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  dcard: {
    marginBottom: 10,
    paddingBottom: 10
  },
  resultContainer:{
    display:'flex',
    flexDirection:'column',
    maxWidth:'100%',
    flexWrap:'wrap',
  },
  resultHeader:{
    display:'flex',
    flexWrap:'wrap',
    marginRight:20
  },
  tablibOper: {
    display: 'flex',
    flexDirection: 'column'
  },
  pCot:{
    display:'flex',
    justifyContent:'space-between',
    padding:5,
    marginTop:5
  }
}));

function IdentificationContrat(props) {
  const classes = useStyles();
  const { user: {credentials },data, api: { produits }, handleNext, handleBack, activeStep, steps,handleShowHome } = props
  const [more,setMore] = useState(false)
  const {mode} = props
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState([])
  const [titleDetails, setTitleDetails] = useState('')
  const [formuleProduit, setFormuleproduit] = useState()
  const handleDetails = (dt, title) => {
    setDetails(dt)
    setTitleDetails(title)
  }
  const [showResult, setShowResult] = useState(false)
  const [contrat, setContrat] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      encours: "",
      suspendu: "",
      arrete: "",
      total: 0

    });
  const [detailContrat, setDetailContrat] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      detailEncours: "",
      detailSuspendu: "",
      detailArrete: "",
      detailAll: ""

    });
  const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      idcontrat: '',
      numeroproposition: '',
      numeropolice: '',
      numerobulletin: '',
      dateeffet: '',
      dateecheance: '',
      capital: '',
      prime: '',
      adherent: '',
      datenaissance: '',
      codeconseiller: '',
      nomconseiller: '',
      etatcontrat: '',
      codeproduit: '',
      codeguichet: '',
      numerocompte: ''
    });
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputField({
      [name]: value
    })
  }
  const handleReset = () => {
    setInputField({
      ['idcontrat']: '',
      ['numeroproposition']: '',
      ['numeropolice']: '',
      ['numerobulletin']: '',
      ['dateeffet']: '',
      ['dateecheance']: '',
      ['capital']: '',
      ['prime']: '',
      ['adherent']: '',
      ['datenaissance']: '',
      ['codeconseiller']: '',
      ['nomconseiller']: '',
      ['etatcontrat']: '',
      ['codeproduit']: '',
      ['codeguichet']: '',
      ['numerocompte']: ''
    })
    setLoading(false)
  }
  const [alertInfo, setAlertInfo] = useState({})
  const handleAlertClose = (action = null) => {
    setAlertInfo({ open: false, message: "", succes: false })
    action && action()
  }
  const handleAlertOpen = (message, succes = false, action) => {
    setAlertInfo({ open: true, message: message, succes: succes, action: action })
  }

  const handleSearch = () => {
    setLoading(true)
    if (inputField.idcontrat === '' && inputField.numeroproposition === '' && inputField.numeropolice === '' && inputField.dateeffet === '' && inputField.dateEcheance === '' && inputField.capital === '' && inputField.prime === '' && inputField.adherent === '' && inputField.codeconseiller === '' && inputField.nomconseiller === '' && inputField.numerocompte === '') {
      return
    }
    
    getSearchContratsAgent(inputField).then(resData => {
      console.log(inputField);
      if (resData) {
        const detailsAll = resData.details
        const productionsAll = resData.production
        let mytotal = 0
        if (productionsAll && productionsAll.lenght !== 0) {
          productionsAll.map(prod => {
            mytotal = mytotal + parseInt(prod.Nombre)
          })
          setContrat({
            ['encours']: productionsAll.filter(item => item.Etat == 1)[0],
            ['suspendu']: productionsAll.filter(item => item.Etat == 2)[0],
            ['arrete']: productionsAll.filter(item => item.Etat == 3)[0],
            ['total']: mytotal
          })
        }

        if (detailsAll && detailsAll.lenght !== 0) {
          setDetailContrat({
            ['detailEncours']: detailsAll.filter(item => item.Etat === 'En cours'),
            ['detailSuspendu']: detailsAll.filter(item => item.Etat === 'Suspendu'),
            ['detailArrete']: detailsAll.filter(item => item.Etat === 'Arrêté'),
            ['detailsTout']: detailsAll
          })
          if(credentials['codepartenaire'] === '092'){
            handleDetails([
              ...detailsAll.filter(item => item.Etat === 'En cours'),
              ...detailsAll.filter(item => item.Etat === 'Suspendu')
            ], "Liste des contrats")
          }else{
            handleDetails(detailsAll, "Liste des contrats")
          }
          
        }
        setShowResult(true)
        setLoading(false)
      } else {
        setLoading(false)
        handleAlertOpen("Aucune données trouvées,Veuillez verifier les critères", false)
      }
    }).catch(err => {
      setLoading(false)
      handleAlertOpen("Erreur lors de la récupération des donneé", false)
    })

  }
  const handleEtatCotisation = (dataEtat) => {
    if (!dataEtat) return false
    EtatCotisation({ idContrat: dataEtat.id }).then(res => {
      if (res) {
        etatEncaissement(res,credentials)
      }
    })

  }
  const handleNewOperation = (dataCtr) => {
    if (dataCtr) {
      if (!props.hasOwnProperty('idproposition')) {
        props.dispatch(registerField("addOper", 'idproposition', 'Field'));
      }
      props.updateField('idproposition', dataCtr.id)

      if (!props.hasOwnProperty('codeproposition')) {
        props.dispatch(registerField("addOper", 'codeproposition', 'Field'));
      }
      props.updateField('codeproposition', dataCtr.CodeProposition)

      if (!props.hasOwnProperty('codepolice')) {
        props.dispatch(registerField("addOper", 'codepolice', 'Field'));
      }
      props.updateField('codepolice', dataCtr.CodePolice)

      if (!props.hasOwnProperty('dateeffet')) {
        props.dispatch(registerField("addOper", 'dateeffet', 'Field'));
      }
      props.updateField('dateeffet', dataCtr.DateEffet)

      if (!props.hasOwnProperty('dateecheance')) {
        props.dispatch(registerField("addOper", 'dateecheance', 'Field'));
      }
      props.updateField('dateecheance', dataCtr.DateEcheance)

      if (!props.hasOwnProperty('adherent')) {
        props.dispatch(registerField("addOper", 'adherent', 'Field'));
      }
      props.updateField('adherent', dataCtr.Adherent)

      if (!props.hasOwnProperty('datenaissance')) {
        props.dispatch(registerField("addOper", 'datenaissance', 'Field'));
      }
      props.updateField('datenaissance', dataCtr.DateNaissance)

      if (!props.hasOwnProperty('prime')) {
        props.dispatch(registerField("addOper", 'prime', 'Field'));
      }
      props.updateField('prime', dataCtr.Prime)

      if (!props.hasOwnProperty('capital')) {
        props.dispatch(registerField("addOper", 'capital', 'Field'));
      }
      props.updateField('capital', dataCtr.Capital)

      if (!props.hasOwnProperty('nombreemission')) {
        props.dispatch(registerField("addOper", 'nombreemission', 'Field'));
      }
      props.updateField('nombreemission', dataCtr.NombreEmission)

      if (!props.hasOwnProperty('montantemission')) {
        props.dispatch(registerField("addOper", 'montantemission', 'Field'));
      }
      props.updateField('montantemission', dataCtr.MontantEmission)

      if (!props.hasOwnProperty('nombreencaissement')) {
        props.dispatch(registerField("addOper", 'nombreencaissement', 'Field'));
      }
      props.updateField('nombreencaissement', dataCtr.NombreEncaissement)

      if (!props.hasOwnProperty('montantencaissement')) {
        props.dispatch(registerField("addOper", 'montantencaissement', 'Field'));
      }
      props.updateField('montantencaissement', dataCtr.MontantEncaissement)

      if (!props.hasOwnProperty('nombreimpayes')) {
        props.dispatch(registerField("addOper", 'nombreimpayes', 'Field'));
      }
      props.updateField('nombreimpayes', dataCtr.NombreImpayes)

      if (!props.hasOwnProperty('montantimpayes')) {
        props.dispatch(registerField("addOper", 'montantimpayes', 'Field'));
      }
      props.updateField('montantimpayes', dataCtr.MontantImpayes)

      if (!props.hasOwnProperty('codeproduit')) {
        props.dispatch(registerField("addOper", 'codeproduit', 'Field'));
      }
      props.updateField('codeproduit', dataCtr.CodeProduit)

      if (produits && produits.lenght !== 0) {
        const cproduit = produits.filter(item => item.CodeProduit === dataCtr.CodeProduit)[0]

        if (cproduit) {
          if (!props.hasOwnProperty('libelleproduit')) {
            props.dispatch(registerField("addOper", 'libelleproduit', 'Field'));
          }
          props.updateField('libelleproduit', cproduit.MonLibelle)
        }
      }

      if (!props.hasOwnProperty('codeproduitformule')) {
        props.dispatch(registerField("addOper", 'codeproduitformule', 'Field'));
      }
      props.updateField('codeproduitformule', dataCtr.CodeProduitFormule)
      let formule
      if (formuleProduit && formuleProduit.lenght !== 0) {
        formule = formuleProduit.filter(item => item.CodeProduitFormule === dataCtr.CodeProduitFormule)[0]
        if (formule) {
          if (!props.hasOwnProperty('libelleproduitformule')) {
            props.dispatch(registerField("addOper", 'libelleproduitformule', 'Field'));
          }
          props.updateField('libelleproduitformule', formule.MonLibelle)
        }
      } else {
        getProduitFormules(dataCtr.CodeProduit).then(fmData => {
          if (fmData) {
            formule = fmData.filter(item => item.CodeProduitFormule === dataCtr.CodeProduitFormule)[0]
            if (!props.hasOwnProperty('libelleproduitformule')) {
              props.dispatch(registerField("addOper", 'libelleproduitformule', 'Field'));
            }
            props.updateField('libelleproduitformule', formule.MonLibelle)
          }
        })
      }
      handleNext()
    }
  }

  return (
    <div className={classes.contain}>
       
      <AlertPage open={alertInfo['open'] || false} action={alertInfo['action'] || null} message={alertInfo['message'] || ""} succes={alertInfo['succes'] || false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
        
        {(['MOD','SIN'].includes(mode))&& <FicheDeclaration mode ={mode} />} 
        <div style={{margin:20}}></div>
      {!showResult && (
        <LabelledOutline label="RECHERCHER UN CONTRAT">
            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5} lg={5}>
                  <CustomInput
                    label="Adherent (Nom & Prenoms)"
                    id="adherent"
                    name="adherent"
                    value={inputField.adherent}
                    formControlProps={{
                      fullWidth: true
                    }}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomDate
                    label="Date de naissance"
                    id="datenaissance"
                    name="datenaissance"
                    formControlProps={{
                      fullWidth: true
                    }}
                    InputProps={{
                      type: 'date',
                    }}
                    value={inputField.datenaissance}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
              </GridContainer>
            </Paper>
            <Paper elevation={3} className={classes.dcard}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={2} lg={2}>
                <CustomInput
                  label="Code guichet"
                  id="codeguichet"
                  name="codeguichet"
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={inputField['codeguichet'] || ""}
                  type='texte'
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  label="N° compte/client"
                  id="numerocompte"
                  name="numerocompte"
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={inputField['numerocompte'] || ""}
                  type='texte'
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </GridItem>
            </GridContainer>
          </Paper>
        {more&&<Fragment>
            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="ID"
                    id="idcontrat"
                    name="idcontrat"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.idcontrat}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="N° Proposition"
                    id="numeroproposition"
                    name="numeroproposition"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.numeroproposition}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="N° Police"
                    id="numeropolice"
                    name="numeropolice"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.numeropolice}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="N° Bulletin"
                    id="numerobulletin"
                    name="numerobulletin"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.numerobulletin}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
              </GridContainer>
            </Paper>
            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomDate
                    label="Date d'effet"
                    id="dateeffet"
                    name="dateeffet"
                    formControlProps={{
                      fullWidth: true
                    }}
                    InputProps={{
                      type: 'date',
                    }}
                    value={inputField.dateeffet}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomDate
                    label="Date fin cotisation"
                    id="dateecheance"
                    name="dateecheance"
                    formControlProps={{
                      fullWidth: true
                    }}
                    InputProps={{
                      type: 'date',
                    }}
                    value={inputField.dateecheance}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomInput
                    label="Capital"
                    id="capital"
                    name="capital"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.capital}
                    type='number'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomInput
                    label="Prime"
                    id="prime"
                    name="prime"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.prime}
                    type='number'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                {(produits && produits.length !== 0) && (
                  <GridItem xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      variant="outlined"
                      id="codeproduit"
                      name='codeproduit'
                      select
                      label="Produit"
                      value={inputField.codeproduit}
                      onChange={handleInputChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      <MenuItem key={0} value={0} />
                      {(produits.length !== 0) && produits.map((option) => (
                        <MenuItem key={option.CodeProduit} value={option.CodeProduit}>
                          {option.MonLibelle.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                )}
              </GridContainer>
            </Paper>

            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={2} md={2} lg={2}>
                  <CustomInput
                    label="Code conseiller"
                    id="codeconseiller"
                    name="codeconseiller"
                    value={inputField.codeconseiller}
                    formControlProps={{
                      fullWidth: true
                    }}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <CustomInput
                    label="Nom conseiller"
                    id="nomconseiller"
                    name="nomconseiller"
                    value={inputField.nomConseiller}
                    formControlProps={{
                      fullWidth: true
                    }}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
              </GridContainer>
            </Paper>
          </Fragment>}
          <Tooltip title={more ? "Moins de critères":"Plus de critères"}>
            <Button variant='outlined' className='rounded-lg m-4 flex justify-center items-center flex-1 border-orange-300 hover:bg-green-700 hover:border-white' onClick={()=>setMore(!more)}>{!more ? <ExpandMoreIcon />:<ExpandLessIcon />}</Button>
          </Tooltip>
          <div elevation={3} className={classes.btncontainer}>   
                <Button variant='outlined' color='secondary' className={classes.buttonReset} onClick={handleReset} >
                  REINITIALISER
                </Button>
              
                <Button variant='outlined' color='primary' disabled={loading} className={classes.buttonSearch} onClick={handleSearch} >
                  RECHERCHER <SearchIcon />
                </Button>
          </div>
        </LabelledOutline>
      )}
      {showResult && (
        <div className={classes.resultContainer}>
           <Button variant='outlined' color='primary' className={classes.buttonNewSearch} onClick={() => setShowResult(false)} >
                Edition des critères de recherche
            </Button>
          <div className={classes.resultHeader}>
            {(contrat.total !== 0 && credentials['codepartenaire']!=='092') && (
              
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailsTout, "Liste de mes contrats")}>
                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Total
                        </Typography>
                      </div>

                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {(contrat.total !== 0) && formatNumber(contrat.total)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailsTout, "Liste des contrats")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
            
            )}

            {(contrat.encours && contrat.encours !== '') && (
              
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailEncours, "Liste des contrats actifs")} >
                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Contrats actifs
                        </Typography>
                      </div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.encours.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailEncours, "Liste des contrats actifs")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              
            )}
            {(contrat.suspendu && contrat.suspendu !== '') && (
              
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailSuspendu, "Liste des contrats suspendus")}>
                    <CardContent>
                      <div className={classes.avatar}><Typography align='center' gutterBottom variant="caption" component="span">
                        Contrats suspendus
                      </Typography></div>

                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.suspendu.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailSuspendu, "Liste des contrats suspendus")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
             
            )}
            {(contrat.arrete && contrat.arrete !== '' && credentials['codepartenaire']!=='092') && (
             
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailArrete, "Liste des contrats arrêtés")}>

                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Contrats arrêtés
                        </Typography></div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.arrete.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailArrete, "Liste des contrats arrêtés")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              
            )}

              </div>
           
            {(details && details.length !== 0 && showResult) ? (
                <MaterialTable
                  columns={[
                    {title:'Contrat', field:'Idcontrat',render:rowData=>(<div className={classes.tablibOper}>
                        <Typography variant='h5'>#ID : {rowData.id}</Typography>
                        <Typography variant='h5'>{rowData.Produit}</Typography>
                        <Typography variant='caption'>{`Date d\'effet :${rowData.DateEffet?rowData.DateEffet:""}`}</Typography>
                        <Typography variant='caption'>{`Date d\'échéance : ${rowData.DateEcheance?rowData.DateEcheance:""}`}</Typography>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Typography variant='caption'>{`Prime :${parseInt(rowData.Prime) ? formatNumber(parseInt(parseInt(rowData.Prime))) : ""}`}</Typography>
                        <Typography variant='caption'>{`Capital :${parseInt(rowData.Capital) ? formatNumber(parseInt(parseInt(rowData.Capital))) : ""}`}</Typography>
                        </div>
                        <Typography variant='caption'>{`Etat : ${rowData.Etat} \n`}</Typography>
                    </div>)},
                    { title: 'Adhérent', field: 'Adherent',render: rowData=>(<div className={classes.tablibOper}>
                         <Typography variant='h5'> {rowData.Adherent}</Typography>
                         <Typography variant='caption'>{`Date de naissance :${rowData.DateNaissance?rowData.DateNaissance:""}`}</Typography>
                    </div>)},
                    { title:"Cotisation", render:rowData=>(<div className={classes.tablibOper}>
                        <Paper className={classes.pCot} >
                          <Typography variant='caption'>{`Nb d\'émission :${parseInt(rowData.NombreEmission) ? formatNumber(parseInt(rowData.NombreEmission)) : ""}`}</Typography>
                          <Typography variant='caption'>{`Mt d\'émission :${parseInt(rowData.MontantEmission) ? formatNumber(parseInt(rowData.MontantEmission)) : ""}`}</Typography>
                        </Paper>
                        <Paper className={classes.pCot}>
                          <Typography variant='caption'>{`Nb d\'enc. :${parseInt(rowData.NombreEncaissement) ? formatNumber(parseInt(rowData.NombreEncaissement)) : ""}`}</Typography>
                          <Typography variant='caption'>{`Mt d\'enc. :${parseInt(rowData.MontantEncaissement) ? formatNumber(parseInt(parseInt(rowData.MontantEncaissement))) : ""}`}</Typography>
                        </Paper>
                        <Paper className={classes.pCot}>
                          <Typography variant='caption'>{`Nb d\'impayé :${parseInt(rowData.NombreImpayes) ? formatNumber(parseInt(parseInt(rowData.NombreImpayes))) : ""}`}</Typography>
                          <Typography variant='caption'>{`Mt d\'impayé :${parseInt(rowData.MontantImpayes) ? formatNumber(parseInt(parseInt(rowData.MontantImpayes))) : ""}`}</Typography>
                        </Paper>
                    </div>),
                    headerStyle: {
                      textAlign: 'center'
                    }    
                  },
                  {title:'Compte',filed:'Compte',render: rowData=>(<div>
                    <div style={{display:'flex',flexDirection:'column',padding:10,justifyContent:'center'}}>
                          <Typography variant='caption'>{`Code guichet :${rowData['CodeGuichet'] || ""} \n`}</Typography><br />
                          <Typography variant='caption'>{`N° Compte :${rowData['NumCompte'] || ""}\n`}</Typography><br />
                          <Typography variant='caption'>{`Rib :${rowData['Rib'] || ""}\n`}</Typography><br />
                    </div>
                  </div>),
                   headerStyle: {
                    textAlign: 'center'
                  }      
                },
                    { title:"Actions", render:rowData=>(
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                     {!['3', '0'].includes(rowData.OnStdbyOff)&&<Tooltip title="Nouvelle operation">
                          <IconButton onClick={() => handleNewOperation(rowData)}>
                            <AddIcon fontSize="large" color='primary' />
                          </IconButton>
                        </Tooltip>
                      }
                      <Tooltip title="Etat de cotisation">
                        <IconButton onClick={() => handleEtatCotisation(rowData)}>
                          <ListAlt />
                        </IconButton>
                      </Tooltip>
                     
                      
                    </div>),
                    headerStyle: {
                      textAlign: 'right'
                    }  
                  }
                  ]}
                  data={details}
                  title={titleDetails}
                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Rechercher',
                      searchTooltip: 'Rechercher'
                    },
                    body: {
                      emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                      labelRowsSelect: 'Ligne(s)',
                      labelDisplayedRows: '{count} sur {from}-{to}',
                      firstTooltip: 'Prémière page',
                      previousTooltip: 'Précédent',
                      nextTooltip: 'Suivant',
                      lastTooltip: 'Dernière page'
                    }
                  }}

                  options={{
                    exportButton: true,
                    searchFieldStyle: {
                      fontSize: 18,
                      width: '100%',
                      height: 50,
                    },
                    actionsColumnIndex: -1
                  }}
                />
          ) : (

            (!loading && (details && details.length === 0)) && (<GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Alert variant="filled" severity="error">
                  Aucune données trouvées ou l'opération à rencontrer une erreur !
                </Alert>
              </GridItem>
            </GridContainer>) 

          )}
          
        </div>
      )}



      {loading && (
        <div className='absolute flex justify-center items-center right-0 left-0 w-full h-full bg-[#0000003a] rounded-lg'>
          <CircularProgress color="secondary" />
        </div>
      )}
      {/* Etat de cotisation */}

    </div>
  )
}



const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
  updateField,
}
const mapStateToProps = (state) => ({
  user: state.user,
  api: state.api,
  ui: state.UI,
  data: getFormValues('addOper')(state),
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false, })(IdentificationContrat))

