import React,{useState,useEffect} from "react";
import { reduxForm,change,getFormValues } from 'redux-form';
import {connect} from 'react-redux'
import ProdFunctions from 'pages/Banking/Propositions/ProdFonctions'
import PropositionList from 'pages/Banking/Propositions/PropositionList'
import ContratList from 'pages/Commons/Contrats/ContratList'
import  RechercheContrat from 'pages/Commons/Contrats/RechercheContrat'
import {verifRule} from 'application'
import ErrorPage from 'components/ErrorPage'

function ProductionHome(props){
    const [fonctionnalite,setFonctionalite]=useState('HOME')
    const handleCliqueFonction = element=>{
        setFonctionalite(element)
    }
    useEffect(() => {
        document.title ="ENOV - Gestion de la production"
      })
      const {privileges} = props.user
    return(
        <div>
            {fonctionnalite==='HOME'&&  <ProdFunctions history={props.history} handleCliqueFonction={handleCliqueFonction}/>}
            {((fonctionnalite==='PROD' || fonctionnalite==='ACT') && verifRule('prop','read',privileges))?  <PropositionList history={props.history} handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='PROD' && !verifRule('prop','read',privileges))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
            {(fonctionnalite==='CONTRAT' && verifRule('ctr','read',privileges))? <ContratList handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='CONTRAT' && !verifRule('ctr','read',privileges))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
            {(fonctionnalite==='SEARCH' && verifRule('ctr','search',privileges))? <RechercheContrat handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='SEARCH' && !verifRule('ctr','search',privileges))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(ProductionHome))
  
