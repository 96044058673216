import React, { useState } from "react";
import { MenuItem, Menu } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ViewModuleSharpIcon from '@material-ui/icons/ViewModuleSharp';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DeleteIcon from '@material-ui/icons/Delete';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import UpdateReseau from './UpdateReseau'
import ZonesReseau from './ZonesReseau'
import EquipesReseau from './EquipesReseau'
import ModulesReseau from './ModulesReseau'
import RolesReseau from './RolesReseau'
import ProduitReseau from './ProduitReseau'
import { connect, useDispatch } from 'react-redux'
import { getReseaux, deleteReseaux } from "redux/actions/settingsAction";
function CustomMenu(props) {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { ui: { actionRef } } = props

  const [ropen, setRopen] = useState(false)
  const handleRopen = () => {
    setRopen(true)
    handleClose()
  }
  const handleCloseAdd = () => {
    setRopen(false)
    handleClose()
  }

  const [zopen, setZopen] = useState(false)
  const handleZopen = () => {
    setZopen(true)
    handleClose()
  }
  const handleCloseZone = () => {
    setZopen(false)
    handleClose()
  }
  const [popen, setPopen] = useState(false)
  const handlePopen = () => {
    setPopen(true)
    handleClose()
  }
  const handleCloseprod = () => {
    setPopen(false)
    handleClose()
  }

  const [eopen, setEopen] = useState(false)
  const handleEopen = () => {
    setEopen(true)
    handleClose()
  }
  const handleCloseEquipe = () => {
    setEopen(false)
    handleClose()
  }

  const [mopen, setMopen] = useState(false)
  const handleMopen = () => {
    setMopen(true)
    dispatch({ type: 'SET_ACTIONREF', payload: 'module' })
  }
  const handleCloseMod = () => {
    setMopen(false)
    dispatch({ type: 'CLEAR_ACTIONREF' })
    handleClose()
  }

  const [roopen, setRoopen] = useState(false)
  const handleRoopen = (etape) => {
    setRoopen(true)
  }
  const handleCloseRole = () => {
    setRoopen(false)
    handleClose()
  }


  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }


  const handleDelete = () => {
    deleteReseaux(data.id).then(mrx => {
      if (mrx) {
        dispatch(getReseaux())
      }
    })
  }

  const { data } = props;
  return (
    <React.Fragment>
      <UpdateReseau reseauData={data} ropen={ropen} handleCloseAdd={handleCloseAdd} />
      <ZonesReseau reseauData={data} zopen={zopen} handleCloseZone={handleCloseZone} />
      <EquipesReseau reseauData={data} eopen={eopen} handleCloseEquipe={handleCloseEquipe} />
      <ModulesReseau reseauData={data} mopen={mopen} handleMopen={handleMopen} handleCloseMod={handleCloseMod} />
      <RolesReseau reseauData={data} roopen={roopen} handleCloseRole={handleCloseRole} />
      <ProduitReseau reseauData={data} open={popen} handleClose={handleCloseprod} />

      <IconButton
        data-id={data.id}
        aria-label="actions"
        aria-controls='long-menu'
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <MenuItem onClick={handleRopen}>
          <ListItemIcon>
            <NoteAddIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <Typography variant="inherit">Modifier</Typography>
        </MenuItem>
        <MenuItem disabled>
          <Divider />
        </MenuItem>
        <MenuItem onClick={handlePopen}>
          <ListItemIcon>
            <NoteAddIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <Typography variant="inherit">Produits</Typography>
        </MenuItem>
        <MenuItem disabled>
          <Divider />
        </MenuItem>
        <MenuItem onClick={handleZopen}>
          <ListItemIcon>
            <BusinessCenterIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <Typography variant="inherit">Zones</Typography>
        </MenuItem>
        <MenuItem onClick={handleEopen}>
          <ListItemIcon>
            <GroupWorkIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <Typography variant="inherit">Equipes</Typography>
        </MenuItem>
        <MenuItem disabled>
          <Divider />
        </MenuItem>
        <MenuItem onClick={handleMopen}>
          <ListItemIcon>
            <ViewModuleSharpIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <Typography variant="inherit">Modules</Typography>
        </MenuItem>
        <MenuItem onClick={handleRoopen}>
          <ListItemIcon>
            <LockOpenIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <Typography variant="inherit">Rôles</Typography>
        </MenuItem>

        <MenuItem disabled>
          <Divider />
        </MenuItem>
        <MenuItem onClick={handleDelete} >
          <ListItemIcon>
            <DeleteIcon fontSize="small" color='primary' />
          </ListItemIcon>
          <Typography variant="inherit">Supprimer</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );

}
const mapStateToProps = (state) => ({
  api: state.api,
  user: state.user,
  ui: state.UI
});
const mapActionsToProps = {}
export default connect(mapStateToProps, mapActionsToProps)(CustomMenu)
