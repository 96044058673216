import React, { useState, useEffect, useReducer } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import DocumentBox from '../components/DocumentBox';
import OperationProdDetails from './OperationProdDetails'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Tooltip} from '@material-ui/core'
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});
const useStyles = makeStyles(theme => ({
    dcard: {
        marginBottom: 10,
        paddingBottom: 10,
        flexWrap: 'wrap'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    coreCustum: {
        borderRadius: 0,
        borderLeft: "10px solid #E35F5B"
    },
    itemTitle: {
        fontStyle: "italic"
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    header:{
        marginBottom: 20,
        padding: 20,
        display:'flex',
        justifyContent:'space-between'
    },
    paper: {
        marginBottom: 20,
        padding: 10
    },
    mainContainer:{
        marginBottom: 20,
        padding: 10,
        marginBottom:20
    },
    fullContainer:{
        position: 'fixed',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        background: 'white',
        zIndex: 10,
        display:'flex',
        flexDirection:'column',
        marginBottom: 20,
        padding: 10,
        overflow:'auto',
        boxSizing:'border-box'
        
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },

    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        marginRight:20,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },

    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
    vheader:{
        display:'flex',
        justifyContent:'space-between',
        marginTop:20
      },
      hleft:{
        display:'flex',
        flex:0.5
      },
      hright:{
          display:'flex',
          flex:2,
          flexDirection:'row',
          justifyContent:'center',
          alignItems:'center'
      }
}))


function OperationValidate(props) {
    const { operData, handleSaveOperation,  } = props
    const [loading, setLoading] = useState(false)
    const classes = useStyles();

    const handleValide = () => {
       
        //dataSend['id'] = operData.id
      //  handleSaveOperation(dataSend)
    }
    
    const [full,setFull] =useState(false)
    return (
        <div className={full?classes.fullContainer:classes.mainContainer}>
                <Paper elevation={0} className={classes.paper}>
                    <div className={classes.vheader}>
                        <div className={classes.hleft}>
                            <Tooltip title="Retour" placement="bottom-start">
                                <IconButton onClick={()=>props.handleCurrentView('LISTOP')} color="primary" aria-label="Retour">
                                    <ArrowBackIcon fontSize='large' />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div className={classes.hright}>
                            <div className={classes.headerTitle}>
                                <Typography variant='h5'>{`Validation opération #Ref ${operData && operData['codeoperation'] || ""} - ${operData && operData['id'] || ""}`}</Typography>
                            </div>
                            <div style={{display:'flex',alignItems:'flex-end'}}>
                                <IconButton onClick={()=>setFull(!full)}>
                                    {full?<FullscreenExitIcon fontSize='large' />:<FullscreenIcon fontSize='large' />}
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </Paper>    
                <div style={{display:'flex',flexDirection:'column',overflowY:'scroll'}}>
                    <GridContainer>   
                        <GridItem  
                            style={{
                            boxShadow:"inset -8px 0 10px -6px rgba(0, 0, 0, 0.5)",
                            boxSizing:'border-box',
                            padding:10,
                            marginBottom:20,
                            }}

                            xs={12} sm={12} md={7} lg={7}>
                            <DocumentBox data={operData} />
                        </GridItem>
                        <GridItem 
                            xs={12} sm={12} md={5} lg={5}
                            style={{
                                boxShadow:"inset 8px 0 10px -6px rgba(0, 0, 0, 0.5)",
                                padding:20,
                                marginBottom:20,
                                boxSizing:'border-box'
                                
                                }}
                            >
                            <div style={{height:'100vh', overflowY:'scroll'}}>
                                <OperationProdDetails operData ={operData} />
                            </div>
                        </GridItem>
                    </GridContainer> 
                    <div style={{display:'flex', margin:20}}>
                        <Button className={classes.buttonReset} autoFocus onClick={handleValide} color="primary" >
                            Réjéter
                        </Button>
                        <Button className={classes.buttonSearch} autoFocus onClick={handleValide} color="primary" >
                            Valider
                        </Button>
                    </div>
                </div>
                   
                    
        </div>
    )
}

export default OperationValidate