import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    STOP_LOADING_UI,
    SET_ACTIONREF,
    CLEAR_ACTIONREF,
    SET_LAYOUT,
    SET_MODE,
    SET_ROUTE,
  } from '../types';
  
  const initialState = {
    loading: false,
    errors: null,
    actionRef:null,
    layoutRoot:'admin',
    mode:'ADD',
    currentRoute:[]
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_ERRORS:
        return {
          ...state,
          loading: false,
          errors: action.payload
        };
      case SET_MODE:
        return {
          ...state,
          loading: false,
          mode: action.payload
        };
      case SET_ROUTE:
        return {
          ...state,
          loading: false,
          currentRoute: action.payload
        };
      case SET_ACTIONREF:
        return {
          ...state,
          actionRef: action.payload
        };
      case CLEAR_ACTIONREF:
        return {
            ...state,
            actionRef: null
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          loading: false,
          errors: null
        };
      case LOADING_UI:
        return {
          ...state,
          loading: true
        };
      case SET_LAYOUT:
        return {
          ...state,
          layoutRoot: action.payload
        };
      case STOP_LOADING_UI:
        return {
          ...state,
          loading: false
        };
      default:
        return state;
    }
  }
  