import React, {useState } from 'react'
import {withStyles, createMuiTheme} from "@material-ui/core/styles";
import {reduxForm,change } from 'redux-form'
import { connect } from 'react-redux';
import {DropzoneArea} from 'material-ui-dropzone'
import MaterialTable from 'material-table';

const styles= (theme)=> ({
    MuiEngagementCard:{
        margin:10,
        transition:"0.3s",
      
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
          boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        },
    },
    MuiMediaContent:{
        paddingTop: "56.25%",
        textAlign: "left",
        padding: muiBaseTheme.spacing(3),
        margin: `${muiBaseTheme.spacing(3)}px 0`
    },
    TextHeader:{
        fontWeight: "bold"
    },
    TextContent:{
        lineHeight: 1.8
    },
    btnSouscribe:{
        backgroundColor:theme.palette.primary.main,
        marginTop:10,
    },
    fileZone:{
        position: 'relative',
        width: '100%',
    },
    paper:{
        width:'100%'
    }
})
  
const muiBaseTheme = createMuiTheme();

function DocumentCard (props) {
    const {documentType,classes} = props
    const [files,setFiles] = useState([])
    const handleFileChange =(file)=>{
        const currentIndex = files.findIndex(item =>item.name===file.name);
        const newFile = [...files]
        if(currentIndex!==-1){
            newFile.splice(currentIndex, 1);
        }
        newFile.push(file)
        setFiles(newFile)
        props.input.onChange(newFile)
    }
    const handleFileDelete = (file) =>{
        const newFile = [...files]
        newFile = newFile.filter(item=>item.name!==file.name)
       setFiles(newFile)
       props.input.onChange(newFile)
    }
   
    return(
        <div style={{width:'100%'}}>
        <MaterialTable
            title={null}
            columns={[{title:'Titre',field:'titre',cellStyle:{fontSize:20,fontWeight:'bold'}},
            {title:'Fichier',field:'fichier',render:rowData=>(
                <DropzoneArea
                    acceptedFiles ={['image/jpeg','image/png','application/pdf']}
                    filesLimit={5}
                    dropzoneText='Télécharger vos documents ici'
                    onDrop={handleFileChange}
                    dropzoneClass={classes.fileZone}
                    onDelete={handleFileDelete}
                />
            )}
            ]}
            data={documentType}
            options={{
                search: false,
                filtering: false,
                sorting:false,
                toolbar:false,
                draggable:false,
                paging:false,
                padding:'dense',
                header:false
            }}
            maxWidth='100%'
        />
</div>
    )
}


const mapStateToProps = (state) => ({});
  
  const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
  const mapActionsToProps = {
    updateField
  }

  DocumentCard= withStyles(styles)(DocumentCard)

export default connect( mapStateToProps,
    mapActionsToProps)(reduxForm({
    form: 'adForm', // a unique identifier for this form
    destroyOnUnmount: false,
  })(DocumentCard))

