import React, { useState, useEffect } from 'react'
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from '@material-ui/lab'
import FormControl from "@material-ui/core/FormControl";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
import TextField from '@material-ui/core/TextField'
import styles from "assets/jss/llv//components/customInputStyle.js";
import { change } from 'redux-form'
import { useDispatch } from 'react-redux'
const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  const dispatch = useDispatch()
  const classes = useStyles();
  const { formControlProps, label, id, name, labelProps, inputProps, options, error, success, children, ...custom } = props

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });

  const marginTop = classNames({
    [classes.marginTop]: label === undefined
  });

  const [currentValue, setCurrentValue] = useState('')
  useEffect(() => {
    if (props.hasOwnProperty('input')) {
      setCurrentValue(props.input.value)
    } else if (props.hasOwnProperty('value')) {
      setCurrentValue(props.value)
    }
  })
  useEffect(() => {
    if (props.hasOwnProperty('input') || props.hasOwnProperty('value')) {
      setCurrentValue(props.hasOwnProperty('input') ? props.input.value : props.hasOwnProperty('value') ? props.value : '')
    }
  }, [currentValue])

  const handleChange = (event) => {

    if (event) {
      setCurrentValue(event.target.value)
      if (props.hasOwnProperty('input')) {
        props.input.onChange(event.target.value)
        if (props.hasOwnProperty('setCusCodeSoc')) {
          const soc = options.filter(item => item.MonLibelle === event.target.value)
          if (soc && soc.length === 1) {
            props.setCusCodeSoc(soc[0].CodeSociete)
          }
        }
      } else {

        props.onChange(event)
      }
    }
  }

  const handleSelect = (e, v) => {
    const target = {}
    target.name = e
    target.value = v ? v.MonLibelle : ""

    if (props.hasOwnProperty('updateField')) {
      dispatch(change("adForm", 'mySelected', v && v))
      //props.updateMyField('mySelected',v&&v)
    }
    setCurrentValue(v ? v.MonLibelle : "")
    //props.setCusCodeSoc(v.CodeSociete)
    if (props.hasOwnProperty('setCusCodeSoc') && v) {
      props.setCusCodeSoc(v.CodeSociete)
    }

    if (props.hasOwnProperty('input')) {
      props.input.onChange(v ? v.MonLibelle : "")
    } else {
      props.onChange({ target })
    }
  }

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps && formControlProps.className + " " + classes.formControl}
    >

      <Autocomplete
        options={options}
        getOptionLabel={option => option.MonLibelle}
        inputValue={currentValue}
        onInputChange={handleChange}
        onChange={(e, v) => handleSelect(name, v)}
        renderInput={params => (
          <TextField
            {...params}
            id={id}
            name={name}
            variant="outlined"
            label={label}
            fullWidth
            className={classes.labelClasses}
            InputLabelProps={{
              shrink: true,

            }}
            {...custom}
          />
        )}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>

  )
}

CustomSelect.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};