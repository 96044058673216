import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, authenticated,layout:Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !authenticated ? <Redirect to="/" /> : (Layout?(<Layout {...props} />):<Component {...props} />)
    }
  />
);

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

AuthRoute.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(AuthRoute);