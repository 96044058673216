import React, { useState, useEffect } from "react";
import { reduxForm, change, getFormValues, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux'
import OperationsFonctions from 'pages/Operations/OperationsFonctions'
import AddOperation from 'pages/Operations/AddOperation'
import OperationList from 'pages/Operations/OperationList'
import EditOperation from 'pages/Operations/EditOperation'
import { useLocation } from "react-router-dom";

function OperationsHome(props) {
    const [fonctionnalite, setFonctionalite] = useState('HOME')
    const [mode, setMode] = useState(null)
    const [cdata, setCdata] = useState(null)
    const [currentstate, setCurrentstate] = useState(null)
    const dispatch = useDispatch()
    const {state} = useLocation();

    const handleCliqueFonction = (element, md, data) => {
        if (element == 'ADDOP') {
            dispatch(reset('addOper'))
        }

        if (element == "LISTOP") {
            switch (md) {
                case "NTR":
                    setCurrentstate(1)
                    break;
                case "TRA":
                    setCurrentstate(2)
                    break;
                case "TRT":
                    setCurrentstate(3)
                    break;
                case "RJ":
                    setCurrentstate(4)
                    break;
                case "AN":
                    setCurrentstate(0)
                    break;
                default:
                    break;
            }
        }
        setFonctionalite(element)
        setMode(md)
        if (data) setCdata(data)
    }

    useEffect(() => {
        document.title = "ENOV - Gestion des opérations"
        
    })
    useEffect(()=>{
        if(state && state.hasOwnProperty('mode')){
            handleCliqueFonction('ADDOP',state['mode'],null)
        }
    },[])

    return (
        <div>
            {fonctionnalite === 'HOME' && <OperationsFonctions setMode={setMode} history={props.history} handleCliqueFonction={handleCliqueFonction} />}
            {fonctionnalite === 'ADDOP' && <AddOperation mode={mode} history={props.history} handleCliqueFonction={handleCliqueFonction} />}
            {fonctionnalite === 'EDITOP' && <EditOperation cdata={cdata} mode={mode} history={props.history} handleCliqueFonction={handleCliqueFonction} />}
            {fonctionnalite === 'LISTOP' && <OperationList history={props.history} currentstate={currentstate} handleCliqueFonction={handleCliqueFonction} />}
        </div>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adForm')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(OperationsHome))

