import React, { useState, useEffect, useReducer } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialTable, { MTableToolbar } from 'material-table'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { getCentres, getMaladies, getActeurSinistre, getDetailActeursSinistre } from 'redux/actions/apiActions'

import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDate from 'components/CustomDate/CustomDate'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import { compare } from 'application'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import dayjs from 'dayjs'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useSelector } from 'react-redux'
import { change, Field, registerField, getFormValues } from 'redux-form';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import { green, pink } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },


});
const useStyles = makeStyles(theme => ({
    dcard: {
        marginBottom: 10,
        paddingBottom: 10,
        flexWrap: 'wrap'
    },
    headerTitle: {
        display: 'flex',
        flex: 1,
        alignItems: 'center'
    },
    coreCustum: {
        borderRadius: 0,
        borderLeft: "10px solid #E35F5B"
    },
    itemTitle: {
        fontStyle: "italic"
    },
    assureInfoRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    paper: {
        marginBottom: 20,
        padding: 10
    },
    buttonSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,

        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonReset: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        color: '#FFF',
        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    buttonNewSearch: {
        width: '100%',
        marginBottom: 20,
        marginTop: 20,
        padding: 10,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: theme.palette.primary.main,
        backgroundColor: "#FFF",
        color: theme.palette.primary.main,
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        }
    },
}))
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
function ModalDetailOperation(props) {
    const { open, handleClose, data, handleAlertClose, handleSaveOperation, handleAlertOpen } = props
    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    const [state, setState] = React.useState({
        decesaccidentel: false,
        hunimationok: false,
        acteursType: null
    });
    const [assList, setAssList] = React.useState(null)
    const [checked, setChecked] = React.useState(null)
    const [expanded, setExpanded] = React.useState(true);
    const handleexpanded = (val) => {
        setExpanded(val ? val : !expanded)
    }
    const [centres, setCentres] = React.useState(null)
    const [maladies, setMaladies] = React.useState(null)
    const [datas, setDatas] = useState({})
    const handleSwithcChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    }
    let mesVilles = useSelector(state => state.api.villes).sort(compare)
    let professions = useSelector(state => state.api.professions).sort(compare)

    const handleCentre = () => {
        getCentres().then(res => {
            if (res) setCentres(res);
        })
    }
    const handleMaladie = () => {
        getMaladies().then(res => {
            if (res) setMaladies(res);
        })
    }

    const handleActeurSinistres = () => {
        getActeurSinistre().then(res => {
            if (res) {
                let acteurs = []
                acteurs = res.map(act => {
                    return { label: act.libelle, value: act.codetypedecces }
                })
                acteurs.push({ label: "AUTRE", value: "AUTRE" })
                if (acteurs && acteurs.length !== 0) {
                    setState({ ...state, acteursType: acteurs });
                }
            }
        })
    }
    useEffect(() => {
        handleCentre()
        handleMaladie()
        handleActeurSinistres()
    }, [])


    const handleActeursSinsitre = () => {
        if (data.hasOwnProperty('typeacteur') && data.hasOwnProperty('codeproposition')) {
            getDetailActeursSinistre({ type: data.typeacteur, proposition: data.codeproposition }).then(dataRes => {
                if (dataRes) {
                    if (dataRes.length > 1) {
                        setAssList(dataRes)
                    } else {
                        const currData = {}
                        currData['nomdefunt'] = dataRes[0].Nom
                        currData['prenomdefunt'] = dataRes[0].Prenom
                        currData['datenaissancedefunt'] = dayjs(dataRes[0].DateNaissance).format('YYYY-MM-DD')
                        currData['lieuresidencedefunt'] = dataRes[0].Lieuresidence
                        currData['lieunaissancedefunt'] = dataRes[0].Lieunaissance
                        currData['professiondefunt'] = dataRes[0].Profession
                        setDatas({ datas, ...currData })
                    }
                }
            })
        }
    }
    const handleSelectAssInList = (dataAss) => {
        setChecked(null)
        if (dataAss) {
            setChecked(dataAss.CodePersonne)
            const currData = {}
            currData['nomdefunt'] = dataAss.Nom
            currData['prenomdefunt'] = dataAss.Prenom
            currData['datenaissancedefunt'] = dayjs(dataAss.DateNaissance).format('YYYY-MM-DD')
            currData['lieuresidencedefunt'] = dataAss.Lieuresidence
            currData['lieunaissancedefunt'] = dataAss.Lieunaissance
            currData['professiondefunt'] = dataAss.Profession
            setDatas({ datas, ...currData })
        }
    }
    useEffect(() => {
        handleActeursSinsitre()
    }, [data.typeacteur])
    const [filterField, setFilterField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            naturesinistre: data.naturesinistre,
            lieusinistre: data.lieusinistre,
            causesinistre: data.causesinistre,
            groupeoperation: data.groupeoperation,
            datesinistre: data.datesinistre,
            lieuconservcorps: data.lieuconservcorps,
            dateinhumation: data.dateinhumation,
            lieuinhumation: data.lieuinhumation,
            nomdefunt: data.nomdefunt,
            datenaissancedefunt: data.datenaissancedefunt,
            lieuresidencedefunt: data.lieuresidencedefunt,
            professiondefunt: data.professiondefunt,
            prenomdefunt: data.prenomdefunt,
            lieunaissancedefunt: data.lieunaissancedefunt,
            secteuractivite: data.secteuractivite,
        });
    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFilterField({
            [name]: value
        })
    }
    const handleValide = async () => {
        const dataSend = filterField
        dataSend['id'] = data.id
        handleSaveOperation(dataSend)
    }

    return (
        <div>
            <Dialog
                onClose={handleClose}
                fullWidth
                disableBackdropClick
                maxWidth={'md'}
                aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {`Edition opération #Ref ${data && data['codeoperation']} - ${data['id']} - Opération`}
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.paper}>
                        <LabelledOutline label="Description du sinistre">
                            <GridContainer>
                                <GridItem sm={12} xs={12} md={6} lg={6}>
                                    <CustomSimpleSelect
                                        required
                                        id="naturesinistre"
                                        name="naturesinistre"
                                        label="Quelle nature du sinistre ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={filterField['naturesinistre'] || ""}
                                        onChange={handleFilterChange}
                                        options={['DECES', 'INVALIDITE']}
                                    />
                                    {centres && <CustomSelect
                                        required
                                        label="Dans quel etablissement le sinistre a eu lieu ?"
                                        id="lieusinistre"
                                        name="lieusinistre"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={filterField['lieusinistre'] || ""}
                                        onChange={handleFilterChange}
                                        options={centres}
                                        variant="outlined"
                                    />}
                                    {maladies && <CustomSelect
                                        required
                                        label="Quelle est la cause du sinistre ?"
                                        id="causesinistre"
                                        name="causesinistre"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={filterField['causesinistre'] || ""}
                                        onChange={handleFilterChange}
                                        options={maladies}
                                        variant="outlined"
                                    />}
                                </GridItem>
                                <GridItem sm={12} xs={12} md={6} lg={6}>
                                    <CustomDate
                                        required
                                        id="datesinistre"
                                        name="datesinistre"
                                        label="A quelle date le sinistre a eu lieu ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={filterField['datesinistre'] ? dayjs(filterField['datesinistre']).format('YYYY-MM-DD') : ""}
                                        onChange={handleFilterChange}
                                        InputProps={{
                                            type: 'date',
                                        }}
                                    />
                                    <CustomInput
                                        required
                                        id="lieuconservcorps"
                                        name="lieuconservcorps"
                                        label="Lieu de conservation du corps"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={filterField['lieuconservcorps'] || ""}
                                        type='texte'
                                        variant="outlined"
                                        onChange={handleFilterChange}
                                    />
                                    <CustomDate
                                        required
                                        id="dateinhumation"
                                        name="dateinhumation"
                                        label="Quelle est la date l'enterrement ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={filterField['dateinhumation'] ? dayjs(filterField['dateinhumation']).format('YYYY-MM-DD') : ""}
                                        onChange={handleFilterChange}
                                        InputProps={{
                                            type: 'date',
                                        }}
                                    />
                                    <CustomInput
                                        required
                                        id="lieuinhumation"
                                        name="lieuinhumation"
                                        label="Quel est le lieu de l'entrerrement ?"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        value={filterField['lieuinhumation'] || ""}
                                        onChange={handleFilterChange}
                                        type='texte'
                                        variant="outlined"
                                    />
                                </GridItem>
                            </GridContainer>
                        </LabelledOutline>
                    </div>
                    <div className={classes.paper}>
                        <LabelledOutline label="Type de sinistre">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    classes={{ label: classes.switcLabel }}
                                    control={<Switch classes={{
                                        root: classes.sswitch,
                                        switchBase: classes.switchBase,
                                        thumb: classes.thumb,
                                        track: classes.track,
                                        checked: classes.checked,
                                    }} checked={state.decesaccidentel} onChange={handleSwithcChange} color="primary" name="decesaccidentel" />}
                                    label="Le décès est-il accidentel ?"
                                    labelPlacement="start"
                                />
                                <FormControlLabel
                                    classes={{ label: classes.switcLabel }}
                                    control={<Switch classes={{
                                        root: classes.sswitch,
                                        switchBase: classes.switchBase,
                                        thumb: classes.thumb,
                                        track: classes.track,
                                        checked: classes.checked,
                                    }} checked={state.hunimationok} onChange={handleSwithcChange} color="primary" name="hunimationok" />}
                                    label="L'hunimation à t'il déjà eu lieu ?"
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </LabelledOutline>
                    </div>
                    <div>
                        <Accordion expanded={expanded}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant='h5'>{(assList && assList.length !== 0) ? "Selectionnez le défunt" : "Informations sur le défunt"}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {(assList && assList.length !== 0) ? (<TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" >#</TableCell>
                                                <TableCell align="center" >Nom</TableCell>
                                                <TableCell align="center">Né(e) le</TableCell>
                                                <TableCell align="center">Lieu de naissance</TableCell>
                                                <TableCell align="center">Lieu de residence</TableCell>
                                                <TableCell align="center">Profession</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {assList.map(ass => (
                                                <TableRow key={ass.CodePersonne}>
                                                    <TableCell align="center" component="th" scope="row">
                                                        <Checkbox
                                                            checked={(checked === ass.CodePersonne)}
                                                            onChange={(e) => handleSelectAssInList(ass)}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">{`${ass.Nom} ${ass.Prenom}`}</TableCell>
                                                    <TableCell align="center">{dayjs(ass.DateNaissance.toString()).isValid() ? dayjs(ass.DateNaissance).format('DD/MM/YYYY') : ""}</TableCell>
                                                    <TableCell align="center">{ass.Lieunaissance}</TableCell>
                                                    <TableCell align="center">{ass.lieuresidence}</TableCell>
                                                    <TableCell align="center">{ass.Profession}</TableCell>
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>) : (<GridContainer>
                                    <GridItem sm={12} xs={12} md={6} lg={6}>
                                        <CustomInput
                                            id="nomdefunt"
                                            name="nomdefunt"
                                            label="Nom du défunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={filterField['nomdefunt'] || ""}
                                            onChange={handleFilterChange}
                                            type='texte'
                                            variant="outlined"
                                        />
                                        <CustomDate
                                            id="datenaissancedefunt"
                                            name="datenaissancedefunt"
                                            label="Date de naissance"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={filterField['datenaissancedefunt'] ? dayjs(filterField['datenaissancedefunt']).format('YYYY-MM-DD') : ""}
                                            onChange={handleFilterChange}
                                            InputProps={{
                                                type: 'date',
                                            }}
                                        />
                                        <CustomSelect
                                            label="Lieu de résidence"
                                            id="lieuresidencedefunt"
                                            name="lieuresidencedefunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={filterField['lieuresidencedefunt'] || ""}
                                            onChange={handleFilterChange}
                                            options={mesVilles}
                                            variant="outlined"

                                        />
                                        <CustomSelect
                                            label="Profession"
                                            id="professiondefunt"
                                            name="professiondefunt"
                                            onChange={handleFilterChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={filterField['professiondefunt'] || ""}
                                            options={professions}

                                        />
                                    </GridItem>
                                    <GridItem sm={12} xs={12} md={6} lg={6}>
                                        <CustomInput
                                            id="prenomdefunt"
                                            name="prenomdefunt"
                                            label="Préom du défunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={filterField['prenomdefunt'] || ""}
                                            onChange={handleFilterChange}
                                            type='texte'
                                            variant="outlined"
                                        />
                                        <CustomSelect
                                            label="Lieu de naissance"
                                            id="lieunaissancedefunt"
                                            name="lieunaissancedefunt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={filterField['lieunaissancedefunt'] || ""}
                                            onChange={handleFilterChange}
                                            options={mesVilles}
                                            variant="outlined"

                                        />
                                        <CustomSelect
                                            label="Secteur d'activité"
                                            id="secteuractivite"
                                            name="secteuractivite"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            value={filterField['secteuractivite'] || ""}
                                            onChange={handleFilterChange}
                                            options={secteurActivites}
                                        />
                                    </GridItem>
                                </GridContainer>)}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.buttonSearch} autoFocus onClick={handleValide} color="primary" >
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ModalDetailOperation

const secteurActivites = [
    { "MonLibelle": 'Agroalimentaire' },
    { "MonLibelle": 'Banque / Assurance' },
    { "MonLibelle": 'Bois / Papier / Carton / Imprimerie' },
    { "MonLibelle": 'BTP / Matériaux de construction' },
    { "MonLibelle": 'Édition / Communication / Multimédia' },
    { "MonLibelle": 'Électronique / Électricité' },
    { "MonLibelle": 'Études et conseils' },
    { "MonLibelle": 'Industrie pharmaceutique' },
    { "MonLibelle": 'Informatique / Télécoms' },
    { "MonLibelle": 'Machines et équipements / Automobile' },
    { "MonLibelle": 'Métallurgie / Travail du métal' },
    { "MonLibelle": 'Plastique / Caoutchouc' },
    { "MonLibelle": 'Services aux entreprises' },
    { "MonLibelle": 'Textile / Habillement / Chaussure' },
    { "MonLibelle": 'Transports / Logistique' },
    { "MonLibelle": 'Autre' }
]
