import React,{useEffect,useReducer,useState} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CustomInput from 'components/CustomInput/CustomInput'
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {connect,useDispatch} from 'react-redux'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertPage from 'components/AlertPage'
import {addEquipe,getReseaux,getZonesReseau,getEquipes} from 'redux/actions/settingsAction'
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
          marginLeft: theme.spacing(2),
        },
        flex:1,
        justifyContent:'center',
        alignItems:'center'
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }));


function AddEquipe(props){
    const {mopen,handleCloseMod,settings:{reseaux,equipes},}=props
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [zones,setZones] = React.useState([]);
    const  dispatch = useDispatch()
    const classes = useStyles();
    useEffect(() => {
        dispatch(getReseaux())
        dispatch(getEquipes())
    }, [])
    
    const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
    {
    codeequipe:'',
    codezone:'',
    libelleequipe:'',
    coderesponsable:'',
    nomresponsable:'',
    codereseau:''
    });

    useEffect(() => {
      if(inputField.codereseau!==''){
        getZonesReseau(inputField.codereseau).then(datar=>{
          if(datar){
            setZones(datar)
          }
        })
      }
    },[inputField.codereseau])
    const [loading,setLoading] = React.useState(false)
    const [alertInfo,setAlertInfo]=useState({})
    const handleAlertClose =(action=null)=>{
        setAlertInfo({open:false,message:"",succes:false})
        action&&action()
    }
    const handleAlertOpen =(message,succes=false,action)=>{
        setAlertInfo({open:true,message:message,succes:succes,action:action})
    }
   
  const handleChange = (event)=>{
    const {name,value} = event.target
    setInputField({
        [name]:value
    })
  }
  const handleSaveEquipe =()=>{
    if(inputField.codezone==='')return
    if(inputField.libelleequipe.trim()==='')return
    if(inputField.codeequipe.trim()==='')return
    setLoading(true)
    props.addEquipe(inputField).then(data=>{
        if(data){
            setInputField({
                ['codeequipe']:'',
                ['codezone']:'',
                ['libelleequipe']:'',
                ['coderesponsable']:'',
                ['nomresponsable']:'',
                ['codereseau']:'',
            })
            setLoading(false)
            handleAlertOpen("Enregistrement effectué avec succès",true,handleCloseMod)
        }else{
            setLoading(false)
            handleAlertOpen("Erreur lors de l'enregistrement",false,null)
            return false
        }
    }).catch(err=>{
        console.log(err);
        setLoading(false)
        handleAlertOpen("Erreur lors de l'enregistrement",false,null)
        return
    })
  }
    return (
        <Dialog maxWidth='md' fullWidth open={mopen} onClose={handleCloseMod} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ajouter une équipe </DialogTitle>
        <DialogContent>
            <AlertPage open={alertInfo['open']||false}  action={alertInfo['action']||null} message={alertInfo['message']||""} succes={alertInfo['succes']||false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />  
            {!loading?(<>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                    <CustomInput
                        required
                        autoFocus
                        variant="outlined"
                        id="codeequipe"
                        name='codeequipe'
                        label="code"
                        type="text"
                        value = {inputField.codeequipe}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={8} lg={8}>
                        <CustomInput
                            autoFocus
                            required
                            variant="outlined"
                            id="libelleequipe"
                            name ='libelleequipe'
                            onChange={handleChange}
                            label="Nom de l'équipe / agence"
                            type="text"
                            value = {inputField.libelleequipe}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                        <CustomInput
                            required
                            autoFocus
                            variant="outlined"
                            id="coderesponsable"
                            name='coderesponsable'
                            label="code responsable"
                            type="text"
                            value = {inputField.coderesponsable}
                            formControlProps={{
                                fullWidth: true
                            }}
                            onChange={handleChange}
                        />
                        </GridItem>
                        <GridItem xs={12} sm={8} md={8} lg={8}>
                        <CustomInput
                            autoFocus
                            required
                            variant="outlined"
                            id="nomresponsable"
                            name ='nomresponsable'
                            onChange={handleChange}
                            label="Nom responsable"
                            type="text"
                            value = {inputField.nomresponsable}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        id="codereseau"
                        name ='codereseau'
                        select
                        label="Réseau de commercialisation"
                        value={inputField.codereseau}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{marginTop:20,marginBottom:10}}
                        >
                        <MenuItem value='' />
                        {(reseaux.length !==0)&& reseaux.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                            {option.libelle}
                            </MenuItem>
                        ))}
                        </TextField>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                        variant="outlined"
                        id="codezone"
                        name ='codezone'
                        select
                        label="Zone/Inspection/Departement"
                        value={inputField.codezone}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{marginTop:20,marginBottom:10}}
                        >
                        <MenuItem value='' />
                        {(zones.length !==0)&& zones.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                            {option.libellezone}
                            </MenuItem>
                        ))}
                        </TextField>
                    </GridItem>
                </GridContainer> 
                <Divider />
            </>):(<div className={classes.root}><CircularProgress color="primary" /></div>)}
                  
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMod} variant='outlined' color="secondary">Annuler</Button>
          <Button onClick={handleSaveEquipe} variant='outlined' color="primary">Ajouter</Button>
        </DialogActions>
      </Dialog>
    )
}


const mapStateToProps = (state) => ({
    settings: state.settings,
    user:state.user
  });
  const mapActionsToProps = {addEquipe}
export default connect(mapStateToProps,mapActionsToProps)(AddEquipe)