import React,{ useReducer,useEffect,useState,useLayoutEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    getEquipesReseau,
    getEquipesZones,
    getZonesReseau,
    getZonesNoDispatch,
    getEquipesNoDispatch
  } from 'redux/actions/settingsAction'

  import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
  import Paper from '@material-ui/core/Paper';
  import TextField from '@material-ui/core/TextField';
  import InputLabel from '@material-ui/core/InputLabel';
  import MenuItem from '@material-ui/core/MenuItem';
  import FormControl from '@material-ui/core/FormControl';
  import Select from '@material-ui/core/Select';
  import GridItem from "components/Grid/GridItem.js";
  import GridContainer from "components/Grid/GridContainer.js";
  import Button from '@material-ui/core/Button';
  import { Grid } from '@material-ui/core';
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    assureInfoRow:{
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-between'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    buttonAdd:{
      width:'100%',
      marginBottom:20,
      borderWidth:1,
      borderStyle:'dashed',
      borderColor:theme.palette.secondary.main,
      borderRadius:30,
      '&:hover':{
        borderColor:theme.palette.primary.main,
      }
    },
    buttonAddChild:{
      color:theme.palette.secondary.main,
      '&:hover':{
        color:theme.palette.primary.main,
      }
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    buttonSearch:{
      borderWidth:1,
      borderStyle:'dashed',
      borderColor:theme.palette.primary.main,
      backgroundColor:theme.palette.primary.main,
     
      color:'#FFF',
      '&:hover':{
        backgroundColor:theme.palette.secondary.main,
        borderColor:theme.palette.secondary.main,
      }
    }
  }));
  

function PretFilter(props){
    const classes = useStyles();
    const {credentials,handleFilterData} =props
    //filter
  const [filterField,setFilterField]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    partenaire:credentials?credentials.codereseau:0,
    zone:[5,7,10].includes(credentials.coderole)?credentials.codezone:0,
    agence:[5,10].includes(credentials.coderole)?credentials.codeequipe:0,
  });
  const handleFilterChange =(event)=>{
    const {name,value} = event.target
    setFilterField({
        [name]:value
    })
  }
  
  const [zoneList,setZoneList] = useState([])
  const handleZoneList =useCallback(function(){
    if(filterField.partenaire && filterField.partenaire!==''){
      getZonesReseau(filterField.partenaire).then(res=>{
        if(res){
          setZoneList(res)
        }
      })
    }else{
      getZonesNoDispatch().then(res=>{
        if(res){
          setZoneList(res)
        }
      })
    }
  },[])
  const [agenceList,setAgenceList] =useState([])
  const handleAgenceList =useCallback(function(){
    if(filterField.partenaire && filterField.partenaire!==''){
      getEquipesReseau(filterField.partenaire).then(res=>{
        if(res){
          setAgenceList(res)
        }
      })
    }else if(filterField.zone && filterField.zone!==''){
      getEquipesZones(filterField.zone).then(res=>{
        if(res){
          setAgenceList(res)
        }
      })
    }else{
      getEquipesNoDispatch().then(res=>{
        if(res){
          setAgenceList(res)
        }
      })
    }
  },[filterField.zone,filterField.partenaire])
  
  useEffect(() => {
    handleZoneList()
  },[])
  useEffect(() => {
    handleAgenceList()
  }, [filterField.zone,filterField.partenaire])

    return(
          <GridContainer>
            <GridItem style={{marginTop:20,marginBottom:20}} xs={12} sm={12} md={12} lg={12}>
            <LabelledOutline label="Filtres">
              <Paper elevation={3}>
                <div style ={{padding:10}}>
                  <Grid container>
                    <form className={classes.container} noValidate>
                      <div style={{border:"1px solid #f3f3", padding:10}}><TextField
                        id="dateSaisieDebut"
                          name="dateSaisieDebut"
                        label="Saisie du"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange ={handleFilterChange}
                        variant='outlined'
                      />
                      <TextField
                        id="dateSaisieFin"
                        name="dateSaisieFin"
                        label="Au"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange ={handleFilterChange}
                        variant='outlined'
                      /></div>
                      <div style={{border:"1px solid #f3f3", padding:10}}><TextField
                        id="dateMiseEnPlaceDebut"
                        name="dateMiseEnPlaceDebut"
                        label="Mise en place du"
                        type="date"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange ={handleFilterChange}
                        variant='outlined'
                      />
                      <TextField
                        id="dateMisEnPlaceFin"
                        name="dateMisEnPlaceFin"
                        label="Au"
                        type="date"
                        onChange ={handleFilterChange}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant='outlined'
                      /></div>
                      <div style={{border:"1px solid #f3f3", padding:10}}>
                            {([9,8].includes(credentials.coderole))&&<FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Zone / inspection</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="zone"
                                name="zone"
                                value={filterField.zone}
                                onChange ={handleFilterChange}
                                label="Zone / Inspection"
                              >
                                <MenuItem value="0">
                                  <em>Aucun</em>
                                </MenuItem>
                                {(zoneList && zoneList.length!==0)&&(zoneList.map(zone=><MenuItem key={`${zone.id}`} value={zone.id}>{zone.libelle}</MenuItem>) )}
                                
                                
                              </Select>
                            </FormControl>}
                            {([7,8,9].includes(credentials.coderole))&&<FormControl variant="outlined" className={classes.formControl}>
                              <InputLabel id="demo-simple-select-outlined-label">Agence/Equipe</InputLabel>
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="agence"
                                name="agence"
                                value={filterField.agence}
                                onChange ={handleFilterChange}
                                label="Agence/Equipe"
                              >
                                <MenuItem value="0">
                                  <em>Aucun</em>
                                </MenuItem>
                                {(agenceList && agenceList.length!==0)&&agenceList.map(ag=>(<MenuItem key={ag.id} value={`${ag.id}`}>{ag.libelleequipe}</MenuItem>))}
                                
                              </Select>
                            </FormControl>}
                        </div>
                        <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleFilterData} >
                            FILTRER
                        </Button> 
                    </form>
                  </Grid>
                </div>
              </Paper>
            </LabelledOutline>
          </GridItem>
        </GridContainer>

    )
}

export default PretFilter