import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from "assets/images/logoYnovGreen.png";
import classNames from "classnames";
import { Tooltip } from '@material-ui/core';
import logo2 from "assets/images/icone.png";
import { Switch, Route } from "react-router-dom";
import {connect} from 'react-redux'
import { useHistory } from 'react-router-dom';
import ChangePassword from 'pages/Dashboard/ChangePassword'
import Footer from "components/Footer/Footer.js";
import Welcome from 'pages/Dashboard/Welcome';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SchoolIcon from '@material-ui/icons/School';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks";

import Esouscription from './Esouscription';
import Eprestation from './Eprestation';
import Esinistre from './Esinistre';
import Epret from './Epret';

import SettingsIcon from '@material-ui/icons/Settings';
import AdminHome from 'pages/Banking/Administration/AdminHome'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AppsIcon from '@material-ui/icons/Apps';
import EtatHome from 'pages/Banking/Etats/EtatHome';
import OperationJournal from 'pages/Commons/Journaux/OperationJournal';
import OperationRapport from 'pages/Commons/Rapport/OperationRapport';
import FormationHome from 'pages/Banking/Formation/FormationHome';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:"white"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    color:theme.palette.primary.main
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor:theme.palette.primary.main
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  too:{
    padding: theme.spacing(1, 1),
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3,0,3,0),
  },
  

  logoContainer:{
    width:'100px'
   },
    img:{
        width:'100%',
        cursor:'pointer'
    },
    imgicon:{
        width:'50%',
        cursor:'pointer'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
      },
      sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      btnnew:{
        backgroundColor:theme.palette.secondary.main,
        borderRadius:50,
        padding:"10px 20px",
        textTransform: 'capitalize',
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
        }
      },
      iconMenu:{ 
        color:theme.palette.primary.main,
        fontSize:40, 
        "&:hover":{
          color:theme.palette.secondary.main,
        }
      },
      item:{
        backgroundColor:theme.palette.secondary.main,
        "&:nth-child(-n + 3)":{
          color:'white'
        }
      },
}));

let ps;



function DrawerLayout(props) {
  const { ui: { layoutRoot },user:{credentials} } = props
  const classes = useStyles();
  const theme = useTheme();
  let autoOpen = true
//  useMediaQuery, useTheme 
  const [open, setOpen] = React.useState(autoOpen);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const history = useHistory()
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlehome=()=>{
    setCmenu('home')
    history.push(`/${layoutRoot}`)
  }
  const [cmenu,setCmenu] =React.useState('home')
  const handleSelectMenu =(op)=>{
    setCmenu(op)
  }
  //selection d'une application
  const handleApplication=(ap)=>{
    if(ap){
        switch (ap) {
            case 'souscription':
                setCmenu('souscription')
                history.push(`/${layoutRoot}/esouscription`)
                break;
            case 'prestation':
                setCmenu('prestation')
                history.push(`/${layoutRoot}/eprestation`)
                break;
            case 'pret':
                setCmenu('pret')
                history.push(`/${layoutRoot}/epret`)
                break;
            case 'sinistre':
                setCmenu('sinistre')
                history.push(`/${layoutRoot}/esinistre`)
                break;
            case 'etat':
              setCmenu('etat')
              history.push(`/${layoutRoot}/etat`)
              break;
            case 'rapport':
              setCmenu('rapport')
              history.push(`/${layoutRoot}/rapport`)
              break;
            case 'journaux':
              setCmenu('journaux')
              history.push(`/${layoutRoot}/journaux`)
              break;
            case 'formation':
              setCmenu('formation')
              history.push(`/${layoutRoot}/formation`)
              break;
            case 'parametre':
                setCmenu('parametre')
                history.push(`/${layoutRoot}/parametre`)
                break;
              
            default:
                return
        }
    }
  }
  
  if (isWidthUp("md", props.width,true) || isWidthUp("lg", props.width,true)) {
    autoOpen = true ;
    } else {
      autoOpen =false ;
  }
  window.onresize = function(event) {
      if (isWidthUp("md", props.width,true) || isWidthUp("lg", props.width,true)) {
        autoOpen=true ;
      } else {
        autoOpen=false ;
      }
  };
  const handleAutopen =()=>{
    setOpen(autoOpen)
  }
  useEffect(()=>{
    handleAutopen()
  },[autoOpen])
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <FormatAlignLeftIcon />
          </IconButton>
            <div className={classes.logoContainer}>
                <a
                    href={`/${layoutRoot}`}
                    className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive
                    })}
                    target="_blank"
                >
                    <img src={logo} alt="Logo" className={classes.img} />
                </a>
            </div>
            <div className={classes.grow} />
            
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <AdminNavbarLinks />
              
            </div>
        </Toolbar>
      </AppBar>
      
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
            <div className={classes.logoContainer}>
                <a
                    href="#"
                    className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: props.rtlActive
                    })}
                    target="_blank"
                >
                    <img src={logo2} alt="Icon" className={classes.imgicon} />
                </a>
            </div>
          
            <IconButton onClick={handleDrawerClose}>
                <FormatAlignLeftIcon />
            </IconButton>
        </div>
        <Divider />
          <List>
            <Tooltip title={!open?"Accueil":""} arrow placement='right'>
              <ListItem button onClick ={handlehome} className={(cmenu.indexOf('home')!==-1)&&classes.item}>
                <ListItemIcon><AppsIcon className={classes.iconMenu} /></ListItemIcon>
                <ListItemText primary="Accueil" />
            </ListItem>
            </Tooltip>
          </List>
        <Divider />
        <List>
            <Tooltip title={!open?"e-Souscriptions":""} arrow placement='right'>
              <ListItem button onClick ={()=>handleApplication('souscription')} className={(cmenu.indexOf('souscription')!==-1)&&classes.item}>
                <ListItemIcon> <AssignmentIcon className={classes.iconMenu} /> </ListItemIcon>
                <ListItemText primary="e-Souscriptions" />
              </ListItem>
            </Tooltip>
            <Tooltip title={!open?"e-Prestations":""} arrow placement='right'>
            <ListItem button onClick ={()=>handleApplication('prestation')} className={(cmenu.indexOf('prestation')!==-1)&&classes.item}>
              <ListItemIcon> <ExtensionIcon className={classes.iconMenu} /> </ListItemIcon>
              <ListItemText primary="e-Prestations" />
            </ListItem>
            </Tooltip>
            <Tooltip title={!open?"e-Sinistres":""} arrow placement='right'>
            <ListItem button onClick ={()=>handleApplication('sinistre')} className={(cmenu.indexOf('sinistre')!==-1)&&classes.item}>
              <ListItemIcon> <AccessibleForwardIcon className={classes.iconMenu} /> </ListItemIcon>
              <ListItemText primary="e-Sinistres" />
            </ListItem>
            </Tooltip>
            {/*
            <Tooltip title={!open?"e-Prêts":""} arrow placement='right'>
            <ListItem button onClick ={()=>handleApplication('pret')} className={(cmenu.indexOf('pret')!==-1)&&classes.item}>
              <ListItemIcon> <AccountBalanceIcon className={classes.iconMenu} /> </ListItemIcon>
              <ListItemText primary="e-Prêts" />
            </ListItem>
            </Tooltip> */}
        </List>

        <Divider />
        <List>
            <Tooltip title={!open?"Etats":""} arrow placement='right'>
              <ListItem button onClick={()=>handleApplication('etat')} className={(cmenu.indexOf('etat')!==-1)&&classes.item}>
                <ListItemIcon><AccountBalanceWalletIcon className={classes.iconMenu} /> </ListItemIcon>
                <ListItemText primary="Etats" />
              </ListItem>
            </Tooltip>
            {
              (credentials.codepartenaire !=='092')&&<>
              <Tooltip title={!open?"Rapports":""} arrow placement='right'>        
                    <ListItem button onClick={()=>handleApplication('rapport')} className={(cmenu.indexOf('rapport')!==-1)&&classes.item}>
                      <ListItemIcon><EqualizerIcon className={classes.iconMenu} /> </ListItemIcon>
                      <ListItemText primary="Rapports" />
                  </ListItem>
              </Tooltip>
              <Tooltip title={!open?"Journaux":""} arrow placement='right'>    
                  <ListItem button onClick={()=>handleApplication('journaux')} className={(cmenu.indexOf('journaux')!==-1)&&classes.item}>
                    <ListItemIcon><FormatListBulletedIcon className={classes.iconMenu} /> </ListItemIcon>
                    <ListItemText primary="Journaux" />
                  </ListItem>
              </Tooltip>
              </>
            }
            <Tooltip title={!open?"Formations":""} arrow placement='right'>
              <ListItem button onClick={()=>handleApplication('formation')} className={(cmenu.indexOf('formation')!==-1)&&classes.item}>
                <ListItemIcon><SchoolIcon className={classes.iconMenu} /> </ListItemIcon>
                <ListItemText primary="Formations" />
              </ListItem>
            </Tooltip>
            <Tooltip title={!open?"Paramètres":""} arrow placement='right'>
              <ListItem button onClick={()=>handleApplication('parametre')} className={(cmenu.indexOf('parametre')!==-1)&&classes.item}>
                <ListItemIcon><SettingsIcon className={classes.iconMenu} /> </ListItemIcon>
                <ListItemText primary="Paramètres" />
              </ListItem>
            </Tooltip>
        </List>
      </Drawer>
        <main className={classes.content}>
            <div className={classes.too} />
            <Switch>
                <Route path={`/${layoutRoot}/esinistre`} component={Esinistre} name='Bienvenue sur e-sinistre' />
                <Route path={`/${layoutRoot}/eprestation`} component={Eprestation} name='Bienvenue sur e-prestation' />
                <Route path={`/${layoutRoot}/esouscription`} component={Esouscription} name='Bienvenue sur e-souscription' />
                <Route path={`/${layoutRoot}/epret`} component={Epret} name='Bienvenue sur e-pret' />
                <Route path={`/${layoutRoot}/etat`} component={EtatHome} name='Etat' />
                <Route path={`/${layoutRoot}/rapport`} component={OperationRapport} name='Rapport' />
                <Route path={`/${layoutRoot}/Journaux`} component={OperationJournal} name='Journal' />
                <Route path={`/${layoutRoot}/formation`} component={FormationHome} name='formation' />
                <Route path={`$/${layoutRoot}/modification-mot-passe`} component={ChangePassword} name='Modification du mot de passe' />
                <Route path={`/${layoutRoot}/parametre`} component={AdminHome} name='Administration' />
                <Route from={`/${layoutRoot}`} component={Welcome} name='Bienvenue sur Y nov' />
            </Switch>
            <Footer />
        </main>
        
    </div>
  );
}

const mapStateToProps = (state) => ({
  ui: state.UI,
  user: state.user
});

const mapActionsToProps = {}
export default withWidth()(connect(mapStateToProps, mapActionsToProps)(DrawerLayout))