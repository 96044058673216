import {
  SET_VILLE,
  SET_CIVILITE,
  SET_SITUATION,
  SET_PAYS,
  SET_SOCIETE,
  SET_PRODUIT,
  SET_PROFESSION,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_PRETS,
  SET_PROPOSITIONS,
  STOP_LOADING_UI
} from '../types';
import axios from 'axios';
import { getBrancheCodeProduit, getUserData } from 'redux/actions/userActions'


//selection des villes
export const getVille = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  return axios
    .get('/villes')
    .then((res) => {
      dispatch({
        type: SET_VILLE,
        payload: res.data
      });
      dispatch({ type: CLEAR_ERRORS });
      return res.data
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
      return false;
    });
};
//selection des civilités
export const getCivilite = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get('/civilites')
    .then((res) => {
      dispatch({
        type: SET_CIVILITE,
        payload: res.data
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
    });
};
//selection des pays
export const getPays = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get('/pays')
    .then((res) => {
      dispatch({
        type: SET_PAYS,
        payload: res.data
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
    });
};
//selection situation matrimoniale
export const getSitMatiromoniale = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get('/situation-matrimoniales')
    .then((res) => {
      dispatch({
        type: SET_SITUATION,
        payload: res.data
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
    });
};
//selection des professions
export const getProfession = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get('/professions')
    .then((res) => {
      dispatch({
        type: SET_PROFESSION,
        payload: res.data
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
    });
};
//selection des sociétés
export const getSociete = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get('/societes')
    .then((res) => {
      dispatch({
        type: SET_SOCIETE,
        payload: res.data
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
    });
};
//selection de mode de paiement
export const getModePaiement = () => {
  return axios
    .get('/mode-paiement')
    .then((res) => {
      if(res.status ===200 || res.status === 201){
        return res.data
      }else{
        return false
      }
    })
    .catch((err) => {
      if(err && err.response){
        console.log(err.response.data)
      }
      return false
    });
};
//selection de produit par branche
export const getProduit = (codeBranche = '') => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/produits`)
    .then((res) => {
      
      if (codeBranche !== '') {
        reduceProduitBranche(codeBranche, res.data).then((data) => {
          dispatch({
            type: SET_PRODUIT,
            payload: data
          });
        });
      } else {
        dispatch({
          type: SET_PRODUIT,
          payload: res.data
        });
      }

      dispatch({ type: CLEAR_ERRORS });
    }).catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
    });
};
//selection tous les produit
export const getProduitAll = () => {
  return axios
    .get('/produits')
    .then((res) => {
     
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    });
};
//selection formules produit
export const getProduitFormules = (codeProduit = '') => {
  return axios
    .get(`/produit-formules/${codeProduit}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    });
};
//uploader document
export const uploadDocuments = (id, data, source = 'ES',titres=null) => {
  let formData = new FormData();
  formData.append('id', id)
  formData.append('source', source)
  
  if(titres){
    let tabTitre = titres.split('||')
    if(tabTitre && tabTitre?.length!==0){
      tabTitre.forEach(element => {
        formData.append('titre[]', element);
      });
      
    }
    data?.map(element => {
      formData.append('documents[]', element)
    })
  }else{
    data?.map(element => {
      formData.append('documents[]', element)
      formData.append('titre[]', element['titre']);
    })
  }

  return axios.post('/upload-documents', formData).then((res) => {
    if (res.status === 200 || res.status === 201) {
      return true
    } else {
      return false
    }
  }).catch((err) => {
    console.log(err);
    if (err.hasOwnProperty('response')) {
      console.log(err.response);
    }
    return false
  })
}
//Ajouter une souscription
export const addSouscription = (datatSouscription, authenticated = false) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  return axios
    .post('/add-souscription', datatSouscription)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {

        dispatch({ type: CLEAR_ERRORS });
        if (authenticated) {
          dispatch(selectPropositionByUser())
        }

        return res.data
      } else {
        dispatch({ type: SET_ERRORS, payload: { error: "Erreur lors de l'enregistrement" } });
        return false
      }
    })
    .catch((err) => {
      console.log(err.response);
      dispatch({
        type: SET_ERRORS,
        payload: err.response
      });
    });
};
//selection des filiations
export const getFiliations = () => {
  return axios
    .get('/filiations')
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//régupération des garanties de produits
export const getGarantiesProduit = (codeProduit) => (dispatch) => {
  dispatch({ type: LOADING_UI })
  if (!codeProduit) {
    dispatch({ type: SET_ERRORS, payload: { error: 'Code produit incorrect' } })
    return false
  }
  return axios
    .get('/garanties-produit/' + codeProduit)
    .then((res) => {
      if (res.status === 200) {
        dispatch({ type: CLEAR_ERRORS })
        return res.data
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: res.data
        })
        return false
      }
    }).catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err
      })
      return false
    })

}
//récupération des garanties assurées
export const getGarantiesAssure = (codeProduit, ageAssure,refcode=null) => (dispatch) => {
 
  if (!codeProduit) {
    dispatch({ type: SET_ERRORS, payload: { error: 'Code produit incorrect' } })
    return false
  }
  if (!ageAssure) {
    dispatch({ type: SET_ERRORS, payload: { error: 'Age assuré incorrect' } })
    return false
  }
  let url = `/garanties-assure/${codeProduit}/${ageAssure}`

  if(refcode){url = url+"/"+refcode}
  
  return axios
    .get(url)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: res.data
        })
        return false
      }
    }).catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err
      })
      if (err.response) {
        console.log(err.response.data);
        
      } else if (err.request) {
        console.log(err.request);
      } else {
        console.log('Error', err.message);
      }
      return false
    })

}
//selection du capital de référence
export const getCapitalReference = (param) => {
  if (!param) return false
  return axios
    .post('/capital-reference', param)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des prime de référence
export const getPrimeReference = (param) => {

  if (!param) return false
  return axios
    .post('/prime-reference', param)
    .then((res) => {
      if (res.status == 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//capital souscrits
export const getPrimesCapital = async (codeProduit, codeGarantie,codeProduitFormule=null) => {
  let url
  if(codeProduit && codeGarantie && codeProduitFormule){
    url = `/primes-capital/${codeProduit}/${codeGarantie}/${codeProduitFormule}`
  }else if (codeProduit && codeGarantie &&! codeProduitFormule){
    url = `/primes-capital/${codeProduit}/${codeGarantie}`
  }else{
    url = `/primes-capital/${codeProduit}`
  } 
  return axios
    .get(url)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des prime accesoire par produit
export const getPrimeAccessoireProduit = async (codeProduit, periodicite) => {
  return axios
    .get(`prime-accessoires-by-produit/${codeProduit}/${periodicite}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des capitaux par garantie
export const getCapitauxGaranies = async (codeProduit, codeGaranties,codeformules=null) => {
  
  return axios
    .get(`capitaux-garanties/${codeProduit}/${codeGaranties}/${codeformules}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des primes par garantie
export const getPrimeGarantie = (params) => {
 
  return axios
    .post('/prime-garantie', params)
    .then((res) => {
      if (res.status === 200 || res.status ===201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection prime principale par produit
export const getPrimePrincipales = (codeProduit) => {
  return axios
    .get(`/primes-principales/${codeProduit}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection indice de masse corporel
export const getTauxIndiceMasse = (param) => {
  if (!param) return false
  return axios
    .post('/taux-indice-masse', param)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection atux d'interêt unique
export const getTauxInteret = (param) => {
  if (!param) return false
  return axios
    .post('/taux-interet-unique', param)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection taux de surmortalité
export const getTauxSurmortalite = (param) => {
  if (!param) return false
  return axios
    .post('/taux-surmortalite', param)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection taux de tension
export const getTauxTension = (param) => {
  if (!param) return false
  return axios
    .post('/taux-tension', param)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection taux de diabète
export const getTauxDiabete = (param) => {
  if (!param) return false
  return axios
    .post('/taux-diabete', param)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selections examens exigés
export const getExamenExiger = (param) => {
  if (!param) return false
  return axios
    .post('/examen-exiger', param)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection table de mortalité assuré
export const getTableMortaliteAssure = (ageAssure, codeTable = 'TDMCP') => {
  if (!ageAssure) return false
  return axios
    .get(`/table-mortalite-assure/${ageAssure}/${codeTable}`)
    .then((res) => {
  
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection taux de prime unique partenaire
export const getTauxPartenaire = (type, partenaire = 'WF') => {
  if (!type) return false
  return axios
    .get(`/taux-partenaire/${type}/${partenaire}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection contrat connexes
export const getContratsConnexes = (dataSend) => {
  if (!dataSend) return false
  return axios
    .post(`/contrats-connexes`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection questionnaires médicales
export const getQuestionnaires = (codebulletin, t) => {
  return axios
    .get(`/questionnaires/${codebulletin}/${t}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//sauvegade d'une demande de cotation pret
export const savegardePret = (data) => (dispatch) => {
  if (!data) {
    dispatch({ type: SET_ERRORS, payload: { pret: 'Aucune donnée fournie' } })
    return false
  }
  dispatch({ type: LOADING_UI })
  return axios
    .post('/add-pret', data)
    .then((res) => {
      if (res.status === 200) {
        dispatch(selectPretByUser())
        return res.data
      } else {
        console.log(res.data);
        return false
      }
    }).catch((err) => {
      console.log(err);
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      return false
    })
}
//mise à jour d'une demande de cotation de pret
export const updatePret = (data) => (dispatch) => {
  if (!data) {
    dispatch({ type: SET_ERRORS, payload: { pret: 'Aucune donnée fournie' } })
    return false
  }
  dispatch({ type: LOADING_UI })
  return axios
    .post('/update-pret', data)
    .then((res) => {
      if (res.status === 200) {
        dispatch(selectPretByUser())
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      return false
    })
}
//mise à jour d'une proposition
export const updateProposition = (data) => (dispatch) => {
  if (!data) {
    dispatch({ type: SET_ERRORS, payload: { pret: 'Aucune donnée fournie' } })
    return false
  }
  dispatch({ type: LOADING_UI })

  return axios
    .post('/edit-contrat', data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch(selectPropositionByUser())
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      return false
    })
}
//selection des medecin
export const getMedecins=(search)=>{
  const url = search ? `/pret-medecins/${search}` : `/pret-medecins` 
  return axios.get(url).then(res=>{
    if(res.status === 200 || res.status === 201){
      return res.data
    }else{
      return false
    }
  }).catch(err=>{
    console.log(err);
    return false
  })
}
//selection des patients medecins
export const getPatientsMedecin=(dataSend)=>{
 
  return axios.post('/patients-medecin',dataSend).then(res=>{
    if(res.status === 200 || res.status === 201){
      return res.data
    }else{
      return false
    }
  }).catch(err=>{
    console.log(err);
    return false
  })
}
//enregistrement validation proposition
export const saveValidationProposition = (data) => (dispatch) => {
  if (!data) {
    dispatch({ type: SET_ERRORS, payload: { pret: 'Aucune donnée fournie' } })
    return false
  }
  dispatch({ type: LOADING_UI })
  return axios
    .post('/producteur-validation-Proposition', data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        dispatch({ type: STOP_LOADING_UI })
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      dispatch({ type: STOP_LOADING_UI })
      return false
    })
}
//validation proposition multiple
export const validationProposition = (data) => {
  if (!data) {
    return false
  }
  return axios
    .post('/prod-validation-proposition', data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      return false
    })
}
//validation proposition simple
export const validationPropositionSimple = (data) => {
  if (!data) {
    return false
  }
  return axios
    .post('/prod-validation-proposition-simple', data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      return false
    })
}
//validation des assurés propositions
export const validationPropositionAssure = (data) => {
  if (!data) {
    return false
  }
  return axios
    .post('/prod-validation-assures', data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      return false
    })
}
//validation des bénéficaires propositions
export const validationPropositionBeneficaire = (data) => {
  if (!data) {
    return false
  }
  return axios
    .post('/prod-validation-beneficaires', data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      return false
    })
}
//validation des documents propositions
export const validationPropositionDocument = (data) => {
  if (!data) {
    return false
  }
  return axios
    .post('/prod-validation-documents', data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      return false
    })
}

export const validationPropositionDocumentIndiv = (data, refDoc) => {
  if (!data) {
    return false
  }
  return axios
    .post(`/prod-validation-documents/${refDoc}`, data)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err.response);
      }
      console.log(err);
      return false
    })
}
//selection des prets par agent
export const selectPretByUser = (params) => (dispatch) => {
  dispatch({ type: LOADING_UI })

  return axios
    .post('/get-prets', params)
    .then((res) => {
      if (res.status === 200) {
        dispatch({
          type: SET_PRETS,
          payload: res.data
        })
        dispatch({ type: CLEAR_ERRORS })
      } else {
        dispatch({ type: SET_ERRORS, payload: { error: "Une erreur s'est produite" } })
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        dispatch({ type: SET_ERRORS, payload: err.response })
        console.log(err);
        return false
      }
      dispatch({ type: SET_ERRORS, payload: { error: "Une erreur s'est produite" } })
      return false
    })
}
//selection des assurés sur un contrat
export const selectAssuresContrat = (codeContrat) => {
  return axios
    .get(`/get-assures-contrat/${codeContrat}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des bénéficaires sur un contrat
export const selectBeneficairesContrat = (codeContrat, source = null) => {
  
  return axios
    .get(`/get-beneficiaires-contrat/${codeContrat}/${source}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des garanties sur un contrat
export const selectGarantiesContrat = (codeContrat) => {
  return axios
    .get(`/get-garanties-contrat/${codeContrat}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des proposition sur agent
export const selectPropositionByUser = () => (dispatch) => {
  dispatch({ type: LOADING_UI })
  return axios
    .get('/proposition-liste')
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        let myData = JSON.stringify(res.data, null, 2)
        myData = JSON.parse(myData)
        dispatch({
          type: SET_PROPOSITIONS,
          payload: myData
        })
        dispatch({ type: CLEAR_ERRORS })
        return true
      } else {
        dispatch({ type: SET_ERRORS, payload: { error: "Une erreur s'est produite" } })
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        dispatch({ type: SET_ERRORS, payload: err.response })
        console.log(err);
        return false
      }
      dispatch({ type: SET_ERRORS, payload: { error: "Une erreur s'est produite" } })
      return false
    })
}
//selection des proposition sur agent + dispatch state global
export const selectPropositionByUserNoDispatch = (dataSend) => {
  if (!dataSend) { return false }
  return axios
    .post('/proposition-liste-with-param', dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        let myData = JSON.stringify(res.data, null, 2)
        myData = JSON.parse(myData)
        return myData
      } else {
        return false
      }
    }).catch((err) => {
      if (err.hasOwnProperty('response')) {
        console.log(err);
        return false
      }
      return false
    })
}
//selection des banques 
export const getBanqueAgences = (codeBanque = null) => {
  return axios
    .post(`/banque-agences`, { codeBanque })
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des agences par banque
export const getBanqueAssuranceAgences = (codeReseau = null, excluCode = null) => {
 
  return axios
    .post(`/banque-assurance-agences`, { codeReseau, excluCode })
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection adherent API BNI
export const getAdherentBni = (dataParam) => {
  if (!dataParam) return
  console.log(dataParam);
  return axios
    .post('/adherent-api-bni', dataParam)
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        let dataOk = JSON.parse(res.data)
        return dataOk
      } else {
        console.log(res.data);
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection des contrat d'un agent
export const getContratsAgent = (codeAgent) => {
  if (!codeAgent) { return }
  return axios
    .get(`/select-contrat-agent/${codeAgent}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//recherche de contrat
export const getSearchContratsAgent = (dataSearch) => {
  console.log(dataSearch);
  if (!dataSearch) { return }
  return axios
    .post(`/search-contrat-agent`, dataSearch)
    .then((res) => {
        console.log(res);
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection des conseillers
export const getConseillerList = (code = null) => {
  if (!code) return false
  return axios
    .get(`/conseiller-list/${code}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection de tout les prospects
export const getProspectAll = (codeAgent) => (dispatch) => {

}
//selection d'un prospect
export const getProspect = (codeProspect) => (dispatch) => {
  dispatch({ type: LOADING_UI });
}
//selection d'un contrat
export const getContrat = () => (dispatch) => {
  //TO DO
}


const setDataOnStorage = (name, data) => {
  localStorage.setItem(`${name}`, JSON.stringify(data));
};

export const reinitialisationStorage = (refStorage) => {
  localStorage.removeItem(refStorage);
}
//filtre de produits par branche
const reduceProduitBranche = (codebranche, produitAll) => {

  return getBrancheCodeProduit(codebranche).then(refprodBranche => {
   
    let produitsOk = produitAll;

    if (refprodBranche) {
      if (refprodBranche.length !== 0 && (produitAll && produitAll.length !== 0)) {
        let mesproduit = produitAll.filter(item => {
          if (refprodBranche.findIndex(element => element.codeproduit === item.CodeProduit) !== -1) {
            return true;
          } else {
            return false;
          }
        });
        //console.log(mesproduit);
        produitsOk = mesproduit;
      }
    }
    return produitsOk;
  });
};
//selection des statistique
export const etatProductionAgent = (dataSend) => {
  return axios
    .post(`/statetat-productionagent`, dataSend)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection des données producteurs
export const getProducteurData = (dataSend) => {
  return axios
    .post(`/producteur-select-data`, dataSend)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection de documents
export const getDocument = (codeContrat, source = null) => {
  return axios
    .get(`/select-documents/${codeContrat}/${source}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//recherche de personne
export const searchPersonne = (dataSend) => {
  return axios
    .post('/search-personne', dataSend).then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {

      if (err.response) {
        console.log(err.response);
      }
      return false
    })
}
//selection contact personne
export const contactsPersonne = (dataSend) => {
  return axios
    .post('/contacts-personne', dataSend).then((res) => {

      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {

      if (err.response) {
        console.log(err.response);
      }
      return false
    })
}
//selection pièces personne
export const piecesPersonne = (dataSend) => {
  return axios
    .post('/pieces-personne', dataSend).then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err.response) {
        console.log(err.response);
      }
      return false
    })
}
//supression de documents
export const deleteDocument = (codeContrat) => {
  return axios
    .get(`/delete-documents/${codeContrat}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//Selection des bordereaux de proposition en cours
export const getBordereauProposition = (codeContrat) => {
  return axios
    .get(`/bodereau-proposition`)
    .then((res) => {
      if (res.status === 200 || res.status == 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection state production agent
export const getTotProductionAgent = (codeUser) => {

  return axios
    .get(`/total-production-agent/${codeUser}`)
    .then((res) => {
      
      if (res.status === 200 || res.status ===201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection stat evolution production agent
export const getEvolutionProductionAgent = (codeUser) => {
  
  return axios
    .get(`/evolution-production-agent/${codeUser}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection stat evolution production cotation de prets agent
export const getEvolutionProductionPret = (codeUser) => {
  return axios
    .get(`/evolution-production-pret/${codeUser}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection stat production agent par produit
export const getProductionAgentParProduit = (codeUser) => {
  return axios
    .get(`/production-agent-par-produit/${codeUser}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection totals  production agent
export const totProductionAgent = (codeUser) => {
  return axios
    .get(`/total-pret-agent/${codeUser}`)
    .then((res) => {
      if (res.status === 200 || res.status ===201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection détail d'un pret
export const getDetailPret = (codepret) => {
  if (!codepret) return
  return axios
    .get(`/pret-details/${codepret}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection data set TCD
export const getdataSet = (paramData) => {
  return axios
    .post(`/get-data-set`, paramData)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//selection contacs personne
export const getContactsPersonne = (code) => {
  return axios.get(`/contact-personne/${code}`).then(res => {
    if (res.status === 200 || res.status === 201) {
      return res.data
    } else {
      return false
    }
  }).catch(err => {
    console.log(err);
    return false
  })
}
//selection contacs personne
export const getComptePersonne = (code) => {
  return axios.get(`/compte-bancaire-personne/${code}`).then(res => {
    if (res.status === 200 || res.status === 201) {
      return res.data
    } else {
      return false
    }
  }).catch(err => {
    console.log(err);
    return false
  })
}
//etat de cotisation
export const EtatCotisation = (dataSend) => {
  if (!dataSend) return false
  return axios.post(`/etat-cotisation-bis`, dataSend).then(res => {
    if (res.status === 200 || res.status === 201) {
      return res.data
    } else {
      return false
    }
  }).catch(err => {
    console.log(err);
    return false
  })
}

//GESTION DES OPERATIONS
//selection type d'opération
export const getTypeOperationList = (dataSend=null) => {
  return axios.post(`/op-type-operation-list`, dataSend).then(res => {
    if (res.status === 200 || res.status === 201) {
      return res.data
    } else {
      return false
    }
  }).catch(err => {
    console.log(err);
    return false
  })
}
//selection Box courrier -list
export const getBoxCourrierList = () => {
  return axios.get(`/courrier-box-list`).then(res => {
    if (res.status === 200 || res.status === 201) {
      console.log(res.data);
      return res.data
    } else {
      return false
    }
  }).catch(err => {
    console.log(err);
    return false
  })
}
//selection centres hospitalier
export const getCentres = () => {
  return axios
    .get('/centres')
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection des maladies
export const getMaladies = () => {
  return axios
    .get('/maladies')
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection acteur sinistre
export const getActeurSinistre = () => {
  return axios
    .get('/type-acteur-sinistre')
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection détails acteur sinistre
export const getDetailActeursSinistre = (dataSend) => {
  return axios
    .post(`/acteurs-sinistre`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection fiche de demande d'opération
export const getFichierOperation = (dataSend) => {
  return axios
    .post(`/fichiers-operation`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//enregistrer une demande d'opération (sinistre,prestation,modification)
export const addOperation = (dataSend) => {
  return axios
    .post(`/add-operation`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      console.log(err);
      return false
    })
}
//mise à jour une demande d'opération (sinistre,prestation,modification)
export const updateOperation = (dataSend) => {
  return axios
    .post(`/update-operation`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      if (err && err.hasOwnProperty('response')) {
        console.log(err);
        console.log(err.response);
      } else {
        console.log(err);
      }

      return false
    })
}
//liste des demandes d'opération (sinistre,prestation,modification)
export const getOperationByUser = (dataSend) => {
  return axios
    .post(`/get-user-operation`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection stat opération
export const operationStatByUser = (dataSend=null) => {
  return axios
    .post(`/user-operation-stat`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//les villes abritant des pompes funèbres
export const pompesFunebresVille = (dataSend) => {
  return axios
    .get(`/pompes-funebres-ville`, dataSend)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//liste des pompes funèbres
export const pompesFunebres = (ville = null) => {
  return axios
    .get(`/pompes-funebres-list/${ville}`)
    .then((res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        return false
      }
    }).catch((err) => {
      return false
    })
}
//selection operation produit partenaire
export const operationProduitPart = (produit,partenaire) => {
  return axios
  .get(`/check-operation-enable/${produit}/${partenaire}`)
  .then((res) => {
    if (res.status === 200 || res.status === 201) {
      return res.data
    } else {
      return false
    }
  }).catch((err) => {
    return false
  })
}

export const getProspectAgent = (dataSend)=>{
  return axios
    .post(`/prospect-agent`,dataSend)
    .then((res) =>{
      if(res.status === 200 || res.status === 201){
        return res.data
      }else{
        return false
      }
    }).catch((err) =>{
      if (err && err.hasOwnProperty('response')) {
        console.log(err);
        console.log(err.response);
      } else {
        console.log(err);
      }
      return false
    })
}

//selection de prospect par reseau
export const getProspectReseau = (dataSend)=>{
  return axios
    .post(`/prospect-reseau`,dataSend)
    .then((res) =>{
      if(res.status === 200 || res.status === 201){
        return res.data
      }else{
        return false
      }
    }).catch((err) =>{
      if (err && err.hasOwnProperty('response')) {
        console.log(err);
        console.log(err.response);
      } else {
        console.log(err);
      }
      return false
    })
}
//sauvegarde de propsect
export const saveProspect = (dataSend) =>{
  return axios
  .post(`/add-prospect`,dataSend)
  .then((res) =>{
    if(res.status === 200 || res.status === 201){
      return res.data
    }else{
      return false
    }
  }).catch((err) =>{
    if (err && err.hasOwnProperty('response')) {
      console.log(err);
      console.log(err.response);
    } else {
      console.log(err);
    }
    return false
  })
}
//selection des campagnes
export const getcampagneList = () =>{
  return axios
    .get('/compagnes')
    .then(res=>{
      if(res.status === 200 || res.status === 201){
        return res.data
      }else{
        return false
      }
    }).catch(err=>{
      if (err && err.hasOwnProperty('response')) {
        console.log(err);
        console.log(err.response);
      } else {
        console.log(err);
      }
      return false
    })
}
//liste des 5 dernière proposition
export const derniereProposition =(limit =5)=>{
  
  return axios.get('/proposition-liste?limit='+limit).then(res=>{
    if(res.status ==200 || res.status ==201){
      return res.data
    } else{
      return false
    }
  }).catch(err=>{
    console.log(err);
  })
}
//liste des dernière demande d'incorportation
export const derniereIncorporation = ()=>{
  return axios.get('/derniere-incorporation').then(res=>{
    if(res.status ===200 && res.status === 201){
      return res.data
    }else{
      return false
    }
  }).catch(err=>{
    console.log(err);
  })
}
//sauvegade des paiements
export const savePaiement = (dataSend)=>{
  if(!dataSend) return false
  return axios.post(`/paiement-via-api`,dataSend).then(res=>{
    if(res.status === 200 || res.status === 201){
        return res.data
    }else{
      return false
    }
  }).catch(err=>{
    console.log(err.response)
  })
}
//nouvelle demande d'incorporation
export const demandeIncorporation = () =>{
  //TO DO
}

//selection tranche prime
export const getTanchePrime = (datasend)=>{
 
    if(!datasend)
        return false
  return axios.post(`/tranche-prime`,datasend).then(res=>{
      if(res.status===200 || res.status ===201){
        return res.data
      }else{
        return false
      }
  }).catch(err=>{
      if(err.hasOwnProperty('response'))
        console.log(err.response.data);
      return false
  })
}

export const getFacteurFraction = (datasend)=>{
  if(!datasend)
      return false
return axios.post(`/facteur-fractionnement`,datasend).then(res=>{
    if(res.status===200 || res.status ===201){
      return res.data
    }else{
      return false
    }
}).catch(err=>{
    if(err.hasOwnProperty('response'))
      console.log(err.response.data);
      return false
})
}

