import React ,{useState,useReducer,useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import withWidth from '@material-ui/core/withWidth';
import { Typography,Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector,connect} from 'react-redux';
import {formValueSelector} from 'redux-form'
import {getFiliations} from 'redux/actions/apiActions'
import CustomInput from 'components/CustomInput/CustomInput'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomDate from 'components/CustomDate/CustomDate'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import dayjs from 'dayjs'
import {Grid} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  rootList: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display:'flex',
    flexDirection:'row',
    justifyContent:'flex-start'
  },
  rootTabs: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
  

function ProfilModalForm(props) {
  const {open,handleClose,handleAddAssure,mesAssures} = props
  const [inputField,setInputField]=useReducer((state, newState) => ({ ...state, ...newState }),
  {
    numeroCniAssure:'',
    nomAssure: '', 
    prenomAssure: '',
    dateNaissanceAssure:'',
    lieuNaissanceAssure:'',
    lieuResidenceAssure:'',
    filiationAssure:'',
    telephoneAssure:'',
    emailAssure:'',
    monIndex:mesAssures.length+1
  });
  const classes = useStyles();
    //get value off villes
    let  mesVilles = useSelector(state=>state.api.villes)
    if(mesVilles.length===0){
      mesVilles=JSON.parse(localStorage.APIVilles);
    }
    //get filaiation value
    const [filiations, setFiliations] = useState(null)
    useEffect(() => {
      getFiliations().then((fil)=>{
        setFiliations(fil);
      })
    }, [])
    
    const handleChange =(event)=>{
      const {name,value}=event.target
      setInputField({
        [name]:value
      })
    }
    const initialField =()=>{
      setInputField({
        ['numeroCniAssure']:'',
        ['nomAssure']: '', 
        ['prenomAssure']: '',
        ['dateNaissanceAssure']:'',
        ['lieuNaissanceAssure']:'',
        ['lieuResidenceAssure']:'',
        ['filiationAssure']:'',
        ['telephoneAssure']:'',
        ['emailAssure']:'',
      })
    }
    const submit=(e)=>{
      e.preventDefault()
      let dataAssure =inputField
      handleAddAssure(dataAssure)
    }
    useEffect(()=>{
      if(open===true){
          initialField()
      }
    },[open])
    const enabled =inputField.nomAssure.length > 0 &&inputField.prenomAssure.length > 0&&inputField.dateNaissanceAssure.length;
    return (
     
            <Dialog fullWidth maxWidth={props.width} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Ajouter un assuré</DialogTitle>
                <Divider />
              <form onSubmit={submit}>
                <DialogContent> 
                
                    <GridContainer>
                      <GridItem xs={12} sm={12} md ={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={5}>
                                <CustomInput
                                    required
                                    label="Nom assuré"
                                    id="nomAssure"
                                    name="nomAssure"
                                    variant="outlined"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={(inputField.nomAssure && inputField.nomAssure!=="")&&inputField.nomAssure}
                                    onChange={handleChange}
                                    autoFocus
                                    
                                />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={7}>
                                <CustomInput
                                    required
                                    label="Prénom assuré"
                                    id="prenomAssure"
                                    name="prenomAssure"
                                    variant="outlined"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={(inputField.prenomAssure && inputField.prenomAssure!=="")&&inputField.prenomAssure}
                                    onChange={handleChange}
                                   
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={4} md ={4} lg={4}>
                            <CustomInput
                                label="Numéro pièce d'indentité"
                                id="numeroCniAssure"
                                name="numeroCniAssure"
                                variant="outlined"
                                formControlProps={{
                                    fullWidth: true
                                }}
                                value={(inputField.numeroCniAssure && inputField.numeroCniAssure!=="")&&inputField.numeroCniAssure}
                                onChange={handleChange}
                               
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={8} lg={8}>
                            <CustomRadio 
                              label="Nature de la pièce"
                              id="naturePiece"
                              name="naturePiece"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={inputField.naturePiece}
                              row
                              onChange={handleChange}
                              autoFocus
                              options={[{"label":"CNI","value":"CNI"},{"label":"Attestation","value":"AT"},{"label":"Passport","value":"Passport"}]}  
                              
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={5}>
                              <CustomDate
                                  required
                                  label="Date de naissance"
                                  id="dateNaissanceAssure"
                                  name="dateNaissanceAssure"
                                  formControlProps={{
                                      fullWidth: true,
                                  }}
                                  InputProps={{
                                      type:'date',
                                      value:(inputField.dateNaissanceAssure && inputField.dateNaissanceAssure!=="")&&inputField.dateNaissanceAssure,
                                      inputProps: { max:dayjs().add(-3,'year').format('YYYY-MM-DD'),min:dayjs().add(-100,'year').format('YYYY-MM-DD')}
                                  }}
                                  onChange={handleChange}
                                 
                              />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={7}>
                              <CustomSelect
                                  label="Lieu de naissance"
                                  id="lieuNaissanceAssure"
                                  name="lieuNaissanceAssure"
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                                  value={(inputField.lieuNaissanceAssure && inputField.lieuNaissanceAssure!=="")&&(inputField.lieuNaissanceAssure.hasOwnProperty('MonLibelle')?inputField.lieuNaissanceAssure.MonLibelle:inputField.lieuNaissanceAssure)}
                                  options={mesVilles}
                                  onChange={handleChange}
                                 
                              />
                          </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem  xs={12} sm={6} md ={6} lg={6}>
                          <CustomSelect
                                  label="Lieu de résidence"
                                  id="lieuResidenceAssure"
                                  name="lieuResidenceAssure"
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                                  value={(inputField.lieuResidenceAssure && inputField.lieuResidenceAssure!=="")&&(inputField.lieuResidenceAssure.hasOwnProperty('MonLibelle')?inputField.lieuResidenceAssure.MonLibelle:inputField.lieuResidenceAssure)}
                                  options={mesVilles}
                                  onChange={handleChange}
                                  
                            />
                        </GridItem>
                        <GridItem  xs={12} sm={6} md ={6} lg={6}>
                          <CustomSelect
                              label="Lien de parenté"
                              id="filiationAssure"
                              name="filiationAssure"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={(inputField.filiationAssure && inputField.filiationAssure!=="")&&(inputField.filiationAssure.hasOwnProperty('MonLibelle')?inputField.filiationAssure.MonLibelle:inputField.filiationAssure)}
                              options={filiations}
                              onChange={handleChange}
                              
                            />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem  xs={12} sm={4} md ={4} lg={4}>
                          <CustomInput
                              label="Téléphone"
                              id="telephoneAssure"
                              name="telephoneAssure"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={(inputField.telephoneAssure && inputField.telephoneAssure!=="")&&inputField.telephoneAssure}
                              onChange={handleChange}
                              autoFocus
                              
                            />
                        </GridItem>
                        <GridItem  xs={12} sm={8} md ={8} lg={8}>
                          <CustomInput
                              label="Addresse email"
                              id="emailAssure"
                              name="emailAssure"
                              type="email"
                              variant="outlined"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              value={(inputField.emailAssure && inputField.emailAssure!=="")&&inputField.emailAssure}
                              onChange={handleChange}
                              autoFocus
                             
                            />
                        </GridItem>
                      </GridContainer>
                      </GridItem>         
                    </GridContainer>
                 
                          
              </DialogContent>
          <DialogActions>
          <Grid container direction="row" justify="space-between" alignItems="center"> 
            <Button onClick={handleClose} className={classes.btnback} variant="contained">
                Annuler
              </Button>
              <Button type='submit' className={classes.btnnext} variant="contained" disabled={!enabled} >
                Ajouter
              </Button>
          </Grid>
           
          </DialogActions>
          </form> 
        </Dialog>
      
    );
  }

  const selector = formValueSelector('adForm')
  const mapStateToProps = (state) => ({
    api: state.api,
    myValues:selector(state, 'produit') 
  });
  
  const mapActionsToProps = {
   
  };
  ProfilModalForm = withWidth()(ProfilModalForm)
  export default connect(mapStateToProps,mapActionsToProps)(ProfilModalForm)