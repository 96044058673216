import React,{useEffect, useState} from 'react'
import { Paper,Divider, Typography, IconButton, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Addbranche from './Updatebranche'
import {getBranche,deleteBranche} from 'redux/actions/settingsAction'
import MaterialTable from 'material-table';
import Tooltip from '@material-ui/core/Tooltip'
import swal from 'sweetalert'
import CircularProgress from '@material-ui/core/CircularProgress'
import PeopleIcon from '@material-ui/icons/People'
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import CustomInput from 'components/CustomInput/CustomInput'
import ProduitBranche from './ProduitBranche'
import UserBranche from './UserBranche'
import { addBranche } from 'redux/actions/settingsAction'
import { associateUserBranche } from 'redux/actions/settingsAction'

const useStyle = makeStyles(theme=>({
  root:{
      display:'flex',
      flexDirection:'column',
      width:'75%',
      marginLeft:'auto',
      marginRight:'auto',
      [theme.breakpoints.down('sm')]: {
          width:'100%',
      },
      [theme.breakpoints.up('md')]: {
      width:'75%',
      },
      marginBottom:20
  },
  hPaper:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8
  },
  bPaper:{
    padding:10,
    marginBottom:20,
    borderRadius:0
},
  hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
  },
  loaddingContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:0
  },
  bhead:{
      padding:10
  }
}))

export default function AjouterBranche(props) {
  const classes = useStyle()
  const [branche,setbranche] = useState()
  const [loading,setLoading] = useState(false)
  const [tabs,setTabs] = useState('BR')
  const handleTabs = (tb)=>{
    setTabs(tb)
  }
  const [inputField,setInputField] = useState({})
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }
  const handleSaveBranche =(e)=>{
    e&&e.preventDefault()
    setLoading(true)
    addBranche(inputField).then(res=>{
      if(res){
        setLoading(false)
        swal('Succes','Eneregistrement effectué avec succès','succes')
        setbranche(res)
        setTabs('PD')
      }else{
        setLoading(false)
        swal('Warning',"Erreur lors de l'enregistrement",'warning')
      }
    }).catch(err=>{
      setLoading(false)
      swal('Error',"Erreur lors de l'enregistrement",'error')
    })
  }
  const handleAssociateUser=async (users)=>{
    if(branche,users){
      let dataSend = {branche:branche.id,users:users}
      let cusers = await associateUserBranche(dataSend)
      if(cusers){
        setInputField({...inputField,users:cusers})
      }
    }
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.hPaper} variant="outlined">
            <div className='flex flex-row items-center'>
                <Tooltip title="Nouvelle branche"> 
                    <IconButton onClick={()=>props.history.push(`/settings/branche`)} color='primary'>
                        <ArrowBackIcon style={{fontSize:40}} />
                    </IconButton>
                </Tooltip>
                <Typography variant='h5'>Ajouter une branche</Typography>
              
            </div>
        </Paper>
        <Paper  variant="outlined">
            <div className='flex flex-row'>
              <Button  onClick={()=>handleTabs('BR')} className={`p-2 px-4 ${tabs==='BR' ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><AddIcon /> Add branches</Button>
              <Button   onClick={()=>handleTabs('PD')} className={`p-2 px-4 ${tabs==='PD' ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><FolderSpecialIcon />Produits</Button>
              <Button  onClick={()=>handleTabs('US')} className={`p-2 px-4 ${tabs==='US' ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><PeopleIcon/>Responsables</Button>
            </div>
            <Divider />
            <div>
              {tabs==='BR'&&<form onSubmit={handleSaveBranche}>
                < div className='max-w-screen-sm mx-auto mt-4'>
                 <CustomInput
                              required
                              autoFocus
                              variant="outlined"
                              id="codebranche"
                              name='codebranche'
                              label="Code"
                              type="text"
                              value = {inputField['codebranche'] || ""}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              onChange={handleChange}
                          />
                       
                          <CustomInput
                              autoFocus
                              required
                              variant="outlined"
                              id="libelle"
                              name ='libelle'
                              onChange={handleChange}
                              label="Nom branche"
                              type="text"
                              value = {inputField['libelle'] || ""}
                              formControlProps={{
                                  fullWidth: true
                              }}
                              
                          />
                      
                    
                    <Divider />
                      <div className='py-3 flex flex-row justify-between'>
                      <Button className='bg-secondary-main rounded-full text-white hover:text-primary-main px-4 shadow-md border-0' type='reset'  variant='outlined' color="warning">Annuler</Button>
                        <Button className='bg-primary-main rounded-full text-white hover:text-primary-main px-4 shadow-md border-0' type='submit'  variant='outlined' disabled={loading} color="success">
                        {(loading)?(<CircularProgress />):"Ajouter"}
                      </Button></div>
                    </div>
                    </form>}

                    {tabs==='PD'&&<ProduitBranche branche={branche} />}
                    {tabs==='US'&&<UserBranche branche={branche} handleAssociate={handleAssociateUser} />}
              
            </div>
        </Paper>
    </div>
  )
}

