import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/llv//components/customRadioStyle.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel';
const useStyles = makeStyles(styles);

export default function CustomRadio(props) {
    const classes = useStyles();
    const { formControlProps,id,labelProps,helperText,inputProps,error,success,options,label,disabled,value,onChange,name,input,required, ...rest } = props
    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error
      });
      const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true
      });
      const marginTop = classNames({
        [classes.marginTop]: label === undefined
      });
      
    return(
        <FormControl
        component="fieldset"
        {...formControlProps}
        className={formControlProps.className + " " + classes.formControl}
        >
        <FormLabel className={classes.labelClasses} component="legend">  {label}</FormLabel>           
          {options &&
            <RadioGroup 
              value={input?input.value:value?value:null} 
              onChange={input?input.onChange:onChange}
              name={name}
              {...rest}
            >
            {options.map((option,index) =>( 
            <FormControlLabel style={{fontSize:8}} disabled={disabled}  key={index} value={option.value} control={<Radio required={required?true:false}/>} label={option.label} />))}
            
            </RadioGroup>
          }
            

            {error ? (
                <Clear className={classes.feedback + " " + classes.labelRootError} />
            ) : success ? (
                <Check className={classes.feedback + " " + classes.labelRootSuccess} />
            ) : null}
        </FormControl>
    )
}

CustomRadio.propTypes = {
    label: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool
  };