import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, Typography,Divider } from '@material-ui/core'
import clsx from 'clsx'
import Card from '@material-ui/core/Card';
import Activites from './Components/Activites';
import { getPartenaire } from 'redux/actions/settingsAction';
import ProductionParproduit from 'pages/stats/ProductionParproduit'
import EvolutionProduction from 'pages/stats/EvolutionProduction'
const useStyle = makeStyles(theme=>({
  root:{
    margin:20,
    display:'flex',
    flexDirection:'column',
    flex:1,
  },
  hpaper:{
    padding:20,
    borderRadius:10,
    backgroundColor:theme.palette.primary.main
  },
  texte:{
    color:'#fff'
  },
  card:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    justifyContent:'space-around',
    padding:20,
    margin:20,
    alignItems:'center'
  },
  activityCard:{
    padding:20,
    margin:20,
  },
  activityContent:{
    display:'flex',
    justifyContent:'sapce-between'
  },
  activityItem1:{
    flex:2,
    margin:10
  },
  activityItem2:{
    flex:1,
    margin:10
  }
}))
function AccueilCourtage(props) {
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <Paper className={classes.hpaper} variant="outlined">
          <div>
            <Typography className={classes.texte} variant='h4'> Bienvene sur la plate forme de gestion de vos contrats</Typography>
            <Typography className={clsx([classes.texte])} variant='caption'> Contrat,adherent,opération,facturation, ....</Typography>
          </div>
      </Paper>
      <div style={{display:'flex',justifyContent:'center'}}>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'> 01</Typography>
          <Typography variant='body1'> Convention(s)</Typography>
        </Card>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'> 10</Typography>
          <Typography variant='body1'> Adhérent(s)</Typography>
        </Card>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'> 10</Typography>
          <Typography variant='body1'> Prestation(s)</Typography>
        </Card>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'> 00</Typography>
          <Typography variant='body1'> Sinsitre(s)</Typography>
        </Card>
      </div>

      <div className='flex flex-wrap justify-between'>
        <div className='flex-2 mr-4'>
          <ProductionParproduit />
        </div>
        <div className='flex-3'>
          <EvolutionProduction />
          <Paper  variant='outlined'>
            <Typography Typography variant='h5'>Dernières souscription</Typography>
            <Divider />
          </Paper>
        </div>
        <Paper className={`flex-2 mr-2`} variant='outlined'>
            <Typography variant='h5'>Dernières activités</Typography>
            <Divider />
          <div className={classes.activityContent}>
            <div className={classes.activityItem1}>
              <Activites />
            </div>
            <div className={classes.activityItem2}></div>
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default AccueilCourtage