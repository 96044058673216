import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import {reducer as reducerForm} from 'redux-form'
import userReducer from './reducers/userReducer';
import apiReducer from './reducers/apiReducer';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session';
import uiReducer from './reducers/uiReducer';
import settingsReducer from './reducers/settingsReducer' 
import backendReducer from './reducers/backendReducer'
import workReducer from './reducers/workReducer' 
import storage from 'redux-persist/lib/storage'
const initialState = {};

const middleware = [thunk];


const reducers = combineReducers({
  form:persistReducer({key:'form',debug:true,storage:storageSession},reducerForm),
  user:persistReducer({key:'user',debug:true,storage:storage},userReducer),
  api:persistReducer({key:'api',debug:true,storage:storage},apiReducer),
  settings:persistReducer({key:'settings',debug:true,storage:storageSession},settingsReducer),
  UI:persistReducer({key:'ui',debug:true,storage:storageSession},uiReducer),
  backend:persistReducer({key:'backend',debug:true,storage:storageSession},backendReducer),
  worker:workReducer
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore( reducers, initialState, enhancer);
let persistor = persistStore(store)


export  { store as default, persistor }

//const store = (window.devToolsExtension ? window.devToolsExtension()(createStore) : createStore)(reducers);
//const myStore = { store, persistor }

//export default myStore;