import React, { useReducer, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from "@material-ui/core/Typography";
import MaterialTable from 'material-table';
import { connect } from 'react-redux'
import Button from 'components/CustomButtons/Button'
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CustomInput from 'components/CustomInput/CustomInput'
import { useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { addEquipe, getZonesReseau, getEquipesReseau } from 'redux/actions/settingsAction'
import MenuItem from '@material-ui/core/MenuItem';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));
function EquipesReseau(props) {
    const { reseauData, eopen, handleCloseEquipe } = props
    const classes = useStyles();
    const theme = useTheme();
    const [zonesReseau, setZonesReseau] = React.useState([])
    const [equipesReseau, setEquipesReseau] = React.useState([])

    useEffect(() => {
        getZonesReseau(reseauData.id).then(data => { if (data) { setZonesReseau(data); } })
        getEquipesReseau(reseauData.id).then(data => { if (data) { setEquipesReseau(data); } })
    }, [])

    const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            codeequipe: "",
            libelleequipe: "",
            coderesponsable: "",
            nomresponsable: "",
            codezone: "",
            codereseau: reseauData.id
        });

    const handleChange = (event) => {
        const { name, value } = event.target
        setInputField({
            [name]: value
        })
    }
    const handleSaveEquipe = () => {
        if (inputField.codezone === '') return
        if (inputField.codereseau === '') return
        if (inputField.codeequipe.trim() === '') return
        if (inputField.libelleequipe.trim() === '') return
        props.addEquipe(inputField).then(data => {
            if (data) {
                getEquipesReseau(reseauData.id).then(data => { if (data) { setEquipesReseau(data); } })
            } else {
                return false
            }
        }).catch(err => {
            console.log(err);
            return
        })
    }
    return (
        <Dialog
            maxWidth='md'
            open={eopen}
            disableBackdropClick
            fullWidth
            onClose={handleCloseEquipe}
            aria-describedby="alert-dialog-description"
            aria-labelledby="form-dialog-title">
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseEquipe} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Gestion des equipes/Agences - Reseau : - #{reseauData.libelle}
                    </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="codeequipe"
                                    name='codeequipe'
                                    label="code"
                                    type="text"
                                    value={inputField.codeequipe}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="libelleequipe"
                                    name='libelleequipe'
                                    onChange={handleChange}
                                    label="Nom de l'équipe/Agence"
                                    type="text"
                                    value={inputField.libelleequipe}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={4} md={4} lg={4}>
                                <CustomInput
                                    required
                                    autoFocus
                                    variant="outlined"
                                    id="coderesponsable"
                                    name='coderesponsable'
                                    label="code responsable"
                                    type="text"
                                    value={inputField.coderesponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={handleChange}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={8} md={8} lg={8}>
                                <CustomInput
                                    autoFocus
                                    required
                                    variant="outlined"
                                    id="nomresponsable"
                                    name='nomresponsable'
                                    onChange={handleChange}
                                    label="Nom responsable"
                                    type="text"
                                    value={inputField.nomresponsable}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    variant="outlined"
                                    id="codezone"
                                    name='codezone'
                                    select
                                    label="Zone/Inspection/Département"
                                    value={inputField.codezone}
                                    onChange={handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ marginTop: 20, marginBottom: 10 }}
                                >
                                    {(zonesReseau.length !== 0) && zonesReseau.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.libellezone}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Button color="primary" onClick={handleSaveEquipe} disabled={(inputField.codeequipe === '' || inputField.libelleequipe === '' || inputField.codezone === '')} style={{ width: '100%', marginBottom: 20 }}> Ajouter</Button>
                    </GridItem>
                </GridContainer>
                <Divider />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <MaterialTable
                            columns={[
                                { title: '#', field: 'id' },
                                { title: 'Code', field: 'codeequipe' },
                                { title: 'Libelle', field: 'libelleequipe' },
                                { title: 'Code responsable', field: 'coderesponsable' },
                                { title: 'Nom responsable', field: 'nomresponsable' },
                                { title: 'Code zone/inspection', field: 'codezone' },
                            ]}
                            data={equipesReseau}
                            title="Equipes/Agences associées"
                            localization={{
                                toolbar: {
                                    searchPlaceholder: 'Rechercher',
                                    searchTooltip: 'Rechercher'
                                },
                                body: {
                                    emptyDataSourceMessage: 'Aucune donnée trouvée !'
                                },
                                pagination: {
                                    labelRowsSelect: 'Ligne(s)',
                                    labelDisplayedRows: '{count} sur {from}-{to}',
                                    firstTooltip: 'Prémière page',
                                    previousTooltip: 'Précédent',
                                    nextTooltip: 'Suivant',
                                    lastTooltip: 'Dernière page'
                                }
                            }}

                            options={{
                                exportButton: true,
                                searchFieldStyle: {
                                    fontSize: 18,
                                    width: '100%',
                                    height: 50,
                                },
                                actionsColumnIndex: -1
                            }}


                        />

                    </GridItem>
                </GridContainer>
            </DialogContent>
        </Dialog>
    )
}
EquipesReseau.propTypes = {
    reseauData: PropTypes.object.isRequired,
    eopen: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
    settings: state.settings,
});
const mapActionsToProps = { addEquipe }
export default connect(mapStateToProps, mapActionsToProps)(EquipesReseau)