import React,{useEffect,useState} from 'react'
import CustomInput from 'components/CustomInput/CustomInput'
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Paper, Typography,Divider, Button} from '@material-ui/core';
import {
  storePartenaire,
  updatePartenaire,
  getPersonnesRessources,
  deletePersonnesRessources,
  addPartenaireconvention,
  getPartenaireconventions,
  deleteconvention
} from 'redux/actions/settingsAction'
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/styles'
import { IconButton } from '@material-ui/core';
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput';
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Tooltip from '@material-ui/core/Tooltip';
import { PhotoCamera } from "@material-ui/icons";
import { green } from '@material-ui/core/colors';
import Tab from 'react-bootstrap/Tab';
import AddIcon from '@material-ui/icons/Add'
import AddPersonnesRessources from './AddPersonnesRessources';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete'
import AddConventions from './AddConventions';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';


const useStyle =makeStyles(theme=>({
  root:{
    display:'flex',
    flexDirection:'column',
    width:'75%',
    marginLeft:'auto',
    marginRight:'auto',
    [theme.breakpoints.down('sm')]: {
        width:'100%',
    },
    [theme.breakpoints.up('md')]: {
      width:'60%',
    },
    [theme.breakpoints.up('lg')]: {
      width:'50%',
    },
    marginBottom:20
  },
  dialogHead:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  hPaper:{
    padding:10,
    marginBottom:20,
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth:8,
    display:'flex'
  },
  bPaper:{
    padding:10,
    borderRadius:0,
    borderRadius:10
  },
  hContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
  },
  loaddingContainer:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  bcontainer:{
    paddingTop:10,
    backgroundColor:'transparent',
    borderRadius:0
  },
  bhead:{
    padding:10
  },
  btContainer:{
    display:'flex',
    justifyContent:'space-between',
    padding:10
  },
  input: {
    display: "none",
  },
  faceImage: {
    color: theme.palette.primary.light,
  },
  logoContainer:{
    marginBottom:20,
    padding:10,
    display:'flex',
    justifyContent:'space-between'
  },
  logoview:{
    display:'flex',
    justifyContent:'flex-end'  
  },
  logo:{
    width:'30%'
  },
  panelContent:{
    marginTop:20
  },
  addbtn:{
    width:'75%',
    padding:10,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    background:'transparent',
    borderRadius:15,
    border:'1px solid #f6f6f6',
    "&:hover":{
      backgroundColor:theme.palette.primary.main,
      color:'#ffffff',
      border:'none'
    },
    '&<':{
      color:'#ffffff',
    }
  },
  pbPaper:{
    padding:10,
    borderRadius:0,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius:10
  },
  convPaper:{
    backgroundColor:green[50]
  },
  prbtn:{
    color:green[400],
    '&:hover':{
      color:'#ffffff',
      fontSize:30
    }
  },
  table:{

  }
}))

function AddPartenaire(props) {
  const {data,mode,handleFonctionShow} =props
  const classes = useStyle()
 
  const [inputField,setInputField] = React.useState({})
  const handleChange =(e)=>{
    const {name,value} = e.target
    setInputField({...inputField,[name]:value})
  }
  statutJuridiques.sort()
  const [loading,setLoading] =React.useState(false)

  const [selectedFile, setSelectedFile] = React.useState(null);
  const handleCapture = ({target}) => {
    setSelectedFile(target.files[0]);
  };
  //savegarde des données
  const handleSavePartenaire = (e) =>{
    e.preventDefault();
    const dataSend = {...inputField,['logo']:selectedFile}
    console.log(dataSend);
    let formdata = new FormData()
    if(dataSend.hasOwnProperty('code') && dataSend['code']!==null){
      formdata.append('code',dataSend['code'])
    }
    if(dataSend.hasOwnProperty('designation') && dataSend['designation']!==null){
      formdata.append('designation',dataSend['designation'])
    }
    if(dataSend.hasOwnProperty('activitesprincipales') && dataSend['activitesprincipales']!==null){
      formdata.append('activitesprincipales',dataSend['activitesprincipales'])
    }
    if(dataSend.hasOwnProperty('capital') && dataSend['capital']!==null){
      formdata.append('capital',dataSend['capital'])
    }
    if(dataSend.hasOwnProperty('formejuridique') && dataSend['formejuridique']!==null){
      formdata.append('formejuridique',dataSend['formejuridique'])
    }
    if(dataSend.hasOwnProperty('comptecontribuable') && dataSend['comptecontribuable']!==null){
      formdata.append('comptecontribuable',dataSend['comptecontribuable'])
    }
    if(dataSend.hasOwnProperty('nrc') && dataSend['nrc']!==null){
      formdata.append('nrc',dataSend['nrc'])
    }
    if(dataSend.hasOwnProperty('telephone') && dataSend['telephone']!==null){
      formdata.append('telephone',dataSend['telephone'])
    }
    if(dataSend.hasOwnProperty('mobile1') && dataSend['mobile1']!==null){
      formdata.append('mobile1',dataSend['mobile1'])
    }
    if(dataSend.hasOwnProperty('mobile2') && dataSend['mobile2']!==null){
      formdata.append('mobile2',dataSend['mobile2'])
    }
    if(dataSend.hasOwnProperty('adresseemail') && dataSend['adresseemail']!==null){
      formdata.append('adresseemail',dataSend['adresseemail'])
    }
    if(dataSend.hasOwnProperty('siteweb') && dataSend['siteweb']!==null){
      formdata.append('siteweb',dataSend['siteweb'])
    }
    if(dataSend.hasOwnProperty('logo') && dataSend['logo']!==null){
      formdata.append('logo',dataSend['logo'])
    }
    if(dataSend.hasOwnProperty('tauxcom') && dataSend['tauxcom']!==null){
      formdata.append('tauxcom',dataSend['tauxcom'])
    }
    if(dataSend.hasOwnProperty('conventions') && dataSend['conventions']!==null){
      formdata.append('conventions',JSON.stringify(dataSend['conventions']))
    }
    if(dataSend.hasOwnProperty('personnesressources') && dataSend['personnesressources']!==null){
      formdata.append('personnesressources',JSON.stringify(dataSend['personnesressources']))
    }
  
    if(mode !=='UP'){
      setLoading(true)
      storePartenaire(formdata).then(res=>{
        if(res){
          setLoading(false)
          swal('Sucess','Eneregistrement effectué avec succès','sucess')
          handleFonctionShow('LIST')
        }else{
          setLoading(false)
          swal('Warning',"Erreur lors de l'enregistrement",'warning')
        }
      }).catch(err=>{
        setLoading(false)
        swal('Error',"Erreur lors de l'enregistrement",'error')
      })
    }else{
      setLoading(true)
      updatePartenaire(data['id'],formdata).then(res=>{
        if(res){
          setLoading(false)
          swal('Sucess','Modification effectuée avec succès','sucess')
        }else{
          setLoading(false)
          swal('Warning',"Erreur lors de l'opération",'warning')
        }
      }).catch(err=>{
        console.log(err)
        setLoading(false)
        swal('Error',"Erreur lors de l'opération",'error')
      })
    }
    
  }

  useEffect(()=>{
    if(mode == 'UP' && data){
      setInputField({...data})
      setLoading(false)
    }else{
      setInputField({})
      setLoading(false)
    }
  },[mode,data])

  const [preview, setPreview] = useState()
  useEffect(() => {
    if (!selectedFile) {
        setPreview(undefined)
        return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])
 
const [open,setOpen] =useState(null)
const handleOpen =(tp)=>{
  setOpen(tp)
}
const handleClose = ()=>{
  setOpen(null)
}

//GESTION DES PERSONNES RESSOURCES
    //ouverture modal personne ressource
    const handlePersonne =()=>{
      handleOpen('pr')
    }
    //selection des personnes résources
    const [personnes,setPersonnes] =useState(null)
    const handleSelectPersonne =()=>{
      if(mode !=='UP'){
        if(inputField.hasOwnProperty('personnesressources')){
          setPersonnes(inputField.personnesressources)
        }
      }else{
        getPersonnesRessources(data['id']).then(res=>{
          if(res){
            setPersonnes(res)
          }
        })
      }
    }
    useEffect(()=>{
      handleSelectPersonne()
    },[data,inputField.personnesressources])

    //supression d'une personne resources
    const handleDeletePersonne =(id)=>{
      if(mode!=='UP'){
        if(inputField.hasOwnProperty('personnesressources')){
          let currentPerso =[...inputField.personnesressources]
          const newDataPerso = currentPerso.filter(item=>item.id!==id)
          
          setInputField({...inputField,['personnesressources']:newDataPerso})
          setPersonnes(newDataPerso)
          handleSelectPersonne()
        }
      }else{
        deletePersonnesRessources(id).then(res=>{
          if(res){
            handleSelectPersonne()
          }
        })
      }
    }

//GESTION DES CONVENTIONS
   {/**const [inputFieldConv,setInputFieldConv] = React.useState({})
    const handleChangeinputConv =(e)=>{
      const {name,value} = e.target
      setInputFieldConv({...inputFieldConv,[name]:value})
    }
    
    //attachercher fichier conventions
    const [selectedConvFile, setSelectedConvFile] = React.useState(null);
    const handleCapturefile = ({target}) => {
      setSelectedConvFile(target.files[0]);
    };
    */} 
    const [tabs,setTabs] = useState(0)
    const handleTabs = (tb)=>{
        setTabs(tb)
    }
  return (
    <div className={classes.root}>
        <AddPersonnesRessources open={(open==='pr')} data={inputField} mode={mode} changedata={setInputField} handleClose={handleClose} actualizPerso={handleSelectPersonne}/>
        <Paper className={classes.hPaper} variant="outlined">
            <Tooltip title="Retour"> 
                <IconButton onClick={()=>handleFonctionShow('LIST')} color='primary'>
                    <ArrowBackIosIcon style={{fontSize:40}} />
                </IconButton>
            </Tooltip>
            <div className={classes.hContainer}>
                <Typography variant='h5'>Ajouter un partenaire</Typography>
            </div>
        </Paper>
        
        <form >
        <Paper  variant="outlined">
            <div className='flex flex-row'>
                <Button id="partenaire-tab" data-tabs-target="#infos" type="button" role="tab" aria-controls="infos" aria-selected="false"  onClick={()=>handleTabs(0)} className={`p-2 px-4 ${tabs===0 ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}>
                  <AccountCircleIcon /> Informations générales
                </Button>
                <Button data-tabs-target="#personne" type="button" role="tab" aria-controls="personne" aria-selected="false"  onClick={()=>handleTabs(1)} className={`p-2 px-4 ${tabs===1 ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}>
                  <AssignmentIndIcon />Personnes résources
                </Button>
                <Button data-tabs-target="#produits" type="button" role="tab" aria-controls="produits" aria-selected="false"   onClick={()=>handleTabs(2)} className={`p-2 px-4 ${tabs===2 ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><ContactPhoneIcon/>Conventions/produits</Button>
                <Button data-tabs-target="#user" type="button" role="tab" aria-controls="user" aria-selected="false" onClick={()=>handleTabs(3)} className={`p-2 px-4 ${tabs===3 ? "bg-primary-main text-white" : "bg-white"}  rounded-0 `}><AccountTreeIcon/>Responsables</Button>
            </div>
           <Divider />
           <div>     
            <div id="infos" role="tabpanel" aria-labelledby="infos-tab">
                <Paper className={classes.bPaper} variant="outlined">
                  <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                    <CustomInput
                        required
                        variant="outlined"
                        id="code"
                        name='code'
                        label="Code"
                        type="text"
                        value = {inputField.code}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8} lg={8}>
                    <CustomInput
                        required
                        variant="outlined"
                        id="designation"
                        name ='designation'
                        onChange={handleChange}
                        label="Raison sociale"
                        type="text"
                        value = {inputField.designation}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8} lg={8}>
                    <CustomInput
                      variant="outlined"
                      id="activitesprincipales"
                      name ='activitesprincipales'
                      onChange={handleChange}
                      label="Activité principale"
                      type="text"
                      value = {inputField.activitesprincipales}
                      formControlProps={{
                          fullWidth: true
                      }}
                      
                    />
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4} lg={4}>
                    <CustomInput
                        variant="outlined"
                        id="capital"
                        name='capital'
                        label="Capital"
                        type="number"
                        value = {inputField.capital}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                  </GridItem>
              </GridContainer>
            </Paper>
            <div className={classes.logoContainer}>
              <div>
                <input
                  accept="image/jpeg"
                  className={classes.input}
                  id="faceImage"
                  type="file"
                  onChange={handleCapture}
                />
                  <Tooltip title="Selectionnez un logo">
                    <label htmlFor="faceImage">
                      <IconButton
                        className={classes.faceImage}
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera fontSize="large" />
                      </IconButton>
                    </label>
                  </Tooltip>
                <label>{selectedFile ? selectedFile.name : "Cliquez sur l'icon pour selectionner un logo"}</label>. . .
              </div>
              {selectedFile&&<div className={classes.logoview}><img className={classes.logo} src={preview} /></div>}
            </div>
            <Divider />
            <div className={classes.bhead}>
            <Typography variant='caption'>Statut</Typography>
            </div>
              
            <Paper className={classes.bPaper} variant="outlined">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                <CustomSimpleSelect
                    id='formejuridique'
                    name="formejuridique" 
                    label="Forme juridique"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    variant="outlined"
                    onChange={handleChange}
                    value = {inputField['formejuridique'] ||""}
                    options={statutJuridiques}
                />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                    <CustomInput
                        variant="outlined"
                        id="comptecontribuable"
                        name ='comptecontribuable'
                        onChange={handleChange}
                        label="Compte contribuable"
                        type="text"
                        value = {inputField.comptecontribuable}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                    <CustomInput
                        variant="outlined"
                        id="nrc"
                        name ='nrc'
                        onChange={handleChange}
                        label="N° RC"
                        type="text"
                        value = {inputField.nrc}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                    />
                </GridItem>
              </GridContainer>
            </Paper>
            <div className={classes.bhead}>
              <Typography variant='caption'>Contacts</Typography>
            </div>
              
              <Paper className={classes.bPaper} variant="outlined">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}></GridItem>
                <GridItem xs={12} sm={16} md={6} lg={6}>
                  <CustomPhoneInput
                        variant="outlined"
                        id="mobile1"
                        name ='mobile1'
                        onChange={(v)=>setInputField({...inputField,['mobile1']:v})}
                        label="Mobile 1"
                        type="text"
                        value = {inputField["mobile1"] ||""}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                    />
                    
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                <CustomPhoneInput
                        variant="outlined"
                        id="mobile2"
                        name ='mobile2'
                        onChange={(v)=>setInputField({...inputField,['mobile2']:v})}
                        label="Mobile 2"
                        type="text"
                        value = {inputField["mobile2"] || ""}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomInput
                        variant="outlined"
                        id="adresseemail"
                        name='adresseemail'
                        label="Adresse email"
                        type="email"
                        value = {inputField["adresseemail"] || ""}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <CustomInput
                        variant="outlined"
                        id="siteweb"
                        name='siteweb'
                        label="Site web"
                        type="text"
                        value = {inputField.siteweb}
                        formControlProps={{
                            fullWidth: true
                        }}
                        onChange={handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </Paper>
            </div> 
            <div id="personne" role="tabpanel" aria-labelledby="personne-tab">
              <Paper className={classes.pbPaper} variant="outlined">
                    <Button className={classes.addbtn} onClick={handlePersonne}>
                      <AddIcon sx={{ fontSize: 40 }} className={classes.prbtn} />
                    </Button>
              </Paper>
              <Paper className={classes.pbPaper} variant="outlined">
                {(personnes && personnes.length!==0)?(<TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Nom</TableCell>
                        <TableCell align="center">Prenom</TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Téléphone</TableCell>
                        <TableCell align="center">Mobile</TableCell>
                        <TableCell align="center">fonction</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {personnes.map((personne) => (
                        <TableRow key={personne.id}>
                          <TableCell align="right">{personne.nom}</TableCell>
                          <TableCell align="right">{personne.prenom}</TableCell>
                          <TableCell align="center">{personne.email}</TableCell>
                          <TableCell align="center">{personne.telephone}</TableCell>
                          <TableCell align="center">{personne.mobile}</TableCell>
                          <TableCell align="right">{personne.fonction}</TableCell>
                          <TableCell align="right">
                            <Tooltip title="Supprimer" onClick={(()=>handleDeletePersonne(personne.id))}> 
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>):<Typography>Aucune donnée</Typography>}
              </Paper>
            </div>    
            <div id="produits" role="tabpanel" aria-labelledby="produits-tab">
                <AddConventions 
                    data={inputField} 
                    inputField={inputField} 
                    mode='UP' 
                    changedata={setInputField} 
                  />
            </div>
            <div id="user" role="tabpanel" aria-labelledby="user-tab">
                <h1>Utilisateur</h1>
            </div>
            </div>     
       <div className={classes.btContainer}>
          <Button  variant='outlined' round color="warning">Annuler</Button>
          <Button type='submit' variant='outlined' round disabled={loading} onClick={handleSavePartenaire} color="success">{(loading)?(<CircularProgress />):(mode==='UP')?"Modifier":"Enregistrer"}</Button>
        </div>
        </Paper>
      </form>
    </div>
  )
}

export default AddPartenaire

const statutJuridiques=[
'EIRL',
'EURL',
'SA',
'SAS',
'SARL',
'SNC',
'SCA',
'SCS',
'Syndicat de copropriété',
'Association',
'Groupement de personnes',
'Coopérative'
]